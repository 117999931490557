import styles from "./list-slov.module.css";
import { TSlova } from "../list-teams/list-teams";
import Slovo from "../slovo/slovo";
import { getRandomInt } from "../../utils/convert";

type TOrderypeprops = {
  list: TSlova[];
};

function ListSlov(props: TOrderypeprops) {
  const list = props.list;
  return (
    <div className={styles.main}>
      <section className={styles.list}>
        {list.map((item, index) => {
          return <Slovo list={item} key={index}></Slovo>;
        })}
      </section>
    </div>
  );
}

export default ListSlov;

//<img src={dog} alt="logo" className={styles.previewImage}/>
