import styles from "./glagpred.module.css";
import { useState } from "react";
import { AppHeader } from "../app-header/app-header";
import { fraz_1 } from "../../utils/frazpres";
import { fraz_2 } from "../../utils/fraz_2";
import { fraz_3 } from "../../utils/frazimp";
import { shuffle } from "../../utils/convert";
import Menu from "../menu/menu";
import SentensNew from "../sentensnew/sentensnew";

function PredGlag() {
  let list = fraz_1.slice(0);
  const [trans, setTrans] = useState("Показывать перевод");
  const [verb, setVerb] = useState("Показывать глагол");
  const [tempo, setTempo] = useState("Presente do indicativo");

  const translist = ["Показывать перевод", "Не показывать перевод"];
  const glaglist = ["Показывать глагол", "Не показывать глагол"];
  const tempolist = [
    "Presente do indicativo",
    "Pretérito perfeito simples do indicativo",
    "Pretérito imperfeito do indicativo",
    "Все времена вместе",
  ];

  if (tempo === "Presente do indicativo") {
    list = fraz_1.slice(0);
    shuffle(list);
  }
  if (tempo === "Pretérito perfeito simples do indicativo") {
    list = fraz_2.slice(0);
    shuffle(list);
  }
  if (tempo === "Pretérito imperfeito do indicativo") {
    list = fraz_3.slice(0);
    shuffle(list);
  }

  if (tempo === "Все времена вместе") {
    list = fraz_2.concat(fraz_1);
    shuffle(list);
  }

  return (
    <div className={styles.main}>
      <AppHeader name={"Напиши глаголы"}></AppHeader>
      <section className={styles.team}>
        <div className={`${styles.menu} ${styles.menu_3}`}>
          <Menu list={tempolist} setlist={setTempo} name={tempo}></Menu>
        </div>

        <div className={`${styles.menu} ${styles.menu_2}`}>
          <Menu list={translist} setlist={setTrans} name={trans}></Menu>
        </div>
        <div className={`${styles.menu} ${styles.menu_1}`}>
          <Menu list={glaglist} setlist={setVerb} name={verb}></Menu>
        </div>
      </section>
      <div className={styles.text_t}>
        <SentensNew slovo={list} pp={trans} pv={verb}></SentensNew>
      </div>
    </div>
  );
}
export default PredGlag;
