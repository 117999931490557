import styles from "./slovo.module.css";
import { TSlova } from "../list-teams/list-teams";
import { useEffect, useState } from "react";

type TOrderypeprops = {
  list: TSlova;
};

function Slovo(props: TOrderypeprops) {
  const slovo = props.list;

  const [team, setTeam] = useState(slovo.ru);

  useEffect(() => {
    setTeam(slovo.ru);
  }, []);

  const buttonHandler = (slovo: TSlova) => {
    if (slovo.ru === team) {
      setTeam(slovo.port);
    } else {
      if (slovo.port === team) {
        setTeam(slovo.ru);
      } else {
        setTeam(slovo.port);
      }
    }
  };
  if (slovo.ru === "") {
    return null;
  }

  if (slovo.ru === team) {
    return (
      <button
        className={` ${styles.buttonbase} ${styles.buttonru}`}
        onClick={() => buttonHandler(slovo)}
      >
        {team}
      </button>
    );
  } else {
    if (slovo.port === team) {
      if (slovo.port.includes("(m)")) {
        return (
          <button
            className={` ${styles.buttonbase} ${styles.buttonm}`}
            onClick={() => buttonHandler(slovo)}
          >
            {team}
          </button>
        );
      }
      if (slovo.port.includes("(f)")) {
        return (
          <button
            className={` ${styles.buttonbase} ${styles.buttonf}`}
            onClick={() => buttonHandler(slovo)}
          >
            {team}
          </button>
        );
      }

      return (
        <button
          className={` ${styles.buttonbase} ${styles.button}`}
          onClick={() => buttonHandler(slovo)}
        >
          {team}
        </button>
      );
    } else
      return (
        <button
          className={` ${styles.buttonbase} ${styles.buttonru}`}
          onClick={() => buttonHandler(slovo)}
        >
          {slovo.ru}
        </button>
      );
  }
}

export default Slovo;
