import { useState } from "react";
import styles from "./menur.module.css";
import iconmenu from "../../images/menu.png";
import iconmenux from "../../images/xmenu.png";
import { menumain } from "../../utils/structura";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import dog from "../../images/icondog.png";
import { setIndexConfiguration } from "firebase/firestore";
import Menuitem from "../menuitem/menuitem";

type Tcont = {
  list: {
    name: string;
    link: string;
  }[];
};
const Content = (props: Tcont) => {
  {
    props.list.map((iten, indexn) => {
      return (
        <NavLink to={iten.link} className={styles.children} key={indexn}>
          {iten.name}
        </NavLink>
      );
    });
  }
};
const pVariants_2 = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

function MenuR() {
  let index = 1;
  const [open, setopen] = useState(false);
  return (
    <div className={styles.main}>
      {!open && (
        <div
          className={styles.icon}
          onClick={() => {
            setopen(true);
          }}
        >
          <img src={iconmenu} alt="menu"></img>
        </div>
      )}
      {open && (
        <div
          className={styles.icon}
          onClick={() => {
            setopen(false);
          }}
        >
          <img src={iconmenux} alt="menuclouse"></img>
        </div>
      )}
      {open && (
        <motion.div
          className={styles.menu}
          initial={"hidden"}
          animate={"visible"}
          transition={{
            delay: 0.2,
          }}
          variants={pVariants_2}
        >
          {menumain.map((item) => {
            index = index + 1;
            return (
              <Menuitem
                title={item.name}
                children={item.content}
                key={index}
              ></Menuitem>
            );
          })}
        </motion.div>
      )}
    </div>
  );
}

export default MenuR;
