import styles from "./chat.module.css";
import { useAppSelector } from "../../types/hook";
import { getuser } from "../../store/action-selector";
import { Tuser } from "../../store/user";
import { useEffect, useState } from "react";
import { AppHeader } from "../app-header/app-header";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

type Tmassege = {
  user: string;
  text: string;
  index: number;
};

const sendMessage = async (user: Tuser, text: string) => {
  try {
    const docRef = await addDoc(collection(db, "message"), {
      user: user,
      text: text,
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};
/*const controlMessage = (list: Tmassege[]) => {
  return list.map((item) => {
    if (item.text.includes("хуй")) {
      item.text = "";
    }
  });
};*/

const takeMessage = async () => {
  try {
    const list: Tmassege[] = [];
    let i = 1;
    const querySnapshot = await getDocs(collection(db, "message"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      i = i + 1;
      list.push({
        user: doc.data().user.email,
        text: doc.data().text,
        index: i,
      });
    });
    return list;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

function Chat() {
  const user = useAppSelector(getuser);
  const list: Tmassege[] = [];
  const [text, setText] = useState("");
  const [listm, setListm] = useState<Tmassege[]>([
    { user: "", text: "Пока нет отзывов, вы можете быть первым", index: 0 },
  ]);

  useEffect(() => {
    takeMessage().then((res) => {
      if (res) {
        setListm(res);
      }
    });
  }, []);

  const setValuetext = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setText(evt.target.value);
  };
  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    sendMessage(user, text);
    takeMessage().then((res) => {
      if (res) {
        setListm(res);
      }
    });
    setText("");
  };

  return (
    <div className={styles.main}>
      <AppHeader name={"Комментарии"}></AppHeader>
      <section className={styles.masseges}>
        {listm.map((item) => {
          return (
            <div className={styles.message} key={item.index}>
              <p className={styles.text}>{item.text}</p>
              <p className={styles.user}>{item.user}</p>
            </div>
          );
        })}
      </section>
      <form className={styles.form} onSubmit={onSubmit}>
        <input
          className={`${styles.active}`}
          type="text"
          placeholder={"напишите сообщение"}
          onChange={setValuetext}
          value={text}
        ></input>
        <button type="submit" className={`${styles.button} `}>
          Отправить
        </button>
      </form>
    </div>
  );
}
export default Chat;
