import { useEffect, useRef, useState } from "react";
import styles from "./sentensnew.module.css";
import { convert, convertpt } from "../../utils/convert";
import dog from "../../images/smile.png";
import Slovo from "../slovo/slovo";
import Speak from "../speak/speak";
type Twww = {
  p_1: string;
  p_2: string;
  p_3: string;
  p_4: string;
  p_5: string;
};

type TOrderypeprops = {
  slovo: Twww[];
  pp: string;
  pv: string;
};

function SentensNew(props: TOrderypeprops) {
  const list = props.slovo;
  const pp = props.pp;
  const pv = props.pv;
  const button_2 = useRef<HTMLButtonElement | null>(null);
  const inp_2 = useRef<HTMLInputElement | null>(null);

  const [slovo, setSlovo] = useState(list[0]);
  const [counter, setCounter] = useState(1);
  const [word, setWord] = useState("");
  const [wordn, setWordn] = useState("");
  const [ok, setok] = useState(false);
  const [trans, settrans] = useState(true);
  const [team, setTeam] = useState(slovo.p_2);
  const [okfim, setokfim] = useState(false);
  const [wordw, setWordw] = useState("пишите");
  const [wordd, setWordd] = useState("");
  const [verbcr, setVerbcr] = useState(slovo.p_4);

  useEffect(() => {
    if (inp_2.current !== null) {
      inp_2.current.focus();
    }
  }, []);

  useEffect(() => {
    setSlovo(list[counter]);
    setVerbcr(list[counter].p_4);
    setWordw("пишите");
    setWordd("");
    setok(false);
    setokfim(false);
    setWordn("");
    setWord("");
    if (inp_2.current !== null) {
      inp_2.current.value = "";
      inp_2.current.disabled = false;
      inp_2.current.focus();
    }
    if (counter > list.length) {
      setCounter(0);
    }
    setCounter(counter + 1);
  }, [props]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
    }
  };

  const buttonHandler_1 = () => {
    setSlovo(list[counter]);
    setVerbcr(list[counter].p_4);
    setWordw("пишите");
    setWordd("");
    setok(false);
    setokfim(false);
    setWordn("");
    setWord("");
    if (inp_2.current !== null) {
      inp_2.current.value = "";
      inp_2.current.disabled = false;
      inp_2.current.focus();
    }
    if (counter === list.length) {
      setCounter(1);
    } else {
      setCounter(counter + 1);
    }
  };

  const onClick = () => {
    if (verbcr === slovo.p_4) {
      setVerbcr(slovo.p_4);
    } else {
      setVerbcr(slovo.p_2);
    }
  };

  const rr = "cursor:pointer";
  //****************************************************************************** */

  const setValueWord = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const slovow = evt.target.value.toLowerCase();
    const slovop = convert(slovo.p_2);
    const slovopt = convertpt(slovo.p_2);
    slovop.trim();

    if (
      slovop.slice(0, slovow.length) === slovow ||
      slovopt.slice(0, slovow.length) === slovow
    ) {
      setok(true);
      setWord(slovow);
      setokfim(false);
      setWordn("");
    } else {
      setok(false);
      setokfim(false);
      if (word !== "") {
        setWordn(`было правильно до ${word}`);
      } else {
        setWordn(`начинается с ${slovop[0]}`);
      }
    }
    if (slovop === slovow || slovopt === slovow) {
      setokfim(true);
      setTeam(slovo.p_2);
      setWordw(slovow);
      setWordd(slovop);
      setWordn(`Да, это правильно: ${slovo.p_2}`);
      evt.target.setAttribute("disabled", "disabled");
      if (button_2.current !== null) {
        button_2.current.focus();
      }
    }
    if (evt.target.value.length < 4) {
      evt.target.size = 1;
    } else {
      evt.target.size = Math.max(evt.target.value.length - 3, 1);
    }
  };
  //****************************************************************************** */
  return (
    <div className={styles.main}>
      {pv === "Показывать глагол" && (
        <div className={styles.p_1}>
          <p
            className={`${styles.menuButton_11} ${styles.menuButton} `}
            onClick={onClick}
          >
            {verbcr}
          </p>
        </div>
      )}
      <div className={styles.p_1}>
        <p className={styles.menuButton}>{slovo.p_1}</p>
        <input
          className={`${styles.menuButton_2} ${styles.menuButton} `}
          type="text"
          placeholder="пишите"
          onInput={setValueWord}
          ref={inp_2}
          id="write"
          onKeyPress={handleKeyPress}
        ></input>
        {okfim === true && (
          <div className={styles.preview}>
            <img src={dog} alt="dog" />
          </div>
        )}
        <p className={styles.menuButton}>{slovo.p_3}</p>
      </div>
      <div className={styles.p_1}>
        <p
          className={`${styles.menuButton_4} ${styles.menuButton} `}
          style={{ color: "green" }}
        >
          {wordn}
        </p>
      </div>
      {pp === "Показывать перевод" && (
        <div
          className={styles.p_1}
          onClick={() => settrans(!trans)}
          style={{ cursor: "pointer" }}
        >
          <p className={`${styles.menuButton_3} ${styles.menuButton}`}>
            {trans ? slovo.p_5 : slovo.p_2}
          </p>
        </div>
      )}

      <button
        className={styles.but}
        onClick={() => buttonHandler_1()}
        ref={button_2}
      >
        еще...
      </button>
    </div>
  );
}

export default SentensNew;
/*<button className={styles.button} ref={button_2} onClick={OnClick}>
еще...
</button>*/
