import { useState } from "react";

import styles from "./fraz.module.css";
import {
  fraz_1,
  team,
  fraz_2,
  fraz_3,
  fraz_4,
  fraz_5,
  fraz_6,
} from "../../utils/fraz";
import ListSlov from "../list-slov/list-slov";
import { AppHeader } from "../app-header/app-header";

function FrazList() {
  const [teamname, setTeamname] = useState("Выбор темы");

  const [listactive, setlistactive] = useState(false);

  const [listslovs, setlistslov] = useState([{ ru: "", port: "" }]);

  const buttonHandler = (nom: string) => {
    setTeamname(nom);

    if (nom === "Основные фразы") {
      setlistslov(fraz_1);
      // console.log(fraz_1);
    }

    if (nom === "Путешествия и транспорт") {
      setlistslov(fraz_2);
    }
    if (nom === "Здоровье") {
      setlistslov(fraz_3);
    }
    if (nom === "Отель") {
      setlistslov(fraz_4);
    }
    if (nom === "Покупки и сервис") {
      setlistslov(fraz_5);
    }

    if (nom === "Ресторан") {
      setlistslov(fraz_6);
    }
    setlistactive(false);
    //console.log(listslovs);
  };

  let index = 0;

  const buttonHandlerTeam = () => {
    setlistactive(true);
  };

  return (
    <div className={styles.main}>
      <AppHeader name={"Учить фразы"}></AppHeader>
      <button className={styles.buttonteam} onClick={() => buttonHandlerTeam()}>
        {teamname}
      </button>
      {listactive === true && (
        <section className={`custom-scroll ${styles.list}`}>
          {team.map((item) => {
            {
              index = index + 1;
            }
            if (item === teamname) {
              return (
                <button
                  className={styles.buttonactive}
                  onClick={() => buttonHandler(item)}
                  key={index}
                >
                  {teamname}
                </button>
              );
            } else {
              return (
                <button
                  className={styles.button}
                  onClick={() => buttonHandler(item)}
                  key={index}
                >
                  {item}
                </button>
              );
            }
          })}
        </section>
      )}

      <ListSlov list={listslovs}></ListSlov>
    </div>
  );
}

export default FrazList;
