import { useState } from "react";
import AppHeader from "../../components/app-header/app-header";
import Cards from "../../components/cards/cards";
import { shuffle } from "../../utils/convert";
import styles from "./verbo.module.css";
import { fetchList } from "../../api/api";
import { Link } from "react-router-dom";
import Cardsverb from "../../components/cardsverb/cardsverb";
import { verboall } from "../../utils/glag";

function Verbo() {
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <AppHeader name={"Повторяем все глаголы"}></AppHeader>
      </div>
      <Cardsverb list={verboall}></Cardsverb>
    </div>
  );
}

export default Verbo;

/*<p className={styles.text}>Основной язык:</p>

<button className={styles.button} onClick={handleClick}>
  {basetext}
</button>*/
