import React, { useState } from "react";
import styles from "./forgot-password.module.css";
import LinLogin from "../../components/link-login/link-login";
import { AppHeader } from "../../components/app-header/app-header";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../types/hook";
import dog from "../../images/dog_2.jpg";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
const user = {};

function ForgotPassword() {
  const [email, setEmail] = useState("anficaw@mail.ru");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [messageOk, setmessageOk] = useState(" ");
  const setValueEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
  };

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;
    auth.languageCode = "rus";
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setmessageOk(
          `Письмо для смены пароля успешно отправлено по адресу: ${email}, если вы не получили письмо, попробуйте другой адрес`
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setmessageOk(`Письмо не удалось отправить ${errorMessage}`);
      });
  };

  return (
    <div className={styles.login}>
      <div className={styles.header}>
        <AppHeader name={"Восстановление пароля"}></AppHeader>
      </div>
      <div className={styles.preview_2}>
        <img src={dog} className={styles.preview} alt="dog" />
      </div>
      <section className={styles.main}>
        <form className={styles.form} onSubmit={onSubmit}>
          <input
            type="email"
            placeholder="e-mail"
            onChange={setValueEmail}
            className={` ${styles.buttonbase} ${styles.button} `}
            value={email}
          />
          <button
            type="submit"
            className={` ${styles.buttons} ${styles.button} `}
          >
            Восстановление пароля
          </button>
          <div className={styles.text}>{messageOk}</div>
          <LinLogin
            to="/login"
            qwest="Вспомнили пароль?"
            text="Войти"
          ></LinLogin>
        </form>
      </section>
    </div>
  );
}
export default ForgotPassword;
