import styles from "./predall.module.css";
import { useState } from "react";
import { AppHeader } from "../app-header/app-header";
import { fraz_1 } from "../../utils/frazpres";
import { fraz_2 } from "../../utils/fraz_2";
import { fraz_3 } from "../../utils/frazimp";
import { shuffle } from "../../utils/convert";
import { pred_1L, pred_f, pred_a2 } from "../../utils/pred";
import Menu from "../menu/menu";

import SentensAll from "../sentensall/sentensall";

function PredAll() {
  const list_1 = fraz_1;
  const list_2 = fraz_2;
  const list_3 = fraz_3;
  const list_1A2 = pred_1L;
  const list_2A2 = pred_f;
  const list_3A2 = pred_a2;
  shuffle(list_1);
  shuffle(list_2);
  shuffle(list_3);
  shuffle(list_1A2);
  shuffle(list_2A2);
  shuffle(list_3A2);
  const [dif, setDif] = useState("Тема: Presente do indicativo");
  const diflist = [
    "Тема: Presente do indicativo",
    "Тема: Pretérito perfeito",
    "Тема: Pretérito imperfeito",
    "Тема: Presente do indicativo A2",
    // "Тема: Pretérito perfeito A2",
    "Тема: Pretérito imperfeito A2",
    "Тема: Сборная солянка A2",
  ];
  type Twww = {
    p_1: string;
    p_2: string;
    p_3: string;
    p_4: string;
    p_5: string;
  };
  type Tall = {
    port: string;
    rus: string;
  };
  const all = (list: Twww[]) => {
    const alllist: Tall[] = [];
    list.map((item) => {
      const slovo = {
        port: item.p_1 + " " + item.p_2 + " " + item.p_3,
        rus: item.p_5,
      };
      alllist.push(slovo);
    });
    return alllist;
  };

  return (
    <div className={styles.main}>
      <AppHeader name={"Переведите предложение"}></AppHeader>
      <section className={styles.team}>
        <div className={styles.menu}>
          <Menu list={diflist} setlist={setDif} name={dif}></Menu>
        </div>
      </section>

      <div className={styles.text_t}>
        {dif === "Тема: Presente do indicativo" && (
          <SentensAll slovo={all(list_1)}></SentensAll>
        )}
        {dif === "Тема: Pretérito perfeito" && (
          <SentensAll slovo={all(list_2)}></SentensAll>
        )}
        {dif === "Тема: Pretérito imperfeito" && (
          <SentensAll slovo={all(list_3)}></SentensAll>
        )}
        {dif === "Тема: Presente do indicativo A2" && (
          <SentensAll slovo={list_1A2}></SentensAll>
        )}
        {dif === "Тема: Pretérito imperfeito A2" && (
          <SentensAll slovo={list_2A2}></SentensAll>
        )}
        {dif === "Тема: Сборная солянка A2" && (
          <SentensAll slovo={list_3A2}></SentensAll>
        )}
      </div>
    </div>
  );
}
export default PredAll;
