import styles from "./glag.module.css";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../types/hook";
import { AppHeader } from "../../components/app-header/app-header";
import { getuser } from "../../store/action-selector";

function GlagList() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getuser);
  if (user.auth) {
    return (
      <div className={styles.main}>
        <AppHeader name={"Учить глаголы"}></AppHeader>
        <section className={styles.team}>
          <NavLink className={styles.active} to="/presente">
            <div className={styles.text}>Presente do indicativo - учить</div>
          </NavLink>
          <NavLink className={styles.active} to="/preswrite">
            <div className={styles.text}>Presente do indicativo - писать</div>
          </NavLink>
          <NavLink className={styles.active} to="/presglag">
            <div className={styles.text}>
              Presente do indicativo - предложения
            </div>
          </NavLink>
        </section>
        <section className={styles.team}>
          <NavLink className={styles.active} to="/perfeito">
            <div className={styles.text}>
              Pretérito perfeito simples do indicativo - учить
            </div>
          </NavLink>
          <NavLink className={styles.active} to="/prefwrite">
            <div className={styles.text}>
              Pretérito perfeito simples do indicativo - писать
            </div>
          </NavLink>
        </section>
        <section className={styles.team}>
          <NavLink className={styles.active} to="/imperfeito">
            <div className={styles.text}>
              Pretérito imperfeito do indicativo - учить
            </div>
          </NavLink>
          <NavLink className={styles.active} to="/imperfeitowriter">
            <div className={styles.text}>
              Pretérito imperfeito do indicativo - писать
            </div>
          </NavLink>
        </section>
      </div>
    );
  } else {
    return (
      <div className={styles.main}>
        <AppHeader name={"Учить глаголы"}></AppHeader>
        <section className={styles.team}>
          <NavLink className={styles.active} to="/presente">
            <div className={styles.text}>Presente do indicativo - учить</div>
          </NavLink>
          <NavLink className={styles.active} to="/preswrite">
            <div className={styles.text}>Presente do indicativo - писать</div>
          </NavLink>
          <NavLink className={styles.active} to="/presglag">
            <div className={styles.text}>
              Presente do indicativo - писать фразы
            </div>
          </NavLink>
        </section>
        <section className={styles.team}>
          <NavLink className={styles.active} to="/register">
            <div className={styles.text}>
              Хочешь узнать и выучить другие времена? Зарегистрируйся... Это
              просто и бесплатно
            </div>
          </NavLink>
        </section>
      </div>
    );
  }
}

export default GlagList;
