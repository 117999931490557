import styles from "./predconst.module.css";
import { useState } from "react";
import { AppHeader } from "../app-header/app-header";
import { pred_1L, pred_f, pred_a2 } from "../../utils/pred";
import { shuffle } from "../../utils/convert";
import SentensСonstOn from "../sentensconst/sentensconst";
import Menu from "../menu/menu";

function PredConst() {
  const list = pred_1L;
  const list_2 = pred_f;
  const list_3 = pred_a2;
  shuffle(list);
  shuffle(list_2);
  shuffle(list_3);
  const [trans, setTrans] = useState("Показывать перевод");
  const [dif, setDif] = useState("Тема: Presente do indicativo");
  const translist = ["Показывать перевод", "Не показывать перевод"];

  const diflist = [
    "Тема: Presente do indicativo",
    "Тема: Pretérito perfeito",
    "Тема: Pretérito imperfeito",
    "Тема: Сборная солянка A2",
  ];
  return (
    <div className={styles.main}>
      <AppHeader name={"Поставьте слова в правильном порядке"}></AppHeader>
      <section className={styles.team}>
        <div className={`${styles.menu} ${styles.menu_2}`}>
          <Menu list={translist} setlist={setTrans} name={trans}></Menu>
        </div>
        <div className={`${styles.menu} ${styles.menu_1}`}>
          <Menu list={diflist} setlist={setDif} name={dif}></Menu>
        </div>
      </section>

      <div className={styles.text_t}>
        {dif === "Тема: Presente do indicativo" && (
          <SentensСonstOn slovo={list} trans={trans}></SentensСonstOn>
        )}
        {dif === "Тема: Pretérito imperfeito" && (
          <SentensСonstOn slovo={list_2} trans={trans}></SentensСonstOn>
        )}
        {dif === "Тема: Сборная солянка A2" && (
          <SentensСonstOn slovo={list_3} trans={trans}></SentensСonstOn>
        )}
      </div>
    </div>
  );
}
export default PredConst;
