import { useState } from "react";
import styles from "./menuitem.module.css";
import { motion, AnimatePresence } from "framer-motion";
import { getuser } from "../../store/action-selector";
import { useAppSelector } from "../../types/hook";
import { NavLink } from "react-router-dom";

type TMenuProps = {
  title: string;
  children: {
    name: string;
    link: string;
  }[];
};

const pVariants_2 = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

function Menuitem(props: TMenuProps) {
  const { title = "click me", children } = props;

  const [isVisible, setVisible] = useState(false);

  const handleVisibility = () => {
    setVisible(!isVisible);
  };

  return (
    <div className={styles.main}>
      <motion.div
        className={styles.title}
        onClick={handleVisibility}
        whileHover={{ scale: 1.1 }}
      >
        {title}
      </motion.div>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            className={styles.menu}
            initial={"hidden"}
            animate={"visible"}
            transition={{
              delay: 0.2,
            }}
            variants={pVariants_2}
          >
            {children.map((item, index) => {
              return (
                <NavLink to={item.link} className={styles.children} key={index}>
                  {item.name}
                </NavLink>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Menuitem;
