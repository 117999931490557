export const aone = [
  { port: "abaixo", rus: "ниже" },
  { port: "aberto", rus: "открыто" },
  { port: "abril", rus: "апрель" },
  { port: "abrir", rus: "открывать" },
  { port: "асаbаr", rus: "заканчивать" },
  { port: "асаbаг", rus: "только что сделать" },
  { port: "рог acaso", rus: "случайно" },
  { port: "acender", rus: "закурить, включить" },
  { port: "acesso ", rus: "доступ" },
  { port: "achar", rus: "считать, думать" },
  { port: "acidente", rus: "авария" },
  { port: "acima", rus: "выше" },
  { port: "acontecer", rus: "случиться" },
  { port: "odotor", rus: "усыновить" },
  { port: "adulto", rus: "взрослый" },
  { port: "advogado", rus: "адвокат" },
  { port: "aeroporto", rus: "аэропорт" },
  { port: "agencia", rus: "агентство" },
  { port: "agora", rus: "сейчас" },
  { port: "agradável", rus: "приятный" },
  { port: "água, а", rus: "вода" },
  { port: "ai", rus: "там" },
  { port: "ainda", rus: "ещё" },
  { port: "Ainda bem!", rus: "Вот и хорошо!" },
  { port: "ajuda, а", rus: "помощь" },
  { port: "ajudar", rus: "помогать" },
  { port: "álcool. о", rus: "алкоголь" },
  { port: "aldeia. а", rus: "деревня" },
  { port: "alegre", rus: "весёлый" },
  { port: "além disso", rus: "кроме того" },
  { port: "alem o", rus: "немецкий, немец" },
  { port: "alérgico", rus: "аллергия" },
  { port: "alfabeto, о", rus: "алфавит" },
  { port: "algo", rus: "кое-что, что-то" },
  { port: "alguém", rus: "кое-кто, кто-то" },
  { port: "algum", rus: "кое-какой, какой-то" },
  { port: "ali", rus: "там" },
  { port: "alimentação, о", rus: "еда" },
  { port: "almoçar", rus: "обедать" },
  { port: "almoço. о", rus: "обед" },
  { port: "alojamento, о", rus: "жильё" },
  { port: "alterar", rus: "изменить" },
  { port: "de altura media", rus: "среднего роста" },
  { port: "naquela altura", rus: "в то время" },
  { port: "alto", rus: "высокий" },
  { port: "alugar", rus: "снять, арендовать" },
  { port: "aluno, о", rus: "ученик" },
  { port: "amanhã", rus: "завтра" },
  { port: "Ate amanhã", rus: "До завтра!" },
  { port: "amar", rus: "любить" },
  { port: "amarelo", rus: "жёлтый" },
  { port: "amargo", rus: "горький" },
  { port: "amblente, о", rus: "обстановка" },
  { port: "ambos", rus: "оба" },
  { port: "ambullância, а", rus: "скорая помощь" },
  { port: "americano", rus: "американский, американец" },
  { port: "amigo, о", rus: "друг " },
  { port: "amor, о", rus: "любовь" },
  { port: "andar", rus: "ходить" },
  { port: "andar (na escola)", rus: "ходить (вшколу)" },
  { port: "andar (а fazer algo)", rus: "делать (что-то)" },
  { port: "andar (de carro) ", rus: "ездить (на машине)" },
  { port: "andar (de fato)", rus: "ходить (в костюме)" },
  { port: "andar, о", rus: "этаж" },
  { port: "angolano", rus: "ангольский, анголец" },
  { port: "animal, о", rus: "животное" },
  { port: "ano, о", rus: "год" },
  { port: "ano novo, о", rus: "новый год" },
  { port: "fazer anos", rus: "день рождения" },
  { port: "anteontem", rus: "позавчера" },
  { port: "antes (de)", rus: "до" },
  { port: "antigamente", rus: "раньше" },
  { port: "antigo", rus: "старый" },
  { port: "antipático", rus: "неприятный" },
  { port: "anualmente", rus: "ежегодно" },
  { port: "apagar", rus: "погасить, выключить" },
  { port: "apaixonar-se", rus: "влюбиться" },
  { port: "apanhar", rus: "поймать" },
  { port: "aparecer", rus: "появиться" },
  { port: "apartamento", rus: "квартира" },
  { port: "apelido, o", rus: "фамилия" },
  { port: "apenas", rus: "всего, только" },
  { port: "apertado", rus: "тесный" },
  { port: "apetecer", rus: "хотеться (чего-либо)" },
  { port: "aprender", rus: "изучать" },
  { port: "apresentar", rus: "представить" },
  { port: "aquecimento central, o", rus: "центральное отопление" },
  { port: "anúncio, о", rus: "объявление" },
  { port: "aquele", rus: "тот" },
  { port: "aqui", rus: "здесь" },
  { port: "Aqui esta!", rus: "Пожалуйста!" },
  { port: "aquilo", rus: "то" },
  { port: "аr, о", rus: "воздух" },
  { port: "ar condicionado, о", rus: "кондиционер" },
  { port: "árаbе", rus: "арабский" },
  { port: "árеа, а", rus: "площадь" },
  { port: "arquiteto, о", rus: "архитектор" },
  { port: "arranjar (bilhete)", rus: "найти (билет)" },
  { port: "arranjar (carro)", rus: "ремонтировать (машину)" },
  { port: "arroz, о", rus: "рис" },
  { port: "arrumar", rus: "убирать" },
  { port: "arte, а", rus: "искусство" },
  { port: "artista, о/а", rus: "артист" },
  { port: "árvore, а", rus: "дерево" },
  { port: "aspirar", rus: "пылесосить" },
  { port: "aspirina, а", rus: "аспирин" },
  { port: "assado", rus: "печёный, жареный" },
  { port: "assento, о", rus: "сиденье" },
  { port: "assim", rus: "так" },
  { port: "assinar", rus: "подписать" },
  { port: "assinoturo, о", rus: "подnись" },
  { port: "assistir (а um jogo)", rus: "посетить (игру)" },
  { port: "assoalhada, а", rus: "комната" },
  { port: "até", rus: "даже,до" },
  { port: "Atenção!", rus: "Внимание!" },
  { port: "atender (о telefone)", rus: "ответить (на звонок)" },
  { port: "aterrar", rus: "nриземляться" },
  { port: "ativo", rus: "активный" },
  { port: "ator, о", rus: "актёр" },
  { port: "atrás (de)", rus: "за" },
  { port: "atrasado", rus: "опоздавший" },
  { port: "chegar com atraso", rus: "оnоздать" },
  { port: "atravessar", rus: "переходить" },
  { port: "atriz, а", rus: "актриса" },
  { port: "aula, а", rus: "урок" },
  { port: "autocarro, о", rus: "автобус" },
  { port: "autostrada, a", rus: "автострада" },
  { port: "autor, о", rus: "автор" },
  { port: "avariado", rus: "неисnравный, не работает" },
  { port: "avenida", rus: "проспект" },
  { port: "avião, o", rus: "самолет" },
  { port: "avisar", rus: "предупреждать" },
  { port: "avó, a", rus: "бабушка" },
  { port: "avô, o", rus: "дедушка" },
  { port: "azeite,o", rus: "оливковое масло" },
  { port: "azeitona,a", rus: "оливка" },
  { port: "azul", rus: "голубой" },
  { port: "azulejo,o", rus: "кафельная плитка" },
  { port: "bacalhau, o", rus: "треска" },
  { port: "bacon, o", rus: "бекон" },
  { port: "bagagem, a", rus: "багаж" },
  { port: "baguete", rus: "багет" },
  { port: "bairro, o", rus: "район" },
  { port: "baixo", rus: "низкий" },
  { port: "balança, a", rus: "весы" },
  { port: "balcão do check", rus: "стойка регистрации" },
  { port: "banal", rus: "банальный" },
  { port: "banco, o", rus: "банк" },
  { port: "banda", rus: "группа" },
  { port: "bandeira, a", rus: "флаг" },
  { port: "banheira, a", rus: "ванна" },
  { port: "bar, o", rus: "бар" },
  { port: "barato", rus: "дешевый" },
  { port: "barba, a", rus: "борода" },
  { port: "bacro, o", rus: "паром, лодка" },
  { port: "barriga, a", rus: "животное" },
  { port: "barulheto", rus: "шумный" },
  { port: "barulho, o", rus: "шум " },
  { port: "bastante", rus: "достаточно" },
  { port: "batalha, a", rus: "битва" },
  { port: "batata, a", rus: "картошка" },
  { port: "bater", rus: "стучать, бить" },
  { port: "beber", rus: "пить" },
  { port: "bebida, a", rus: "напиток" },
  { port: "beleza, a", rus: "красота" },
  { port: "belga, a", rus: "бельгиец" },
  { port: "bem", rus: "хорошо" },
  { port: "bem-disposto", rus: "хорошее настороение" },
  { port: "bem-vindo", rus: "добро пожаловать" },
  { port: "biblioteca, a", rus: "библиотека" },
  { port: "bica, a", rus: "чашечка кофе" },
  { port: "bicicleta, a", rus: "велосипед" },
  { port: "bigote, o", rus: "усы" },
  { port: "blusa, a", rus: "блузка" },
  { port: "boca, a", rus: "рот" },
  { port: "um bocado", rus: "немного" },
  { port: "bolacha, а", rus: "печенье" },
  { port: "bolo, о", rus: "торт, пирог" },
  { port: "bolso, о", rus: "карман" },
  { port: "bom/boa", rus: "хороший, хорошая" },
  { port: "Estás bom/boa?", rus: "Как дела?" },
  { port: "Воа!", rus: "Отлично!" },
  { port: "ser bоm (em)", rus: "быть мастером (в)" },
  { port: "bоmbа de gasolina, а", rus: "заправка" },
  { port: "bonito", rus: "красивый" },
  { port: "borrego, о", rus: "баранина" },
  { port: "botão, о", rus: "кнопка" },
  { port: "botas, as", rus: "ботинки, сапоги" },
  { port: "braço, o ", rus: "рука" },
  { port: "branco", rus: "белый" },
  { port: "brasileiro", rus: "бразильский, бразилец" },
  { port: "Estás а brincar!", rus: "Ты шутишь!" },
  { port: "brindar", rus: "поднимать тост" },
  { port: "brinquedo, о", rus: "игрушка" },
  { port: "bronzeado", rus: "загорелый" },
  { port: "ir buscar", rus: "идти, ехать за" },
  { port: "сá", rus: "здесь" },
  { port: "саbeça, a", rus: "голова" },
  { port: "cabelo, о", rus: "волосы" },
  { port: "саbег", rus: "помещаться" },
  { port: "cacau, o", rus: "какао" },
  { port: "cadeira, а", rus: "стул" },
  { port: "caderno, о", rus: "тетрадь" },
  { port: "café, о", rus: "кофе" },
  { port: "café, о", rus: "кафе" },
  { port: "саiг", rus: "упасть" },
  { port: "caixa, а", rus: "коробка" },
  { port: "calado", rus: "молчаливый" },
  { port: "calar-se", rus: "замолчать" },
  { port: "calçada, а", rus: "мостовая" },
  { port: "calçado, о", rus: "обувь" },
  { port: "calçar", rus: "обувать" },
  { port: "calças, as", rus: "брюки" },
  { port: "calções, os", rus: "шорты" },
  { port: "se calhar", rus: "может быть" },
  { port: "calmo", rus: "спокойный" },
  { port: "estar com calor", rus: "(Мне) жарко" },
  { port: "estar/fazer calor", rus: "жарко" },
  { port: "cama, а", rus: "кровать" },
  { port: "cãmara municipal, а", rus: "муниципалитет" },
  { port: "caminho, о", rus: "дорога, путь" },
  { port: "camioneta, а", rus: "автобус" },
  { port: "camisa, а", rus: "рубашка" },
  { port: "camisola, а", rus: "свитер" },
  { port: "fazer campismo", rus: "ходитьв поход" },
  { port: "campo, о", rus: "поле" },
  { port: "canção, а", rus: "песня" },
  { port: "cancelado", rus: "отменён" },
  { port: "cancelar", rus: "отменить" },
  { port: "candeeiro, о", rus: "светильник" },
  { port: "caneta, а", rus: "ручка" },
  { port: "cansado", rus: "усталый" },
  { port: "cansativo", rus: "утомительный" },
  { port: "cantar", rus: "петь" },
  { port: "cantina, а", rus: "столовая" },
  { port: "cantor, о", rus: "певец" },
  { port: "сãо, о", rus: "собака" },
  { port: "capital, а", rus: "столица" },
  { port: "саrа, а", rus: "лицо" },
  { port: "carácter, о", rus: "характер" },
  { port: "саrеса, о", rus: "лысый" },
  { port: "caril, о", rus: "карри" },
  { port: "саriоса, о", rus: "житель Рио-де-Жанейро" },
  { port: "саrnе, а", rus: "мясо" },
  { port: "саrо", rus: "дорогой" },
  { port: "саrrеgаdоr, о", rus: "зарядное устройство" },
  { port: "саrrеgаr (em)", rus: "нажать (на)" },
  { port: "саrrеirа, а", rus: "карьера" },
  { port: "саrrо, о", rus: "машина" },
  { port: "carruagem, а", rus: "вагон" },
  { port: "саrtа, а", rus: "письмо" },
  { port: "саrtа de condução, а", rus: "права" },
  { port: "саrtãо de embarque, о", rus: "посадочный талон" },
  { port: "cartão de visito,о", rus: "визитная карточка" },
  { port: "carteira, а", rus: "коwелёк" },
  { port: "casa, а", rus: "дом" },
  { port: "casa de banho. а", rus: "туалет" },
  { port: "casaco, о", rus: "куртка,  пиджак" },
  { port: "casado", rus: "женатый" },
  { port: "casal, о", rus: "пара" },
  { port: "саsаr (com)", rus: "жениться/выйти замуж" },
  { port: "castanho", rus: "коричневый" },
  { port: "castelo, о", rus: "замок" },
  { port: "catorze", rus: "четырнадцать" },
  { port: "роr causa de", rus: "из-за" },
  { port: "andar а cavalo", rus: "ездить на лошади" },
  { port: "caves de vinho, as", rus: "винные погреба" },
  { port: "cedo", rus: "рано" },
  { port: "cem/cento", rus: "сто" },
  { port: "centenas, as", rus: "сотни" },
  { port: "cêntimo, о", rus: "цент" },
  { port: "centro comercial, о", rus: "торговый центр" },
  { port: "centro, о", rus: "центр" },
  { port: "сегса de", rus: "около" },
  { port: "cereais, os", rus: "хлопья" },
  { port: "сегеjа, а", rus: "черешня" },
  { port: "com/de certeza", rus: "конечно" },
  { port: "certo", rus: "правильно" },
  { port: "cerveja, а", rus: "пиво" },
  { port: "céu, о", rus: "небо" },
  { port: "chá, о", rus: "чай" },
  { port: "chamada (de telefone), а", rus: "звонок (телефонный)" },
  { port: "chamar-se", rus: "звать" },
  { port: "chão, о", rus: "пол" },
  { port: "chapéu de chuva, о", rus: "зонт" },
  { port: "chato", rus: "скучный" },
  { port: "chave, а", rus: "ключ" },
  { port: "chávena, а", rus: "чашка" },
  { port: "chefe, о/а", rus: "wеф" },
  { port: "chegada (do aviao), а", rus: "nрилет, посадка самолета" },
  { port: "chegar", rus: "приходить" },
  { port: "cheio", rus: "nолный" },
  { port: "chinelos, os", rus: "таnки" },
  { port: "chinês", rus: "китайский, китаец" },
  { port: "chocolate, о", rus: "шоколад" },
  { port: "chorar", rus: "плакать" },
  { port: "chover", rus: "идёт дождь" },
  { port: "chumbar (numexame)", rus: "провалить (экзамен)" },
  { port: "chuva, а", rus: "дождь" },
  { port: "chuveiro, о", rus: "душ" },
  { port: "cidade, а", rus: "город" },
  { port: "cigarro, о", rus: "сигарета" },
  { port: "em cima(de)", rus: "на" },
  { port: "cinco", rus: "пять" },
  { port: "cinema, о", rus: "кино" },
  { port: "cinquenta", rus: "пятьдесят" },
  { port: "cinto(de segurança), о", rus: "ремень (безопасности)" },
  { port: "cinzento", rus: "серый" },
  { port: "claro", rus: "светлый" },
  { port: "Claro!", rus: "Конечно!" },
  { port: "classe, а", rus: "класс" },
  { port: "(musica) clássica, а", rus: "классическая (музыка)" },
  { port: "cliente, о/а", rus: "клиент" },
  { port: "clima, о", rus: "климат" },
  { port: "clube (de futebol), о", rus: "(футбольный) клуб" },
  { port: "agua de сосо, а", rus: "кокосовая вода" },
  { port: "código, о", rus: "nин-код" },
  { port: "código postal, о", rus: "почтовый индекс" },
  { port: "coisa, а", rus: "вещь" },
  { port: "Coitado!", rus: "Бедненький!" },
  { port: "colega, о/а", rus: "коллега" },
  { port: "colher, а", rus: "ложка" },
  { port: "colina, а", rus: "холм" },
  { port: "colocar", rus: "положить" },
  { port: "соm", rus: "c" },
  { port: "Está combinado!", rus: "Договорились!" },
  { port: "comboio, о", rus: "поезд" },
  { port: "começar", rus: "начинать" },
  { port: "comer", rus: "есть" },
  { port: "comércio, о", rus: "торговля" },
  { port: "comida, а", rus: "еда" },
  { port: "comissário, о", rus: "бортпроводник" },
  { port: "como", rus: "как" },
  { port: "А como е?", rus: "По чём...?" },
  { port: "companhia, а", rus: "компания" },
  { port: "companhia aérea, а", rus: "авиакомпания" },
  { port: "completamente", rus: "полностью" },
  { port: "nome completo, о", rus: "полное имя" },
  { port: "compositor, о", rus: "композитор" },
  { port: "comprar", rus: "покупать" },
  { port: "compras, as", rus: "покупки" },
  { port: "Nao compreendo!", rus: "Не понимаю!" },
  { port: "comprido", rus: "длинный" },
  { port: "comprimido, о", rus: "таблетка" },
  { port: "computador, о", rus: "компьютер" },
  { port: "concerto, о", rus: "концерт" },
  { port: "concorrente,о/а", rus: "конкурент" },
  { port: "concurso, о", rus: "конкурс" },
  { port: "conduzir", rus: "водить (машину)" },
  { port: "confortável", rus: "Ьequem" },
  { port: "congelado", rus: "(tief-)gefroren" },
  { port: "conhecer", rus: "знать,познакомиться" },
  { port: "conhecimento, о", rus: "знание" },
  { port: "conjunto, о", rus: "набор," },
  { port: "conseguir", rus: "получаться, мочь" },
  { port: "conselho, о", rus: "совет" },
  { port: "constipado", rus: "простуда" },
  { port: "consulta (médica),", rus: "консультация (медицинская)" },
  { port: "conta, а", rus: "счёт" },
  { port: "conta (bancária),", rus: "счёт банковский" },
  { port: "tomar conta", rus: "присматривать" },
  { port: "contar", rus: "рассказать" },
  { port: "continuar", rus: "продолжать" },
  { port: "contornar", rus: "объехать" },
  { port: "conversa, а", rus: "беседа" },
  { port: "conversar", rus: "разговаривать" },
  { port: "convidado, о", rus: "приглашённый, гость" },
  { port: "convidar", rus: "приглашать" },
  { port: "convite, о", rus: "приглашение" },
  { port: "соро, о", rus: "стакан" },
  { port: "cor, а", rus: "цвет" },
  { port: "согaçãо, о", rus: "сердце" },
  { port: "coreano", rus: "Корея" },
  { port: "corpo, о", rus: "тело" },
  { port: "corredor, о", rus: "коридор" },
  { port: "correio, о", rus: "почта" },
  { port: "correios, os", rus: "почта" },
  { port: "correr", rus: "бегать" },
  { port: "correto", rus: "правильно" },
  { port: "costa, а", rus: "побережье" },
  { port: "costas, as", rus: "спина" },
  { port: "costumar", rus: "обычно (что-то делать)" },
  { port: "costumes, os", rus: "обычай, привычка" },
  { port: "cozer", rus: "варить" },
  { port: "cozido", rus: "варёный" },
  { port: "cozinha, а", rus: "кухня" },
  { port: "cozinhar", rus: "готовить" },
  { port: "cozinheiro, о", rus: "повар" },
  { port: "criança, a", rus: "ребёнок" },
  { port: "croissant (simples),", rus: "круассан" },
  { port: "cruzamento, о", rus: "nерекрёсток" },
  { port: "cruzeiro, о", rus: "круиз" },
  { port: "Cuidado!", rus: "Осторожно!" },
  { port: "cultura", rus: "культура" },
  { port: "curso, o", rus: "курс" },
  { port: "curto", rus: "короткий" },
  { port: "custar", rus: "стоить" },
  { port: "dançar", rus: "танцевать" },
  { port: "daqui а", rus: "через" },
  { port: "dar", rus: "дать" },
  { port: "data de nascimento, а", rus: "датa рождения" },
  { port: "debaixo (de)", rus: "nод" },
  { port: "decidir", rus: "решить" },
  { port: "décimo", rus: "десятый" },
  { port: "dесогаçãо, а", rus: "дизайн" },
  { port: "decorar", rus: "украшать" },
  { port: "dedo, о", rus: "nалец" },
  { port: "deitar-se", rus: "ложиться сnать, лечь" },
  { port: "deixar (algo/alguem)", rus: "бросать, оставлять" },
  { port: "deixar (de fazer algo)", rus: "бросить (что-то делать)" },
  { port: "dele/dela", rus: "его, её" },
  { port: "deles/delas", rus: "ИХ" },
  { port: "demorar", rus: "занимать время" },
  { port: "dente, о", rus: "зуб" },
  { port: "dentro (de)", rus: "в" },
  { port: "depender (de)", rus: "зависеть (от)" },
  { port: "depois (de)", rus: "после" },
  { port: "depositar dinheiro", rus: "внести деньги на счёт" },
  { port: "desagradável", rus: "неприятный" },
  { port: "desarrumado", rus: "неприбранный" },
  { port: "descalçar", rus: "разуться" },
  { port: "descansar", rus: "отдохнуть" },
  { port: "descer (as escadas)", rus: "спуститься (по лестнице)" },
  { port: "descobrir", rus: "сделать открытие, узнавать" },
  { port: "desconfortável", rus: "неудобный" },
  { port: "desconto, о", rus: "скидка" },
  { port: "Desculpe!", rus: "Извините!" },
  { port: "pedir desculpa рог", rus: "просить прощения за" },
  { port: "desde", rus: "с" },
  { port: "deserto, о", rus: "nустыня" },
  { port: "desligar", rus: "выключить" },
  { port: "despachar-se", rus: "поторопиться" },
  { port: "despedir-se", rus: "попрощаться" },
  { port: "despertador, о", rus: "будильник" },
  { port: "despir", rus: "снять (одежду)" },
  { port: "despir-se", rus: "раздеться" },
  { port: "desporto, о", rus: "спорт" },
  { port: "detestar", rus: "ненавидеть" },
  { port: "devagar", rus: "медленно" },
  { port: "dever", rus: "должен, наверно" },
  { port: "devolver", rus: "возвращать" },
  { port: "dez", rus: "десять" },
  { port: "dezanove", rus: "девятнадцать" },
  { port: "dezasseis", rus: "шестнадцать" },
  { port: "dezassete", rus: "семнадцать" },
  { port: "dezembro", rus: "декабрь" },
  { port: "dezenas, as", rus: "десятки" },
  { port: "dezoito", rus: "восемнадцать" },
  { port: "dia, о", rus: "день" },
  { port: "Воm dia!", rus: "Добрый день!" },
  { port: "dia da semana, о", rus: "день недели" },
  { port: "Dia das Bruxos, о", rus: "Хеллоуин" },
  { port: "diariamente", rus: "ежедневно" },
  { port: "dicionário, о", rus: "словарь" },
  { port: "dieta, а", rus: "диета" },
  { port: "diferença. О", rus: "разница" },
  { port: "diferente", rus: "другой, отличаться" },
  { port: "difícil", rus: "трудный" },
  { port: "dinheiro, о", rus: "деньги" },
  { port: "Direito, о", rus: "Право" },
  { port: "direito", rus: "nравый" },
  { port: "á direita", rus: "сnрава, наnраво" },
  { port: "disco, о", rus: "диск" },
  { port: "discoteca, а", rus: "дискотека" },
  { port: "discutir", rus: "сnорить, ругаться" },
  { port: "distância, а", rus: "расстояние" },
  { port: "divertido", rus: "забавный" },
  { port: "divertir-se", rus: "развлекаться" },
  { port: "divisão (da casa), а", rus: "комната" },
  { port: "divorciado", rus: "разведённый" },
  { port: "divorciar-se", rus: "развестись" },
  { port: "divórcio, о", rus: "развод" },
  { port: "dizer", rus: "говорить, сказать" },
  { port: "Como se diz...?", rus: "Как сказать...?" },
  { port: "Diga!", rus: "Говори!" },
  { port: "Diga? •", rus: "Что-что?" },
  { port: "doce", rus: "сладкий" },
  { port: "doce (de laranja), о", rus: "варенье (апельсиновое)" },
  { port: "doces, os", rus: "сладости" },
  { port: "doente", rus: "больной" },
  { port: "doer", rus: "болеть" },
  { port: "dois", rus: "два" },
  { port: "domingo, о", rus: "воскресенье" },
  { port: "dona de casa, а", rus: "домохозяйка" },
  { port: "dono, о", rus: "хозяин, владелец" },
  { port: "dor, а", rus: "боль" },
  { port: "dormir", rus: "сnать" },
  { port: "dose, а", rus: "nорция" },
  { port: "doze", rus: "двенадцать" },
  { port: "duas", rus: "две" },
  { port: "tomar duche", rus: "принимать душ" },
  { port: "quarto duplo, о", rus: "двухместный номер" },
  { port: "duração", rus: "продолжительность" },
  { port: "durante", rus: "в течение" },
  { port: "durar", rus: "длиться" },
  { port: "duzentos", rus: "двести" },
  { port: "dúzia, uma", rus: "дюжина" },
  { port: "ediffcio, о", rus: "здание" },
  { port: "ele/ela", rus: "он, она" },
  { port: "elétrico, о", rus: "трамвай" },
  { port: "elevador, о", rus: "лифт" },
  { port: "em", rus: "в, на" },
  { port: "emagrecer", rus: "похудеть" },
  { port: "embaixada, а", rus: "посольство" },
  { port: "embalagem, а", rus: "уnаковка" },
  { port: "embarque, о", rus: "посадка" },
  { port: "ir-se embora", rus: "уходить, уезжать" },
  { port: "embrulhar", rus: "завернуть" },
  { port: "ementa, а", rus: "меню" },
  { port: "emprego, о", rus: "работа" },
  { port: "empregada doméstica, а", rus: "домработница" },
  { port: "empregado de mesa, о", rus: "официант" },
  { port: "empresa, а", rus: "фирма" },
  { port: "empresário, о", rus: "бизнесмен" },
  { port: "emprestar", rus: "одалживать" },
  { port: "Empurre", rus: "От себя" },
  { port: "encerrar", rus: "закрывать" },
  { port: "encomenda, а", rus: "посылка, заказ" },
  { port: "encomendar", rus: "заказать" },
  { port: "encontrar", rus: "найти" },
  { port: "encontrar-se (com)", rus: "встречаться (с)" },
  { port: "encontro, о", rus: "встреча" },
  { port: "enfermeiro, о", rus: "медбрат" },
  { port: "engenheiro, о", rus: "инженер" },
  { port: "engordar", rus: "толстеть" },
  { port: "enorme", rus: "огромный" },
  { port: "ensinar", rus: "учить (кого-либо)" },
  { port: "então", rus: "тогда" },
  { port: "entrada, а", rus: "закуска" },
  { port: "entrada, а", rus: "вход" },
  { port: "entrar", rus: "входить" },
  { port: "entre", rus: "между" },
  { port: "entrevista, а", rus: "собеседование" },
  { port: "envelope, о", rus: "конверт" },
  { port: "enviar", rus: "посылать" },
  { port: "errado", rus: "неправильно" },
  { port: "escadas, as", rus: "лестница" },
  { port: "escola, а", rus: "школа" },
  { port: "escolher", rus: "выбрать" },
  { port: "escrever", rus: "написать" },
  { port: "Соmо se escreve?", rus: "Как пишется?" },
  { port: "escritório, о", rus: "офис" },
  { port: "escuro", rus: "тёмный" },
  { port: "espaço, о", rus: "место" },
  { port: "espanhol", rus: "испанский, испанец" },
  { port: "especial", rus: "особенный" },
  { port: "espelho, о", rus: "зеркало" },
  { port: "еsреrаr (роr)", rus: "ждать" },
  { port: "esquadra de polícia, а", rus: "отделение полиции" },
  { port: "esquecer-se (de)", rus: "забывать" },
  { port: "esquerdo", rus: "левый" },
  { port: "á  esquerda", rus: "лево/налево" },
  { port: "fazer esqui", rus: "кататься на лыжах" },
  { port: "esquina, а", rus: "угол" },
  { port: "esse/essa", rus: "этот, эта" },
  { port: "estação de comboios, а", rus: "железнодорожная станция" },
  { port: "estação do ano, а", rus: "время года" },
  { port: "estacionar", rus: "парковать" },
  { port: "estadia, а", rus: "отдых, визит" },
  { port: "Воа estadia!", rus: "Приятного отдыха!" },
  { port: "estádio, о", rus: "стадион" },
  { port: "estado civil, о", rus: "семейное положение" },
  { port: "estante, а", rus: "стеллаж" },
  { port: "estar", rus: "быть, есть" },
  { port: "Como está?", rus: "Как" },
  { port: "Está?/Estou?", rus: "Алло!" },
  { port: "estatísticas, as", rus: "статистика" },
  { port: "este/esta", rus: "этот/эта" },
  { port: "este, o", rus: "восток" },
  { port: "estômago, o", rus: "желудок" },
  { port: "estrada, a", rus: "дорога" },
  { port: "estragar", rus: "испортить" },
  { port: "estrangeiro, o", rus: "заграница" },
  { port: "estrangeiro, o", rus: "иностранец" },
  { port: "estreito", rus: "узкий" },
  { port: "estrela, a", rus: "звезда" },
  { port: "estudante, o/a", rus: "студент" },
  { port: "estudar", rus: "учиться" },
  { port: "estúpido", rus: "estupido" },
  { port: "eu", rus: "я" },
  { port: "euro, o", rus: "евро" },
  { port: "evitar", rus: "избежать" },
  { port: "exagerar", rus: "преувеличивать" },
  { port: "exame, o", rus: "экзамен" },
  { port: "porexemplo", rus: "рог" },
  { port: "exerclcio, o", rus: "упражнение" },
  { port: "existir", rus: "существовать" },
  { port: "êxito, o", rus: "успех" },
  { port: "experiência, a", rus: "опыт" },
  { port: "experimentar(roupa)", rus: "мерить (одежду)" },
  { port: "explorar", rus: "исследовать" },
  { port: "exposição, a", rus: "выставка" },
  { port: "fã, o/a", rus: "поклонник" },
  { port: "faca, a", rus: "нож" },
  { port: "fácil", rus: "лёrкий" },
  { port: "defacto", rus: "на самом деле" },
  { port: "fadista, o/a", rus: "фадист" },
  { port: "falador", rus: "говорун" },
  { port: "falar", rus: "говорить" },
  { port: "faltar", rus: "осталось до" },
  { port: "família,a", rus: "семья" },
  { port: "famoso", rus: "известный" },
  { port: "farmácia, a", rus: "аптека" },
  { port: "fatia, a", rus: "кусок" },
  { port: "fato, o", rus: "костюм" },
  { port: "febre, a", rus: "температура (жар)" },
  { port: "fechado", rus: "закрытый" },
  { port: "fechar", rus: "закрывать" },
  { port: "feijão,o", rus: "фасоль" },
  { port: "feio", rus: "некрасивый" },
  { port: "feliz", rus: "счастливый" },
  { port: "felizmente", rus: " к счастью" },
  { port: "férias, as", rus: "отпуск" },
  { port: "festa", rus: "вечеринка" },
  { port: "fevereiro", rus: "февраль" },
  { port: "fiambre, o", rus: "ветчина" },
  { port: "ficar", rus: "находиться" },
  { port: "ficar (triste)", rus: "стать грустным" },
  { port: "fiel", rus: "верный" },
  { port: "fila, а", rus: "ряд" },
  { port: "filha, а", rus: "дочь" },
  { port: "filho, о", rus: "сын" },
  { port: "filme, о", rus: "фильм" },
  { port: "fim, o", rus: "конец" },
  { port: "fim de semana, о", rus: "выходные дни" },
  { port: "finalmente", rus: "в конце концов" },
  { port: "flor, а", rus: "цветок" },
  { port: "floresta, а", rus: "лес" },
  { port: "fogão, o", rus: "кухонная плита" },
  { port: "estar com fome", rus: "бытьголодным" },
  { port: "fora (de)", rus: "за, снаружи" },
  { port: "formação, a", rus: "образование" },
  { port: "formulário, о", rus: "формуляр" },
  { port: "forno, о", rus: "духовка" },
  { port: "forte", rus: "сильный" },
  { port: "fotografia, а", rus: "фотография" },
  { port: "fraco", rus: "слабый" },
  { port: "francês", rus: "французский, француз" },
  { port: "frango, о", rus: "цыплёнок" },
  { port: "frasco, о", rus: "банка" },
  { port: "frequente ", rus: "частый" },
  { port: "frequentemente", rus: "часто" },
  { port: "fresco", rus: "свежий" },
  { port: "água fresca", rus: "холодная вода" },
  { port: "frigorífico, о", rus: "холодильник" },
  { port: "frio", rus: "холодный" },
  { port: "estar com frio", rus: "(Мне) холодно" },
  { port: "estar/fazer frio", rus: "ХОЛОДНО" },
  { port: "frito", rus: "жареный" },
  { port: "fronteira, а", rus: "граница" },
  { port: "fruta, а", rus: "фрукты" },
  { port: "fugir", rus: "убежать" },
  { port: "fumado", rus: "копчёный" },
  { port: "fumar", rus: "курить" },
  { port: "funcional", rus: "функциональный" },
  { port: "funcionar", rus: "работать" },
  { port: "funcionario, о", rus: "служащий" },
  { port: "ао fundo (de)", rus: "в глубине, в конце" },
  { port: "furioso", rus: "разъярённый" },
  { port: "futebol, о", rus: "футбол" },
  { port: "gabinete de prova, о", rus: "примерочная" },
  { port: "galeriade arte, а", rus: "художественная галерея" },
  { port: "ganhar", rus: "выиграть, зарабатывать" },
  { port: "garagem, a", rus: "гараж" },
  { port: "garfo, o", rus: "вилка" },
  { port: "garganta, a", rus: "горло" },
  { port: "gastar", rus: "тратить" },
  { port: "gato, о", rus: "кошка" },
  { port: "gelado, о", rus: "мороженое" },
  { port: "gelo, о", rus: "лёд" },
  { port: "gente, а", rus: "люди, мы" },
  { port: "toda а gente", rus: "все" },
  { port: "Gestão, а", rus: "Управление" },
  { port: "ginásio, о", rus: "спортзал" },
  { port: "girafa, а", rus: "жираф" },
  { port: "giro", rus: "мило" },
  { port: "gordo", rus: "толстый" },
  { port: "gostar", rus: "нравиться" },
  { port: "gostos, os", rus: "вкусы" },
  { port: "Muito gosto!", rus: "Очень приятно!" },
  { port: "grama, о", rus: "грамм" },
  { port: "grande ", rus: "большой" },
  { port: "gratuito", rus: "бесплатный" },
  { port: "grau, о", rus: "градус" },
  { port: "gravar", rus: "записать" },
  { port: "gravata, а", rus: "галстук" },
  { port: "grave", rus: "серьезный" },
  { port: "grávida", rus: "беременная" },
  { port: "grego", rus: "грек" },
  { port: "grelhado", rus: "жареный на гриле" },
  { port: "gripe, а", rus: "грипп" },
  { port: "gritar", rus: "кричать" },
  { port: "guardanapo, о", rus: "салфетка" },
  { port: "guardar", rus: "сохранить, положить" },
  { port: "guerra, а", rus: "война" },
  { port: "guia, о", rus: "путеводитель" },
  { port: "guia, о/а", rus: "гид" },
  { port: "guitarra, а", rus: "гитара" },
  { port: "guloso", rus: "сластёна" },
  { port: "hábitos, os", rus: "привычки" },
  { port: "haver", rus: "иметь" },
  { port: "história, a", rus: "история" },
  { port: "histórico", rus: "исторический" },
  { port: "hoje", rus: "сегодня" },
  { port: "holandês", rus: "голландец" },
  { port: "homem, о", rus: "мужчина" },
  { port: "hora, а", rus: "час" },
  { port: "horário, о", rus: "расписание" },
  { port: "horrível", rus: "ужас" },
  { port: "hóspede,", rus: "гость" },
  { port: "hospital, о", rus: "больница" },
  { port: "hotel, о", rus: "гостиница" },
  { port: "húmido", rus: "влажный" },
  { port: "húngaro", rus: "венгр" },
  { port: "ida е volta", rus: "туда и  обратно" },
  { port: "idade, а", rus: "возраст" },
  { port: "Воа ideia!", rus: "Отличная идея!" },
  { port: "igreja, а", rus: "церковь" },
  { port: "igual", rus: "одинаковый" },
  { port: "igualmente", rus: "также" },
  { port: "ilha, а", rus: "остров" },
  { port: "imediatamente", rus: "сразу же" },
  { port: "imediato", rus: "мгновенный" },
  { port: "imenso", rus: "много" },
  { port: "agência imobiliária, а", rus: "агентство недвижимости" },
  { port: "agenteimobiliário, о", rus: "риэлтор" },
  { port: "impaciente", rus: "нетерпеливый" },
  { port: "imperial, а", rus: "стакан пива" },
  { port: "importante", rus: "важный" },
  { port: "importar-se (de)", rus: "обращать внимание на (что-либо) " },
  { port: "impresso, о", rus: "бланк" },
  { port: "imprimir", rus: "распечатать" },
  { port: "tudo incluído", rus: "всё включено" },
  { port: "incómodo, о", rus: "беспокойство" },
  { port: "indeciso", rus: "нерешительный" },
  { port: "indiano", rus: "индийский, индус" },
  { port: "quarto individual, о", rus: "одноместный номер" },
  { port: "infeliz", rus: "несчастный" },
  { port: "infelizmente", rus: "к сожалению" },
  { port: "informação, а", rus: "информация" },
  { port: "informático, о", rus: "информатик" },
  { port: "inglês", rus: "английский, англичанин" },
  { port: "ingrediente, о", rus: "ингредиент" },
  { port: "início, о", rus: "начало" },
  { port: "inquilino, о", rus: "квартиросъёмщик" },
  { port: "inserir", rus: "ввести" },
  { port: "instrumento, О", rus: "инструмент" },
  { port: "instrutor, о", rus: "инструктор" },
  { port: "inteligente", rus: "умный" },
  { port: "interdito", rus: "запрещённый" },
  { port: "interessante", rus: "интересный" },
  { port: "tеr interesse (em)", rus: "интересоваться" },
  { port: "inletnacional", rus: "интернациональный" },
  { port: "inútil", rus: "бесполезный" },
  { port: "Que invejal", rus: "Везёт же некоторым!" },
  { port: "inverno, о", rus: "зима" },
  { port: "iogurte, о", rus: "йогурт" },
  { port: "iг", rus: "идти, ехать" },
  { port: "irlandês", rus: "ирландский, ирландец" },
  { port: "irmã, а", rus: "сестра" },
  { port: "irmão, о", rus: "брат" },
  { port: "isso", rus: "это" },
  { port: "isto", rus: "это" },
  { port: "italiano", rus: "итальянский, итальянец" },
  { port: "já", rus: "уже" },
  { port: "Ate já!", rus: "До скорого!" },
  { port: "já agora", rus: "кстати" },
  { port: "janeiro", rus: "январь" },
  { port: "janela, а", rus: "окно" },
  { port: "janeiro", rus: "январь" },
  { port: "jantar", rus: "ужинать" },
  { port: "jantar fога", rus: "ужинать в ресторане" },
  { port: "jantar, о", rus: "ужин" },
  { port: "japonês", rus: "японский, японец" },
  { port: "jardim, о", rus: "сад" },
  { port: "jardim zoológico, о", rus: "зоопарк" },
  { port: "jogar", rus: "играть" },
  { port: "jogo, о", rus: "игра" },
  { port: "jogo de futebol, о", rus: "футбольный матч" },
  { port: "jornal, о", rus: "газета" },
  { port: "jornalista. о/а", rus: "журналист" },
  { port: "jovem", rus: "молодой" },
  { port: "julho", rus: "июль" },
  { port: "junho", rus: "июнь" },
  { port: "juntar", rus: "добавить" },
  { port: "juntos", rus: "вместе" },
  { port: "lá", rus: "там" },
  { port: "ао lado (de)", rus: "около" },
  { port: "рог todo о lado", rus: "везде" },
  { port: "lago, о", rus: "озеро" },
  { port: "lapis, о", rus: "карандаш" },
  { port: "laranja, а", rus: "апельсин" },
  { port: "largo", rus: "широкий" },
  { port: "lata, а", rus: "банка" },
  { port: "lavaг", rus: "мьrrь" },
  { port: "legumes, os", rus: "овощи" },
  { port: "leite, о", rus: "МОЛОКО" },
  { port: "lembrar-se (de)", rus: "помнить" },
  { port: "lento", rus: "медленный" },
  { port: "ler", rus: "читать" },
  { port: "letra, а", rus: "буква" },
  { port: "letra, а", rus: "слова" },
  { port: "levantar dinheiro", rus: "снять деньги" },
  { port: "levantar voo", rus: "взлететь" },
  { port: "levantar-se", rus: "вставать" },
  { port: "levar", rus: "взять, принести" },
  { port: "рага levar", rus: "с собой" },
  { port: "leve", rus: "легкий" },
  { port: "Com licença!", rus: "Разрешите!" },
  { port: "ligar", rus: "Включить" },
  { port: "ligar", rus: "позвонить" },
  { port: "limão, о", rus: "ЛИМОН" },
  { port: "limpar", rus: "чистить" },
  { port: "limpo", rus: "чистый" },
  { port: "lindo", rus: "красивый" },
  { port: "língua, а", rus: "язык" },
  { port: "linha, а", rus: "путь" },
  { port: "lisboeta", rus: "лиссабонец" },
  { port: "livraria, а", rus: "книжный магазин" },
  { port: "livre", rus: "свободный" },
  { port: "livro, о", rus: "книга" },
  { port: "lixo, о", rus: "мусор" },
  { port: "local de trabalho, о", rus: "место работы" },
  { port: "localização", rus: "местонахождение" },
  { port: "logo", rus: "скоро" },
  { port: "Até logo!", rus: "До скорого!" },
  { port: "loiro/louro", rus: "блондин" },
  { port: "loja, а", rus: "магазин" },
  { port: "longe", rus: "далеко" },
  { port: "longo", rus: "длинный" },
  { port: "ао longo de", rus: "вдоль" },
  { port: "lua, a", rus: "луна" },
  { port: "loiça/louça, a", rus: "посуда" },
  { port: "lugar, о", rus: "место" },
  { port: "lume, о", rus: "огонь" },
  { port: "luz, а", rus: "свет" },
  { port: "mãe, а", rus: "мать" },
  { port: "magro", rus: "худой" },
  { port: "maio", rus: "май" },
  { port: "mais", rus: "ещё" },
  { port: "mais ou menos", rus: "более-менее" },
  { port: "Mais alguma coisa?", rus: "Что-нибудь ещё?" },
  { port: "mal", rus: "плохо" },
  { port: "mala, а", rus: "сумка" },
  { port: "mala, а", rus: "чемодан" },
  { port: "fazer а mala", rus: "собирать чемодан" },
  { port: "mal-educado", rus: "невоспитанный" },
  { port: "maluco", rus: "сумасшедший" },
  { port: "mandar", rus: "посылать" },
  { port: "maneira, а", rus: "манера, способ" },
  { port: "de manhã", rus: "утром" },
  { port: "manteiga, а", rus: "масло" },
  { port: "em manutenção", rus: "на техническом обслуживании" },
  { port: "mão, а", rus: "рука (кисть)" },
  { port: "mapa, о", rus: "карта" },
  { port: "máquina de lavar loiça, а", rus: "посудомоечная машина" },
  { port: "máquina de lavar roupa, а", rus: "стиральная машина" },
  { port: "máquina fotográfica, а", rus: "фотоаппарат" },
  { port: "mar, о", rus: "море" },
  { port: "marcar uma consulta", rus: "назначить консультацию" },
  { port: "hora marcado, о", rus: "назначенное время" },
  { port: "março", rus: "март" },
  { port: "margem, а", rus: "берег" },
  { port: "marido, о", rus: "муж" },
  { port: "marroquino", rus: "марокканский, марокканец" },
  { port: "mas", rus: "но" },
  { port: "matar", rus: "убить" },
  { port: "mau/má", rus: "плохой/плохая" },
  { port: "máximo", rus: "максимальный" },
  { port: "medicamento, о", rus: "лекарство" },
  { port: "Medicino, о", rus: "медицина" },
  { port: "médico, о", rus: "врач" },
  { port: "médio", rus: "средний" },
  { port: "em médio", rus: "в среднем" },
  { port: "ter medo", rus: "бояться" },
  { port: "meia de leite, а", rus: "чашка кофе с молоком" },
  { port: "meia-noite, а", rus: "полночь" },
  {
    port: "meio de transporte, о",
    rus: "средство передвижения, вид транспорта",
  },
  { port: "meio-dia, о", rus: "полдень" },
  { port: "по meio de", rus: "среди" },
  { port: "melhor", rus: "лучше" },
  { port: "As melhoras!", rus: "Выздоравливай(те)!" },
  { port: "menos", rus: "меньше" },
  { port: "mensagem, а", rus: "сообщение" },
  { port: "mercado, о", rus: "базар" },
  { port: "mercearia, а", rus: "бакалея" },
  { port: "mergulhar", rus: "нырнуть" },
  { port: "fazer mergulho", rus: "нырять" },
  { port: "mês, о", rus: "месяц" },
  { port: "mesa, а", rus: "стол" },
  { port: "mesmo, о", rus: "тоже самое" },
  { port: "mesquita, а", rus: "мечеть" },
  { port: "metro, о", rus: "метро" },
  { port: "metro, um", rus: "метр" },
  { port: "meu/minha", rus: "мой/моя" },
  { port: "mexicano", rus: "мексиканский, мексиканец" },
  { port: "mil", rus: "тысяча" },
  { port: "milhão", rus: "миллион" },
  { port: "milhares, os", rus: "тысячи" },
  { port: "mina, а", rus: "шахта" },
  { port: "minuto, о", rus: "минута" },
  { port: "miradouro, о", rus: "смотровая площадка" },
  { port: "misturar", rus: "смешать" },
  { port: "miúdo, о", rus: "мальчик" },
  { port: "mobllia, а", rus: "мебель" },
  { port: "mochila, а", rus: "рюкзак" },
  { port: "moderno", rus: "современный" },
  { port: "moeda, а", rus: "монета" },
  { port: "molhado", rus: "мокрый" },
  { port: "molho, о", rus: "соус" },
  { port: "momento, о", rus: "секундочка" },
  { port: "montanha, а", rus: "гора" },
  { port: "morada, а", rus: "адрес" },
  { port: "moradia, а", rus: "коттедж" },
  { port: "morar", rus: "жить" },
  { port: "moreno", rus: "смуглый" },
  { port: "morrer", rus: "умереть" },
  { port: "morte, а", rus: "смерть" },
  { port: "mostrar", rus: "показать" },
  { port: "motivo, о", rus: "причина" },
  { port: "móvel, о", rus: "мебель" },
  { port: "mudar (de)", rus: "менять" },
  { port: "mudar-se", rus: "переселиться" },
  { port: "muito", rus: "много" },
  { port: "mulher, а", rus: "жена" },
  { port: "mulher, а", rus: "женщина" },
  { port: "multa, а", rus: "штраф" },
  { port: "multibanco, о", rus: "банкомат" },
  { port: "mundial", rus: "мировой" },
  { port: "mundo, о", rus: "мир" },
  { port: "museu, о", rus: "музей" },
  { port: "música, а", rus: "музыка" },
  { port: "nacionalidade,а", rus: "гражданство" },
  { port: "nada", rus: "ничего,ничто" },
  { port: "Dе nada!", rus: "Не за что!" },
  { port: "Nada disso!", rus: "Ничего подобного!" },
  { port: "nadar", rus: "плавать" },
  { port: "namorado, о", rus: "(мой) друг, парень" },
  { port: "namorar", rus: "встречаться" },
  { port: "não", rus: "нет" },
  { port: "nariz, о", rus: "нос" },
  { port: "nascer", rus: "родиться" },
  { port: "nascimento, о", rus: "рождение" },
  { port: "Natal, о", rus: "Рождество" },
  { port: "natas, as", rus: "сливки" },
  { port: "água natural", rus: "вода комнатной темnературы" },
  { port: "natureza, а", rus: "природа" },
  { port: "negócio, о", rus: "бизнес" },
  { port: "nem", rus: "ни" },
  { port: "Nem pensar!", rus: "Ни за что!" },
  { port: "nenhum", rus: "никакой" },
  { port: "neto, о", rus: "внук" },
  { port: "nevar", rus: "снегопад" },
  { port: "neve, а", rus: "снег" },
  { port: "nevoeiro, о", rus: "туман" },
  { port: "ninguém", rus: "никто" },
  { port: "noite, а", rus: "ночь" },
  { port: "á noite", rus: "ночью" },
  { port: "Воа noite!", rus: "Добрый вечер!" },
  { port: "nome, о", rus: "имя" },
  { port: "nono", rus: "девятый" },
  { port: "normal", rus: "нормальный, обычный" },
  { port: "normalmente", rus: "обычно" },
  { port: "norte, о", rus: "север" },
  { port: "nós", rus: "мы" },
  { port: "nosso", rus: "наш" },
  { port: "nota, а", rus: "банкнота" },
  { port: "notícia, а", rus: "новость" },
  { port: "nove", rus: "девять" },
  { port: "novecentos", rus: "девятьсот" },
  { port: "novembro", rus: "ноябрь" },
  { port: "noventa", rus: "девяносто" },
  { port: "novo", rus: "НОВЫЙ,МОЛОДОЙ" },
  { port: "número, о", rus: "номер" },
  { port: "nunca", rus: "никогда" },
  { port: "nuvem, а", rus: "туча, облако" },
  { port: "Estamos em оЬгаs.", rus: "На ремонте." },
  { port: "0brigado!", rus: "Спасибо!" },
  { port: "obviamente", rus: "очевидно" },
  { port: "ocasião, а", rus: "случай" },
  { port: "oceanário, о", rus: "океанариум" },
  { port: "oceano, о", rus: "океан" },
  { port: "óculos, os", rus: "очки" },
  { port: "óculos de sol, os", rus: "солнечные очки" },
  { port: "ocupado", rus: "занят" },
  { port: "oeste, о", rus: "запад" },
  { port: "оfегесег", rus: "подарить" },
  { port: "oferta, а", rus: "подарок" },
  { port: "oitavo", rus: "восьмой" },
  { port: "oitenta", rus: "восемьдесят" },
  { port: "oito", rus: "восемь" },
  { port: "oitocentos", rus: "восемьсот" },
  { port: "Olá!", rus: "Привет!" },
  { port: "olhar", rus: "смотреть" },
  { port: "olho, о", rus: "глаз" },
  { port: "onde", rus: "где" },
  { port: "ontem", rus: "вчера" },
  { port: "onze", rus: "одиннадцать" },
  { port: "orelha. а", rus: "ухо" },
  { port: "organizado", rus: "дисциплинированный" },
  { port: "Ótimo!", rus: "Отлично!" },
  { port: "ou", rus: "или" },
  { port: "outono, о", rus: "осень" },
  { port: "outro", rus: "другой" },
  { port: "outubro", rus: "октябрь" },
  { port: "ouvido, о", rus: "ухо" },
  { port: "ouvir", rus: "слушать" },
  { port: "ovo, о", rus: "яйцо" },
  { port: "pacote. о", rus: "пачка" },
  { port: "padaria, а", rus: "булочная" },
  { port: "pagar", rus: "платить" },
  { port: "página, а", rus: "страница" },
  { port: "Pai Natal, о", rus: "Дед Мороз" },
  { port: "pai, о", rus: "отец" },
  { port: "pais, os", rus: "родители" },
  { port: "país, о", rus: "страна" },
  { port: "paisagem, а", rus: "пейзаж" },
  { port: "palacio, о", rus: "дворец" },
  { port: "palco, о", rus: "сцена" },
  { port: "рãо, o", rus: "хлеб" },
  { port: "Рaрa,о", rus: "папа" },
  { port: "papagaio, о", rus: "попугай" },
  { port: "papelaria, а", rus: "магазин канцелярских товаров" },
  { port: "раrа", rus: "для, в" },
  { port: "Parabéns!", rus: "Поздравления!" },
  { port: "paragem, а", rus: "остановка" },
  { port: "parar", rus: "останоаиться" },
  { port: "parecer", rus: "казаться" },
  { port: "parede, а", rus: "стена" },
  { port: "parque, о", rus: "парк" },
  { port: "parque de estacionamento, о", rus: "парковка" },
  { port: "parque nacional, о", rus: "национальный парк" },
  { port: "parte, а", rus: "часть" },
  { port: "partida, а", rus: "отnравление, вылет" },
  { port: "partir", rus: "отправляться" },
  { port: "partir а perna", rus: "сломать ногу" },
  { port: "Páscoa, о", rus: "Пасха" },
  { port: "passageiro, о", rus: "пассажир" },
  { port: "passaporte, о", rus: "паспорт" },
  { port: "passar tempo", rus: "проводить" },
  { port: "passar рог", rus: "пройти/проехать мимо" },
  { port: "passar а ferro", rus: "гладить" },
  { port: "passar (num exame)", rus: "сдать (экзамен)" },
  { port: "passear", rus: "гулять" },
  { port: "passeio, о", rus: "тротуар" },
  { port: "passeio, о", rus: "прогулка" },
  { port: "pasta, а", rus: "папка" },
  { port: "pastel de nata, о", rus: "корзиночки с заварным кремом" },
  { port: "pastelaria, а", rus: "кондитерская" },
  { port: "pavilhão, о", rus: "павильон" },
  { port: "рé, о", rus: "нога (ступня)" },
  { port: "a рé", rus: "пешком" },
  { port: "реça, а", rus: "пьеса" },
  { port: "реçа, а", rus: "место" },
  { port: "pedaço, um", rus: "кусок" },
  { port: "pedir", rus: "просить" },
  { port: "pedir emprestado", rus: "брать взаймы" },
  { port: "pegar", rus: "взять" },
  { port: "peito, о", rus: "грудь" },
  { port: "peixe, о", rus: "рыба" },
  { port: "pelo menos", rus: "по крайней мере" },
  { port: "Que репа!", rus: "Как жаль!" },
  { port: "pensar", rus: "думать" },
  { port: "pequeno", rus: "маленький" },
  { port: "pequeno-almoço, о", rus: "завтрак" },
  { port: "perceber", rus: "понимать" },
  { port: "Nãо percebo!", rus: "Не понимаю!" },
  { port: "perder", rus: "потерять" },
  { port: "perder (о comboio)", rus: "опоздать (на поезд)" },
  { port: "estar perdido", rus: "потеряться" },
  { port: "perfeito", rus: "идеальный" },
  { port: "perfume, о", rus: "духи" },
  { port: "pergunta, а", rus: "вопрос" },
  { port: "perguntar", rus: "спрашивать" },
  { port: "perigoso", rus: "опасный" },
  { port: "perna, а", rus: "нога" },
  { port: "perto (de)", rus: "около" },
  { port: "pesado", rus: "тяжёлый" },
  { port: "pesar", rus: "взвесить" },
  { port: "pescoço, о", rus: "шея" },
  { port: "pessoa, а", rus: "человек" },
  { port: "pessoal, о", rus: "компания" },
  { port: "pessoalmente", rus: "лично" },
  { port: "piano, о", rus: "фортепиано" },
  { port: "picante", rus: "острый (перец)" },
  { port: "pilha, а", rus: "батарейка" },
  { port: "pintar", rus: "красить" },
  { port: "pintar (о cabelo)", rus: "красить (волосы)" },
  { port: "pintor, о", rus: "художник" },
  { port: "piranha, а", rus: "пиранья" },
  { port: "piscina, а", rus: "бассейн" },
  { port: "Piso molhado!", rus: "Мокрый пол!" },
  { port: "planear", rus: "планировать" },
  { port: "plano, о", rus: "план" },
  { port: "planta, а", rus: "растение, цветок" },
  { port: "plástico", rus: "пластиковый" },
  { port: "рó, о", rus: "пыль" },
  { port: "pobre", rus: "бедный" },
  { port: "poder", rus: "мочь" },
  { port: "Pois (е)!", rus: "Точно!" },
  { port: "Pois não?", rus: "Не так ли?" },
  { port: "polaco", rus: "польский, поляк" },
  { port: "politica, а", rus: "политика" },
  { port: "politico", rus: "политический" },
  { port: "poluição, а", rus: "загрязнение" },
  { port: "poivo, о", rus: "осьминог" },
  { port: "ponte, а", rus: "мост" },
  { port: "popular", rus: "популярный" },
  { port: "рõr", rus: "положить/класть" },
  { port: "рõr а mesa", rus: "накрыть на стол" },
  { port: "рог dia", rus: "в день" },
  { port: "рог semana", rus: "в неделю" },
  { port: "рог isso", rus: "поэтому" },
  { port: "саrпе de porco", rus: "свинина" },
  { port: "porque", rus: "потому что" },
  { port: "Porquê?", rus: "Почему?" },
  { port: "рогtа, а", rus: "дверь" },
  { port: "português", rus: "португальский,португалец" },
  { port: "possivel", rus: "возможный" },
  { port: "postal, о", rus: "открытка" },
  { port: "pouco", rus: "мало" },
  { port: "um pouco (de)", rus: "немного" },
  { port: "poupar", rus: "экономить" },
  { port: "praça, а", rus: "площадь" },
  { port: "praia, а", rus: "пляж" },
  { port: "prático", rus: "практичный" },
  { port: "prato, о", rus: "блюдо" },
  { port: "prato, о", rus: "тарелка" },
  { port: "Muito prazer!", rus: "Очень приятно!" },
  { port: "precisar (de)", rus: "нуждаться,требоваться" },
  { port: "É preciso...", rus: "Нужно..." },
  { port: "рreçо, о", rus: "цена" },
  { port: "prédio, о", rus: "здание" },
  { port: "preencher", rus: "заполнить" },
  { port: "preferido", rus: "предпочтительный" },
  { port: "preferir", rus: "предпочитать" },
  { port: "preguiçoso", rus: "ленивый" },
  { port: "prenda, а", rus: "подарок" },
  { port: "preocupado", rus: "обеспокоенный" },
  { port: "preocupar-se", rus: "беспокоиться" },
  { port: "рrераrаr", rus: "подготовить" },
  { port: "presente, о", rus: "подарок" },
  { port: "ter/estar сот pressa", rus: "торопиться" },
  { port: "preto", rus: "чёрный" },
  { port: "previsão (de tempo) a", rus: "прогноз (погоды)" },
  { port: "primavera, а", rus: "весна" },
  { port: "primeiro", rus: "первый" },
  { port: "Primeiro....", rus: "Сначала..." },
  { port: "primo, о", rus: "двоюродный брат" },
  { port: "principalmente", rus: "главным образом" },
  { port: "problema, о", rus: "проблема" },
  { port: "Nãо há ргoblеmа!", rus: "Без проблем!" },
  { port: "procurar", rus: "искать" },
  { port: "produto de beleza, о", rus: "косметические товары" },
  { port: "professor, о", rus: "учитель" },
  { port: "profissção, а", rus: "профессия" },
  { port: "programa, о", rus: "программа" },
  { port: "proibldo", rus: "запрещено" },
  { port: "estar pronto", rus: "быть готовым" },
  { port: "Ргопtо!", rus: "Ну да! Хорошо!" },
  { port: "É  рróрriо.", rus: "Это я." },
  { port: "рrоvаr", rus: "пробовать" },
  { port: "provavelmente", rus: "вероятно" },
  { port: "próximo", rus: "следующий" },
  { port: "Puxe", rus: "К себе" },
  { port: "quadrado", rus: "в квадрате" },
  { port: "quadro, о", rus: "картина" },
  { port: "quadro, о", rus: "доска" },
  { port: "qual", rus: "какой" },
  { port: "quando", rus: "когда" },
  { port: "quanto", rus: "сколько" },
  { port: "Quanto é?", rus: "Сколько стоит?" },
  { port: "quarenta", rus: "сорок" },
  { port: "quarta-feira, а", rus: "среда" },
  { port: "quarto", rus: "четвёртый" },
  { port: "quarto de hora, о", rus: "четверть часа" },
  { port: "quarto, о", rus: "комната" },
  { port: "quase", rus: "почти" },
  { port: "quatro", rus: "четыре" },
  { port: "quatrocentos", rus: "четыреста" },
  { port: "(о) que", rus: "что" },
  { port: "que", rus: "что" },
  { port: "queijo, о", rus: "сыр" },
  { port: "quem", rus: "кто" },
  { port: "quente", rus: "горячий" },
  { port: "querer", rus: "хотеть" },
  { port: "Queria...", rus: "Хотел(а) бы..." },
  { port: "quer dizer", rus: "то есть" },
  { port: "querido", rus: "милый, дорогой" },
  { port: "quilo, о", rus: "килограмм" },
  { port: "quilómetro, о", rus: "километр" },
  { port: "quinhentos", rus: "пятьсот" },
  { port: "quinta-feira, а", rus: "четверг" },
  { port: "quinto", rus: "пятый" },
  { port: "quinze", rus: "пятнадцать" },
  { port: "quiosque, о", rus: "киоск" },
  { port: "rádio, о", rus: "радио" },
  { port: "rapariga, а", rus: "девушка" },
  { port: "rapaz, о", rus: "парень" },
  { port: "rapidamente", rus: "быстро" },
  { port: "rápido", rus: "быстрый" },
  { port: "raramente", rus: "редко" },
  { port: "receber", rus: "получать" },
  { port: "receção, а", rus: "стойка регистрации" },
  { port: "receita (culinária), а", rus: "рецепт (кулинарный)" },
  { port: "receita (médica), а", rus: "рецепт (медицинский)" },
  { port: "recomendar", rus: "посоветовать" },
  { port: "rede, а", rus: "связь, сеть" },
  { port: "refeição, а", rus: "приёмпищи" },
  { port: "reformado", rus: "пенсионер" },
  { port: "reformar-se", rus: "выйти на пенсию" },
  { port: "regar", rus: "поливать" },
  { port: "regressar", rus: "возвращаться" },
  { port: "regresso, о", rus: "возвращение" },
  { port: "regularmente", rus: "регулярно" },
  { port: "relatório, о", rus: "отчёт" },
  { port: "relógio, о", rus: "часы" },
  { port: "de repente", rus: "вдруг, внезапно" },
  { port: "repetir", rus: "повторить" },
  { port: "rés do chão, о", rus: "цокольный этаж" },
  { port: "reserva, а", rus: "бронь, заказ" },
  { port: "Reservado а residentes", rus: "только для резидентов" },
  { port: "responder", rus: "ответить" },
  { port: "resposta, а", rus: "ответ" },
  { port: "restaurante, о", rus: "ресторан" },
  { port: "revista, а", rus: "журнал" },
  { port: "rico", rus: "богатый" },
  { port: "rio, о", rus: "река" },
  { port: "rir", rus: "смеяться" },
  { port: "romance, о", rus: "роман" },
  { port: "romântico", rus: "романтичный" },
  { port: "rotunda, а", rus: "кольцо (на дороге)" },
  { port: "roubar", rus: "воровать, украсть" },
  { port: "roupa, а", rus: "одежда" },
  { port: "rua, а", rus: "улица" },
  { port: "ruivo", rus: "рыжий" },
  { port: "russo", rus: "русский" },
  { port: "sábado, о", rus: "суббота" },
  { port: "saber", rus: "знать" },
  { port: "Não sei", rus: "Не знаю" },
  { port: "saboroso", rus: "вкусный" },
  { port: "saco, о", rus: "пакет" },
  { port: "saia, а", rus: "юбка" },
  { port: "saída de emergência, а", rus: "аварийный выход" },
  { port: "sair", rus: "выходить" },
  { port: "sal, о", rus: "соль" },
  { port: "sala de aula, а", rus: "класс" },
  { port: "salade conferência, а", rus: "конференц-зал" },
  { port: "sala de espera, а", rus: "зал ожидания" },
  { port: "sala de estar, а", rus: "гостиная" },
  { port: "salada, а", rus: "салат" },
  { port: "saldos, os", rus: "скидки" },
  { port: "salgado", rus: "солёный" },
  { port: "salmão, о", rus: "лосось" },
  { port: "salsicha, а", rus: "сосиска" },
  { port: "sandes, а", rus: "бутерброд" },
  { port: "sapataria, а", rus: "обувной магазин" },
  { port: "sapato, о", rus: "ботинок" },
  { port: "sardinha, а", rus: "сардина" },
  { port: "sardinhada, а", rus: "сардины на гриле" },
  { port: "satisfeito", rus: "удовлетворённый" },
  { port: "ter saudades", rus: "скучать" },
  { port: "saudável", rus: "здоровый" },
  { port: "saúde, а", rus: "здоровье" },
  { port: "sесçãо, а", rus: "секция" },
  { port: "seco", rus: "сухой" },
  { port: "secretária, а", rus: "письменный стол" },
  { port: "secretária, а", rus: "секретарь" },
  { port: "século, о", rus: "век" },
  { port: "estar com/ter sede", rus: "хотеть nить" },
  { port: "seguinte", rus: "следующий" },
  { port: "seguir (em frente)", rus: "идти, ехать (прямо)" },
  { port: "А seguir, ...", rus: "Потом..." },
  { port: "segunda-feira, а", rus: "понедельник" },
  { port: "segundo", rus: "второй" },
  { port: "segundo, о", rus: "секунда" },
  { port: "seguro", rus: "неопасный" },
  { port: "seis", rus: "шесть" },
  { port: "seiscentos", rus: "шестьсот" },
  { port: "selo, о", rus: "марка" },
  { port: "sem", rus: "без" },
  { port: "semáforo, о", rus: "светофор" },
  { port: "semana, а", rus: "неделя" },
  { port: "semiequipodo", rus: "частично меблированная" },
  { port: "sempre", rus: "всегда" },
  { port: "senha, а", rus: "талон" },
  { port: "senhor, о", rus: "господин" },
  { port: "senhora, а", rus: "госпожа" },
  { port: "sentar-se", rus: "сесть, садиться" },
  { port: "estar sentado", rus: "сидеть" },
  { port: "sentir-se", rus: "чувствовать" },
  { port: "conta separada, а", rus: "отдельный счёт" },
  { port: "ser", rus: "быть" },
  { port: "а sério", rus: "серьёзно" },
  { port: "fога de serviço", rus: "не работает" },
  { port: "servir", rus: "подавать, обслуживать" },
  { port: "sessenta", rus: "шестьдесят" },
  { port: "sete", rus: "семь" },
  { port: "setecentos", rus: "семьсот" },
  { port: "setembro", rus: "сентябрь" },
  { port: "setenta", rus: "семьдесят" },
  { port: "sétimo", rus: "седьмой" },
  { port: "seu/sua", rus: "свой/своя" },
  { port: "sexta-feira, а", rus: "пятница" },
  { port: "sexto", rus: "шестой" },
  { port: "О que significa?", rus: "Что это значит?" },
  { port: "simpático", rus: "приятный (вобхождении)" },
  { port: "simples", rus: "простой" },
  { port: "simplesmente", rus: "просто" },
  { port: "situação, а", rus: "ситуация" },
  { port: "só", rus: "только" },
  { port: "sobre", rus: "о" },
  { port: "sobremesa, а", rus: "десерт" },
  { port: "sobretudo", rus: "особенно" },
  { port: "sociável", rus: "общительный" },
  { port: "sofá, о", rus: "диван" },
  { port: "sogra, а", rus: "тёща, свекровь" },
  { port: "sol, о", rus: "солнце" },
  { port: "estar sol", rus: "солнечно" },
  { port: "solteiro", rus: "холостой" },
  { port: "solução, а", rus: "решение" },
  { port: "estar com/ter sono", rus: "хотеться спать" },
  { port: "som, o", rus: "звук" },
  { port: "sopa, а", rus: "суп" },
  { port: "sorrir", rus: "улыбаться" },
  { port: "Воа sorte!", rus: "Удачи!" },
  { port: "sozinho", rus: "один" },
  { port: "stresse, о", rus: "стресс" },
  { port: "subir (as escadas)", rus: "подниматься (no лестнице)" },
  { port: "sudeste, о", rus: "ЮГО·ВОСТОК" },
  { port: "sueco", rus: "шведский, швед" },
  { port: "sujo", rus: "грязный" },
  { port: "sul, о", rus: "юг" },
  { port: "sumo, о", rus: "сок" },
  { port: "supermercado, o", rus: "супермаркет" },
  { port: "Que tal...?", rus: "Как на счёт...?" },
  { port: "talento,o", rus: "талант" },
  { port: "talheres,os", rus: "столовые приборы" },
  { port: "talho, o", rus: "мясной магазин" },
  { port: "talvez", rus: "может быть" },
  { port: "tamanho,o", rus: "размер" },
  { port: "também ", rus: "тоже" },
  { port: "tanto", rus: "столько" },
  { port: "Tanto faz!", rus: "Все равно" },
  { port: "Há tanto tempo!", rus: "Сколько лет, сколько зим" },
  { port: "Tão (bonito)!", rus: "Такой красивый" },
  { port: "tão...como...", rus: "такой же … как" },
  { port: "tapar", rus: "закрыть" },
  { port: "tapete, o", rus: "ковёр" },
  { port: "tarde", rus: "день" },
  { port: "à tarde", rus: "поздно, днём" },
  { port: "Boa tarde!", rus: "Добрый день" },
  { port: "tarefas domésticas,", rus: "домашние дела" },
  { port: "táxi, o", rus: "такси" },
  { port: "teatro, o", rus: "театр" },
  { port: "teimoso", rus: "упрямый" },
  { port: "teleférico,o", rus: "канатная дорога" },
  { port: "telefonar", rus: "позвонить" },
  { port: "telefone, o", rus: "телефон" },
  { port: "telemóvel, o", rus: "сотовый телефон" },
  { port: "televisão, a", rus: "телевизор" },
  { port: "temperatura, a", rus: "температура" },
  { port: "tempo, o", rus: "время" },
  { port: "tempo, o", rus: "погода" },
  { port: "ténis, o", rus: "теннис" },
  { port: "ténis, os", rus: "кеды, кроссовки" },
  { port: "tentar", rus: "пытаться" },
  { port: "ter", rus: "иметь" },
  { port: "ter de/que", rus: "нужно" },
  { port: "terça-feira, a", rus: "вторник" },
  { port: "terceiro", rus: "третий" },
  { port: "terminal, o", rus: "терминал" },
  { port: "terminar", rus: "заканчивать" },
  { port: "terra, a", rus: "земля" },
  { port: "terraço, o", rus: "терраса" },
  { port: "teu/tua", rus: "твой, твоя" },
  { port: "tia, a", rus: "тётя" },
  { port: "tio, o", rus: "дядя" },
  { port: "tímido ", rus: "скромный" },
  { port: "vinho tinto, o", rus: "красное вино" },
  { port: "típico ", rus: "типичный" },
  { port: "tipo,o", rus: "тип" },
  { port: "tirar ", rus: "достать, снимать" },
  { port: "tirar fotos", rus: "сделать фото" },
  { port: "título,o", rus: "название" },
  { port: "tocar", rus: "играть" },
  { port: "tocar (guitarra)", rus: "играть на гитаре" },
  { port: "todo", rus: "всё" },
  { port: "todos", rus: "все" },
  { port: "tomar", rus: "принимать," },
  { port: "tomar café", rus: "nить кофе" },
  { port: "tomate, о", rus: "помидор" },
  { port: "torrada, а", rus: "гренки" },
  { port: "tоrrе, а", rus: "башня" },
  { port: "tosse, а", rus: "кашель" },
  { port: "tosta (mista), а", rus: "тостс ветчиной  и сыром" },
  { port: "trabalhador", rus: "трудолюбивый" },
  { port: "trabalhar", rus: "работать" },
  { port: "trabalho, о", rus: "работа" },
  { port: "tranquilo", rus: "спокойный" },
  { port: "transferência, а", rus: "перевод" },
  { port: "trânsito, о", rus: "дорожное движение" },
  { port: "transporte, о", rus: "транспорт" },
  { port: "trazer", rus: "принести" },
  { port: "très", rus: "три" },
  { port: "treze", rus: "тринадцать" },
  { port: "trezentos", rus: "триста" },
  { port: "trinta", rus: "тридцать" },
  { port: "triste", rus: "грустный" },
  { port: "trocar", rus: "обменять" },
  { port: "troco, о", rus: "сдача" },
  { port: "t-shirt, а", rus: "футболка" },
  { port: "tu", rus: "ты" },
  { port: "tudo", rus: "всё" },
  { port: "Tudo bеm?", rus: "орошо?" },
  { port: "turco", rus: "турецкий, турок" },
  { port: "turismo, о", rus: "туризм" },
  { port: "turista, о/а", rus: "турист" },
  { port: "turma, а", rus: "класс" },
  { port: "ucraniano", rus: "украинский, украинец" },
  { port: "último", rus: "последний" },
  { port: "um", rus: "один" },
  { port: "União Europeia, а", rus: "Европейский союз" },
  { port: "único", rus: "единственный" },
  { port: "universidade, а", rus: "университет" },
  { port: "urso, о", rus: "медведь" },
  { port: "usar", rus: "использовать" },
  { port: "útil", rus: "полезный" },
  { port: "utilizador, о", rus: "пользователь" },
  { port: "vaca, а", rus: "корова" },
  { port: "varanda, а", rus: "балкон" },
  { port: "vários", rus: "несколько" },
  { port: "vazio", rus: "пустой" },
  { port: "vegetariano, о", rus: "вегетарианец" },
  { port: "velho", rus: "старый" },
  { port: "vender", rus: "продавать" },
  { port: "vento, о", rus: "ветер" },
  { port: "estar/fazer vento", rus: "ветренно" },
  { port: "ver", rus: "видеть, смотреть" },
  { port: "verão, о", rus: "лето" },
  { port: "verdade, а", rus: "правда" },
  { port: "verde", rus: "зелёный" },
  { port: "vermelho", rus: "красный" },
  { port: "vestido, о", rus: "платье" },
  { port: "vestir", rus: "надевать" },
  { port: "vestir-se", rus: "одеваться" },
  { port: "estar vestido(com)", rus: "быть одеть,м (в)" },
  { port: "vez, а", rus: "раз" },
  { port: "ás vezes", rus: "иногда" },
  { port: "viagem, а", rus: "поездка" },
  { port: "Воа viagem!", rus: "Приятного пути!" },
  { port: "viajar", rus: "путешествовать" },
  { port: "vida, а", rus: "жизнь" },
  { port: "video, о", rus: "видео" },
  { port: "vinho, о", rus: "вино" },
  { port: "vinte", rus: "двадцать" },
  { port: "vir", rus: "приходить,приезжать" },
  { port: "virar", rus: "поворачивать" },
  { port: "visita, а", rus: "посещение" },
  { port: "visitar", rus: "посещать" },
  { port: "vista, а", rus: "вид" },
  { port: "viver", rus: "жить" },
  { port: "vizinho, о", rus: "сосед" },
  { port: "você", rus: "вы" },
  { port: "vocês", rus: "вы" },
  { port: "рог volta de", rus: "около" },
  { port: "olhar ет volta", rus: "оглядеться" },
  { port: "voltar", rus: "вернуться" },
  { port: "voltar а ligar", rus: "позвонить ещё раз" },
  { port: "voo, о", rus: "рейс" },
  { port: "vosso", rus: "ваш" },
  { port: "voz, а", rus: "голос" },
  { port: "xadrez, о", rus: "шахматы" },
  { port: "xarope, о", rus: "сироп" },
  { port: "zangado", rus: "злой" },
  { port: "zего", rus: "ноль" },
  { port: "zona, а", rus: "зона" },
  { port: "África", rus: "Африка" },
  { port: "Africa do Sul, а", rus: "Южная Африка" },
  { port: "Alemanha, а", rus: "Германия" },
  { port: "América Latina. а", rus: "Латинская Америка" },
  { port: "Amesterdão", rus: "Амстердам" },
  { port: "Angola", rus: "Ангола" },
  { port: "Argentina. а", rus: "Аргентина" },
  { port: "Ásia, а", rus: "Азия" },
  { port: "Atenas", rus: "Афины" },
  { port: "Austraia, а", rus: "Австралия" },
  { port: "Brasil, о", rus: "Бразилия" },
  { port: "Budapeste", rus: "Будапешт" },
  { port: "Cairo, о", rus: "Каир" },
  { port: "Canadá, о", rus: "Канада" },
  { port: "Cantão", rus: "Гуанчжоу" },
  { port: "China, а", rus: "Китай" },
  { port: "Cidade do СаЬо, а", rus: "Кейптаун" },
  { port: "Colónia", rus: "Кёльн" },
  { port: "Coreia do Sul, а", rus: "Южная Корея" },
  { port: "Espanha, а", rus: "Испания" },
  { port: "Estados Unidos, os", rus: "Соединённые Штаты" },
  { port: "Estocolmo", rus: "Стокгольм" },
  { port: "Europa, а", rus: "Европа" },
  { port: "Filipinas, as", rus: "Филиппины" },
  { port: "Finlândia. а", rus: "Финляндия" },
  { port: "França, а", rus: "Франция" },
  { port: "Grã-Bretanha, а", rus: "Великобритания" },
  { port: "Grécia, а", rus: "Греция" },
  { port: "Helsínquia", rus: "Хельсинки" },
  { port: "Holanda, а", rus: "Голландия" },
  { port: "Índia, а", rus: "Индия" },
  { port: "lnglaterra, а", rus: "Англия" },
  { port: "lrlanda. а", rus: "Ирландия" },
  { port: "ltália, а", rus: "Италия" },
  { port: "Japão, о", rus: "Япония" },
  { port: "Líbano. о", rus: "Ливан" },
  { port: "Usboa", rus: "Лиссабон" },
  { port: "Londres", rus: "Лондон" },
  { port: "Macau", rus: "Макао" },
  { port: "Maldivas, as", rus: "Мальдивы" },
  { port: "Marrocos", rus: "Марокко" },
  { port: "México, о", rus: "Мексика" },
  { port: "Milão", rus: "Милан" },
  { port: "MongóIia, а", rus: "Монголия" },
  { port: "Moscovo", rus: "Москва" },
  { port: "Noruega, а", rus: "Норвегия" },
  { port: "Nova lorque", rus: "Нью-Йорк" },
  { port: "Pequim", rus: "Пекин" },
  { port: "Polónia, а", rus: "Польша" },
  { port: "Portugal", rus: "Португалия" },
  { port: "Roma", rus: "Рим" },
  { port: "Rússia, а", rus: "Россия" },
  { port: "Suécia, а", rus: "Швеция" },
  { port: "Suiça, а", rus: "Швейцария" },
  { port: "Tóquio", rus: "Токио" },
  { port: "Turquia. а", rus: "Турция" },
  { port: "Ucrânia. а", rus: "Украина" },
  { port: "Varsóvia", rus: "Варшава" },
  { port: "Veneza", rus: "Венеция" },
  { port: "Xangai", rus: "Шанхай" },
];
