import styles from "./card.module.css";
import likei from "../../images/like.png";
import likeactive from "../../images/likeactive.png";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { NavLink } from "react-router-dom";

type Tprops = {
  name: string;
  avatar: string;
  content: {
    name: string;
    link: string;
  }[];
};
const pVariants_2 = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};
const pVariants_3 = {
  hidden: {
    width: 0,
    opacity: 0,
  },
  visible: {
    width: 320,
    opacity: 1,
  },
};

const Card = (props: Tprops) => {
  const [active, setactive] = useState(false);
  return (
    <div className={styles.main}>
      {!active && (
        <AnimatePresence>
          <motion.div
            key={1}
            className={styles.card}
            initial={"hidden"}
            animate={"visible"}
            transition={{
              delay: 0.3,
            }}
            variants={pVariants_3}
            onClick={() => {
              setactive(true);
            }}
            whileHover={{ scale: 1.2 }}
          >
            <motion.img
              className={styles.image}
              src={props.avatar}
              alt={"dog"}
            />
            <div className={styles.text}>{props.name}</div>
            <div className={styles.like}></div>
          </motion.div>
        </AnimatePresence>
      )}
      {active && (
        <AnimatePresence>
          <motion.div
            key={2}
            className={styles.card}
            initial={"hidden"}
            animate={"visible"}
            transition={{
              delay: 0.3,
            }}
            variants={pVariants_3}
            onClick={() => {
              setactive(false);
            }}
          >
            {props.content.map((item, index) => {
              return (
                <NavLink to={item.link} className={styles.children} key={index}>
                  {item.name}
                </NavLink>
              );
            })}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default Card;
//{ok && <div className={styles.textform}>{oktext}</div>}
/*<motion.div
            className={styles.card}
            onClick={() => {
              setactive(false);
            }}
          >
            <img className={styles.image} src={props.avatar} alt={"dog"} />
            <div className={styles.text}>{props.name}</div>
            <div className={styles.like}></div>
          </motion.div>*/
