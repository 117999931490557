import { useState } from "react";
import styles from "./sentensconsthard.module.css";
import { divid, randomInteger } from "../../utils/convert";
import dog from "../../images/smile.png";

type Twww = {
  port: string;
  rus: string;
};

type Tone = {
  sone: string;
  id: number;
  order: number;
};

type TOrderypeprops = {
  slovo: Twww[];
  trans: string;
};

function SentensСonstHard(props: TOrderypeprops) {
  const list = props.slovo;
  const [slovo, setSlovo] = useState(list[0]);
  const [liston, setListon] = useState(divid(slovo.port));
  const [liston_2, setListon_2] = useState(
    divid(list[0].port + " " + list[1].port)
  );
  const [ok, setOk] = useState(false);
  const [sentenser, setSentenser] = useState(slovo.rus);
  const [cardon, setCardon] = useState(liston[0]);
  const [listof, setListof] = useState<Tone[]>([
    {
      sone: "Перенесите нужные слова сюда",
      id: 0,
      order: 0,
    },
  ]);
  /********************************************************* */
  const [{ dx, dy }, setOffset] = useState({
    dx: 0,
    dy: 0,
  });

  const [startPos, setStartPos] = useState({
    x: 0,
    y: 0,
  });
  const [endPos, setEndPos] = useState({
    x: 0,
    y: 0,
  });
  const [div_r, setdiv] = useState<Element | null>(null);
  type Tmap = {
    div: HTMLElement;
    card: string;
    x: number;
    y: number;
    heigth: number;
    wigth: number;
    order: number;
  };
  const [zam, setZam] = useState<Tmap | null>(null);
  const [map, setMap] = useState<Tmap[] | null>();
  const [mapb, setMapb] = useState<Tmap | null>();
  const [mapn, setMapn] = useState<Tmap | null>();
  //***************************************************** */
  const touchStartHendler = (
    e: React.TouchEvent<HTMLDivElement>,
    card: Tone
  ) => {
    // console.log("start touch1");
    setCardon(card);
    const touch = e.touches[0];
    const startP = {
      x: touch.clientX,
      y: touch.clientY,
    };

    setStartPos(startP);
    setEndPos(startP);
    const dx = 0;
    const dy = 0;
    setOffset({ dx, dy });
    const container_2 = document.getElementById("www");
    if (container_2 !== null) {
      const map_b2 = {
        div: container_2,
        card: "b",
        x: container_2.getBoundingClientRect().x,
        y: container_2.getBoundingClientRect().y,
        heigth: container_2.getBoundingClientRect().height,
        wigth: container_2.getBoundingClientRect().width,
        order: 1,
      };
      if (map_b2) {
        setMapn(map_b2);
      }
    }
    let list_s;
    if (container_2) {
      list_s = container_2.querySelectorAll("div");
    }

    if (list_s) {
      const list_p: Tmap[] = [];
      list_s.forEach((item, indexOf) => {
        const map_1 = {
          div: item,
          card: item.id,
          x: item.getBoundingClientRect().x,
          y: item.getBoundingClientRect().y,
          heigth: item.getBoundingClientRect().height,
          wigth: item.getBoundingClientRect().width,
          order: indexOf,
        };
        list_p.push(map_1);
      });
      //    console.log(list_p);
      setMap(list_p);
    }
  };
  //***************************************************************************************** */

  const touchStartHendler_2 = (
    e: React.TouchEvent<HTMLDivElement>,
    card: Tone
  ) => {
    //    console.log("start touch2");
    setCardon(card);
    const touch = e.touches[0];
    const startP = {
      x: touch.clientX,
      y: touch.clientY,
    };

    setStartPos(startP);
    setEndPos(startP);
    const dx = 0;
    const dy = 0;
    setOffset({ dx, dy });
    const container = document.getElementById("box");
    const container_2 = document.getElementById("www");

    if (container !== null) {
      const map_b1 = {
        div: container,
        card: "b",
        x: container.getBoundingClientRect().x,
        y: container.getBoundingClientRect().y,
        heigth: container.getBoundingClientRect().height,
        wigth: container.getBoundingClientRect().width,
        order: 1,
      };
      if (map_b1) {
        setMapb(map_b1);
      }
    }

    if (container_2 !== null) {
      const map_b2 = {
        div: container_2,
        card: "b",
        x: container_2.getBoundingClientRect().x,
        y: container_2.getBoundingClientRect().y,
        heigth: container_2.getBoundingClientRect().height,
        wigth: container_2.getBoundingClientRect().width,
        order: 1,
      };
      if (map_b2) {
        setMapn(map_b2);
      }
    }

    let list_s;
    if (container_2) {
      list_s = container_2.querySelectorAll("div");
    }

    if (list_s) {
      const list_p: Tmap[] = [];
      list_s.forEach((item, indexOf) => {
        const map_1 = {
          div: item,
          card: item.id,
          x: item.getBoundingClientRect().x,
          y: item.getBoundingClientRect().y,
          heigth: item.getBoundingClientRect().height,
          wigth: item.getBoundingClientRect().width,
          order: indexOf,
        };
        list_p.push(map_1);
      });

      setMap(list_p);
    }
  };

  //************************************************************************************* */
  const touchMoveHendler = (
    e: React.TouchEvent<HTMLDivElement>,
    card: Tone
  ) => {
    const touch = e.touches[0];
    const div = e.touches[0].target;
    const ele = div as HTMLDivElement;
    // console.log("start touch");
    const dx = touch.clientX - startPos.x;
    const dy = touch.clientY - startPos.y;
    // const ele = eleRef.current;

    // Set the position of element
    if (!ele) {
      return;
    }
    setdiv(ele.offsetParent);
    ele.style.transform = `translate(${dx}px,${dy}px)`;
    setOffset({ dx, dy });
    const endP = {
      x: touch.clientX,
      y: touch.clientY,
    };
    setEndPos(endP);
    if (mapn !== null && mapn) {
      if (
        touch.clientX > mapn.x &&
        touch.clientX < mapn.x + mapn.wigth &&
        touch.clientY > mapn.y &&
        touch.clientY < mapn.y + mapn.heigth
      ) {
        if (map !== null && map) {
          map.forEach((item) => {
            if (
              touch.clientX > item.x &&
              touch.clientX < item.x + item.wigth &&
              touch.clientY > item.y &&
              touch.clientY < item.y + item.heigth
            ) {
              if (ele === item.div) {
                item.div.style.opacity = "0.6";
              } else {
                item.div.style.backgroundColor = "rgb(3, 149, 160)";
              }
            } else {
              item.div.style.opacity = "1";
              item.div.style.backgroundColor = "aquamarine";
            }
          });
        }
      }
    }
  };
  //*************************************************************************************** */
  const touchEndHendler = (e: React.TouchEvent<HTMLDivElement>, card: Tone) => {
    const ele = e.target;
    const div = ele as HTMLDivElement;

    if (!mapn) {
      return;
    }
    if (!map) {
      return;
    }

    if (
      endPos.x > mapn.x &&
      endPos.x < mapn.x + mapn.wigth &&
      endPos.y > mapn.y &&
      endPos.y < mapn.y + mapn.heigth
    ) {
      // если пустой блок
      let rr = false;
      if (map.length === 0) {
        //    console.log("еще ничего нет");
        const result_l = [
          {
            sone: card.sone,
            id: card.id,
            order: 1,
          },
        ];
        rr = true;
        setListof(result_l);
      } else {
        //*если блок ставим в начало
        if (map[0].x > endPos.x) {
          //        console.log("в начале");
          const result = {
            sone: card.sone,
            id: card.id,
            order: 0,
          };
          listof.splice(0, 0, result);
          const result_2 = listof.filter(
            (e) => e.sone !== "Перенесите нужные слова сюда"
          );
          rr = true;
          setListof(result_2);
        } else {
          //*************************************************** */
          //*если блок ставим в конец
          if (map[map.length - 1].x < endPos.x) {
            //           console.log("в конце");
            const result = {
              sone: card.sone,
              id: card.id,
              order: 0,
            };
            listof.push(result);
            const result_2 = listof.filter(
              (e) => e.sone !== "Перенесите нужные слова сюда"
            );
            rr = true;
            setListof(result_2);
          } else {
            let i = 0;

            map.forEach((item) => {
              if (
                endPos.x > item.x &&
                endPos.x < item.x + item.wigth &&
                endPos.y > item.y &&
                endPos.y < item.y + item.heigth
              ) {
                //console.log("замена");
                const result = {
                  sone: card.sone,
                  id: card.id,
                  order: item.order,
                };
                listof.splice(i, 0, result);
                const result_2 = listof.filter(
                  (e) => e.sone !== "Перенесите нужные слова сюда"
                );
                setListof(result_2);
                rr = true;
                //console.log(rr);
              }
              i = i + 1;
            });
          }
        }
        //*************************************** */}
      }
      // удалили из прошлого массива
      if (rr) {
        const list_fin = liston_2.filter((e) => e !== card);
        setListon_2(list_fin);
        div.style.transform = `none`;
      } else {
        div.style.transform = `translate(${0}px,${0}px)`;
      }

      //***************************************** */
    } else {
      div.style.transform = `translate(${0}px,${0}px)`;
    }

    //  console.log(listof);
    //  console.log(liston);

    if (listof.length === liston.length) {
      //console.log("da");
      let rot = true;
      let i = 0;
      listof.map((item) => {
        if (item.sone !== liston[i].sone) {
          //        console.log("sravn");
          //        console.log(item.sone);
          //        console.log(liston[i].sone);
          rot = false;
        }
        i = i + 1;
      });

      setOk(rot);
    }
    setOffset({
      dx: 0,
      dy: 0,
    });

    setStartPos({
      x: 0,
      y: 0,
    });
    setEndPos({
      x: 0,
      y: 0,
    });
    setdiv(null);
    setMap(null);
    setMapb(null);
    setZam(null);
  };

  //*************************************************************************************** */
  const touchEndHendler_2 = (
    e: React.TouchEvent<HTMLDivElement>,
    card: Tone
  ) => {
    let res: Tone[] | null = null;
    const ele = e.target;
    const div = ele as HTMLDivElement;
    //console.log("end2");
    if (!mapn) {
      return;
    }
    if (!mapb) {
      return;
    }
    if (!map) {
      return;
    }

    if (
      endPos.x > mapb.x &&
      endPos.x < mapb.x + mapb.wigth &&
      endPos.y > mapb.y &&
      endPos.y < mapb.y + mapb.heigth
    ) {
      const list_finv = liston_2.slice();
      list_finv.push({
        sone: card.sone,
        id: card.id,
        order: card.order,
      });
      const list_fin_2 = listof.filter((e) => e.sone !== card.sone);
      res = listof.filter((e) => e.sone !== card.sone);
      setListon_2(list_finv);
      setListof(list_fin_2);
    }

    if (
      endPos.x > mapn.x &&
      endPos.x < mapn.x + mapn.wigth &&
      endPos.y > mapn.y &&
      endPos.y < mapn.y + mapn.heigth
    ) {
      const result_1 = listof.filter((e) => e.sone !== card.sone);
      let rr = false;
      if (map[0].x > endPos.x) {
        //console.log("в начале");
        const result = {
          sone: card.sone,
          id: card.id,
          order: 0,
        };

        result_1.splice(0, 0, result);

        const result_2 = result_1.filter(
          (e) => e.sone !== "Перенесите нужные слова сюда"
        );
        rr = true;
        res = result_1.filter((e) => e.sone !== "Перенесите нужные слова сюда");
        setListof(result_2);
      } else {
        if (map[map.length - 1].x < endPos.x) {
          //console.log("в конце");
          const result = {
            sone: card.sone,
            id: card.id,
            order: 0,
          };
          result_1.push(result);
          const result_2 = result_1.filter(
            (e) => e.sone !== "Перенесите нужные слова сюда"
          );
          rr = true;
          res = result_1.filter(
            (e) => e.sone !== "Перенесите нужные слова сюда"
          );
          setListof(result_2);
        } else {
          let i = 0;

          map.forEach((item) => {
            if (
              endPos.x > item.x &&
              endPos.x < item.x + item.wigth &&
              endPos.y > item.y &&
              endPos.y < item.y + item.heigth
            ) {
              //           console.log("замена");
              const result = {
                sone: card.sone,
                id: card.id,
                order: item.order,
              };
              result_1.splice(i, 0, result);
              rr = true;
              res = result_1.filter(
                (e) => e.sone !== "Перенесите нужные слова сюда"
              );
              //          console.log("pfklklj");
              //         console.log(res);
              setListof(res);
            }
            i = i + 1;
          });
        }
      }
      if (rr) {
        div.style.transform = `none`;
      } else {
        const result = {
          sone: card.sone,
          id: card.id,
          order: card.order,
        };
        result_1.splice(0, 0, result);
        rr = true;
        res = result_1.filter((e) => e.sone !== "Перенесите нужные слова сюда");
        //  console.log("pfklklj");
        // console.log(res);
        setListof(res);
        div.style.transform = `translate(${0}px,${0}px)`;
      }
    } else {
      div.style.transform = `translate(${0}px,${0}px)`;
    }

    // console.log(listof);
    // console.log(liston);
    // console.log(res);
    if (res !== null) {
      setListof(res);
      if (res.length === liston.length) {
        // console.log("da");
        let rot = true;
        let i = 0;
        res.map((item) => {
          //       console.log("sravn");
          //        console.log(item);
          //       console.log(liston[i]);
          if (item.sone !== liston[i].sone) {
            //         console.log("net");
            //         console.log(item);
            //         console.log(liston[i]);
            rot = false;
          }
          i = i + 1;
        });

        setOk(rot);
      }
    }

    setOffset({
      dx: 0,
      dy: 0,
    });

    setStartPos({
      x: 0,
      y: 0,
    });
    setEndPos({
      x: 0,
      y: 0,
    });
    setdiv(null);
    setMap(null);
    setMapb(null);
  };

  //************************************************************ */
  const buttonHandler_1 = () => {
    const cont = randomInteger(0, props.slovo.length - 1);
    const novo = props.slovo[cont];
    setSlovo(novo);
    let novo_2 = props.slovo[0];

    if (cont > 1) {
      novo_2 = props.slovo[cont - 1];
    }
    if (!novo_2) {
      novo_2 = props.slovo[0];
    }
    //console.log("444444444444444");
    //console.log(novo);
    //console.log(novo_2);
    setListof([
      {
        sone: "Перенесите нужные слова сюда",
        id: 0,
        order: 0,
      },
    ]);
    //console.log(novo);
    //console.log(novo_2);

    const slovo_n = divid(novo.port);
    const slovo_ny = divid(novo.port + " " + novo_2.port);
    //console.log("3333333333333333333333");
    //console.log(slovo_n);
    //console.log(slovo_ny);
    setSentenser(novo.rus);
    setListon_2(slovo_ny);
    setOk(false);
    setListon(slovo_n);
    setCardon(liston[0]);
    //console.log("55555555555555");
    //console.log(liston);
    //console.log(slovo);
  };
  //****************************************************************************** */
  function dragStartHendler(e: React.DragEvent<HTMLDivElement>, card: Tone) {
    setCardon(card);
    e.currentTarget.style.boxShadow = "";
  }
  function dragLeaveHendler(e: React.DragEvent<HTMLDivElement>) {
    //e.currentTarget.style.background = "aquamarine";
    e.currentTarget.style.boxShadow = "";
  }
  function dragEndHendler(e: React.DragEvent<HTMLDivElement>, card: Tone) {
    let rot = true;
    //console.log("00000000000000");
    //console.log(liston);
    //console.log(listof);
    listof.sort(sortCards);
    if (liston) {
      if (listof.length !== liston.length) {
        rot = false;
      } else {
        liston.map((item) => {
          if (item.sone !== listof[item.id - 1].sone) {
            rot = false;
            // console.log("111111111111");
            // console.log("22222222222222");
            //console.log(listof[item.order - 1]);
          } else {
            e.currentTarget.style.background = "rgb(22, 230, 160)";
          }
        });
      }
    }
    setOk(rot);
    //e.currentTarget.style.background = "aquamarine";
    e.currentTarget.style.boxShadow = "";
  }

  function dragOverHendler(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.currentTarget.style.boxShadow = "0 5px 5px 5px rgb(93, 89, 153)";
  }

  function dropHendler(e: React.DragEvent<HTMLDivElement>, card: Tone) {
    e.preventDefault();

    if (cardon) {
      setListof(
        listof.map((c) => {
          if (c.id === card.id) {
            return { ...c, order: cardon.order };
          }
          if (c.id === cardon.id) {
            return { ...c, order: card.order };
          }
          return c;
        })
      );
    }
    e.currentTarget.style.boxShadow = " ";
    //console.log(listof);
    e.currentTarget.style.boxShadow = "";
  }
  function dropHendler_2(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();

    const result_l = listof.filter(
      (word) => word.sone !== "Перенесите нужные слова сюда"
    );
    //console.log(listof.indexOf(cardon));
    if (listof.indexOf(cardon) < 0) {
      const result = result_l.filter((word) => word.id !== cardon.id);
      const result_2 = liston_2.filter((word) => word.id !== cardon.id);
      result.push(cardon);

      if (result) {
        setListof(result);
        //console.log(result);
      }
      if (result_2) {
        setListon_2(result_2);
        //console.log(result_2);
      }
    }

    e.currentTarget.style.boxShadow = " ";
  }

  const onClick = () => {
    if (sentenser === slovo.rus) {
      setSentenser(slovo.port);
    } else {
      setSentenser(slovo.rus);
    }
  };

  function dropHendler_3(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    const result = listof.filter((word) => word.id !== cardon.id);

    const result_2 = liston_2.filter((word) => word.id !== cardon.id);

    result_2.push(cardon);

    if (result) {
      setListof(result);
      // console.log(result);
    }
    if (result_2) {
      setListon_2(result_2);
      //console.log(result_2);
    }
    e.currentTarget.style.boxShadow = " ";
  }

  const sortCards = (a: Tone, b: Tone) => {
    if (a.order > b.order) {
      return 1;
    } else {
      return -1;
    }
  };
  //************************************************************************************ */
  return (
    <div className={styles.main}>
      <div
        className={styles.dragof}
        id={"box"}
        onDrop={(e: React.DragEvent<HTMLDivElement>) => dropHendler_3(e)}
        onDragOver={(e: React.DragEvent<HTMLDivElement>) => dragOverHendler(e)}
        onDragLeave={(e: React.DragEvent<HTMLDivElement>) =>
          dragLeaveHendler(e)
        }
      >
        {liston_2.sort(sortCards).map((item) => {
          return (
            <div
              className={styles.menuButton}
              id={item.sone}
              key={item.id}
              draggable={true}
              onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
                dragStartHendler(e, item)
              }
              onDragLeave={(e: React.DragEvent<HTMLDivElement>) =>
                dragLeaveHendler(e)
              }
              onDragEnd={(e: React.DragEvent<HTMLDivElement>) =>
                dragEndHendler(e, item)
              }
              onDragOver={(e: React.DragEvent<HTMLDivElement>) =>
                dragOverHendler(e)
              }
              onTouchStart={(e: React.TouchEvent<HTMLDivElement>) =>
                touchStartHendler(e, item)
              }
              onTouchMove={(e: React.TouchEvent<HTMLDivElement>) =>
                touchMoveHendler(e, item)
              }
              onTouchEnd={(e: React.TouchEvent<HTMLDivElement>) => {
                touchEndHendler(e, item);
              }}
            >
              {item.sone}
            </div>
          );
        })}
      </div>

      <div
        className={styles.dragon}
        id="www"
        onDrop={(e: React.DragEvent<HTMLDivElement>) => dropHendler_2(e)}
        onDragOver={(e: React.DragEvent<HTMLDivElement>) => dragOverHendler(e)}
        onDragLeave={(e: React.DragEvent<HTMLDivElement>) =>
          dragLeaveHendler(e)
        }
      >
        {listof.map((item) => {
          return (
            <div
              style={{
                background: ok === true ? "rgb(22, 230, 160)" : "aquamarine",
              }}
              className={styles.menuButton}
              key={item.id}
              draggable={true}
              onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
                dragStartHendler(e, item)
              }
              onDragLeave={(e: React.DragEvent<HTMLDivElement>) =>
                dragLeaveHendler(e)
              }
              onDragEnd={(e: React.DragEvent<HTMLDivElement>) =>
                dragEndHendler(e, item)
              }
              onDragOver={(e: React.DragEvent<HTMLDivElement>) =>
                dragOverHendler(e)
              }
              onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                dropHendler(e, item)
              }
              onTouchStart={(e: React.TouchEvent<HTMLDivElement>) =>
                touchStartHendler_2(e, item)
              }
              onTouchMove={(e: React.TouchEvent<HTMLDivElement>) =>
                touchMoveHendler(e, item)
              }
              onTouchEnd={(e: React.TouchEvent<HTMLDivElement>) => {
                touchEndHendler_2(e, item);
              }}
            >
              {item.sone}
            </div>
          );
        })}
      </div>

      {ok === true && (
        <div className={styles.preview}>
          <img src={dog} alt="dog" />
        </div>
      )}
      {ok === true && (
        <div className={styles.p_1}>
          <p className={`${styles.menuButton_4} ${styles.menuButton} `}>
            {`Ура, это правильно: ${slovo.port}`}
          </p>
        </div>
      )}
      {props.trans === "Показывать перевод" && (
        <div className={styles.p_1} onClick={onClick}>
          <p className={`${styles.menuButton_3} ${styles.menuButton} `}>
            {sentenser}
          </p>
        </div>
      )}
      <button className={styles.but} onClick={() => buttonHandler_1()}>
        еще...
      </button>
      <div className={`${styles.menuButton_1} ${styles.menuButton} `}>
        Можно увидеть подсказку кликнув на перевод.
      </div>
    </div>
  );
}

export default SentensСonstHard;
