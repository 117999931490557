export const bone = [
  { port: "abandonar ", rus: "отказаться" },
  { port: "abdominal  ", rus: "брюшной" },
  { port: "abertura", rus: "открытие" },
  { port: "aborrecido  ", rus: "скучающий" },
  { port: "abusar ", rus: "злоупотреблять" },
  { port: "acabar por   ", rus: "закончить" },
  { port: "ação, a  ", rus: "действие" },
  { port: "aceitar  ", rus: "принять" },
  { port: "acertar  ", rus: "ударить" },
  { port: "acessível ", rus: "доступный" },
  { port: "acompanhar  ", rus: "сопровождать" },
  { port: "de acordo com ", rus: "в соответствии" },
  { port: "açoriano, o", rus: "Азорский" },
  { port: "acreditar (em)  ", rus: "верить" },
  { port: "acrescentar  ", rus: "добавлять" },
  { port: "adaptação, a", rus: "приспособление" },
  { port: "adepto,  o", rus: "адепт" },
  { port: "adequado  ", rus: "адекватный" },
  { port: "adiar  ", rus: "отложить" },
  { port: "admitir  ", rus: "признавать" },
  { port: "adolescente, o/a ", rus: "подросток " },
  { port: "adormecer ", rus: "засыпать" },
  { port: "afinal ", rus: "заключительный" },
  { port: "aflito  ", rus: "пораженный" },
  { port: "agarrar ", rus: "схватить" },
  { port: "agenda, a", rus: "аренда" },
  { port: "agente, o/a ", rus: "агент" },
  { port: "agente (da polícia), o/a ", rus: "агент полиции" },
  { port: "agradar  ", rus: "доставить удовольствие" },
  { port: "agradecer  ", rus: "благодарить" },
  { port: "agrafador, o", rus: "степлер" },
  { port: "agressivo ", rus: "агрессивный" },
  { port: "agricultor, o  ", rus: "фермер" },
  { port: "aguaceiros, os ", rus: "душ, ливень" },
  { port: "aguardar ", rus: "хранить, ждать" },
  { port: "aguentar ", rus: "держаться" },
  { port: "ainda por cima  ", rus: "более того" },
  { port: "ajudante, o/a  ", rus: "помощник" },
  { port: "alcunha, a  ", rus: "прозвище" },
  { port: "alegria, a ", rus: "аллергия" },
  { port: "além de", rus: "в дополнение к" },
  { port: "alface,  a", rus: "салат, " },
  { port: "aliás ", rus: "действительно" },
  { port: "álibi, o ", rus: "алиби" },
  { port: "alimentos, os  ", rus: "еда" },
  { port: "aliviar  ", rus: "чтобы облегчить" },
  { port: "altitude, a ", rus: "высота" },
  { port: "alto-mar, o  ", rus: "открытое море" },
  { port: "aluguer, o ", rus: "аренда" },
  { port: "amante, o/a ", rus: "любовник " },
  { port: "ambicioso ", rus: "амбициозный" },
  { port: "amizade, a ", rus: "дружба" },
  { port: "análise, a  ", rus: "анализ" },
  { port: "anexo, o  ", rus: "вложение" },
  { port: "animal de estimação, o ", rus: "домашний питомец" },
  { port: "ansioso ", rus: "тревожный" },
  { port: "com antecedência  ", rus: "заранее" },
  { port: "anterior  ", rus: "предыдущий" },
  { port: "antes da hora  ", rus: "раньше времени" },
  { port: "antibiótico, o ", rus: "антибиоик" },
  { port: "anunciar ", rus: "объявить" },
  { port: "aparelho, o ", rus: "устройство," },
  { port: "aperceber-se (de)  ", rus: "осознать  " },
  { port: "apesar de  ", rus: "несмотря на" },
  { port: "apitar ", rus: "сигналить" },
  { port: "apoiar ", rus: "поддерживать" },
  { port: "apoio, o ", rus: "поддержка" },
  { port: "apresentação, a  ", rus: "презентация," },
  { port: "apresentar ", rus: "представлять" },
  { port: "apropriado  ", rus: "соответствующий" },
  { port: "aproveitar  ", rus: "наслаждаться" },
  { port: "aproximar-se (de)  ", rus: "подойти ближе (к чему-л.)" },
  { port: "aquecer  ", rus: "нагревать, нагреть" },
  { port: "aquecimento, o  ", rus: "отопление" },
  { port: "aranha, a ", rus: "паук" },
  { port: "arder  ", rus: "гореть" },
  { port: "areia, a ", rus: "песок" },
  { port: "armazém, o  ", rus: "склад " },
  { port: "arquitetura, a  ", rus: "архитектура" },
  { port: "arranjo, o ", rus: "расположение" },
  { port: "arredores, os  ", rus: "окрестности" },
  { port: "arrendamento, o ", rus: "аренда" },
  { port: "arrendar ", rus: "арендовать" },
  { port: "arrepender-se (de)  ", rus: "сожалеть (о чем-л.)" },
  { port: "artigo, o  ", rus: "статья" },
  { port: "asa, a ", rus: "крыло" },
  { port: "asneira, a  ", rus: "ерунда " },
  { port: "aspeto, o ", rus: "аспект" },
  { port: "aspirador, o  ", rus: "пылесос" },
  { port: "assalto, o ", rus: "нападение" },
  { port: "assassino, o ", rus: "убийца" },
  { port: "assim-assim  ", rus: "так себе" },
  { port: "assim que  ", rus: "как только" },
  { port: "assistência técnica, a ", rus: "техническая поддержка" },
  { port: "assistente de bordo, a ", rus: "стюардесса" },
  { port: "associar ", rus: "соединять" },
  { port: "assunto, o  ", rus: "предмет" },
  { port: "assustado  ", rus: "испуганный" },
  { port: "assustar  ", rus: "пугать" },
  { port: "atacador, o ", rus: "шнурок" },
  { port: "Atenção! ", rus: "внимание" },
  { port: "prestar atenção  ", rus: "обращать внимание" },
  { port: "atendimento, o ", rus: "обслуживание" },
  { port: "atingir  ", rus: "достигать" },
  { port: "atirar  ", rus: "стрелять" },
  { port: "atitude, a ", rus: "отношение" },
  { port: "atividade, a  ", rus: "активность" },
  { port: "atleta, o/a  ", rus: "атлет" },
  { port: "atraente  ", rus: "привлекательный" },
  { port: "através ", rus: "через" },
  { port: "atropelar ", rus: "переехать" },
  { port: "atualmente  ", rus: "в данный момент" },
  { port: "atum, o ", rus: "тунец" },
  { port: "audiência, a  ", rus: "аудитория" },
  { port: "auditório, o  ", rus: "зрительный зал" },
  { port: "aumentar  ", rus: "увеличивать" },
  { port: "ausência, a  ", rus: "отсутствие" },
  { port: "ausente ", rus: "отсутствующий" },
  { port: "autoestrada, a  ", rus: "автострада" },
  { port: "automóvel, o  ", rus: "автомобиль," },
  { port: "avaliação, a  ", rus: "оценка," },
  { port: "ave, a ", rus: "птица," },
  { port: "aventura, a ", rus: "приключение," },
  { port: "azar, o  ", rus: "невезение," },
  { port: "baixar  ", rus: "спускаться" },
  { port: "balão, o  ", rus: "воздушный шар" },
  { port: "balde, o  ", rus: "ведро" },
  { port: "baleia, a ", rus: "кит" },
  { port: "baliza, a  ", rus: "маяк" },
  { port: "balneários, os  ", rus: "раздевалки" },
  { port: "banco, o  ", rus: "банк" },
  { port: "banda sonora, a ", rus: "саундтрек" },
  { port: "banho de imersão, o ", rus: "погружная ванна" },
  { port: "basear(-se) em  ", rus: "основываться на" },
  { port: "basquetebol, o   ", rus: "баскетбол" },
  { port: "bastar ", rus: "достаточно" },
  { port: "bater  ", rus: "бить, стучать" },
  { port: "bateria, a  ", rus: "батарея" },
  { port: "belo ", rus: "красивый" },
  { port: "bem-disposto ", rus: "доброжелательный" },
  { port: "bem-educado  ", rus: "очень вежливо" },
  { port: "Bem feito! ", rus: "Отличная работа!" },
  { port: "bem passado ", rus: "отличная работа" },
  { port: "bisavó, a  ", rus: "прабабушка" },
  { port: "bisavô, o  ", rus: "прадедушка" },
  { port: "boi, o  ", rus: "бык" },
  { port: "bola, a  ", rus: "мяч" },
  { port: "bolsa, a ", rus: "сумка, стипендия" },
  { port: "bolseiro, o  ", rus: "стипендиат" },
  { port: "bombeiro, o ", rus: "пожарный" },
  { port: "borboleta, a  ", rus: "бабочка" },
  { port: "borracha, a ", rus: "стирательная резинка" },
  { port: "borrego, o ", rus: "ягненок" },
  { port: "em breve  ", rus: "вскоре" },
  { port: "brilhante  ", rus: "яркий" },
  { port: "brincar ", rus: "играть" },
  { port: "cabeleireiro, o  ", rus: "парикмахер" },
  { port: "cabo, o  ", rus: "кабель" },
  { port: "cabra, a  ", rus: "козел" },
  { port: "caçar ", rus: "охотиться" },
  { port: "cada  ", rus: "каждый" },
  { port: "cada vez (que)  ", rus: "каждый раз" },
  { port: "calculadora, a  ", rus: "калькулятор" },
  { port: "caldo, o ", rus: "бульон" },
  { port: "calhar  ", rus: "соответствовать, " },
  { port: "calma, a  ", rus: "спокойствие" },
  { port: "camarão. o  ", rus: "креветки" },
  { port: "câmaras de vigilância, as  ", rus: "камеры наблюдения" },
  { port: "camião. o  ", rus: "грузовик" },
  { port: "camionista, o/a ", rus: "водитель грузовика" },
  { port: "campeão, o  ", rus: "чемпион" },
  { port: "canal, o  ", rus: "канал" },
  { port: "canalizador, o  ", rus: "сантехник" },
  { port: "candidatar-se  ", rus: "применять" },
  { port: "capa, a  ", rus: "обложка" },
  { port: "capacidade, a  ", rus: "емкость" },
  { port: "capaz (de)  ", rus: "способность" },
  { port: "capítulo, o  ", rus: "глава" },
  { port: "carácter, o  ", rus: "персонаж" },
  { port: "característica, a", rus: "особенность" },
  { port: "cara-metade, a ", rus: "вторая половинка" },
  { port: "carregador, o  ", rus: "зарядное устройство" },
  { port: "cartão de cidadão, o  ", rus: "карта гражданина" },
  { port: "carteirista, o/a  ", rus: "карманник" },
  { port: "carteiro, o ", rus: "почтальон" },
  { port: "casamento, o  ", rus: "свадьба" },
  { port: "caseiro ", rus: "сделано дома" },
  { port: "no caso de ", rus: "в случае" },
  { port: "caso ,o", rus: "случай" },
  { port: "castanhas, as  ", rus: "каштаны" },
  { port: "cataratas, as", rus: "катаракта" },
  { port: "categoria, a  ", rus: "категория" },
  { port: "caução, a ", rus: "депозит" },
  { port: "cebola, a ", rus: "лук" },
  { port: "celebridade, a ", rus: "знаменитость" },
  { port: "cenoura, a ", rus: "морковь" },
  { port: "chama, a ", rus: "пламя" },
  { port: "chamar ", rus: "звонить" },
  { port: "chapéu ", rus: "шляпа " },
  { port: "chateado ", rus: "расстройство" },
  { port: "chatear  ", rus: "расстраивать" },
  { port: "chatice, a  ", rus: "скука" },
  { port: "Que chatice! ", rus: "как жаль" },
  { port: "chegar à  ", rus: "прибывать" },
  { port: "cheia, a ", rus: "наводнение" },
  { port: "cheirar ", rus: "чувствовать запах" },
  { port: "cheiro, o  ", rus: "запах" },
  { port: "chileno  ", rus: "Чилийский" },
  { port: "cidadão, o ", rus: "гражданин" },
  { port: "ciência, a  ", rus: "наука" },
  { port: "cientista, o/a  ", rus: "ученый" },
  { port: "cigarras, as ", rus: "цикады" },
  { port: "cirurgia, a ", rus: "хирургия" },
  { port: "clara, a ", rus: "белок" },
  { port: "clínica, a  ", rus: "клиника" },
  { port: "cobrar ", rus: "требовать" },
  { port: "cobrir ", rus: "покрывать" },
  { port: "coelho, o ", rus: "кролик" },
  { port: "cogumelo, o  ", rus: "гриб" },
  { port: "coincidência, a ", rus: "совпадение" },
  { port: "cola, a glue cola colle ll'li'K", rus: "сотрудничать" },
  { port: "coleção, a ", rus: "коллекция" },
  { port: "colónia, a  ", rus: "колония" },
  { port: "colorido  ", rus: "цветной" },
  { port: "comando, o  ", rus: "команда" },
  { port: "comédia, a  ", rus: "комедия" },
  { port: "comissário de bordo, o  ", rus: "бортпроводник" },
  { port: "companheiro, o ", rus: "партнер" },
  { port: "comparação com  ", rus: "в сравнении с" },
  { port: "comparar ", rus: "сравнивать" },
  { port: "competência, a ", rus: "компетентность" },
  { port: "completo ", rus: "полный" },
  { port: "complicado ", rus: "сложный" },
  { port: "comportamento, o ", rus: "поведение" },
  { port: "compra, a  ", rus: "покупка" },
  { port: "compreender  ", rus: "понимать" },
  { port: "compreensão, a  ", rus: "понимание" },
  { port: "comprimento, o  ", rus: "длина" },
  { port: "comum ", rus: "общий" },
  { port: "comunicação, a  ", rus: "общение" },
  { port: "comunidade, a  ", rus: "сообщество" },
  { port: "concordar  ", rus: "соглашаться" },
  { port: "concurso, o ", rus: "конкуренция" },
  { port: "condição, a  ", rus: "состояние" },
  { port: "condutor, o  ", rus: "водитель" },
  { port: "conferência, a c ", rus: "конференция" },
  { port: "confessar  ", rus: "признаваться" },
  { port: "confirmar ", rus: "пдтверждать" },
  { port: "confusão, a  ", rus: "путаница, т." },
  { port: "confuso  ", rus: "смущенный" },
  { port: "congelador, o ", rus: "морозильная камера" },
  { port: "conhecimento, o ", rus: "знания" },
  { port: "cônjuge, o  ", rus: "супруга" },
  { port: "constipação, a", rus: "запор" },
  { port: "construção, a  ", rus: "строительство" },
  { port: "construção civil, a  ", rus: "гражданское строительство" },
  { port: "construir ", rus: "нарастить" },
  { port: "consultar  ", rus: "консультировать" },
  { port: "consumir ", rus: "потреблять" },
  { port: "contabilista, o/a  ", rus: "бухгалтер" },
  { port: "contagem, a ", rus: "сумма" },
  { port: "contar  ", rus: "рассказывать, считать" },
  { port: "contemporâneo ", rus: "современный" },
  { port: "conter ", rus: "содержать" },
  { port: "continente, o  ", rus: "континент" },
  { port: "contra  ", rus: "против" },
  { port: "ao contrário de ", rus: "напротив" },
  { port: "contraste, o ", rus: "контраст" },
  { port: "contratar ", rus: "нанимать" },
  { port: "contrato, o  ", rus: "контракт" },
  { port: "contribuir ", rus: "способствовать" },
  { port: "controlador, o ", rus: "контроллер" },
  { port: "controlar  ", rus: "контролировать" },
  { port: "contudo ", rus: "однако" },
  { port: "convencido  ", rus: "убежденный" },
  { port: "convívio, o  ", rus: "сосуществование " },
  { port: "coragem, a  ", rus: "смелость " },
  { port: "corajoso  ", rus: "храбрый" },
  { port: "a cores ", rus: "в цвете" },
  { port: "correspondência, a  ", rus: "переписка" },
  { port: "corresponder ", rus: "соответствовать" },
  { port: "corrida, a  ", rus: "гонка," },
  { port: "cortar  ", rus: "резать" },
  { port: "cortiça, a ", rus: "пробка" },
  { port: "cortina, a  ", rus: "занавеска" },
  { port: "coruja, a ", rus: "сова" },
  { port: "cotovia, a  ", rus: "жаворонок," },
  { port: "couve, a  ", rus: "капуста, т." },
  { port: "cozer ", rus: "варить" },
  { port: " cozinheiro, o  ", rus: "повар" },
  { port: "crença, a ", rus: "вера" },
  { port: "crítica, a  ", rus: "критика" },
  { port: "criticar  ", rus: "критиковать" },
  { port: "cru  ", rus: "сырой" },
  { port: "cruzar ", rus: "пересечь" },
  { port: "cubo, o ", rus: "куб" },
  { port: "cuidado, o  ", rus: "осторожность" },
  { port: "cuidar  ", rus: "беспокоиться" },
  { port: "cujo  ", rus: "чей" },
  { port: "culpa.  ", rus: "вина" },
  { port: "cumprimentar  ", rus: "приветствовать" },
  { port: "curioso ", rus: "любопытный" },
  { port: "currículo, o ", rus: "резюме" },
  { port: "curva, a  ", rus: "кривая" },
  { port: "custo, o  ", rus: "стоимость" },
  { port: "dado, o ", rus: "игральный кубик" },
  { port: "dar aulas  ", rus: "давать уроки" },
  { port: "dar fome  ", rus: "делаться голодным" },
  { port: "dar jeito ", rus: "быть полезным" },
  { port: "dar um passeio  ", rus: "прогуляться" },
  { port: "dar um pontapé ", rus: "дать пинка" },
  { port: "dar uma volta ", rus: "прогуляться" },
  { port: "dar vontade  ", rus: "дать волю" },
  { port: "dar-se (com) ", rus: "сдаваться  " },
  { port: "decade  ", rus: "упадок" },
  { port: "dececionado ", rus: "расстроенный" },
  { port: "decisão, a  ", rus: "решение" },
  { port: "dedicado ", rus: "преданный" },
  { port: "defeito, o ", rus: "дефект " },
  { port: "deitar fora ", rus: "выбрасывать" },
  { port: "deixar cair ", rus: "бросать" },
  { port: "Deixe estar!  ", rus: "Будь как будет!" },
  { port: "delicado  ", rus: "нежный" },
  { port: "demais  ", rus: "слишком" },
  { port: "demasiado ", rus: "слишком" },
  { port: "demolir  ", rus: "снести" },
  { port: "depósito, o ", rus: "депозит" },
  { port: "depressa ", rus: "быстро" },
  { port: "deprimido  ", rus: "депрессивный" },
  { port: "derrota, a  ", rus: "поражение," },
  { port: "desabafar ", rus: "выпалить" },
  { port: "desafio, o ", rus: "вызов," },
  { port: "desaparecer  ", rus: "исчезать" },
  { port: "desarrumar ", rus: "испортить" },
  { port: "descarregar  ", rus: "скачать" },
  { port: "desconfiado  ", rus: "подозрительный" },
  { port: "desconfiar  ", rus: "подозревать" },
  { port: "descongelar  ", rus: "разморозить" },
  { port: "desconhecer ", rus: "игнорировать" },
  { port: "descontrair ", rus: "расслабляться" },
  { port: "descrever ", rus: "описать" },
  { port: "descrição, a  ", rus: "описание" },
  { port: "desculpar  ", rus: "извиняться" },
  { port: "desejar ", rus: "желать" },
  { port: "desempenhar ", rus: "выполнять" },
  { port: "desempregado  ", rus: "безработные" },
  { port: "desemprego, o  ", rus: "безработица" },
  { port: "desesperado ", rus: "отчаянный" },
  { port: "desgraça, a ", rus: "несчастье" },
  { port: "desiludido ", rus: "расстроенный" },
  { port: "desistir ", rus: "сдаваться" },
  { port: "desmarcar  ", rus: "отмечать" },
  { port: "despedida, a  ", rus: "прощание" },
  { port: "despedir-se  ", rus: "прощаться" },
  { port: "desportivo  ", rus: "спортивный" },
  { port: "desportos de inverno, os ", rus: "зимний спорт" },
  { port: "destino, o ", rus: "пункт назначения" },
  { port: "destruído  ", rus: "уничтоженый" },
  { port: "destruir ", rus: "уничтожать" },
  { port: "desvantagem, a ", rus: "недостаток" },
  { port: "deter  ", rus: "задерживать" },
  { port: "dever  ", rus: "должен" },
  { port: "devido ", rus: "должный" },
  { port: "dia útil, o", rus: "рабочий день" },
  { port: "diário, o ", rus: "дневник" },
  { port: "dificuldade, a  ", rus: "сложность" },
  { port: "direção, a  ", rus: "направление" },
  { port: "direito ", rus: "прямо" },
  { port: "discussão, a  ", rus: "обсуждение" },
  { port: "disparate, o  ", rus: "ерунда" },
  { port: "disponibilidade, a  ", rus: "доступность," },
  { port: "disponível  ", rus: "доступный" },
  { port: "distante  ", rus: "далекий" },
  { port: "distinguir ", rus: "различать" },
  { port: "dobrado ", rus: "свернутый" },
  { port: "documentário, o  ", rus: "документальный фильм" },
  { port: "documento, o", rus: "документ" },
  { port: "doença, a ", rus: "болезнь" },
  { port: "dossiê, o  ", rus: "досье," },
  { port: "dourado  ", rus: "золотой" },
  { port: "dragão, o  ", rus: "дракон" },
  { port: "drama, o  ", rus: "драма" },
  { port: "duna, a  ", rus: "дюна," },
  { port: "duro ", rus: "жесткий" },
  { port: "dúvida, a ", rus: "сомнение" },
  { port: "educar ", rus: "обучать" },
  { port: "efeitos especiais, os ", rus: "спецэффекты" },
  { port: "elaborar  ", rus: "разрабатывать" },
  { port: "elefante, o e ", rus: "слон" },
  { port: "elegante ", rus: "элегантный" },
  { port: "elenco, o ", rus: "актерский состав" },
  { port: "elevado ", rus: "лифт" },
  { port: "elogiar  ", rus: "хвалить" },
  { port: "embora  ", rus: "хотя" },
  { port: "emergência, a  ", rus: "чрезвычайная ситуация" },
  { port: "emigração, a  ", rus: "эмиграция" },
  { port: "emigrante, o/a ", rus: "эмиргант" },
  { port: "emigrar to emigrate emigrar émigrer H0071-", rus: "эмигрировать" },
  { port: "emissão, a  ", rus: "выбросы" },
  { port: "emoção, a  ", rus: "эмоции, т." },
  { port: "emocionante ", rus: "захватывающий" },
  { port: "empate, o ", rus: "ничья" },
  { port: "empurrar ", rus: "толкать" },
  { port: "encantar ", rus: "очаровывать" },
  { port: "encerramento, o ", rus: "закрытие," },
  { port: "encher  ", rus: "заполнить" },
  { port: "encontrar-se (em)  ", rus: "встретиться" },
  { port: "endereço, o ", rus: "адрес" },
  { port: "enfim  ", rus: "в любом случае" },
  { port: "enganar  ", rus: "обмануть" },
  { port: "enganar-se  ", rus: "ошибиться" },
  { port: "engano, o ", rus: "ошибка" },
  { port: "engarrafamento, o  ", rus: "пробка, затор" },
  { port: "engomar  ", rus: "гладить" },
  { port: "engraçado ", rus: "забавный" },
  { port: "enquanto ", rus: "пока, когда в то время" },
  { port: "ensino, o  ", rus: "обучение" },
  { port: "entornar ", rus: "проливать" },
  { port: "entregar  ", rus: "доставлять" },
  { port: "entretanto  ", rus: "тем не менее" },
  { port: "entretenimento, o  ", rus: "развлечения" },
  { port: "entrevistador, o ", rus: "интервьюер" },
  { port: "entupido ", rus: "групый" },
  { port: "entusiasmado  ", rus: "восторженный" },
  { port: "episódio, o  ", rus: "эпизод," },
  { port: "época, a  ", rus: "время, период" },
  { port: "equipa, a ", rus: "команда" },
  { port: "equipado  ", rus: "оборудованный" },
  { port: "errar  ", rus: "сделать ошибку" },
  { port: "erro, o ", rus: "ошибка" },
  { port: "escaldão, o ", rus: "ожог" },
  { port: "escolha, a  ", rus: "выбор" },
  { port: "escolher ", rus: "выбирать" },
  { port: "esconder(-se)  ", rus: "спрятаться " },
  { port: "escorregar ", rus: "скользить" },
  { port: "escovar  ", rus: "чистить" },
  { port: "escritor, o  ", rus: "писатель" },
  { port: "escultura, a  ", rus: "скульптура" },
  { port: "esforço, o ", rus: "усилие," },
  { port: "esgotado  ", rus: "распроданный" },
  { port: "espalhar-se  ", rus: "раскинулся" },
  { port: "especialista, o/a  ", rus: "специалист" },
  { port: "especiarias, as  ", rus: "специи" },
  { port: "espécie, a", rus: "вид" },
  { port: "espectador, o ", rus: "зритель" },
  { port: "esperança, a ", rus: "надежда" },
  { port: "esperto ", rus: "эксперт" },
  { port: "espetacular  ", rus: "удивительный" },
  { port: "espetáculo, o show", rus: "спектакль" },
  { port: "espfrito, o ", rus: "дух" },
  { port: "esplanada, a ", rus: "эспланада" },
  { port: "esposa, a  ", rus: "жена" },
  { port: "estabilidade, a ", rus: "стабильность" },
  { port: "estégio, o ", rus: "этап" },
  { port: "estar a bordo  ", rus: "быть на борту" },
  { port: "estar alojado ", rus: "быть размещенным" },
  { port: "estar arrependido ", rus: "сожалеть" },
  { port: "estar de baixa ", rus: "быть расстроеным" },
  { port: "estar deitado  ", rus: "лежать" },
  { port: "estar em cartaz  ", rus: "быть на виду" },
  { port: "estar em obras ", rus: "находиться в стадии строительства" },
  { port: "estar em ordem ", rus: "быть в порядке" },
  { port: "estar em pé ", rus: "стоять" },
  { port: "estar gravida  ", rus: "быть беременной" },
  { port: "estar na fila  ", rus: "быть в очереди" },
  { port: "estar-se nas tintas ", rus: "наплевать" },
  { port: "Estés enganado! ", rus: "Вы не правы!" },
  { port: "estatlsticas, as ", rus: "статистика" },
  { port: "estévet ", rus: "вечер" },
  { port: "esticar  ", rus: "растянуть" },
  { port: "estilo, o  ", rus: "стиль" },
  { port: "estragado  ", rus: "поврежденый" },
  { port: "estranho ", rus: "иностранный" },
  { port: "estrear  ", rus: "дебютировать, использовать первый раз" },
  { port: "estreia, a  ", rus: "дебют" },
  { port: "estrelar os ovos  ", rus: "жарить яйца" },
  { port: "estuério, o  ", rus: "устье " },
  { port: "estúdio, o  ", rus: "студия" },
  { port: "estudo, o  ", rus: "исследование" },
  { port: "evento , o  ", rus: "событие" },
  { port: "exagerado ", rus: "преувеличенный" },
  { port: " exagerar ", rus: "преувеличивать" },
  { port: "estrella", rus: "звезда" },
  { port: "exactamente", rus: "точно" },
  { port: "excelente", rus: "большой" },
  { port: "excêntrico", rus: "эксцентричный" },
  { port: "exclusivo", rus: "эксклюзивный" },
  { port: "excursão, a  ", rus: "экскурсия " },
  { port: "exemplar, o  ", rus: "образцовый" },
  { port: "exemplo, o  ", rus: "например" },
  { port: "exercício físico, o  ", rus: "физические упражнения " },
  { port: "exigente  ", rus: "требовательный" },
  { port: "explicar ", rus: "объяснять" },
  { port: "explorador, o  ", rus: "исследователь," },
  { port: "extraordinário ", rus: "необыкновенный" },
  { port: "fábrica, a  ", rus: "фабрика" },
  { port: "facilmente  ", rus: "просто" },
  { port: "falante, o/a  ", rus: "спикер" },
  { port: "falésia, a ", rus: "скала" },
  { port: "falso  ", rus: "обман, ложь" },
  { port: "falta, a  ", rus: "недостаток" },
  { port: "faltar ", rus: "отсутствовать, пропускать" },
  { port: "faltar às aulas  ", rus: "пропускать занятия" },
  { port: "familiar, o  ", rus: "семья" },
  { port: "farinha, a ", rus: "мука" },
  { port: "farol, o  ", rus: "маяк" },
  { port: "fartar-se {de) ", rus: "надоесть {чего-л.)" },
  { port: "farto ", rus: "сыт по горло" },
  { port: "fascinante   ", rus: "очаровательный" },
  { port: "fascinar ", rus: "очаровывать" },
  { port: "fatura, a ", rus: "счет" },
  { port: "fazer a barba ", rus: "бриться" },
  { port: "fazer contas ", rus: "заниматься математикой" },
  { port: "fazer escala   ", rus: "иметь пересадку" },
  { port: "fazer um favor {a alguém)   ", rus: "сделать кому-либо одолжение" },
  { port: "fazer festas {em alguém)  ", rus: "погладить (собаку)" },
  { port: "fazer ideia {de)   ", rus: "иметь представление (о)" },
  { port: "fazer impressão to revolt, ", rus: "возмутиться" },
  { port: "fazer parte (de)  ", rus: "быть частью" },
  { port: "fazer queixa (de)   ", rus: "подать жалобу (на что-л.)" },
  { port: "fazer questão ", rus: "поставить точку" },
  { port: "fazer sentido   ", rus: "иметь смысл" },
  { port: "fazer uma cena  ", rus: "устроить сцену" },
  { port: "feitio, o  ", rus: "заклинание" },
  { port: "felicidade, a ", rus: "счастье" },
  { port: "feminino  ", rus: "женский" },
  { port: "fenómeno, o  ", rus: "явление" },
  { port: "feriado, o  ", rus: "праздничный день" },
  { port: "ferido ", rus: "раненый" },
  { port: "ferro de engomar, o", rus: "утюг" },
  { port: "ferver  ", rus: "кипятить" },
  { port: "Fica para a próxima!   ", rus: "Возможно, в следующий раз!" },
  { port: "ficção científica", rus: "научная фантастика" },
  { port: "ficha, a ", rus: "жетон, талон" },
  { port: "ficheiro, o  ", rus: "файл" },
  { port: "fiel  ", rus: "лояльный" },
  { port: "filhote, o  ", rus: "мальчик, сынок, щенок" },
  { port: "fingir ", rus: "подделать" },
  { port: "fio, o  ", rus: "провод" },
  { port: "fluentemente ", rus: "бегло" },
  { port: "fofo ", rus: "милый" },
  { port: "fogo, o  ", rus: "огонь" },
  { port: "folga, a  ", rus: "выходной" },
  { port: "folha, a ", rus: "лист" },
  { port: "folheto, o ", rus: "листовка" },
  { port: "fonte, a  ", rus: "источник" },
  { port: "fora do comum  ", rus: "необычный" },
  { port: "formado  ", rus: "сформированный" },
  { port: "formador, o ", rus: "тренер" },
  { port: "fracasso, o ", rus: "неудача" },
  { port: "frase, a  ", rus: "фраза " },
  { port: "fraturar ", rus: "сломать" },
  { port: "frente e verso   ", rus: "спереди и сзади" },
  { port: "frequência, a ", rus: "частота" },
  { port: "frequentar ", rus: "посещать" },
  { port: "frigideira, a  ", rus: "кастрюля" },
  { port: "fritar  ", rus: "жарить" },
  { port: "fruto, o  ", rus: "фрукт" },
  { port: "fumador, o ", rus: "курильщик" },
  { port: "fumo, o ", rus: "дым" },
  { port: "funcionário público, o  ", rus: "государственный служащий" },
  { port: "fundar  ", rus: "найти" },
  { port: "furacão, o  ", rus: "ураган" },
  { port: "futuro, o  ", rus: "будущее" },
  { port: "gaivota, a  ", rus: "чайка," },
  { port: "galo, o  ", rus: "петух," },
  { port: "garantia, a ", rus: "гарантия" },
  { port: "gargalhada, a  ", rus: "смех" },
  { port: "gasolina, a  ", rus: "бензин" },
  { port: "gastronomia, a ", rus: "гастрономия" },
  { port: "gaveta, a ", rus: "ящик" },
  { port: "gema ", rus: "яичный желток" },
  { port: "género, o", rus: "пол" },
  { port: "gigante ", rus: "гигантский" },
  { port: "golfinho, o  ", rus: "дельфин" },
  { port: "golo, o ", rus: "гол" },
  { port: "gordura, a ", rus: "жирность" },
  { port: "gorjeta, a  ", rus: "совет" },
  { port: "gosto, o  ", rus: "лайк" },
  { port: "gota, a  ", rus: "падение," },
  { port: "gozar (com)  ", rus: "наслаждаться (с)" },
  { port: "grisalho  ", rus: "серый" },
  { port: "grito, o  ", rus: "крик" },
  { port: "grosso ", rus: "толстый" },
  { port: "grupo, o ", rus: "группа" },
  { port: "guarda-chuva, o  ", rus: "зонтик" },
  { port: "guerra, a  ", rus: "война" },
  { port: "habitação, a  ", rus: "жилье" },
  { port: "habitante, o/a  ", rus: "житель" },
  { port: "habitar", rus: "жить" },
  { port: "hábito, o  ", rus: "привычка" },
  { port: "helicóptero, o ", rus: "вертолет" },
  { port: "hilariante ", rus: "веселый" },
  { port: "hóquei ", rus: "хоккей" },
  { port: "na hora  ", rus: "вовремя" },
  { port: "a horas ", rus: "вовремя" },
  { port: "horas extra, as   ", rus: "сверхурочная работа" },
  { port: "horóscopo, o   ", rus: "гороскоп" },
  { port: "hospitalidade, a  ", rus: "гостеприимство" },
  { port: "humidade, a ", rus: "влажность" },
  { port: "humor, o  ", rus: "юмор" },
  { port: "iate, o  ", rus: "яхта" },
  { port: "idoso, o  ", rus: "пожилые люди" },
  { port: "ignorar   ", rus: "игнорировать" },
  { port: "ilegal ", rus: "незаконный" },
  { port: "iluminar ", rus: "освещать" },
  { port: "imaginar  ", rus: "представить" },
  { port: "de imediato ", rus: "немедленно" },
  { port: "imigração, a  ", rus: "иммиграция," },
  { port: "imposslvel ", rus: "невозможный" },
  { port: "imprensa, a  ", rus: "печать" },
  { port: "impressora, a  ", rus: "принтер" },
  { port: "incêndio, o  ", rus: "огонь" },
  { port: "incluir  ", rus: "включать" },
  { port: "incomodar ", rus: "беспокоить" },
  { port: "incrlvel ", rus: "невероятный" },
  { port: "independente  ", rus: "независимый" },
  { port: "industrial ", rus: "промышленный" },
  { port: "inesperado  ", rus: "непредвиденный" },
  { port: "inesqueclvel  ", rus: "незабываемый" },
  { port: "inferno, o  ", rus: "ад" },
  { port: "inflamado ", rus: "воспаленный" },
  { port: "influência, a  ", rus: "влияние" },
  { port: "informar ", rus: "поставить в известность" },
  { port: "inquilino, o  ", rus: "жилец" },
  { port: "inscrever-se (em)  ", rus: "зарегистрироваться (в)" },
  { port: "inscrição, a ", rus: "зачисление" },
  { port: "inscrito ", rus: "зарегистрированный" },
  { port: "inseguro  ", rus: "ненадежный" },
  { port: "inseto, o  ", rus: "насекомое" },
  { port: "inspetor, o ", rus: "инспектор" },
  { port: "inspiração, a ", rus: "вдохновение" },
  { port: "instalações, as ", rus: "объекты" },
  { port: "instalar  ", rus: "установить" },
  { port: "instante, o  ", rus: "мгновение" },
  { port: "inteiro ", rus: "весь" },
  { port: "inteligência, a ", rus: "интеллект" },
  { port: "interesse, o  ", rus: "интерес" },
  { port: "interior  ", rus: "интерьер" },
  { port: "internar  ", rus: "признавать" },
  { port: "intérprete, o/a ", rus: "переводчик" },
  { port: "interrogatório, o   ", rus: "допрос" },
  { port: "interromper  ", rus: "прерывать" },
  { port: "intoxicação, a ", rus: "интоксикация" },
  { port: "inveja, a  ", rus: "зависть" },
  { port: "invejoso  ", rus: "завистливый   " },
  { port: "inventar  ", rus: "изобретать " },
  { port: "investigação, a  !Mi'.", rus: "исследование" },
  { port: "ir parar (algures)  ", rus: "остановиться (где-то) " },
  { port: "Isso mesmo! ", rus: "Это верно!  " },
  { port: "Isto não presta!  ", rus: "Это отстой! Это чушь !" },
  { port: "já que as ", rus: "с тех пор" },
  { port: "jacarandá, o ", rus: "палисандр" },
  { port: "jipe, o ", rus: "джип " },
  { port: "jogador, o ", rus: "игрок,  " },
  { port: "jogar à bola  ", rus: "играть в мяч " },
  { port: "jogos olímpicos, os ", rus: "Олимпийские игры" },
  { port: "jornalismo, o ", rus: "журналистика " },
  { port: "juntar  ", rus: "добавить " },
  { port: "lã, a  ", rus: "шерсть " },
  { port: "em lado nenhum  ", rus: "нигде  " },
  { port: "por um lado ", rus: "с одной стороны  " },
  { port: "por outro lado  ", rus: "с другой стороны " },
  { port: "ladrão, o  ", rus: "вор " },
  { port: "lamentar ", rus: "сожалеть " },
  { port: "lãmpada, a  ", rus: "лампа " },
  { port: "lançar ", rus: "запустить " },
  { port: "laranjeira, a ", rus: "апельсиновое дерево" },
  { port: "largo, o ", rus: "место" },
  { port: "largura, a  ", rus: "ширина " },
  { port: "leão, o ", rus: " лев " },
  { port: " legendas, as  ", rus: " субтитры " },
  { port: " leitão, o  ", rus: " поросенок " },
  { port: "leitura, a ", rus: "чтение" },
  { port: " lembrança, a ", rus: "сувенир" },
  { port: "lentes de contacto, as ", rus: "контактные линзы" },
  { port: "lição, a  ", rus: "урок " },
  { port: "licenciado ", rus: "выпускник  " },
  { port: "licenciatura, a  ", rus: "степень " },
  {
    port: "lidar (com) to deal (with) lidiar (con) gérer H , lfü1",
    rus: "иметь дело (с) ",
  },
  { port: "ligado (switched) on conectado branché :Jflâ", rus: "включен " },
  { port: "ligar ", rus: "подключиться , включить" },
  { port: "Não ligues a isso! ", rus: "Плевать на это!  " },
  { port: "ligeiro  ", rus: "свет " },
  { port: "limite, o ", rus: "предел " },
  { port: "limpeza, a  ", rus: "чистка " },
  { port: "lince, o  ", rus: "рысь " },
  { port: "literário ", rus: "литературный " },
  { port: "literatura, a  ", rus: "литература " },
  { port: "lobo, o  ", rus: "волк " },
  { port: "local  ", rus: "местный  " },
  { port: "local, o ", rus: "местность" },
  { port: "localização, a  ", rus: "локализация " },
  { port: "localizar ", rus: "найти  " },
  { port: "Logo que possível.  ", rus: "Как можно скорее " },
  { port: "louco  ", rus: "сумасшедший " },
  { port: "loucura, a  ", rus: "безумие " },
  { port: "lume, o  ", rus: "свет " },
  { port: "lutar ", rus: "драться " },
  { port: "luxo, o  ", rus: "роскошь " },
  { port: "maçã, a ", rus: "яблоко " },
  { port: "macaco, o  ", rus: "обезьяна " },
  { port: "madeira, a ", rus: "дерево, " },
  { port: "madrugada, a  ", rus: "рассвет " },
  { port: "maduro  ", rus: "спелое " },
  { port: "magnifico ", rus: "великолепное " },
  { port: "magoar-se ", rus: "причинить себе боль " },
  { port: "maioria, a  ", rus: "большинство " },
  { port: "mal pago  ", rus: "недоплачено " },
  { port: "malpassado", rus: "редкий  " },
  { port: "maldisposto  ", rus: "плохо расположен,  в плохом настроении " },
  { port: "mal-educado ", rus: "невоспитанный, грубый " },
  { port: "mancha, a  ", rus: "пятно " },
  { port: "mandar ", rus: "посылать" },
  { port: "manter  ", rus: "продолжать" },
  { port: "manter a linha  ", rus: "поддерживать линию  " },
  { port: "manter-se em forma  ", rus: "держать в форме" },
  { port: "máquina de barbear, a  ", rus: "опасная бритва " },
  { port: "maratona, a ", rus: "марафон " },
  { port: "maravilha, a ", rus: "чудо " },
  { port: "maravilhoso  ", rus: "чудесно  " },
  { port: "marcar um golo  ", rus: "забить гол " },
  { port: "marisco, o ", rus: "морепродукты " },
  { port: "masculino male ", rus: "мужской род " },
  { port: "massa, a ", rus: "макароны, паста" },
  { port: "matar ", rus: "убить " },
  { port: "matemática, a ", rus: "математика " },
  { port: "matéria, a  ", rus: "материя " },
  { port: "material, o  ", rus: "материал " },
  { port: "máximo ", rus: "максимум  " },
  { port: "mecânico, o ", rus: "механик " },
  { port: "medalha, a  ", rus: "медаль " },
  { port: "média, a ", rus: "средний " },
  { port: "média, os ", rus: "в среднем " },
  { port: "medida, a ", rus: "мера, измерение" },
  { port: "medieval  ", rus: "средневековый  " },
  { port: "medir  ", rus: "измерять" },
  { port: "meia, a  ", rus: "носок " },
  { port: "meigo  ", rus: "нежный  " },
  { port: "melhorar ", rus: "улучшить " },
  { port: "membro, o  ", rus: "участник " },
  { port: "memória, a  ", rus: "память " },
  { port: "menino, o  ", rus: "мальчик " },
  { port: "mensalidade, a  ", rus: "ежемесячный платеж " },
  { port: "mensalmente ", rus: "ежемесячно  " },
  { port: "mentir ", rus: "врать, лгать " },
  { port: "mentira, a  ", rus: "ложь " },
  { port: "mentiroso  ", rus: "лживый" },
  { port: "merecer ", rus: "заслуживают " },
  { port: "mesmo  ", rus: "также, такой же" },
  { port: "mestrado, o ", rus: "степень магистра " },
  { port: "mestre, o/a  ", rus: "мастер " },
  { port: "metade, a  ", rus: "половина " },
  { port: "metal, o  ", rus: "металл " },
  { port: "meter  ", rus: "поставить что-то куда-то" },
  { port: "meter-se em sarilhos ", rus: "попасть в беду " },
  { port: "mexer ", rus: "мешать" },
  { port: "mexer-se  ", rus: "переместиться, переместить" },
  { port: "micro-ondas, o  ", rus: "микроволновая печь " },
  { port: "mimado  ", rus: "испорченный " },
  { port: "mistério, o  ", rus: "тайна " },
  { port: "mobilado ", rus: "мебелированный" },
  { port: "moda, a  ", rus: "мода " },
  { port: "modelo, o  ", rus: "модель" },
  { port: "moderado  ", rus: "умеренный " },
  { port: "modesto  ", rus: "скромный  " },
  { port: "modo, o  ", rus: "режим " },
  { port: " wet mojado mouillé lá<J,$H", rus: "мокрое  " },
  { port: "monótono  ", rus: "монотонный " },
  { port: "montra, a ", rus: "витрина " },
  { port: "morador, o  ", rus: "житель " },
  { port: "morto ", rus: "мертвый" },
  { port: "mosca, a ", rus: "муха " },
  { port: "motivo, o r ", rus: "мотив " },
  { port: "motorista, o/a  ", rus: "водитель " },
  { port: "mover  ", rus: "переместить " },
  { port: " movimentar-se t ", rus: " передвигаться " },
  { port: " muçulmano, o  ", rus: " мусульманин " },
  { port: "mudança, a ", rus: "изменение " },
  { port: "muralha, a  ", rus: "стена, настенная роспись " },
  { port: "musical.o  ", rus: "мюзикл " },
  { port: "naçlo, a  ", rus: "нация" },
  { port: "nacional ", rus: "национальный  " },
  { port: "nadador-salvador, o  ", rus: "спасатель " },
  { port: "natação", rus: "плавание " },
  { port: "natas, as  ", rus: "сливки" },
  { port: "nativo  ", rus: "родной " },
  { port: "natural  ", rus: "естественный " },
  { port: "naturalidade, a  ", rus: "естественность, место его рождения" },
  { port: "necessariamente  ", rus: "обязательно  " },
  { port: "necesúrio ", rus: "необходимо " },
  { port: "necessidade ", rus: "необходимость " },
  { port: "negativo ", rus: "отрицательный  " },
  { port: "Nem por isso!  ", rus: "Не совсем!  " },
  { port: "nervos, os  ", rus: "нервы " },
  { port: "nervoso  ", rus: "нервный " },
  { port: "nlvel,o ", rus: "уровень " },
  { port: "no entanto ", rus: "однако " },
  { port: "noitada, a ", rus: "вечеринка " },
  { port: "nojo, o  ", rus: "отвращение " },
  { port: "nora. A ", rus: "невестка  " },
  { port: "normalidade, a ", rus: "нормальность " },
  { port: "nota, a  ", rus: "примечание " },
  { port: "noticiário, o  ", rus: "новости " },
  { port: "novidade, a ", rus: "новинка " },
  { port: "nublado ", rus: "облачно " },
  { port: "objetivo, o ", rus: "цель " },
  { port: "objeto, o ", rus: "объект " },
  { port: "obra de arte, a  ", rus: " произведение искусства" },
  { port: "obras, as  ", rus: "произведения " },
  { port: "obrigatório  ", rus: "обязательный " },
  { port: "óbvio  ", rus: "очевидно  " },
  { port: "ocupar ", rus: "занимать " },
  { port: "odiar ", rus: "ненавидеть" },
  { port: "ofender ", rus: "обидеть  " },
  { port: "ofendido  ", rus: "обиженный" },
  { port: "oficial ", rus: "официальный  " },
  { port: "oficina, a  ", rus: "мастерская " },
  { port: "óleo, o  ", rus: "масло " },
  { port: "omitir  ", rus: "опустить  " },
  { port: "onda, a ", rus: "волна " },
  { port: "opção, a  ", rus: "опция " },
  { port: "operério, o  ", rus: "фабричный рабочий " },
  { port: "opinião, a  ", rus: "мнение" },
  { port: "oportunidade, a  ", rus: "возможность " },
  { port: "ordem, a  ", rus: "порядок " },
  { port: "ordenado, o  ", rus: "зарплата " },
  { port: "organização, a  ", rus: "организация " },
  { port: "organizar ", rus: "организовать  " },
  { port: "orgulho, o ", rus: "гордость" },
  { port: "orgulhoso  ", rus: "гордый " },
  { port: "origem, a  ", rus: "происхождение" },
  { port: "osso, o ", rus: "кость " },
  { port: "ouro, o  ", rus: "золото " },
  { port: "paciência", rus: "терпение" },
  { port: "paciente  ", rus: "пациент " },
  { port: "padre, o  ", rus: "священник " },
  { port: "pagar  ", rus: "платить" },
  { port: "paixão, a  ", rus: "страсть " },
  { port: "panela, a  ", rus: "кастрюля " },
  { port: "papel higiénico, o  ", rus: "туалетная бумага " },
  { port: "papel.o  ", rus: "бумага " },
  { port: "paralelo ", rus: "параллельно " },
  {
    port: "parar",
    rus: "остановить (делать что-то) прекратить (делать что-то)  ",
  },
  { port: "parecido ", rus: "подобные  " },
  { port: "participação, a ", rus: "участие" },
  { port: "participar  ", rus: "участвовать " },
  { port: "partilhar  ", rus: "поделиться " },
  { port: "partir-se ", rus: "прерваться" },
  { port: "parvo ", rus: "глупо " },
  { port: "passadeira, a ", rus: "пешеходный переход " },
  { port: "passado, o  ", rus: "прошлое " },
  { port: "passagem, a ", rus: "проход  " },
  { port: "pássaro, o bird pájaro oiseau ~", rus: "птица " },
  { port: "passe, o  ", rus: "пропуск " },
  { port: "passo, o ", rus: "шаг " },
  { port: "pasta, a ", rus: "папка " },
  { port: "pastilha elástica, a  ", rus: "жевательная резинка  " },
  { port: "pata, a ", rus: "лапа " },
  { port: "patinagem, a  ", rus: "катание на коньках " },
  { port: "peão, o ", rus: "пешеход " },
  { port: "ao péde ", rus: "у подножия " },
  { port: "pedido, o  ", rus: "запрос " },
  { port: "pedra, a  ", rus: "камень " },
  { port: "pele, a  ", rus: "кожа " },
  { port: "Pelo contrário!  ", rus: "Напротив!  " },
  { port: "pelo, o  ", rus: "мех " },
  { port: "pendurar ", rus: "повесить  " },
  { port: "pensão, a  ", rus: "пансион " },
  { port: "pentear  ", rus: "расчесываться" },
  { port: "pepino, o  ", rus: "огурец " },
  { port: "percurso,  ", rus: "маршрут " },
  { port: "perda, a  ", rus: "потеря " },
  { port: "permissão, a ", rus: "разрешение " },
  { port: "permitir ", rus: "разрешать" },
  { port: "perpendicular ", rus: "перпендикуляр " },
  { port: "personagem, o/a  ", rus: "персонаж " },
  { port: "personalidade, a ", rus: "личность " },
  { port: "pesadelo, o  ", rus: "кошмар " },
  { port: "pesca, a  ", rus: "рыбалка " },
  { port: "peso, o  ", rus: "вес " },
  { port: "pesquisa, a  ", rus: "исследование, опрос " },
  { port: "pessoal  ", rus: "личный " },
  { port: "piloto, o/a  ", rus: "пилот " },
  { port: "pimenta, a  ", rus: "перец " },
  { port: "pimento, o ", rus: "перец " },
  { port: "pingar ", rus: "капать" },
  { port: "pinguim, o  ", rus: "пингвин " },
  { port: "pintura, a  ", rus: "живопись " },
  { port: "piora ", rus: "ухудшаться " },
  { port: "pipocas, as ", rus: "попкорн " },
  { port: "pisar ", rus: "наступить  " },
  { port: "piza, a ", rus: "пицца " },
  { port: "plástico, o  ", rus: "пластик " },
  { port: "plateia, a  ", rus: "публика, зрительный зал " },
  { port: "pneu, o ", rus: "шина " },
  { port: "pobreza, a  ", rus: "бедность " },
  { port: "poema, o  ", rus: "стихотворение" },
  { port: "poeta, o/a  ", rus: "поэт " },
  { port: "policial, o  ", rus: "полицейский " },
  { port: "político, o ", rus: "политик " },
  { port: "poluição, a ", rus: "загрязнение " },
  { port: "pombo, o  ", rus: "голубь" },
  { port: "ponto, o  ", rus: "точка " },
  { port: "ponto de encontro ", rus: "место встречи " },
  { port: "pontual ", rus: "пунктуальный " },
  { port: "população, a  ", rus: "численность населения" },
  { port: "por pouco  ", rus: "почти  " },
  { port: "porcaria, a ", rus: "чушь " },
  { port: "porcelana, a ", rus: "фарфор " },
  { port: "porco, o  ", rus: "свинья " },
  { port: "porém  ", rus: "однако  " },
  { port: "pormenor, o  ", rus: "деталь " },
  { port: "portanto therefore por tanto donc i,;]Jlt", rus: "следовательно " },
  { port: "portar-se  ", rus: "вести себя  " },
  { port: "portátil. o  ", rus: " ноутбук" },
  { port: "posição, a position posición position l!Ri'L", rus: "позиция " },
  { port: "positivo  ", rus: "положительный  " },
  { port: "possibilidade, a  ", rus: "возможность " },
  { port: "povo, o ", rus: "люди " },
  { port: "praça de táxis, a  ", rus: "стоянка такси " },
  { port: "prancha, a  ", rus: "доска " },
  { port: "prateleira, a ", rus: "полка " },
  { port: "praticamente ", rus: "практически   " },
  { port: "praticar ", rus: "практиковаться" },
  { port: "prazer, o ", rus: "удовольствие " },
  { port: "precipitação, a  ", rus: "осадки " },
  { port: "preferência, a  ", rus: "предпочтение " },
  { port: "de preferência ", rus: "предпочтительно " },
  { port: "prémio, o  ", rus: "приз " },
  { port: "prender  ", rus: "арестовать " },
  { port: "presença, a ", rus: "присутствие " },
  { port: "presente  ", rus: "настоящее " },
  { port: "preso  ", rus: "арестован" },
  { port: "Não presta!  ", rus: "Это отстой! Это бесполезно! " },
  { port: "prestável  ", rus: "полезное  " },
  { port: "pretender ", rus: "намереваться " },
  { port: "previsão do tempo, a  ", rus: "прогноз погоды " },
  { port: "principal ", rus: "главная " },
  { port: "principalmente ", rus: "преимущественно  " },
  { port: "princípio  ", rus: "принцип" },
  { port: "prisão, a ", rus: "тюрьма" },
  { port: "privado ", rus: "частный  " },
  { port: "à procura de  ", rus: "поиск, в поисках  " },
  { port: "produzir ", rus: "производить " },
  { port: "profissional  ", rus: "профессиональный " },
  { port: "profundidade, a  ", rus: "глубина " },
  { port: "profundo  ", rus: "глубоко  " },
  { port: "programa, o  ", rus: "программа " },
  { port: "proibir ", rus: "запретить  " },
  { port: "projeto, o  ", rus: "проект " },
  { port: "prometer ", rus: "обещать " },
  { port: "pronúncia, a  ", rus: "произношение" },
  { port: "pronunciar  ", rus: "произносить" },
  { port: "proposta, a ", rus: "предложение" },
  { port: "proprietário, o  ", rus: "владелец " },
  { port: "propio ", rus: "собственный " },
  { port: "protetor solar, o  ", rus: "солнцезащитный крем " },
  { port: "psicólogo, o  ", rus: "психолог " },
  { port: "psiquiátrico  ", rus: "психиатрический  " },
  { port: "publicar  ", rus: "опубликовать  " },
  { port: "publicidade, a  ", rus: "реклама" },
  { port: "público, o  ", rus: "публика " },
  { port: "puxar ", rus: "тянуть " },
  { port: "qualidade, a  ", rus: "качество " },
  { port: "qualificações, as  ", rus: "квалификации" },
  { port: "qualquer  ", rus: "любой " },
  { port: "quanto a ...  ", rus: "как... что касается.. " },
  { port: "quartel de bombeiros, o  ", rus: "пожарная часть " },
  { port: "queda, a ", rus: "падение " },
  { port: "queimado ", rus: "сгоревший" },
  { port: "queixar-se (de) ", rus: "жаловаться (на)  " },
  { port: "Quem está a seguir?", rus: "Кто следующий? " },
  { port: "questionário, o  ", rus: "анкета" },
  { port: "química, a ", rus: "химия " },
  { port: "quinta, a  ", rus: "ферма " },
  { port: "rainha, a  ", rus: "королева " },
  { port: "raio X, o  ", rus: "рентген " },
  { port: "ralar  ", rus: "тереть" },
  { port: "raro  ", rus: "редко " },
  { port: "rato, o  ", rus: "мышь " },
  { port: "razão, a ", rus: "причина " },
  { port: "reação, a  ", rus: "реакция " },
  { port: "realizador, o ", rus: "режиссёр " },
  { port: "realizar ", rus: "осуществить  " },
  { port: "realmente  ", rus: "действительно " },
  { port: "recear ", rus: "бояться  " },
  { port: "rececionista, o/a  ", rus: "администратор " },
  { port: "recentemente  ", rus: "недавно " },
  { port: "recibo, o  ", rus: "квитанция" },
  { port: "reclamação, a  ", rus: "жалоба " },
  { port: "reconhecer  ", rus: "распознать " },
  { port: "recordação, a  ", rus: "сувенир" },
  { port: "recuperar ", rus: "восстановить  " },
  { port: "rede social  ", rus: "социальная сеть  " },
  { port: "redondo ", rus: "круглый  " },
  { port: "reduzir ", rus: "уменьшить " },
  { port: "de referência  ", rus: "ссылка  " },
  { port: "refletir ", rus: "размышлять " },
  { port: "regra, a  ", rus: "правило" },
  { port: "regular ", rus: "регулярный " },
  { port: "rei.o  ", rus: "король" },
  { port: "reino.o ", rus: "королевство " },
  { port: "relação, a  ", rus: "отношение " },
  { port: "em relação  ", rus: "по отношению  " },
  { port: "relações públicas, as  ", rus: "Связи с общественностью " },
  { port: "relatório, o  ", rus: "доклад, отчет" },
  { port: "relaxar  ", rus: "расслабиться" },
  { port: "religião, a  ", rus: "религия " },
  { port: "renda, a ", rus: "аренда " },
  { port: "renovar ", rus: "продлить  " },
  { port: "repara ", rus: "отремонтировать   " },
  { port: "reservado ", rus: "зарезервировано  " },
  { port: "residência, a  ", rus: "резиденция" },
  { port: "resistente  ", rus: "устойчивый " },
  { port: "resolver ", rus: "решить  " },
  { port: "respeitar  ", rus: "уважать" },
  { port: "respeito, o ", rus: "уважение " },
  { port: "respirar  ", rus: "дышать " },
  { port: "responsabilidade, a r", rus: "ответственность " },
  { port: "responsável  ", rus: " ответственный  " },
  { port: "em resposta ", rus: "в ответ  " },
  { port: "restar  ", rus: "оставаться" },
  { port: "resto, o ", rus: "отдых" },
  { port: "resultado, o ", rus: "результат " },
  { port: "retrato, o portrait retrato portrait H", rus: "портрет " },
  { port: "reunião, a  ", rus: "встреча" },
  { port: "reunir-se", rus: "воссоединиться" },
  { port: "revisão, a  ", rus: "обзор" },
  { port: "ridlculo ", rus: "нелепый" },
  { port: "rígido ", rus: "жесткий  " },
  { port: "rijo", rus: "жестко " },
  { port: "rinque de patinagem, o  ", rus: "каток " },
  { port: "riqueza, a  ", rus: "богатство " },
  { port: "risco, o ", rus: "риск" },
  { port: "em risco de ", rus: "в опасности  " },
  { port: "roda, a  ", rus: "колесо" },
  { port: "rodar ", rus: "вращать " },
  { port: "rodela, a  ", rus: "медальон" },
  { port: "rodoviária, a  ", rus: "автовокзал " },
  { port: "rosa, a ", rus: "роза " },
  { port: "roubo, o ", rus: "ограбление" },
  { port: "roupa interior, a  ", rus: "нижнее белье " },
  { port: "roupeiro,  o ", rus: "гардероб" },
  { port: "ruido, o ", rus: "шум " },
  { port: "sabedoria, a  ", rus: "мудрость " },
  { port: "saber  ", rus: "знать, уметь" },
  { port: "sabor, o  ", rus: "вкус " },
  { port: "salão de beleza, o  ", rus: "салон красоты " },
  { port: "salário, o  ", rus: "зарплата" },
  { port: "saltar ", rus: "прыгать" },
  { port: "salvar  ", rus: "сохранить" },
  { port: "salvo ", rus: "сохранено" },
  { port: "sangue, o ", rus: "кровь " },
  { port: "saudade, a  ", rus: "тоска " },
  { port: "seca, a ", rus: "засуха " },
  { port: "secador, o ", rus: "фен" },
  { port: "secagem, a ", rus: "сушка " },
  { port: "secar ", rus: "высушить " },
  { port: "segredo, o  ", rus: "секрет " },
  { port: "segurança social, a ", rus: "социальное обеспечение " },
  { port: "segurança, a ", rus: "безопасность" },
  { port: "segurança, o/a  ", rus: "охранник " },
  { port: "segurar  ", rus: "удержать " },
  { port: "seguro, o  ", rus: "страхование, страховка," },
  { port: "selvagem  ", rus: "дикий " },
  { port: "semanário, o  ", rus: "еженедельник " },
  { port: "semelhança, a ", rus: "сходство, " },
  { port: "semelhante ", rus: "подобное  " },
  { port: "semestre, o ", rus: "семестр " },
  { port: "senhorio, o  ", rus: "арендодатель " },
  { port: "sensível ", rus: "чувствительный   " },
  { port: "sensual  ", rus: "чувственный " },
  { port: "sentido de humor, o  ", rus: "чувство юмора  " },
  { port: "sentido, o  ", rus: "смысл " },
  { port: "sentido único ", rus: "в одну сторону " },
  { port: "sentimento, o  ", rus: "чувство юмора  " },
  { port: "separação, a  ", rus: "разлука " },
];
