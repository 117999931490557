import styles from "./perfeito.module.css";
import { verb_p, verbP } from "../../utils/verbopast";
import Verb from "../verb/verb";
import AppHeader from "../app-header/app-header";

function Perfeito() {
  return (
    <div className={styles.main}>
      <AppHeader name={"Учи спряжения глаголов"}></AppHeader>
      <h1 className={styles.title}>Pretérito perfeito simples do indicativo</h1>
      <Verb list={verb_p} listP={verbP}></Verb>
    </div>
  );
}
export default Perfeito;
