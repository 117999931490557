import styles from "./imperfeitowriter.module.css";
import { verb_im } from "../../utils/verboimp";
import WriterVerb from "../writerverb/writerverb";
import AppHeader from "../app-header/app-header";

function ImperfeitoWrite() {
  return (
    <div className={styles.main}>
      <AppHeader name={"Пиши глаголы"}></AppHeader>
      <h1 className={styles.title}>Pretérito imperfeito do indicativo</h1>
      <WriterVerb list={verb_im}></WriterVerb>
    </div>
  );
}

export default ImperfeitoWrite;
