import styles from "./start.module.css";
import dog from "../../images/title.jpg";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../types/hook";
import { getuser } from "../../store/action-selector";
import { motion } from "framer-motion";

import { useEffect, useState } from "react";
import { speakru } from "../../utils/speakru";

function Start() {
  const user = useAppSelector(getuser);

  const [info, setinfo] = useState(true);

  let link = "/login";
  let nameLink = "Вход";

  if (user.auth) {
    link = "/profile";
    nameLink = "Личный кабинет";
  }

  const pVariants = {
    hidden: {
      x: 1500,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
  };
  const pVariants_2 = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  return (
    <div className={styles.main}>
      <div className={styles.preview}>
        <div className={styles.preview_2}>
          <img src={dog} alt="dog" />
        </div>
        <motion.div
          initial={"hidden"}
          animate={"visible"}
          transition={{
            delay: 1,
          }}
          variants={pVariants_2}
        >
          <NavLink className={styles.activet_2} to={link}>
            <div className={styles.textt}>{nameLink}</div>
          </NavLink>
        </motion.div>
        <motion.div
          className={styles.activet}
          initial={"hidden"}
          animate={"visible"}
          transition={{
            delay: 0.2,
          }}
          variants={pVariants_2}
        >
          <h1
            className={styles.textt}
            onClick={() => speakru("Привет, меня зовут Тоби!", 1.5)}
          >
            Привет, меня зовут Тоби! Будем учить европейский португальский язык
            вместе!
          </h1>
        </motion.div>
        <motion.div
          className={styles.activets}
          initial={"hidden"}
          animate={"visible"}
          transition={{
            delay: 0.5,
          }}
          variants={pVariants_2}
          whileHover={{
            scale: 1.4,
            transition: { duration: 0.1 },
          }}
        >
          <NavLink to={"/menu"} className={styles.textt}>
            Начинаем!
          </NavLink>
        </motion.div>
      </div>
    </div>
  );
}

export default Start;
