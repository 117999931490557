import styles from "./many.module.css";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../types/hook";
import { AppHeader } from "../app-header/app-header";
import { getuser } from "../../store/action-selector";

function ManyList() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getuser);
  if (user.auth) {
    return (
      <div className={styles.main}>
        <AppHeader name={"Еще больше интересного"}></AppHeader>
        <section className={styles.team}>
          <NavLink className={styles.active} to="/fraz">
            <div className={styles.text}>Учить фразы</div>
          </NavLink>
          <NavLink className={styles.active} to="/predglag">
            <div className={styles.text}>Писать глаголы в предложениях</div>
          </NavLink>
          <NavLink className={styles.active} to="/predall">
            <div className={styles.text}>Переводить предложения</div>
          </NavLink>
          <NavLink className={styles.active} to="/predconst">
            <div className={styles.text}>Составлять предложения (просто)</div>
          </NavLink>
          <NavLink className={styles.active} to="/predconsthard">
            <div className={styles.text}>Составлять предложения (сложно)</div>
          </NavLink>
        </section>
      </div>
    );
  } else {
    return (
      <div className={styles.main}>
        <AppHeader name={"Еще больше интересного"}></AppHeader>
        <section className={styles.team}>
          <NavLink className={styles.active} to="/fraz">
            <div className={styles.text}>Учить фразы</div>
          </NavLink>
        </section>
        <section className={styles.team}>
          <NavLink className={styles.active} to="/register">
            <div className={styles.text}>
              Хочешь узнать еще больше? Зарегистрируйся... Это просто и
              бесплатно
            </div>
          </NavLink>
        </section>
      </div>
    );
  }
}

export default ManyList;
