export const fraz_1 = [
  {
    p_1: "Em Portugal os bancos",
    p_2: "fecham",
    p_3: "às 15h00.",
    p_4: "fechar",
    p_5: "В Португалии банки закрываются  в 15.00.",
  },
  {
    p_1: "O João  ",
    p_2: "compra",
    p_3: "o jornal todas as manhãs.",
    p_4: "comprar",
    p_5: "Жоао покупает газету каждое утро.",
  },
  {
    p_1: "Nós ",
    p_2: "moramos",
    p_3: "num pequeno apartamento.",
    p_4: "morar",
    p_5: "Мы живем в маленькой квартире.",
  },
  {
    p_1: "Ela é professora e",
    p_2: "ensina",
    p_3: "português a crianças do 1.o ciclo.",
    p_4: "ensinar",
    p_5: "Она - учительница и учит португальскому детей начальной школы",
  },
  {
    p_1: "Eu",
    p_2: "gosto",
    p_3: "muito do meu trabalho.",
    p_4: "gostar",
    p_5: "Я очень нравится моя работа.",
  },
  {
    p_1: "Eles",
    p_2: "jogam",
    p_3: " futebol todos os domingos.",
    p_4: "jogar",
    p_5: "Они играют в футбол каждое воскресенье.",
  },
  {
    p_1: "Nunca me",
    p_2: "levanto",
    p_3: " tarde.",
    p_4: "levantar",
    p_5: "Я никогда не встаю поздно.",
  },
  {
    p_1: "Normalmente nós ",
    p_2: "ficamos",
    p_3: "em casa à noite. ",
    p_4: "ficar",
    p_5: "Обычно, мы остаемся дома по вечерам.",
  },
  {
    p_1: "Ele  ",
    p_2: "lava",
    p_3: "o carro ao fim de semana.",
    p_4: "lavar",
    p_5: "Он моет машину на выходных.",
  },
  {
    p_1: "Ela",
    p_2: "apanha",
    p_3: "sempre o autocarro das 8h00.",
    p_4: "apanhar",
    p_5: "Она всегда ловит автобус в 8.00",
  },
  {
    p_1: "O meu filho",
    p_2: "usa",
    p_3: " óculos.",
    p_4: "usar",
    p_5: "Мой сын носит очки.",
  },
  {
    p_1: "O filme ",
    p_2: "começa",
    p_3: " às 21h30.",
    p_4: "começar",
    p_5: "Фильм начнется в 21.30",
  },
  {
    p_1: "O filme  ",
    p_2: "acaba",
    p_3: " às 21h31.",
    p_4: "acabar",
    p_5: "Фильм закончится в 21.31",
  },
  {
    p_1: "Ela",
    p_2: "toca",
    p_3: "piano muito bem.",
    p_4: "tocar",
    p_5: "Она играет на пианино очень хорошо.",
  },
  {
    p_1: "Nos ",
    p_2: "falamos",
    p_3: "português. ",
    p_4: "falar",
    p_5: "Мы говорим по португальски.",
  },
  {
    p_1: "Eu não ",
    p_2: "trabalho",
    p_3: "aos fins de semana. ",
    p_4: "trabalhar",
    p_5: "Я не работаю по  выходным.",
  },
  {
    p_1: "O Pedro",
    p_2: "gosta",
    p_3: "de futebol.",
    p_4: "gostar",
    p_5: "Педро нравится футбол.",
  },
  {
    p_1: "Eles",
    p_2: "andam",
    p_3: "na universidade. ",
    p_4: "andar",
    p_5: "Она ходит (учится) в университет.",
  },
  {
    p_1: "O António e a Carla",
    p_2: "estudam",
    p_3: "medicina. ",
    p_4: "estudar",
    p_5: "Антонио и Клара изучают медицину.",
  },
  {
    p_1: "Depois do almoço eu",
    p_2: "tomo",
    p_3: "sempre um café. ",
    p_4: "tomar",
    p_5: "После обеда я пью (принимаю) кофе.",
  },
  {
    p_1: "Quem é que",
    p_2: "paga",
    p_3: "a conta? ",
    p_4: "pagar",
    p_5: "Кто оплачивает счет?",
  },
  {
    p_1: "Ele ",
    p_2: "telefona",
    p_3: "aos pais todos os dias. ",
    p_4: "telefonar",
    p_5: "Он звонит родителям каждый день.",
  },
  {
    p_1: "A que horas é que tu",
    p_2: "jantas",
    p_3: "? ",
    p_4: "jantar",
    p_5: "В каком часу ты ужинаешь?",
  },
  {
    p_1: "Onde é que vocês se",
    p_2: "encontram",
    p_3: "hoje à noite?",
    p_4: "encontrar",
    p_5: "Где вы встречаетесь сегодня вечером?",
  },
  {
    p_1: "Ela  ",
    p_2: "ganha",
    p_3: " bem na nova empresa.",
    p_4: "ganhar",
    p_5: "Она зарабатывает хорошо в новой компании.",
  },
  {
    p_1: "As crianças  ",
    p_2: "brincam",
    p_3: "no parque todos os domingos. ",
    p_4: "brincar",
    p_5: "Дети играют в парке каждое воскресенье.",
  },
  {
    p_1: "Ao pequeno-almoço nós",
    p_2: "bebemos",
    p_3: "café com leite.",
    p_4: "beber",
    p_5: "На завтрак мы пьем кофе с молоком.",
  },
  {
    p_1: "Ao pequeno-almoço nós",
    p_2: "comemos",
    p_3: "pão com manteiga.",
    p_4: "comer",
    p_5: "На завтрак мы едим хлеб с маслом.",
  },
  {
    p_1: "Vocês",
    p_2: "aprendem",
    p_3: "português numa escola de línguas?",
    p_4: "aprender",
    p_5: "Вы изучаете португальский в языковой школе?",
  },
  {
    p_1: "Ela ",
    p_2: "parece",
    p_3: " -se muito com o pai.",
    p_4: "parecer",
    p_5: "Она очень похожа на своего отца.",
  },
  {
    p_1: "Eles agora",
    p_2: "vivem",
    p_3: "no Porto.",
    p_4: "viver",
    p_5: "Они сейчас живут в Порто.",
  },
  {
    p_1: "No verão raramente",
    p_2: "chove",
    p_3: ".",
    p_4: "chover",
    p_5: "Летом редко идут дожди.",
  },
  {
    p_1: "Quando ele está de férias",
    p_2: "escreve",
    p_3: "sempre aos amigos. ",
    p_4: "escrever",
    p_5: "Когда он в отпуске, он всегда пишет своим друзьям.",
  },
  {
    p_1: "O texto é dificil. Eu não ",
    p_2: "compreendo",
    p_3: "nada.",
    p_4: "compreender",
    p_5: "Текст сложный. Я не понял ничего.",
  },
  {
    p_1: "Quando o telefone toca, é sempre o filho que",
    p_2: "atende",
    p_3: ".",
    p_4: "atender",
    p_5: "Когда звонит телефон, всегда отвечает мой сын.",
  },
  {
    p_1: "Tu ",
    p_2: "esquces",
    p_3: " -te sempre do nome dela.",
    p_4: "esqucer",
    p_5: "Ты всегда забываешь ее имя.",
  },
  {
    p_1: "Eu ",
    p_2: "desco",
    p_3: "esta rua todos os dias para apanhar o autocarro.",
    p_4: "descer",
    p_5: "Я использую эту улицу каждый день, чтобы сесть на автобус.",
  },
  {
    p_1: "Ela não  ",
    p_2: "conhece ",
    p_3: "a professora de português.",
    p_4: "conhecer",
    p_5: "Она не знает учительницу португальского языка.",
  },
  {
    p_1: "Quem é que ",
    p_2: "responde",
    p_3: "a esta pergunta?",
    p_4: "responder",
    p_5: "Кто ответит на этот вопрос?",
  },
  {
    p_1: "Eu nunca ",
    p_2: "vejo ",
    p_3: "televisão.",
    p_4: "ver",
    p_5: "Я никогда не смотрю телевизор.",
  },
  {
    p_1: "Ela",
    p_2: "faz ",
    p_3: "anos hoje.",
    p_4: "fazer",
    p_5: "Она празднует день рождения.",
  },
  {
    p_1: "Amanhã (eu) ",
    p_2: "faço ",
    p_3: "uma festa em casa.",
    p_4: "fazer",
    p_5: "Завтра я делаю вечеринку дома.",
  },
  {
    p_1: "(Eu) não ",
    p_2: "sei ",
    p_3: "o nome dela.",
    p_4: "saber",
    p_5: "Я не знаю его имя.",
  },
  {
    p_1: "O Sr. Ramos ",
    p_2: "lê ",
    p_3: "o jornal todos os dias.",
    p_4: "ler",
    p_5: "Сеньор Рамос читает газету каждый день.",
  },
  {
    p_1: "Eu",
    p_2: "trago",
    p_3: "uma prenda para a Ana. ",
    p_4: "trazer",
    p_5: "Я несу подарок для Анны.",
  },
  {
    p_1: "Eu não ",
    p_2: "posso",
    p_3: "sair à noite.",
    p_4: "poder",
    p_5: "Я не могу выходить ночью.",
  },
  {
    p_1: "Eles",
    p_2: "trazem ",
    p_3: "os livros na pasta.",
    p_4: "trazer",
    p_5: "Они носят книги в портфеле.",
  },
  {
    p_1: "Eu",
    p_2: "leio ",
    p_3: "o jornal todos os dias.",
    p_4: "ler",
    p_5: "Я читаю газету каждый день.",
  },
  {
    p_1: "Ela ",
    p_2: "sabe",
    p_3: "falar muitas linguas.",
    p_4: "saber",
    p_5: "Она может говорить на многих языках.",
  },
  {
    p_1: "A empregada",
    p_2: "traz ",
    p_3: "o pão de manhã.",
    p_4: "trazer",
    p_5: "Горничная приносит хлеб утром.",
  },
  {
    p_1: "Hoje (eu) ",
    p_2: "quero",
    p_3: "ficar em casa.",
    p_4: "querer",
    p_5: "Сегодня я хочу остаться дома.",
  },
  {
    p_1: "Ele ",
    p_2: "vê ",
    p_3: "mal ao longe.",
    p_4: "ver",
    p_5: "Он плохо видит далеко.",
  },
  {
    p_1: "Eu já ",
    p_2: "leio ",
    p_3: "o jornal em português.",
    p_4: "ler",
    p_5: "Я уже читаю газеты на португальском.",
  },
  {
    p_1: "Eu nunca ",
    p_2: "perco ",
    p_3: "o chapéu de chuva.",
    p_4: "perder",
    p_5: "Я никогда не теряю свой зонтик.",
  },
  {
    p_1: "O empregado ",
    p_2: "serve",
    p_3: "o café à mesa. ",
    p_4: "server",
    p_5: "Официант подает кофе на стол.",
  },
  {
    p_1: "Ela ",
    p_2: "traduz ",
    p_3: "romances para inglês.",
    p_4: "traduzir",
    p_5: "Она переводит романы на английский.",
  },
  {
    p_1: "O senhor ",
    p_2: "segue ",
    p_3: "sempre em frente.",
    p_4: "seguir",
    p_5: "Вы всегда двигаетесь вперед.",
  },
  {
    p_1: "Os bancos ",
    p_2: "abrem",
    p_3: "às 8h30.",
    p_4: "abrir",
    p_5: "Банки открываются в 8.30.",
  },
  {
    p_1: "Ela ",
    p_2: "divide ",
    p_3: "o bolo com os irmãos.",
    p_4: "dividir",
    p_5: "Она делит торт с братьями.",
  },
  {
    p_1: "Eu",
    p_2: "prefiro ",
    p_3: "ficar em casa.",
    p_4: "prefirir",
    p_5: "Я предпочитаю остаться дома.",
  },
  {
    p_1: "O avião ",
    p_2: "parte ",
    p_3: "às 17h00.",
    p_4: "partir",
    p_5: "Самолет отправляется в 17.",
  },
  {
    p_1: "Tu nunca ",
    p_2: "admites ",
    p_3: "os teus erros.",
    p_4: "admitir",
    p_5: "Ты никогда не признаешь своих ошибок.",
  },
  {
    p_1: "Eu não ",
    p_2: "consigo ",
    p_3: "estudar com barulho.",
    p_4: "conseguir",
    p_5: "Я не могу учиться в шуме.",
  },
  {
    p_1: "No início da aula o professor ",
    p_2: "introduz ",
    p_3: "a matéria nova.",
    p_4: "introduzir",
    p_5: "В начале урока учитель представил новый материал.",
  },
  {
    p_1: "Eu hoje ",
    p_2: "sinto-me ",
    p_3: "muito cansado.",
    p_4: "sentir-se",
    p_5: "Я сегодня чувствую себя очень усталым.",
  },
  {
    p_1: "Quando se abre a porta, o cão ",
    p_2: "foge",
    p_3: "logo para rua.",
    p_4: "fugir",
    p_5: "Когда дверь открывается, собака тут же выбегает на улицу.",
  },
  {
    p_1: "Todos se",
    p_2: "riem",
    p_3: "das anedotas que ele conta.",
    p_4: "rir",
    p_5: "Все смеются над его шутками.",
  },
  {
    p_1: "Aos sábados, eu",
    p_2: "durmo",
    p_3: "sempre até mais tarde.",
    p_4: "dormir",
    p_5: "По субботам я сплю всегда до поздна.",
  },
  {
    p_1: "Eles ",
    p_2: "vem",
    p_3: "ter connosco cá a casa.",
    p_4: "vir",
    p_5: "Они приходят к нам в дом.",
  },
  {
    p_1: "Eu ",
    p_2: "vou",
    p_3: "a conserto com Rita.",
    p_4: "ir",
    p_5: "Я иду на концерт с Ритой.",
  },
  {
    p_1: "Senhoras e senhores, ",
    p_2: "peço",
    p_3: "a vossa atenção, por favor.",
    p_4: "pedir ",
    p_5: "Сеньоры и сеньоры, прошу вашего внимания.",
  },
  {
    p_1: "Ele",
    p_2: "sobe",
    p_3: "às árvores como um macaco!",
    p_4: "subir",
    p_5: "Он взбирается по деревьям, как обезьяна!",
  },
  {
    p_1: "Fala mais alto. Não",
    p_2: "ouço",
    p_3: "nada.",
    p_4: "ouvir",
    p_5: "Говори громче. Я ничего не слышу.",
  },
  {
    p_1: "A que horas é que  tu ",
    p_2: "sais",
    p_3: "de casa?",
    p_4: "sair",
    p_5: "В какое время ты выходишь из дома?",
  },
  {
    p_1: "No outono, as folhas",
    p_2: "caem",
    p_3: "e as árvores ficam despidas.",
    p_4: "cair",
    p_5: "Осенью опадают листья и деревья оголяются.",
  },
  {
    p_1: "Eu hoje ",
    p_2: "sinto-me ",
    p_3: "muito cansado.",
    p_4: "sentir-se",
    p_5: "Я сегодня чувствую себя очень усталым.",
  },
];
