import { useEffect, useState } from "react";
import AppHeader from "../app-header/app-header";
import Cards from "../cards/cards";
import { aone } from "../../utils/aone";
import { shuffle } from "../../utils/convert";
import styles from "./listtem.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { tems, tems_1, tems_2 } from "../../utils/team";
import { slova } from "../../utils/slova";

type TProps = {
  setslova: React.Dispatch<
    React.SetStateAction<
      {
        ru: string;
        port: string;
      }[]
    >
  >;
  setname: React.Dispatch<React.SetStateAction<string>>;
  setv: React.Dispatch<React.SetStateAction<boolean>>;
};

function Listtem(props: TProps) {
  const [base, setbase] = useState(true);
  const [name, setname] = useState({
    name: "Основые понятия 1",
    nom: tems_1,
  });
  const [active, setactive] = useState(false);

  const [isVisible, setVisible] = useState(false);

  const handleClick = (nom: string[], name: string) => {};

  const handleVisibility = () => {
    setVisible(!isVisible);
  };

  const buttonHandler_2 = (name: string) => {
    const slovas = slova.find((element) => element.name === name);
    if (slovas) {
      props.setslova(slovas.dict);
      props.setname(name);
      props.setv(false);
      console.log(slovas);
      setVisible(false);
    }
  };

  const pVariants = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <div className={styles.list_team}>
      <motion.div
        initial={"hidden"}
        animate={"visible"}
        transition={{
          delay: 0.1,
          duration: 0.2,
        }}
        variants={pVariants}
        className={styles.list}
      >
        {tems.map((item, index) => {
          return (
            <motion.div
              className={styles.list_item}
              key={index}
              whileHover={{ scale: 1.1 }}
              onClick={() => {
                setname(item);
                setVisible(true);
                console.log(name);
              }}
            >
              {item.name}
            </motion.div>
          );
        })}
      </motion.div>
      {isVisible && (
        <motion.div
          initial={"hidden"}
          animate={"visible"}
          transition={{
            delay: 0.1,
            duration: 0.2,
          }}
          variants={pVariants}
          className={styles.list}
        >
          {name.nom.map((iten, indexx) => {
            return (
              <motion.div
                className={styles.list_item}
                key={indexx}
                whileHover={{ scale: 1.1 }}
                onClick={() => {
                  buttonHandler_2(iten);
                }}
              >
                {iten}
              </motion.div>
            );
          })}
        </motion.div>
      )}
    </div>
  );
}

export default Listtem;
/*{isVisible && (
  <motion.div
    initial={"hidden"}
    animate={"visible"}
    transition={{
      delay: 0.4,
      duration: 1,
    }}
    variants={pVariants}
    className={styles.list}
  >
    {name.nom.map((iten, indexx) => {
      return (
        <motion.div
          className={styles.list_item}
          key={indexx}
          whileHover={{ scale: 1.1 }}
          onClick={() => {}}
        >
          {iten}
        </motion.div>
      );
    })}
  </motion.div>
)}*/
