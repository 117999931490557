import { useRef, useState } from "react";
import styles from "./sentensconst.module.css";
import { divid, randomInteger } from "../../utils/convert";
import dog from "../../images/smile.png";

type Twww = {
  port: string;
  rus: string;
};

type Tone = {
  sone: string;
  id: number;
  order: number;
};

type TOrderypeprops = {
  slovo: Twww[];
  trans: string;
};

function SentensСonstOn(props: TOrderypeprops) {
  const list = props.slovo;
  const button_2 = useRef<HTMLButtonElement | null>(null);
  const [slovo, setSlovo] = useState(list[0]);
  const [liston, setListon] = useState(divid(slovo.port));
  const [ok, setOk] = useState(false);
  const [cardon, setCardon] = useState(liston[0]);
  const [sentenser, setSentenser] = useState(slovo.rus);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
    }
  };
  /********************************************************* */
  const [{ dx, dy }, setOffset] = useState({
    dx: 0,
    dy: 0,
  });

  const [startPos, setStartPos] = useState({
    x: 0,
    y: 0,
  });
  const [endPos, setEndPos] = useState({
    x: 0,
    y: 0,
  });
  const [div_r, setdiv] = useState<Element | null>(null);
  type Tmap = {
    div: HTMLElement;
    card: string;
    x: number;
    y: number;
    heigth: number;
    wigth: number;
  };
  const [map, setMap] = useState<Tmap[] | null>();
  const [mapb, setMapb] = useState<Tmap | null>();

  //***************************************************** */
  const touchStartHendler = (
    e: React.TouchEvent<HTMLDivElement>,
    card: Tone
  ) => {
    //console.log("start touch");
    setCardon(card);
    const touch = e.touches[0];
    const startP = {
      x: touch.clientX,
      y: touch.clientY,
    };

    setStartPos(startP);
    setEndPos(startP);
    const dx = 0;
    const dy = 0;
    setOffset({ dx, dy });
    const container = document.getElementById("box");

    if (container !== null) {
      const map_b1 = {
        div: container,
        card: "b",
        x: container.getBoundingClientRect().x,
        y: container.getBoundingClientRect().y,
        heigth: container.getBoundingClientRect().height,
        wigth: container.getBoundingClientRect().width,
      };
      if (map_b1) {
        setMapb(map_b1);
      }
    }

    let list_s;
    if (container) {
      list_s = container.querySelectorAll("div");
    }

    if (list_s) {
      const list_p: Tmap[] = [];
      list_s.forEach((item) => {
        const map_1 = {
          div: item,
          card: item.id,
          x: item.getBoundingClientRect().x,
          y: item.getBoundingClientRect().y,
          heigth: item.getBoundingClientRect().height,
          wigth: item.getBoundingClientRect().width,
        };
        list_p.push(map_1);
      });
      // console.log(list_p);
      setMap(list_p);
    }
  };

  const touchMoveHendler = (
    e: React.TouchEvent<HTMLDivElement>,
    card: Tone
  ) => {
    const touch = e.touches[0];
    const div = e.touches[0].target;
    const ele = div as HTMLDivElement;
    //console.log("start touch");
    const dx = touch.clientX - startPos.x;
    const dy = touch.clientY - startPos.y;
    // const ele = eleRef.current;

    // Set the position of element
    if (!ele) {
      return;
    }

    setdiv(ele.offsetParent);
    ele.style.transform = `translate(${dx}px,${dy}px)`;
    setOffset({ dx, dy });
    const endP = {
      x: touch.clientX,
      y: touch.clientY,
    };
    setEndPos(endP);
    if (mapb !== null && mapb) {
      if (
        touch.clientX > mapb.x &&
        touch.clientX < mapb.x + mapb.wigth &&
        touch.clientY > mapb.y &&
        touch.clientY < mapb.y + mapb.heigth
      ) {
        if (map !== null && map) {
          map.forEach((item) => {
            if (
              touch.clientX > item.x &&
              touch.clientX < item.x + item.wigth &&
              touch.clientY > item.y &&
              touch.clientY < item.y + item.heigth
            ) {
              if (ele === item.div) {
                item.div.style.opacity = "0.6";
              } else {
                item.div.style.boxShadow = "5px 5px 5px rgb(4, 192, 206)";
              }
            } else {
              item.div.style.boxShadow = "0 0 0 0";
              item.div.style.opacity = "1";
            }
          });
        }
      }
    }
  };

  const touchEndHendler = (e: React.TouchEvent<HTMLDivElement>, card: Tone) => {
    //console.log("end");
    //console.log(e.target);
    //console.log(e);
    const ele = e.target;
    const div = ele as HTMLDivElement;

    if (!map) {
      return;
    }
    if (!mapb) {
      return;
    }
    const list_fin: Tone[] = [];
    if (
      endPos.x > mapb.x &&
      endPos.x < mapb.x + mapb.wigth &&
      endPos.y > mapb.y &&
      endPos.y < mapb.y + mapb.heigth
    ) {
      map.forEach((item) => {
        if (
          endPos.x > item.x &&
          endPos.x < item.x + item.wigth &&
          endPos.y > item.y &&
          endPos.y < item.y + item.heigth
        ) {
          const noml = liston.filter((tt) => tt.sone === item.card);
          let nom = noml[0];
          const neworder_c = nom.order;

          liston.forEach((it) => {
            let neworder = it.order;
            if (it === nom) {
              neworder = card.order;
            }
            if (it === card) {
              neworder = neworder_c;
            }

            list_fin.push({
              sone: it.sone,
              id: it.id,
              order: neworder,
            });
          });
        } else {
        }
      });
      if (list_fin.length === liston.length) {
        let rot = true;

        list_fin.map((item) => {
          if (item.order !== item.id) {
            rot = false;
          }
        });

        setOk(rot);
        setListon(list_fin);
      }

      div.style.transform = `none`;
    } else {
      div.style.transform = `translate(${0}px,${0}px)`;
    }
    setOffset({
      dx: 0,
      dy: 0,
    });

    setStartPos({
      x: 0,
      y: 0,
    });
    setEndPos({
      x: 0,
      y: 0,
    });
    setdiv(null);
    setMap(null);
    setMapb(null);
  };

  //************************************************************ */
  const buttonHandler_1 = () => {
    const novo = props.slovo[randomInteger(0, props.slovo.length)];
    setSlovo(novo);
    let slovo_n = divid(novo.port);
    setOk(false);
    setListon(slovo_n);
    setCardon(liston[0]);
    setSentenser(novo.rus);
    //console.log("55555555555555");
    //console.log(novo);
    //console.log(slovo);
  };
  //*************************************** */
  const onClick = () => {
    if (sentenser === slovo.rus) {
      setSentenser(slovo.port);
    } else {
      setSentenser(slovo.rus);
    }
  };
  //****************************************************************************** */
  function dragStartHendler(
    e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    card: Tone
  ) {
    setCardon(card);
    //console.log("start mouse");
    e.currentTarget.style.boxShadow = "0 0 0 0";
  }

  function dragLeaveHendler(e: React.DragEvent<HTMLDivElement>) {
    e.currentTarget.style.boxShadow = "0 0 0 0";
    //console.log("move mouse");
  }
  function dragEndHendler(
    e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    card: Tone
  ) {
    let rot = true;
    //console.log("end mouse");
    if (liston) {
      liston.map((item) => {
        if (item.order !== item.id) {
          rot = false;
        }
      });
    }
    setOk(rot);
    e.currentTarget.style.boxShadow = "0 0 0 0";
  }

  function dragOverHendler(e: React.DragEvent<HTMLDivElement>, card: Tone) {
    e.preventDefault();
    e.currentTarget.style.boxShadow = "0 5px 5px 5px rgb(93, 89, 153)";
    //console.log("over mouse");
  }

  function dropHendler(
    e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    card: Tone
  ) {
    e.preventDefault();
    //console.log("drop mouse");

    if (cardon) {
      setListon(
        liston.map((c) => {
          if (c.id === card.id) {
            return { ...c, order: cardon.order };
          }
          if (c.id === cardon.id) {
            return { ...c, order: card.order };
          }
          return c;
        })
      );
    }
    e.currentTarget.style.boxShadow = "0 0 0 0";
  }

  const sortCards = (a: Tone, b: Tone) => {
    if (a.order > b.order) {
      return 1;
    } else {
      return -1;
    }
  };
  //************************************************************************************ */
  return (
    <div className={styles.main}>
      <div className={styles.dragon} id="box">
        {liston.sort(sortCards).map((item) => {
          if (item.id === item.order) {
            return (
              <div
                className={`${styles.menuButton_11} ${styles.menuButton} `}
                key={item.id}
                draggable={true}
                id={item.sone}
                onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
                  dragStartHendler(e, item)
                }
                onDragLeave={(e: React.DragEvent<HTMLDivElement>) =>
                  dragLeaveHendler(e)
                }
                onDragEnd={(e: React.DragEvent<HTMLDivElement>) =>
                  dragEndHendler(e, item)
                }
                onDragOver={(e: React.DragEvent<HTMLDivElement>) =>
                  dragOverHendler(e, item)
                }
                onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                  dropHendler(e, item)
                }
                onTouchStart={(e: React.TouchEvent<HTMLDivElement>) =>
                  touchStartHendler(e, item)
                }
                onTouchMove={(e: React.TouchEvent<HTMLDivElement>) =>
                  touchMoveHendler(e, item)
                }
                onTouchEnd={(e: React.TouchEvent<HTMLDivElement>) => {
                  touchEndHendler(e, item);
                }}
              >
                {item.sone}
              </div>
            );
          } else {
            return (
              <div
                className={styles.menuButton}
                key={item.id}
                id={item.sone}
                draggable={true}
                onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
                  dragStartHendler(e, item)
                }
                onDragLeave={(e: React.DragEvent<HTMLDivElement>) =>
                  dragLeaveHendler(e)
                }
                onDragEnd={(e: React.DragEvent<HTMLDivElement>) =>
                  dragEndHendler(e, item)
                }
                onDragOver={(e: React.DragEvent<HTMLDivElement>) =>
                  dragOverHendler(e, item)
                }
                onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                  dropHendler(e, item)
                }
                onTouchStart={(e: React.TouchEvent<HTMLDivElement>) =>
                  touchStartHendler(e, item)
                }
                onTouchMove={(e: React.TouchEvent<HTMLDivElement>) =>
                  touchMoveHendler(e, item)
                }
                onTouchEnd={(e: React.TouchEvent<HTMLDivElement>) => {
                  touchEndHendler(e, item);
                }}
              >
                {item.sone}
              </div>
            );
          }
        })}
      </div>
      {ok === true && (
        <div className={styles.preview}>
          <img src={dog} alt="dog" />
        </div>
      )}
      {ok === true && (
        <div className={styles.p_1}>
          <p className={`${styles.menuButton_4} ${styles.menuButton} `}>
            {`Ура, это правильно: ${slovo.port}`}
          </p>
        </div>
      )}
      {props.trans === "Показывать перевод" && (
        <div className={styles.p_1} onClick={onClick}>
          <p className={`${styles.menuButton_3} ${styles.menuButton} `}>
            {sentenser}
          </p>
        </div>
      )}
      <button
        className={styles.but}
        onClick={() => buttonHandler_1()}
        ref={button_2}
      >
        еще...
      </button>
      <div className={`${styles.menuButton_1} ${styles.menuButton} `}>
        Можно увидеть подсказку кликнув на перевод.
      </div>
    </div>
  );
}

export default SentensСonstOn;
