export const tems_1 = [
  "Местоимения",
  "Числа от 1 до 10",
  "Числа от 10 до 100",
  "Числа от 100",
  "Дроби",
  "Математические действия",
  "Числа. Разное",
  "Самые важные глаголы - 1",
  "Самые важные глаголы - 2",
  "Самые важные глаголы - 3",
  "Самые важные глаголы - 4",
  "Цвета",
  "Вопросы",
  "Основные предлоги",
  "Наречия - 1",
  "Наречия - 2",
];

export const tems_2 = [
  "Дни недели",
  "Часы. Время суток",
  "Месяцы. Времена года",
  "Время. Разное",
  "Противоположности",
  "Вводные и служебные слова",
  "Линии и формы",
  "Меры измерения",
  "Емкости",
  "Материалы",
  "Металлы",
  "Термины общие",
];

export const tems_3 = ["Человек.Общие понятия", "Анатомия", "Голова", "Тело"];

export const tems_4 = [
  "Верхняя одежда",
  "Одежда",
  "Белье",
  "Головные уборы",
  "Обувь",
  "Ткани, материалы",
  "Аксессуары",
  "Одежда разное",
  "Предметы личной гигиены, косметика",
  "Украшения",
  "Часы",
];
export const tems_5 = [
  "Продукты",
  "Напитки",
  "Овощи",
  "Фрукты, орехи",
  "Сладости, хлеб",
  "Блюда",
  "Приправы.Специи",
  "Прием пищи",
  "Сервировка стола",
  "Ресторан",
];
export const tems_6 = [
  "Анкета. Анкетные данные",
  "Семья, родственники",
  "Друзья, знакомые, коллеги",
  "Женщина, мужчина",
  "Возраст",
  "Ребенок",
  "Романтические отношения",
];
export const tems_7 = [
  "Состояние человека",
  "Черты характра, личность",
  "Сон",
  "Юмор, смех, радость",
  "Общение, диалог - 1",
  "Общение, диалог - 2",
  "Общение, диалог - 3",
  "Согласие, несогласие, одобрение",
  "Успех, удача, поражение",
  "Обиды, ссора, негативные эмоции",
];

export const tems_8 = [
  "Болезни",
  "Симтомы, лечение - 1",
  "Симтомы, лечение - 2",
  "Симтомы, лечение - 3",
  "Врачи",
  "Лекарства, принадлежности",
  "Курение, табак",
];
export const tems_9 = [
  "Город. Жизнь в городе",
  "Городские учреждения",
  "Достопримечательности",
  "Покупки",
  "Деньги",
  "Почта",
];
export const tems_10 = [
  "Дом, жилище",
  "Дом, подьезд, лифт",
  "Дом, электричество",
  "Дом, дверь, замок",
  "Загородный дои, Дом в деревне",
  "Особняк, вилла",
  "Замок, дворец",
  "Квартира",
  "Уборка",
  "Мебель, интерьер",
  "Постельные принадлежности",
  "Кухня",
  "Ванная комата",
  "Бытовая техника",
  "Ремонт",
  "Водопровод",
  "Пожар",
];
export const tems_11 = [
  "Офис, работа в офисе",
  "Работа - 1",
  "Работа - 2",
  "Контракт",
  "Производство",
  "Импорт, экспорт",
  "Финансы",
  "Маркетинг",
  // "Реклама",
  //"Банк",
  // "Телефон, общение по телефону",
  "Мобильный телефон",
  "Канцелярия",
  "Документы",
  "Виды бизнеса",
  "Выставка",
  "СМИ",
  "Сельское хозяйство",
  "Стройка",
  "Наука",
];
export const tems_12 = [
  "Поиск работы, увольнение",
  "Люди в бизнесе",
  "Профессии в сфере услуг",
  "Государсвенные служащие",
  "Профессии в сельском хозяйстве",
  "Профессии в искусстве",
  "Профессии разные",
  "Социальное положение",
];
export const tems_13 = [
  "Виды спорта",
  "Спортивный зал",
  "Хоккей",
  "Футбол",
  "Горные лыжи",
  "Теннис, гольф",
  "Шахматы",
  "Бокс",
  "Спорт разное",
];
export const tems_14 = [
  "Школа",
  "Вуз",
  "Науки и дисциплины",
  "Письмо, части речи, орфография",
  //"Изучение иностранных языков",
  "Сказочные персонажи",
  "Знаки зодиака",
];

export const tems_15 = [
  "Театр",
  "Кино",
  "Изобразительное исскуство",
  "Литература, поэзия",
  "Цирк",
  "Музыка, эстрада",
];
export const tems_16 = [
  "Турпоездка",
  "Гостиница",
  "Книги, чтение",
  "Охота, рыбалка",
  "Игры",
  "Казино",
  "Отдых разное",
  "Фотография",
  "Пляж",
];
export const tems_17 = [
  "Компьютер",
  "Интернет",
  "Электричество",
  "Инструменты",
];
export const tems_18 = [
  //"Самолет",
  "Поезд",
  "Корабль",
  "Аэропорт",
  "Велосипед, мотоцикл",
];
export const tems_19 = [
  "Автомобиль виды",
  "Кузов",
  "Автомобиль салон",
  "Автомобиль двигатель",
  //"Авария, ремонт",
  "Дорога",
  "Дорожные знаки",
];

export const tems_21 = [
  "Праздник",
  "Похороны",
  "Война",
  "Война - 2",
  "Оружие",
  "Первобытные люди",
  "Средние века",
  "Правитель, шеф, руководитель",
  "Дорога, путь",
  "Нарушение закона -1",
  "Нарушение закона - 2",
  "Полиция - 1",
  "Полиция - 2",
];

export const tems_22 = [
  "Космос",
  "Земля",
  "Части света",
  "Море, океан",
  //"Названия морей и океанов",
  "Горы",
  "Названия гор",
  "Реки",
  //"Названия рек",
  "Лес",
  "Природные ресурсы",
];
export const tems_23 = ["Погода", "Стихия", "Шумы, звуки", "Зима"];
export const tems_24 = [
  "Млекопитающие",
  "Дикие животные",
  "Домашние животные",
  "Собаки, породы",
  "Звуки животных",
  "Детеныши животных",
  "Птицы",
  "Рыбы, морские животные",
  "Земноводные, пресмыкающиеся",
  "Насекомые, беспозвоночные",
  "Части тела животных",
  "Действия животных",
  "Среда обитания",
  "Разное",
  "Лошади",
];
export const tems_25 = [
  "Деревья",
  "Кустарники",
  "Грибы",
  "Фрукты,ягоды",
  "Цветы, растения",
  "Зерновые",
  "Овощи, зелень",
];
export const tems_26 = [
  "Западная Европа",
  "Центральная и Восточная Европа",
  "Страны бывшего СССР",
  "Азия",
  // "Северная Америка",
  "Центральная и Южная Америка",
  "Африка",
  "Австралия и Океания",
  "Города",
  //"Дорога, путь",
  "Политика -1",
  "Политика - 2",
  "Страны разное",
  "Мировые религии",
];

export const tems_27 = [
  "Глаголы А-Е",
  "Глаголы Ж-М",
  "Глаголы Н-О",
  "Глаголы П",
  "Глаголы Р-Т",
  "Глаголы У-Я",
  "Наиболее употребляемые глаголы 0-100",
  "Наиболее употребляемые глаголы 100-200",
  "Наиболее употребляемые глаголы 200-300",
  "Наиболее употребляемые глаголы 300-400",
];

export const tems_28 = [
  "Прилагательные - 1",
  "Прилагательные - 2",
  "Прилагательные - 3",
  "Прилагательные - 4",
  "Прилагательные - 5",
];
export const tems_29 = [
  "Неделя - 1",
  "Неделя - 2",
  "Неделя - 3",
  "Неделя - 4",
  "Неделя - 5",
];

export const tems = [
  { name: "Основые понятия 1", nom: tems_1 },
  { name: "Основые понятия 2", nom: tems_2 },
  { name: "Человек", nom: tems_3 },
  { name: "Одежда", nom: tems_4 },
  { name: "Питание", nom: tems_5 },
  { name: "Семья, окружающие", nom: tems_6 },
  { name: "Чувства, Черты характера", nom: tems_7 },
  { name: "Медицина", nom: tems_8 },
  { name: "Город", nom: tems_9 },
  { name: "Жилище", nom: tems_10 },
  { name: "Работа, бизнес", nom: tems_11 },
  { name: "Профессии", nom: tems_12 },
  { name: "Спорт", nom: tems_13 },
  { name: "Образование", nom: tems_14 },
  { name: "Исскуство", nom: tems_15 },
  { name: "Отдых, развлечения", nom: tems_16 },
  { name: "Техника", nom: tems_17 },
  { name: "Транспорт", nom: tems_18 },
  { name: "Автомобиль", nom: tems_19 },
  { name: "События в жизни человека", nom: tems_21 },
  { name: "Планета", nom: tems_22 },
  { name: "Погода", nom: tems_23 },
  { name: "Фауна", nom: tems_24 },
  { name: "Флора", nom: tems_25 },
  { name: "Страны", nom: tems_26 },
  { name: "Прилагательные", nom: tems_28 },
  { name: "Глаголы", nom: tems_27 },
  { name: "Слова недели", nom: tems_29 },
];
