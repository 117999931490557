import styles from "./perfwrite.module.css";
import { verb_p } from "../../utils/verbopast";
import WriterVerb from "../writerverb/writerverb";
import AppHeader from "../app-header/app-header";

function ImperfeitoWrite() {
  return (
    <div className={styles.main}>
      <AppHeader name={"Пиши глаголы"}></AppHeader>
      <h1 className={styles.title}>Pretérito perfeito simples do indicativo</h1>
      <WriterVerb list={verb_p}></WriterVerb>
    </div>
  );
}
export default ImperfeitoWrite;
