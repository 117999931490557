export type Tverb = {
  ru: string;
  port: string;
  eu: string;
  tu: string;
  ele: string;
  nos: string;
  eles: string;
  spr: string;
};

export const verb_Im = [
  {
    ru: "глаголы -ar",
    port: "...ar",
    eu: "-ava",
    tu: "-avas",
    ele: "-ava",
    nos: "-ávamos",
    eles: "-avam",
    spr: "1",
  },
  {
    ru: "глаголы -er",
    port: "...er",
    eu: "-ia",
    tu: "-ias",
    ele: "-ia",
    nos: "-íamos",
    eles: "-iam",
    spr: "2",
  },
  {
    ru: "глаголы -ir",
    port: "...ir",
    eu: "-ia",
    tu: "-ias",
    ele: "-ia",
    nos: "-íamos",
    eles: "-iam",
    spr: "3",
  },
  {
    ru: "не правильные",
    port: " ",
    eu: " ",
    tu: "",
    ele: " ",
    nos: " ",
    eles: " ",
    spr: "4",
  },
];

export const verb_im = [
  {
    ru: "заканчивать",
    port: "acabar",
    eu: "acabava",
    tu: "acabavas",
    ele: "acabava",
    nos: "acabávamos",
    eles: "acabavam",
    spr: "1",
  },
  {
    ru: "принимать",
    port: "aceitar",
    eu: "aceitava",
    tu: "aceitavas",
    ele: "aceitava",
    nos: "aceitávamos",
    eles: "aceitavam",
    spr: "1",
  },
  {
    ru: "думать",
    port: "achar",
    eu: "achava",
    tu: "achavas",
    ele: "achava",
    nos: "achávamos",
    eles: "achavam",
    spr: "1",
  },
  {
    ru: "сопровождать",
    port: "acompanhar",
    eu: "acompanhava",
    tu: "acompanhavas",
    ele: "acompanhava",
    nos: "acompanhávamos",
    eles: "acompanhavam",
    spr: "1",
  },
  {
    ru: "просыпаться",
    port: "acordar",
    eu: "acordava",
    tu: "acordavas",
    ele: "acordava",
    nos: "acordávamos",
    eles: "acordavam",
    spr: "1",
  },
  {
    ru: "обожать",
    port: "adorar",
    eu: "adorava",
    tu: "adoravas",
    ele: "adorava",
    nos: "adorávamos",
    eles: "adoravam",
    spr: "1",
  },
  {
    ru: "ждать",
    port: "aguardar",
    eu: "aguardava",
    tu: "aguardavas",
    ele: "aguardava",
    nos: "aguardávamos",
    eles: "aguardavam",
    spr: "1",
  },
  {
    ru: "помогать",
    port: "ajudar",
    eu: "ajudava",
    tu: "ajudavas",
    ele: "ajudava",
    nos: "ajudávamos",
    eles: "ajudavam",
    spr: "1",
  },
  {
    ru: "кормить",
    port: "alimentar",
    eu: "alimentava",
    tu: "alimentavas",
    ele: "alimentava",
    nos: "alimentávamos",
    eles: "alimentavam",
    spr: "1",
  },
  {
    ru: "обедать",
    port: "almoçar",
    eu: "almoçava",
    tu: "almoçavas",
    ele: "almoçava",
    nos: "almoçávamos",
    eles: "almoçavam",
    spr: "1",
  },
  {
    ru: "арендовать, снимать",
    port: "alugar",
    eu: "alugava",
    tu: "alugavas",
    ele: "alugava",
    nos: "alugávamos",
    eles: "alugavam",
    spr: "1",
  },
  {
    ru: "любить",
    port: "amar",
    eu: "amava",
    tu: "amavas",
    ele: "amava",
    nos: "amávamos",
    eles: "amavam",
    spr: "1",
  },
  {
    ru: "ходить",
    port: "andar",
    eu: "andava",
    tu: "andavas",
    ele: "andava",
    nos: "andávamos",
    eles: "andavam",
    spr: "1",
  },
  {
    ru: "брать, ловить",
    port: "apanhar",
    eu: "apanhava",
    tu: "apanhavas",
    ele: "apanhava",
    nos: "apanhávamos",
    eles: "apanhavam",
    spr: "1",
  },
  {
    ru: "насладжаться, прользоваться",
    port: "aproveitar",
    eu: "aproveitava",
    tu: "aproveitavas",
    ele: "aproveitava",
    nos: "aproveitávamos",
    eles: "aproveitavam",
    spr: "1",
  },
  {
    ru: "раздобыть",
    port: "arranjar",
    eu: "arranjava",
    tu: "arranjavas",
    ele: "arranjava",
    nos: "arranjávamos",
    eles: "arranjavam",
    spr: "1",
  },
  {
    ru: "убирать",
    port: "arrumar",
    eu: "arrumava",
    tu: "arrumavas",
    ele: "arrumava",
    nos: "arrumávamos",
    eles: "arrumavam",
    spr: "1",
  },
  {
    ru: "переходить",
    port: "atravessar",
    eu: "atravessava",
    tu: "atravessavas",
    ele: "atravessava",
    nos: "atravessávamos",
    eles: "atravessavam",
    spr: "1",
  },
  {
    ru: "играть дети",
    port: "brincar",
    eu: "brincava",
    tu: "brincavas",
    ele: "brincava",
    nos: "brincávamos",
    eles: "brincavam",
    spr: "1",
  },
  {
    ru: "искать",
    port: "buscar",
    eu: "buscava",
    tu: "buscavas",
    ele: "buscava",
    nos: "buscávamos",
    eles: "buscavam",
    spr: "1",
  },
  {
    ru: "молчать",
    port: "calar",
    eu: "calava",
    tu: "calavas",
    ele: "calava",
    nos: "calávamos",
    eles: "calavam",
    spr: "1",
  },
  {
    ru: "обуваться",
    port: "calçar",
    eu: "calçava",
    tu: "calçavas",
    ele: "calçava",
    nos: "calçávamos",
    eles: "calçavam",
    spr: "1",
  },
  {
    ru: "петь",
    port: "cantar",
    eu: "cantava",
    tu: "cantavas",
    ele: "cantava",
    nos: "cantávamos",
    eles: "cantavam",
    spr: "1",
  },
  {
    ru: "жениться",
    port: "casar",
    eu: "casava",
    tu: "casavas",
    ele: "casava",
    nos: "casávamos",
    eles: "casavam",
    spr: "1",
  },
  {
    ru: "звать",
    port: "chamar",
    eu: "chamava",
    tu: "chamavas",
    ele: "chamava",
    nos: "chamávamos",
    eles: "chamavam",
    spr: "1",
  },
  {
    ru: "прибывать, приезжать",
    port: "chegar",
    eu: "chegava",
    tu: "chegavas",
    ele: "chegava",
    nos: "chegávamos",
    eles: "chegavam",
    spr: "1",
  },
  {
    ru: "плакать",
    port: "chorar",
    eu: "chorava",
    tu: "choravas",
    ele: "chorava",
    nos: "chorávamos",
    eles: "choravam",
    spr: "1",
  },
  {
    ru: "начинать",
    port: "começar",
    eu: "começava",
    tu: "começavas",
    ele: "começava",
    nos: "começávamos",
    eles: "começavam",
    spr: "1",
  },
  {
    ru: "покупать",
    port: "comprar",
    eu: "comprava",
    tu: "compravas",
    ele: "comprava",
    nos: "comprávamos",
    eles: "compravam",
    spr: "1",
  },
  {
    ru: "соглашаться",
    port: "concordar",
    eu: "concordava",
    tu: "concordavas",
    ele: "concordava",
    nos: "concordávamos",
    eles: "concordavam",
    spr: "1",
  },
  {
    ru: "считать, рассказывать",
    port: "contar",
    eu: "contava",
    tu: "contavas",
    ele: "contava",
    nos: "contávamos",
    eles: "contavam",
    spr: "1",
  },
  {
    ru: "продолжать",
    port: "continuar",
    eu: "continuava",
    tu: "continuavas",
    ele: "continuava",
    nos: "continuávamos",
    eles: "continuavam",
    spr: "1",
  },
  {
    ru: "общаться",
    port: "conversar",
    eu: "conversava",
    tu: "conversavas",
    ele: "conversava",
    nos: "conversávamos",
    eles: "conversavam",
    spr: "1",
  },
  {
    ru: "приглашать",
    port: "convidar",
    eu: "convidava",
    tu: "convidavas",
    ele: "convidava",
    nos: "convidávamos",
    eles: "convidavam",
    spr: "1",
  },
  {
    ru: "обычно делать",
    port: "costumar",
    eu: "costumava",
    tu: "costumavas",
    ele: "costumava",
    nos: "costumávamos",
    eles: "costumavam",
    spr: "1",
  },
  {
    ru: "готовить",
    port: "cozinhar",
    eu: "cozinhava",
    tu: "cozinhavas",
    ele: "cozinhava",
    nos: "cozinhávamos",
    eles: "cozinhavam",
    spr: "1",
  },
  {
    ru: "заботиться",
    port: "cuidar",
    eu: "cuidava",
    tu: "cuidavas",
    ele: "cuidava",
    nos: "cuidávamos",
    eles: "cuidavam",
    spr: "1",
  },
  {
    ru: "стоить",
    port: "custar",
    eu: "custava",
    tu: "custavas",
    ele: "custava",
    nos: "custávamos",
    eles: "custavam",
    spr: "1",
  },
  {
    ru: "танцевать",
    port: "dançar",
    eu: "dançava",
    tu: "dançavas",
    ele: "dançava",
    nos: "dançávamos",
    eles: "dançavam",
    spr: "1",
  },
  {
    ru: "класть",
    port: "deitar",
    eu: "deitava",
    tu: "deitavas",
    ele: "deitava",
    nos: "deitávamos",
    eles: "deitavam",
    spr: "1",
  },
  {
    ru: "ложись ",
    port: "deitar",
    eu: "deitava",
    tu: "deitavas",
    ele: "deitava",
    nos: "deitávamos",
    eles: "deitavam",
    spr: "1",
  },
  {
    ru: "оставлять, уходить, покидать",
    port: "deixar",
    eu: "deixava",
    tu: "deixavas",
    ele: "deixava",
    nos: "deixávamos",
    eles: "deixavam",
    spr: "1",
  },
  {
    ru: "задерживаться, продолжаться",
    port: "demorar",
    eu: "demorava",
    tu: "demoravas",
    ele: "demorava",
    nos: "demorávamos",
    eles: "demoravam",
    spr: "1",
  },
  {
    ru: "разуваться",
    port: "descalçar",
    eu: "descalçava",
    tu: "descalçavas",
    ele: "descalçava",
    nos: "descalçávamos",
    eles: "descalçavam",
    spr: "1",
  },
  {
    ru: "отдыхать",
    port: "descansar",
    eu: "descansava",
    tu: "descansavas",
    ele: "descansava",
    nos: "descansávamos",
    eles: "descansavam",
    spr: "1",
  },
  {
    ru: "извиняться",
    port: "desculpar",
    eu: "desculpava",
    tu: "desculpavas",
    ele: "desculpava",
    nos: "desculpávamos",
    eles: "desculpavam",
    spr: "1",
  },
  {
    ru: "желать",
    port: "desejar",
    eu: "desejava",
    tu: "desejavas",
    ele: "desejava",
    nos: "desejávamos",
    eles: "desejavam",
    spr: "1",
  },
  {
    ru: "рисовать карандашом",
    port: "desenhar",
    eu: "desenhava",
    tu: "desenhavas",
    ele: "desenhava",
    nos: "desenhávamos",
    eles: "desenhavam",
    spr: "1",
  },
  {
    ru: "выключать",
    port: "desligar",
    eu: "desligava",
    tu: "desligavas",
    ele: "desligava",
    nos: "desligávamos",
    eles: "desligavam",
    spr: "1",
  },
  {
    ru: "длиться",
    port: "durar",
    eu: "durava",
    tu: "duravas",
    ele: "durava",
    nos: "durávamos",
    eles: "duravam",
    spr: "1",
  },
  {
    ru: "одалживать",
    port: "empresar",
    eu: "empresava",
    tu: "empresavas",
    ele: "empresava",
    nos: "empresávamos",
    eles: "empresavam",
    spr: "1",
  },
  {
    ru: "находить, встречаться",
    port: "encontrar",
    eu: "encontrava",
    tu: "encontravas",
    ele: "encontrava",
    nos: "encontrávamos",
    eles: "encontravam",
    spr: "1",
  },
  {
    ru: "учить",
    port: "ensinar",
    eu: "ensinava",
    tu: "ensinavas",
    ele: "ensinava",
    nos: "ensinávamos",
    eles: "ensinavam",
    spr: "1",
  },
  {
    ru: "входить",
    port: "entrar",
    eu: "entrava",
    tu: "entravas",
    ele: "entrava",
    nos: "entrávamos",
    eles: "entravam",
    spr: "1",
  },
  {
    ru: "доставлять",
    port: "entregar",
    eu: "entregava",
    tu: "entregavas",
    ele: "entregava",
    nos: "entregávamos",
    eles: "entregavam",
    spr: "1",
  },
  {
    ru: "послать",
    port: "enviar",
    eu: "enviava",
    tu: "enviavas",
    ele: "enviava",
    nos: "enviávamos",
    eles: "enviavam",
    spr: "1",
  },
  {
    ru: "расчесываться",
    port: "escovar",
    eu: "escovava",
    tu: "escovavas",
    ele: "escovava",
    nos: "escovávamos",
    eles: "escovavam",
    spr: "1",
  },
  {
    ru: "ждать",
    port: "esperar",
    eu: "esperava",
    tu: "esperavas",
    ele: "esperava",
    nos: "esperávamos",
    eles: "esperavam",
    spr: "1",
  },
  {
    ru: "учить",
    port: "estudar",
    eu: "estudava",
    tu: "estudavas",
    ele: "estudava",
    nos: "estudávamos",
    eles: "estudavam",
    spr: "1",
  },
  {
    ru: "существовать",
    port: "existir",
    eu: "existava",
    tu: "existavas",
    ele: "existava",
    nos: "existávamos",
    eles: "existavam",
    spr: "1",
  },
  {
    ru: "обьяснять",
    port: "explicar",
    eu: "explicava",
    tu: "explicavas",
    ele: "explicava",
    nos: "explicávamos",
    eles: "explicavam",
    spr: "1",
  },
  {
    ru: "говорить",
    port: "falar",
    eu: "falava",
    tu: "falavas",
    ele: "falava",
    nos: "falávamos",
    eles: "falavam",
    spr: "1",
  },
  {
    ru: "закрываться",
    port: "fechar",
    eu: "fechava",
    tu: "fechavas",
    ele: "fechava",
    nos: "fechávamos",
    eles: "fechavam",
    spr: "1",
  },
  {
    ru: "находиться",
    port: "ficar",
    eu: "ficava",
    tu: "ficavas",
    ele: "ficava",
    nos: "ficávamos",
    eles: "ficavam",
    spr: "1",
  },
  {
    ru: "курить",
    port: "fumar",
    eu: "fumava",
    tu: "fumavas",
    ele: "fumava",
    nos: "fumávamos",
    eles: "fumavam",
    spr: "1",
  },
  {
    ru: "зарабатывать",
    port: "ganhar",
    eu: "ganhava",
    tu: "ganhavas",
    ele: "ganhava",
    nos: "ganhávamos",
    eles: "ganhavam",
    spr: "1",
  },
  {
    ru: "тратить",
    port: "gastar",
    eu: "gastava",
    tu: "gastavas",
    ele: "gastava",
    nos: "gastávamos",
    eles: "gastavam",
    spr: "1",
  },
  {
    ru: "вращаться",
    port: "girar",
    eu: "girava",
    tu: "giravas",
    ele: "girava",
    nos: "girávamos",
    eles: "giravam",
    spr: "1",
  },
  {
    ru: "нравиться",
    port: "gostar",
    eu: "gostava",
    tu: "gostavas",
    ele: "gostava",
    nos: "gostávamos",
    eles: "gostavam",
    spr: "1",
  },
  {
    ru: "кричать",
    port: "gritar",
    eu: "gritava",
    tu: "gritavas",
    ele: "gritava",
    nos: "gritávamos",
    eles: "gritavam",
    spr: "1",
  },
  {
    ru: "храненить",
    port: "guardar",
    eu: "guardava",
    tu: "guardavas",
    ele: "guardava",
    nos: "guardávamos",
    eles: "guardavam",
    spr: "1",
  },
  {
    ru: "ужинать",
    port: "jantar",
    eu: "jantava",
    tu: "jantavas",
    ele: "jantava",
    nos: "jantávamos",
    eles: "jantavam",
    spr: "1",
  },
  {
    ru: "играть",
    port: "jogar",
    eu: "jogava",
    tu: "jogavas",
    ele: "jogava",
    nos: "jogávamos",
    eles: "jogavam",
    spr: "1",
  },
  {
    ru: "бросать",
    port: "lançar",
    eu: "lançava",
    tu: "lançavas",
    ele: "lançava",
    nos: "lançávamos",
    eles: "lançavam",
    spr: "1",
  },
  {
    ru: "полдничать",
    port: "lanchar",
    eu: "lanchava",
    tu: "lanchavas",
    ele: "lanchava",
    nos: "lanchávamos",
    eles: "lanchavam",
    spr: "1",
  },
  {
    ru: "мыть",
    port: "lavar",
    eu: "lavava",
    tu: "lavavas",
    ele: "lavava",
    nos: "lavávamos",
    eles: "lavavam",
    spr: "1",
  },
  {
    ru: "помнить",
    port: "lembrar",
    eu: "lembrava",
    tu: "lembravas",
    ele: "lembrava",
    nos: "lembrávamos",
    eles: "lembravam",
    spr: "1",
  },
  {
    ru: "вставать",
    port: "levantar",
    eu: "levantava",
    tu: "levantavas",
    ele: "levantava",
    nos: "levantávamos",
    eles: "levantavam",
    spr: "1",
  },
  {
    ru: "брать, забирать",
    port: "levar",
    eu: "levava",
    tu: "levavas",
    ele: "levava",
    nos: "levávamos",
    eles: "levavam",
    spr: "1",
  },
  {
    ru: "включать, звонить",
    port: "ligar",
    eu: "ligava",
    tu: "ligavas",
    ele: "ligava",
    nos: "ligávamos",
    eles: "ligavam",
    spr: "1",
  },
  {
    ru: "чистить",
    port: "limpar",
    eu: "limpava",
    tu: "limpavas",
    ele: "limpava",
    nos: "limpávamos",
    eles: "limpavam",
    spr: "1",
  },
  {
    ru: "отправлять",
    port: "mandar",
    eu: "mandava",
    tu: "mandavas",
    ele: "mandava",
    nos: "mandávamos",
    eles: "mandavam",
    spr: "1",
  },
  {
    ru: "записаться",
    port: "marcar",
    eu: "marcava",
    tu: "marcavas",
    ele: "marcava",
    nos: "marcávamos",
    eles: "marcavam",
    spr: "1",
  },
  {
    ru: "улучшить",
    port: "melhorar",
    eu: "melhorava",
    tu: "melhoravas",
    ele: "melhorava",
    nos: "melhorávamos",
    eles: "melhoravam",
    spr: "1",
  },
  {
    ru: "жить",
    port: "morar",
    eu: "morava",
    tu: "moravas",
    ele: "morava",
    nos: "morávamos",
    eles: "moravam",
    spr: "1",
  },
  {
    ru: "показывать",
    port: "mostrar",
    eu: "mostrava",
    tu: "mostravas",
    ele: "mostrava",
    nos: "mostrávamos",
    eles: "mostravam",
    spr: "1",
  },
  {
    ru: "показывать",
    port: "mostrar",
    eu: "mostrava",
    tu: "mostravas",
    ele: "mostrava",
    nos: "mostrávamos",
    eles: "mostravam",
    spr: "1",
  },
  {
    ru: "изменять",
    port: "mudar",
    eu: "mudava",
    tu: "mudavas",
    ele: "mudava",
    nos: "mudávamos",
    eles: "mudavam",
    spr: "1",
  },
  {
    ru: "плавать",
    port: "nadar",
    eu: "nadava",
    tu: "nadavas",
    ele: "nadava",
    nos: "nadávamos",
    eles: "nadavam",
    spr: "1",
  },
  {
    ru: "встречаться",
    port: "namorar",
    eu: "namorava",
    tu: "namoravas",
    ele: "namorava",
    nos: "namorávamos",
    eles: "namoravam",
    spr: "1",
  },
  {
    ru: "смотреть",
    port: "olhar",
    eu: "olhava",
    tu: "olhavas",
    ele: "olhava",
    nos: "olhávamos",
    eles: "olhavam",
    spr: "1",
  },
  {
    ru: "платить",
    port: "pagar",
    eu: "pagava",
    tu: "pagavas",
    ele: "pagava",
    nos: "pagávamos",
    eles: "pagavam",
    spr: "1",
  },
  {
    ru: "останавливаться",
    port: "parar",
    eu: "parava",
    tu: "paravas",
    ele: "parava",
    nos: "parávamos",
    eles: "paravam",
    spr: "1",
  },
  {
    ru: "участвовать",
    port: "participar",
    eu: "participava",
    tu: "participavas",
    ele: "participava",
    nos: "participávamos",
    eles: "participavam",
    spr: "1",
  },
  {
    ru: "делиться",
    port: "partilhar",
    eu: "partilhava",
    tu: "partilhavas",
    ele: "partilhava",
    nos: "partilhávamos",
    eles: "partilhavam",
    spr: "1",
  },
  {
    ru: "тратить, проводить",
    port: "passar",
    eu: "passava",
    tu: "passavas",
    ele: "passava",
    nos: "passávamos",
    eles: "passavam",
    spr: "1",
  },
  {
    ru: "брать, ловить",
    port: "pegar",
    eu: "pegava",
    tu: "pegavas",
    ele: "pegava",
    nos: "pegávamos",
    eles: "pegavam",
    spr: "1",
  },
  {
    ru: "обдумывать",
    port: "pensar",
    eu: "pensava",
    tu: "pensavas",
    ele: "pensava",
    nos: "pensávamos",
    eles: "pensavam",
    spr: "1",
  },
  {
    ru: "спрашивать",
    port: "perguntar",
    eu: "perguntava",
    tu: "perguntavas",
    ele: "perguntava",
    nos: "perguntávamos",
    eles: "perguntavam",
    spr: "1",
  },
  {
    ru: "взвешивать",
    port: "pesar",
    eu: "pesava",
    tu: "pesavas",
    ele: "pesava",
    nos: "pesávamos",
    eles: "pesavam",
    spr: "1",
  },
  {
    ru: "рисовать красками",
    port: "pintar",
    eu: "pintava",
    tu: "pintavas",
    ele: "pintava",
    nos: "pintávamos",
    eles: "pintavam",
    spr: "1",
  },
  {
    ru: "экономить",
    port: "poupar",
    eu: "poupava",
    tu: "poupavas",
    ele: "poupava",
    nos: "poupávamos",
    eles: "poupavam",
    spr: "1",
  },
  {
    ru: "нуждаться",
    port: "precisar",
    eu: "precisava",
    tu: "precisavas",
    ele: "precisava",
    nos: "precisávamos",
    eles: "precisavam",
    spr: "1",
  },
  {
    ru: "готовить",
    port: "preparar",
    eu: "preparava",
    tu: "preparavas",
    ele: "preparava",
    nos: "preparávamos",
    eles: "preparavam",
    spr: "1",
  },
  {
    ru: "искать",
    port: "procurar",
    eu: "procurava",
    tu: "procuravas",
    ele: "procurava",
    nos: "procurávamos",
    eles: "procuravam",
    spr: "1",
  },
  {
    ru: "возвращаться",
    port: "regresso",
    eu: "regresava",
    tu: "regresavas",
    ele: "regresava",
    nos: "regresávamos",
    eles: "regresavam",
    spr: "1",
  },
  {
    ru: "отвечать",
    port: "responder",
    eu: "respondava",
    tu: "respondavas",
    ele: "respondava",
    nos: "respondávamos",
    eles: "respondavam",
    spr: "1",
  },
  {
    ru: "прагать",
    port: "saltar",
    eu: "saltava",
    tu: "saltavas",
    ele: "saltava",
    nos: "saltíamos",
    eles: "saltavam",
    spr: "1",
  },
  {
    ru: "сушить",
    port: "secar",
    eu: "secava",
    tu: "secavas",
    ele: "secava",
    nos: "secávamos",
    eles: "secavam",
    spr: "1",
  },
  {
    ru: "сидеть",
    port: "sentar",
    eu: "sentava",
    tu: "sentavas",
    ele: "sentava",
    nos: "sentávamos",
    eles: "sentavam",
    spr: "1",
  },
  {
    ru: "разделять",
    port: "separar",
    eu: "separava",
    tu: "separavas",
    ele: "separava",
    nos: "separávamos",
    eles: "separavam",
    spr: "1",
  },
  {
    ru: "попытаться",
    port: "tentar",
    eu: "tentava",
    tu: "tentavas",
    ele: "tentava",
    nos: "tentávamos",
    eles: "tentavam",
    spr: "1",
  },
  {
    ru: "взять, брать",
    port: "tirar",
    eu: "tirava",
    tu: "tiravas",
    ele: "tirava",
    nos: "tirávamos",
    eles: "tiravam",
    spr: "1",
  },
  {
    ru: "играть на муз.инструметнах",
    port: "tocar",
    eu: "tocava",
    tu: "tocavas",
    ele: "tocava",
    nos: "tocávamos",
    eles: "tocavam",
    spr: "1",
  },
  {
    ru: "брать, принимать",
    port: "tomar",
    eu: "tomava",
    tu: "tomavas",
    ele: "tomava",
    nos: "tomávamos",
    eles: "tomavam",
    spr: "1",
  },
  {
    ru: "работать",
    port: "trabalhar",
    eu: "trabalhava",
    tu: "trabalhavas",
    ele: "trabalhava",
    nos: "trabalhávamos",
    eles: "trabalhavam",
    spr: "1",
  },
  {
    ru: "заботиться о ком то",
    port: "tratar",
    eu: "tratava",
    tu: "tratavas",
    ele: "tratava",
    nos: "tratávamos",
    eles: "tratavam",
    spr: "1",
  },
  {
    ru: "менять",
    port: "trocar",
    eu: "trocava",
    tu: "trocavas",
    ele: "trocava",
    nos: "trocávamos",
    eles: "trocavam",
    spr: "1",
  },
  {
    ru: "использовать",
    port: "usar",
    eu: "usava",
    tu: "usavas",
    ele: "usava",
    nos: "usávamos",
    eles: "usavam",
    spr: "1",
  },
  {
    ru: "путешествовать",
    port: "viajar",
    eu: "viajava",
    tu: "viajavas",
    ele: "viajava",
    nos: "viajávamos",
    eles: "viajavam",
    spr: "1",
  },
  {
    ru: "повернуть",
    port: "virar",
    eu: "virava",
    tu: "viravas",
    ele: "virava",
    nos: "virávamos",
    eles: "viravam",
    spr: "1",
  },
  {
    ru: "возвращаться",
    port: "voltar",
    eu: "voltava",
    tu: "voltavas",
    ele: "voltava",
    nos: "voltávamos",
    eles: "voltavam",
    spr: "1",
  },
  {
    ru: "зажигать",
    port: "acender",
    eu: "acendia",
    tu: "acendias",
    ele: "acendia",
    nos: "acendíamos",
    eles: "acendiam",
    spr: "2",
  },
  {
    ru: "появиться",
    port: "aparecer ",
    eu: "apareceia",
    tu: "apareceias",
    ele: "apareceia",
    nos: "apareceíamos",
    eles: "apareceiam",
    spr: "2",
  },
  {
    ru: "учить навык",
    port: "aprender",
    eu: "aprendia",
    tu: "aprendias",
    ele: "aprendia",
    nos: "aprendíamos",
    eles: "aprendiam",
    spr: "2",
  },
  {
    ru: "нагреваться",
    port: "aquecer",
    eu: "aquecia",
    tu: "aquecias",
    ele: "aquecia",
    nos: "aquecíamos",
    eles: "aqueciam",
    spr: "2",
  },
  {
    ru: "отвечать",
    port: "atender",
    eu: "atendia",
    tu: "atendias",
    ele: "atendia",
    nos: "atendíamos",
    eles: "atendiam",
    spr: "2",
  },
  {
    ru: "бить",
    port: "bater ",
    eu: "bateia",
    tu: "bateias",
    ele: "bateia",
    nos: "bateíamos",
    eles: "bateiam",
    spr: "2",
  },
  {
    ru: "пить",
    port: "beber",
    eu: "bebia",
    tu: "bebias",
    ele: "bebia",
    nos: "bebíamos",
    eles: "bebiam",
    spr: "2",
  },
  {
    ru: "понимать",
    port: "compreender",
    eu: "compreendia",
    tu: "compreendias",
    ele: "compreendia",
    nos: "compreendíamos",
    eles: "compreendiam",
    spr: "2",
  },
  {
    ru: "знать",
    port: "conhecer",
    eu: "conhecia",
    tu: "conhecias",
    ele: "conhecia",
    nos: "conhecíamos",
    eles: "conheciam",
    spr: "2",
  },
  {
    ru: "бежать",
    port: "correr",
    eu: "corria",
    tu: "corrias",
    ele: "corria",
    nos: "corríamos",
    eles: "corriam",
    spr: "2",
  },
  {
    ru: "расти",
    port: "crescer",
    eu: "crescia",
    tu: "crescias",
    ele: "crescia",
    nos: "crescíamos",
    eles: "cresciam",
    spr: "2",
  },
  {
    ru: "защищать",
    port: "defender",
    eu: "defendia",
    tu: "defendias",
    ele: "defendia",
    nos: "defendíamos",
    eles: "defendiam",
    spr: "2",
  },
  {
    ru: "зависеть",
    port: "depender",
    eu: "dependia",
    tu: "dependias",
    ele: "dependia",
    nos: "dependíamos",
    eles: "dependiam",
    spr: "2",
  },
  {
    ru: "спускаться",
    port: "descer",
    eu: "descia",
    tu: "descias",
    ele: "descia",
    nos: "descíamos",
    eles: "desciam",
    spr: "2",
  },
  {
    ru: "должен",
    port: "dever",
    eu: "devia",
    tu: "devias",
    ele: "devia",
    nos: "devíamos",
    eles: "deviam",
    spr: "2",
  },
  {
    ru: "наполнять",
    port: "encher",
    eu: "enchia",
    tu: "enchias",
    ele: "enchia",
    nos: "enchíamos",
    eles: "enchiam",
    spr: "2",
  },
  {
    ru: "понимать",
    port: "entender",
    eu: "entendia",
    tu: "entendias",
    ele: "entendia",
    nos: "entendíamos",
    eles: "entendiam",
    spr: "2",
  },
  {
    ru: "выбирать",
    port: "escolher",
    eu: "escolhia",
    tu: "escolhias",
    ele: "escolhia",
    nos: "escolhíamos",
    eles: "escolhiam",
    spr: "2",
  },
  {
    ru: "прятать",
    port: "esconder",
    eu: "escondia",
    tu: "escondias",
    ele: "escondia",
    nos: "escondíamos",
    eles: "escondiam",
    spr: "2",
  },
  {
    ru: "писать",
    port: "escrever",
    eu: "escrevia",
    tu: "escrevias",
    ele: "escrevia",
    nos: "escrevíamos",
    eles: "escreviam",
    spr: "2",
  },
  {
    ru: "забывать",
    port: "esquecer",
    eu: "esquecia",
    tu: "esquecias",
    ele: "esquecia",
    nos: "esquecíamos",
    eles: "esqueciam",
    spr: "2",
  },
  {
    ru: "вставлять",
    port: "meter",
    eu: "metia",
    tu: "metias",
    ele: "metia",
    nos: "metíamos",
    eles: "metiam",
    spr: "2",
  },
  {
    ru: "кусать",
    port: "morder",
    eu: "mordia",
    tu: "mordias",
    ele: "mordia",
    nos: "mordíamos",
    eles: "mordiam",
    spr: "2",
  },
  {
    ru: "умереть",
    port: "morrer",
    eu: "morria",
    tu: "morrias",
    ele: "morria",
    nos: "morríamos",
    eles: "morriam",
    spr: "2",
  },
  {
    ru: "родиться",
    port: "nascer",
    eu: "nascia",
    tu: "nascias",
    ele: "nascia",
    nos: "nascíamos",
    eles: "nasciam",
    spr: "2",
  },
  {
    ru: "предлагать",
    port: "oferecer",
    eu: "oferecia",
    tu: "oferecias",
    ele: "oferecia",
    nos: "oferecíamos",
    eles: "ofereciam",
    spr: "2",
  },
  {
    ru: "казаться",
    port: "parecer",
    eu: "parecia",
    tu: "parecias",
    ele: "parecia",
    nos: "parecíamos",
    eles: "pareciam",
    spr: "2",
  },
  {
    ru: "понимать",
    port: "perceber",
    eu: "percebia",
    tu: "percebias",
    ele: "percebia",
    nos: "percebíamos",
    eles: "percebiam",
    spr: "2",
  },
  {
    ru: "обезопасить",
    port: "prender",
    eu: "prendia",
    tu: "prendias",
    ele: "prendia",
    nos: "prendíamos",
    eles: "prendiam",
    spr: "2",
  },
  {
    ru: "намереваться",
    port: "pretender",
    eu: "pretendia",
    tu: "pretendias",
    ele: "pretendia",
    nos: "pretendíamos",
    eles: "pretendiam",
    spr: "2",
  },
  {
    ru: "обещать",
    port: "prometer",
    eu: "prometia",
    tu: "prometias",
    ele: "prometia",
    nos: "prometíamos",
    eles: "prometiam",
    spr: "2",
  },
  {
    ru: "защищать",
    port: "proteger",
    eu: "protegia",
    tu: "protegias",
    ele: "protegia",
    nos: "protegíamos",
    eles: "protegiam",
    spr: "2",
  },
  {
    ru: "получать",
    port: "receber",
    eu: "recebia",
    tu: "recebias",
    ele: "recebia",
    nos: "recebíamos",
    eles: "recebiam",
    spr: "2",
  },
  {
    ru: "решать проблему",
    port: "resolver",
    eu: "resolvia",
    tu: "resolvias",
    ele: "resolvia",
    nos: "resolvíamos",
    eles: "resolviam",
    spr: "2",
  },
  {
    ru: "отвечать",
    port: "responder",
    eu: "respondia",
    tu: "respondias",
    ele: "respondia",
    nos: "respondíamos",
    eles: "respondiam",
    spr: "2",
  },
  {
    ru: "расставаться",
    port: "romper",
    eu: "rompia",
    tu: "rompias",
    ele: "rompia",
    nos: "rompíamos",
    eles: "rompiam",
    spr: "2",
  },
  {
    ru: "удивлять",
    port: "surpreender",
    eu: "surpreendia",
    tu: "surpreendias",
    ele: "surpreendia",
    nos: "surpreendíamos",
    eles: "surpreendiam",
    spr: "2",
  },
  {
    ru: "продавать",
    port: "vender",
    eu: "vendia",
    tu: "vendias",
    ele: "vendia",
    nos: "vendíamos",
    eles: "vendiam",
    spr: "2",
  },
  {
    ru: "жить",
    port: "viver",
    eu: "vivia",
    tu: "vivias",
    ele: "vivia",
    nos: "vivíamos",
    eles: "viviam",
    spr: "2",
  },
  {
    ru: "открывать",
    port: "abrir",
    eu: "abria",
    tu: "abrias",
    ele: "abria",
    nos: "abríamos",
    eles: "abriam",
    spr: "3",
  },
  {
    ru: "открывать",
    port: "abrir",
    eu: "abria",
    tu: "abrias",
    ele: "abria",
    nos: "abríamos",
    eles: "abriam",
    spr: "3",
  },
  {
    ru: "приобретать",
    port: "adquirir",
    eu: "adquiria",
    tu: "adquirias",
    ele: "adquiria",
    nos: "adquiríamos",
    eles: "adquiriam",
    spr: "3",
  },
  {
    ru: "присутствовать",
    port: "assistir",
    eu: "assistia",
    tu: "assistias",
    ele: "assistia",
    nos: "assistíamos",
    eles: "assistiam",
    spr: "3",
  },
  {
    ru: "принимать",
    port: "assumir",
    eu: "assumia",
    tu: "assumias",
    ele: "assumia",
    nos: "assumíamos",
    eles: "assumiam",
    spr: "3",
  },
  {
    ru: "водить",
    port: "conduzir",
    eu: "conduzia",
    tu: "conduzias",
    ele: "conduzia",
    nos: "conduzíamos",
    eles: "conduziam",
    spr: "3",
  },
  {
    ru: "выполнить",
    port: "cumprir",
    eu: "cumpria",
    tu: "cumprias",
    ele: "cumpria",
    nos: "cumpríamos",
    eles: "cumpriam",
    spr: "3",
  },
  {
    ru: "решать",
    port: "decidir",
    eu: "decidia",
    tu: "decidias",
    ele: "decidia",
    nos: "decidíamos",
    eles: "decidiam",
    spr: "3",
  },
  {
    ru: "находить",
    port: "descobrir",
    eu: "descobria",
    tu: "descobrias",
    ele: "descobria",
    nos: "descobríamos",
    eles: "descobriam",
    spr: "3",
  },
  {
    ru: "снимать ",
    port: "despir",
    eu: "despia",
    tu: "despias",
    ele: "despia",
    nos: "despíamos",
    eles: "despiam",
    spr: "3",
  },
  {
    ru: "обсуждать",
    port: "discutir",
    eu: "discutia",
    tu: "discutias",
    ele: "discutia",
    nos: "discutíamos",
    eles: "discutiam",
    spr: "3",
  },
  {
    ru: "делить",
    port: "dividir",
    eu: "dividia",
    tu: "dividias",
    ele: "dividia",
    nos: "dividíamos",
    eles: "dividiam",
    spr: "3",
  },
  {
    ru: "гарантировать",
    port: "garantir",
    eu: "garantia",
    tu: "garantias",
    ele: "garantia",
    nos: "garantíamos",
    eles: "garantiam",
    spr: "3",
  },
  {
    ru: "печатать",
    port: "imprimir",
    eu: "imprimia",
    tu: "imprimias",
    ele: "imprimia",
    nos: "imprimíamos",
    eles: "imprimiam",
    spr: "3",
  },
  {
    ru: "преставлять",
    port: "introduzir",
    eu: "introduzia",
    tu: "introduzias",
    ele: "introduzia",
    nos: "introduzíamos",
    eles: "introduziam",
    spr: "3",
  },
  {
    ru: "уезжать, ломать, разбивать",
    port: "partir",
    eu: "partia",
    tu: "partias",
    ele: "partia",
    nos: "partíamos",
    eles: "partiam",
    spr: "3",
  },
  {
    ru: "разрешать",
    port: "permitir",
    eu: "permitia",
    tu: "permitias",
    ele: "permitia",
    nos: "permitíamos",
    eles: "permitiam",
    spr: "3",
  },
  {
    ru: "повторять",
    port: "repetir",
    eu: "repetia",
    tu: "repetias",
    ele: "repetia",
    nos: "repetíamos",
    eles: "repetiam",
    spr: "3",
  },
  {
    ru: "проживать",
    port: "residir",
    eu: "residia",
    tu: "residias",
    ele: "residia",
    nos: "residíamos",
    eles: "residiam",
    spr: "3",
  },
  {
    ru: "сопротивляться",
    port: "resistir",
    eu: "resistia",
    tu: "resistias",
    ele: "resistia",
    nos: "resistíamos",
    eles: "resistiam",
    spr: "3",
  },
  {
    ru: "сожалеть",
    port: "sentir",
    eu: "sentia",
    tu: "sentias",
    ele: "sentia",
    nos: "sentíamos",
    eles: "sentiam",
    spr: "3",
  },
  {
    ru: "обслуживать",
    port: "servir",
    eu: "servia",
    tu: "servias",
    ele: "servia",
    nos: "servíamos",
    eles: "serviam",
    spr: "3",
  },
  {
    ru: "улыбаться",
    port: "sorrir",
    eu: "sorria",
    tu: "sorrias",
    ele: "sorria",
    nos: "sorríamos",
    eles: "sorriam",
    spr: "3",
  },
  {
    ru: "падать",
    port: "cair",
    eu: "caía",
    tu: "caías",
    ele: "caía",
    nos: "caíamos",
    eles: "caíam",
    spr: "3",
  },
  {
    ru: "покрывать",
    port: "cobrir",
    eu: "cobria",
    tu: "cobrias",
    ele: "cobria",
    nos: "cobríamos",
    eles: "cobriam",
    spr: "3",
  },
  {
    ru: "мочь, быть способным",
    port: "conseguir",
    eu: "conseguia",
    tu: "conseguias",
    ele: "conseguia",
    nos: "conseguíamos",
    eles: "conseguiam",
    spr: "3",
  },
  {
    ru: "содержать",
    port: "conter",
    eu: "continha",
    tu: "continhas",
    ele: "continha",
    nos: "contínhamos",
    eles: "continham",
    spr: "2",
  },
  {
    ru: "давать",
    port: "dar",
    eu: "dava",
    tu: "davas",
    ele: "dava",
    nos: "dávamos",
    eles: "davam",
    spr: "1",
  },
  {
    ru: "сказать",
    port: "dizer",
    eu: "dizia",
    tu: "dizias",
    ele: "dizia",
    nos: "dizíamos",
    eles: "diziam",
    spr: "2",
  },
  {
    ru: "спать",
    port: "dormir",
    eu: "dormia",
    tu: "dormias",
    ele: "dormia",
    nos: "dormíamos",
    eles: "dormiam",
    spr: "3",
  },
  {
    ru: "быть",
    port: "estar",
    eu: "estava",
    tu: "estavas",
    ele: "estava",
    nos: "estávamos",
    eles: "estavam",
    spr: "1",
  },
  {
    ru: "делать",
    port: "fazer",
    eu: "fazia",
    tu: "fazias",
    ele: "fazia",
    nos: "fazíamos",
    eles: "faziam",
    spr: "2",
  },
  {
    ru: "идти",
    port: "ir",
    eu: "ia",
    tu: "ias",
    ele: "ia",
    nos: "íamos",
    eles: "iam",
    spr: "3",
  },
  {
    ru: "читать",
    port: "ler",
    eu: "lia",
    tu: "lias",
    ele: "lia",
    nos: "líamos",
    eles: "liam",
    spr: "2",
  },
  {
    ru: "измерять",
    port: "medir",
    eu: "media",
    tu: "medias",
    ele: "media",
    nos: "medíamos",
    eles: "mediam",
    spr: "3",
  },
  {
    ru: "ненавидеть",
    port: "odiar",
    eu: "odiava",
    tu: "odiavas",
    ele: "odiava",
    nos: "odíavamos",
    eles: "odiavam",
    spr: "1",
  },
  {
    ru: "слышать",
    port: "ouvir",
    eu: "ouvia",
    tu: "ouvias",
    ele: "ouvia",
    nos: "ouvíamos",
    eles: "ouviam",
    spr: "3",
  },
  {
    ru: "просить",
    port: "pedir",
    eu: "pedia",
    tu: "pedias",
    ele: "pedia",
    nos: "pedíamos",
    eles: "pediam",
    spr: "3",
  },
  {
    ru: "терять",
    port: "perder",
    eu: "perdia",
    tu: "perdias",
    ele: "perdia",
    nos: "perdíamos",
    eles: "perdiam",
    spr: "2",
  },
  {
    ru: "мочь",
    port: "poder",
    eu: "podia",
    tu: "podias",
    ele: "podia",
    nos: "podíamos",
    eles: "podiam",
    spr: "2",
  },
  {
    ru: "класть, положить",
    port: "pôr",
    eu: "punha",
    tu: "punhas",
    ele: "punha",
    nos: "púnhamos",
    eles: "punham",
    spr: "4",
  },
  {
    ru: "предпочитать",
    port: "preferir",
    eu: "preferia",
    tu: "preferias",
    ele: "preferia",
    nos: "preferíamos",
    eles: "preferiam",
    spr: "3",
  },
  {
    ru: "хотеть",
    port: "querer",
    eu: "queria",
    tu: "querias",
    ele: "queria",
    nos: "queríamos",
    eles: "queriam",
    spr: "2",
  },
  {
    ru: "смеяться",
    port: "rir",
    eu: "ria",
    tu: "rias",
    ele: "ria",
    nos: "ríamos",
    eles: "riam",
    spr: "3",
  },
  {
    ru: "знать",
    port: "saber",
    eu: "sabia",
    tu: "sabias",
    ele: "sabia",
    nos: "sabíamos",
    eles: "sabiam",
    spr: "2",
  },
  {
    ru: "выходить",
    port: "sair",
    eu: "saía",
    tu: "saías",
    ele: "saia",
    nos: "saíamos",
    eles: "saíam",
    spr: "4",
  },
  {
    ru: "быть",
    port: "ser",
    eu: "era",
    tu: "eras",
    ele: "era",
    nos: "éramos",
    eles: "eram",
    spr: "4",
  },
  {
    ru: "подниматься",
    port: "subir",
    eu: "subia",
    tu: "subias",
    ele: "subia",
    nos: "subíamos",
    eles: "subiam",
    spr: "3",
  },
  {
    ru: "иметь ",
    port: "ter",
    eu: "tinha",
    tu: "tihas",
    ele: "tinha",
    nos: "tínhamos",
    eles: "tinham",
    spr: "4",
  },
  {
    ru: "приносить",
    port: "trazer",
    eu: "trazia",
    tu: "trazias",
    ele: "trazia",
    nos: "trazíamos",
    eles: "traziam",
    spr: "2",
  },
  {
    ru: "видеть",
    port: "ver",
    eu: "via",
    tu: "vias",
    ele: "via",
    nos: "víamos",
    eles: "viam",
    spr: "2",
  },
  {
    ru: "одевать",
    port: "vestir",
    eu: "vestia",
    tu: "vestias",
    ele: "vestia",
    nos: "vestíamos",
    eles: "vestiam",
    spr: "3",
  },
];
