export const fraz_2 = [
  {
    p_1: "Ontem ele ",
    p_2: "foi",
    p_3: "de carro para o trabalho.",
    p_4: "ir",
    p_5: "Вчера он поехал на машине  на работу.",
  },
  {
    p_1: "Hoje de manha ",
    p_2: "fui",
    p_3: "ao supermercado.",
    p_4: "ir",
    p_5: "Сегодня утром я ходил в супермаркет.",
  },
  {
    p_1: "Ontem à noite",
    p_2: "fomos",
    p_3: "ao cinema.",
    p_4: "ir",
    p_5: "Вчера вечером мы ходили в кино.",
  },
  {
    p_1: "Ontem à tarde",
    p_2: "tive",
    p_3: "um teste.",
    p_4: "ter",
    p_5: "Вчера днем у меня был тест.",
  },
  {
    p_1: "Na semana passada ",
    p_2: "esteve",
    p_3: "doente.",
    p_4: "estar",
    p_5: "На прошлой неделе он был болен.",
  },
  {
    p_1: "Ontem ",
    p_2: "estive",
    p_3: "em casa à noite.",
    p_4: "estar",
    p_5: "Вчера был дома вечером.",
  },
  {
    p_1: "O irmao",
    p_2: "foi ",
    p_3: "um bom aluno na escola.",
    p_4: "ser",
    p_5: "Брат был хорошим учеником в школе.",
  },
  {
    p_1: "No més passado",
    p_2: "estiveram",
    p_3: "em Lisboa.",
    p_4: "estar",
    p_5: "В прошлом месяце они были в Лиссабоне.",
  },
  {
    p_1: "Estes exercícios",
    p_2: "foram",
    p_3: "mais difíceis.",
    p_4: "ser",
    p_5: "Эти упражнения были более сложными.",
  },
  {
    p_1: "No sábado passado ",
    p_2: "foram",
    p_3: "a uma festa.",
    p_4: "ir",
    p_5: "В прошлую субботу они ходили  на вечеринку.",
  },
  {
    p_1: "Ontem à noite",
    p_2: "ficámos ",
    p_3: "em casa.",
    p_4: "ficar",
    p_5: "Вчера вечером мы остались дома.",
  },
  {
    p_1: "No domingo passado ",
    p_2: "comi",
    p_3: "tantos chocolates.",
    p_4: "comer",
    p_5: "В прошлое воскресенье я съел так много шоколада.",
  },
  {
    p_1: "No més passado",
    p_2: "fiquei",
    p_3: "mal disposto.",
    p_4: "ficar",
    p_5: "Вчера у меня было плохое настроение.",
  },
  {
    p_1: "A camioneta para o Porto já",
    p_2: "partiu",
    p_3: ".",
    p_4: "partir",
    p_5: "Автобус в Порту уже ушел.",
  },
  {
    p_1: "A Anna ",
    p_2: "nasceu",
    p_3: "no Porto.",
    p_4: "nascer",
    p_5: "Анна родилась в Порту.",
  },
  {
    p_1: "O Pedro sempre",
    p_2: "viveu",
    p_3: "em Lisboa.",
    p_4: "viver",
    p_5: "Педро всегда жил в Лиссабоне.",
  },
  {
    p_1: "Ao jantar",
    p_2: "comeram",
    p_3: "carne.",
    p_4: "comer",
    p_5: "На ужин они ели мясо.",
  },
  {
    p_1: "Ao jantar",
    p_2: "beberam",
    p_3: "vinho tinto.",
    p_4: "beber",
    p_5: "За ужином они пили красное вино.",
  },
  {
    p_1: "Ontem ",
    p_2: "trabalhei",
    p_3: "das nove às seis.",
    p_4: "trabalhar",
    p_5: "Вчера я работал с девяти до шести.",
  },
  {
    p_1: "Na semana passada ela",
    p_2: "escreveu",
    p_3: "um amigo muito interessante",
    p_4: "escrever",
    p_5: "На прошлой неделе она написала очень интересному другу",
  },
  {
    p_1: "Ontem à tarde ele",
    p_2: "sentiu-se",
    p_3: "mal.",
    p_4: "sentir",
    p_5: "Вчера днем ​​он почувствовал себя плохо.",
  },
  {
    p_1: "Hoje de manha ",
    p_2: "tomou ",
    p_3: "duche.",
    p_4: "tomar",
    p_5: "Сегодня утром он принял душ.",
  },
  {
    p_1: "Hoje de manha ",
    p_2: "tomou ",
    p_3: "o pequeno-almoço às 11h00.",
    p_4: "tomar",
    p_5: "Сегодня утром он завтракал в 11 часов утра.",
  },
  {
    p_1: "Hoje de manha ",
    p_2: "foi",
    p_3: "às compras.",
    p_4: "ir",
    p_5: "Сегодня утром он пошел за покупками.",
  },
  {
    p_1: "À tarde ",
    p_2: "leu",
    p_3: "o jornal.",
    p_4: "ler",
    p_5: "Днем он читал газету.",
  },
  {
    p_1: "À tarde ",
    p_2: "ouviu",
    p_3: "música.",
    p_4: "ouvir",
    p_5: "Днем он слушал музыку.",
  },
  {
    p_1: "À noite ",
    p_2: "jantou",
    p_3: "fora.",
    p_4: "jantar",
    p_5: "Вечером он ужинал на улице.",
  },
  {
    p_1: "No domingo passado ",
    p_2: "foi ",
    p_3: "ao cinema com os amigos.",
    p_4: "ir",
    p_5: "В прошлое воскресенье он пошел в кино со своими друзьями.",
  },
  {
    p_1: "No domingo passado ",
    p_2: "voltou",
    p_3: "para casa á meia-noite.",
    p_4: "voltar",
    p_5: "В прошлое воскресенье он вернулся домой в полночь.",
  },
  {
    p_1: "No domingo passado ",
    p_2: "dormiu",
    p_3: "até ao meio-dia.",
    p_4: "dormir",
    p_5: "В прошлое воскресенье он спал до полудня.",
  },
  {
    p_1: "No domingo passado ",
    p_2: "almoçou",
    p_3: "fora.",
    p_4: "almoçar",
    p_5: "В прошлое воскресенье он обедал вне дома.",
  },
  {
    p_1: "À tarde ",
    p_2: "falou",
    p_3: "com os amigos no Messenger",
    p_4: "falar",
    p_5: "Днем он пообщался с друзьями в Мессенджере",
  },
  {
    p_1: "À tarde ",
    p_2: "telefonou",
    p_3: "á avó.",
    p_4: "telefonar",
    p_5: "Днем он позвонил бабушке.",
  },
  {
    p_1: "À noite ",
    p_2: "foi",
    p_3: "para cama cedo.",
    p_4: "ir",
    p_5: "Вечером он рано лег спать.",
  },
  {
    p_1: "Eles",
    p_2: "trouxeram",
    p_3: "presentes para todos.",
    p_4: "trazer",
    p_5: "Всем принесли подарки. ",
  },
  {
    p_1: "Eu não",
    p_2: "pude",
    p_3: "ir ao cinema.",
    p_4: "poder",
    p_5: "Я не мог пойти в кино.",
  },
  {
    p_1: "Nós ",
    p_2: "vimos",
    p_3: "um bom filme na TV.",
    p_4: "ver",
    p_5: "Мы посмотрели хороший фильм по телевизору.",
  },
  {
    p_1: "Ninguém ",
    p_2: "fez",
    p_3: "os exercícios.",
    p_4: "fazer",
    p_5: "Упражнения никто не делал.",
  },
  {
    p_1: "Vocês ",
    p_2: "souberam",
    p_3: "o que aconteceu?",
    p_4: "saber",
    p_5: "Знаете ли вы, что произошло?",
  },
  {
    p_1: "Os meus amigos ",
    p_2: "deram",
    p_3: "uma festa no sábado.",
    p_4: "dar",
    p_5: "Мои друзья устроили вечеринку в субботу.",
  },
  {
    p_1: "Ela ",
    p_2: "quis",
    p_3: "ficar em casa.",
    p_4: "querer",
    p_5: "Она хотела остаться дома.",
  },
  {
    p_1: "Eles",
    p_2: "puseram",
    p_3: "o casaco e saíram.",
    p_4: "pôr",
    p_5: "Они надели пальто и ушли.",
  },
  {
    p_1: "O que é que tu",
    p_2: "fizeste ",
    p_3: "ontem?",
    p_4: "fazer",
    p_5: "Что ты делал вчера?",
  },
  {
    p_1: "Vocês ",
    p_2: "trouxeram",
    p_3: "os livros?",
    p_4: "trazer",
    p_5: "Ты принес книги?",
  },
  {
    p_1: "Eu não",
    p_2: "vi",
    p_3: "o acidente.",
    p_4: "ver",
    p_5: "Я не видел аварии.",
  },
  {
    p_1: "O Simão não ",
    p_2: "pôde",
    p_3: "ir ao futebol.",
    p_4: "poder",
    p_5: "Симау не мог пойти на футбол.",
  },
  {
    p_1: "Quantos erros",
    p_2: "deu",
    p_3: "a Clara na composicão?",
    p_4: "dar",
    p_5: "Сколько ошибок допустила Клара в сочинении?",
  },
  {
    p_1: "Eu  ",
    p_2: "vim ",
    p_3: "de carro para o trabalho.",
    p_4: "vir",
    p_5: "Я поехал на работу.",
  },
  {
    p_1: "Ela ",
    p_2: "veio",
    p_3: "tarde para casa.",
    p_4: "vir",
    p_5: "Она пришла домой поздно.",
  },
  {
    p_1: "Eu  ",
    p_2: "parti",
    p_3: "para Londres.",
    p_4: "partir",
    p_5: "Я уехал в Лондон.",
  },
  {
    p_1: "Nós ",
    p_2: "abrimos",
    p_3: "a garrafa de espumante.",
    p_4: "abrir",
    p_5: "Мы открыли бутылку игристого вина.",
  },
  {
    p_1: "Vocês ",
    p_2: "dividiram",
    p_3: "as despesas",
    p_4: "dividir",
    p_5: "Вы разделили расходы",
  },
  {
    p_1: "Tu",
    p_2: "pediste",
    p_3: "um favor ao João?",
    p_4: "pedir",
    p_5: "Вы просили Жоао об одолжении?",
  },
  {
    p_1: "Há dois meses a Sandra e o Pedro ",
    p_2: "encontraram",
    p_3: "o Rui na cidade do Porto.",
    p_4: "encontrar",
    p_5: "Два месяца назад Сандра и Педро встретили Руи в городе Порту.",
  },
  {
    p_1: "O Rui",
    p_2: "convidou",
    p_3: "a Sandra e o Pedro para um almoço.",
    p_4: "convidar",
    p_5: "Руи пригласил Сандру и Педро на обед.",
  },
  {
    p_1: "Ele",
    p_2: "levou",
    p_3: "a Sandra a um restaurante no Porto.",
    p_4: "levar",
    p_5: "Он отвез Сандру в ресторан в Порту.",
  },
  {
    p_1: "O Pedro ",
    p_2: "telefonou",
    p_3: "a Sandra ",
    p_4: "telefonar",
    p_5: "Педро позвонил Сандре",
  },
  {
    p_1: "O Pedro ",
    p_2: "pediu",
    p_3: "para ela chegar cedo.",
    p_4: "pedir",
    p_5: "Педро попросил ее прийти пораньше.",
  },
  {
    p_1: "Eles ",
    p_2: "combinaram",
    p_3: "encontrar-se na Ribeira, perto do rio.",
    p_4: "combinar",
    p_5: "Они договорились встретиться в Рибейре, недалеко от реки.",
  },
  {
    p_1: "Eles ",
    p_2: "acabaram",
    p_3: "de almoçar.",
    p_4: "acabar",
    p_5: "Они только что пообедали.",
  },
  {
    p_1: "Eu",
    p_2: "comprei",
    p_3: "um dicionário.",
    p_4: "comprar",
    p_5: "Я купил словарь.",
  },
  {
    p_1: "O Rui",
    p_2: "falou",
    p_3: "com a Ana.",
    p_4: "falar",
    p_5: "Руи поговорил с Аной.",
  },
  {
    p_1: "Eles",
    p_2: "ganharam",
    p_3: "o jogo.",
    p_4: "ganharar",
    p_5: "Они выиграли игру.",
  },
  {
    p_1: "Nós ",
    p_2: "lavámos",
    p_3: "o carro.",
    p_4: "lavar",
    p_5: "Мы помыли машину.",
  },
  {
    p_1: "Tu",
    p_2: "encontraste",
    p_3: "tua mala",
    p_4: "encontrar",
    p_5: "Вы нашли свой чемодан",
  },
  {
    p_1: "Eu",
    p_2: "corri",
    p_3: "na rua.",
    p_4: "correr",
    p_5: "Я побежал по улице.",
  },
  {
    p_1: "Tu",
    p_2: "vendeste",
    p_3: "as flores.",
    p_4: "vender",
    p_5: "Вы продали цветы.",
  },
  {
    p_1: "Ele",
    p_2: "compreendeu",
    p_3: "o exercício.",
    p_4: "compreender",
    p_5: "Он понял это упражнение.",
  },
  {
    p_1: "Elas",
    p_2: "beberam",
    p_3: "a água.",
    p_4: "beber",
    p_5: "Они выпили воду.",
  },
  {
    p_1: "Nós ",
    p_2: "comemos",
    p_3: "a sandes.",
    p_4: "comer",
    p_5: "Мы съели сэндвич.",
  },
];
