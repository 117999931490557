import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { tems, tems_2 } from "../../utils/team";
import styles from "./fest-list.module.css";
import { slova } from "../../utils/slova";
import ListSlov from "../../components/list-slov/list-slov";

import { AppHeader } from "../../components/app-header/app-header";

function FestList() {
  const [team, setTeam] = useState(tems_2);
  const [teamname, setTeamname] = useState("Выбор темы");
  const [teamname_2, setTeamname_2] = useState("Выбор темы");
  const [listactive, setlistactive] = useState(false);
  const [listactive_2, setlistactive_2] = useState(false);
  const [listslovs, setlistslov] = useState([{ ru: "", port: "" }]);

  useEffect(() => {
    if (listactive === true) {
      document.addEventListener("click", hendlerClick);
    }
    if (listactive === false) {
      document.removeEventListener("click", hendlerClick);
    }
  }, [listactive]);

  const hendlerClick = (evt: any) => {
    if (!evt.target.className.includes("buttonteam")) {
      if (!evt.target.className.includes("buttonactive")) {
        if (listactive === true) {
          if (evt.target.className) {
            if (!evt.target.className.includes("menuf")) {
              setlistactive(false);
              setlistactive_2(false);
            }
          }
        }
      }
    }
  };

  const buttonHandler = (nom: string[], ttt: string) => {
    setTeamname(ttt);
    setTeam(nom);
  };

  const buttonHandler_2 = (name: string) => {
    setTeamname_2(name);
    const slovas = slova.find((element) => element.name === name);
    if (slovas) {
      setlistslov(slovas.dict);
    }
    setlistactive_2(false);
    setlistactive(false);
  };

  const listTeam = tems;
  let index = 0;

  const buttonHandlerTeam = () => {
    setlistactive(true);
    setlistactive_2(true);
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <AppHeader name={"Учи слова"}></AppHeader>
        <button
          className={styles.buttonteam}
          onClick={() => buttonHandlerTeam()}
        >
          {teamname_2}
        </button>
      </div>
      {listactive === true && (
        <section className={`custom-scroll ${styles.list} ${styles.menuf} `}>
          {listTeam.map((item) => {
            index = index + 1;
            if (item.name === teamname) {
              return (
                <button
                  className={styles.buttonactive}
                  onClick={() => buttonHandler(item.nom, item.name)}
                  key={index}
                >
                  {item.name}
                </button>
              );
            } else {
              return (
                <button
                  className={styles.button}
                  onClick={() => buttonHandler(item.nom, item.name)}
                  key={index}
                >
                  {item.name}
                </button>
              );
            }
          })}
        </section>
      )}
      {listactive_2 === true && (
        <section className={`custom-scroll ${styles.list_2} ${styles.menuf}`}>
          {team.map((item) => {
            index = index + 1;

            if (item === teamname_2) {
              return (
                <button
                  className={styles.buttonactive}
                  onClick={() => buttonHandler_2(item)}
                  key={index}
                >
                  {item}
                </button>
              );
            } else {
              return (
                <button
                  className={styles.button}
                  onClick={() => buttonHandler_2(item)}
                  key={index}
                >
                  {item}
                </button>
              );
            }
          })}
        </section>
      )}
      <ListSlov list={listslovs}></ListSlov>
    </div>
  );
}

export default FestList;
