import styles from "./presente.module.css";
import { verb_n, verb } from "../../utils/verbo";

import Verb from "../verb/verb";
import AppHeader from "../app-header/app-header";

function Presente() {
  return (
    <div className={styles.main}>
      <AppHeader name={"Учим спряжения глаголов"}></AppHeader>
      <h1 className={styles.title}>Pretérito imperfeito do indicativo</h1>
      <Verb list={verb_n} listP={verb}></Verb>
    </div>
  );
}
export default Presente;
