import styles from "./imperfeito.module.css";

import { verb_im, verb_Im } from "../../utils/verboimp";

import Verb from "../verb/verb";
import AppHeader from "../app-header/app-header";

function Imperfeito() {
  return (
    <div className={styles.main}>
      <AppHeader name={"Учи спряжения глаголов"}></AppHeader>
      <h1 className={styles.title}>Pretérito imperfeito do indicativo</h1>
      <Verb list={verb_im} listP={verb_Im}></Verb>
    </div>
  );
}

export default Imperfeito;
