import styles from "./profile.module.css";
import log from "../../images/Patreon_logo.svg.png";
import { useState } from "react";
import dog from "../../images/title.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../types/hook";
import { dellball } from "../../store/score";
import { getuser } from "../../store/action-selector";
import { AppHeader } from "../../components/app-header/app-header";
import LinLogin from "../../components/link-login/link-login";
import { delluser, setuser } from "../../store/user";
import { getAuth, signOut } from "firebase/auth";

function Profile() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getuser);
  const [texthello, setTexthello] = useState("Привет, спасибо за регистрацию!");
  const navigate = useNavigate();

  let useremail = "";

  if (user) {
    useremail = user.email;
  }

  const onClick = (evt: React.FormEvent) => {
    evt.preventDefault();
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        dispatch(delluser());
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <div className={styles.login}>
      <div className={styles.header}>
        <AppHeader name={user.email}></AppHeader>
      </div>
      <section className={styles.main}>
        <div className={styles.preview_2}>
          <img src={dog} className={styles.preview} alt="dog" />
        </div>
        <section className={styles.form}>
          <p className={styles.text}>
            Если вам понравилось и вы будете заниматься дальше, можете
            поддержать автора:
          </p>
          <a
            className={`${styles.text} ${styles.link_b}`}
            href="https://patreon.com/portuguesdicionario?utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink"
          >
            подпиской на Patreon
            <img src={log} alt="lofo patreon" className={styles.imageP} />
          </a>

          <NavLink className={`${styles.text} ${styles.text_b}`} to="/chat">
            Oтзывы, предложения и т.п. ...
          </NavLink>

          <button onClick={onClick} className={` ${styles.text}`}>
            Выйти из аккаунта {useremail}
          </button>
        </section>
      </section>
    </div>
  );
}

export default Profile;
