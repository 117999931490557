import { useState } from "react";
import { aone } from "../../utils/aone";
import { shuffle } from "../../utils/convert";
import styles from "./startpage.module.css";
import Start from "../../components/start/start";

function StartPage() {
  return (
    <div className={styles.main}>
      <Start></Start>
    </div>
  );
}

export default StartPage;
