import styles from "./cardsw.module.css";
import Card from "../card/card";
import { AnimatePresence, wrap, motion } from "framer-motion";
import { useState } from "react";
import { shuffle } from "../../utils/convert";
import Speak from "../speak/speak";

type Tcards = {
  list: {
    port: string;
    ru: string;
  }[];
};

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const sp = (text: string) => {
  let word = "";
  let mass = text.split("(");

  if (mass) {
    word = mass[0];
  } else {
    word = text;
  }

  return word;
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const Cardsw = (props: Tcards) => {
  const listcard = props.list;
  const [[page, direction], setPage] = useState([0, 0]);
  const [active, setactive] = useState(true);
  const port = "port";
  const imageIndex = wrap(0, listcard.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <div className={styles.main}>
      <section className={styles.imgblock}>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            className={styles.image}
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
              setactive(active);
            }}
            onClick={() => {
              setactive(!active);
            }}
          >
            {active && (
              <div className={styles.box}>
                <h1 className={styles.texttyping}>{listcard[imageIndex].ru}</h1>
              </div>
            )}
            {!active && (
              <div className={styles.box}>
                <h1 className={styles.texttyping}>
                  {listcard[imageIndex].port}
                </h1>
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </section>
      <Speak text={sp(listcard[imageIndex].port)}></Speak>
    </div>
  );
};

export default Cardsw;
/*<div className={styles.list}>
        {listcard.map((item, index) => {
          return <Card palavra={item} key={index}></Card>;
        })}
      </div>*/
