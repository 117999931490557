export type Tverb ={
  ru: string, 
  port: string, 
  eu: string, 
  tu:string, 
  ele: string, 
  nos: string, 
  eles: string,
  spr: string
}

export const verb= [
  { ru: "глаголы -ar", port: "...ar", eu: "o", tu: "-as", ele: "-a", nos: "-amos", eles: "-am", spr: "1" },
  { ru: "глаголы -er", port: "...er", eu: "o", tu: "-es", ele: "-e", nos: "-emos", eles: "-em", spr: "2" },
  { ru: "глаголы -ir", port: "...ir", eu: "o", tu: "-es", ele: "-e", nos: "-imos", eles: "-em", spr: "3" },
  { ru: "неправильные", port: " ", eu: " ", tu: "", ele: " ", nos: " ", eles: " ", spr: "4" },]
  
export const verb_n= [
   {
    ru: "открывать",
    port: "abrir ",
    eu: "abro",
    tu: "abres",
    ele: "abre",
    nos: "abrimos",
    eles: "abrem",
    spr: "3",
  },
  {
    ru: "заканчивать",
    port: "acabar ",
    eu: "acabo",
    tu: "acabas",
    ele: "acaba",
    nos: "acabamos",
    eles: "acabam",
    spr: "1",
  },
  {
    ru: "принимать",
    port: "aceitar",
    eu: "aceito",
    tu: "aceitas",
    ele: "aceita",
    nos: "aceitamos",
    eles: "aceitam",
    spr: "1",
  },
  {
    ru: "зажигать",
    port: "acender ",
    eu: "acendo",
    tu: "acendes",
    ele: "acende",
    nos: "acendemos",
    eles: "acendem",
    spr: "2",
  },
  {
    ru: "думать",
    port: "achar",
    eu: "acho",
    tu: "achas",
    ele: "acha",
    nos: "achamos",
    eles: "acham",
    spr: "1",
  },
  {
    ru: "открывать",
    port: "abrir",
    eu: "abro",
    tu: "abres ",
    ele: "abre",
    nos: "abrimos",
    eles: "abrem",
    spr: "3",
  },
  {
    ru: "сопровождать",
    port: "acompanhar ",
    eu: "acompanho",
    tu: "acompanhas",
    ele: "acompanha",
    nos: "acompanhamos",
    eles: "acompanham",
    spr: "1",
  },
  {
    ru: "просыпаться",
    port: "acordar ",
    eu: "acordo",
    tu: "acordas",
    ele: "acorda",
    nos: "acordamos",
    eles: "acordam",
    spr: "1",
  },
  {
    ru: "обожать",
    port: "adorar",
    eu: "adoro",
    tu: "adoras",
    ele: "adora",
    nos: "adoramos",
    eles: "adoram",
    spr: "1",
  },
  {
    ru: "приобретать",
    port: "adquirir ",
    eu: "adquiro",
    tu: "adquires",
    ele: "adquire",
    nos: "adguirimos",
    eles: "adquirem",
    spr: "3",
  },
  {
    ru: "ждать",
    port: "aguardar",
    eu: "aguardo",
    tu: "aguardas",
    ele: "aguarda",
    nos: "aguardamos",
    eles: "aguardam",
    spr: "1",
  },
  {
    ru: "помогать",
    port: "ajudar",
    eu: "ajudo",
    tu: "ajudas",
    ele: "ajuda",
    nos: "ajudamos",
    eles: "ajudam",
    spr: "1",
  },
  {
    ru: "обедать",
    port: "almoçar ",
    eu: "almoço",
    tu: "almoças",
    ele: "almoça",
    nos: "almoçamos",
    eles: "almoçam",
    spr: "1",
  },
  {
    ru: "кормить",
    port: "alimentar",
    eu: "alimento",
    tu: "alimentas",
    ele: "alimenta",
    nos: "alimentamos",
    eles: "alimentam",
    spr: "1",
  },
  {
    ru: "арендовать, снимать",
    port: "alugar",
    eu: "alugo",
    tu: "alugas",
    ele: "aluga",
    nos: "alugamos",
    eles: "alugam",
    spr: "1",
  },
  {
    ru: "любить",
    port: "amar",
    eu: "amo",
    tu: "amas",
    ele: "ama",
    nos: "amamos",
    eles: "amam",
    spr: "1",
  },
  {
    ru: "ходить",
    port: "andar",
    eu: "ando",
    tu: "andas",
    ele: "anda",
    nos: "andamos",
    eles: "andam",
    spr: "1",
  },
  {
    ru: "брать, ловить",
    port: "apanhar",
    eu: "apanho",
    tu: "apanhas",
    ele: "apanha",
    nos: "apanhamos",
    eles: "apanham",
    spr: "1",
  },
  {
    ru: "появиться",
    port: "aparecer ",
    eu: "apareço",
    tu: "apareces",
    ele: "aparece",
    nos: "aparecemos",
    eles: "aparecem",
    spr: "2",
  },
  {
    ru: "учить навык",
    port: "aprender",
    eu: "aprendo",
    tu: "aprendes",
    ele: "aprende",
    nos: "aprendemos",
    eles: "aprendem",
    spr: "2",
  },
  {
    ru: "насладжаться, прользоваться",
    port: "aproveitar",
    eu: "aproveito",
    tu: "aproveitas",
    ele: "aproveita",
    nos: "aproveitamos",
    eles: "aproveitam",
    spr: "1",
  },
  {
    ru: "нагреваться",
    port: "aquecer",
    eu: "aqueço",
    tu: "aqueces",
    ele: "aquece",
    nos: "aquecemos",
    eles: "aquecem",
    spr: "2",
  },
  {
    ru: "раздобыть",
    port: "arranjar",
    eu: "arranjo",
    tu: "arranjas",
    ele: "arranja",
    nos: "arranjamos",
    eles: "arranjam",
    spr: "1",
  },
  {
    ru: "убирать",
    port: "arrumar",
    eu: "arrumo",
    tu: "arrumas",
    ele: "arruma",
    nos: "arrumamos",
    eles: "arrumam",
    spr: "1",
  },
  {
    ru: "присутствовать",
    port: "assistir ",
    eu: "assisto",
    tu: "assistes",
    ele: "assiste",
    nos: "assistimos",
    eles: "assistem",
    spr: "3",
  },
  {
    ru: "принимать",
    port: "assumir ",
    eu: "assumo",
    tu: "assumes",
    ele: "assume",
    nos: "assumimos",
    eles: "assumem",
    spr: "3",
  },
  {
    ru: "отвечать",
    port: "atender",
    eu: "atendo",
    tu: "atendes",
    ele: "atande",
    nos: "atendemos",
    eles: "atendem",
    spr: "2",
  },
  {
    ru: "переходить",
    port: "atravessar",
    eu: "atravesso",
    tu: "atravessas",
    ele: "atravessa",
    nos: "atravessamos",
    eles: "atravessam",
    spr: "1",
  },
  {
    ru: "бить",
    port: "bater ",
    eu: "bato",
    tu: "bates",
    ele: "bate",
    nos: "batemos",
    eles: "batem",
    spr: "2",
  },
  {
    ru: "пить",
    port: "beber ",
    eu: "bebo",
    tu: "bebes",
    ele: "bebe",
    nos: "bebemos",
    eles: "bebem",
    spr: "2",
  },
  {
    ru: "играть дети",
    port: "brincar",
    eu: "brinco",
    tu: "brincas",
    ele: "brinca",
    nos: "brincamos",
    eles: "brincam",
    spr: "1",
  },
  {
    ru: "искать",
    port: "buscar ",
    eu: "busco",
    tu: "buscas",
    ele: "busca",
    nos: "buscamos",
    eles: "buscam",
    spr: "1",
  },
  {
    ru: "обуваться",
    port: "calçar",
    eu: "calço",
    tu: "calças",
    ele: "calça",
    nos: "calçamos",
    eles: "calçam",
    spr: "1",
  },
  {
    ru: "падать",
    port: "cair",
    eu: "caio",
    tu: "cais",
    ele: "cai",
    nos: "caimos",
    eles: "caem",
    spr: "4",
  },
  {
    ru: "петь",
    port: "cantar",
    eu: "canto",
    tu: "cantas",
    ele: "canta",
    nos: "cantamos",
    eles: "cantam",
    spr: "1",
  },
  {
    ru: "молчать",
    port: "calar",
    eu: "calo",
    tu: "calas",
    ele: "cala",
    nos: "calamos",
    eles: "calam",
    spr: "1",
  },
  {
    ru: "жениться",
    port: "casar",
    eu: "caso",
    tu: "casas",
    ele: "casa",
    nos: "casamos",
    eles: "casam",
    spr: "1",
  },
  {
    ru: "звать",
    port: "chamar ",
    eu: "chamo",
    tu: "chamas",
    ele: "chama",
    nos: "chamamos",
    eles: "chamam",
    spr: "1",
  },
  {
    ru: "прибывать, приезжать",
    port: "chegar",
    eu: "chego",
    tu: "chegas",
    ele: "chega",
    nos: "chegamos",
    eles: "chegam",
    spr: "1",
  },
  {
    ru: "покрывать",
    port: "cobrir",
    eu: "cubro",
    tu: "cobres",
    ele: "cobre",
    nos: "cobrimos",
    eles: "cobrem",
    spr: "4",
  },
  {
    ru: "плакать",
    port: "chorar",
    eu: "choro",
    tu: "choras",
    ele: "chora",
    nos: "choramos",
    eles: "choram",
    spr: "1",
  },
  {
    ru: "начинать",
    port: "começar ",
    eu: "começo",
    tu: "começas",
    ele: "começa",
    nos: "começamos",
    eles: "começam",
    spr: "1",
  },
  {
    ru: "покупать",
    port: "comprar",
    eu: "compro",
    tu: "compres",
    ele: "compra",
    nos: "compramos",
    eles: "compram",
    spr: "1",
  },
  {
    ru: "соглашаться",
    port: "concordar",
    eu: "concordo",
    tu: "concordas ",
    ele: "concorda",
    nos: "concordamos",
    eles: "concordam",
    spr: "1",
  },
  {
    ru: "готовить",
    port: "cozinhar",
    eu: "cozinho",
    tu: "cozinhas",
    ele: "cozinha",
    nos: "cozinhamos",
    eles: "cozinham",
    spr: "1",
  },
  {
    ru: "знать",
    port: "conhecer",
    eu: "conheço",
    tu: "conheces",
    ele: "conhece",
    nos: "conhesemos",
    eles: "conhesem",
    spr: "2",
  },
  {
    ru: "считать, рассказывать",
    port: "contar",
    eu: "conto",
    tu: "contas",
    ele: "conta",
    nos: "contamos",
    eles: "contam",
    spr: "1",
  },
  {
    ru: "продолжать",
    port: "continuar",
    eu: "continuo",
    tu: "continuas",
    ele: "continua",
    nos: "continuamos",
    eles: "continuam",
    spr: "1",
  },
  {
    ru: "общаться",
    port: "conversar",
    eu: "converso",
    tu: "conversas",
    ele: "conversa",
    nos: "conversamos",
    eles: "conversam",
    spr: "1",
  },
  {
    ru: "приглашать",
    port: "convidar",
    eu: "covido",
    tu: "convidas",
    ele: "convida",
    nos: "convidamos",
    eles: "convidam",
    spr: "1",
  },
  {
    ru: "понимать",
    port: "compreender",
    eu: "compreendo",
    tu: "compreendes",
    ele: "compreende",
    nos: "compreendemos",
    eles: "compreendem",
    spr: "2",
  },
  {
    ru: "водить",
    port: "conduzir",
    eu: "conduzo",
    tu: "conduzes",
    ele: "conduz",
    nos: "conduzimos",
    eles: "conduzem",
    spr: "3",
  },
  {
    ru: "содержать",
    port: "conter",
    eu: "contenho",
    tu: "conténs",
    ele: "contém",
    nos: "contemos",
    eles: "contêm",
    spr: "4",
  },
  {
    ru: "мочь",
    port: "",
    eu: "consigo",
    tu: "consegues",
    ele: "consegue",
    nos: "conseguimos",
    eles: "conseguem",
    spr: "4",
  },
  {
    ru: "бежать",
    port: "correr ",
    eu: "corro",
    tu: "corres",
    ele: "corre",
    nos: "corremos",
    eles: "correm",
    spr: "2",
  },
  {
    ru: "обычно делать",
    port: "costumar ",
    eu: "costumo",
    tu: "costumas",
    ele: "costuma",
    nos: "costumamos",
    eles: "costumam",
    spr: "1",
  },
  {
    ru: "расти",
    port: "crescer",
    eu: "cresço",
    tu: "cresces",
    ele: "cresce",
    nos: "crescemos",
    eles: "crescem",
    spr: "2",
  },
  {
    ru: "заботиться",
    port: "cuidar",
    eu: "cuido",
    tu: "cuidas",
    ele: "cuida",
    nos: "cuidamos",
    eles: "cuidam",
    spr: "1",
  },
  {
    ru: "выполнить",
    port: "cumprir ",
    eu: "cumpro",
    tu: "cumpres",
    ele: "cumpre",
    nos: "cumprimos",
    eles: "cumprem",
    spr: "3",
  },
  {
    ru: "стоить",
    port: "custar",
    eu: "custo",
    tu: "custas",
    ele: "custa ",
    nos: "custamos",
    eles: "custam",
    spr: "1",
  },
  {
    ru: "давать",
    port: "dar",
    eu: "dou",
    tu: "dás",
    ele: "dá",
    nos: "damos",
    eles: "dão",
    spr: "4",
  },
  {
    ru: "танцевать",
    port: "dançar",
    eu: "danço",
    tu: "danças",
    ele: "dança",
    nos: "dançamos",
    eles: "dançam",
    spr: "1",
  },
  {
    ru: "решать",
    port: "decidir ",
    eu: "decido",
    tu: "decides",
    ele: "decide",
    nos: "decidimos",
    eles: "decidem",
    spr: "3",
  },
  {
    ru: "защищать",
    port: "defender ",
    eu: "defendo",
    tu: "defendes",
    ele: "defende",
    nos: "defendemos",
    eles: "defendem",
    spr: "2",
  },
  
  {
    ru: "ложиться ",
    port: "deitar ",
    eu: "deito",
    tu: "deitas",
    ele: "deita",
    nos: "deitamos",
    eles: "deitam",
    spr: "1",
  },
  {
    ru: "оставлять, уходить, покидать",
    port: "deixar",
    eu: "deixo",
    tu: "deixas",
    ele: "deixa",
    nos: "deixamos",
    eles: "deixam",
    spr: "1",
  },
  {
    ru: "зависеть",
    port: "depender",
    eu: "dependo",
    tu: "dependes",
    ele: "depende",
    nos: "dependemos",
    eles: "dependem",
    spr: "2",
  },
  {
    ru: "находить",
    port: "descobrir",
    eu: "descobro",
    tu: "descobres",
    ele: "descobre",
    nos: "descobrimos",
    eles: "descobrem",
    spr: "3",
  },
  {
    ru: "разуваться",
    port: "descalçar",
    eu: "descalço",
    tu: "descalças",
    ele: "descalça",
    nos: "descalçamos",
    eles: "descalçam",
    spr: "1",
  },
  {
    ru: "отдыхать",
    port: "descansar",
    eu: "descanso",
    tu: "descansas",
    ele: "descansa",
    nos: "descansamos",
    eles: "descansam",
    spr: "1",
  },
  {
    ru: "спускаться",
    port: "descer",
    eu: "desço",
    tu: "desces",
    ele: "desce",
    nos: "descemos",
    eles: "descem",
    spr: "2",
  },
  {
    ru: "извиняться",
    port: "desculpar",
    eu: "desculpo",
    tu: "desculpas",
    ele: "desculpa",
    nos: "desculpamos",
    eles: "desculpam",
    spr: "1",
  },
  {
    ru: "желать",
    port: "desejar ",
    eu: "desejo",
    tu: "desejas",
    ele: "deseja",
    nos: "desijamos",
    eles: "desijam",
    spr: "1",
  },
  {
    ru: "рисовать карандашом",
    port: "desenhar",
    eu: "desenho",
    tu: "desenhas",
    ele: "desenha",
    nos: "desenhamos",
    eles: "desenham",
    spr: "1",
  },
  {
    ru: "выключать",
    port: "desligar",
    eu: "desligo",
    tu: "desligas",
    ele: "desliga",
    nos: "desligamos",
    eles: "desligam",
    spr: "1",
  },
  {
    ru: "делить",
    port: "dividir",
    eu: "divido",
    tu: "divides",
    ele: "divide",
    nos: "dividimos",
    eles: "dividem",
    spr: "3",
  },
  {
    ru: "снимать ",
    port: "despir",
    eu: "dispo",
    tu: "despes",
    ele: "despe",
    nos: "despimos",
    eles: "despem",
    spr: "3",
  },
  {
    ru: "сказать",
    port: "dizer",
    eu: "digo",
    tu: "dizes",
    ele: "diz",
    nos: "dizemos",
    eles: "dizem",
    spr: "4",
  },
  {
    ru: "обсуждать",
    port: "discutir",
    eu: "discuto",
    tu: "discutes",
    ele: "discute",
    nos: "discutimos",
    eles: "didcutem",
    spr: "3",
  },
  {
    ru: "задерживаться, продолжаться",
    port: "demorar",
    eu: "demoro",
    tu: "demoras",
    ele: "demora",
    nos: "demoramos",
    eles: "demoram",
    spr: "1",
  },
  {
    ru: "длиться",
    port: "durar",
    eu: "duro",
    tu: "duras",
    ele: "dura",
    nos: "duramos",
    eles: "duram",
    spr: "1",
  },
  {
    ru: "веселиться",
    port: "ddivertir",
    eu: "divirto",
    tu: "divertes",
    ele: "diverte",
    nos: "divertimos",
    eles: "divertem",
    spr: "3",
  },
  {
    ru: "одалживать",
    port: "empresar",
    eu: "empreso",
    tu: "empresas",
    ele: "empresa",
    nos: "empresamos",
    eles: "empresam",
    spr: "1",
  },
  {
    ru: "наполнять",
    port: "encher",
    eu: "encho",
    tu: "enches",
    ele: "enche",
    nos: "enchemos",
    eles: "enchem",
    spr: "2",
  },
  {
    ru: "находить, встречаться",
    port: "encontrar ",
    eu: "encontro",
    tu: "encontras",
    ele: "encontra",
    nos: "encontramos",
    eles: "encontram",
    spr: "1",
  },
  {
    ru: "учить",
    port: "ensinar",
    eu: "ensino",
    tu: "ensinas",
    ele: "ensina",
    nos: "ensinamos",
    eles: "ensinam",
    spr: "1",
  },
  {
    ru: "входить",
    port: "entrar ",
    eu: "entro",
    tu: "entras",
    ele: "entra",
    nos: "entramos",
    eles: "entam",
    spr: "1",
  },
  {
    ru: "выбирать",
    port: "escolher",
    eu: "escolho",
    tu: "escolhes",
    ele: "escolhe",
    nos: "escolhemos",
    eles: "escolhem",
    spr: "2",
  },
  {
    ru: "доставлять",
    port: "entregar",
    eu: "entrego",
    tu: "entregas",
    ele: "entrega",
    nos: "entregamos",
    eles: "entregam",
    spr: "1",
  },
  {
    ru: "расчесываться",
    port: "escovar",
    eu: "escovo",
    tu: "escovas",
    ele: "escova",
    nos: "escovamos",
    eles: "escovam",
    spr: "1",
  },
  {
    ru: "послать",
    port: "enviar",
    eu: "envio",
    tu: "envias",
    ele: "envia",
    nos: "enviamos",
    eles: "enviam",
    spr: "1",
  },
  {
    ru: "забывать",
    port: "esquecer",
    eu: "esqueço",
    tu: "esqueces",
    ele: "esquece",
    nos: "esquecemos",
    eles: "esquecem",
    spr: "2",
  },
  {
    ru: "прятать",
    port: "esconder ",
    eu: "escondo",
    tu: "escondes",
    ele: "esconde",
    nos: "escondemos",
    eles: "escondem",
    spr: "2",
  },
  {
    ru: "писать",
    port: "escrever ",
    eu: "escrevo",
    tu: "escreves",
    ele: "escreve",
    nos: "escrevemos",
    eles: "escrevem",
    spr: "2",
  },
  {
    ru: "ждать",
    port: "esperar",
    eu: "espero",
    tu: "esperas",
    ele: "espera",
    nos: "esperamos",
    eles: "esperam",
    spr: "1",
  },
  {
    ru: "учить",
    port: "estudar",
    eu: "estudo",
    tu: "estudas",
    ele: "estuda",
    nos: "estudamos",
    eles: "estudam",
    spr: "1",
  },
  {
    ru: "понимать",
    port: "entender",
    eu: "entendo",
    tu: "entendes",
    ele: "entende",
    nos: "entendemos",
    eles: "entendem",
    spr: "2",
  },
  {
    ru: "быть",
    port: "estar",
    eu: "estou",
    tu: "estás",
    ele: "está",
    nos: "estamos",
    eles: "estão",
    spr: "4",
  },
  {
    ru: "существовать",
    port: "existir",
    eu: "existo",
    tu: "existes",
    ele: "existe",
    nos: "existimos",
    eles: "existem",
    spr: "1",
  },
  {
    ru: "обьяснять",
    port: "explicar",
    eu: "explico",
    tu: "explicas",
    ele: "explica",
    nos: "explicamos",
    eles: "explicam",
    spr: "1",
  },
  {
    ru: "говорить",
    port: "falar",
    eu: "falo",
    tu: "falas",
    ele: "fala",
    nos: "falamos",
    eles: "falam",
    spr: "1",
  },
  {
    ru: "закрываться",
    port: "fechar",
    eu: "fecho",
    tu: "fechas",
    ele: "fecha",
    nos: "fechamos",
    eles: "fecham",
    spr: "1",
  },
  {
    ru: "находиться",
    port: "ficar",
    eu: "fico",
    tu: "ficas",
    ele: "fica",
    nos: "ficamos",
    eles: "ficam",
    spr: "1",
  },
  {
    ru: "делать",
    port: "fazer",
    eu: "faço",
    tu: "fazes",
    ele: "faz",
    nos: "fazemos",
    eles: "fazem",
    spr: "4",
  },
  {
    ru: "курить",
    port: "fumar",
    eu: "fumo",
    tu: "fumas",
    ele: "fuma",
    nos: "fumamos",
    eles: "fumam",
    spr: "1",
  },
  {
    ru: "зарабатывать",
    port: "ganhar",
    eu: "ganho",
    tu: "ganhas",
    ele: "ganha",
    nos: "ganhamos",
    eles: "ganham",
    spr: "1",
  },
  {
    ru: "гарантировать",
    port: "garantir ",
    eu: "garanto",
    tu: "garantes",
    ele: "garante",
    nos: "garantimos",
    eles: "garantem",
    spr: "3",
  },
  {
    ru: "тратить",
    port: "gastar",
    eu: "gasto",
    tu: "gastas",
    ele: "gasta",
    nos: "gastamos",
    eles: "gastam",
    spr: "1",
  },
  {
    ru: "вращаться",
    port: "girar",
    eu: "giro",
    tu: "giras",
    ele: "gira",
    nos: "giramos",
    eles: "giram",
    spr: "1",
  },
  {
    ru: "нравиться",
    port: "gostar",
    eu: "gosto",
    tu: "gostas",
    ele: "gosta",
    nos: "gostamos",
    eles: "gostam",
    spr: "1",
  },
  {
    ru: "кричать",
    port: "gritar",
    eu: "grito",
    tu: "gritas",
    ele: "grita",
    nos: "gritamos",
    eles: "gritam",
    spr: "1",
  },
  {
    ru: "храненить",
    port: "guardar",
    eu: "guardo",
    tu: "guardas",
    ele: "guarda",
    nos: "guardamos",
    eles: "guardam",
    spr: "1",
  },
  {
    ru: "иметься",
    port: "haver",
    eu: "hei",
    tu: "hás",
    ele: "há",
    nos: "havemos",
    eles: "hão",
    spr: "4",
  },
  {
    ru: "преставлять",
    port: "introduzir",
    eu: "introduzo",
    tu: "introduzes",
    ele: "introduze",
    nos: "introduzimos",
    eles: "introduzem",
    spr: "3",
  },
  {
    ru: "печатать",
    port: "imprimir",
    eu: "imprimiro",
    tu: "imprimires",
    ele: "imprimire",
    nos: "imprimimos",
    eles: "imprimem",
    spr: "3",
  },
  {
    ru: "ужинать",
    port: "jantar ",
    eu: "janto",
    tu: "jantas",
    ele: "janta",
    nos: "jantamos",
    eles: "jantam",
    spr: "1",
  },
  {
    ru: "играть",
    port: "jogar",
    eu: "jogo",
    tu: "jogas",
    ele: "joga",
    nos: "jogamos",
    eles: "jogam",
    spr: "1",
  },
  {
    ru: "бросать",
    port: "lançar",
    eu: "lanço",
    tu: "lanças",
    ele: "lança",
    nos: "lançamos",
    eles: "lançam",
    spr: "1",
  },
  {
    ru: "полдничать",
    port: "lanchar",
    eu: "lancho",
    tu: "lanchas",
    ele: "lancha",
    nos: "lanchamos",
    eles: "lancham",
    spr: "1",
  },
  {
    ru: "читать",
    port: "ler",
    eu: "leio",
    tu: "lês",
    ele: "lê",
    nos: "lemos",
    eles: "leem",
    spr: "4",
  },
  {
    ru: "мыть",
    port: "lavar",
    eu: "lavo",
    tu: "lavas",
    ele: "lava",
    nos: "lavamos",
    eles: "lavam",
    spr: "1",
  },
  {
    ru: "помнить",
    port: "lembrar",
    eu: "lembro",
    tu: "lembras",
    ele: "lembra",
    nos: "lembramos",
    eles: "lembram",
    spr: "1",
  },
  {
    ru: "вставать",
    port: "levantar",
    eu: "levanto",
    tu: "levantas",
    ele: "levanta",
    nos: "levantamos",
    eles: "levantam",
    spr: "1",
  },
  {
    ru: "брать, забирать",
    port: "levar ",
    eu: "levo",
    tu: "levas",
    ele: "leva",
    nos: "levamos",
    eles: "levam",
    spr: "1",
  },
  {
    ru: "включать, звонить",
    port: "ligar",
    eu: "ligo",
    tu: "ligas",
    ele: "liga",
    nos: "ligamos",
    eles: "ligam",
    spr: "1",
  },
  {
    ru: "чистить",
    port: "limpar",
    eu: "limpo",
    tu: "limpas",
    ele: "limpa",
    nos: "limpamos",
    eles: "limpam",
    spr: "1",
  },
  {
    ru: "улучшить",
    port: "melhorar",
    eu: "melhoro",
    tu: "melhoras",
    ele: "melhora",
    nos: "melhoramos",
    eles: "melhoram",
    spr: "1",
  },
  {
    ru: "записаться",
    port: "marcar",
    eu: "marco",
    tu: "marcas",
    ele: "marca",
    nos: "marcamos",
    eles: "marcam",
    spr: "1",
  },
  {
    ru: "вставлять",
    port: "meter",
    eu: "meto",
    tu: "metes",
    ele: "mete",
    nos: "metemos",
    eles: "metem",
    spr: "2",
  },
  {
    ru: "измерять",
    port: "medir",
    eu: "meço",
    tu: "medes",
    ele: "mede",
    nos: "medimos",
    eles: "medem",
    spr: "4",
  },
  {
    ru: "отправлять",
    port: "mandar",
    eu: "mando",
    tu: "mandas",
    ele: "manda",
    nos: "mandamos",
    eles: "mandam",
    spr: "1",
  },
  {
    ru: "умереть",
    port: "morrer",
    eu: "morro",
    tu: "morres",
    ele: "morre",
    nos: "morremos",
    eles: "morrem",
    spr: "2",
  },
  {
    ru: "показывать",
    port: "mostrar",
    eu: "mostro",
    tu: "mostras",
    ele: "mostra",
    nos: "mostramos",
    eles: "mostram",
    spr: "1",
  },
  {
    ru: "изменять",
    port: "mudar",
    eu: "muda",
    tu: "mudas",
    ele: "muda",
    nos: "mudamos",
    eles: "mudam",
    spr: "1",
  },
  {
    ru: "жить",
    port: "morar",
    eu: "moro",
    tu: "moras",
    ele: "mora",
    nos: "moramos",
    eles: "moram",
    spr: "1",
  },
  {
    ru: "кусать",
    port: "morder",
    eu: "mordo",
    tu: "mordes",
    ele: "morde",
    nos: "mordemos",
    eles: "mordem",
    spr: "2",
  },
  {
    ru: "показывать",
    port: "mostrar",
    eu: "mostro",
    tu: "mostras",
    ele: "mostra",
    nos: "mostramos",
    eles: "mostram",
    spr: "1",
  },
  {
    ru: "встречаться",
    port: "namorar",
    eu: "namoro",
    tu: "namoras",
    ele: "namora",
    nos: "namoramos",
    eles: "namoram",
    spr: "1",
  },
  {
    ru: "родиться",
    port: "nascer",
    eu: "nasço",
    tu: "nasces",
    ele: "nasce",
    nos: "nascemos",
    eles: "nascem",
    spr: "2",
  },
  {
    ru: "плавать",
    port: "nadar",
    eu: "nado",
    tu: "nadas",
    ele: "nada",
    nos: "nadamos",
    eles: "nadam",
    spr: "1",
  },
  {
    ru: "предлагать",
    port: "oferecer",
    eu: "ofereço",
    tu: "ofereces",
    ele: "oferece",
    nos: "oferecem",
    eles: "oferecemos",
    spr: "2",
  },
  {
    ru: "смотреть",
    port: "olhar",
    eu: "olho",
    tu: "olhas",
    ele: "olha",
    nos: "olhamos",
    eles: "olham",
    spr: "1",
  },
  {
    ru: "платить",
    port: "pagar ",
    eu: "pago",
    tu: "pagas",
    ele: "paga",
    nos: "pagamos",
    eles: "pagam",
    spr: "1",
  },
  {
    ru: "останавливаться",
    port: "parar",
    eu: "para",
    tu: "paras",
    ele: "para",
    nos: "paramos",
    eles: "param",
    spr: "1",
  },
  {
    ru: "казаться",
    port: "parecer",
    eu: "pareço",
    tu: "pareces",
    ele: "parece",
    nos: "parecemos",
    eles: "parecem",
    spr: "2",
  },
  {
    ru: "участвовать",
    port: "participar",
    eu: "participo",
    tu: "participas",
    ele: "participa",
    nos: "participamos",
    eles: "participam",
    spr: "1",
  },
  {
    ru: "делиться",
    port: "partilhar",
    eu: "partilho",
    tu: "partilhas",
    ele: "partilha",
    nos: "partilhamos",
    eles: "partilham",
    spr: "1",
  },
  {
    ru: "уезжать, ломать, разбивать",
    port: "partir",
    eu: "parto",
    tu: "partes",
    ele: "parte",
    nos: "partimos",
    eles: "partem",
    spr: "3",
  },
  {
    ru: "тратить, проводить",
    port: "passar",
    eu: "passo",
    tu: "passes",
    ele: "passa",
    nos: "passamos",
    eles: "passam",
    spr: "1",
  },
  {
    ru: "брать, ловить",
    port: "pegar",
    eu: "pego",
    tu: "pegas",
    ele: "pega",
    nos: "pegamos",
    eles: "pegam",
    spr: "1",
  },
  {
    ru: "обдумывать",
    port: "pensar",
    eu: "penso",
    tu: "pensas",
    ele: "pensa",
    nos: "pensamos",
    eles: "pensam",
    spr: "1",
  },
  {
    ru: "понимать",
    port: "perceber ",
    eu: "percebo",
    tu: "percebes",
    ele: "percebe",
    nos: "percebomos",
    eles: "persebem",
    spr: "2",
  },
  {
    ru: "обещать",
    port: "prometer",
    eu: "prometo",
    tu: "prometes",
    ele: "promete",
    nos: "prometemos",
    eles: "prometem",
    spr: "2",
  },
  {
    ru: "терять",
    port: "perder",
    eu: "perco",
    tu: "perdes",
    ele: "perde",
    nos: "perdemos",
    eles: "perdem",
    spr: "4",
  },
  {
    ru: "спрашивать",
    port: "perguntar",
    eu: "pergunto",
    tu: "perguntas",
    ele: "pergunta",
    nos: "perguntamos",
    eles: "perguntam",
    spr: "1",
  },
  {
    ru: "взвешивать",
    port: "pesar",
    eu: "peso",
    tu: "pesas",
    ele: "pesa",
    nos: "pesamos",
    eles: "pesam",
    spr: "1",
  },
  {
    ru: "намереваться",
    port: "pretender",
    eu: "pretendo",
    tu: "pretendes",
    ele: "pretende",
    nos: "pretendemos",
    eles: "pretendem",
    spr: "2",
  },
  {
    ru: "искать",
    port: "procurar",
    eu: "procuro",
    tu: "procuras",
    ele: "procura",
    nos: "procuramos",
    eles: "procuram",
    spr: "1",
  },
  {
    ru: "предпочитать",
    port: "preferir",
    eu: "prefiro",
    tu: "preferes",
    ele: "prefere",
    nos: "preferimos",
    eles: "preferem",
    spr: "4",
  },
  {
    ru: "разрешать",
    port: "permitir",
    eu: "permito",
    tu: "permite",
    ele: "permites",
    nos: "permitimos",
    eles: "permitem",
    spr: "3",
  },
  {
    ru: "рисовать красками",
    port: "pintar",
    eu: "pinto",
    tu: "pintas",
    ele: "pinta",
    nos: "pintamos",
    eles: "pintam",
    spr: "1",
  },
  {
    ru: "класть, положить",
    port: "pôr",
    eu: "ponho",
    tu: "pões",
    ele: "põe",
    nos: "pomos",
    eles: "põem",
    spr: "4",
  },
  {
    ru: "мочь",
    port: "poder",
    eu: "posso",
    tu: "podes",
    ele: "pode",
    nos: "podemos",
    eles: "podem",
    spr: "4",
  },
  {
    ru: "сохранять",
    port: "poupar",
    eu: "poupo",
    tu: "poupas",
    ele: "poupa",
    nos: "poupamos",
    eles: "poupam",
    spr: "1",
  },
  {
    ru: "нуждаться",
    port: "precisar",
    eu: "preciso",
    tu: "precisas",
    ele: "precisa",
    nos: "precisamos",
    eles: "precisam",
    spr: "1",
  },
  {
    ru: "обезопасить",
    port: "prender",
    eu: "prendo",
    tu: "prendes",
    ele: "prende",
    nos: "prendemos",
    eles: "prendem",
    spr: "2",
  },
  {
    ru: "готовить",
    port: "preparar ",
    eu: "preparo",
    tu: "preparas",
    ele: "prepapa",
    nos: "preparamos",
    eles: "prepapam",
    spr: "1",
  },
  {
    ru: "защищать",
    port: "proteger",
    eu: "protejo",
    tu: "proteges",
    ele: "protege",
    nos: "protegemos",
    eles: "protegem",
    spr: "2",
  },
  {
    ru: "получать",
    port: "receber ",
    eu: "recebo",
    tu: "recebes",
    ele: "recebe",
    nos: "recebemos",
    eles: "recebem",
    spr: "2",
  },
  {
    ru: "повторять",
    port: "repetir",
    eu: "repeto",
    tu: "repetes",
    ele: "repete",
    nos: "repetimos",
    eles: "repetiem",
    spr: "3",
  },
  {
    ru: "проживать",
    port: "residir",
    eu: "resido",
    tu: "resides",
    ele: "reside",
    nos: "residimos",
    eles: "residem",
    spr: "3",
  },
  {
    ru: "сопротивляться",
    port: "resistir",
    eu: "resisto",
    tu: "resistes",
    ele: "resiste",
    nos: "resistimos",
    eles: "resistem",
    spr: "3",
  },
  {
    ru: "решать проблему",
    port: "resolver",
    eu: "resolvo",
    tu: "resolves",
    ele: "resolve",
    nos: "resolvemos",
    eles: "resolvem",
    spr: "2",
  },
  {
    ru: "отвечать",
    port: "responder",
    eu: "respondo",
    tu: "respondes",
    ele: "responde",
    nos: "respondemos",
    eles: "respondem",
    spr: "2",
  },
  {
    ru: "возвращаться",
    port: "regressar",
    eu: "regresso",
    tu: "regressas",
    ele: "regressa",
    nos: "regressamos",
    eles: "regressam",
    spr: "1",
  },
  {
    ru: "отвечать",
    port: "responder",
    eu: "respondo",
    tu: "respondes",
    ele: "responde",
    nos: "respondemos",
    eles: "respondem",
    spr: "1",
  },
  {
    ru: "расставаться",
    port: "romper",
    eu: "rompo",
    tu: "rompes",
    ele: "rompe",
    nos: "rompemos",
    eles: "rompem",
    spr: "2",
  },
  {
    ru: "сушить",
    port: "secar",
    eu: "seco",
    tu: "secas",
    ele: "seca",
    nos: "secamos",
    eles: "secam",
    spr: "1",
  },
  {
    ru: "чувствовать",
    port: "sentir",
    eu: "sinto",
    tu: "sentes",
    ele: "sente",
    nos: "sentimos",
    eles: "sentem",
    spr: "3",
  },
  {
    ru: "разделять",
    port: "separar",
    eu: "separo",
    tu: "separas",
    ele: "separa",
    nos: "separamos",
    eles: "separam",
    spr: "1",
  },
  {
    ru: "обслуживать",
    port: "servir",
    eu: "sirvo",
    tu: "serves",
    ele: "serve",
    nos: "servimos",
    eles: "servem",
    spr: "3",
  },
  {
    ru: "удивлять",
    port: "surpreender ",
    eu: "surpreendo",
    tu: "surpreendes",
    ele: "surpreende",
    nos: "surpreendemos",
    eles: "surprendem",
    spr: "2",
  },
  {
    ru: "попытаться",
    port: "tentar",
    eu: "tento",
    tu: "tentas",
    ele: "tenta",
    nos: "tentamos",
    eles: "tentam",
    spr: "1",
  },
  {
    ru: "взять, брать",
    port: "tirar",
    eu: "tiro",
    tu: "tiras",
    ele: "tira",
    nos: "tiramos",
    eles: "tiram",
    spr: "1",
  },
  {
    ru: "играть на муз.инструметнах",
    port: "tocar",
    eu: "toco",
    tu: "tocas",
    ele: "toca",
    nos: "tocamos",
    eles: "tocam",
    spr: "1",
  },
  {
    ru: "брать, принимать",
    port: "tomar ",
    eu: "tomo",
    tu: "tomas",
    ele: "toma",
    nos: "tomamos",
    eles: "tomam",
    spr: "1",
  },
  {
    ru: "работать",
    port: "trabalhar",
    eu: "trabalho",
    tu: "trabalhas",
    ele: "trabalha",
    nos: "trabalhamos",
    eles: "trabalham",
    spr: "1",
  },
  {
    ru: "заботиться о ком то",
    port: "tratar",
    eu: "trato",
    tu: "tratas",
    ele: "trata",
    nos: "tratamos",
    eles: "tratam",
    spr: "1",
  },
  {
    ru: "приносить",
    port: "trazer",
    eu: "trago",
    tu: "trazes",
    ele: "traz",
    nos: "trazemos",
    eles: "trazem",
    spr: "4",
  },
  {
    ru: "менять",
    port: "trocar",
    eu: "troco",
    tu: "trocas",
    ele: "troca",
    nos: "trocamos",
    eles: "trocam",
    spr: "1",
  },
  {
    ru: "использовать",
    port: "usar",
    eu: "uso",
    tu: "usas",
    ele: "usa",
    nos: "usamos",
    eles: "usam",
    spr: "1",
  },
  {
    ru: "одевать",
    port: "vestir",
    eu: "visto",
    tu: "vestes",
    ele: "veste",
    nos: "vestimos",
    eles: "vestem",
    spr: "4",
  },
  {
    ru: "путешествовать",
    port: "viajar",
    eu: "viajo",
    tu: "viajas",
    ele: "viaja",
    nos: "viajamos",
    eles: "viajam",
    spr: "1",
  },
  {
    ru: "повернуть",
    port: "virar",
    eu: "viro",
    tu: "viras",
    ele: "vira",
    nos: "viramos",
    eles: "viram",
    spr: "1",
  },
  {
    ru: "жить",
    port: "viver ",
    eu: "vivo",
    tu: "vives",
    ele: "vive",
    nos: "vivemos",
    eles: "vivem",
    spr: "2",
  },
  {
    ru: "возвращаться",
    port: "voltar ",
    eu: "volto",
    tu: "voltas",
    ele: "volta",
    nos: "voltamos",
    eles: "voltam",
    spr: "1",
  },
  {
    ru: "продавать",
    port: "vender",
    eu: "vendo",
    tu: "vendes",
    ele: "vende",
    nos: "vendemos",
    eles: "vendem",
    spr: "2",
  },
  {
    ru: "хотеть",
    port: "querer",
    eu: "quero",
    tu: "queres",
    ele: "quer",
    nos: "queremos",
    eles: "querem",
    spr: "4",
  },
  {
    ru: "знать",
    port: "saber",
    eu: "sei",
    tu: "sabes",
    ele: "sabe",
    nos: "sabemos",
    eles: "sabem",
    spr: "4",
  },
  {
    ru: "быть",
    port: "ser",
    eu: "sou",
    tu: "és",
    ele: "é",
    nos: "somos",
    eles: "são",
    spr: "4",
  },
  {
    ru: "иметь ",
    port: "ter",
    eu: "tenho",
    tu: "tens",
    ele: "tem",
    nos: "temos",
    eles: "têm",
    spr: "4",
  },
  {
    ru: "видеть",
    port: "ver",
    eu: "vejo",
    tu: "vês",
    ele: "vê",
    nos: "vemos",
    eles: "veem",
    spr: "4",
  },
  {
    ru: "спать",
    port: "dormir",
    eu: "durmo",
    tu: "dormes",
    ele: "dorme",
    nos: "dormimos",
    eles: "dormem",
    spr: "4",
  },
  {
    ru: "идти",
    port: "ir",
    eu: "vou",
    tu: "vais",
    ele: "vai",
    nos: "vamos",
    eles: "vão",
    spr: "4",
  },
  {
    ru: "просить",
    port: "pedir",
    eu: "peço",
    tu: "pedes",
    ele: "pede",
    nos: "pedimos",
    eles: "pedem",
    spr: "4",
  },
  {
    ru: "выходить",
    port: "sair",
    eu: "saio",
    tu: "sais",
    ele: "sai",
    nos: "samos",
    eles: "saem",
    spr: "4",
  },
  {
    ru: "подниматься",
    port: "subir",
    eu: "subo",
    tu: "sobes",
    ele: "sobe",
    nos: "subimos",
    eles: "sobem",
    spr: "4",
  },
  {
    ru: "слышать",
    port: "ouvir",
    eu: "ouço",
    tu: "ouves",
    ele: "ouve",
    nos: "ouvimos",
    eles: "ouvem",
    spr: "4",
  },
  {
    ru: "приходить, приезжать",
    port: "vir",
    eu: "venho",
    tu: "vens",
    ele: "vem",
    nos: "vimos",
    eles: "vêm",
    spr: "4",
  },
  {
    ru: "должен",
    port: "dever",
    eu: "devo",
    tu: "deves",
    ele: "deve",
    nos: "devemos",
    eles: "devem",
    spr: "2",
  },
  {
    ru: "сидеть",
    port: "sentar",
    eu: "sento",
    tu: "sentas",
    ele: "senta",
    nos: "sentamos",
    eles: "sentam",
    spr: "1",
  },
  {
    ru: "улыбаться",
    port: "sorrir",
    eu: "sorrio",
    tu: "sorris",
    ele: "sorri",
    nos: "sorrimos",
    eles: "sorriem",
    spr: "3",
  },
  {
    ru: "подниматься",
    port: "subir",
    eu: "subo",
    tu: "sobes",
    ele: "sobe",
    nos: "subimos",
    eles: "sobem",
    spr: "4",
  },
  {
    ru: "прагать",
    port: "saltar",
    eu: "salto",
    tu: "saltas",
    ele: "salta",
    nos: "saltamos",
    eles: "saltam",
    spr: "1",
  },
  {
    ru: "ненавидеть",
    port: "odiar",
    eu: "odeio",
    tu: "odeias",
    ele: "odeia",
    nos: "odiamos",
    eles: "odeiam",
    spr: "4",
  },
  {
    ru: "смеяться",
    port: "rir",
    eu: "rio",
    tu: "ris",
    ele: "ri",
    nos: "rimos",
    eles: "riem",
    spr: "4",
  },
];
