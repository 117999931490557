import { useEffect, useState } from "react";
import styles from "./listtemf.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { tems, tems_1, tems_2 } from "../../utils/team";
import { slova } from "../../utils/slova";
import { teamm } from "../../utils/fraz";

type TProps = {
  setslova: React.Dispatch<
    React.SetStateAction<
      {
        ru: string;
        port: string;
      }[]
    >
  >;
  setname: React.Dispatch<React.SetStateAction<string>>;
  setv: React.Dispatch<React.SetStateAction<boolean>>;
};

function Listtemf(props: TProps) {
  const [base, setbase] = useState(true);
  const [name, setname] = useState("Основные фразы");
  const [active, setactive] = useState(false);

  const [isVisible, setVisible] = useState(false);

  const handleClick = (nom: string[], name: string) => {};

  const handleVisibility = () => {
    setVisible(!isVisible);
  };

  type Tyyy = {
    name: string;
    list: {
      ru: string;
      port: string;
    }[];
  };
  const buttonHandler_2 = (ttt: Tyyy) => {
    props.setslova(ttt.list);
    props.setname(ttt.name);
    props.setv(false);
  };

  const pVariants = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <div className={styles.list_team}>
      <motion.div
        initial={"hidden"}
        animate={"visible"}
        transition={{
          delay: 0.1,
          duration: 0.2,
        }}
        variants={pVariants}
        className={styles.list}
      >
        {teamm.map((item, index) => {
          return (
            <motion.div
              className={styles.list_item}
              key={index}
              whileHover={{ scale: 1.1 }}
              onClick={() => {
                buttonHandler_2(item);
              }}
            >
              {item.name}
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
}

export default Listtemf;
