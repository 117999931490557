import { useEffect, useState } from "react";
import AppHeader from "../../components/app-header/app-header";
import Cards from "../../components/cards/cards";
import { aone } from "../../utils/aone";
import { shuffle } from "../../utils/convert";
import styles from "./frazs.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { tems, tems_2 } from "../../utils/team";
import { slova } from "../../utils/slova";
import Listtem from "../../components/listtem/listtem";
import Cardsw from "../../components/cardsw/cardsw";
import Listtemf from "../../components/listtemf/listtemf";
import Cardf from "../../components/cardsf/cardsf";

function Frazs() {
  const list = shuffle(aone);
  const [base, setbase] = useState(true);
  const [name, setname] = useState("...");
  const [slova, setslova] = useState([{ ru: "я", port: "eu" }]);
  const [active, setactive] = useState(false);

  const [isVisible, setVisible] = useState(false);

  const handleClick = () => {};

  const handleVisibility = () => {
    setVisible(!isVisible);
  };

  const pVariants = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  useEffect(() => {
    if (isVisible === true) {
      document.addEventListener("click", hendlerClick);
    }
    if (isVisible === false) {
      document.removeEventListener("click", hendlerClick);
    }
  }, [isVisible]);

  const hendlerClick = (evt: any) => {
    if (
      !evt.target.className.includes("list") &&
      !evt.target.className.includes("res")
    ) {
      if (isVisible === true) {
        if (evt.target.className) {
          setVisible(false);
        }
      }
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <AppHeader name={"Карточки с фразами"}></AppHeader>
      </div>
      <div className={styles.block}>
        <div className={styles.res} onClick={() => setVisible(true)}>
          {` Тема набора: ${name}`}
        </div>
        <Cardf list={slova}></Cardf>
      </div>
      {isVisible && (
        <div className={styles.list}>
          <Listtemf
            setname={setname}
            setslova={setslova}
            setv={setVisible}
          ></Listtemf>
        </div>
      )}
    </div>
  );
}

export default Frazs;
