import styles from "./speak.module.css";
import sp from "../../images/speak.png";
import { speak } from "../../utils/speak";

type Tprops = {
  text: string;
};
const pVariants_2 = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};
const pVariants_3 = {
  hidden: {
    width: 0,
    opacity: 0,
  },
  visible: {
    width: 320,
    opacity: 1,
  },
};

const Speak = (props: Tprops) => {
  return (
    <div className={styles.main}>
      <button onClick={() => speak(props.text, 0.8)} className={styles.group}>
        <img src={sp} alt="speak" className={styles.image}></img>
        <p className={styles.text}>1.0</p>
      </button>
      <button onClick={() => speak(props.text, 0.4)} className={styles.group}>
        <img src={sp} alt="speak" className={styles.image}></img>
        <p className={styles.text}>0.5</p>
      </button>
    </div>
  );
};

export default Speak;
