import { useState } from "react";
import ListTeams from "../../components/list-teams/list-teams";
import { tems, tems_2 } from "../../utils/team";
import styles from "./found-list.module.css";
import {
  Input,
  Button,
} from "@ya.praktikum/react-developer-burger-ui-components";
import { slova } from "../../utils/slova";
import { TSlova } from "../../components/list-teams/list-teams";
import { AppHeader } from "../../components/app-header/app-header";

function FoundList() {
  const slovo = { ru: "я", port: "eu" };
  let list: TSlova[] = [];

  const [word, setWord] = useState("");
  const [wordtr, setWordtr] = useState(list);
  const [stringt, setstringt] = useState("");
  const [teamname, setTeamname] = useState("");

  const setValueWord = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setWord(evt.target.value.toLowerCase());
    setstringt("");
    setWordtr(list);
  };

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    if (!word) {
      setstringt("А что искать то?");
      return;
    }
    setstringt("Почему, то ничего не нашли, проверьте правильность написания");
    {
      slova.map((item) => {
        {
          item.dict.map((elem) => {
            if (elem.port.includes(word)) {
              list.push(elem);
              setWordtr(list);
              setstringt("Вот что удалось найти:");
            }
            if (elem.ru.includes(word)) {
              list.push(elem);
              setWordtr(list);
              setstringt("Вот что удалось найти:");
            }
          });
        }
      });
    }
  };

  const listTeam = tems;
  let index = 0;
  return (
    <div className={styles.main}>
      <AppHeader name={"Еще больше интересного"}></AppHeader>
      <form className={styles.form} onSubmit={onSubmit}>
        <input
          type="text"
          placeholder="Какое слово нужно найти?"
          onChange={setValueWord}
          value={word}
          name={"слово"}
          className={` ${styles.buttonbase} ${styles.button} `}
        />
        <button
          type="submit"
          className={` ${styles.buttons} ${styles.button} `}
        >
          Найти слово
        </button>
      </form>

      <div className={styles.list}>
        <p>{stringt}</p>
        {wordtr.map((elem) => {
          index = index + 1;
          return (
            <p
              key={index}
            >{`по русски: ${elem.ru} по португальски ${elem.port}`}</p>
          );
        })}
      </div>
    </div>
  );
}

export default FoundList;
