import styles from "./end.module.css";
import log from "../../images/Patreon_logo.svg.png";

export function End() {
  return (
    <div className={styles.main}>
      <a
        className={`${styles.link} ${styles.link_b}`}
        href="https://patreon.com/portuguesdicionario?utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink"
      >
        поддержать автора
        <img src={log} alt="lofo patreon" className={styles.imageP} />
      </a>
    </div>
  );
}
export default End;
