export const pred_1L = [
  {
    port: "О rapaz é о mais simpático de todos.",
    rus: "Мальчик самый красивый из всех.",
  },
  {
    port: "A menina parece a mais sisuda de toda a família.",
    rus: "Девушка кажется самой серьезной из всей семьи.",
  },
  {
    port: "Acho que ela é a mais alta da turma.",
    rus: "Я думаю, что она самая высокая в классе.",
  },
  {
    port: "O meu primo é o mais risonho e divertido de todos.",
    rus: "Мой двоюродный брат самый веселый и забавный из всех.",
  },
  {
    port: " O seu sobrinho é o mais moreno de todos.",
    rus: "Твой племянник самый темноволосый из всех.",
  },
  {
    port: "A sua sobrinha é a mais loira da família.",
    rus: "Твоя племянница самая светловолосая в семье.",
  },
  {
    port: "A minha prima é a mais pontual de todos.",
    rus: "Моя двоюродная сестра самыя пунктуальная из всех.",
  },
  {
    port: "A avó parece a mais velha de toda a famíla.",
    rus: "Бабушка, кажется, самая старшая во всей семье.",
  },
  {
    port: "Sou o mais de- dicado de todos.",
    rus: "Я самый преданный из всех.",
  },
  {
    port: "Ele é o menos introvertido de todos os primos.",
    rus: "Он наименее замкнутый из всех кузенов.",
  },
  {
    port: "O rapaz é o mais baixo dos outros.",
    rus: "Мальчик самый низкий из остальных.",
  },
  {
    port: "O André faz o trabalho de casa.",
    rus: "Андре делает домашнее задание.",
  },
  {
    port: "A Marina faz toda a lide da casa.",
    rus: "Марина делает всю работу по дому.",
  },
  { port: "A Patrícia prepara o jantar.", rus: "Патрисия готовит ужин." },
  {
    port: "A empregada doméstica lava a loiça.",
    rus: "Горничная моет посуду.",
  },
  { port: "A mãe serve a sopa.", rus: "Мама подает суп." },
  { port: "O António lava a roupa.", rus: "Антониу стирает одежду." },
  { port: "O Pedro escreve os exercícios.", rus: "Педро пишет упражнения." },
  { port: "A Nora põe a mesa.", rus: "Нора накрывает на стол." },
  { port: "As crianças vêem o filme.", rus: "Дети смотрят фильм." },
  {
    port: "O director paga os salários aos operários.",
    rus: "Директор выплачивает зарплату работникам.",
  },
  { port: "O rapaz ganha dinheiro.", rus: "Мальчик зарабатывает деньги." },
  {
    port: "Os jovens gastam muito dinheiro.",
    rus: "Молодежь тратит много денег.",
  },
  {
    port: "O povo elege o seu presidente.",
    rus: "Народ выбирает своего президента.",
  },
  {
    port: "A administração expulsa o aluno da universidade.",
    rus: "Администрация отчисляет студента из университета.",
  },
  {
    port: "Estou a tirar um curso de pósgraduação.",
    rus: "Я учусь в аспирантуре.",
  },
  {
    port: "Joana, tira o casaco, porque está calor.",
    rus: "Джоана, сними пальто, потому что жарко.",
  },
  {
    port: "O Miguel, quando chega à praia, tira os sapatos.",
    rus: "Мигель, придя на пляж, снимает обувь.",
  },
  {
    port: "Vou tirar férias e ir para Cabo Verde.",
    rus: "Я собираюсь взять отпуск и поехать в Кабо-Верде.",
  },
  {
    port: "Já não preciso de copiar manualmente os textos, tiro fotocópias.",
    rus: "Мне больше не нужно вручную копировать тексты, я делаю ксерокопии.",
  },
  {
    port: "Quando se trata da minha especialização, tiro só boas notas.",
    rus: "По специализации я получаю только хорошие оценки.",
  },
  {
    port: "Vais tirar um cinco a Português, tenho a certeza! ",
    rus: "Я уверен, что ты получишь пятёрку за португальский!",
  },
  {
    port: "Para completar a minha formação vou tirar um curso de informática à distância.",
    rus: "Для завершения обучения я пройду дистанционный курс ИТ.",
  },
  {
    port: "A minha secretária é original e ninguém tem outra igual.",
    rus: "Мой стол оригинальный, и другого подобного нет ни у кого.",
  },
  {
    port: "Há outro género de leitura de que gosto, que é a filosofia.",
    rus: "Есть еще один тип чтения, который мне нравится, — философия.",
  },
  {
    port: "Sabes o número de telefone daquela outra rapariga que conhecemos no cinema?  ",
    rus: "Ты знаешь номер телефона той девушки, которую мы встретили в кино?",
  },
  { port: "Diz-me isto outra vez! ", rus: "Скажи мне это еще раз!" },
  {
    port: "Uma vez limpo eu a louça, outra vez limpas tu.",
    rus: "Как только я помою посуду, ты помоешь ее снова.",
  },
  {
    port: "Dá-me outro lápis, por favor.",
    rus: "Дайте мне еще один карандаш, пожалуйста.",
  },
  {
    port: "Sabes quem me telefonou noutro dia? ",
    rus: "Знаешь, кто звонил мне на днях?",
  },
  {
    port: "Não deves preocupar-te com o que dizem os outros.",
    rus: "Вам не следует беспокоиться о том, что говорят другие.",
  },
  {
    port: "O meu avô está calado, só diz uma coisa ou outra quando lhe apetece.",
    rus: "Дедушка молчит, говорит то или другое только тогда, когда ему хочется.",
  },
  {
    port: "Ele cruzou a meta antes de todos os outros.",
    rus: "Он пересек финиш раньше всех остальных.",
  },
  {
    port: "Ela fica muito focada durante o turno dela",
    rus: "Она очень сосредоточена во время своей смены.",
  },
  {
    port: "Nós comparámos as fotos do antes e depois",
    rus: " Мы сравниваем фото «до» и «после».",
  },
];
export const pred_f = [
  {
    port: "Quando era pequena, brincava sempre com bonecas.",
    rus: "Когда я была маленькой, я всегда играла в куклы.",
  },
  {
    port: "Antigamente viviam no campo.",
    rus: "Раньше они жили в сельской местности.",
  },
  {
    port: "Quando eles eram crianças viviam  fora da cidade.",
    rus: "Когда они были детьми, они жили за пределами города.",
  },
  {
    port: "Quando eles eram crianças levantaram-semuito cedo para ir para a escola",
    rus: "Когда они были детьми, они вставали очень рано, чтобы пойти в школу.",
  },
  {
    port: "Na escolatinhamaulas das 8h00 até às 13h00.",
    rus: "В школе занятия были с 8 утра до 13 часов.",
  },
  {
    port: "Marta e Paulsaiamde casa às 7h00 da manhã.",
    rus: "Марта и Пол выходили из дома в 7:00 утра.",
  },
  {
    port: "Marta e Pauliamde autocarro até à cidade.",
    rus: "Марта и Пол ездили на автобусе в город.",
  },
  {
    port: "Marta e Paulvoltavam para casa  normalmente às 13h00.",
    rus: "Марта и Пол возвращались домой обычно в 13:00.",
  },
  {
    port: "Marta e Paulalmoçavamsempre às 14h00.",
    rus: "Марта и Пол всегда обедали в 14:00.",
  },
  {
    port: "Depois   da escola  elesiamfazer os trabalos de casa  às 14h30.",
    rus: "После школы они делали домашнее задание в 14:30.",
  },
  {
    port: "Antigamente, à noitejantavamcedo.",
    rus: "Раньше ужинали рано вечером.",
  },
  { port: "Antigamente moravam numa vivenda.", rus: "Раньше они жили в доме." },
  {
    port: "Depois da escola faziam sempre os trabalos de casa.",
    rus: "После школы они всегда делали домашнее задание.",
  },
  {
    port: "Ele costumava ir a pé para o trabalho, agora vai de carro.",
    rus: "Раньше он ходил на работу пешком, теперь ездит на машине.",
  },
  {
    port: "Eucostumava usar óculos, agora uso lentes de contacto.",
    rus: "Раньше я носил очки, теперь ношу контактные линзы.",
  },
  {
    port: "Quando morava costumava a andar de carro.",
    rus: "Когда я жил, я путешествовал на машине.",
  },
  {
    port: "Quando morava na cidade,costumava a andar de carro.",
    rus: "Когда я жил в городе, я путешествовал на машине.",
  },
  {
    port: "Eutinha 4 anos quando fui ao cinema pela primeira vez.",
    rus: "Мне было 4 года, когда я впервые пошел в кино.",
  },
  {
    port: "Ontem de manhã, enquanto a Ana tomava duche, a irmã fazia as camas.",
    rus: "Вчера утром, пока Ана принимала душ, ее сестра заправляла кровати.",
  },
  { port: "A mãearrumava a roupa.", rus: "Мама убирала одежду" },
  { port: "A Inêsestava a dormir.", rus: "Инес спала." },
  {
    port: "O Sr. Pinto estava a pintar a sala. ",
    rus: "Мистер Пинто красил комнату.",
  },
  { port: "Eles estavam a jogar no jardim.", rus: "Они играли в саду." },
  {
    port: "Eu estava a ouvir música, quando chefe chegou.",
    rus: "Я слушал музыку, когда пришел босс.",
  },
  {
    port: "Os alunos estavam a trabalhar quando o professor entrou.",
    rus: "Студенты работали, когда вошел учитель.",
  },
  {
    port: "Nós estávamos a trabalhar quando o computador se avariou.",
    rus: "Мы работали, когда сломался компьютер.",
  },
  {
    port: "Enquanto os filhos tomavam duche, a mãe arrumava os quartos. ",
    rus: "Пока дети принимали душ, мама наводила порядок в комнатах.",
  },
  {
    port: "Enquanto eu via televisão, ele lia o jornal.",
    rus: "Пока я смотрел телевизор, он читал газету.",
  },

  {
    port: "Enquanto os filhos tomavam duche, a mãe arrumava os quartos. ",
    rus: "Пока дети принимали душ, мама наводила порядок в комнатах.",
  },
  {
    port: "Enquanto eles preparavam as bebidas, nós púnhamos a mesa.",
    rus: "Пока они готовили напитки, мы накрыли стол.",
  },
  {
    port: "Enquanto ela estava ao telefone, tomava notas.",
    rus: "Пока она разговаривала по телефону, она делала заметки.",
  },

  {
    port: "Enquanto a Xana e o Filipe estudavam, ouviam música.",
    rus: "Пока Ксана и Филипе учились, они слушали музыку.",
  },

  {
    port: "Enquanto a orquestra tocava, o Sr. Martins dormia.",
    rus: "Пока играл оркестр, мистер Мартинс спал.",
  },

  {
    port: "Enquanto as crianças brincavam, nós conversávamos.",
    rus: "Пока дети играли, мы разговаривали.",
  },

  {
    port: "Enquanto a empregada limpava a casa, eu tratava das crianças. ",
    rus: "Пока горничная убирала дом, я присматривал за детьми.",
  },

  {
    port: "Enquanto ele falava, fazia gestos com as mãos.",
    rus: "Говоря это, он жестикулировал руками.",
  },

  {
    port: "Enquanto o Zezinho vestia o pijama, a mãe abria a cama.",
    rus: "Пока Зезиньо надевал пижаму, его мать открыла кровать.",
  },

  {
    port: "Enquanto ela estudava, enviava sms aos amigos.",
    rus: "Пока она училась, она отправляла текстовые сообщения своим друзьям.",
  },

  {
    port: "Enquanto eles jogavam Playstation, elas ouviam música. ",
    rus: "Пока они играли в Playstation, они слушали музыку.",
  },

  {
    port: "Enquanto eu me dirigia para a porta, o cão ladrava.",
    rus: "Когда я подошел к двери,  собака лаяла.",
  },
  {
    port: "Eu costumava trabalhar num escritório, agora trabalho num banco.",
    rus: "Раньше я работал в офисе, сейчас работаю в банке.",
  },
  {
    port: "Aos domingos costumavam ficar em casa, agora vão ao cinema",
    rus: "По воскресеньям раньше оставались дома, теперь ходят в кино.",
  },
  {
    port: "Nós costumávamos ter férias em julho, agora temos férias em agosto.",
    rus: "Раньше у нас были каникулы в июле, теперь каникулы в августе.",
  },
  {
    port: "O Pedro costumava ser muito gordo, agora é magro.",
    rus: "Педро раньше был очень толстым, теперь он похудел.",
  },
  {
    port: "A Ana costumava estudar pouco, agora estuda muito.",
    rus: "Раньше Ана мало училась, теперь она учится много.",
  },
  {
    port: "O Sr. Machado costumava chegar atrasado, agora chega a horas.",
    rus: "Раньше господин Мачадо приходил поздно, теперь он приходит вовремя.",
  },
  {
    port: "Eu costumava praticar desporto, agora não faço nada.",
    rus: "Раньше я занимался спортом, сейчас ничем не занимаюсь.",
  },
  {
    port: "Aos sábados costumava ir à praça, agora vai ao supermercado.",
    rus: "По субботам он ходил на площадь, теперь ходит в супермаркет.",
  },
  {
    port: "As crianças costumavam brincar em casa, agora brincam no jardim.",
    rus: "Раньше дети играли дома, теперь они играют в саду.",
  },
  {
    port: "O João costumava viver com os pais; agora vive sozinho.",
    rus: "Жоау раньше жил со своими родителями; теперь он живет один.",
  },
  { port: "Antigamente não havia aviões.", rus: "Раньше самолетов не было." },
  {
    port: "Antigamente as pessoas costumavam viajar de comboio.",
    rus: "Раньше люди путешествовали на поезде.",
  },
  { port: "Antigamente não havia carros.", rus: "Раньше машин не было." },
  {
    port: "Antigamente as pessoas costumavam andar mais a pé.",
    rus: "Раньше люди больше ходили пешком.",
  },
  {
    port: "Antigamente não havia telefones.",
    rus: "Раньше телефонов не было.",
  },
  {
    port: "Antigamente as pessoas costumavam escrever cartas.",
    rus: "Раньше люди писали письма.",
  },
  {
    port: "Antigamente não havia televisão.",
    rus: "Раньше не было телевидения.",
  },
  {
    port: "Antigamente as pessoas costumavam conversar mais. ",
    rus: "Раньше люди больше говорили.",
  },
  { port: "Antigamente não havia cinema.", rus: "Раньше кинотеатров не было." },
  {
    port: "Antigamente as pessoas costumavam ir ao teatro.",
    rus: "Раньше люди ходили в театр.",
  },
  {
    port: "A mãe costumava fazer compras na mercearia local. ",
    rus: "Мама покупала продукты в местном продуктовом магазине.",
  },
  {
    port: "A tarde costumavam dar passeios de bicicleta. ",
    rus: "Днем они ходили кататься на велосипедах.",
  },
  {
    port: "As crianças costumavam brincar na rua.",
    rus: "Дети играли на улице.",
  },
  {
    port: "Aos domingos costumavam fazer um piquenique.",
    rus: "По воскресеньям они устраивали пикник.",
  },
];
export const pred_a2 = [
  {
    port: "Ela esteve numa prisão do estado.",
    rus: "Она была в государственной тюрьме.",
  },
  { port: "As calças estão curtas.", rus: "Штаны короткие." },
  { port: "Ela tem cabelo comprido.", rus: "Она имеет длинные волосы." },
  { port: "No verão, os dias são mais compridos.", rus: "Летом дни длиннее." },
  {
    port: "Eu gosto de vestidos compridos.",
    rus: "мне нравятся длинные платья.",
  },
  {
    port: "Curto ou comprido, como quer o cabelo?",
    rus: "Короткие или длинные, какие вам волосы?",
  },
  {
    port: "O homem vai ter uma vida curta.",
    rus: "У человека будет короткая жизнь.",
  },
  {
    port: "Ela gosta de vestidos curtos.",
    rus: "Ей нравятся короткие платья.",
  },
  {
    port: "De Lisboa a Madrid é um longo caminho.",
    rus: "От Лиссабона до Мадрида долгий путь.",
  },
  { port: "No inverno, as noites são longas.", rus: "Зимой ночи длинные." },
  { port: "A nossa casa é pequena.", rus: "Наш дом маленький." },
  { port: "Os cães são pequenos.", rus: "Собаки маленькие." },
  { port: "A tua casa é enorme.", rus: "Твой дом огромен." },
  { port: "Os sapatos são grandes.", rus: "Обувь большая." },
  { port: "O cão não é grande.", rus: "Собака не большая." },
  { port: "O teu chapéu é grande.", rus: "Твоя шляпа большая." },
  {
    port: "Ele é mais pequeno do que a irmã dele.",
    rus: "Он меньше своей сестры.",
  },
  { port: "O homem é alto.", rus: "Мужчина высокий." },
  { port: "A árvore é alta.", rus: "Дерево высокое." },
  { port: "A mesa é baixa.", rus: "Стол низкий." },
  { port: "O gato está gordo.", rus: "Кот толстый." },
  { port: "O cão está gordo.", rus: "Собака толстая." },
  { port: "O homem é magro.", rus: "Мужчина худой." },
  { port: "O menino é magro.", rus: "Мальчик худой." },
  { port: "As minhas luvas estão apertadas.", rus: "Мои перчатки тугие." },
  { port: "A saia está apertada.", rus: "Юбка тесная." },
  { port: "Este não é o meu tamanho.", rus: "Это не мой размер." },
  {
    port: "Sabes qual é o teu tamanho?",
    rus: "Знаешь ли ты, какой у тебя размер?",
  },
  { port: "As calças dela estão largas.", rus: "Штаны у нее мешковатые." },
  { port: "Estes sapatos são o meu número.", rus: "Эти туфли - мой номер." },
  { port: "O teu casaco está apertado.", rus: "Ваше пальто тесное." },
  { port: "Eu quero o mesmo que tu.", rus: "Я хочу того же, что и ты." },
  {
    port: "Ela não gosta de roupas apertadas.",
    rus: "Она не любит обтягивающую одежду.",
  },
  { port: "Queria 100 gramas de queijo.", rus: "Я хотел бы 100 граммов сыра." },
  {
    port: "A distância mínima de segurança é de 2 metros.",
    rus: "Минимальное безопасное расстояние составляет 2 метра.",
  },
  {
    port: "O menino media a altura todos os dias.",
    rus: "Мальчик каждый день измерял свой рост.",
  },
  {
    port: "A ponte tem 2 quilómetros de comprimento.",
    rus: "Длина моста 2 километра.",
  },
  {
    port: "A estrada tem 2 metros de largura.",
    rus: "Дорога шириной 2 метра.",
  },
  { port: "Ele gosta de café sem açúcar.", rus: "Он любит кофе без сахара." },
  {
    port: "Ele quer falar sobre ontem.",
    rus: "Он хочет поговорить о вчерашнем дне.",
  },
  { port: "Eu quero café com leite.", rus: "Я хочу кофе с молоком." },
  {
    port: "Até quando ficas em Lisboa?",
    rus: "Как долго вы пробудите в Лиссабоне?",
  },
  {
    port: "Ele vem sozinho desde Lisboa.",
    rus: "Он приезжает один из Лиссабона.",
  },
  {
    port: "A partida joga-se sob forte chuva.",
    rus: "Матч проходит под сильным дождем.",
  },
  {
    port: "Não como doces desde ontem.",
    rus: "Я не ел сладкое со вчерашнего дня.",
  },
  { port: "Até ela pode falar.", rus: "Даже она может говорить." },
  {
    port: "Desde quando é que és vegetariano?",
    rus: "С каких это пор ты стал вегетарианцем?",
  },
  {
    port: "Ela está perante uma decisão difícil.",
    rus: "Ей предстоит принять трудное решение.",
  },
  {
    port: "Após a reunião, discutimos o assunto.",
    rus: "После встречи мы обсудили этот вопрос.",
  },
  {
    port: "Entre azul e vermelho, qual preferes.",
    rus: "Какой из синего и красного вы предпочитаете?",
  },
  { port: "Ele é contra a violência.", rus: "Он против насилия." },
  { port: "Este livro é debaixo da cama.", rus: "Эта книга под кроватью." },
  { port: "Ele corre atrás do cão dele.", rus: "Он бежит за своей собакой." },
  { port: "Ele senta-se ao lado da lareira.", rus: "Он сидит у камина." },
  {
    port: "A entrevista é feita através de Skype.",
    rus: "Собеседование проводится по Skype.",
  },
  { port: "Estou em frente à tua casa.", rus: "Я перед твоим домом." },
  {
    port: "O programa começa dentro de momentos.",
    rus: "Программа запустится через несколько минут.",
  },
  { port: "A área em redor da cidade.", rus: "Территория вокруг города." },
  { port: "Levo tudo exceto o casaco.", rus: "Я беру все, кроме пальто." },
  {
    port: "A reunião decorre conforme esperado.",
    rus: "Встреча проходит, как и ожидалось.",
  },
  { port: "Nós dormimos durante o filme.", rus: "Мы проспали фильм." },
  {
    port: "Põe no frigorífico, senão estraga-se.",
    rus: "Поставьте в холодильник, иначе испортится.",
  },
  { port: "Eu gosto de gatos.", rus: "я люблю котов." },
  { port: "A menina tem um rato.", rus: "У девочки есть мышка." },
  { port: "O macaco gosta de bananas.", rus: "Обезьяна любит бананы." },
  { port: "A vaca dá leite.", rus: "Корова дает молоко." },
  { port: "O peru é uma ave.", rus: "Индейка — птица." },
  { port: "Os patos nadam.", rus: "Утки плавают." },
  { port: "Os pássaros comem pão.", rus: "Птицы едят хлеб." },
  { port: "A galinha come milho.", rus: "Курица ест кукурузу." },
  { port: "A aranha come.", rus: "Паук ест." },
  { port: "Tu gostas de animais?", rus: "Тебе нравятся животные?" },
  { port: "É uma formiga.", rus: "Это муравей." },
  { port: "A que horas é o voo?", rus: "В какое время рейс?" },
  { port: "A viagem é direta?", rus: "Дорога прямая?" },
  { port: "Este autocarro vai para onde?", rus: "Куда идет этот автобус?" },
  {
    port: "Quanto tempo demora a viagem?",
    rus: "Сколько времени занимает поездка?",
  },
  {
    port: "Quando é o próximo autocarro?",
    rus: "Когда будет следующий автобус?",
  },
  { port: "Onde posso encontrar um táxi?", rus: "Где я могу найти такси?" },
  { port: "Como posso chegar lá?", rus: "Как я могу попасть туда?" },
  {
    port: "Sabe onde fica esta rua?",
    rus: "Вы знаете, где находится эта улица?",
  },
  {
    port: "É ali ao virar da esquina, à direita.",
    rus: "Это прямо за углом, справа.",
  },
  {
    port: "Sabe onde fica este restaurante?",
    rus: "Вы знаете, где находится этот ресторан?",
  },
  { port: "Onde se apanha o metro?", rus: "Где вы садитесь на метро?" },
  {
    port: "Em que paragem tenho de sair?",
    rus: "На какой остановке мне нужно выйти?",
  },
  { port: "É perto daqui?.", rus: "Это близко отсюда?" },
  { port: "Tem de atravessar a estrada.", rus: "Вам придется перейти дорогу." },
  { port: "Fica muito longe?", rus: "Это слишком далеко?" },
  {
    port: "Onde fica a paragem de autocarro?",
    rus: "Где автобусная остановка?",
  },
  {
    port: "Onde fica o centro comercial mais próximo?",
    rus: "Где находится ближайший торговый центр?",
  },
  { port: "Fica perto do centro.", rus: "Это близко к центру." },
  { port: "Onde fica a zona dos restaurantes?", rus: "Где ресторанная зона?" },
  {
    port: "Basta ir sempre em frente.",
    rus: "Просто продолжайте двигаться вперед.",
  },
  {
    port: "O restaurante fica ao fundo da rua.",
    rus: "Ресторан находится в конце улицы.",
  },
  { port: "Tem de ir de carro.", rus: "Тебе придется ехать на машине." },
  { port: "É a primeira porta à esquerda.", rus: "Это первая дверь слева." },
  { port: "Não dá para ir a pé.", rus: "Ты не можешь ходить." },
  { port: "Fica algures por aqui.", rus: "Это где-то здесь." },
  { port: "Pode repetir, por favor?", rus: "Можешь повторить, пожалуйста?" },
  { port: "Onde é a casa de banho?", rus: "Где здесь ванная комната?" },
  { port: "Tem de entrar na autoestrada.", rus: "Где здесь ванная комната?" },
  {
    port: "Saia na segunda saída da rotunda.",
    rus: "Сверните на второй съезд с кольцевой развязки.",
  },
  { port: "A entrada é do outro lado.", rus: "Вход с другой стороны." },
  {
    port: "Ele cruzou a meta antes de todos osoutros.",
    rus: "Он пересек финиш раньше всех остальных.",
  },
  {
    port: "Ela fica muito focada durante o turno dela.",
    rus: "Она очень сосредоточена во время своей смены.",
  },
  {
    port: "Nós comparámos as fotos do antes e depois.",
    rus: " Мы сравниваем фото «до» и «после».",
  },
  {
    port: "Eles não falaram durante o concerto.",
    rus: "Они не разговаривали во время концерта.",
  },
  {
    port: "Ele chegou depois das seis e meia da tarde.",
    rus: "Он прибыл после шести тридцати днем.",
  },
  {
    port: "Ele está atrasado, vai chegar mais tarde que o habitual..",
    rus: "Он опаздывает, он придет позже обычного.",
  },
  {
    port: "Eu não consigo funcionar antes das três da tarde.",
    rus: "Я не могу работать раньше трёх часов дня.",
  },
  {
    port: "Agora é tarde demais, já perdi o autocarro.",
    rus: "Теперь уже слишком поздно, я уже опоздал на автобус.",
  },
  {
    port: "O espetáculo começou mais tarde do que o desejado.",
    rus: "Шоу началось позже, чем хотелось.",
  },
  { port: "Nós vamos encontrar-nos mais tarde.", rus: "Мы встретимся позже." },
  {
    port: "Nós não chegámos a casa tarde porque saímos antes do fim.",
    rus: "Мы не вернулись домой поздно, потому что ушли до конца.",
  },
  {
    port: "Ele tomou os medicamentos mais cedo hoje.",
    rus: "Oн принял лекарства раньше сгодня.",
  },
  {
    port: "Ele saiu cedo porque o relógio dele está adiantado.",
    rus: "Он ушел рано, потому что его часы спешат.",
  },
  { port: "Eu vou aparecer mais cedo amanhã.", rus: "Я приду завтра рано." },
  {
    port: "Ela acordou muito cedo hoje.",
    rus: "Сегодня она проснулась очень рано.",
  },
  { port: "Ela é sempre pontual.", rus: "Она всегда пунктуальна." },
  { port: "A escola acabou cedo ontem.", rus: "Школа закончилась вчера рано." },
  {
    port: "Ele não estudou antes do exame de hoje.",
    rus: "Он не учился перед сегодняшним экзаменом.",
  },
  {
    port: "O relógio adiantou uma hora à uma da manhã.",
    rus: "Часы продвинулись на один час в час ночи.",
  },
  {
    port: "Depois da meia-noite, é um novo dia.",
    rus: "После полуночи наступает новый день.",
  },
  {
    port: "Nós vamos chegar mais tarde, porque o voo partiu 15 minutos atrasado.",
    rus: "Мы приедем позже, потому что рейс вылетел с опозданием на 15 минут.",
  },
  {
    port: "A reunião começou antes das dez e meia da manhã.",
    rus: "Заседание началось около половины одиннадцатого утра.",
  },
  { port: "Eu chego a tempo.", rus: "Я прибываю вовремя." },
  { port: "Tu chegas no mesmo dia.", rus: "Tu chegas no mesmo dia." },
  {
    port: "Há um mês que eu não te vejo!",
    rus: "Я не видел тебя целый месяц!",
  },
  {
    port: "O aniversário dela foi há uma semana.",
    rus: "Ее день рождения был неделю назад.",
  },
  { port: "Há três horas que eu estou à espera.", rus: "Я ждал три часа." },
  { port: "O bebé nasceu há um dia.", rus: "Ребенок родился день назад." },
  {
    port: "Nós fomos ao supermercado há um quarto de hora.",
    rus: "Мы ходили в супермаркет четверть часа назад.",
  },
  {
    port: "Eles começaram a caminhar há meio dia.",
    rus: "Они начали поход полдня назад.",
  },
  {
    port: "Há muito tempo que nós vivemos aqui.",
    rus: "Мы живем здесь уже давно.",
  },
  {
    port: "Houve muitos aplausos antes, durante e depois da atuação.",
    rus: "Было много аплодисментов до, во время и после выступления.",
  },
  {
    port: "A encomenda vai ser entregue daqui a dois dias.",
    rus: "Заказ будет доставлен в течение двух дней.",
  },
  {
    port: "Eu vou buscar-te daqui a meia hora.",
    rus: "Я заберу тебя через полчаса.",
  },
  { port: "O verão começa daqui a um mês.", rus: "Лето начнётся через месяц." },
  {
    port: "Há um feriado daqui a uma semana.",
    rus: "Через неделю будет праздник.",
  },
  {
    port: "Vai chover daqui a alguns minutos.",
    rus: "Через несколько минут пойдет дождь.",
  },
  {
    port: "O vencedor vai ser anunciado daqui a pouco.",
    rus: "Победитель будет объявлен в ближайшее время.",
  },
  {
    port: "O autocarro vai chegar daqui a doze minutos.",
    rus: "Автобус прибудет через двенадцать минут.",
  },
  {
    port: "Reformámo-nos no ano passado.",
    rus: "Мы вышли на пенсию в прошлом году.",
  },
  {
    port: "A noite passada, consegui dormir bem.",
    rus: "Прошлой ночью мне удалось хорошо выспаться.",
  },
  {
    port: "Eles vão casar-se no próximo ano.",
    rus: "Они поженятся в следующем году.",
  },
  {
    port: "Ela teve uma festa na semana passada.",
    rus: "Ela teve uma festa na semana passada.",
  },
  {
    port: "A casa vai ficar disponível no próximo mês.",
    rus: "Дом будет сдан в следующем месяце.",
  },
  {
    port: "Eu não conheço nenhum dos meus avós.",
    rus: "Я не знаю никого из своих бабушек и дедушек.",
  },
  { port: "Toda a equipa tem medo dela.", rus: "Вся команда ее боится." },
  {
    port: "Ele melhora todos os dias.",
    rus: "Он совершенствуется с каждым днем.",
  },
  {
    port: "Nenhuma destas camisolas lhe agrada.",
    rus: "Ни одна из этих рубашек тебе не подходит.",
  },
  {
    port: "Ele não tem quaisquer dificuldades.",
    rus: "У него нет никаких трудностей.",
  },
  {
    port: "Cada criança recebe um balão.",
    rus: "Каждый ребенок получает воздушный шарик.",
  },
  {
    port: "Vocês podem convidar qualquer pessoa.",
    rus: "Вы можете пригласить кого угодно.",
  },
  {
    port: "Temos estas taças em ambas as cores.",
    rus: "У нас есть эти чашки в обоих цветах.",
  },
  { port: "Eu não vi ninguém.", rus: "Я никого не видел." },
  {
    port: "Nenhum amigo vai à festa.",
    rus: "Никто из друзей не пойдет на вечеринку.",
  },
  { port: "Ninguém come bolo.", rus: "Никто не ест торт." },
  { port: "Ele não comeu nada.", rus: "Он ничего не ел." },
  {
    port: "Eles não falam com ninguém.",
    rus: "Они ни с кем не разговаривают.",
  },
  { port: "Nenhum deles se queixou.", rus: "Никто из них не жаловался." },
  { port: "Não recebi nenhuma prenda.", rus: "Я не получил никаких подарков." },
  { port: "Ela não fez nada.", rus: "Она ничего не сделала." },
  {
    port: "Nós temos de fazer alguma coisa.",
    rus: "Мы должны что-то сделать.",
  },
  {
    port: "Alguns colegas de trabalho são amigos.",
    rus: "Некоторые коллеги являются друзьями.",
  },
  { port: "Há poucas pessoas na rua.", rus: "На улице мало людей." },
  {
    port: "Ela faz muitas coisas de uma vez.",
    rus: "Она делает много дел одновременно.",
  },
  {
    port: "Tu não precisas de outro gato.",
    rus: "Тебе не нужен еще один кот.",
  },
  {
    port: "Há tantos turistas na cidade.",
    rus: "В городе так много туристов.",
  },
  {
    port: "Ela tem bastantes motivos para faltar ao trabalho.",
    rus: "У нее много причин пропускать работу.",
  },
  { port: "Eu tenho várias ideias.", rus: "У меня есть несколько идей." },
  { port: "Quanto custa o casaco?", rus: "Сколько стоит пальто?" },
  { port: "De quanta madeira precisam?.", rus: "Сколько древесины им нужно?" },
  { port: "Tens quantos irmãos?", rus: "Сколько у тебя братьев?" },
  {
    port: "Quantas folgas tens este mês?",
    rus: "Сколько выходных у вас в этом месяце?",
  },
  {
    port: "Por quanto vendes esses sapatos?",
    rus: "За сколько вы продаете эти туфли?",
  },
  {
    port: "Mozart usa tantas notas quantas existem.",
    rus: "Моцарт использует столько нот, сколько есть.",
  },
  {
    port: "Elas preparam tantos cartazes quantos são necessários.",
    rus: "Они готовят столько плакатов, сколько необходимо.",
  },
  {
    port: "Tens tanto trabalho quanto conseguires fazer!",
    rus: "У вас столько работы, сколько вы можете выполнить!",
  },
  {
    port: "Ele usou quanta lã tinha para fazer este gorro.",
    rus: "Он использовал столько шерсти, сколько у него было, чтобы сделать эту шляпу.",
  },
  {
    port: "Tudo quanto ela diz é uma mentira.",
    rus: "Все, что она говорит, это ложь.",
  },
  {
    port: "Tudo quanto podemos fazer é continuar a tentar.",
    rus: "Все, что мы можем сделать, это продолжать пытаться.",
  },
  {
    port: "Isto é tudo quanto tenho a dizer.",
    rus: "Это все, что я должен сказать.",
  },
  {
    port: "Todos quantos vieram à festa chegaram tarde.",
    rus: "Все, кто пришел на вечеринку, опоздали.",
  },
  {
    port: "Tudo quanto eu quero é ser feliz.",
    rus: "Все, что я хочу, это быть счастливым.",
  },
  { port: "Tu moras nesta rua?", rus: "Ты живешь на этой улице?" },
  { port: "Ele passeia na rua.", rus: "Он ходит по улице." },
  {
    port: "O carro está na bomba de gasolina.",
    rus: "Автомобиль стоит на бензоколонке",
  },
  { port: "A praia tem areia.", rus: "На пляже есть песок." },
  { port: "O homem passeia no jardim.", rus: "Мужчина гуляет в саду." },
  {
    port: "A família passa o dia na praia.",
    rus: "Семья проводит день на пляже.",
  },
  { port: "A criança brinca no parque.", rus: "Ребенок играет в парке." },
  {
    port: "O teu jardim tem muitas flores.",
    rus: "В вашем саду много цветов.",
  },
  { port: "Eu moro nesta praça.", rus: "Я живу на этой площади." },
  { port: "Tu trabalhas no aeroporto.", rus: "Вы работаете в аэропорту." },
  { port: "A rainha vive no castelo.", rus: "Королева живет в замке." },
  { port: "Hoje há uma festa na praça.", rus: "Сегодня на площади вечеринка." },
  { port: "Ela casa na igreja.", rus: "Она выходит замуж в церкви." },
  {
    port: "Ela vai ao supermercado todos os dias.",
    rus: "Она ходит в супермаркет каждый день.",
  },
  {
    port: "O meu filho trabalha neste banco.",
    rus: "Мой сын работает в этом банке.",
  },
  { port: "O dinheiro está no banco.", rus: "O dinheiro está no banco." },
  {
    port: "Eu compro um envelope no posto dos correios.",
    rus: "Я покупаю конверт на почте.",
  },
  {
    port: "Vou fazer compras ao mercado.",
    rus: "Я собираюсь пойти за покупками на рынок.",
  },
  {
    port: "Nós temos de ir ao hospital agora.",
    rus: "Нам сейчас нужно в больницу.",
  },
  {
    port: "Vocês treinam no ginásio.",
    rus: "Вы тренируетесь в тренажерном зале?",
  },
  {
    port: "As piscinas fecharam há cinco minutos.",
    rus: "Бассейны закрылись пять минут назад.",
  },
  {
    port: "É difícil subir estas escadas.",
    rus: "Трудно подняться по этой лестнице.",
  },
  { port: "É bom ter tempo livre.", rus: "Хорошо иметь свободное время." },
  { port: "É importante caminhar.", rus: "Важно гулять." },
  {
    port: "É divertido brincar com os meus netos.",
    rus: "Весело играть с моими внуками.",
  },
  {
    port: "Esta é a receita mais fácil de seguir.",
    rus: "Это самый простой рецепт.",
  },
  { port: "Tu és fácil de entender.", rus: "Тебя легко понять." },
  { port: "Tenta acordar cedo.", rus: "Попробуй встать пораньше." },
  { port: "Fumar faz mal à saúde.", rus: "Курение вредно для здоровья." },
  {
    port: "A melhor coisa a fazer é aceitar.",
    rus: "Лучшее, что можно сделать, это принять.",
  },
  { port: "Aqui, é proibido gritar.", rus: "Здесь кричать запрещено." },
  { port: "Amar é fácil.", rus: "Любить просто." },
  { port: "Ele não consegue ouvir nada.", rus: "Он ничего не слышит." },
  { port: "Eu preciso de trabalhar.", rus: "мне нужно работать." },
  { port: "Ela aprende a nadar.", rus: "Она учится плавать." },
  {
    port: "A minha mãe prefere ler poesia.",
    rus: "A minha mãe prefere ler poesia.",
  },
  {
    port: "Eles vão passar por aqui e dar-me boleia.",
    rus: "Моя мама предпочитает читать стихи.",
  },
  { port: "Tu precisas de estar ali.", rus: "Тебе нужно быть там." },
  { port: "Não consigo fazer isso.", rus: "я не могу сделать это." },
  {
    port: "Praticar exercício é um excelente passatempo.",
    rus: "Занятия спортом – отличное времяпрепровождение.",
  },
  { port: "Não sei o que dizer.", rus: "я не знаю, что сказать." },
  { port: "Ele costuma sair mais cedo.", rus: "Обычно он уходит рано." },
  {
    port: "Lembro-me de ter falado com o João.",
    rus: "Помнится, как разговаривал с Жоао.",
  },
  {
    port: "Gosto imenso de jogar futebol.",
    rus: "Мне очень нравится играть в футбол.",
  },
  {
    port: "Trabalhar demasiado é prejudicial à saúde.",
    rus: "Слишком много работать вредно для здоровья.",
  },
  { port: "Vou precisar de ajuda.", rus: "Мне нужна помощь." },
  {
    port: "Ouvir música pode ser relaxante.",
    rus: "Прослушивание музыки может быть расслабляющим.",
  },
  {
    port: "Vai pagar com multibanco?",
    rus: "Вы собираетесь платить через банкомат?",
  },
  {
    port: "Quer número de contribuinte?",
    rus: "Вам нужен номер налогоплательщика?",
  },
  { port: "Como é que vai pagar?", rus: "Как вы собираетесь платить?" },
  { port: "Vou pagar em dinheiro.", rus: "Я заплачу наличными." },
  { port: "Vou pagar com cartão.", rus: "Я заплачу картой." },
  { port: "Tenho aqui dinheiro certo.", rus: "У меня есть без сдачи." },
  { port: "Não vejo o desconto no talão.", rus: "Я не вижу скидки в чеке." },
  {
    port: "Acho que se enganou no troco.",
    rus: "Я думаю, что вы ошиблись в сдаче.",
  },
  {
    port: "Até quando dura esta promoção?",
    rus: "Как долго длится эта акция?",
  },
  {
    port: "Em que corredor estão as bolachas?",
    rus: "В каком ряду лежит печенье?",
  },
  {
    port: "Queria 100 gramas de fiambre.",
    rus: "Я хотел бы 100 граммов ветчины.",
  },
  {
    port: "Posso vir cá depois trocar?",
    rus: "Могу ли я прийти сюда позже, чтобы обменять?",
  },
  {
    port: "Tenho de pesar a fruta ou pesam na caixa.",
    rus: "Нужно ли мне взвешивать фрукты или они взвешивают их на кассе?",
  },
  { port: "Queria abrir uma conta.", rus: "Я хотел бы открыть счет." },
  {
    port: "Quero pedir um empréstimo.",
    rus: "Я хочу подать заявку на кредит.",
  },
  { port: "Quero fazer um depósito.", rus: "Я хочу внести депозит." },
  {
    port: "Quero fazer um plano poupança-reforma.",
    rus: "Я хочу составить план пенсионных накоплений.",
  },
  {
    port: "O multibanco ficou-me com o cartão.",
    rus: "Банкомат забрал мою карту.",
  },
  {
    port: "Não consigo aceder à minha conta.",
    rus: "Я не могу получить доступ к своей учетной записи.",
  },
  {
    port: "Quero cancelar um débito direto.",
    rus: "Я хочу отменить прямой дебет.",
  },
  { port: "O meu cartão expirou.", rus: "Срок действия моей карты истек." },
  {
    port: "Queríamos abrir uma conta conjunta.",
    rus: "Мы хотели открыть совместный счет.",
  },
  {
    port: "Como posso ver o meu extrato bancário?",
    rus: "Как я могу увидеть выписку по своему банковскому счету?",
  },
  { port: "Quais são as taxas de juro?", rus: "Каковы процентные ставки?." },
  {
    port: "O meu cartão de débito foi recusado.",
    rus: "Моя дебетовая карта была отклонена.",
  },
  {
    port: "Vamos enviar-lhe um cartão novo.",
    rus: "Мы вышлем вам новую карту.",
  },
  {
    port: "Eu preciso de um empréstimo bancário.",
    rus: "Мне нужен банковский кредит.",
  },
  {
    port: "Vai receber uma carta com o seu cartão.",
    rus: "Вы получите письмо с вашей картой.",
  },
  { port: "Quem é o titular da conta?", rus: "Кто является владельцем счета?" },
  {
    port: "A sua conta está a descoberto.",
    rus: "На вашем счету превышен лимит средств.",
  },
  { port: "Qual é a taxa de câmbio?.", rus: "Каков обменный курс?" },
  {
    port: "Não reconheço esta transferência.",
    rus: "Я не узнаю этот трансфер.",
  },
  {
    port: "Não consigo aceder ao serviço de homebanking.",
    rus: "Я не могу получить доступ к услуге домашнего банкинга.",
  },
  { port: "Qual é o número da conta?", rus: "Какой номер счета?." },
  {
    port: "Podemos simular um empréstimo?",
    rus: "Можем ли мы смоделировать кредит?",
  },
  {
    port: "Conta de serviços mínimos bancários.",
    rus: "Счет с минимумом  банковских услуг.",
  },
  {
    port: "Tem de tirar uma senha primeiro.",
    rus: "Сначала вам нужно получить пароль.",
  },
  {
    port: "Pode-me trocar isto por euros?",
    rus: "Вы можете обменять это на евро?",
  },
  { port: "Quero fechar a minha conta.", rus: "Я хочу закрыть свой счет." },
  {
    port: "Despesas de manutenção de conta.",
    rus: "Расходы на обслуживание счета.",
  },
  {
    port: "Fiz um pedido de crédito à habitação.",
    rus: "Я подал заявку на ипотечный кредит.",
  },
  { port: "Tive que hipotecar a casa.", rus: "Мне пришлось заложить дом." },
  { port: "Uma formiga é pequena.", rus: "Муравей маленький." },
  { port: "Que casa grande!", rus: "Какой большой дом!" },
  { port: "Os hipopótamos são grandes.", rus: "Бегемоты большие." },
  { port: "Um bebé é pequeno.", rus: "Ребенок маленький." },
  { port: "Eu quero uma pizza grande.", rus: "Я хочу большую пиццу." },
  {
    port: "As bicicletas são pequenas, mas um camião é grande.",
    rus: "Велосипеды маленькие, а грузовик большой.",
  },
  {
    port: "Ela gosta de vestidos curtos.",
    rus: "Ей нравятся короткие платья.",
  },
  { port: "No verão, os dias são mais compridos.", rus: "Летом дни длиннее." },
  { port: "A modelo tem pernas longas.", rus: "У модели длинные ноги." },
  { port: "O computador é lento.", rus: "Компьютер работает медленно." },
  { port: "Estes carros são rápidos.", rus: "Эти машины быстрые." },
  { port: "Elas vão ser rápidas a chegar.", rus: "Они быстро прибудут." },
  { port: "Tu és rápido.", rus: "Ты быстрый." },
  {
    port: "Esta velocidade da internet está muito lenta.",
    rus: "Эта скорость интернета очень медленная.",
  },
  { port: "O colchão é mole.", rus: "Матрас мягкий." },
  { port: "As esponjas são moles.", rus: "Губки мягкие." },
  { port: "A pele do pêssego é suave.", rus: "Кожа персика гладкая." },
  { port: "O meu trabalho é duro.", rus: "Моя работа тяжелая." },
  { port: "O cão está gordo.", rus: "Собака толстая." },
  { port: "O homem é magro.", rus: "Мужчина худой." },
  { port: "O teu cabelo é fraco.", rus: "У тебя слабые волосы." },
  { port: "Tu és pobre.", rus: "Ты бедный." },
  { port: "Ele é rico?", rus: "Он богатый?" },
  {
    port: "Ele vai ser rico, nós vamos ser pobres.",
    rus: "Он будет богатым, мы будем бедными.",
  },
  { port: "A bicicleta é barata.", rus: "Велосипед дешевый." },
  { port: "As bebidas são grátis.", rus: "Напитки бесплатны." },
  { port: "As refeições foram baratas.", rus: "Еда была дешевой." },
  { port: "A refeição é cara.", rus: "Еда дорогая." },
  { port: "Os bilhetes vão ser caros.", rus: "Билеты будут дорогими." },
  { port: "Tu és aborrecido.", rus: "Ты скучный." },
  { port: "Tu és incrível.", rus: "Ты великолепен." },
  { port: "Eles são humanos.", rus: "Они люди." },
  { port: "Ela é simpática.", rus: "Она милая." },
  { port: "Ele é um excelente marido.", rus: "Он отличный муж." },
  { port: "Ele é divertido.", rus: "Он веселый." },
  { port: "Ele está zangado.", rus: "Он злится." },
  { port: "O meu pai ficou desapontado.", rus: "Мой отец был разочарован." },
  { port: "Estou preocupado contigo.", rus: "Я беспокоюсь о тебе." },
  {
    port: "Acho que ela está deprimida.",
    rus: "Я думаю, что она в депрессии.",
  },
  { port: "Estás muito nervosa.", rus: "Ты очень нервничаешь." },
  {
    port: "A mãe está orgulhosa do seu filho.",
    rus: "Мать гордится своим сыном.",
  },
  { port: "Pareces estar mais calmo.", rus: "Ты выглядишь спокойнее." },
  {
    port: "Eles estão otimistas em relação ao futuro.",
    rus: "Они с оптимизмом смотрят в будущее.",
  },
  {
    port: "Ficámos surpreendidos com a situação.",
    rus: "Мы были удивлены ситуацией.",
  },
  {
    port: "Estou muito satisfeito com o resultado.",
    rus: "Я очень довольна результатом.",
  },
  { port: "O bebé está no berço.", rus: "Ребенок в кроватке." },
  { port: "Há um elefante na sala.", rus: "В комнате слон." },
  { port: "Eles estão no hospital.", rus: "Они в больнице." },
  {
    port: "Os brinquedos estão na caixa.",
    rus: "Игрушки находятся в коробке.",
  },
  { port: "Os livros estão na prateleira.", rus: "Книги лежат на полке." },
  { port: "Há neve no telhado.", rus: "На крыше лежит снег." },
  { port: "O jantar está na mesa.", rus: "Ужин на столе." },
  {
    port: "A árvore está no centro do jardim.",
    rus: "Дерево находится в центре сада.",
  },
  {
    port: "A Lua está muito distante da Terra.",
    rus: "Луна находится очень далеко от Земли.",
  },
  {
    port: "O farol fica perto da praia.",
    rus: "Маяк находится недалеко от пляжа.",
  },
  {
    port: "O aeroporto está próximo do centro da cidade.",
    rus: "Аэропорт находится недалеко от центра города.",
  },
  {
    port: "É difícil subir estas escadas.",
    rus: "Трудно подняться по этой лестнице.",
  },
  {
    port: "A ilha fica longe do continente.",
    rus: "Остров находится далеко от материка.",
  },
  {
    port: "A paragem de autocarro é ao lado da minha casa.",
    rus: "Автобусная остановка рядом с моим домом.",
  },
  { port: "Tu tens um mapa à tua frente.", rus: "Перед вами карта." },
  { port: "Ele está atrás da porta.", rus: "Он за дверью." },
  {
    port: "O carro está a andar para trás.",
    rus: "O carro está a andar para trás.",
  },
  { port: "Agora vivo mais perto de ti.", rus: "Теперь я живу ближе к тебе." },
  {
    port: "O GPS diz-me para ir em frente.",
    rus: "GPS говорит мне идти вперед.",
  },
  { port: "Tu sentaste-te ao meu lado.", rus: "Ты сидел рядом со мной." },
  {
    port: "O destino é mais longe do que parece.",
    rus: "Пункт назначения дальше, чем кажется.",
  },
  { port: "Eu vou virar à esquerda.", rus: "Я поверну налево." },
  { port: "A criança apontou para cima.", rus: "Ребенок указал вверх." },
  { port: "A seta está a apontar para baixo.", rus: "Стрелка указывает вниз." },
  { port: "Olha para a tua direita.", rus: "Посмотрите направо." },
  { port: "O cão está debaixo da mesa.", rus: "Собака под столом." },
  { port: "Há nuvens sobre a cidade.", rus: "Над городом облака." },
  { port: "O gato está fora do saco.", rus: "Кот вылез из мешка." },
  { port: "Elas estão dentro do edifício.", rus: "Они внутри здания." },
  {
    port: "Nós sentámo-nos debaixo de uma árvore.",
    rus: "Мы сидели под деревом.",
  },
  {
    port: "A temperatura caiu abaixo dos 0ºC.",
    rus: "Температура упала ниже 0°C.",
  },
  {
    port: "Nós escalámos cinco quilómetros acima do nível do mar.",
    rus: "Мы поднялись на пять километров над уровнем моря.",
  },
  { port: "Eu estou fora da cidade.", rus: "Меня нет в городе." },
  { port: "A casa está no fundo da rua.", rus: "Дом находится в конце улицы." },
  { port: "O pássaro está dentro da gaiola.", rus: "Птица внутри клетки." },
  { port: "Ela deu um passo atrás.", rus: "Она сделала шаг назад." },
  { port: "Fica em que nome?", rus: "Под каким именем?" },
  {
    port: "Tenho uma reserva em nome de Pedro Soares.",
    rus: "У меня есть бронирование на имя Педро Соареса.",
  },
  {
    port: "Queria reservar uma mesa para 6 pessoas.",
    rus: "Я хотел бы зарезервировать столик на 6 человек.",
  },
  { port: "Estão abertos?", rus: "Они открыты?" },
  { port: "Qual é o tempo de espera?", rus: "Каково время ожидания?" },
  { port: "Posso ver a ementa?", rus: "Могу ли я увидеть меню?" },
  { port: "Já podemos pedir.", rus: "Теперь мы можем спросить." },
  { port: "Já decidiram?", rus: "Вы уже решили?" },
  { port: "Ainda estamos a decidir.", rus: "Мы все еще решаем." },
  { port: "Era um copo de água, por favor.", rus: "Стакан воды, пожалуйста." },
  { port: "Tem carta de vinhos?", rus: "У вас есть карта вин?" },
  { port: "Tem água com gás?", rus: "У вас есть газированная вода?" },
  { port: "Que pratos recomenda?", rus: "Какие блюда посоветуете?" },
  {
    port: "Este prato dá para duas pessoas?",
    rus: "Этого блюда хватит на двоих?",
  },
  { port: "Tem pratos vegetarianos?", rus: "У вас есть вегетарианские блюда?" },
  { port: "Quais são os pratos do dia?", rus: "Какие блюда дня?" },
  { port: "Nós vamos partilhar um prato.", rus: "Мы поделимся блюдом." },
  { port: "Vai querer mais alguma coisa?", rus: "Вы хотите что-нибудь еще?" },
  {
    port: "Pode-me arranjar outro guardanapo?",
    rus: "Можешь принести мне еще одну салфетку?",
  },
  {
    port: "Era outra cerveja, se faz favor.",
    rus: "Еще одно пиво, пожалуйста.",
  },
  {
    port: "A salada é para ele, o peixe é para mim.",
    rus: "Салат для него, рыба для меня.",
  },
  { port: "Como é que está tudo?", rus: "Как все было?" },
  { port: "A carne está mal passada.", rus: "Мясо плохо прожарено." },
  { port: "Encontrei um cabelo na comida.", rus: "Я нашел волос в еде." },
  { port: "Está delicioso, obrigada.", rus: "Это вкусно, спасибо." },
  { port: "Tem adoçante?", rus: "У вас есть подсластитель?" },
  { port: "A comida estava excelente.", rus: "Еда была превосходной." },
  { port: "Queria um café, por favor.", rus: "Я хотел бы кофе, пожалуйста." },
  {
    port: "Sim, pode levantar os pratos.",
    rus: "Да, вы можете поднять посуду.",
  },
  { port: "A conta não está certa.", rus: "Счет не правильный." },
  {
    port: "A fatura é com número de contribuinte.",
    rus: "В счете-фактуре указан номер налогоплательщика.",
  },
  { port: "Aceita cartão de crédito?", rus: "Вы принимаете кредитную карту?" },
  { port: "Era a conta, se faz favor.", rus: "Счет, пожалуйста." },
  { port: "Boa comida nunca é demais.", rus: "Вкусной еды много не бывает." },
  { port: "Ele tem pouco tempo.", rus: "У него мало времени." },
  { port: "Eu gosto muito de ti.", rus: "Ты мне очень нравишься." },
  { port: "Eu sou muito paciente.", rus: "Я очень терпеливый." },
  { port: "Eu tenho dinheiro suficiente.", rus: "У меня достаточно денег." },
  { port: "Comemos bastante ao almoço.", rus: "Мы много ели на обед." },
  { port: "Ele quer mais cereais.", rus: "Он хочет больше хлопьев." },
  { port: "Eu gosto tanto de férias.", rus: "Мне так нравится отпуск." },
  {
    port: "A empresa tem menos recursos este ano.",
    rus: "В этом году у компании меньше ресурсов.",
  },
  {
    port: "As respostas estão quase todas corretas.",
    rus: "Ответы почти все правильные.",
  },
  { port: "O bebé é tão lindo.", rus: "Ребенок такой красивый." },
  { port: "O João está quase a chegar.", rus: "Жоау почти прбыл." },
  { port: "Agora não é uma boa altura.", rus: "Сейчас не лучшее время." },
  { port: "Enfim, estamos sós!.", rus: "Наконец мы одни." },
  { port: "O espetáculo está quase a começar.", rus: "Шоу вот-вот начнется." },
  { port: "Ela canta sempre no banho.", rus: "Она всегда поет в ванне." },
  {
    port: "Entretanto, vamos ver televisão.",
    rus: "А пока давай посмотрим телевизор.",
  },
  { port: "Eles ainda estão a caminho.", rus: "Они все еще в пути." },
  { port: "Sai já da minha casa.", rus: "Убирайся из моего дома сейчас же." },
  {
    port: "Lisboa já foi uma cidade calma.",
    rus: "Лиссабон когда-то был спокойным городом.",
  },
  { port: "Os convidados já estão cá.", rus: "Гости уже здесь." },
  { port: "Nós já estamos no museu.", rus: "Мы уже в музее." },
  { port: "Já chega!.", rus: "Достаточно!" },
  { port: "Eu já volto.", rus: "Я скоро вернусь." },
  { port: "Eu levo isso já.", rus: "Я возьму это сейчас." },
  { port: "Eu já sei da novidade.", rus: "Я уже знаю новости." },
  { port: "Ele não se sente bem.", rus: "Он плохо себя чувствует." },
  { port: "O tempo está pior.", rus: "Погода ухудшилась." },
  { port: "Ela sente-se melhor.", rus: "Она чувствует себя лучше." },
  { port: "Eu conduzo mal.", rus: "Я плохо вожу." },
  { port: "Eu passo através da porta.", rus: "Я вхожу в дверь." },
  { port: "Tu lês devagar.", rus: "Ты читаешь медленно." },
  { port: "Faz isso assim.", rus: "Делай это так." },
  { port: "O atleta corre depressa.", rus: "Спортсмен бежит быстро." },
  {
    port: "Eu gosto sobretudo de pescar.",
    rus: "Мне особенно нравится рыбалка.",
  },
  { port: "Eu não gosto do café assim.", rus: "Я не люблю такой кофе." },
  {
    port: "O conceito da exposição é bastante interessante.",
    rus: "Концепция выставки довольно интересная.",
  },
  { port: "Realmente, vai chover.", rus: "Действительно будет дождь." },
  { port: "É quase de noite.", rus: "Уже почти ночь." },
  { port: "Eu vi bastantes coisas.", rus: "Я видел много вещей." },
  { port: "O cão já comeu o bastante.", rus: "Собака съела достаточно." },
  { port: "Ele é realmente simpático.", rus: "Он действительно хороший." },
  { port: "O Manuel é quase careca.", rus: "Мануэль почти лысый." },
  { port: "A criança aprende facilmente.", rus: "Ребенок легко учится." },
  { port: "Geralmente, eu prefiro peixe.", rus: "Вообще я предпочитаю рыбу." },
  {
    port: "Os dois ciclistas são igualmente bons.",
    rus: "Оба велосипедиста одинаково хороши.",
  },
  { port: "Ele é completamente louco.", rus: "Он совершенно сумасшедший." },
  { port: "Ela come praticamente tudo.", rus: "Она ест практически всё." },
  {
    port: "O chefe despediu os empregados friamente.",
    rus: "Начальник холодно уволил сотрудников.",
  },
  {
    port: "O avô geralmente dorme cedo.",
    rus: "Дедушка обычно ложится спать рано.",
  },
  { port: "Eu não a vi ultimamente.", rus: "Я не видел ее в последнее время." },
  { port: "O verão finalmente chegou.", rus: "Лето наконец наступило." },
  { port: "Vem cá imediatamente.", rus: "Иди сюда немедленно." },
  { port: "O comediante fala comicamente.", rus: "Комик говорит комично." },
  { port: "O leão rugiu agressivamente.", rus: "Лев агрессивно зарычал." },
  {
    port: "O candidato apresenta-se educadamente.",
    rus: "Кандидат вежливо представляется.",
  },
  { port: "Ela tem olhos verdes.", rus: "У нее зеленые глаза." },
  { port: "Eu cheiro pelas narinas.", rus: "Я чувствую запах через ноздри." },
  {
    port: "O meu irmão tem frio nas orelhas.",
    rus: "У моего брата холодные уши.",
  },
  { port: "O homem tem um nariz grande.", rus: "У мужчины большой нос." },
  { port: "Eu uso brincos nas orelhas.", rus: "Я ношу серьги в ушах." },
  { port: "A menina tem bochechas rosadas.", rus: "У девушки румяные щеки." },
  { port: "O bebé não tem dentes.", rus: "У ребенка нет зубов." },
  { port: "Tu tens uma língua afiada.", rus: "У тебя острый язык." },
  { port: "Os teus dentes são brancos.", rus: "Твои зубы белые." },
  { port: "Eles deram um beijo na boca.", rus: "Они поцеловались в губы." },
  { port: "A avó pinta os lábios.", rus: "Бабушка красит губы." },
];
