import styles from "./preswrite.module.css";
import { verb_n } from "../../utils/verbo";
import WriterVerb from "../writerverb/writerverb";
import AppHeader from "../app-header/app-header";

function PresWrite() {
  return (
    <div className={styles.main}>
      <AppHeader name={"Пиши глаголы"}></AppHeader>
      <h1 className={styles.title}>Presente do indicativo</h1>
      <WriterVerb list={verb_n}></WriterVerb>
    </div>
  );
}
export default PresWrite;
