import styles from "./link-login.module.css";
import { Link } from "react-router-dom";

type TLinLoginprops = {
  qwest: string;
  to: string;
  text: string;
};

function LinLogin(props: TLinLoginprops) {
  return (
    <Link to={props.to} className={styles.button}>
      <p>{`${props.qwest}  ${props.text} `}</p>
    </Link>
  );
}

export default LinLogin;
