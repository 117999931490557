import styles from "./glag-present.module.css";
import { useState } from "react";
import { AppHeader } from "../app-header/app-header";
import { fraz_1 } from "../../utils/frazpres";
import { shuffle } from "../../utils/convert";
import Menu from "../menu/menu";
import SentensNew from "../sentensnew/sentensnew";

function GlagFraz() {
  let list = fraz_1.slice(0);
  const [trans, setTrans] = useState("Показывать перевод");
  const [verb, setVerb] = useState("Показывать глагол");

  const translist = ["Показывать перевод", "Не показывать перевод"];
  const glaglist = ["Показывать глагол", "Не показывать глагол"];

  list = fraz_1.slice(0);
  shuffle(list);

  return (
    <div className={styles.main}>
      <AppHeader name={"Напиши глаголы"}></AppHeader>
      <section className={styles.team}>
        <div className={`${styles.menu} ${styles.menu_2}`}>
          <Menu list={translist} setlist={setTrans} name={trans}></Menu>
        </div>
        <div className={`${styles.menu} ${styles.menu_1}`}>
          <Menu list={glaglist} setlist={setVerb} name={verb}></Menu>
        </div>
      </section>
      <div className={styles.text_t}>
        <SentensNew slovo={list} pp={trans} pv={verb}></SentensNew>
      </div>
    </div>
  );
}
export default GlagFraz;
