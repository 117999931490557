import { createSlice, PayloadAction} from "@reduxjs/toolkit";

    type Tscore = {
    ball: number,
    ok:boolean,
    start:boolean,
    otdel:string,
  }



export const initialState: Tscore = {
    ball: 0,
    ok:true,
    start:true,
    otdel:"",
  };

   
  export const scoreSlice = createSlice({
    name: "scoore",
    initialState: initialState,
    reducers: {
      addball: (state, action:PayloadAction<Tscore>) => {
        state.ball = state.ball + action.payload.ball;
        state.ok = true;
        state.start = false;
        state.otdel = action.payload.otdel;

      },
      dellball: (state,action:PayloadAction<Tscore>) => {
        state.ball = 0;
        state.ok = true;
        state.start = true;
        state.otdel = action.payload.otdel;
      }  
  }});

  export default scoreSlice.reducer;
  export const { addball, dellball} = scoreSlice.actions;
   