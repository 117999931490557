import { useState } from "react";
import AppHeader from "../../components/app-header/app-header";
import Cards from "../../components/cards/cards";
import { bone } from "../../utils/bone";
import { shuffle } from "../../utils/convert";
import styles from "./palavrab.module.css";

function Palavrab() {
  const list = shuffle(bone);
  const [base, setbase] = useState(true);
  const [basetext, setbasetext] = useState("русский");

  const handleClick = () => {
    if (basetext === "русский") {
      setbasetext("португальский");
      setbase(false);
    } else {
      setbasetext("русский");
      setbase(true);
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <AppHeader name={"Учим слова B1"}></AppHeader>
      </div>
      <Cards list={bone}></Cards>
    </div>
  );
}

export default Palavrab;
