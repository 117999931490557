import { useState } from "react";
import AppHeader from "../../components/app-header/app-header";
import Cards from "../../components/cards/cards";
import { aone } from "../../utils/aone";
import { shuffle } from "../../utils/convert";
import styles from "./menupage.module.css";
import Start from "../../components/start/start";
import { menumain, textinfo } from "../../utils/structura";
import { motion } from "framer-motion";
import MenuMain from "../../components/menumain/menumain";
import { text_1, text_2 } from "../../utils/more";
import Card from "../../components/card/card";

function MenuPage() {
  const list = shuffle(aone);
  const [info, setinfo] = useState(true);
  const [base, setbase] = useState(true);
  const [basetext, setbasetext] = useState("русский");

  const pVariants = {
    hidden: {
      x: 1500,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <AppHeader name={"Что можно делать"}></AppHeader>
      </div>
      <div className={styles.block}>
        <section className={styles.grup}>
          {menumain.map((item, index) => {
            return (
              <motion.div
                initial={"hidden"}
                animate={"visible"}
                transition={{
                  delay: index * 0.4,
                  duration: 1,
                }}
                variants={pVariants}
                key={index}
              >
                <Card
                  name={item.name}
                  avatar={item.avatar}
                  content={item.content}
                ></Card>
              </motion.div>
            );
          })}
        </section>
      </div>
    </div>
  );
}

export default MenuPage;
/*<MenuMain
title={item.name}
children={item.content}
active={setinfo}
></MenuMain>*/
