import { useState } from "react";
import AppHeader from "../../components/app-header/app-header";
import Cards from "../../components/cards/cards";
import { aone } from "../../utils/aone";
import { shuffle } from "../../utils/convert";
import styles from "./palavra.module.css";

function Palavra() {
  const list = shuffle(aone);
  const [base, setbase] = useState(true);
  const [basetext, setbasetext] = useState("русский");

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <AppHeader name={"Учим слова А2"}></AppHeader>
      </div>
      <Cards list={aone}></Cards>
    </div>
  );
}

export default Palavra;

/*<p className={styles.text}>Основной язык:</p>

<button className={styles.button} onClick={handleClick}>
  {basetext}
</button>*/
