import styles from "./cardsverb.module.css";
import { AnimatePresence, wrap, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Speak from "../speak/speak";

type Tcards = {
  list: {
    port: string;
    rus: string;
    link: string;
  }[];
};

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const Cardsverb = (props: Tcards) => {
  const listcard1 = props.list;
  const [[page, direction], setPage] = useState([0, 0]);
  const [active, setactive] = useState(true);
  const [listcard, setlist] = useState<
    {
      port: string;
      rus: string;
      link: string;
    }[]
  >([
    {
      port: "ir",
      rus: "направляться",
      link: "https://www.conjugacao.com.br/verbo-ir/",
    },
  ]);
  const [foundv, setfound] = useState(false);
  const [name, setname] = useState("");
  const [verb, setverb] = useState<any>();
  const [ok, setok] = useState(false);
  const [start_1, setstart_1] = useState(true);
  const [start_2, setstart_2] = useState(false);
  const [start_3, setstart_3] = useState(false);
  const [start_4, setstart_4] = useState(false);
  const [start_5, setstart_5] = useState(false);
  const [sait, setsait] = useState(false);

  useEffect(() => {
    let list: {
      port: string;
      rus: string;
      link: string;
    }[] = [
      {
        port: "ir",
        rus: "направляться",
        link: "https://www.conjugacao.com.br/verbo-ir/",
      },
    ];
    let mass1: {
      port: string;
      rus: string;
      link: string;
    }[] = [];
    let mass2: {
      port: string;
      rus: string;
      link: string;
    }[] = [];
    let mass3: {
      port: string;
      rus: string;
      link: string;
    }[] = [];
    let mass4: {
      port: string;
      rus: string;
      link: string;
    }[] = [];
    let mass5: {
      port: string;
      rus: string;
      link: string;
    }[] = [];

    if (start_1) {
      mass1 = listcard1.filter((word, index) => index >= 0 && index < 101);
    }
    if (start_2) {
      mass2 = listcard1.filter((word, index) => index > 100 && index < 201);
    }
    if (start_3) {
      mass3 = listcard1.filter((word, index) => index > 200 && index < 301);
    }
    if (start_4) {
      mass4 = listcard1.filter((word, index) => index > 300 && index < 401);
    }
    if (start_5) {
      mass5 = listcard1.filter((word, index) => index > 400 && index < 500);
    }

    const listfin = list.concat(mass1, mass2, mass3, mass4, mass5);

    setlist(listfin);
  }, [start_2, start_3, start_1, start_4, start_5]);

  useEffect(() => {
    if (sait === true) {
      document.addEventListener("click", hendlerClick);
    }
    if (sait === false) {
      document.removeEventListener("click", hendlerClick);
    }
  }, [sait]);

  const hendlerClick = (evt: any) => {
    if (!evt.target.className.includes("frame")) {
      setsait(false);
    }
  };

  const imageIndex = wrap(0, listcard.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const handleClick = () => {
    setfound(true);
    setverb("");

    let glag = listcard1.filter((word) => word.port === name.toLowerCase());

    if (glag.length === 0) {
      glag = listcard1.filter((word) => word.rus === name.toLowerCase());
      if (glag.length === 0) {
        setok(false);
      } else {
        setverb(glag[0]);
        setok(true);
      }
    } else {
      setverb(glag[0]);
      setok(true);
    }
  };
  const changeName = (e: any) => {
    setname(e.target.value);
    setfound(true);
  };

  return (
    <div className={styles.main}>
      <div className={styles.res}>
        <input
          className={styles.input}
          type="text"
          value={name}
          autoComplete="off"
          name="name"
          onChange={changeName}
          placeholder="amar.. любить.. ter"
        ></input>
        <button className={styles.button} onClick={handleClick}>
          Найти
        </button>
      </div>
      <section className={styles.imgblock}>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            className={styles.image}
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
              setfound(false);
            }}
            onClick={() => {
              setactive(!active);
            }}
          >
            {active && !foundv && (
              <div className={styles.box}>
                <h1 className={styles.texttyping}>
                  {listcard[imageIndex].rus}
                </h1>
              </div>
            )}
            {!active && !foundv && (
              <div className={styles.box}>
                <h1 className={styles.texttyping}>
                  {listcard[imageIndex].port}
                </h1>
              </div>
            )}
            {active && foundv && ok && (
              <div className={styles.box}>
                <h1 className={styles.texttyping}>{verb.rus}</h1>
                <div></div>
              </div>
            )}
            {!active && foundv && ok && (
              <div className={styles.box}>
                <h1>{verb.port}</h1>
              </div>
            )}
            {foundv && !ok && (
              <div className={styles.box}>
                <h1 className={styles.texter}>
                  Возможно тут нет такого глагола или попробуйте написать иначе
                </h1>
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </section>
      <div className={styles.deck}>
        {!foundv && (
          <a
            href={listcard[imageIndex].link}
            className={styles.link}
            target="_blank"
          >
            {`Посмотреть все спряжения глагола (браз.) (ведет на другой сайт)--->`}
          </a>
        )}
        {foundv && ok && (
          <a href={verb.link} className={styles.link} target="_blank">
            {`Посмотреть все спряжения глагола (браз.) (ведет на другой сайт)--->`}
          </a>
        )}
        <button onClick={() => setsait(true)} className={styles.frameb}>
          Посмотреть спряжения тут
        </button>
        {foundv && ok && <Speak text={verb.port}></Speak>}
        {!foundv && <Speak text={listcard[imageIndex].port}></Speak>}
      </div>
      <div className={styles.res}>
        <button
          className={!start_1 ? styles.button2 : styles.button3}
          onClick={() => setstart_1(!start_1)}
        >
          Первые 100
        </button>
        <button
          className={!start_2 ? styles.button2 : styles.button3}
          onClick={() => setstart_2(!start_2)}
        >
          100-200
        </button>
        <button
          className={!start_3 ? styles.button2 : styles.button3}
          onClick={() => setstart_3(!start_3)}
        >
          200-300
        </button>
        <button
          className={!start_4 ? styles.button2 : styles.button3}
          onClick={() => setstart_4(!start_4)}
        >
          300-400
        </button>
        <button
          className={!start_5 ? styles.button2 : styles.button3}
          onClick={() => setstart_5(!start_5)}
        >
          400-500
        </button>
      </div>
      {sait && !foundv && (
        <div className={styles.frame}>
          <iframe
            className={styles.iframe}
            src={`https://european-portuguese.info/pt/conjugator/${listcard[imageIndex].port}`}
          ></iframe>
        </div>
      )}
      {sait && foundv && ok && (
        <div className={styles.frame}>
          <iframe
            className={styles.iframe}
            src={`https://european-portuguese.info/pt/conjugator/${verb.port}`}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Cardsverb;
