export const fraz_3 = [
  {
    p_1: "Quando era pequena, ",
    p_2: "brincava ",
    p_3: "sempre com bonecas.",
    p_4: "brincar",
    p_5: "Когда я была маленькой, я всегда играла в куклы.",
  },
  {
    p_1: "Antigamente",
    p_2: "viviam ",
    p_3: "no campo.",
    p_4: "viver",
    p_5: "Раньше они жили в сельской местности.",
  },
  {
    p_1: "Quando eles ",
    p_2: "eram ",
    p_3: "crianças viviam  fora da cidade.",
    p_4: "ser",
    p_5: "Когда они были детьми, они жили за пределами города.",
  },
  {
    p_1: "Quando eles eram crianças ",
    p_2: "viviam  ",
    p_3: "fora da cicade.",
    p_4: "viver",
    p_5: "Когда они были детьми, они жили за пределами города.",
  },
  {
    p_1: "Quando eles eram crianças ",
    p_2: "levantaram-se",
    p_3: "muito cedo para ir para a escola",
    p_4: "levantar-se",
    p_5: "Когда они были детьми, они вставали очень рано, чтобы пойти в школу.",
  },
  {
    p_1: "Na escola",
    p_2: "tinham",
    p_3: "aulas das 8h00 até às 13h00.",
    p_4: "ter",
    p_5: "В школе занятия были с 8 утра до 13 часов.",
  },
  {
    p_1: "Marta e Paul",
    p_2: "saiam",
    p_3: "de casa às 7h00 da manhã.",
    p_4: "sair",
    p_5: "Марта и Пол выходили из дома в 7:00 утра.",
  },
  {
    p_1: "Marta e Paul",
    p_2: "iam",
    p_3: "de autocarro até à cidade.",
    p_4: "ir",
    p_5: "Марта и Пол ездили на автобусе в город.",
  },
  {
    p_1: "Marta e Paul",
    p_2: "voltavam ",
    p_3: "para casa  normalmente às 13h00.",
    p_4: "voltar",
    p_5: "Марта и Пол возвращались домой обычно в 13:00.",
  },
  {
    p_1: "Marta e Paul",
    p_2: "almoçavam",
    p_3: "sempre às 14h00.",
    p_4: "almoçar",
    p_5: "Марта и Пол всегда обедали в 14:00.",
  },
  {
    p_1: "Depois   da escola  eles",
    p_2: "iam",
    p_3: "fazer os trabalos de casa  às 14h30.",
    p_4: "it",
    p_5: "После школы они делали домашнее задание в 14:30.",
  },
  {
    p_1: "Antigamente, à noite",
    p_2: "jantavam",
    p_3: "cedo.",
    p_4: "jantar",
    p_5: "Раньше ужинали рано вечером.",
  },
  {
    p_1: "Antigamente",
    p_2: "moravam",
    p_3: "numa vivenda.",
    p_4: "morar",
    p_5: "Раньше они жили в доме.",
  },
  {
    p_1: "Depois da escola ",
    p_2: "faziam",
    p_3: "sempre os trabalos de casa.",
    p_4: "fazer",
    p_5: "После школы они всегда делали домашнее задание.",
  },
  {
    p_1: "Ele ",
    p_2: "costumava ",
    p_3: "ir a pé para o trabalho, agora vai de carro.",
    p_4: "costumar",
    p_5: "Раньше он ходил на работу пешком, теперь ездит на машине.",
  },
  {
    p_1: "Eu",
    p_2: "costumava ",
    p_3: "usar óculos, agora uso lentes de contacto.",
    p_4: "costumar",
    p_5: "Раньше я носил очки, теперь ношу контактные линзы.",
  },
  {
    p_1: "Quando ",
    p_2: "morava ",
    p_3: "costumava a andar de carro.",
    p_4: "morar",
    p_5: "Когда я жил, я путешествовал на машине.",
  },
  {
    p_1: "Quando morava na cidade,",
    p_2: "costumava ",
    p_3: "a andar de carro.",
    p_4: "costumar",
    p_5: "Когда я жил в городе, я путешествовал на машине.",
  },
  {
    p_1: "Eu",
    p_2: "tinha ",
    p_3: "4 anos quando fui ao cinema pela primeira vez.",
    p_4: "ter",
    p_5: "Мне было 4 года, когда я впервые пошел в кино.",
  },
  {
    p_1: "Ontem de manhã, enquanto a Ana ",
    p_2: "tomava ",
    p_3: "duche, a irmã fazia as camas.",
    p_4: "tomar",
    p_5: "Вчера утром, пока Ана принимала душ, ее сестра заправляла кровати.",
  },
  {
    p_1: "Ontem de manhã, enquanto a Ana tomava duche, a irmã ",
    p_2: "fazia ",
    p_3: "as camas.",
    p_4: "fazer",
    p_5: "Вчера утром, пока Ана принимала душ, ее сестра заправляла кровати.",
  },
  {
    p_1: "A mãe",
    p_2: "arrumava",
    p_3: " a roupa.",
    p_4: "arrumar",
    p_5: "Мама убирала одежду",
  },
  {
    p_1: "A Inês",
    p_2: "estava ",
    p_3: "a dormir.",
    p_4: "estar",
    p_5: "Инес спала.",
  },
  {
    p_1: "O Sr. Pinto ",
    p_2: "estava ",
    p_3: "a pintar a sala. ",
    p_4: "estar",
    p_5: "Мистер Пинто красил комнату.",
  },
  {
    p_1: "Eles ",
    p_2: "estavam ",
    p_3: "a jogar no jardim.",
    p_4: "estar",
    p_5: "Они играли в саду.",
  },
  {
    p_1: "Eu ",
    p_2: "estava ",
    p_3: "a ouvir música, quando chefe chegou.",
    p_4: "estar",
    p_5: "Я слушал музыку, когда пришел босс.",
  },
  {
    p_1: "Os alunos ",
    p_2: "estavam ",
    p_3: "a trabalhar quando o professor entrou.",
    p_4: "estar",
    p_5: "Студенты работали, когда вошел учитель.",
  },
  {
    p_1: "Nós ",
    p_2: "estávamos",
    p_3: " a trabalhar quando o computador se avariou.",
    p_4: "estar",
    p_5: "Мы работали, когда сломался компьютер.",
  },
  {
    p_1: "Enquanto os filhos ",
    p_2: "tomavam",
    p_3: " duche, a mãe arrumava os quartos. ",
    p_4: "tomar",
    p_5: "Пока дети принимали душ, мама наводила порядок в комнатах.",
  },
  {
    p_1: "Enquanto eu ",
    p_2: "via",
    p_3: " televisão, ele lia o jornal.",
    p_4: "ver",
    p_5: "Пока я смотрел телевизор, он читал газету.",
  },
  {
    p_1: "Enquanto eu via televisão, ele ",
    p_2: "lia ",
    p_3: "o jornal.",
    p_4: "ler",
    p_5: "Пока я смотрел телевизор, он читал газету.",
  },
  {
    p_1: "Enquanto os filhos tomavam duche, a mãe ",
    p_2: "arrumava",
    p_3: " os quartos. ",
    p_4: "arrumar",
    p_5: "Пока дети принимали душ, мама наводила порядок в комнатах.",
  },
  {
    p_1: "Enquanto eles ",
    p_2: "preparavam ",
    p_3: "as bebidas, nós púnhamos a mesa.",
    p_4: "preparar",
    p_5: "Пока они готовили напитки, мы накрыли стол.",
  },
  {
    p_1: "Enquanto eles preparavam as bebidas, nós ",
    p_2: "púnhamos ",
    p_3: "a mesa.",
    p_4: "por",
    p_5: "Пока они готовили напитки, мы накрыли стол.",
  },
  {
    p_1: "Enquanto ela ",
    p_2: "estava",
    p_3: "ao telefone, tomava notas.",
    p_4: "estar",
    p_5: "Пока она разговаривала по телефону, она делала заметки.",
  },
  {
    p_1: "Enquanto ela estava ao telefone, ",
    p_2: "tomava ",
    p_3: "notas.",
    p_4: "tomar",
    p_5: "Пока она разговаривала по телефону, она делала заметки.",
  },
  {
    p_1: "Enquanto a Xana e o Filipe ",
    p_2: "estudavam",
    p_3: ", ouviam música.",
    p_4: "estudar",
    p_5: "Пока Ксана и Филипе учились, они слушали музыку.",
  },
  {
    p_1: "Enquanto a Xana e o Filipe estudavam, ",
    p_2: "ouviam ",
    p_3: "música.",
    p_4: "ouvir",
    p_5: "Пока Ксана и Филипе учились, они слушали музыку.",
  },
  {
    p_1: "Enquanto a orquestra ",
    p_2: "tocava",
    p_3: ", o Sr. Martins dormia.",
    p_4: "tocar",
    p_5: "Пока играл оркестр, мистер Мартинс спал.",
  },
  {
    p_1: "Enquanto a orquestra tocava, o Sr. Martins ",
    p_2: "dormia",
    p_3: ".",
    p_4: "dormir",
    p_5: "Пока играл оркестр, мистер Мартинс спал.",
  },
  {
    p_1: "Enquanto as crianças ",
    p_2: "brincavam",
    p_3: "nós conversávamos.",
    p_4: "brincar",
    p_5: "Пока дети играли, мы разговаривали.",
  },
  {
    p_1: "Enquanto as crianças brincavam, nós conversávamos.",
    p_2: "conversávamos",
    p_3: ".",
    p_4: "conversar",
    p_5: "Пока дети играли, мы разговаривали.",
  },
  {
    p_1: "Enquanto a empregada",
    p_2: "limpava ",
    p_3: "a casa, eu tratava das crianças. ",
    p_4: "limpar",
    p_5: "Пока горничная убирала дом, я присматривал за детьми.",
  },
  {
    p_1: "Enquanto a empregada limpava a casa, eu ",
    p_2: "tratava",
    p_3: " das crianças. ",
    p_4: "tratar",
    p_5: "Пока горничная убирала дом, я присматривал за детьми.",
  },
  {
    p_1: "Enquanto ele ",
    p_2: "falava",
    p_3: ", fazia gestos com as mãos.",
    p_4: "falar",
    p_5: "Говоря это, он жестикулировал руками.",
  },
  {
    p_1: "Enquanto ele falava,",
    p_2: "fazia ",
    p_3: "gestos com as mãos.",
    p_4: "fazer",
    p_5: "Говоря это, он жестикулировал руками.",
  },
  {
    p_1: "Enquanto o Marko",
    p_2: "vestia ",
    p_3: "o pijama, a mãe abria a cama.",
    p_4: "vestir",
    p_5: "Пока Марко надевал пижаму, его мать открыла кровать.",
  },
  {
    p_1: "Enquanto o Marko vestia o pijama, a mãe ",
    p_2: "abria ",
    p_3: "a cama.",
    p_4: "abrir",
    p_5: "Пока Марко надевал пижаму, его мать открыла кровать.",
  },
  {
    p_1: "Enquanto ela ",
    p_2: "estudava",
    p_3: ", enviava sms aos amigos.",
    p_4: "estudar",
    p_5: "Пока она училась, она отправляла текстовые сообщения своим друзьям.",
  },
  {
    p_1: "Enquanto ela estudava, ",
    p_2: "enviava ",
    p_3: "sms aos amigos.",
    p_4: "enviar",
    p_5: "Пока она училась, она отправляла текстовые сообщения своим друзьям.",
  },
  {
    p_1: "Enquanto eles ",
    p_2: "jogavam ",
    p_3: "Playstation, elas ouviam música. ",
    p_4: "jogar",
    p_5: "Пока они играли в Playstation, они слушали музыку.",
  },
  {
    p_1: "Enquanto eles jogavam Playstation, elas ",
    p_2: "ouviam",
    p_3: "música. ",
    p_4: "ouvir",
    p_5: "Пока они играли в Playstation, они слушали музыку.",
  },
  {
    p_1: "Enquanto eu me ",
    p_2: "dirigia ",
    p_3: "para a porta, o cão ladrava.",
    p_4: "dirigir",
    p_5: "Когда я подошел к двери,  собака лаяла.",
  },
  {
    p_1: "Eu ",
    p_2: "costumava",
    p_3: " trabalhar num escritório, agora trabalho num banco.",
    p_4: "costumar",
    p_5: "Раньше я работал в офисе, сейчас работаю в банке.",
  },
  {
    p_1: "Aos domingos",
    p_2: "costumavam ",
    p_3: "ficar em casa, agora vão ao cinema",
    p_4: "costumar",
    p_5: "По воскресеньям раньше оставались дома, теперь ходят в кино.",
  },
  {
    p_1: "Nós ",
    p_2: "costumávamos",
    p_3: "ter férias em julho, agora temos férias em agosto.",
    p_4: "costumar",
    p_5: "Раньше у нас были каникулы в июле, теперь каникулы в августе.",
  },
  {
    p_1: "O Pedro ",
    p_2: "costumava ",
    p_3: "ser muito gordo, agora é magro.",
    p_4: "costumar",
    p_5: "Педро раньше был очень толстым, теперь он похудел.",
  },
  {
    p_1: "A Ana ",
    p_2: "costumava ",
    p_3: "estudar pouco, agora estuda muito.",
    p_4: "costumar",
    p_5: "Раньше Ана мало училась, теперь она учится много.",
  },
  {
    p_1: "O Sr. Machado ",
    p_2: "costumava ",
    p_3: "chegar atrasado, agora chega a horas.",
    p_4: "costumar",
    p_5: "Раньше господин Мачадо приходил поздно, теперь он приходит вовремя.",
  },
  {
    p_1: "Eu ",
    p_2: "costumava ",
    p_3: "praticar desporto, agora não faço nada.",
    p_4: "costumar",
    p_5: "Раньше я занимался спортом, сейчас ничем не занимаюсь.",
  },
  {
    p_1: "Aos sábados ",
    p_2: "costumava",
    p_3: " ir à praça, agora vai ao supermercado.",
    p_4: "costumar",
    p_5: "По субботам он ходил на площадь, теперь ходит в супермаркет.",
  },
  {
    p_1: "As crianças ",
    p_2: "costumavam ",
    p_3: "brincar em casa, agora brincam no jardim.",
    p_4: "costumar",
    p_5: "Раньше дети играли дома, теперь они играют в саду.",
  },
  {
    p_1: "O João ",
    p_2: "costumava ",
    p_3: "viver com os pais; agora vive sozinho.",
    p_4: "costumar",
    p_5: "Жоау раньше жил со своими родителями; теперь он живет один.",
  },
  {
    p_1: "Antigamente não ",
    p_2: "havia",
    p_3: "aviões.",
    p_4: "havir",
    p_5: "Раньше самолетов не было.",
  },
  {
    p_1: "Antigamente as pessoas ",
    p_2: "costumavam ",
    p_3: "viajar de comboio.",
    p_4: "costumar",
    p_5: "Раньше люди обычно путешествовали на поезде.",
  },
  {
    p_1: "Antigamente não ",
    p_2: "havia ",
    p_3: "carros.",
    p_4: "havir",
    p_5: "Раньше машин не было.",
  },
  {
    p_1: "Antigamente as pessoas ",
    p_2: "costumavam ",
    p_3: "andar mais a pé.",
    p_4: "costumar",
    p_5: "Раньше люди обычно ходили пешком.",
  },
  {
    p_1: "Antigamente não ",
    p_2: "havia ",
    p_3: "telefones.",
    p_4: "havir",
    p_5: "Раньше телефонов не было.",
  },
  {
    p_1: "Antigamente as pessoas ",
    p_2: "costumavam ",
    p_3: "escrever cartas.",
    p_4: "costumar",
    p_5: "Раньше люди писали письма.",
  },
  {
    p_1: "Antigamente não ",
    p_2: "havia ",
    p_3: "televisão.",
    p_4: "havir",
    p_5: "Раньше не было телевидения.",
  },
  {
    p_1: "Antigamente as pessoas ",
    p_2: "costumavam ",
    p_3: "conversar mais. ",
    p_4: "costumar",
    p_5: "Раньше люди больше говорили.",
  },
  {
    p_1: "Antigamente não ",
    p_2: "havia ",
    p_3: "cinema.",
    p_4: "havir",
    p_5: "Раньше кинотеатров не было.",
  },
  {
    p_1: "Antigamente as pessoas ",
    p_2: "costumavam ",
    p_3: "ir ao teatro.",
    p_4: "costumar",
    p_5: "Раньше люди ходили в театр.",
  },
  {
    p_1: "A mãe ",
    p_2: "costumava ",
    p_3: "fazer compras na mercearia local. ",
    p_4: "costumar",
    p_5: "Мама покупала продукты в местном продуктовом магазине.",
  },
  {
    p_1: "A tarde ",
    p_2: "costumavam ",
    p_3: "dar passeios de bicicleta. ",
    p_4: "costumar",
    p_5: "Днем они ходили кататься на велосипедах.",
  },
  {
    p_1: "As crianças ",
    p_2: "costumavam",
    p_3: "brincar na rua.",
    p_4: "costumar",
    p_5: "Дети играли на улице.",
  },
  {
    p_1: "Aos domingos",
    p_2: "costumavam ",
    p_3: "fazer um piquenique.",
    p_4: "costumar",
    p_5: "По воскресеньям они устраивали пикник.",
  },
];
