import { motion } from "framer-motion";
import styles from "./app-header.module.css";
import dog from "../../images/logo.png";
import { NavLink } from "react-router-dom";
import MenuR from "../menur/menur";
import { useState } from "react";

type TListMenu = {
  title: string;
  link: string;
};

type Tprops = {
  name: string;
};

export function AppHeader(props: Tprops) {
  return (
    <div className={styles.main}>
      <div className={`${styles.menul} ${styles.menu}`}>
        <NavLink className={styles.preview} to={"/"}>
          <motion.img
            src={dog}
            alt="logo"
            animate={{ rotate: 360 }}
            transition={{
              duration: 2,
              repeat: 1,
              repeatDelay: 1,
              repeatType: "mirror",
              type: "tween",
            }}
          />
        </NavLink>
        <h1 className={styles.tit}>{props.name}</h1>
      </div>
      <div className={styles.menu}>
        <MenuR></MenuR>
      </div>
    </div>
  );
}
export default AppHeader;
