import styles from "./slovo-w.module.css";
import { TSlova } from "../list-teams/list-teams";
import { useState } from "react";
import { convert, convertpt } from "../../utils/convert";
import dog from "../../images/smile.png";

type TOrderypeprops = {
  list: TSlova;
};

function SlovoW(props: TOrderypeprops) {
  const slovo = props.list;

  const [word, setWord] = useState("");
  const [wordn, setWordn] = useState("");
  const [ok, setok] = useState(false);
  const [team, setTeam] = useState(slovo.ru);
  const [okfim, setokfim] = useState(false);
  const [wordw, setWordw] = useState("пишите");
  const [wordd, setWordd] = useState("");

  const buttonHandler = (slovo: TSlova) => {
    if (slovo.ru === team) {
      setTeam(slovo.port);
    } else {
      if (slovo.port === team) {
        setTeam(slovo.ru);
      } else {
        setTeam(slovo.port);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
    }
  };

  const setValueWord = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const slovow = evt.target.value.toLowerCase();
    const slovop = convert(slovo.port);
    const slovopt = convertpt(slovo.port);
    slovop.trim();

    if (
      slovop.slice(0, slovow.length) === slovow ||
      slovopt.slice(0, slovow.length) === slovow
    ) {
      setok(true);
      setWord(slovow);
      setokfim(false);
    } else {
      setok(false);
      setokfim(false);
      if (word !== "") {
        setWordn(`было правильно до ${word}`);
      } else {
        setWordn(`начинается с ${slovop[0]}`);
      }
    }
    if (slovop === slovow || slovopt === slovow) {
      setokfim(true);
      setTeam(slovo.port);
      setWordw(slovow);
      setWordd(slovop);

      evt.target.setAttribute("disabled", "disabled");
    }
  };
  if (slovo.ru === "") {
    return null;
  }

  if (slovo.ru === team || slovo.port === team) {
    return (
      <div className={styles.list}>
        <button
          className={` ${styles.buttonbase} ${styles.button} `}
          onClick={() => buttonHandler(slovo)}
        >
          {team}
        </button>

        <input
          className={` ${styles.buttonbase} ${styles.button} `}
          type="text"
          placeholder={wordw}
          onInput={setValueWord}
          onKeyPress={handleKeyPress}
        ></input>
        {ok === true && (
          <div className={`  ${styles.buttonok} ${styles.button} `}>
            {word}
            {okfim === true && (
              <div className={styles.preview}>
                <img src={dog} alt="dog" />
              </div>
            )}
          </div>
        )}

        {ok === false && (
          <div className={` ${styles.buttonno} ${styles.button}`}>{wordn} </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.list}>
        <button
          className={` ${styles.buttonbase} ${styles.button} `}
          onClick={() => buttonHandler(slovo)}
        >
          {slovo.ru}
        </button>
        <input
          className={` ${styles.buttonbase} ${styles.button} `}
          type="text"
          placeholder="пишите"
          onInput={setValueWord}
          onKeyPress={handleKeyPress}
        ></input>
        {ok === true && (
          <div className={` ${styles.buttonok} ${styles.button} `}>
            {word}
            {okfim === true && (
              <div className={styles.preview}>
                <img src={dog} alt="dog" />
              </div>
            )}
          </div>
        )}

        {ok === false && (
          <div className={`${styles.buttonno} ${styles.button}`}>{wordn}</div>
        )}
      </div>
    );
  }
}

export default SlovoW;
