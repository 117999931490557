import styles from "./verb.module.css";
import { useEffect, useState } from "react";
import { verb_im, verb_Im } from "../../utils/verboimp";
import { Tverb } from "../../utils/verbo";
import { AppHeader } from "../app-header/app-header";
import { motion } from "framer-motion";

type TOrderypeprops = {
  list: Tverb[];
  listP: Tverb[];
};

function typeverb(verb: Tverb) {
  let vrem = 5;
  if (verb.spr === "1") {
    vrem = 0;
  }
  if (verb.spr === "2") {
    vrem = 1;
  }
  if (verb.spr === "3") {
    vrem = 2;
  }
  if (verb.spr === "4") {
    vrem = 3;
  }
  return vrem;
}

function Verb(props: TOrderypeprops) {
  const listVerb = props.list;
  const listPrav = props.listP;
  const [ss, setvss] = useState("...");
  const [set, setset] = useState("все глаголы");

  const [isset, setisset] = useState(false);
  const verbotype = [
    "все глаголы",
    "только ...ar",
    "только ...er",
    "только ...ir",
    "неправильные",
  ];
  const verbo_1: Tverb[] = [];

  listVerb.map((item) => {
    if (set === "все глаголы") {
      verbo_1.push(item);
    }
    if (set === "только ...ar") {
      if (item.spr === "1") {
        verbo_1.push(item);
      }
    }
    if (set === "только ...er") {
      if (item.spr === "2") {
        verbo_1.push(item);
      }
    }
    if (set === "только ...ir") {
      if (item.spr === "3") {
        verbo_1.push(item);
      }
    }
    if (set === "неправильные") {
      if (item.spr === "4") {
        verbo_1.push(item);
      }
    }
  });

  const [team_1, setTeam_1] = useState(verbo_1[0]);
  const [istactive_1, setlistactive_1] = useState(false);
  const [setP, setsetP] = useState(listPrav[typeverb(verbo_1[0])]);

  let index_1 = 0;

  const buttonHandler_3 = () => {
    let r_1 = verbo_1.indexOf(team_1) + 1;
    if (verbo_1.indexOf(team_1) === verbo_1.length - 1) {
      r_1 = 0;
    }

    setTeam_1(verbo_1[r_1]);
    setsetP(listPrav[typeverb(verbo_1[r_1])]);
    const input = document.querySelectorAll("input");

    input.forEach((userItem) => {
      userItem.value = "";
      userItem.removeAttribute("disabled");
    });
  };

  useEffect(() => {
    if (istactive_1 === true) {
      document.addEventListener("click", hendlerClick);
    }
    if (istactive_1 === false) {
      document.removeEventListener("click", hendlerClick);
    }
  }, [istactive_1]);

  const hendlerClick = (evt: any) => {
    if (!evt.target.className.includes("butV")) {
      if (istactive_1 === true) {
        if (evt.target.className) {
          if (!evt.target.className.includes("verbactiv")) {
            setlistactive_1(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isset === true) {
      document.addEventListener("click", hendlerClick_2);
    }
    if (isset === false) {
      document.removeEventListener("click", hendlerClick_2);
    }
  }, [isset]);

  const hendlerClick_2 = (evt: any) => {
    if (!evt.target.className.includes("but_t")) {
      if (isset === true) {
        if (evt.target.className) {
          if (!evt.target.className.includes("verbactiv")) {
            setisset(false);
          }
        }
      }
    }
  };

  const buttonHandlertem_1 = () => {
    setlistactive_1(true);
  };

  const buttonHandler_1 = (name: Tverb) => {
    setTeam_1(name);
    setlistactive_1(false);
    setsetP(listPrav[typeverb(name)]);
  };

  const buttonHandlertem_2 = () => {
    setisset(true);
  };

  const buttonHandler_2 = (name: string) => {
    setset(name);
    setisset(false);
  };

  return (
    <div className={styles.main}>
      <section className={styles.header}>
        <button
          className={` ${styles.but_t} ${styles.shab}  `}
          onClick={() => buttonHandlertem_2()}
        >
          {set}
        </button>
        <button
          className={` ${styles.tl} ${styles.but} ${styles.shab} ${styles.butV}  `}
          onClick={() => buttonHandlertem_1()}
        >
          {`${team_1.port} - ${team_1.ru}`}
        </button>
      </section>

      <section className={styles.grammatica_1}>
        <div className={`${styles.tl} ${styles.shab}`}>eu</div>
        <div className={`${styles.verbo} ${styles.shab}`}>{team_1.eu}</div>
        <div className={`${styles.pr} ${styles.shab}`}>{setP.eu}</div>

        <div className={`${styles.tl} ${styles.shab}`}>tu</div>
        <div className={`${styles.verbo} ${styles.shab}`}>{team_1.tu}</div>
        <div className={`${styles.pr} ${styles.shab}`}>{setP.tu}</div>

        <div className={`${styles.tl} ${styles.shab}`}>ele</div>
        <div className={`${styles.verbo} ${styles.shab}`}>{team_1.ele}</div>
        <div className={`${styles.pr} ${styles.shab}`}>{setP.ele}</div>

        <div className={`${styles.tl} ${styles.shab}`}>nós</div>
        <div className={`${styles.verbo} ${styles.shab}`}>{team_1.nos}</div>
        <div className={`${styles.pr} ${styles.shab}`}>{setP.nos}</div>

        <div className={`${styles.tl} ${styles.shab}`}>eles</div>
        <div className={`${styles.verbo} ${styles.shab}`}>{team_1.eles}</div>
        <div className={`${styles.pr} ${styles.shab}`}>{setP.eles}</div>
      </section>

      {istactive_1 === true && (
        <section
          className={`custom-scroll  ${styles.list} ${styles.verbactiv}`}
        >
          {verbo_1.map((item) => {
            index_1 = index_1 + 1;
            if (item.port === team_1.port) {
              return (
                <button
                  className={styles.buttonactive}
                  onClick={() => buttonHandler_1(item)}
                  key={index_1}
                >
                  {item.port}
                </button>
              );
            } else {
              return (
                <button
                  className={styles.button}
                  onClick={() => buttonHandler_1(item)}
                  key={index_1}
                >
                  {item.port}
                </button>
              );
            }
          })}
        </section>
      )}

      {isset === true && (
        <section className={`${styles.list}  ${styles.listprav}`}>
          {verbotype.map((item) => {
            index_1 = index_1 + 1;
            if (item === set) {
              return (
                <button
                  className={styles.buttonactive}
                  onClick={() => buttonHandler_2(item)}
                  key={index_1}
                >
                  {item}
                </button>
              );
            } else {
              return (
                <button
                  className={styles.button}
                  onClick={() => buttonHandler_2(item)}
                  key={index_1}
                >
                  {item}
                </button>
              );
            }
          })}
        </section>
      )}
      <button className={styles.but_2} onClick={() => buttonHandler_3()}>
        еще...
      </button>
    </div>
  );
}

export default Verb;
