import styles from "./writerverb.module.css";
import { useEffect, useRef, useState } from "react";
import dog from "../../images/smile.png";
import { Tverb } from "../../utils/verbo";
import { convert, convertpt } from "../../utils/convert";

type TOrderypeprops = {
  list: Tverb[];
};

function WriterVerb(props: TOrderypeprops) {
  const verb_im = props.list;

  const [tans, settrans] = useState(false);

  const [ss, setvss] = useState("...");
  const [set, setset] = useState("все глаголы");
  const [isset, setisset] = useState(false);
  const button_2 = useRef<HTMLButtonElement | null>(null);
  const inp_1 = useRef<HTMLInputElement | null>(null);
  const inp_2 = useRef<HTMLInputElement | null>(null);
  const inp_3 = useRef<HTMLInputElement | null>(null);
  const inp_4 = useRef<HTMLInputElement | null>(null);
  const inp_5 = useRef<HTMLInputElement | null>(null);

  const verbo_1: Tverb[] = [];

  verb_im.map((item) => {
    if (set === "все глаголы") {
      verbo_1.push(item);
    }
    if (set === "только ...ar") {
      if (item.spr === "1") {
        verbo_1.push(item);
      }
    }
    if (set === "только ...er") {
      if (item.spr === "2") {
        verbo_1.push(item);
      }
    }
    if (set === "только ...ir") {
      if (item.spr === "3") {
        verbo_1.push(item);
      }
    }
    if (set === "неправильные") {
      if (item.spr === "4") {
        verbo_1.push(item);
      }
    }
  });

  const [team_1, setTeam_1] = useState(verbo_1[0]);
  const [istactive_1, setlistactive_1] = useState(false);

  const verbotype = [
    "все глаголы",
    "только ...ar",
    "только ...er",
    "только ...ir",
    "неправильные",
  ];

  const [verb_1, setverb_1] = useState("пишите");
  const [verb_2, setverb_2] = useState("пишите");
  const [verb_3, setverb_3] = useState("пишите");
  const [verb_4, setverb_4] = useState("пишите");
  const [verb_5, setverb_5] = useState("пишите");

  const [verb_1_d, setverb_1_d] = useState("");
  const [verb_2_d, setverb_2_d] = useState("");
  const [verb_3_d, setverb_3_d] = useState("");
  const [verb_4_d, setverb_4_d] = useState("");
  const [verb_5_d, setverb_5_d] = useState("");

  const [verb_1_n, setverb_1_n] = useState("");
  const [verb_2_n, setverb_2_n] = useState("");
  const [verb_3_n, setverb_3_n] = useState("");
  const [verb_4_n, setverb_4_n] = useState("");
  const [verb_5_n, setverb_5_n] = useState("");

  const [verb_1_ok, setverb_1_ok] = useState(false);
  const [verb_2_ok, setverb_2_ok] = useState(false);
  const [verb_3_ok, setverb_3_ok] = useState(false);
  const [verb_4_ok, setverb_4_ok] = useState(false);
  const [verb_5_ok, setverb_5_ok] = useState(false);

  const [verb_1_okfim, setverb_1_okfim] = useState(false);
  const [verb_2_okfim, setverb_2_okfim] = useState(false);
  const [verb_3_okfim, setverb_3_okfim] = useState(false);
  const [verb_4_okfim, setverb_4_okfim] = useState(false);
  const [verb_5_okfim, setverb_5_okfim] = useState(false);

  let index_1 = 0;
  const buttonHandler_3 = () => {
    let r_1 = 0;
    if (verbo_1.indexOf(team_1) + 1 === verbo_1.length) {
      r_1 = 0;
    } else {
      r_1 = verbo_1.indexOf(team_1) + 1;
    }

    setTeam_1(verbo_1[r_1]);
    setverb_1("пишите");
    setverb_2("пишите");
    setverb_3("пишите");
    setverb_4("пишите");
    setverb_5("пишите");

    setverb_1_d("");
    setverb_2_d("");
    setverb_3_d("");
    setverb_4_d("");
    setverb_5_d("");

    setverb_1_n("");
    setverb_2_n("");
    setverb_3_n("");
    setverb_4_n("");
    setverb_5_n("");

    setverb_1_ok(false);
    setverb_2_ok(false);
    setverb_3_ok(false);
    setverb_4_ok(false);
    setverb_5_ok(false);

    setverb_1_okfim(false);
    setverb_2_okfim(false);
    setverb_3_okfim(false);
    setverb_4_okfim(false);
    setverb_5_okfim(false);

    const input = document.querySelectorAll("input");

    input.forEach((userItem) => {
      userItem.value = "";
      userItem.removeAttribute("disabled");
    });
    if (inp_1.current !== null) {
      inp_1.current.focus();
    }
  };

  const buttonHandlertem_1 = () => {
    setlistactive_1(true);
  };

  const buttonHandlertem_2 = () => {
    setisset(true);
  };

  const buttonHandler_2 = (name: string) => {
    setset(name);
    setisset(false);
  };

  const buttonHandler_1 = (name: Tverb) => {
    const input = document.querySelectorAll("input");

    input.forEach((userItem) => {
      userItem.value = "";
      userItem.removeAttribute("disabled");
    });

    setTeam_1(name);
    setlistactive_1(false);
    if (name.spr === "1") {
      setvss("...ar");
    }

    if (name.spr === "2") {
      setvss("...er");
    }

    if (name.spr === "3") {
      setvss("...ir");
    }

    setverb_1("пишите");
    setverb_2("пишите");
    setverb_3("пишите");
    setverb_4("пишите");
    setverb_5("пишите");

    setverb_1_d("");
    setverb_2_d("");
    setverb_3_d("");
    setverb_4_d("");
    setverb_5_d("");

    setverb_1_n("");
    setverb_2_n("");
    setverb_3_n("");
    setverb_4_n("");
    setverb_5_n("");

    setverb_1_ok(false);
    setverb_2_ok(false);
    setverb_3_ok(false);
    setverb_4_ok(false);
    setverb_5_ok(false);

    setverb_1_okfim(false);
    setverb_2_okfim(false);
    setverb_3_okfim(false);
    setverb_4_okfim(false);
    setverb_5_okfim(false);
    if (inp_1.current !== null) {
      inp_1.current.focus();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
    }
  };

  const setValueWord_1 = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const slovow = evt.target.value.toLowerCase();
    const slovop = convert(team_1.eu);
    const slovopt = convertpt(team_1.eu);
    slovop.trim();

    if (
      slovop.slice(0, slovow.length) === slovow ||
      slovopt.slice(0, slovow.length) === slovow
    ) {
      setverb_1_ok(true);
      setverb_1(slovow);
      setverb_1_okfim(false);
      setverb_1_d(slovow);
    } else {
      setverb_1_ok(false);
      setverb_1_okfim(false);
      if (verb_1 !== "") {
        setverb_1_n(`было правильно до ${verb_1}`);
      } else {
        setverb_1_n(`начинается с ${slovop[0]}`);
      }
    }
    if (slovop === slovow || slovopt === slovow) {
      setverb_1_okfim(true);
      setverb_1_d(slovow);
      setverb_1(slovow);
      evt.target.setAttribute("disabled", "disabled");
      if (inp_2.current !== null) {
        inp_2.current.focus();
      }
    }
  };

  const setValueWord_2 = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const slovow = evt.target.value.toLowerCase();
    const slovop = convert(team_1.tu);
    const slovopt = convertpt(team_1.tu);
    slovop.trim();

    if (
      slovop.slice(0, slovow.length) === slovow ||
      slovopt.slice(0, slovow.length) === slovow
    ) {
      setverb_2_ok(true);
      setverb_2(slovow);
      setverb_2_okfim(false);
      setverb_2_d(slovow);
    } else {
      setverb_2_ok(false);
      setverb_2_okfim(false);
      if (verb_2 !== "") {
        setverb_2_n(`было правильно до ${verb_2}`);
      } else {
        setverb_2_n(`начинается с ${slovop[0]}`);
      }
    }
    if (slovop === slovow || slovopt === slovow) {
      setverb_2_okfim(true);
      setverb_2_d(slovow);
      setverb_2(slovow);
      evt.target.setAttribute("disabled", "disabled");
      if (inp_3.current !== null) {
        inp_3.current.focus();
      }
    }
  };

  const setValueWord_3 = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const slovow = evt.target.value.toLowerCase();
    const slovop = convert(team_1.ele);
    const slovopt = convertpt(team_1.ele);
    slovop.trim();

    if (
      slovop.slice(0, slovow.length) === slovow ||
      slovopt.slice(0, slovow.length) === slovow
    ) {
      setverb_3_ok(true);
      setverb_3(slovow);
      setverb_3_okfim(false);
      setverb_3_d(slovow);
    } else {
      setverb_3_ok(false);
      setverb_3_okfim(false);
      if (verb_3 !== "") {
        setverb_3_n(`было правильно до ${verb_3}`);
      } else {
        setverb_3_n(`начинается с ${slovop[0]}`);
      }
    }
    if (slovop === slovow || slovopt === slovow) {
      setverb_3_okfim(true);
      setverb_3_d(slovow);
      setverb_3(slovow);
      evt.target.setAttribute("disabled", "disabled");
      if (inp_4.current !== null) {
        inp_4.current.focus();
      }
    }
  };

  const setValueWord_4 = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const slovow = evt.target.value.toLowerCase();
    const slovop = convert(team_1.nos);
    const slovopt = convertpt(team_1.nos);
    slovop.trim();

    if (
      slovop.slice(0, slovow.length) === slovow ||
      slovopt.slice(0, slovow.length) === slovow
    ) {
      setverb_4_ok(true);
      setverb_4(slovow);
      setverb_4_okfim(false);
      setverb_4_d(slovow);
    } else {
      setverb_4_ok(false);
      setverb_4_okfim(false);
      if (verb_4 !== "") {
        setverb_4_n(`было правильно до ${verb_4}`);
      } else {
        setverb_4_n(`начинается с ${slovop[0]}`);
      }
    }
    if (slovop === slovow || slovopt === slovow) {
      setverb_4_okfim(true);
      setverb_4_d(slovow);
      setverb_4(slovow);
      evt.target.setAttribute("disabled", "disabled");
      if (inp_5.current !== null) {
        inp_5.current.focus();
      }
    }
  };

  const setValueWord_5 = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const slovow = evt.target.value.toLowerCase();
    const slovop = convert(team_1.eles);
    const slovopt = convertpt(team_1.eles);
    slovop.trim();

    if (
      slovop.slice(0, slovow.length) === slovow ||
      slovopt.slice(0, slovow.length) === slovow
    ) {
      setverb_5_ok(true);
      setverb_5(slovow);
      setverb_5_okfim(false);
      setverb_5_d(slovow);
    } else {
      setverb_5_ok(false);
      setverb_5_okfim(false);
      if (verb_5 !== "") {
        setverb_5_n(`было правильно до ${verb_5}`);
      } else {
        setverb_5_n(`начинается с ${slovop[0]}`);
      }
    }
    if (slovop === slovow || slovopt === slovow) {
      setverb_5_okfim(true);
      setverb_5_d(slovow);
      setverb_5(slovow);
      evt.target.setAttribute("disabled", "disabled");
      if (button_2.current !== null) {
        button_2.current.focus();
      }
    }
  };

  useEffect(() => {
    if (istactive_1 === true) {
      document.addEventListener("click", hendlerClick);
    }
    if (istactive_1 === false) {
      document.removeEventListener("click", hendlerClick);
    }
  }, [istactive_1]);

  const hendlerClick = (evt: any) => {
    if (!evt.target.className.includes("butV")) {
      if (istactive_1 === true) {
        if (evt.target.className) {
          if (!evt.target.className.includes("verbactiv")) {
            setlistactive_1(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isset === true) {
      document.addEventListener("click", hendlerClick_2);
    }
    if (isset === false) {
      document.removeEventListener("click", hendlerClick_2);
    }
  }, [isset]);

  const hendlerClick_2 = (evt: any) => {
    if (!evt.target.className.includes("but_t")) {
      if (isset === true) {
        if (evt.target.className) {
          if (!evt.target.className.includes("verbactiv")) {
            setisset(false);
          }
        }
      }
    }
  };
  return (
    <div className={styles.main}>
      <section className={styles.grammatica_1}>
        <div className={styles.block}>
          <button
            className={`${styles.pr} ${styles.but} ${styles.shab} ${styles.butV}`}
            onClick={() => buttonHandlertem_1()}
          >
            {team_1.port}
          </button>
          <div
            className={`${styles.verbo} ${styles.shab}`}
            onClick={() => settrans(!tans)}
          >
            {tans ? team_1.ru : ""}
          </div>
        </div>
        <button
          className={`${styles.but} ${styles.shab} ${styles.but_t}`}
          onClick={() => buttonHandlertem_2()}
        >
          {set}
        </button>

        <div className={styles.block}>
          <div className={`${styles.tl} ${styles.shab}`}>eu</div>

          <input
            className={`${styles.verbo} ${styles.shab} ${styles.inp} `}
            type="text"
            placeholder={verb_1}
            onInput={setValueWord_1}
            onKeyPress={handleKeyPress}
            ref={inp_1}
          ></input>
        </div>
        {verb_1_ok === true && (
          <div className={`  ${styles.shab} ${styles.pr} `}>
            {verb_1}
            {verb_1_okfim === true && (
              <div className={styles.preview}>
                <img src={dog} alt="dog" />
              </div>
            )}
          </div>
        )}

        {verb_1_ok === false && (
          <div className={` ${styles.shab} ${styles.prn}`}>{verb_1_n} </div>
        )}
        <div className={styles.block}>
          <div className={`${styles.tl} ${styles.shab}`}>tu</div>
          <input
            className={`${styles.verbo} ${styles.shab} ${styles.inp}`}
            type="text"
            placeholder={verb_2}
            onInput={setValueWord_2}
            onKeyPress={handleKeyPress}
            ref={inp_2}
          ></input>
        </div>
        {verb_2_ok === true && (
          <div className={`  ${styles.shab} ${styles.pr} `}>
            {verb_2}
            {verb_2_okfim === true && (
              <div className={styles.preview}>
                <img src={dog} alt="dog" />
              </div>
            )}
          </div>
        )}

        {verb_2_ok === false && (
          <div className={` ${styles.shab} ${styles.prn}`}>{verb_2_n} </div>
        )}

        <div className={styles.block}>
          <div className={`${styles.tl} ${styles.shab}`}>ele</div>
          <input
            className={`${styles.verbo} ${styles.shab} ${styles.inp}`}
            type="text"
            placeholder={verb_3}
            onInput={setValueWord_3}
            onKeyPress={handleKeyPress}
            ref={inp_3}
          ></input>
        </div>
        {verb_3_ok === true && (
          <div className={`  ${styles.shab} ${styles.pr} `}>
            {verb_3}
            {verb_3_okfim === true && (
              <div className={styles.preview}>
                <img src={dog} alt="dog" />
              </div>
            )}
          </div>
        )}

        {verb_3_ok === false && (
          <div className={` ${styles.shab} ${styles.prn}`}>{verb_3_n} </div>
        )}
        <div className={styles.block}>
          <div className={`${styles.tl} ${styles.shab}`}>nós</div>
          <input
            className={`${styles.verbo} ${styles.shab} ${styles.inp}`}
            type="text"
            placeholder={"пишите"}
            onInput={setValueWord_4}
            onKeyPress={handleKeyPress}
            ref={inp_4}
          ></input>
        </div>
        {verb_4_ok === true && (
          <div className={`  ${styles.shab} ${styles.pr} `}>
            {verb_4}
            {verb_4_okfim === true && (
              <div className={styles.preview}>
                <img src={dog} alt="dog" />
              </div>
            )}
          </div>
        )}

        {verb_4_ok === false && (
          <div className={` ${styles.shab} ${styles.prn}`}>{verb_4_n} </div>
        )}
        <div className={styles.block}>
          <div className={`${styles.tl} ${styles.shab}`}>eles</div>
          <input
            className={`${styles.verbo} ${styles.shab} ${styles.inp}`}
            type="text"
            placeholder={"пишите"}
            onInput={setValueWord_5}
            onKeyPress={handleKeyPress}
            ref={inp_5}
          ></input>
        </div>
        {verb_5_ok === true && (
          <div className={`  ${styles.shab} ${styles.pr} `}>
            {verb_5}
            {verb_5_okfim === true && (
              <div className={styles.preview}>
                <img src={dog} alt="dog" />
              </div>
            )}
          </div>
        )}

        {verb_5_ok === false && (
          <div className={` ${styles.shab} ${styles.prn}`}>{verb_5_n} </div>
        )}
      </section>

      {istactive_1 === true && (
        <section className={`custom-scroll ${styles.list} ${styles.verbactiv}`}>
          {verbo_1.map((item) => {
            index_1 = index_1 + 1;
            if (item.port === team_1.port) {
              return (
                <button
                  className={styles.buttonactive}
                  onClick={() => buttonHandler_1(item)}
                  key={index_1}
                >
                  {item.port}
                </button>
              );
            } else {
              return (
                <button
                  className={styles.button}
                  onClick={() => buttonHandler_1(item)}
                  key={index_1}
                >
                  {item.port}
                </button>
              );
            }
          })}
        </section>
      )}

      {isset === true && (
        <section className={`${styles.list} ${styles.verbactiv}`}>
          {verbotype.map((item) => {
            index_1 = index_1 + 1;
            if (item === set) {
              return (
                <button
                  className={styles.buttonactive}
                  onClick={() => buttonHandler_2(item)}
                  key={index_1}
                >
                  {item}
                </button>
              );
            } else {
              return (
                <button
                  className={styles.button}
                  onClick={() => buttonHandler_2(item)}
                  key={index_1}
                >
                  {item}
                </button>
              );
            }
          })}
        </section>
      )}
      <button
        className={styles.but_2}
        onClick={() => buttonHandler_3()}
        ref={button_2}
      >
        еще...
      </button>
    </div>
  );
}

export default WriterVerb;
