import React, { useState } from "react";
import LinLogin from "../../components/link-login/link-login";
import styles from "./login.module.css";
import { useAppDispatch } from "../../types/hook";
import dog from "../../images/dog_2.jpg";
import { AppHeader } from "../../components/app-header/app-header";
import { setuser } from "../../store/user";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [texterror, setTexterror] = useState("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state;
  let priv = "Вход";
  if (fromPage === 123) {
    priv =
      "Некоторые функции доступны только для зарегистрированных пользователей. Это совершенно бесплатно";
  }

  const setValueEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
  };

  const setValuePassWord = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(evt.target.value);
  };

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        //console.log(user.email);
        if (user.email) {
          //console.log(user);
          dispatch(
            setuser({
              email: user.email,
              tocken: user.refreshToken,
              id: user.uid,
              auth: false,
              name: "",
            })
          );
        }
        navigate("/profile");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        //console.log(errorMessage);
        if (errorMessage.includes("Error (auth/invalid-credential)")) {
          setTexterror("Возможно не правильный пароль или пользователь");
          //console.log(error.message);
        }
      });
  };

  return (
    <div className={styles.login}>
      <div className={styles.header}>
        <AppHeader name={"Вход"}></AppHeader>
      </div>
      <div className={styles.preview_2}>
        <img src={dog} className={styles.preview} alt="dog" />
      </div>
      <section className={styles.main}>
        <form className={styles.form} onSubmit={onSubmit}>
          <p className={styles.text}>{priv}</p>
          <input
            type="email"
            placeholder="e-mail"
            onChange={setValueEmail}
            className={` ${styles.buttonbase} ${styles.button} `}
            value={email}
          />
          <input
            type="password"
            placeholder="Пароль"
            onChange={setValuePassWord}
            className={` ${styles.buttonbase} ${styles.button} `}
            value={password}
          />
          <button
            type="submit"
            className={` ${styles.buttons} ${styles.button} `}
          >
            Войти
          </button>
          <p className={styles.text}>{texterror}</p>
          <LinLogin
            to="/register"
            qwest="Новый пользователь?  "
            text=" Зарегистрироваться"
          ></LinLogin>
          <LinLogin
            to="/forgotpassword"
            qwest="Забыли пароль?  "
            text=" Восстановить пароль"
          ></LinLogin>
        </form>
      </section>
    </div>
  );
}

export default Login;
