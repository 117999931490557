import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../types/hook";
import { getuser } from "../store/action-selector";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setuser } from "../store/user";
import { useEffect } from "react";
import { getreg } from "../api/api";
type Ty = {
  children: JSX.Element;
};

const RequireAuthc = (props: Ty) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  console.log("66666666666");
  console.log(location);

  let users = useAppSelector(getuser);

  if (users.auth) {
    //if (user.email === "") {
    if (location.state !== "")
      return <Navigate to={location.state} state={123}></Navigate>;
    //}
  }

  return props.children;
};

export default RequireAuthc;
