import React, { useState } from "react";
import LinLogin from "../../components/link-login/link-login";
import styles from "./registr.module.css";
import { useAppDispatch } from "../../types/hook";
import dog from "../../images/dog_2.jpg";
import { AppHeader } from "../../components/app-header/app-header";
import { setuser } from "../../store/user";
import { useLocation, useNavigate } from "react-router-dom";

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [texterror, setTexterror] = useState("");
  const navigate = useNavigate();

  const user = {
    email: "",
    id: "",
    tocken: "",
    auth: false,
  };
  const location = useLocation();

  const dispatch = useAppDispatch();

  const setValueEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
  };

  const setValuePassWord = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(evt.target.value);
  };

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    setTexterror("");
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        if (user.email) {
          //console.log(user);
          dispatch(
            setuser({
              email: user.email,
              tocken: user.refreshToken,
              id: user.uid,
              auth: false,
              name: "",
            })
          );
        }
        navigate("/profile");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // console.log(error.message);

        if (errorMessage.includes("Password should be at least 6 characters")) {
          setTexterror("Пожалуйста, задайте пароль не менее 6 знаков");
          //console.log(error.message);
        }
        if (errorMessage.includes("Error (auth/email-already-in-use)")) {
          setTexterror(
            "Такой пользователь уже зарегистрирован,попробуйте просто войти"
          );
          //console.log(error.message);
        }
        // ..
      });
  };

  return (
    <div className={styles.login}>
      <div className={styles.header}>
        <AppHeader name={"Регистрация  8"}></AppHeader>
      </div>
      <div className={styles.preview_2}>
        <img src={dog} className={styles.preview} alt="dog" />
      </div>
      <section className={styles.main}>
        <form className={styles.form} onSubmit={onSubmit}>
          <input
            type="email"
            placeholder="e-mail"
            onChange={setValueEmail}
            className={` ${styles.buttonbase}`}
            value={email}
          />
          <input
            type="password"
            placeholder="Пароль"
            onChange={setValuePassWord}
            className={` ${styles.buttonbase}`}
            value={password}
          />

          <button
            type="submit"
            className={` ${styles.buttons} ${styles.button} `}
          >
            Зарегистрироваться
          </button>

          <LinLogin
            to="/login"
            qwest="Уже зарегистрированы?"
            text="Войти"
          ></LinLogin>
          <p className={styles.text}>{texterror}</p>
        </form>
      </section>
    </div>
  );
}

export default Register;
