import styles from "./list-slovwrite.module.css";
import { TSlova } from "../list-teams/list-teams";
import { useState } from "react";
import SlovoW from "../slovo-w/slovo-w";
import { getRandomInt } from "../../utils/convert";

type TOrderypeprops = {
  list: TSlova[];
};

const nom = getRandomInt(10, 100);
let index = nom;

function ListSlovwrite(props: TOrderypeprops) {
  const list = props.list;
  const [team, setTeam] = useState(list[0]);

  return (
    <div className={styles.main}>
      <section className={`custom-scroll ${styles.list}`}>
        {list.map((item) => {
          index = index + 1;
          return <SlovoW list={item} key={index}></SlovoW>;
        })}
      </section>
    </div>
  );
}

export default ListSlovwrite;

//<img src={dog} alt="logo" className={styles.previewImage}/>
