import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../types/hook";
import { getuser } from "../store/action-selector";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setuser } from "../store/user";
import { useEffect, useState } from "react";
import { getreg } from "../api/api";
type Ty = {
  children: JSX.Element;
};

const RequireAuth = (props: Ty) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  let users = useAppSelector(getuser);

  if (!users.auth) {
    //if (user.email === "") {
    return <Navigate to="/login" state={location}></Navigate>;
    //}
  }

  return props.children;
};

export default RequireAuth;
