export const slova = [
  {
    name: "Местоимения",
    dict: [
      { ru: "я", port: "eu" },
      { ru: "ты", port: "tu" },
      { ru: "он", port: "ele" },
      { ru: "она", port: "ela" },
      { ru: "мы", port: "nós" },
      { ru: "вы", port: "vocês" },
      { ru: "они (муж. род)", port: "eles" },
      { ru: "они (жен. род)", port: "elas" },
    ],
  },
  {
    name: "Числа от 1 до 10",
    dict: [
      { ru: "0 ноль", port: "zero" },
      { ru: "1 один", port: "um" },
      { ru: "2 два", port: "dois" },
      { ru: "3 три", port: "três" },
      { ru: "4 четыре", port: "quatro" },
      { ru: "5 пять", port: "cinco" },
      { ru: "6 шесть", port: "seis" },
      { ru: "7 семь", port: "sete" },
      { ru: "8 восемь", port: "oito" },
      { ru: "9 девять", port: "nove" },
      { ru: "10 десять", port: "dez" },
    ],
  },
  {
    name: "Вводные и служебные слова",
    dict: [
      { ru: "Где?", port: "Onde?" },
      { ru: "здесь", port: "aqui" },
      { ru: "там", port: "lá, ali" },
      { ru: "где-то", port: "em algum lugar" },
      { ru: "нигде", port: "em lugar nenhum" },
      { ru: "у … (около)", port: "ao pé de …" },
      { ru: "у окна", port: "ao pé da janela" },
      { ru: "Куда?", port: "Para onde?" },
      { ru: "сюда", port: "para cá" },
      { ru: "туда", port: "para lá" },
      { ru: "отсюда", port: "daqui" },
      { ru: "оттуда", port: "de lá, dali" },
      { ru: "близко", port: "perto" },
      { ru: "далеко", port: "longe" },
      { ru: "около (рядом)", port: "perto de …" },
      { ru: "рядом (недалеко)", port: "ao lado de" },
      { ru: "недалеко (ехать, идти)", port: "perto, não fica longe" },
      { ru: "левый", port: "esquerdo" },
      { ru: "слева", port: "à esquerda" },
      { ru: "налево", port: "para esquerda" },
      { ru: "правый", port: "direito" },
      { ru: "справа", port: "à direita" },
      { ru: "направо", port: "para direita" },
      { ru: "спереди", port: "à frente" },
      { ru: "передний", port: "da frente" },
      { ru: "вперёд (движение)", port: "para a frente" },
      { ru: "сзади (находиться)", port: "atrás de …" },
      { ru: "сзади (подойти)", port: "por detrás" },
      { ru: "назад (движение)", port: "para trás" },
      { ru: "середина", port: "meio (m), metade (f)" },
      { ru: "посередине", port: "no meio" },
    ],
  },

  {
    name: "Числа от 10 до 100",
    dict: [
      { ru: "11 одиннадцать", port: "onze" },
      { ru: "12 двенадцать", port: "doze" },
      { ru: "13 тринадцать", port: "treze" },
      { ru: "14 четырнадцать", port: "catorze" },
      { ru: "15 пятнадцать", port: "quinze" },
      { ru: "16 шестнадцать", port: "dezasseis" },
      { ru: "17 семнадцать", port: "dezassete" },
      { ru: "18 восемнадцать", port: "dezoito" },
      { ru: "19 девятнадцать", port: "dezanove" },
      { ru: "20 двадцать", port: "vinte" },
      { ru: "21 двадцать один", port: "vinte e um" },
      { ru: "22 двадцать два", port: "vinte e dois" },
      { ru: "30 тридцать", port: "trinta" },
      { ru: "40 сорок", port: "quarenta" },
      { ru: "50 пятьдесят", port: "cinquenta" },
      { ru: "60 шестьдесят", port: "sessenta" },
      { ru: "70 семьдесят", port: "setenta" },
      { ru: "80 восемьдесят", port: "oitenta" },
      { ru: "90 девяносто", port: "noventa" },
    ],
  },
  {
    name: "Числа от 100",
    dict: [
      { ru: "100 сто", port: "cem" },
      { ru: "200 двести", port: "duzentos" },
      { ru: "300 триста", port: "trezentos" },
      { ru: "400 четыреста", port: "quatrocentos" },
      { ru: "500 пятьсот", port: "quinhentos" },
      { ru: "600 шестьсот", port: "seiscentos" },
      { ru: "700 семьсот", port: "setecentos" },
      { ru: "800 восемьсот", port: "oitocentos" },
      { ru: "900 девятьсот", port: "novecentos" },
      { ru: "1000 тысяча", port: "mil" },
      { ru: "2000 две тысячи", port: "dois mil" },
      { ru: "3000 три тысячи", port: "três mil" },
      { ru: "10000 десять тысяч", port: "dez mil" },
      { ru: "100000 сто тысяч", port: "cem mil" },
      { ru: "миллион", port: "um milhão" },
      { ru: "миллиард", port: "mil milhões" },
    ],
  },
  {
    name: "Дроби",
    dict: [
      { ru: "дробь", port: "fração (f)" },
      { ru: "одна вторая", port: "um meio" },
      { ru: "одна третья", port: "um terço" },
      { ru: "одна четвертая", port: "um quarto" },
      { ru: "одна восьмая", port: "um oitavo" },
      { ru: "одна десятая", port: "um décimo" },
      { ru: "две третьих", port: "dois terços" },
      { ru: "три четвёртых", port: "três quartos" },
    ],
  },
  {
    name: "Математические действия",
    dict: [
      { ru: "вычитание", port: "subtração (f)" },
      { ru: "вычитать", port: "subtrair (vi, vt)" },
      { ru: "деление", port: "divisão (f)" },
      { ru: "делить", port: "dividir (vt)" },
      { ru: "сложение", port: "adição (f)" },
      { ru: "сложить (матем.)", port: "somar (vt)" },
      { ru: "прибавлять", port: "adicionar (vt)" },
      { ru: "умножение", port: "multiplicação (f)" },
      { ru: "умножать", port: "multiplicar (vt)" },
    ],
  },
  {
    name: "Числа. Разное",
    dict: [
      { ru: "цифра", port: "algarismo, dígito (m)" },
      { ru: "число", port: "número (m)" },
      { ru: "числительное", port: "numeral (m)" },
      { ru: "минус", port: "menos (m)" },
      { ru: "плюс", port: "mais (m)" },
      { ru: "формула", port: "fórmula (f)" },
      { ru: "вычисление", port: "cálculo (m)" },
      { ru: "считать", port: "contar, calcular (vt)" },
      { ru: "подсчитывать", port: "fazer a contagem" },
      { ru: "сравнивать", port: "comparar (vt)" },
      { ru: "Сколько? (неисчисл.)", port: "Quanto?" },
      { ru: "Сколько? (исчисл.)", port: "Quantos? -as?" },
      { ru: "сумма", port: "soma (f)" },
      { ru: "результат", port: "resultado (m)" },
      { ru: "остаток", port: "resto (m)" },
      { ru: "несколько", port: "alguns …" },
      { ru: "немного (исчисл.)", port: "poucos, poucas" },
      { ru: "немного (неисчисл.)", port: "um pouco …" },
      { ru: "остальное", port: "resto (m)" },
      { ru: "полтора", port: "um e meio" },
      { ru: "дюжина", port: "dúzia (f)" },
      { ru: "пополам (на 2 части)", port: "ao meio" },
      { ru: "поровну", port: "em partes iguais" },
      { ru: "половина", port: "metade (f)" },
      { ru: "раз", port: "vez (f)" },
    ],
  },
  {
    name: "Самые важные глаголы - 1",
    dict: [
      { ru: "бежать", port: "correr (vi)" },
      { ru: "бояться", port: "ter medo" },
      { ru: "брать", port: "pegar (vt)" },
      { ru: "быть (в настоящий момент)", port: "estar (vi)" },
      { ru: "быть (всегда)", port: "ser (vi)" },
      { ru: "видеть", port: "ver (vt)" },
      { ru: "владеть", port: "possuir (vt)" },
      { ru: "возражать", port: "objetar (vt)" },
      { ru: "входить (в комнату и т.п.)", port: "entrar (vi)" },
      { ru: "выбирать", port: "escolher (vt)" },
      { ru: "выходить (из дома)", port: "sair (vi)" },
      { ru: "говорить (разговаривать)", port: "falar (vi)" },
      { ru: "готовить (обед)", port: "preparar (vt)" },
      { ru: "давать", port: "dar (vt)" },
      { ru: "делать", port: "fazer (vt)" },
      { ru: "доверять", port: "confiar (vt)" },
      { ru: "думать", port: "pensar (vt)" },
      { ru: "жаловаться", port: "reclamar (vi)" },
      { ru: "ждать", port: "esperar (vi, vt)" },
      { ru: "забывать", port: "esquecer (vi, vt)" },
      { ru: "завтракать", port: "tomar o pequeno-almoço" },
      { ru: "заказывать", port: "pedir (vt)" },
      { ru: "заканчивать", port: "terminar (vt)" },
      { ru: "замечать (увидеть)", port: "perceber (vt)" },
      { ru: "записывать", port: "anotar (vt)" },
      { ru: "защищать (страну)", port: "defender (vt)" },
      { ru: "звать (на помощь и т.п.)", port: "chamar (vt)" },
      { ru: "знать (кого-л.)", port: "conhecer (vt)" },
      { ru: "знать (что-л.)", port: "saber (vt)" },
    ],
  },
  {
    name: "Самые важные глаголы - 2",
    dict: [
      { ru: "играть", port: "brincar, jogar (vi, vt)" },
      { ru: "идти", port: "ir (vi)" },
      { ru: "извинять", port: "desculpar (vt)" },
      { ru: "извиняться", port: "desculpar-se (vp)" },
      { ru: "изменить (поменять)", port: "mudar (vt)" },
      { ru: "изучать", port: "estudar (vt)" },
      { ru: "иметь", port: "ter (vt)" },
      { ru: "интересоваться", port: "interessar-se (vp)" },
      { ru: "информировать", port: "informar (vt)" },
      { ru: "искать …", port: "buscar (vt)" },
      { ru: "контролировать", port: "controlar (vt)" },
      { ru: "красть", port: "roubar (vt)" },
      { ru: "кричать", port: "gritar (vi)" },
      { ru: "купаться (в море и т.п.)", port: "ir nadar" },
      { ru: "лететь", port: "voar (vi)" },
      { ru: "ловить", port: "pegar (vt)" },
      { ru: "ломать", port: "quebrar (vt)" },
      { ru: "любить (кого-л.)", port: "amar (vt)" },
      { ru: "молиться", port: "rezar, orar (vi)" },
      { ru: "молчать", port: "ficar em silêncio" },
      { ru: "мочь", port: "poder (v aux)" },
      { ru: "наблюдать", port: "observar (vt)" },
      { ru: "надеяться", port: "esperar (vt)" },
      { ru: "наказывать", port: "punir (vt)" },
      { ru: "настаивать (упорствовать)", port: "insistir (vi)" },
      { ru: "находить", port: "encontrar (vt)" },
      { ru: "начинать", port: "começar (vt)" },
      { ru: "недооценивать", port: "subestimar (vt)" },
      { ru: "нравиться", port: "gostar (vt)" },
      { ru: "обедать", port: "almoçar (vi)" },
      { ru: "обещать", port: "prometer (vt)" },
      { ru: "обманывать", port: "enganar (vt)" },
      { ru: "обсуждать", port: "discutir (vt)" },
      { ru: "объединять", port: "unir (vt)" },
      { ru: "объяснять", port: "explicar (vt)" },
      { ru: "означать", port: "significar (vt)" },
      { ru: "освобождать (город)", port: "libertar (vt)" },
      { ru: "оскорблять", port: "insultar (vt)" },
      { ru: "останавливаться", port: "parar (vi)" },
      { ru: "отвечать", port: "responder (vt)" },
      { ru: "отгадать", port: "adivinhar (vt)" },
      { ru: "отказываться", port: "negar-se (vt)" },
      { ru: "открывать (дверь и т.п.)", port: "abrir (vt)" },
      { ru: "отправлять", port: "enviar (vt)" },
      { ru: "охотиться", port: "caçar (vi)" },
      { ru: "ошибаться", port: "errar (vi)" },
    ],
  },
  {
    name: "Самые важные глаголы - 3",
    dict: [
      { ru: "падать", port: "cair (vi)" },
      { ru: "переводить (текст)", port: "traduzir (vt)" },
      { ru: "писать", port: "escrever (vt)" },
      { ru: "плавать", port: "nadar (vi)" },
      { ru: "плакать", port: "chorar (vi)" },
      { ru: "планировать", port: "planear (vt)" },
      { ru: "платить", port: "pagar (vt)" },
      { ru: "поворачивать", port: "virar (vi)" },
      { ru: "повторять", port: "repetir (vt)" },
      { ru: "подписывать", port: "assinar (vt)" },
      { ru: "подсказать (отгадку)", port: "dar uma dica" },
      { ru: "показывать", port: "mostrar (vt)" },
      { ru: "помогать", port: "ajudar (vt)" },
      { ru: "понимать", port: "compreender (vt)" },
      { ru: "предвидеть (ожидать)", port: "prever (vt)" },
      { ru: "предлагать", port: "propor (vt)" },
      { ru: "предпочитать", port: "preferir (vt)" },
      { ru: "предупреждать", port: "advertir (vt)" },
      { ru: "прекращать", port: "cessar (vt)" },
      { ru: "приглашать", port: "convidar (vt)" },
      { ru: "приезжать", port: "chegar (vi)" },
      { ru: "приказывать", port: "ordenar (vt)" },
      { ru: "принадлежать", port: "pertencer (vt)" },
      { ru: "пробовать (пытаться)", port: "tentar (vt)" },
      { ru: "продавать", port: "vender (vt)" },
      { ru: "продолжать", port: "continuar (vt)" },
      { ru: "произносить (слово)", port: "pronunciar (vt)" },
      { ru: "пропускать (занятия и т.п.)", port: "faltar a …" },
      { ru: "просить", port: "pedir (vt)" },
      { ru: "прощать", port: "perdoar (vt)" },
      { ru: "прятать", port: "esconder (vt)" },
      { ru: "путать (ошибаться)", port: "confundir (vt)" },
      { ru: "работать", port: "trabalhar (vi)" },
      { ru: "разрешать", port: "permitir (vt)" },
      { ru: "рассчитывать на …", port: "contar com …" },
      { ru: "резервировать", port: "reservar (vt)" },
      { ru: "рекомендовать", port: "recomendar (vt)" },
      { ru: "ронять", port: "deixar cair (vt)" },
      { ru: "ругать", port: "repreender (vt)" },
      { ru: "руководить (чем-л.)", port: "dirigir (vt)" },
    ],
  },
  {
    name: "Самые важные глаголы - 4",
    dict: [
      { ru: "садиться", port: "sentar-se (vp)" },
      { ru: "сказать", port: "dizer (vt)" },
      { ru: "следовать за …", port: "seguir …" },
      { ru: "слышать", port: "ouvir (vt)" },
      { ru: "смеяться", port: "rir (vi)" },
      { ru: "снимать (напр. квартиру)", port: "alugar (vt)" },
      { ru: "советовать", port: "aconselhar (vt)" },
      { ru: "соглашаться", port: "concordar (vt)" },
      { ru: "сожалеть", port: "lamentar (vt)" },
      { ru: "создать", port: "criar (vt)" },
      { ru: "сомневаться", port: "duvidar (vt)" },
      { ru: "сохранять", port: "guardar (vt)" },
      { ru: "спасать", port: "salvar (vt)" },
      { ru: "спрашивать", port: "perguntar (vt)" },
      { ru: "спускаться", port: "descer (vi)" },
      { ru: "сравнивать", port: "comparar (vt)" },
      { ru: "стоить", port: "custar (vt)" },
      { ru: "стрелять", port: "atirar (vi)" },
      { ru: "существовать", port: "existir (vi)" },
      { ru: "считать (подсчитывать)", port: "contar (vt)" },
      { ru: "торопиться", port: "estar com pressa" },
      { ru: "требовать", port: "exigir (vt)" },
      { ru: "требоваться", port: "ser necessário" },
      { ru: "трогать", port: "tocar (vt)" },
      { ru: "убивать", port: "matar (vt)" },
      { ru: "угрожать", port: "ameaçar (vt)" },
      { ru: "удивляться", port: "surpreender-se (vp)" },
      { ru: "ужинать", port: "jantar (vi)" },
      { ru: "украшать", port: "decorar (vt)" },
      { ru: "улыбаться", port: "sorrir (vi)" },
      { ru: "упоминать", port: "mencionar (vt)" },
      { ru: "участвовать", port: "participar (vi)" },
      { ru: "хвастаться", port: "gabar-se (vp)" },
      { ru: "хотеть", port: "querer (vt)" },
      { ru: "хотеть есть", port: "ter fome" },
      { ru: "хотеть пить", port: "ter sede" },
      { ru: "читать", port: "ler (vt)" },
      { ru: "шутить", port: "fazer piadas" },
    ],
  },
  {
    name: "Цвета",
    dict: [
      { ru: "цвет", port: "cor (f)" },
      { ru: "оттенок", port: "matiz (m)" },
      { ru: "тон", port: "tom (m)" },
      { ru: "радуга", port: "arco-íris (m)" },
      { ru: "белый", port: "branco" },
      { ru: "чёрный", port: "preto" },
      { ru: "серый", port: "cinzento" },
      { ru: "зелёный", port: "verde" },
      { ru: "жёлтый", port: "amarelo" },
      { ru: "красный", port: "vermelho" },
      { ru: "синий", port: "azul" },
      { ru: "голубой", port: "azul claro" },
      { ru: "розовый", port: "cor-de-rosa" },
      { ru: "оранжевый", port: "cor de laranja" },
      { ru: "фиолетовый", port: "violeta" },
      { ru: "коричневый", port: "castanho" },
      { ru: "золотой", port: "dourado" },
      { ru: "серебристый", port: "prateado" },
      { ru: "бежевый", port: "bege" },
      { ru: "кремовый", port: "creme" },
      { ru: "бирюзовый", port: "turquesa" },
      { ru: "вишнёвый", port: "vermelho cereja" },
      { ru: "лиловый", port: "lilás" },
      { ru: "малиновый", port: "carmesim" },
      { ru: "светлый", port: "claro" },
      { ru: "тёмный", port: "escuro" },
      { ru: "яркий", port: "vivo" },
      { ru: "цветной (карандаш)", port: "de cor" },
      { ru: "цветной (фильм)", port: "a cores" },
      { ru: "чёрно-белый", port: "preto e branco" },
      { ru: "одноцветный", port: "de uma só cor" },
      { ru: "разноцветный", port: "multicor, multicolor" },
    ],
  },
  {
    name: "Вопросы",
    dict: [
      { ru: "Кто?", port: "Quem?" },
      { ru: "Что?", port: "Que?" },
      { ru: "Где?", port: "Onde?" },
      { ru: "Куда?", port: "Para onde?" },
      { ru: "Откуда?", port: "De onde?" },
      { ru: "Когда?", port: "Quando?" },
      { ru: "Зачем?", port: "Para quê?" },
      { ru: "Почему?", port: "Porquê?" },
      { ru: "Для чего?", port: "Para quê?" },
      { ru: "Как?", port: "Como?" },
      { ru: "Какой?", port: "Qual?" },
      { ru: "Который?", port: "Qual?" },
      { ru: "Кому?", port: "A quem?" },
      { ru: "О ком?", port: "De quem?" },
      { ru: "О чём?", port: "Do quê?" },
      { ru: "С кем?", port: "Com quem?" },
      { ru: "Сколько? (исчисл.)", port: "Quantos? -as?" },
      { ru: "Сколько? (неисчисл.)", port: "Quanto?" },
      { ru: "Чей?", port: "De quem?" },
    ],
  },
  {
    name: "Основные предлоги",
    dict: [
      { ru: "с … (с кем-л.)", port: "com" },
      { ru: "без (~ сахара и т.п.)", port: "sem" },
      { ru: "в (предлог движения)", port: "a, para" },
      { ru: "о (говорить о …)", port: "sobre" },
      { ru: "перед (во времени)", port: "antes de …" },
      { ru: "перед (в пространстве)", port: "na frente de" },
      { ru: "под (внизу)", port: "sob, debaixo de …" },
      { ru: "над (наверху)", port: "sobre, em cima de …" },
      { ru: "на (на чём-то)", port: "sobre, em" },
      { ru: "из (откуда-то)", port: "de" },
      { ru: "из (о материале)", port: "de" },
      { ru: "через ... (о времени)", port: "dentro de" },
      { ru: "через (о препятствии)", port: "por cima de …" },
    ],
  },
  {
    name: "Наречия - 1",
    dict: [
      { ru: "сбоку (со стороны)", port: "de lado" },
      { ru: "везде", port: "em todo lugar" },
      { ru: "вокруг", port: "ao redor" },
      { ru: "изнутри", port: "de dentro" },
      { ru: "куда-то", port: "para algum lugar" },
      { ru: "напрямик", port: "diretamente" },
      { ru: "обратно", port: "de volta" },
      { ru: "откуда-нибудь", port: "de algum lugar" },
      { ru: "откуда-то", port: "de um lugar" },
      { ru: "во-первых", port: "em primeiro lugar" },
      { ru: "во-вторых", port: "em segundo lugar" },
      { ru: "в-третьих", port: "em terceiro lugar" },
      { ru: "вдруг", port: "de repente" },
      { ru: "вначале", port: "inicialmente" },
      { ru: "впервые", port: "pela primeira vez" },
      { ru: "задолго до …", port: "muito antes de …" },
      { ru: "заново", port: "novamente" },
      { ru: "насовсем", port: "para sempre" },
      { ru: "никогда", port: "nunca" },
      { ru: "опять", port: "de novo" },
      { ru: "теперь", port: "agora" },
      { ru: "часто", port: "frequentemente" },
      { ru: "тогда", port: "então" },
      { ru: "срочно", port: "urgentemente" },
      { ru: "обычно", port: "usualmente" },
      { ru: "кстати, …", port: "a propósito" },
      { ru: "возможно", port: "é possível" },
      { ru: "вероятно", port: "provavelmente" },
      { ru: "может быть", port: "talvez" },
      { ru: "кроме того, …", port: "além disso, …" },
      { ru: "поэтому …", port: "por isso …" },
      { ru: "несмотря на …", port: "apesar de …" },
      { ru: "благодаря …", port: "graças a …" },
      { ru: "что (местоимение)", port: "que" },
      { ru: "что (союз)", port: "que" },
      { ru: "что-то", port: "algo" },
      { ru: "что-нибудь", port: "alguma coisa" },
      { ru: "ничего", port: "nada" },
      { ru: "кто", port: "quem" },
      { ru: "кто-то", port: "alguém" },
      { ru: "кто-нибудь", port: "alguém" },
      { ru: "никто", port: "ninguém" },
      { ru: "никуда", port: "para lugar nenhum" },
      { ru: "ничей", port: "de ninguém" },
      { ru: "чей-нибудь", port: "de alguém" },
      { ru: "так", port: "tão" },
      { ru: "также", port: "também" },
    ],
  },
  {
    name: "Наречия - 2",
    dict: [
      { ru: "Почему?", port: "Porquê?" },
      { ru: "почему-то", port: "por alguma razão" },
      { ru: "потому, что …", port: "porque …" },
      { ru: "зачем-то", port: "não se sabe para que" },
      { ru: "и", port: "e" },
      { ru: "или", port: "ou" },
      { ru: "но", port: "mas" },
      { ru: "для (предлог)", port: "para" },
      { ru: "слишком", port: "demasiado, muito" },
      { ru: "только", port: "só, somente" },
      { ru: "точно", port: "exatamente" },
      { ru: "около (~ 10 кг)", port: "cerca de" },
      { ru: "приблизительно", port: "aproximadamente" },
      { ru: "приблизительный", port: "aproximado" },
      { ru: "почти", port: "quase" },
      { ru: "остальное", port: "resto (m)" },
      { ru: "другой (второй)", port: "o outro" },
      { ru: "другой (иной)", port: "outro" },
      { ru: "каждый", port: "cada" },
      { ru: "любой", port: "qualquer" },
      { ru: "много (с исчисл.)", port: "muitos, muitas" },
      { ru: "много (с неисчисл.)", port: "muito" },
      { ru: "многие", port: "muitas pessoas" },
      { ru: "все (все люди)", port: "todos" },
      { ru: "в обмен на …", port: "em troca de …" },
      { ru: "взамен", port: "em troca" },
      { ru: "вручную", port: "à mão" },
      { ru: "вряд ли", port: "é pouco provável" },
      { ru: "наверное (вероятно)", port: "provavelmente" },
      { ru: "нарочно", port: "de propósito" },
      { ru: "случайно", port: "por acidente" },
      { ru: "очень", port: "muito" },
      { ru: "например", port: "por exemplo" },
      { ru: "между", port: "entre" },
      { ru: "среди", port: "entre, no meio de …" },
      { ru: "столько", port: "tanto" },
      { ru: "особенно", port: "especialmente" },
    ],
  },
  {
    name: "Дни недели",
    dict: [
      { ru: "понедельник", port: "segunda-feira (f)" },
      { ru: "вторник", port: "terça-feira (f)" },
      { ru: "среда", port: "quarta-feira (f)" },
      { ru: "четверг", port: "quinta-feira (f)" },
      { ru: "пятница", port: "sexta-feira (f)" },
      { ru: "суббота", port: "sábado (m)" },
      { ru: "воскресенье", port: "domingo (m)" },
      { ru: "сегодня", port: "hoje" },
      { ru: "завтра", port: "amanhã" },
      { ru: "послезавтра", port: "depois de amanhã" },
      { ru: "вчера", port: "ontem" },
      { ru: "позавчера", port: "anteontem" },
      { ru: "день", port: "dia (m)" },
      { ru: "рабочий день", port: "dia (m)  de trabalho" },
      { ru: "праздничный день", port: "feriado (m)" },
      { ru: "выходной день", port: "dia (m)  de folga" },
      { ru: "выходные", port: "fim (m)  de semana" },
      { ru: "весь день", port: "o dia todo" },
      { ru: "на следующий день", port: "no dia seguinte" },
      { ru: "2 дня назад", port: "há dois dias" },
      { ru: "накануне", port: "na véspera" },
      { ru: "ежедневный", port: "diário" },
      { ru: "ежедневно", port: "todos os dias" },
      { ru: "неделя", port: "semana (f)" },
      { ru: "на прошлой неделе", port: "na semana passada" },
      { ru: "на следующей неделе", port: "na próxima semana" },
      { ru: "еженедельный", port: "semanal" },
      { ru: "еженедельно", port: "semanalmente" },
      { ru: "2 раза в неделю", port: "duas vezes por semana" },
      { ru: "каждый вторник", port: "cada terça-feira" },
    ],
  },
  {
    name: "Часы. Время суток",
    dict: [
      { ru: "утро", port: "manhã (f)" },
      { ru: "утром", port: "de manhã" },
      { ru: "полдень", port: "meio-dia (m)" },
      { ru: "после обеда", port: "à tarde" },
      { ru: "вечер", port: "noite (f)" },
      { ru: "вечером", port: "à noite" },
      { ru: "ночь", port: "noite (f)" },
      { ru: "ночью", port: "à noite" },
      { ru: "полночь", port: "meia-noite (f)" },
      { ru: "секунда", port: "segundo (m)" },
      { ru: "минута", port: "minuto (m)" },
      { ru: "час", port: "hora (f)" },
      { ru: "полчаса", port: "meia hora (f)" },
      { ru: "четверть часа", port: "quarto (m)  de hora" },
      { ru: "15 минут", port: "quinze minutos" },
      { ru: "сутки", port: "vinte e quatro horas" },
      { ru: "восход солнца", port: "nascer (m)  do sol" },
      { ru: "рассвет", port: "amanhecer (m)" },
      { ru: "раннее утро", port: "madrugada (f)" },
      { ru: "закат", port: "pôr do sol (m)" },
      { ru: "рано утром", port: "de madrugada" },
      { ru: "сегодня утром", port: "hoje de manhã" },
      { ru: "завтра утром", port: "amanhã de manhã" },
      { ru: "сегодня днём", port: "hoje à tarde" },
      { ru: "после обеда", port: "à tarde" },
      { ru: "завтра после обеда", port: "amanhã à tarde" },
      { ru: "сегодня вечером", port: "hoje à noite" },
      { ru: "завтра вечером", port: "amanhã à noite" },
      { ru: "ровно в 3 часа", port: "às três horas em ponto" },
      { ru: "около 4-х часов", port: "por volta das quatro" },
      { ru: "к 12-ти часам", port: "às doze" },
      { ru: "через 20 минут", port: "dentro de vinte minutos" },
      { ru: "через час", port: "dentro duma hora" },
      { ru: "вовремя", port: "a tempo" },
      { ru: "без четверти", port: "menos um quarto" },
      { ru: "в течение часа", port: "durante uma hora" },
      { ru: "каждые 15 минут", port: "a cada quinze minutos" },
      { ru: "круглые сутки", port: "as vinte e quatro horas" },
    ],
  },
  {
    name: "Месяцы. Времена года",
    dict: [
      { ru: "январь", port: "janeiro (m)" },
      { ru: "февраль", port: "fevereiro (m)" },
      { ru: "март", port: "março (m)" },
      { ru: "апрель", port: "abril (m)" },
      { ru: "май", port: "maio (m)" },
      { ru: "июнь", port: "junho (m)" },
      { ru: "июль", port: "julho (m)" },
      { ru: "август", port: "agosto (m)" },
      { ru: "сентябрь", port: "setembro (m)" },
      { ru: "октябрь", port: "outubro (m)" },
      { ru: "ноябрь", port: "novembro (m)" },
      { ru: "декабрь", port: "dezembro (m)" },
      { ru: "весна", port: "primavera (f)" },
      { ru: "весной", port: "na primavera" },
      { ru: "весенний", port: "primaveril" },
      { ru: "лето", port: "verão (m)" },
      { ru: "летом", port: "no verão" },
      { ru: "летний", port: "de verão" },
      { ru: "осень", port: "outono (m)" },
      { ru: "осенью", port: "no outono" },
      { ru: "осенний", port: "outonal" },
      { ru: "зима", port: "inverno (m)" },
      { ru: "зимой", port: "no inverno" },
      { ru: "зимний", port: "de inverno" },
      { ru: "месяц", port: "mês (m)" },
      { ru: "в этом месяце", port: "este mês" },
      { ru: "в следующем месяце", port: "no próximo mês" },
      { ru: "в прошлом месяце", port: "no mês passado" },
      { ru: "месяц назад", port: "há um mês" },
      { ru: "через месяц", port: "dentro de um mês" },
      { ru: "через 2 месяца", port: "dentro de dois meses" },
      { ru: "весь месяц", port: "durante todo o mês" },
      { ru: "целый месяц", port: "um mês inteiro" },
      { ru: "ежемесячный", port: "mensal" },
      { ru: "ежемесячно", port: "mensalmente" },
      { ru: "каждый месяц", port: "cada mês" },
      { ru: "2 раза в месяц", port: "duas vezes por mês" },
      { ru: "год", port: "ano (m)" },
      { ru: "в этом году", port: "este ano" },
      { ru: "в следующем году", port: "no próximo ano" },
      { ru: "в прошлом году", port: "no ano passado" },
      { ru: "год назад", port: "há um ano" },
      { ru: "через год", port: "dentro dum ano" },
      { ru: "через 2 года", port: "dentro de 2 anos" },
      { ru: "весь год", port: "durante todo o ano" },
      { ru: "целый год", port: "um ano inteiro" },
      { ru: "каждый год", port: "cada ano" },
      { ru: "ежегодный", port: "anual" },
      { ru: "ежегодно", port: "anualmente" },
      { ru: "4 раза в год", port: "quatro vezes por ano" },
      { ru: "число (день месяца)", port: "data (f)" },
      { ru: "дата", port: "data (f)" },
      { ru: "календарь", port: "calendário (m)" },
      { ru: "полгода", port: "meio ano" },
      { ru: "полугодие", port: "seis meses" },
      { ru: "сезон", port: "estação (f)" },
      { ru: "век (столетие)", port: "século (m)" },
    ],
  },
  {
    name: "Время. Разное",
    dict: [
      { ru: "время", port: "tempo (m)" },
      { ru: "миг", port: "momento (m)" },
      { ru: "мгновение", port: "instante (m)" },
      { ru: "мгновенный", port: "instantâneo" },
      { ru: "отрезок (времени)", port: "período (m)  de tempo" },
      { ru: "жизнь", port: "vida (f)" },
      { ru: "вечность", port: "eternidade (f)" },
      { ru: "эпоха", port: "época (f)" },
      { ru: "эра", port: "era (f)" },
      { ru: "цикл", port: "ciclo (m)" },
      { ru: "период", port: "período (m)" },
      { ru: "срок (долгий ~)", port: "prazo (m)" },
      { ru: "будущее (сущ.)", port: "futuro (m)" },
      { ru: "будущий", port: "futuro" },
      { ru: "в следующий раз", port: "da próxima vez" },
      { ru: "прошлое (сущ.)", port: "passado (m)" },
      { ru: "прошлый", port: "passado" },
      { ru: "в прошлый раз", port: "na vez passada" },
      { ru: "позже", port: "mais tarde" },
      { ru: "после", port: "depois" },
      { ru: "сейчас (в наше время)", port: "atualmente" },
      { ru: "сейчас (в эту минуту)", port: "agora" },
      { ru: "немедленно", port: "imediatamente" },
      { ru: "скоро", port: "logo, brevemente" },
      { ru: "заранее", port: "de antemão" },
      { ru: "давно", port: "há muito tempo" },
      { ru: "недавно", port: "há pouco tempo" },
      { ru: "судьба", port: "destino (m)" },
      { ru: "память (воспоминания)", port: "recordações (f pl)" },
      { ru: "архив", port: "arquivo (m)" },
      { ru: "во время …", port: "durante …" },
      { ru: "долго", port: "durante muito tempo" },
      { ru: "недолго", port: "pouco tempo" },
      { ru: "рано (~ проснуться)", port: "cedo" },
      { ru: "поздно (~ встать)", port: "tarde" },
      { ru: "навсегда", port: "para sempre" },
      { ru: "начинать", port: "começar (vt)" },
      { ru: "перенести (о сроке)", port: "adiar (vt)" },
      { ru: "одновременно", port: "simultaneamente" },
      { ru: "постоянно (все время)", port: "permanentemente" },
      { ru: "постоянный (шум)", port: "constante" },
      { ru: "временный", port: "temporário" },
      { ru: "иногда", port: "às vezes" },
      { ru: "редко", port: "raramente" },
      { ru: "часто", port: "frequentemente" },
    ],
  },
  {
    name: "Противоположности",
    dict: [
      { ru: "богатый", port: "rico" },
      { ru: "бедный", port: "pobre" },
      { ru: "больной", port: "doente" },
      { ru: "здоровый", port: "são" },
      { ru: "большой", port: "grande" },
      { ru: "маленький", port: "pequeno" },
      { ru: "быстро", port: "rapidamente" },
      { ru: "медленно", port: "lentamente" },
      { ru: "быстрый", port: "rápido" },
      { ru: "медленный", port: "lento" },
      { ru: "весёлый", port: "alegre" },
      { ru: "грустный", port: "triste" },
      { ru: "вместе", port: "juntos" },
      { ru: "отдельно", port: "separadamente" },
      { ru: "вслух", port: "em voz alta" },
      { ru: "про себя", port: "para si" },
      { ru: "высокий", port: "alto" },
      { ru: "низкий", port: "baixo" },
      { ru: "глубокий", port: "profundo" },
      { ru: "мелкий", port: "pouco fundo" },
      { ru: "да", port: "sim" },
      { ru: "нет", port: "não" },
      { ru: "далёкий", port: "distante" },
      { ru: "близкий", port: "próximo" },
      { ru: "далеко", port: "longe" },
      { ru: "рядом", port: "perto" },
      { ru: "длинный", port: "longo" },
      { ru: "короткий", port: "curto" },
      { ru: "добрый", port: "bondoso" },
      { ru: "злой", port: "mau" },
      { ru: "женатый", port: "casado" },
      { ru: "холостой", port: "solteiro" },
      { ru: "запретить", port: "proibir (vt)" },
      { ru: "разрешить", port: "permitir (vt)" },
      { ru: "конец", port: "fim (m)" },
      { ru: "начало", port: "começo (m)" },
      { ru: "левый", port: "esquerdo" },
      { ru: "правый", port: "direito" },
      { ru: "первый", port: "primeiro" },
      { ru: "последний", port: "último" },
      { ru: "преступление", port: "crime (m)" },
      { ru: "наказание", port: "castigo (m)" },
      { ru: "приказать", port: "ordenar (vt)" },
      { ru: "подчиниться", port: "obedecer (vt)" },
      { ru: "прямой", port: "reto" },
      { ru: "кривой", port: "curvo" },
      { ru: "рай", port: "paraíso (m)" },
      { ru: "ад", port: "inferno (m)" },
      { ru: "родиться", port: "nascer (vi)" },
      { ru: "умереть", port: "morrer (vi)" },
      { ru: "сильный", port: "forte" },
      { ru: "слабый", port: "fraco" },
      { ru: "старый", port: "idoso" },
      { ru: "молодой", port: "jovem" },
      { ru: "старый", port: "velho" },
      { ru: "новый", port: "novo" },
      { ru: "твёрдый", port: "duro" },
      { ru: "мягкий", port: "mole" },
      { ru: "тёплый", port: "morno" },
      { ru: "холодный", port: "frio" },
      { ru: "толстый", port: "gordo" },
      { ru: "худой", port: "magro" },
      { ru: "узкий", port: "estreito" },
      { ru: "широкий", port: "largo" },
      { ru: "хороший", port: "bom" },
      { ru: "плохой", port: "mau" },
      { ru: "храбрый", port: "valente" },
      { ru: "трусливый", port: "cobarde" },
    ],
  },
  {
    name: "Линии и формы",
    dict: [
      { ru: "квадрат", port: "quadrado (m)" },
      { ru: "квадратный", port: "quadrado" },
      { ru: "круг", port: "círculo (m)" },
      { ru: "круглый", port: "redondo" },
      { ru: "треугольник", port: "triângulo (m)" },
      { ru: "треугольный", port: "triangular" },
      { ru: "овал", port: "oval (f)" },
      { ru: "овальный", port: "oval" },
      { ru: "прямоугольник", port: "retângulo (m)" },
      { ru: "прямоугольный", port: "retangular" },
      { ru: "пирамида", port: "pirâmide (f)" },
      { ru: "ромб", port: "rombo (m)" },
      { ru: "трапеция", port: "trapézio (m)" },
      { ru: "куб", port: "cubo (m)" },
      { ru: "призма", port: "prisma (m)" },
      { ru: "окружность", port: "circunferência (f)" },
      { ru: "сфера", port: "esfera (f)" },
      { ru: "шар (геометр.)", port: "globo (m)" },
      { ru: "диаметр", port: "diâmetro (m)" },
      { ru: "радиус", port: "raio (m)" },
      { ru: "периметр", port: "perímetro (m)" },
      { ru: "центр", port: "centro (m)" },
      { ru: "горизонтальный", port: "horizontal" },
      { ru: "вертикальный", port: "vertical" },
      { ru: "параллель", port: "paralela (f)" },
      { ru: "параллельный", port: "paralelo" },
      { ru: "линия", port: "linha (f)" },
      { ru: "черта", port: "traço (m)" },
      { ru: "прямая (сущ.)", port: "reta (f)" },
      { ru: "кривая (сущ.)", port: "curva (f)" },
      { ru: "тонкий", port: "fino" },
      { ru: "контур", port: "contorno (m)" },
    ],
  },
  {
    name: "Меры измерения",
    dict: [
      { ru: "вес", port: "peso (m)" },
      { ru: "длина", port: "comprimento (m)" },
      { ru: "ширина", port: "largura (f)" },
      { ru: "высота", port: "altura (f)" },
      { ru: "глубина", port: "profundidade (f)" },
      { ru: "объём", port: "volume (m)" },
      { ru: "площадь", port: "área (f)" },
      { ru: "грамм", port: "grama (m)" },
      { ru: "миллиграмм", port: "miligrama (m)" },
      { ru: "килограмм", port: "quilograma (m)" },
      { ru: "тонна", port: "tonelada (f)" },
      { ru: "фунт (мера веса)", port: "libra (f)" },
      { ru: "унция", port: "onça (f)" },
      { ru: "метр", port: "metro (m)" },
      { ru: "миллиметр", port: "milímetro (m)" },
      { ru: "сантиметр", port: "centímetro (m)" },
      { ru: "километр", port: "quilómetro (m)" },
      { ru: "миля", port: "milha (f)" },
      { ru: "дюйм", port: "polegada (f)" },
      { ru: "фут", port: "pé (m)" },
      { ru: "ярд", port: "jarda (f)" },
      { ru: "квадратный метр", port: "metro (m)  quadrado" },
      { ru: "гектар", port: "hectare (m)" },
      { ru: "литр", port: "litro (m)" },
      { ru: "градус", port: "grau (m)" },
      { ru: "вольт", port: "volt (m)" },
      { ru: "ампер", port: "ampere (m)" },
      { ru: "лошадиная сила", port: "cavalo-vapor (m)" },
      { ru: "количество", port: "quantidade (f)" },
      { ru: "немного …", port: "um pouco de …" },
      { ru: "половина", port: "metade (f)" },
      { ru: "дюжина", port: "dúzia (f)" },
      { ru: "штука", port: "peça (f)" },
      { ru: "размер (предмета)", port: "dimensão (f)" },
      { ru: "масштаб", port: "escala (f)" },
      { ru: "минимальный", port: "mínimo" },
      { ru: "наименьший", port: "menor, mais pequeno" },
      { ru: "средний", port: "médio" },
      { ru: "максимальный", port: "máximo" },
      { ru: "наибольший", port: "maior, mais grande" },
    ],
  },
  {
    name: "Емкости",
    dict: [
      { ru: "банка (стеклянная)", port: "boião (m)  de vidro" },
      { ru: "банка (жестяная)", port: "lata (f)" },
      { ru: "ведро", port: "balde (m)" },
      { ru: "бочка", port: "barril (m)" },
      { ru: "таз", port: "bacia (f)" },
      { ru: "бак", port: "tanque (m)" },
      { ru: "фляжка", port: "cantil (m)  de bolso" },
      { ru: "канистра", port: "bidão (m)  de gasolina" },
      { ru: "цистерна", port: "cisterna (f)" },
      { ru: "кружка", port: "caneca (f)" },
      { ru: "чашка", port: "chávena (f)" },
      { ru: "блюдце", port: "pires (m)" },
      { ru: "стакан", port: "copo (m)" },
      { ru: "бокал", port: "taça (m)  de vinho" },
      { ru: "кастрюля", port: "panela (f)" },
      { ru: "бутылка", port: "garrafa (f)" },
      { ru: "горлышко (бутылки)", port: "gargalo (m)" },
      { ru: "графин", port: "jarro, garrafa (f)" },
      { ru: "кувшин", port: "jarro (m)  de barro" },
      { ru: "сосуд", port: "vasilhame (m)" },
      { ru: "горшок", port: "pote (m)" },
      { ru: "ваза", port: "vaso (m)" },
      { ru: "флакон", port: "frasco, frasquinho (m)" },
      { ru: "пузырёк", port: "frasco (m)" },
      { ru: "тюбик", port: "tubo (m)" },
      { ru: "мешок (сахара и т.п.)", port: "saca (f)" },
      { ru: "пакет (мешок)", port: "saco (m)" },
      { ru: "пачка (чая, сигарет)", port: "maço (m)" },
      { ru: "коробка", port: "caixa (f)" },
      { ru: "ящик", port: "engradado (m)" },
      { ru: "корзина", port: "cesto (m), cesta (f)" },
    ],
  },
  {
    name: "Материалы",
    dict: [
      { ru: "материал", port: "material (m)" },
      { ru: "дерево", port: "madeira (f)" },
      { ru: "деревянный", port: "de madeira" },
      { ru: "стекло", port: "vidro (m)" },
      { ru: "стеклянный", port: "de vidro" },
      { ru: "камень", port: "pedra (f)" },
      { ru: "каменный", port: "de pedra" },
      { ru: "пластик, пластмасса", port: "plástico (m)" },
      { ru: "пластмассовый", port: "de plástico" },
      { ru: "резина", port: "borracha (f)" },
      { ru: "резиновый", port: "de borracha" },
      { ru: "ткань", port: "tecido, pano (m)" },
      { ru: "из ткани", port: "de tecido" },
      { ru: "бумага", port: "papel (m)" },
      { ru: "бумажный", port: "de papel" },
      { ru: "картон", port: "cartão (m)" },
      { ru: "картонный", port: "de cartão" },
      { ru: "полиэтилен", port: "polietileno (m)" },
      { ru: "целлофан", port: "celofane (m)" },
      { ru: "линолеум", port: "linóleo (m)" },
      { ru: "фанера", port: "contraplacado (m)" },
      { ru: "фарфор", port: "porcelana (f)" },
      { ru: "фарфоровый", port: "de porcelana" },
      { ru: "глина", port: "barro (f)" },
      { ru: "глиняный (о посуде)", port: "de barro" },
      { ru: "керамика", port: "cerâmica (f)" },
      { ru: "керамический", port: "de cerâmica" },
    ],
  },
  {
    name: "Металлы",
    dict: [
      { ru: "металл", port: "metal (m)" },
      { ru: "металлический", port: "metálico" },
      { ru: "сплав", port: "liga (f)" },
      { ru: "золото", port: "ouro (m)" },
      { ru: "золотой", port: "de ouro" },
      { ru: "серебро", port: "prata (f)" },
      { ru: "серебряный", port: "de prata" },
      { ru: "железо", port: "ferro (m)" },
      { ru: "железный", port: "de ferro" },
      { ru: "сталь", port: "aço (m)" },
      { ru: "стальной", port: "de aço" },
      { ru: "медь", port: "cobre (m)" },
      { ru: "медный", port: "de cobre" },
      { ru: "алюминий", port: "alumínio (m)" },
      { ru: "алюминиевый", port: "de alumínio" },
      { ru: "бронза", port: "bronze (m)" },
      { ru: "бронзовый", port: "de bronze" },
      { ru: "латунь", port: "latão (m)" },
      { ru: "никель", port: "níquel (m)" },
      { ru: "платина", port: "platina (f)" },
      { ru: "ртуть", port: "mercúrio (m)" },
      { ru: "олово", port: "estanho (m)" },
      { ru: "свинец", port: "chumbo (m)" },
      { ru: "цинк", port: "zinco (m)" },
    ],
  },
  {
    name: "Металлы",
    dict: [
      { ru: "металл", port: "metal (m)" },
      { ru: "металлический", port: "metálico" },
      { ru: "сплав", port: "liga (f)" },
      { ru: "золото", port: "ouro (m)" },
      { ru: "золотой", port: "de ouro" },
      { ru: "серебро", port: "prata (f)" },
      { ru: "серебряный", port: "de prata" },
      { ru: "железо", port: "ferro (m)" },
      { ru: "железный", port: "de ferro" },
      { ru: "сталь", port: "aço (m)" },
      { ru: "стальной", port: "de aço" },
      { ru: "медь", port: "cobre (m)" },
      { ru: "медный", port: "de cobre" },
      { ru: "алюминий", port: "alumínio (m)" },
      { ru: "алюминиевый", port: "de alumínio" },
      { ru: "бронза", port: "bronze (m)" },
      { ru: "бронзовый", port: "de bronze" },
      { ru: "латунь", port: "latão (m)" },
      { ru: "никель", port: "níquel (m)" },
      { ru: "платина", port: "platina (f)" },
      { ru: "ртуть", port: "mercúrio (m)" },
      { ru: "олово", port: "estanho (m)" },
      { ru: "свинец", port: "chumbo (m)" },
      { ru: "цинк", port: "zinco (m)" },
    ],
  },
  {
    name: "Человек.Общие понятия",
    dict: [
      { ru: "человек", port: "ser (m)  humano" },
      { ru: "мужчина", port: "homem (m)" },
      { ru: "женщина", port: "mulher (f)" },
      { ru: "ребёнок", port: "criança (f)" },
      { ru: "девочка", port: "menina (f)" },
      { ru: "мальчик", port: "menino (m)" },
      { ru: "подросток", port: "adolescente (m)" },
      { ru: "старик", port: "velhote, idoso (m)" },
      { ru: "старая женщина", port: "velhota (f)" },
    ],
  },
  {
    name: "Анатомия",
    dict: [
      { ru: "организм", port: "organismo (m)" },
      { ru: "сердце", port: "coração (m)" },
      { ru: "кровь", port: "sangue (m)" },
      { ru: "артерия", port: "artéria (f)" },
      { ru: "вена", port: "veia (f)" },
      { ru: "мозг", port: "cérebro (m)" },
      { ru: "нерв", port: "nervo (m)" },
      { ru: "нервы", port: "nervos (m pl)" },
      { ru: "позвонок", port: "vértebra (f)" },
      { ru: "позвоночник", port: "coluna (f)  vertebral" },
      { ru: "желудок", port: "estômago (m)" },
      { ru: "кишечник", port: "intestinos (m pl)" },
      { ru: "кишка", port: "intestino (m)" },
      { ru: "печень", port: "fígado (m)" },
      { ru: "почка", port: "rim (m)" },
      { ru: "кость", port: "osso (m)" },
      { ru: "скелет", port: "esqueleto (m)" },
      { ru: "ребро", port: "costela (f)" },
      { ru: "череп", port: "crânio (m)" },
      { ru: "мышца", port: "músculo (m)" },
      { ru: "бицепс", port: "bíceps (m)" },
      {
        ru: "трицепс сухожилие сустав",
        port: "triceps (m) tendão (m) articulação (f)",
      },
      { ru: "лёгкие", port: "pulmões (m pl)" },
      { ru: "половые органы", port: "órgãos (m pl)  genitais" },
      { ru: "кожа", port: "pele (f)" },
    ],
  },
  {
    name: "Голова",
    dict: [
      { ru: "голова", port: "cabeça (f)" },
      { ru: "лицо", port: "cara (f)" },
      { ru: "нос", port: "nariz (m)" },
      { ru: "рот", port: "boca (f)" },
      { ru: "глаз", port: "olho (m)" },
      { ru: "глаза", port: "olhos (m pl)" },
      { ru: "зрачок", port: "pupila (f)" },
      { ru: "бровь", port: "sobrancelha (f)" },
      { ru: "ресница", port: "pestana (f)" },
      { ru: "веко", port: "pálpebra (f)" },
      { ru: "язык", port: "língua (f)" },
      { ru: "зуб", port: "dente (m)" },
      { ru: "губы", port: "lábios (m pl)" },
      { ru: "скулы", port: "maçãs (f pl)  do rosto" },
      { ru: "десна", port: "gengiva (f)" },
      { ru: "нёбо", port: "céu (f)  da boca" },
      { ru: "ноздри", port: "narinas (f pl)" },
      { ru: "подбородок", port: "queixo (m)" },
      { ru: "челюсть", port: "mandíbula (f)" },
      { ru: "щека", port: "bochecha (f)" },
      { ru: "лоб", port: "testa (f)" },
      { ru: "висок", port: "têmpora (f)" },
      { ru: "ухо", port: "orelha (f)" },
      { ru: "затылок", port: "nuca (f)" },
      { ru: "шея", port: "pescoço (m)" },
      { ru: "горло", port: "garganta (f)" },
      { ru: "волосы", port: "cabelos (m pl)" },
      { ru: "причёска", port: "penteado (m)" },
      { ru: "стрижка (причёска)", port: "corte (m)  de cabelo" },
      { ru: "парик", port: "peruca (f)" },
      { ru: "усы", port: "bigode (m)" },
      { ru: "борода", port: "barba (f)" },
      { ru: "носить (усы, бороду)", port: "usar, ter (vt)" },
      { ru: "коса", port: "trança (f)" },
      { ru: "бакенбарды", port: "suíças (f pl)" },
      { ru: "рыжий (человек)", port: "ruivo" },
      { ru: "седой (о волосах)", port: "grisalho" },
      { ru: "лысый", port: "calvo" },
      { ru: "лысина", port: "calva (f)" },
      { ru: "хвост (причёска)", port: "rabo-de-cavalo (m)" },
      { ru: "чёлка", port: "franja (f)" },
    ],
  },
  {
    name: "Тело",
    dict: [
      { ru: "кисть", port: "mão (f)" },
      { ru: "рука (вся)", port: "braço (m)" },
      { ru: "палец", port: "dedo (m)" },
      { ru: "палец (ноги)", port: "dedo (m)" },
      { ru: "большой палец", port: "polegar (m)" },
      { ru: "мизинец", port: "dedo (m)  mindinho" },
      { ru: "ноготь", port: "unha (f)" },
      { ru: "кулак", port: "punho (m)" },
      { ru: "ладонь", port: "palma (f)  da mão" },
      { ru: "запястье", port: "pulso (m)" },
      { ru: "предплечье", port: "antebraço (m)" },
      { ru: "локоть", port: "cotovelo (m)" },
      { ru: "плечо", port: "ombro (m)" },
      { ru: "нога (выше ступни)", port: "perna (f)" },
      { ru: "ступня", port: "pé (m)" },
      { ru: "колено", port: "joelho (m)" },
      { ru: "икра", port: "barriga (f)  da perna" },
      { ru: "бедро", port: "coxa (f)" },
      { ru: "пятка", port: "calcanhar (m)" },
      { ru: "тело", port: "corpo (m)" },
      { ru: "живот", port: "barriga (f)" },
      { ru: "грудь", port: "peito (m)" },
      { ru: "грудь (женская)", port: "seio (m)" },
      { ru: "бок", port: "lado (m)" },
      { ru: "спина", port: "costas (f pl)" },
      { ru: "поясница", port: "região (f)  lombar" },
      { ru: "талия", port: "cintura (f)" },
      { ru: "пупок", port: "umbigo (m)" },
      { ru: "ягодицы", port: "nádegas (f pl)" },
      { ru: "зад", port: "traseiro (m)" },
      { ru: "родинка", port: "sinal (m)" },
      { ru: "родимое пятно", port: "sinal (m)  de nascença" },
      { ru: "татуировка", port: "tatuagem (f)" },
      { ru: "шрам", port: "cicatriz (f)" },
    ],
  },
  {
    name: "Верхняя одежда",
    dict: [
      { ru: "одежда", port: "roupa (f)" },
      { ru: "верхняя одежда", port: "roupa (f)  exterior" },
      { ru: "зимняя одежда", port: "roupa (f)  de inverno" },
      { ru: "пальто", port: "sobretudo (m)" },
      { ru: "шуба", port: "casaco (m)  de peles" },
      { ru: "полушубок", port: "casaco curto (m)  de peles" },
      { ru: "пуховик", port: "colchão (m)  de penas" },
      { ru: "куртка (кожаная и т.п.)", port: "casaco, blusão (m)" },
      { ru: "плащ", port: "capa (f)" },
      { ru: "непромокаемый", port: "impermeável" },
    ],
  },
  {
    name: "Одежда",
    dict: [
      { ru: "рубашка", port: "camisa (f)" },
      { ru: "брюки", port: "calças (f pl)" },
      { ru: "джинсы", port: "calças de ganga" },
      { ru: "пиджак", port: "casaco (m)" },
      { ru: "костюм (мужской)", port: "fato (m)" },
      { ru: "платье", port: "vestido (m)" },
      { ru: "юбка", port: "saia (f)" },
      { ru: "блузка", port: "blusa (f)" },
      { ru: "кофта (шерстяная)", port: "casaco de malha" },
      { ru: "жакет", port: "casaquinho (m)" },
      { ru: "футболка", port: "t-shirt, camiseta (f)" },
      { ru: "шорты", port: "calções (m pl)" },
      { ru: "спортивный костюм", port: "fato de treino" },
      { ru: "халат (махровый)", port: "roupão de banho" },
      { ru: "пижама", port: "pijama (m)" },
      { ru: "свитер", port: "suéter (m)" },
      { ru: "пуловер", port: "pulôver (m)" },
      { ru: "жилет", port: "colete (m)" },
      { ru: "фрак", port: "casaca (f)" },
      { ru: "смокинг", port: "smoking (m)" },
      { ru: "форма (униформа)", port: "uniforme (m)" },
      { ru: "рабочая одежда", port: "roupa de trabalho" },
      { ru: "комбинезон", port: "fato-macaco (m)" },
      { ru: "халат (врача)", port: "bata (f)" },
    ],
  },
  {
    name: "Белье",
    dict: [
      {
        ru: "бельё (одежда) трусы мужские",
        port: "roupa (f)  interior cuecas boxer (f pl)",
      },
      { ru: "трусы женские", port: "cuecas (f pl)" },
      { ru: "майка", port: "camisola (f)  interior" },
      { ru: "носки", port: "peúgas (f pl)" },
      { ru: "ночная рубашка", port: "camisa (f)  de noite" },
      { ru: "бюстгальтер", port: "sutiã (m)" },
      { ru: "гольфы (носки)", port: "meias 3/4 (f pl)" },
      { ru: "колготки", port: "meias-calças (f pl)" },
      { ru: "чулки", port: "meias (f pl)" },
      { ru: "купальник", port: "fato (m)  de banho" },
    ],
  },
  {
    name: "Ткани, материалы",
    dict: [
      { ru: "хлопок", port: "algodão (m)" },
      { ru: "из хлопка", port: "de algodão" },
      { ru: "лён", port: "linho (m)" },
      { ru: "из льна", port: "de linho" },
      { ru: "шёлк", port: "seda (f)" },
      { ru: "шёлковый", port: "de seda" },
      { ru: "шерсть", port: "lã (f)" },
      { ru: "шерстяной", port: "de lã" },
      { ru: "бархат", port: "veludo (m)" },
      { ru: "замша", port: "camurça (f)" },
      { ru: "вельвет", port: "bombazina (f)" },
      { ru: "нейлон", port: "nylon (m)" },
      { ru: "из нейлона", port: "de nylon" },
      { ru: "полиэстер", port: "poliéster (m)" },
      { ru: "полиэстровый", port: "de poliéster" },
      { ru: "кожа", port: "couro (m)" },
      { ru: "из кожи", port: "de couro" },
      { ru: "мех", port: "pele (f)" },
      { ru: "меховой", port: "de peles, de pele" },
    ],
  },
  {
    name: "Обувь",
    dict: [
      { ru: "обувь", port: "calçado (m)" },
      { ru: "ботинки", port: "botinas (f pl)" },
      { ru: "туфли", port: "sapatos (m pl)" },
      { ru: "сапоги", port: "botas (f pl)" },
      { ru: "тапочки", port: "pantufas (f pl)" },
      { ru: "кроссовки", port: "ténis (m pl)" },
      { ru: "кеды", port: "sapatilhas (f pl)" },
      { ru: "сандалии", port: "sandálias (f pl)" },
      { ru: "сапожник", port: "sapateiro (m)" },
      { ru: "каблук", port: "salto (m)" },
      { ru: "пара (обуви и т.п.)", port: "par (m)" },
      { ru: "шнурок", port: "atacador (m)" },
      { ru: "шнуровать (ботинки)", port: "amarrar (vt)" },
      { ru: "рожок (для обуви)", port: "calçadeira (f)" },
      { ru: "крем для обуви", port: "graxa (f)  para calçado" },
    ],
  },
  {
    name: "Аксессуары",
    dict: [
      { ru: "перчатки", port: "luvas (f pl) " },
      { ru: " варежки", port: "mitenes (f pl) " },
      { ru: " шарф", port: "cachecol (m)" },
      { ru: "очки", port: "óculos (m pl)" },
      { ru: "оправа", port: "armação (f)  de óculos" },
      { ru: "зонт", port: "guarda-chuva (m)" },
      { ru: "трость", port: "bengala (f)" },
      { ru: "галстук", port: "gravata (f)" },
      { ru: "галстук-бабочка", port: "gravata-borboleta (f)" },
      { ru: "подтяжки", port: "suspensórios (m pl)" },
      { ru: "носовой платок", port: "lenço (m)" },
      { ru: "расчёска", port: "pente (m)" },
      { ru: "заколка", port: "travessão (m)" },
      { ru: "шпилька", port: "gancho (m)  de cabelo" },
      { ru: "пряжка", port: "fivela (f)" },
      { ru: "пояс (ремень)", port: "cinto (m)" },
      { ru: "ремень (сумки)", port: "correia (f)" },
      { ru: "сумка", port: "bolsa (f)" },
      { ru: "сумочка (женская)", port: "bolsa (f)  de senhora" },
      { ru: "рюкзак", port: "mochila (f)" },
    ],
  },
  {
    name: "Одежда разное",
    dict: [
      { ru: "мода", port: "moda (f)" },
      { ru: "модный", port: "na moda" },
      { ru: "модельер", port: "estilista (m)" },
      { ru: "воротник", port: "colarinho (m), gola (f)" },
      { ru: "карман", port: "bolso (m)" },
      { ru: "карманный", port: "de bolso" },
      { ru: "рукав", port: "manga (f)" },
      { ru: "вешалка (петля)", port: "cabide (m)" },
      { ru: "ширинка", port: "braguilha (f)" },
      { ru: "молния (застежка)", port: "fecho de correr (m)" },
      { ru: "застёжка", port: "fecho (m), colchete (m)" },
      { ru: "пуговица", port: "botão (m)" },
      { ru: "петля (для пуговицы)", port: "casa (f)  de botão" },
      { ru: "оторваться (о пуговице)", port: "cair (vi)" },
      { ru: "шить", port: "coser, costurar (vi)" },
      { ru: "вышивать", port: "bordar (vt)" },
      { ru: "вышивка", port: "bordado (m)" },
      { ru: "игла (для шитья)", port: "agulha (f)" },
      { ru: "нитка", port: "fio (m)" },
      { ru: "шов (швейный)", port: "costura (f)" },
      { ru: "испачкаться", port: "sujar-se (vp)" },
      { ru: "пятно", port: "mancha (f)" },
      { ru: "помяться (об одежде)", port: "amarrotar-se (vp)" },
      { ru: "порвать (испортить)", port: "rasgar (vt)" },
      { ru: "моль", port: "traça (f)" },
    ],
  },
  {
    name: "Предметы личной гигиены, косметика",
    dict: [
      { ru: "зубная паста", port: "pasta (f)  de dentes" },
      { ru: "зубная щётка", port: "escova (f)  de dentes" },
      { ru: "чистить зубы", port: "escovar os dentes" },
      { ru: "бритва", port: "máquina (f)  de barbear" },
      { ru: "крем для бритья", port: "creme (m)  de barbear" },
      { ru: "бриться", port: "barbear-se (vp)" },
      { ru: "мыло", port: "sabonete (m)" },
      { ru: "шампунь", port: "champô (m)" },
      { ru: "ножницы", port: "tesoura (f)" },
      { ru: "пилочка для ногтей", port: "lima (f)  de unhas" },
      { ru: "щипчики для ногтей", port: "corta-unhas (m)" },
      { ru: "пинцет", port: "pinça (f)" },
      { ru: "косметика", port: "cosméticos (m pl)" },
      { ru: "маска (косметич.)", port: "máscara (f)" },
      { ru: "маникюр", port: "manicura (f)" },
      { ru: "делать маникюр", port: "fazer a manicura" },
      { ru: "педикюр", port: "pedicure (f)" },
      { ru: "косметичка", port: "bolsa (f)  de maquilhagem" },
      { ru: "пудра", port: "pó (m)" },
      { ru: "пудреница", port: "caixa (f)  de pó" },
      { ru: "румяна", port: "blush (m)" },
      { ru: "духи", port: "perfume (m)" },
      { ru: "туалетная вода", port: "água (f)  de toilette" },
      { ru: "лосьон", port: "loção (m)" },
      { ru: "одеколон", port: "água-de-colónia (f)" },
      { ru: "тени для век", port: "sombra (f)  para os olhos" },
      { ru: "карандаш для глаз", port: "lápis (m)  delineador" },
      { ru: "тушь для ресниц", port: "máscara (f), rímel (m)" },
      { ru: "губная помада", port: "batom (m)" },
      { ru: "лак для ногтей", port: "verniz (m)  de unhas" },
      { ru: "лак для волос", port: "laca (f)  para cabelos" },
      { ru: "дезодорант", port: "desodorizante (m)" },
      { ru: "крем", port: "creme (m)" },
      { ru: "крем для лица", port: "creme (m)  de rosto" },
      { ru: "крем для рук", port: "creme (m)  de mãos" },
      { ru: "крем против морщин", port: "creme (m)  antirrugas" },
      { ru: "дневной крем", port: "creme (m)  de dia" },
      { ru: "ночной крем", port: "creme (m)  de noite" },
      { ru: "дневной", port: "de dia" },
      { ru: "ночной", port: "da noite" },
      { ru: "тампон", port: "tampão (m)" },
    ],
  },
  {
    name: "Украшения",
    dict: [
      { ru: "драгоценности", port: "joias (f pl)" },
      { ru: "драгоценный (камень)", port: "precioso" },
      { ru: "проба (клеймо)", port: "marca (f)  de contraste" },
      { ru: "кольцо", port: "anel (m)" },
      { ru: "обручальное кольцо", port: "aliança (f)" },
      { ru: "браслет (украшение)", port: "pulseira (f)" },
      { ru: "серьги", port: "brincos (m pl)" },
      { ru: "ожерелье", port: "colar (m)" },
      { ru: "корона", port: "coroa (f)" },
      { ru: "бусы", port: "colar (m)  de contas" },
      { ru: "бриллиант", port: "diamante (m)" },
      { ru: "изумруд", port: "esmeralda (f)" },
      { ru: "рубин", port: "rubi (m)" },
      { ru: "сапфир", port: "safira (f)" },
      { ru: "жемчуг", port: "pérola (f)" },
      { ru: "янтарь", port: "âmbar (m)" },
    ],
  },
  {
    name: "Часы",
    dict: [
      { ru: "часы (наручные)", port: "relógio (m)  de pulso" },
      { ru: "циферблат", port: "mostrador (m)" },
      { ru: "стрелка (часов)", port: "ponteiro (m)" },
      { ru: "браслет (на часах)", port: "bracelete (f)  em aço" },
      { ru: "ремешок", port: "bracelete (f)  em pele" },
      { ru: "батарейка", port: "pilha (f)" },
      { ru: "сесть (о батарейке)", port: "acabar (vi)" },
      { ru: "поменять батарейку", port: "trocar a pilha" },
      { ru: "спешить (о часах)", port: "estar adiantado" },
      { ru: "отставать", port: "estar atrasado" },
      { ru: "часы настенные", port: "relógio (m)  de parede" },
      { ru: "часы песочные", port: "ampulheta (f)" },
      { ru: "часы солнечные", port: "relógio (m)  de sol" },
      { ru: "будильник", port: "despertador (m)" },
      { ru: "часовщик", port: "relojoeiro (m)" },
      { ru: "ремонтировать", port: "reparar (vt)" },
    ],
  },
  {
    name: "Продукты",
    dict: [
      { ru: "мясо", port: "carne (f)" },
      { ru: "курица", port: "galinha (f)" },
      { ru: "цыплёнок", port: "frango (m)" },
      { ru: "утка", port: "pato (m)" },
      { ru: "гусь", port: "ganso (m)" },
      { ru: "дичь", port: "caça (f)" },
      { ru: "индейка", port: "peru (m)" },
      { ru: "свинина", port: "carne (f)  de porco" },
      { ru: "телятина", port: "carne (f)  de vitela" },
      { ru: "баранина", port: "carne (f)  de carneiro" },
      { ru: "говядина", port: "carne (f)  de vaca" },
      { ru: "кролик", port: "carne (f)  de coelho" },
      { ru: "колбаса", port: "chouriço (m)" },
      { ru: "сосиска", port: "salsicha (f)" },
      { ru: "бекон", port: "bacon (m)" },
      { ru: "ветчина", port: "fiambre (f)" },
      { ru: "окорок", port: "presunto (m)" },
      { ru: "паштет", port: "patê (m)" },
      { ru: "печень", port: "iscas (f pl)" },
      { ru: "сало", port: "toucinho (m)" },
      { ru: "фарш", port: "carne (f)  moída" },
      { ru: "язык", port: "língua (f)" },
      { ru: "яйцо", port: "ovo (m)" },
      { ru: "яйца", port: "ovos (m pl)" },
      { ru: "белок", port: "clara (f)  do ovo" },
      { ru: "желток", port: "gema (f)  do ovo" },
      { ru: "рыба", port: "peixe (m)" },
      { ru: "морепродукты", port: "marisco (m)" },
      { ru: "ракообразные", port: "crustáceos (m pl)" },
      { ru: "икра", port: "caviar (m)" },
      { ru: "краб", port: "caranguejo (m)" },
      { ru: "креветка", port: "camarão (m)" },
      { ru: "устрица", port: "ostra (f)" },
      { ru: "лангуст", port: "lagosta (f)" },
      { ru: "осьминог", port: "polvo (m)" },
      { ru: "кальмар", port: "lula (f)" },
      { ru: "осетрина", port: "esturjão (m)" },
      { ru: "лосось", port: "salmão (m)" },
      { ru: "палтус", port: "halibute (m)" },
      { ru: "треска", port: "bacalhau (m)" },
      { ru: "скумбрия", port: "cavala (m), sarda (f)" },
      { ru: "тунец", port: "atum (m)" },
      { ru: "угорь", port: "enguia (f)" },
      { ru: "форель", port: "truta (f)" },
      { ru: "сардина", port: "sardinha (f)" },
      { ru: "щука", port: "lúcio (m)" },
      { ru: "сельдь", port: "arenque (m)" },
      { ru: "хлеб", port: "pão (m)" },
      { ru: "сыр", port: "queijo (m)" },
      { ru: "сахар", port: "açúcar (m)" },
      { ru: "соль", port: "sal (m)" },
      { ru: "рис", port: "arroz (m)" },
      { ru: "макароны", port: "massas (f pl)" },
      { ru: "лапша", port: "talharim (m)" },
      { ru: "сливочное масло", port: "manteiga (f)" },
      { ru: "масло растительное", port: "óleo (m)" },
      { ru: "подсолнечное масло", port: "óleo (m)  de girassol" },
      { ru: "маргарин", port: "margarina (f)" },
      { ru: "оливки", port: "azeitonas (f pl)" },
      { ru: "масло оливковое", port: "azeite (m)" },
      { ru: "молоко", port: "leite (m)" },
      { ru: "сгущённое молоко", port: "leite (m)  condensado" },
      { ru: "йогурт", port: "iogurte (m)" },
      { ru: "сметана", port: "creme (m)  azedo" },
      { ru: "сливки", port: "nata (f)  do leite" },
      { ru: "майонез", port: "maionese (f)" },
      { ru: "крем", port: "creme (m)" },
      { ru: "крупа", port: "grãos (m pl)  de cereais" },
      { ru: "мука", port: "farinha (f)" },
      { ru: "консервы", port: "conservas (f pl)" },
      { ru: "кукурузные хлопья", port: "flocos (m pl)  de milho" },
      { ru: "мёд", port: "mel (m)" },
      { ru: "джем, конфитюр", port: "doce (m)" },
      { ru: "жевательная резинка", port: "pastilha (f)  elástica" },
    ],
  },
  {
    name: "Напитки",
    dict: [
      { ru: "вода", port: "água (f)" },
      { ru: "питьевая вода", port: "água (f)  potável" },
      { ru: "минеральная вода", port: "água (f)  mineral" },
      { ru: "без газа", port: "sem gás" },
      { ru: "газированный", port: "gaseificada" },
      { ru: "с газом", port: "com gás" },
      { ru: "лёд", port: "gelo (m)" },
      { ru: "со льдом", port: "com gelo" },
      { ru: "безалкогольный", port: "sem álcool" },
      { ru: "безалкогольный напиток", port: "bebida (f)  sem álcool" },
      { ru: "прохладительный напиток", port: "refresco (m)" },
      { ru: "лимонад", port: "limonada (f)" },
      { ru: "алкогольные напитки", port: "bebidas (f pl)  alcoólicas" },
      { ru: "вино", port: "vinho (m)" },
      { ru: "белое вино", port: "vinho (m)  branco" },
      { ru: "красное вино", port: "vinho (m)  tinto" },
      { ru: "ликёр", port: "licor (m)" },
      { ru: "шампанское", port: "champanhe (m)" },
      { ru: "вермут", port: "vermute (m)" },
      { ru: "виски", port: "uísque (m)" },
      { ru: "водка", port: "vodca, vodka (f)" },
      { ru: "джин", port: "gim (m)" },
      { ru: "коньяк", port: "conhaque (m)" },
      { ru: "ром", port: "rum (m)" },
      { ru: "кофе", port: "café (m)" },
      { ru: "чёрный кофе", port: "café (m)  puro" },
      { ru: "кофе с молоком", port: "café (m)  com leite" },
      { ru: "капучино", port: "cappuccino (m)" },
      { ru: "растворимый кофе", port: "café (m)  solúvel" },
      { ru: "молоко", port: "leite (m)" },
      { ru: "коктейль", port: "coquetel (m)" },
      { ru: "молочный коктейль", port: "batido (m)  de leite" },
      { ru: "сок", port: "sumo (m)" },
      { ru: "томатный сок", port: "sumo (m)  de tomate" },
      { ru: "апельсиновый сок", port: "sumo (m)  de laranja" },
      { ru: "свежевыжатый сок", port: "sumo (m)  fresco" },
      { ru: "пиво", port: "cerveja (f)" },
      { ru: "светлое пиво", port: "cerveja (f)  clara" },
      { ru: "тёмное пиво", port: "cerveja (m)  preta" },
      { ru: "чай", port: "chá (m)" },
      { ru: "чёрный чай", port: "chá (m)  preto" },
      { ru: "зелёный чай", port: "chá (m)  verde" },
    ],
  },
  {
    name: "Овощи",
    dict: [
      { ru: "овощи", port: "legumes (m pl)" },
      { ru: "зелень", port: "verduras (f pl)" },
      { ru: "помидор", port: "tomate (m)" },
      { ru: "огурец", port: "pepino (m)" },
      { ru: "морковь", port: "cenoura (f)" },
      { ru: "картофель", port: "batata (f)" },
      { ru: "лук (луковица)", port: "cebola (f)" },
      { ru: "чеснок", port: "alho (m)" },
      { ru: "капуста", port: "couve (f)" },
      { ru: "цветная капуста", port: "couve-flor (f)" },
      { ru: "брюссельская капуста", port: "couve-de-bruxelas (f)" },
      { ru: "капуста брокколи", port: "brócolos (m pl)" },
      { ru: "свёкла", port: "beterraba (f)" },
      { ru: "баклажан", port: "beringela (f)" },
      { ru: "кабачок", port: "curgete (f)" },
      { ru: "тыква", port: "abóbora (f)" },
      { ru: "репа", port: "nabo (m)" },
      { ru: "петрушка", port: "salsa (f)" },
      { ru: "укроп", port: "funcho, endro (m)" },
      { ru: "салат (латук)", port: "alface (f)" },
      { ru: "сельдерей", port: "aipo (m)" },
      { ru: "спаржа", port: "espargo (m)" },
      { ru: "шпинат", port: "espinafre (m)" },
      { ru: "горох", port: "ervilha (f)" },
      { ru: "бобы", port: "fava (f)" },
      { ru: "кукуруза", port: "milho (m)" },
      { ru: "фасоль", port: "feijão (m)" },
      { ru: "перец", port: "pimentão (m)" },
      { ru: "редис", port: "rabanete (m)" },
      { ru: "артишок", port: "alcachofra (f)" },
    ],
  },
  {
    name: "Фрукты, орехи",
    dict: [
      { ru: "фрукт", port: "fruta (f)" },
      { ru: "яблоко", port: "maçã (f)" },
      { ru: "груша", port: "pera (f)" },
      { ru: "лимон", port: "limão (m)" },
      { ru: "апельсин", port: "laranja (f)" },
      { ru: "клубника", port: "morango (m)" },
      { ru: "мандарин", port: "tangerina (f)" },
      { ru: "слива", port: "ameixa (f)" },
      { ru: "персик", port: "pêssego (m)" },
      { ru: "абрикос", port: "damasco (m)" },
      { ru: "малина", port: "framboesa (f)" },
      { ru: "ананас", port: "ananás (m)" },
      { ru: "банан", port: "banana (f)" },
      { ru: "арбуз", port: "melancia (f)" },
      { ru: "виноград", port: "uva (f)" },
      { ru: "вишня", port: "ginja (f)" },
      { ru: "черешня", port: "cereja (f)" },
      { ru: "дыня", port: "meloa (f), melão (m)" },
      { ru: "грейпфрут", port: "toranja (f)" },
      { ru: "авокадо", port: "abacate (m)" },
      { ru: "папайя", port: "papaia (f), mamão (m)" },
      { ru: "манго", port: "manga (f)" },
      { ru: "гранат", port: "romã (f)" },
      { ru: "красная смородина", port: "groselha (f)  vermelha" },
      { ru: "чёрная смородина", port: "groselha (f)  preta" },
      { ru: "крыжовник", port: "groselha (f)  espinhosa" },
      { ru: "черника", port: "mirtilo (m)" },
      { ru: "ежевика", port: "amora silvestre (f)" },
      { ru: "изюм", port: "uvas (f pl)  passas" },
      { ru: "инжир", port: "figo (m)" },
      { ru: "финик", port: "tâmara (f)" },
      { ru: "арахис", port: "amendoim (m)" },
      { ru: "миндаль", port: "amêndoa (f)" },
      { ru: "орех (грецкий)", port: "noz (f)" },
      { ru: "орех (лесной)", port: "avelã (f)" },
      { ru: "орех кокосовый", port: "coco (m)" },
      { ru: "фисташки", port: "pistáchios (m pl)" },
    ],
  },
  {
    name: "Сладости, хлеб",
    dict: [
      { ru: "кондитерские изделия", port: "pastelaria (f)" },
      { ru: "хлеб", port: "pão (m)" },
      { ru: "печенье", port: "bolacha (f)" },
      { ru: "шоколад", port: "chocolate (m)" },
      { ru: "шоколадный", port: "de chocolate" },
      { ru: "конфета", port: "rebuçado (m)" },
      { ru: "пирожное", port: "bolo (m)" },
      { ru: "торт", port: "bolo (m)  de aniversário" },
      { ru: "пирог", port: "tarte (f)" },
      { ru: "начинка", port: "recheio (m)" },
      { ru: "варенье", port: "doce (m)" },
      { ru: "мармелад", port: "geleia (f)  de frutas" },
    ],
  },
  {
    name: "Блюда",
    dict: [
      { ru: "блюдо", port: "prato (m)" },
      { ru: "кухня (национальная)", port: "cozinha (f)" },
      { ru: "рецепт (кулинарн.)", port: "receita (f)" },
      { ru: "порция", port: "porção (f)" },
      { ru: "салат", port: "salada (f)" },
      { ru: "суп", port: "sopa (f)" },
      { ru: "бульон", port: "caldo (m)" },
      { ru: "бутерброд", port: "sandes (f)" },
      { ru: "яичница", port: "ovos (m pl)  estrelados" },
      { ru: "котлета", port: "croquete (m)" },
      { ru: "гамбургер", port: "hambúrguer (m)" },
      { ru: "бифштекс", port: "bife (m)" },
      { ru: "жаркое", port: "guisado (m)" },
      { ru: "гарнир", port: "conduto (m)" },
      { ru: "спагетти", port: "espaguete (m)" },
      { ru: "картофельное пюре", port: "puré (m)  de batata" },
      { ru: "пицца", port: "pizza (f)" },
      { ru: "каша", port: "papa (f)" },
      { ru: "омлет", port: "omelete (f)" },
      { ru: "варёный", port: "cozido" },
      { ru: "копчёный", port: "fumado" },
      { ru: "жареный (на сковороде)", port: "frito" },
      { ru: "сушёный", port: "seco" },
      { ru: "замороженный", port: "congelado" },
      { ru: "маринованный", port: "em vinagre" },
      { ru: "сладкий (о чае, кофе)", port: "doce, açucarado" },
      { ru: "солёный", port: "salgado" },
      { ru: "холодный", port: "frio" },
      { ru: "горячий", port: "quente" },
      { ru: "горький", port: "amargo" },
      { ru: "вкусный", port: "gostoso" },
      { ru: "варить (готовить)", port: "cozinhar em água a ferver" },
      { ru: "готовить (ужин)", port: "fazer, preparar (vt)" },
      { ru: "жарить", port: "fritar (vt)" },
      { ru: "разогревать", port: "aquecer (vt)" },
      { ru: "солить", port: "salgar (vt)" },
      { ru: "перчить", port: "apimentar (vt)" },
      { ru: "тереть (на тёрке)", port: "ralar (vt)" },
      { ru: "кожура", port: "casca (f)" },
      { ru: "чистить (картошку и т.п.)", port: "descascar (vt)" },
    ],
  },
  {
    name: "Приправы.Специи",
    dict: [
      { ru: "соль", port: "sal (m)" },
      { ru: "солёный", port: "salgado" },
      { ru: "солить", port: "salgar (vt)" },
      { ru: "чёрный перец", port: "pimenta (f)  preta" },
      { ru: "красный перец", port: "pimenta (f)  vermelha" },
      { ru: "горчица", port: "mostarda (f)" },
      { ru: "хрен", port: "raiz-forte (f)" },
      { ru: "приправа", port: "condimento (m)" },
      { ru: "пряность", port: "especiaria (f)" },
      { ru: "соус", port: "molho (m)" },
      { ru: "уксус", port: "vinagre (m)" },
      { ru: "анис", port: "anis (m)" },
      { ru: "базилик", port: "manjericão (m)" },
      { ru: "гвоздика", port: "cravo (m)" },
      { ru: "имбирь", port: "gengibre (m)" },
      { ru: "кориандр", port: "coentro (m)" },
      { ru: "корица", port: "canela (f)" },
      { ru: "кунжут", port: "sésamo (m)" },
      { ru: "лавровый лист", port: "folhas (f pl)  de louro" },
      { ru: "паприка", port: "páprica (f)" },
      { ru: "тмин", port: "cominho (m)" },
      { ru: "шафран", port: "açafrão (m)" },
    ],
  },
  {
    name: "Прием пищи",
    dict: [
      { ru: "еда", port: "comida (f)" },
      { ru: "есть, кушать", port: "comer (vt)" },
      { ru: "завтрак", port: "pequeno-almoço (m)" },
      { ru: "завтракать", port: "tomar o pequeno-almoço" },
      { ru: "обед", port: "almoço (m)" },
      { ru: "обедать", port: "almoçar (vi)" },
      { ru: "ужин", port: "jantar (m)" },
      { ru: "ужинать", port: "jantar (vi)" },
      { ru: "аппетит", port: "apetite (m)" },
      { ru: "Приятного аппетита!", port: "Bom apetite!" },
      { ru: "открывать (банку и т.п.)", port: "abrir (vt)" },
      { ru: "пролить", port: "derramar (vt)" },
      { ru: "пролиться", port: "derramar-se (vp)" },
      { ru: "кипеть", port: "estar a ferver" },
      { ru: "кипятить", port: "ferver (vt)" },
      { ru: "кипячёный", port: "fervido" },
      { ru: "охладить", port: "arrefecer (vt)" },
      { ru: "охлаждаться", port: "arrefecer-se (vp)" },
      { ru: "вкус", port: "sabor, gosto (m)" },
      { ru: "привкус", port: "gostinho (m)" },
      { ru: "худеть (быть на диете)", port: "fazer dieta" },
      { ru: "диета", port: "dieta (f)" },
      { ru: "витамин", port: "vitamina (f)" },
      { ru: "калория", port: "caloria (f)" },
      { ru: "вегетарианец", port: "vegetariano (m)" },
      { ru: "вегетарианский", port: "vegetariano" },
      { ru: "жиры", port: "gorduras (f pl)" },
      { ru: "белки", port: "proteínas (f pl)" },
      { ru: "углеводы", port: "hidratos (m pl)  de carbono" },
      { ru: "ломтик", port: "fatia (f)" },
      { ru: "кусок", port: "bocado, pedaço (m)" },
      { ru: "крошка (хлеба и т.п.)", port: "migalha (f)" },
    ],
  },
  {
    name: "Сервировка стола",
    dict: [
      { ru: "ложка", port: "colher (f)" },
      { ru: "нож", port: "faca (f)" },
      { ru: "вилка", port: "garfo (m)" },
      { ru: "чашка", port: "chávena (f)" },
      { ru: "тарелка", port: "prato (m)" },
      { ru: "блюдце", port: "pires (m)" },
      { ru: "салфетка", port: "guardanapo (m)" },
      { ru: "зубочистка", port: "palito (m)" },
    ],
  },
  {
    name: "Ресторан",
    dict: [
      { ru: "ресторан", port: "restaurante (m)" },
      { ru: "кофейня", port: "café (m)" },
      { ru: "бар", port: "bar (m)" },
      { ru: "чайный салон", port: "salão (m)  de chá" },
      { ru: "официант", port: "empregado (m)  de mesa" },
      { ru: "официантка", port: "empregada (f)  de mesa" },
      { ru: "бармен", port: "barman (m)" },
      { ru: "меню", port: "ementa (f)" },
      { ru: "карта вин", port: "lista (f)  de vinhos" },
      { ru: "забронировать столик", port: "reservar uma mesa" },
      { ru: "блюдо (кушанье)", port: "prato (m)" },
      { ru: "заказать (блюдо)", port: "pedir (vt)" },
      { ru: "сделать заказ", port: "pedir (vi)" },
      { ru: "аперитив", port: "aperitivo (m)" },
      { ru: "закуска", port: "entrada (f)" },
      { ru: "десерт", port: "sobremesa (f)" },
      { ru: "счёт", port: "conta (f)" },
      { ru: "оплатить счёт", port: "pagar a conta" },
      { ru: "дать сдачу", port: "dar o troco" },
      { ru: "чаевые", port: "gorjeta (f)" },
    ],
  },
  {
    name: "Анкета. Анкетные данные",
    dict: [
      { ru: "имя", port: "nome (m)" },
      { ru: "фамилия", port: "apelido (m)" },
      { ru: "дата рождения", port: "data (f)  de nascimento" },
      { ru: "место рождения", port: "local (m)  de nascimento" },
      { ru: "национальность", port: "nacionalidade (f)" },
      { ru: "место жительства", port: "lugar (m)  de residência" },
      { ru: "страна", port: "país (m)" },
      { ru: "профессия", port: "profissão (f)" },
      { ru: "пол (муж. или жен.)", port: "sexo (m)" },
      { ru: "рост", port: "estatura (f)" },
      { ru: "вес", port: "peso (m)" },
    ],
  },
  {
    name: "Семья, родственники",
    dict: [
      { ru: "мать", port: " mãe (f)" },
      { ru: "отец", port: "pai (m)" },
      { ru: "сын", port: "filho (m)" },
      { ru: "дочь", port: "filha (f)  " },
      { ru: "брат", port: " irmão (m)" },
      { ru: "сестра", port: "irmã (f)" },
      { ru: "двоюродный брат     ", port: "primo (m) " },
      { ru: "двоюродная сестра   ", port: "prima (f) " },
      { ru: "мама", port: "mamã (f)" },
      { ru: "папа", port: "papá (m)      " },
      { ru: "родители   ", port: "pais (pl)" },
      { ru: "ребёнок ", port: "criança (f) " },
      { ru: "дети          ", port: "crianças (f pl) " },
      { ru: "дедушка", port: "avô (m)" },
      { ru: "внук", port: "neto (m)" },
      { ru: "внучка", port: "neta (f)" },
      { ru: "внуки", port: "netos (pl)" },
      { ru: "дядя", port: "tio (m)" },
      { ru: "тётя", port: "tia (f)" },
      { ru: "племянник", port: "sobrinho (m)" },
      { ru: "племянница", port: "sobrinha (f)" },
      { ru: "тёща", port: "sogra (f)" },
      { ru: "свёкор", port: "sogro (m)" },
      { ru: "зять", port: "genro (m)" },
      { ru: "мачеха", port: "madrasta (f)" },
      { ru: "отчим", port: "padrasto (m)" },
      { ru: "грудной ребёнок", port: "criança (f)  de colo" },
      { ru: "младенец", port: "bebé (m)" },
      { ru: "малыш", port: "menino (m)" },
      { ru: "жена", port: "mulher (f)" },
      { ru: "муж", port: "marido (m)" },
      { ru: "супруг", port: "esposo (m)" },
      { ru: "супруга", port: "esposa (f)" },
      { ru: "женатый", port: "casado" },
      { ru: "замужняя", port: "casada" },
      { ru: "холостой (прил.)", port: "solteiro" },
      { ru: "холостяк", port: "solteirão (m)" },
      { ru: "разведённый", port: "divorciado" },
      { ru: "вдова", port: "viúva (f)" },
      { ru: "вдовец", port: "viúvo (m)" },
      { ru: "родственник", port: "parente (m)" },
      { ru: "близкий родственник", port: "parente (m)  próximo" },
      { ru: "дальний родственник", port: "parente (m)  distante" },
      { ru: "родные (родственники)", port: "parentes (m pl)" },
      { ru: "сирота (мальчик)", port: "órfão (m)" },
      { ru: "сирота (девочка)", port: "órfã (f)" },
      { ru: "опекун", port: "tutor (m)" },
      { ru: "усыновить", port: "adotar (vt)" },
      { ru: "удочерить", port: "adotar (vt)" },
    ],
  },
  {
    name: "Друзья, знакомые, коллеги",
    dict: [
      { ru: "друг", port: "amigo (m)" },
      { ru: "подруга", port: "amiga (f)" },
      { ru: "дружба", port: "amizade (f)" },
      { ru: "дружить", port: "ser amigos" },
      { ru: "приятель", port: "amigo (m)" },
      { ru: "приятельница", port: "amiga (f)" },
      { ru: "партнёр", port: "parceiro (m)" },
      { ru: "шеф", port: "chefe (m)" },
      { ru: "начальник", port: "superior (m)" },
      { ru: "владелец", port: "proprietário (m)" },
      { ru: "подчинённый (сущ.)", port: "subordinado (m)" },
      { ru: "коллега", port: "colega (m)" },
      { ru: "знакомый (сущ.)", port: "conhecido (m)" },
      { ru: "попутчик", port: "companheiro (m)  de viagem" },
      { ru: "одноклассник", port: "colega (m)  de classe" },
      { ru: "сосед", port: "vizinho (m)" },
      { ru: "соседка", port: "vizinha (f)" },
      { ru: "соседи", port: "vizinhos (pl)" },
    ],
  },
  {
    name: "Женщина, мужчина",
    dict: [
      { ru: "женщина", port: "mulher (f)" },
      { ru: "девушка", port: "rapariga (f)" },
      { ru: "невеста", port: "noiva (f)" },
      { ru: "красивая", port: "bonita" },
      { ru: "высокая", port: "alta" },
      { ru: "стройная", port: "esbelta" },
      { ru: "невысокого роста", port: "de estatura média" },
      { ru: "блондинка", port: "loura (f)" },
      { ru: "брюнетка", port: "morena (f)" },
      { ru: "дамский", port: "de senhora" },
      { ru: "девственница", port: "virgem (f)" },
      { ru: "беременная", port: "grávida" },
      { ru: "мужчина", port: "homem (m)" },
      { ru: "блондин", port: "louro (m)" },
      { ru: "брюнет", port: "moreno (m)" },
      { ru: "высокий", port: "alto" },
      { ru: "невысокого роста", port: "de estatura média" },
      { ru: "грубый", port: "rude" },
      { ru: "коренастый", port: "atarracado" },
      { ru: "крепкий", port: "robusto" },
      { ru: "сильный", port: "forte" },
      { ru: "сила (человека)", port: "força (f)" },
      { ru: "полный (толстый)", port: "gordo" },
      { ru: "смуглый", port: "moreno" },
      { ru: "стройный", port: "esbelto" },
      { ru: "элегантный", port: "elegante" },
    ],
  },
  {
    name: "Возраст",
    dict: [
      { ru: "возраст", port: "idade (f)" },
      { ru: "юность", port: "juventude (f)" },
      { ru: "молодой", port: "jovem" },
      { ru: "младше", port: "mais novo" },
      { ru: "старше", port: "mais velho" },
      { ru: "юноша", port: "jovem (m)" },
      { ru: "подросток", port: "adolescente (m)" },
      { ru: "парень", port: "rapaz (m)" },
      { ru: "старик", port: "velhote (m)" },
      { ru: "старая женщина", port: "velhota (f)" },
      { ru: "взрослый", port: "adulto" },
      { ru: "средних лет", port: "de meia-idade" },
      { ru: "пожилой", port: "de certa idade" },
      { ru: "старый", port: "idoso" },
      { ru: "пенсия", port: "reforma (f)" },
      { ru: "уйти на пенсию", port: "reformar-se (vp)" },
      { ru: "пенсионер", port: "reformado (m)" },
    ],
  },
  {
    name: "Ребенок",
    dict: [
      { ru: "ребёнок", port: "criança (f)" },
      { ru: "дети", port: "crianças (f pl)" },
      { ru: "близнецы", port: "gémeos (m pl)" },
      { ru: "люлька, колыбель", port: "berço (m)" },
      { ru: "погремушка", port: "guizo (m)" },
      { ru: "подгузник", port: "fralda (f)" },
      { ru: "соска", port: "chucha (f)" },
      { ru: "коляска (детская)", port: "carrinho (m)  de bebé" },
      { ru: "детский сад", port: "jardim (m)  de infância" },
      { ru: "няня", port: "babysitter (f)" },
      { ru: "детство", port: "infância (f)" },
      { ru: "кукла", port: "boneca (f)" },
      { ru: "игрушка", port: "brinquedo (m)" },
      { ru: "конструктор (игра)", port: "jogo (m)  de armar" },
      { ru: "воспитанный", port: "bem-educado" },
      { ru: "невоспитанный", port: "mal-educado" },
      { ru: "избалованный", port: "mimado" },
      { ru: "шалить", port: "ser travesso" },
      { ru: "шаловливый", port: "travesso, traquinas" },
    ],
  },
  {
    name: "Романтические отношения",
    dict: [
      { ru: "целовать", port: "beijar (vt)" },
      { ru: "целоваться", port: "beijar-se (vp)" },
      { ru: "семья", port: "família (f)" },
      { ru: "семейный", port: "familiar" },
      { ru: "пара, чета", port: "casal (m)" },
      { ru: "брак (семейная жизнь)", port: "matrimónio (m)" },
      { ru: "домашний очаг", port: "lar (m)" },
      { ru: "династия", port: "dinastia (f)" },
      { ru: "свидание", port: "encontro (m)" },
      { ru: "поцелуй", port: "beijo (m)" },
      { ru: "любовь", port: "amor (m)" },
      { ru: "любить (кого-л.)", port: "amar (vt)" },
      { ru: "любимый (человек)", port: "amado, querido" },
      { ru: "нежность", port: "ternura (f)" },
      { ru: "нежный", port: "terno, afetuoso" },
      { ru: "верность", port: "fidelidade (f)" },
      { ru: "верный", port: "fiel" },
      { ru: "забота (о человеке)", port: "cuidado (m)" },
      { ru: "заботливый", port: "carinhoso" },
      { ru: "молодожёны", port: "recém-casados (m pl)" },
      { ru: "медовый месяц", port: "lua de mel (f)" },
      { ru: "выйти замуж", port: "casar-se (vp)" },
      { ru: "жениться", port: "casar-se (vp)" },
      { ru: "свадьба", port: "boda (f)" },
      { ru: "золотая свадьба", port: "bodas (f pl)  de ouro" },
      { ru: "годовщина", port: "aniversário (m)" },
      { ru: "любовник", port: "amante (m)" },
      { ru: "любовница", port: "amante (f)" },
      { ru: "измена", port: "adultério (m)" },
      { ru: "изменить", port: "cometer adultério" },
      { ru: "ревнивый", port: "ciumento" },
      { ru: "ревновать", port: "ser ciumento" },
    ],
  },

  {
    name: "Состояние человека",
    dict: [
      { ru: "чувство", port: "sentimento (m)" },
      { ru: "чувства", port: "sentimentos (m pl)" },
      { ru: "чувствовать", port: "sentir (vt)" },
      { ru: "голод", port: "fome (f)" },
      { ru: "хотеть есть", port: "ter fome" },
      { ru: "жажда", port: "sede (f)" },
      { ru: "хотеть пить", port: "ter sede" },
      { ru: "сонливость", port: "sonolência (f)" },
      { ru: "хотеть спать", port: "ter sono" },
      { ru: "усталость", port: "cansaço (m)" },
      { ru: "усталый", port: "cansado" },
      { ru: "устать (быть усталым)", port: "estar cansado" },
      { ru: "настроение", port: "humor (m)" },
      { ru: "скука", port: "tédio (m)" },
      { ru: "скучать", port: "aborrecer-se (vp)" },
      { ru: "уединение", port: "isolamento (m)" },
      { ru: "уединиться", port: "isolar-se" },
      { ru: "беспокоить (волновать)", port: "preocupar (vt)" },
      { ru: "беспокоиться", port: "preocupar-se (vp)" },
      { ru: "беспокойство", port: "preocupação (f)" },
      { ru: "тревога", port: "ansiedade (f)" },
      { ru: "озабоченный", port: "preocupado" },
      { ru: "нервничать", port: "estar nervoso" },
      { ru: "паниковать", port: "entrar em pânico" },
      { ru: "надежда", port: "esperança (f)" },
      { ru: "надеяться", port: "esperar (vt)" },
      { ru: "уверенность", port: "certeza (f)" },
      { ru: "уверенный", port: "certo" },
      { ru: "неуверенность", port: "indecisão (f)" },
      { ru: "неуверенный", port: "indeciso" },
      { ru: "пьяный", port: "ébrio, bêbado" },
      { ru: "трезвый", port: "sóbrio" },
      { ru: "слабый", port: "fraco" },
      { ru: "счастливый", port: "feliz" },
      { ru: "испугать", port: "assustar (vt)" },
      { ru: "бешенство", port: "fúria (f)" },
      { ru: "ярость", port: "ira, raiva (f)" },
      { ru: "депрессия", port: "depressão (f)" },
      { ru: "дискомфорт", port: "desconforto (m)" },
      { ru: "комфорт", port: "conforto (m)" },
      { ru: "сожалеть", port: "lamentar (vt)" },
      { ru: "сожаление", port: "lamento (m)" },
      { ru: "невезение", port: "azar (m), má sorte (f)" },
      { ru: "огорчение", port: "tristeza (f)" },
      { ru: "стыд", port: "vergonha (f)" },
      { ru: "веселье (радость)", port: "alegria (f)" },
      { ru: "энтузиазм", port: "entusiasmo (m)" },
      { ru: "энтузиаст", port: "entusiasta (m)" },
      { ru: "проявить энтузиазм", port: "mostrar entusiasmo" },
    ],
  },
  {
    name: "Черты характра, личность",
    dict: [
      { ru: "характер", port: "caráter (m)" },
      { ru: "недостаток (характера)", port: "falha (f)  de caráter" },
      { ru: "ум", port: "mente (f)" },
      { ru: "разум", port: "razão (f)" },
      { ru: "совесть", port: "consciência (f)" },
      { ru: "привычка", port: "hábito (m)" },
      { ru: "способность (к чему-л.)", port: "habilidade (f)" },
      { ru: "уметь", port: "saber" },
      { ru: "терпеливый", port: "paciente" },
      { ru: "нетерпеливый", port: "impaciente" },
      { ru: "любопытный", port: "curioso" },
      { ru: "любопытство", port: "curiosidade (f)" },
      { ru: "скромность", port: "modéstia (f)" },
      { ru: "скромный", port: "modesto" },
      { ru: "нескромный", port: "imodesto" },
      { ru: "лень", port: "preguiça (f)" },
      { ru: "ленивый", port: "preguiçoso" },
      { ru: "лентяй", port: "preguiçoso (m)" },
      { ru: "хитрость", port: "astúcia (f)" },
      { ru: "хитрый", port: "astuto" },
      { ru: "недоверие", port: "desconfiança (f)" },
      { ru: "недоверчивый", port: "desconfiado" },
      { ru: "щедрость", port: "generosidade (f)" },
      { ru: "щедрый", port: "generoso" },
      { ru: "талантливый", port: "talentoso" },
      { ru: "талант", port: "talento (m)" },
      { ru: "смелый", port: "corajoso" },
      { ru: "смелость", port: "coragem (f)" },
      { ru: "честный", port: "honesto" },
      { ru: "честность", port: "honestidade (f)" },
      { ru: "осторожный", port: "prudente" },
      { ru: "отважный", port: "valente" },
      { ru: "серьёзный", port: "sério" },
      { ru: "строгий", port: "severo" },
      { ru: "решительный", port: "decidido" },
      { ru: "нерешительный", port: "indeciso" },
      { ru: "робкий", port: "tímido" },
      { ru: "робость", port: "timidez (f)" },
      { ru: "доверие", port: "confiança (f)" },
      { ru: "верить (доверять)", port: "confiar (vt)" },
      { ru: "доверчивый", port: "crédulo" },
      { ru: "искренне", port: "sinceramente" },
      { ru: "искренний", port: "sincero" },
      { ru: "искренность", port: "sinceridade (f)" },
      { ru: "тихий (спокойный)", port: "calmo" },
      { ru: "откровенный", port: "franco" },
      { ru: "открытый (человек)", port: "aberto" },
      { ru: "наивный", port: "ingénuo" },
      { ru: "рассеянный", port: "distraído" },
      { ru: "смешной (забавный)", port: "engraçado" },
      { ru: "жадность (скупость)", port: "ganância (f)" },
      { ru: "жадный (скупой)", port: "ganacioso" },
      { ru: "скупой", port: "avarento" },
      { ru: "злой", port: "mau" },
      { ru: "упрямый", port: "teimoso" },
      { ru: "неприятный", port: "desagradável" },
      { ru: "эгоист", port: "egoísta (m)" },
      { ru: "эгоистичный", port: "egoísta" },
      { ru: "трус", port: "cobarde (m)" },
      { ru: "трусливый", port: "cobarde" },
    ],
  },
  {
    name: "Сон",
    dict: [
      { ru: "спать", port: "dormir (vi)" },
      { ru: "сон (состояние)", port: "sono (m)" },
      { ru: "сон (сновидения)", port: "sonho (m)" },
      { ru: "видеть сны", port: "sonhar (vi)" },
      { ru: "сонный", port: "sonolento" },
      { ru: "кровать", port: "cama (f)" },
      { ru: "матрас", port: "colchão (m)" },
      { ru: "одеяло", port: "cobertor (m)" },
      { ru: "подушка", port: "almofada (f)" },
      { ru: "простыня", port: "lençol (m)" },
      { ru: "бессонница", port: "insónia (f)" },
      { ru: "бессонный", port: "insone" },
      { ru: "снотворное", port: "sonífero (m)" },
      { ru: "принять снотворное", port: "tomar um sonífero" },
      { ru: "хотеть спать", port: "estar sonolento" },
      { ru: "зевать", port: "bocejar (vi)" },
      { ru: "идти спать", port: "ir para a cama" },
      { ru: "стелить постель", port: "fazer a cama" },
      { ru: "заснуть", port: "adormecer (vi)" },
      { ru: "кошмар", port: "pesadelo (m)" },
      { ru: "храп", port: "ronco (m)" },
      { ru: "храпеть", port: "ressonar (vi)" },
      { ru: "будильник", port: "despertador (m)" },
      { ru: "разбудить", port: "acordar, despertar (vt)" },
      { ru: "просыпаться", port: "acordar (vi)" },
      { ru: "подниматься (утром)", port: "levantar-se (vp)" },
      { ru: "умываться", port: "lavar-se (vp)" },
    ],
  },
  {
    name: "Юмор, смех, радость",
    dict: [
      { ru: "юмор", port: "humor (m)" },
      { ru: "чувство (юмора)", port: "sentido (m)  de humor" },
      { ru: "веселиться", port: "divertir-se (vp)" },
      { ru: "весёлый", port: "alegre" },
      { ru: "веселье", port: "alegria (f)" },
      { ru: "улыбка", port: "sorriso (m)" },
      { ru: "улыбаться", port: "sorrir (vi)" },
      { ru: "засмеяться", port: "começar a rir" },
      { ru: "смеяться", port: "rir (vi)" },
      { ru: "смех", port: "riso (m)" },
      { ru: "анекдот", port: "anedota (f)" },
      { ru: "смешной (анекдот)", port: "engraçado" },
      { ru: "смешной (человек)", port: "ridículo" },
      { ru: "шутить", port: "fazer piadas" },
      { ru: "шутка", port: "piada (f)" },
      { ru: "радость (при встрече)", port: "alegria (f)" },
      { ru: "радоваться", port: "regozijar-se (vp)" },
      { ru: "радостный", port: "alegre" },
    ],
  },
  {
    name: "Общение, диалог - 1",
    dict: [
      { ru: "общение", port: "comunicação (f)" },
      { ru: "общаться", port: "comunicar-se (vp)" },
      { ru: "разговор", port: "conversa (f)" },
      { ru: "диалог", port: "diálogo (m)" },
      { ru: "дискуссия", port: "discussão (f)" },
      { ru: "спор (дебаты)", port: "debate (m)" },
      { ru: "спорить", port: "debater (vt)" },
      { ru: "собеседник", port: "interlocutor (m)" },
      { ru: "тема", port: "tema (m)" },
      { ru: "точка зрения", port: "ponto (m)  de vista" },
      { ru: "мнение", port: "opinião (f)" },
      { ru: "речь (выступление)", port: "discurso (m)" },
      { ru: "обсуждение", port: "discussão (f)" },
      { ru: "обсуждать", port: "discutir (vt)" },
      { ru: "беседа", port: "conversa (f)" },
      { ru: "беседовать", port: "conversar (vi)" },
      { ru: "встреча", port: "encontro (m)" },
      { ru: "встречаться", port: "encontrar-se (vp)" },
      { ru: "пословица", port: "provérbio (m)" },
      { ru: "поговорка", port: "ditado (m)" },
      { ru: "загадка", port: "adivinha (f)" },
      { ru: "загадывать загадку", port: "dizer uma adivinha" },
      { ru: "пароль", port: "senha (f)" },
      { ru: "секрет", port: "segredo (m)" },
      { ru: "клятва", port: "juramento (m)" },
      { ru: "клясться", port: "jurar (vi)" },
      { ru: "обещание", port: "promessa (f)" },
      { ru: "обещать", port: "prometer (vt)" },
      { ru: "совет", port: "conselho (m)" },
      { ru: "советовать", port: "aconselhar (vt)" },
      { ru: "следовать совету", port: "seguir o conselho" },
      { ru: "слушаться (совета)", port: "escutar (vt)" },
      { ru: "новость", port: "novidade (f)" },
      { ru: "сенсация", port: "sensação (f)" },
      { ru: "сведения", port: "informação (f)" },
      { ru: "вывод (заключение)", port: "conclusão (f)" },
      { ru: "голос", port: "voz (f)" },
      { ru: "комплимент", port: "elogio (m)" },
      { ru: "любезный", port: "amável" },
      { ru: "слово", port: "palavra (f)" },
      { ru: "фраза", port: "frase (f)" },
      { ru: "ответ (на вопрос)", port: "resposta (f)" },
      { ru: "правда", port: "verdade (f)" },
      { ru: "ложь", port: "mentira (f)" },
      { ru: "мысль", port: "pensamento (m)" },
      { ru: "мысль (идея)", port: "ideia (f)" },
      { ru: "фантазия (выдумка)", port: "fantasia (f)" },
    ],
  },
  {
    name: "Общение, диалог - 2",
    dict: [
      { ru: "уважаемый", port: "estimado" },
      { ru: "уважать", port: "respeitar (vt)" },
      { ru: "уважение", port: "respeito (m)" },
      { ru: "Уважаемый … (в письме)", port: "Estimado … , Caro …" },
      { ru: "познакомить", port: "apresentar (vt)" },
      { ru: "познакомиться (с кем-л.)", port: "conhecer (vt)" },
      { ru: "намерение", port: "intenção (f)" },
      { ru: "намереваться", port: "tencionar (vt)" },
      { ru: "пожелание", port: "desejo (m)" },
      { ru: "пожелать", port: "desejar (vt)" },
      { ru: "удивление", port: "surpresa (f)" },
      { ru: "удивлять", port: "surpreender (vt)" },
      { ru: "удивляться", port: "surpreender-se (vp)" },
      { ru: "дать", port: "dar (vt)" },
      { ru: "взять", port: "pegar (vt)" },
      { ru: "вернуть", port: "devolver (vt)" },
      { ru: "отдать (вернуть)", port: "devolver (vt)" },
      { ru: "извиняться", port: "desculpar-se (vp)" },
      { ru: "извинение", port: "desculpa (f)" },
      { ru: "прощать", port: "perdoar (vt)" },
      { ru: "разговаривать", port: "falar (vi)" },
      { ru: "слушать", port: "escutar (vt)" },
      { ru: "выслушать", port: "ouvir até o fim" },
      { ru: "понять", port: "compreender (vt)" },
      { ru: "показать", port: "mostrar (vt)" },
      { ru: "глядеть на …", port: "olhar para …" },
      { ru: "позвать", port: "chamar (vt)" },
      { ru: "беспокоить (отвлечь от дел)", port: "distrair (vt)" },
      { ru: "мешать (беспокоить)", port: "perturbar (vt)" },
      { ru: "передать (подарок и т.п.)", port: "entregar (vt)" },
      { ru: "просьба", port: "pedido (m)" },
      { ru: "просить", port: "pedir (vt)" },
      { ru: "требование", port: "exigência (f)" },
      { ru: "требовать", port: "exigir (vt)" },
      { ru: "дразнить (обзывать)", port: "chamar nomes (vt)" },
    ],
  },
  {
    name: "Общение, диалог - 3",
    dict: [
      { ru: "насмехаться", port: "zombar (vt)" },
      { ru: "насмешка", port: "zombaria (f)" },
      { ru: "прозвище", port: "apelido (m)" },
      { ru: "намёк", port: "alusão (f)" },
      { ru: "намекать", port: "aludir (vt)" },
      { ru: "подразумевать", port: "subentender (vt)" },
      { ru: "описание", port: "descrição (f)" },
      { ru: "описать (обрисовать)", port: "descrever (vt)" },
      { ru: "похвала", port: "elogio (m)" },
      { ru: "похвалить", port: "elogiar (vt)" },
      { ru: "разочарование", port: "desapontamento (m)" },
      { ru: "разочаровать", port: "desapontar (vt)" },
      { ru: "разочароваться", port: "desapontar-se (vp)" },
      { ru: "предположение", port: "suposição (f)" },
      { ru: "предполагать", port: "supor (vt)" },
      { ru: "предостережение", port: "advertência (f)" },
      { ru: "предостеречь", port: "advertir (vt)" },
      { ru: "уговорить", port: "convencer (vt)" },
      { ru: "успокаивать", port: "acalmar (vt)" },
      { ru: "молчание", port: "silêncio (m)" },
      { ru: "молчать", port: "ficar em silêncio" },
      { ru: "шепнуть", port: "sussurrar (vt)" },
      { ru: "шёпот", port: "sussurro (m)" },
      { ru: "откровенно", port: "francamente" },
      { ru: "по моему мнению …", port: "a meu ver …" },
      { ru: "подробность", port: "detalhe (m)" },
      { ru: "подробный", port: "detalhado" },
      { ru: "подробно", port: "detalhadamente" },
      { ru: "подсказка (ключ к отгадке)", port: "dica (f)" },
      { ru: "подсказать (отгадку)", port: "dar uma dica" },
      { ru: "взгляд (выражение лица)", port: "olhar (m)" },
      { ru: "взглянуть", port: "dar uma vista de olhos" },
      { ru: "неподвижный (взгляд)", port: "fixo" },
      { ru: "моргать", port: "piscar (vi)" },
      { ru: "мигнуть", port: "pestanejar (vt)" },
      { ru: "кивнуть (в знак согласия)", port: "acenar (vt)" },
      { ru: "вздох", port: "suspiro (m)" },
      { ru: "вздохнуть", port: "suspirar (vi)" },
      { ru: "вздрагивать", port: "estremecer (vi)" },
      { ru: "жест", port: "gesto (m)" },
      { ru: "прикоснуться", port: "tocar (vt)" },
      { ru: "хватать (за руку и т.п.)", port: "agarrar (vt)" },
      { ru: "хлопать (по плечу и т.п.)", port: "bater de leve" },
      { ru: "Осторожно!", port: "Cuidado!" },
      { ru: "Неужели?", port: "A sério?" },
      { ru: "Ты уверен?", port: "Tens a certeza?" },
      { ru: "Удачи!", port: "Boa sorte!" },
      { ru: "Ясно! (понятно)", port: "Compreendi!" },
      { ru: "Жаль!", port: "Que pena!" },
    ],
  },
  {
    name: "Согласие, несогласие, одобрение",
    dict: [
      { ru: "согласие", port: "consentimento (m)" },
      { ru: "соглашаться", port: "consentir (vi)" },
      { ru: "одобрение", port: "aprovação (f)" },
      { ru: "одобрить", port: "aprovar (vt)" },
      { ru: "отказ", port: "recusa (f)" },
      { ru: "отказываться", port: "negar-se (vt)" },
      { ru: "Отлично!", port: "Está ótimo!" },
      { ru: "Хорошо! (согласен)", port: "Muito bem!" },
      { ru: "Ладно! (согласен)", port: "Está bem!" },
      { ru: "запрещённый", port: "proibido" },
      { ru: "нельзя (запрещено)", port: "é proibido" },
      { ru: "невозможно", port: "é impossível" },
      { ru: "неправильный (ошибочный)", port: "incorreto" },
      { ru: "отклонить (просьбу)", port: "rejeitar (vt)" },
      { ru: "поддержать (предложение)", port: "apoiar (vt)" },
      { ru: "принять (согласиться)", port: "aceitar (vt)" },
      { ru: "подтвердить", port: "confirmar (vt)" },
      { ru: "подтверждение", port: "confirmação (f)" },
      { ru: "разрешение (согласие)", port: "permissão (f)" },
      { ru: "разрешить", port: "permitir (vt)" },
      { ru: "решение", port: "decisão (f)" },
      { ru: "промолчать", port: "não dizer nada" },
      { ru: "условие", port: "condição (f)" },
      { ru: "отговорка", port: "pretexto (m)" },
      { ru: "похвала", port: "elogio (m)" },
      { ru: "похвалить", port: "elogiar (vt)" },
    ],
  },
  {
    name: "Успех, удача, поражение",
    dict: [
      { ru: "успех", port: "êxito, sucesso (m)" },
      { ru: "успешно", port: "com êxito" },
      { ru: "успешный", port: "bem sucedido" },
      { ru: "удача (везение)", port: "sorte (f)" },
      { ru: "Удачи!", port: "Boa sorte!" },
      { ru: "удачный", port: "feliz" },
      { ru: "удачливый (человек)", port: "sortudo, felizardo" },
      { ru: "неудача (провал)", port: "fracasso (m)" },
      { ru: "неудача (невезение)", port: "pouca sorte (f)" },
      { ru: "невезение", port: "azar (m), má sorte (f)" },
      { ru: "неудачный (попытка)", port: "mal sucedido" },
      { ru: "катастрофа (перен.)", port: "catástrofe (f)" },
      { ru: "гордость", port: "orgulho (m)" },
      { ru: "гордый", port: "orgulhoso" },
      { ru: "гордиться", port: "estar orgulhoso" },
      { ru: "победитель", port: "vencedor (m)" },
      { ru: "победить", port: "vencer (vi)" },
      { ru: "проиграть (в спорте)", port: "perder (vt)" },
      { ru: "попытка", port: "tentativa (f)" },
      { ru: "пытаться", port: "tentar (vt)" },
      { ru: "шанс", port: "chance (m)" },
    ],
  },
  {
    name: "Обиды, ссора, негативные эмоции",
    dict: [
      { ru: "крик", port: "grito (m)" },
      { ru: "кричать", port: "gritar (vi)" },
      { ru: "закричать", port: "começar a gritar" },
      { ru: "ссора (перебранка)", port: "discussão (f)" },
      { ru: "ссориться", port: "discutir (vt)" },
      { ru: "скандал (ссора)", port: "escândalo (m)" },
      { ru: "скандалить", port: "criar escândalo" },
      { ru: "конфликт", port: "conflito (m)" },
      { ru: "недоразумение", port: "mal-entendido (m)" },
      { ru: "оскорбление", port: "insulto (m)" },
      { ru: "оскорблять", port: "insultar (vt)" },
      { ru: "оскорбленный", port: "insultado" },
      { ru: "обида", port: "ofensa (f)" },
      { ru: "обидеть", port: "ofender (vt)" },
      { ru: "обидеться", port: "ofender-se (vp)" },
      { ru: "возмущение", port: "indignação (f)" },
      { ru: "возмущаться", port: "indignar-se (vp)" },
      { ru: "жалоба", port: "queixa (f)" },
      { ru: "жаловаться", port: "queixar-se (vp)" },
      { ru: "извинение", port: "desculpa (f)" },
      { ru: "извиняться", port: "desculpar-se (vp)" },
      { ru: "просить прощения", port: "pedir perdão" },
      { ru: "критика", port: "crítica (f)" },
      { ru: "критиковать", port: "criticar (vt)" },
      { ru: "обвинение", port: "acusação (f)" },
      { ru: "обвинять", port: "acusar (vt)" },
      { ru: "месть", port: "vingança (f)" },
      { ru: "мстить", port: "vingar (vt)" },
      { ru: "отплатить (отомстить)", port: "pagar de volta" },
      { ru: "презрение", port: "desprezo (m)" },
      { ru: "презирать", port: "desprezar (vt)" },
      { ru: "ненависть", port: "ódio (m)" },
      { ru: "ненавидеть", port: "odiar (vt)" },
      { ru: "нервный", port: "nervoso" },
      { ru: "нервничать", port: "estar nervoso" },
      { ru: "сердитый", port: "zangado" },
      { ru: "рассердить", port: "zangar (vt)" },
      { ru: "ругаться (на кого-л.)", port: "praguejar" },
      { ru: "унижение", port: "humilhação (f)" },
      { ru: "унижать", port: "humilhar (vt)" },
      { ru: "унижаться", port: "humilhar-se (vp)" },
      { ru: "шок", port: "choque (m)" },
      { ru: "шокировать", port: "chocar (vt)" },
      { ru: "неприятность", port: "aborrecimento (m)" },
      { ru: "неприятный", port: "desagradável" },
      { ru: "страх", port: "medo (m)" },
      { ru: "страшный (мороз и т.п.)", port: "terrível" },
      { ru: "страшный (рассказ)", port: "assustador" },
      { ru: "ужас", port: "horror (m)" },
      { ru: "ужасный", port: "horrível" },
      { ru: "задрожать", port: "começar a tremer" },
      { ru: "плакать", port: "chorar (vi)" },
      { ru: "заплакать", port: "começar a chorar" },
      { ru: "слеза", port: "lágrima (f)" },
      { ru: "вина (ошибка, проступок)", port: "falta (f)" },
      { ru: "вина (чувство вины)", port: "culpa (f)" },
      { ru: "позор", port: "desonra (f)" },
      { ru: "протест", port: "protesto (m)" },
      { ru: "стресс", port: "stress (m)" },
      { ru: "беспокоить (мешать)", port: "perturbar (vt)" },
      { ru: "злиться", port: "zangar-se com …" },
      { ru: "злой (сердитый)", port: "zangado" },
      { ru: "прекращать (отношения)", port: "terminar (vt)" },
      { ru: "пугаться", port: "assustar-se" },
      { ru: "ударить", port: "golpear (vt)" },
      { ru: "драться (в драке)", port: "bater-se (vp)" },
      { ru: "урегулировать (конфликт)", port: "resolver (vt)" },
      { ru: "недовольный", port: "descontente" },
      { ru: "яростный (взгляд)", port: "furioso" },
      { ru: "Это нехорошо!", port: "Não está bem!" },
      { ru: "Это плохо!", port: "É mau!" },
    ],
  },
  {
    name: "Болезни",
    dict: [
      { ru: "болезнь", port: "doença (f)" },
      { ru: "болеть (быть больным)", port: "estar doente" },
      { ru: "здоровье", port: "saúde (f)" },
      { ru: "насморк", port: "nariz (m)  a escorrer" },
      { ru: "ангина", port: "amigdalite (f)" },
      { ru: "простуда", port: "constipação (f)" },
      { ru: "простудиться", port: "constipar-se (vp)" },
      { ru: "бронхит", port: "bronquite (f)" },
      { ru: "воспаление лёгких", port: "pneumonia (f)" },
      { ru: "грипп", port: "gripe (f)" },
      { ru: "близорукий", port: "míope" },
      { ru: "дальнозоркий", port: "presbita" },
      { ru: "косоглазие", port: "estrabismo (m)" },
      { ru: "косоглазый", port: "estrábico" },
      { ru: "катаракта", port: "catarata (f)" },
      { ru: "глаукома", port: "glaucoma (m)" },
      { ru: "инсульт", port: "AVC (m), apoplexia (f)" },
      { ru: "инфаркт", port: "ataque (m)  cardíaco" },
      { ru: "инфаркт миокарда", port: "enfarte (m)  do miocárdio" },
      { ru: "паралич", port: "paralisia (f)" },
      { ru: "парализовать", port: "paralisar (vt)" },
      { ru: "аллергия", port: "alergia (f)" },
      { ru: "астма", port: "asma (f)" },
      { ru: "диабет", port: "diabetes (f)" },
      { ru: "зубная боль", port: "dor (f)  de dentes" },
      { ru: "кариес", port: "cárie (f)" },
      { ru: "диарея", port: "diarreia (f)" },
      { ru: "запор", port: "prisão (f)  de ventre" },
      { ru: "расстройство желудка", port: "desarranjo (m)  intestinal" },
      { ru: "отравление (пищевое)", port: "intoxicação (f)  alimentar" },
      { ru: "отравиться (едой)", port: "intoxicar-se" },
      { ru: "артрит", port: "artrite (f)" },
      { ru: "рахит", port: "raquitismo (m)" },
      { ru: "ревматизм", port: "reumatismo (m)" },
      { ru: "атеросклероз", port: "arteriosclerose (f)" },
      { ru: "гастрит", port: "gastrite (f)" },
      { ru: "аппендицит", port: "apendicite (f)" },
      { ru: "холецистит", port: "colecistite (f)" },
      { ru: "язва (внутренняя)", port: "úlcera (f)" },
      { ru: "корь", port: "sarampo (m)" },
      { ru: "краснуха", port: "rubéola (f)" },
      { ru: "желтуха", port: "iterícia (f)" },
      { ru: "гепатит", port: "hepatite (f)" },
      { ru: "шизофрения", port: "esquizofrenia (f)" },
      { ru: "бешенство", port: "raiva (f)" },
      { ru: "невроз", port: "neurose (f)" },
      { ru: "сотрясение мозга", port: "comoção (f)  cerebral" },
      { ru: "рак", port: "cancro (m)" },
      { ru: "склероз", port: "esclerose (f)" },
      { ru: "рассеянный склероз", port: "esclerose (f)  múltipla" },
      { ru: "алкоголизм", port: "alcoolismo (m)" },
      { ru: "алкоголик", port: "alcoólico (m)" },
      { ru: "сифилис", port: "sífilis (f)" },
      { ru: "СПИД", port: "SIDA (f)" },
      { ru: "опухоль (внутренняя)", port: "tumor (m)" },
      { ru: "злокачественная", port: "maligno" },
      { ru: "доброкачественная", port: "benigno" },
      { ru: "лихорадка", port: "febre (f)" },
      { ru: "малярия", port: "malária (f)" },
      { ru: "гангрена", port: "gangrena (f)" },
      { ru: "морская болезнь", port: "enjoo (m)" },
      { ru: "эпилепсия", port: "epilepsia (f)" },
      { ru: "эпидемия", port: "epidemia (f)" },
      { ru: "тиф", port: "tifo (m)" },
      { ru: "туберкулёз", port: "tuberculose (f)" },
      { ru: "холера", port: "cólera (f)" },
      { ru: "чума", port: "peste (f)" },
    ],
  },
  {
    name: "Симтомы, лечение - 1",
    dict: [
      { ru: "симптом", port: "sintoma (m)" },
      { ru: "температура", port: "temperatura (f)" },
      { ru: "высокая температура", port: "febre (f)" },
      { ru: "пульс", port: "pulso (m)" },
      { ru: "головокружение", port: "vertigem (f)" },
      { ru: "горячий (напр. лоб)", port: "quente" },
      { ru: "озноб", port: "calafrio (m)" },
      { ru: "бледный", port: "pálido" },
      { ru: "кашель", port: "tosse (f)" },
      { ru: "кашлять", port: "tossir (vi)" },
      { ru: "чихать", port: "espirrar (vi)" },
      { ru: "обморок", port: "desmaio (m)" },
      { ru: "упасть в обморок", port: "desmaiar (vi)" },
      { ru: "синяк", port: "nódoa (f)  negra" },
      { ru: "шишка", port: "galo (m)" },
      { ru: "удариться", port: "magoar-se (vp)" },
      { ru: "ушиб", port: "pisadura (f)" },
      { ru: "ушибиться", port: "aleijar-se (vp)" },
      { ru: "хромать", port: "coxear (vi)" },
      { ru: "вывих", port: "deslocação (f)" },
      { ru: "вывихнуть", port: "deslocar (vt)" },
      { ru: "перелом", port: "fratura (f)" },
      { ru: "получить перелом", port: "fraturar (vt)" },
      { ru: "порез", port: "corte (m)" },
      { ru: "порезаться", port: "cortar-se (vp)" },
      { ru: "кровотечение", port: "hemorragia (f)" },
      { ru: "ожог", port: "queimadura (f)" },
      { ru: "обжечься", port: "queimar-se (vp)" },
      { ru: "уколоть (поранить)", port: "picar (vt)" },
      { ru: "уколоться", port: "picar-se (vp)" },
      { ru: "повредить (поранить)", port: "lesionar (vt)" },
      { ru: "повреждение (рана)", port: "lesão (m)" },
      { ru: "рана", port: "ferida (f), ferimento (m)" },
      { ru: "травма", port: "trauma (m)" },
      { ru: "бредить (мед.)", port: "delirar (vi)" },
      { ru: "заикаться", port: "gaguejar (vi)" },
      { ru: "солнечный удар", port: "insolação (f)" },
    ],
  },
  {
    name: "Симтомы, лечение - 2",
    dict: [
      { ru: "боль", port: "dor (f)" },
      { ru: "заноза", port: "farpa (f)" },
      { ru: "пот", port: "suor (m)" },
      { ru: "потеть", port: "suar (vi)" },
      { ru: "рвота", port: "vómito (m)" },
      { ru: "судороги", port: "convulsões (f pl)" },
      { ru: "беременная", port: "grávida" },
      { ru: "родиться", port: "nascer (vi)" },
      { ru: "роды", port: "parto (m)" },
      { ru: "рожать", port: "dar à luz" },
      { ru: "аборт", port: "aborto (m)" },
      { ru: "дыхание", port: "respiração (f)" },
      { ru: "вдох", port: "inspiração (f)" },
      { ru: "выдох", port: "expiração (f)" },
      { ru: "выдохнуть", port: "expirar (vi)" },
      { ru: "сделать вдох", port: "inspirar (vi)" },
      { ru: "инвалид", port: "inválido (m)" },
      { ru: "калека", port: "aleijado (m)" },
      { ru: "наркоман", port: "toxicodependente (m)" },
      { ru: "глухой", port: "surdo" },
      { ru: "немой", port: "mudo" },
      { ru: "глухонемой", port: "surdo-mudo" },
      { ru: "сумасшедший (прил.)", port: "louco" },
      { ru: "сумасшедший (сущ.)", port: "louco (m)" },
      { ru: "сумасшедшая (сущ.)", port: "louca (f)" },
      { ru: "сойти с ума", port: "ficar louco" },
      { ru: "ген", port: "gene (m)" },
      { ru: "иммунитет", port: "imunidade (f)" },
      { ru: "наследственный", port: "hereditário" },
      { ru: "врождённый", port: "congénito" },
      { ru: "вирус", port: "vírus (m)" },
      { ru: "микроб", port: "micróbio (m)" },
      { ru: "бактерия", port: "bactéria (f)" },
      { ru: "инфекция", port: "infeção (f)" },
    ],
  },
  {
    name: "Симтомы, лечение - 3",
    dict: [
      { ru: "больница", port: "hospital (m)" },
      { ru: "пациент", port: "paciente (m)" },
      { ru: "диагноз", port: "diagnóstico (m)" },
      { ru: "лечение", port: "cura (f)" },
      { ru: "лечение (больных)", port: "tratamento (m)  médico" },
      { ru: "лечиться", port: "curar-se (vp)" },
      { ru: "лечить (ухаживать)", port: "tratar (vt)" },
      { ru: "ухаживать (за больным)", port: "cuidar (vt)" },
      { ru: "уход (за больным)", port: "cuidados (m pl)" },
      { ru: "операция (мед.)", port: "operação (f)" },
      { ru: "перевязать", port: "pôr uma ligadura" },
      { ru: "перевязка", port: "ligadura (f)" },
      { ru: "прививка", port: "vacinação (f)" },
      { ru: "делать прививку", port: "vacinar (vt)" },
      { ru: "укол (мед.)", port: "injeção (f)" },
      { ru: "делать укол (кому-л.)", port: "dar uma injeção" },
      { ru: "приступ, припадок", port: "ataque (m)" },
      { ru: "ампутация", port: "amputação (f)" },
      { ru: "ампутировать", port: "amputar (vt)" },
      { ru: "кома", port: "coma (m)" },
      { ru: "быть в коме", port: "estar em coma" },
      { ru: "реанимация", port: "reanimação (f)" },
      { ru: "выздоравливать", port: "recuperar-se (vp)" },
      { ru: "состояние (больного)", port: "estado (m)" },
      { ru: "сознание (мед.)", port: "consciência (f)" },
      { ru: "память", port: "memória (f)" },
      { ru: "удалять (зуб)", port: "tirar (vt)" },
      { ru: "пломба (на зубах)", port: "chumbo (m), obturação (f)" },
      { ru: "пломбировать (зубы)", port: "chumbar, obturar (vt)" },
      { ru: "гипноз", port: "hipnose (f)" },
      { ru: "гипнотизировать", port: "hipnotizar (vt)" },
    ],
  },
  {
    name: "Врачи",
    dict: [
      { ru: "врач", port: "médico (m)" },
      { ru: "медсестра", port: "enfermeira (f)" },
      { ru: "личный врач", port: "médico (m)  pessoal" },
      { ru: "дантист", port: "dentista (m)" },
      { ru: "окулист", port: "oculista (m)" },
      { ru: "терапевт", port: "terapeuta (m)" },
      { ru: "хирург", port: "cirurgião (m)" },
      { ru: "психиатр", port: "psiquiatra (m)" },
      { ru: "педиатр", port: "pediatra (m)" },
      { ru: "психолог", port: "psicólogo (m)" },
      { ru: "гинеколог", port: "ginecologista (m)" },
      { ru: "кардиолог", port: "cardiologista (m)" },
    ],
  },
  {
    name: "Лекарства, принадлежности",
    dict: [
      { ru: "лекарство", port: "medicamento (m)" },
      { ru: "средство (мед.)", port: "remédio (m)" },
      { ru: "прописать (лекарство)", port: "receitar (vt)" },
      { ru: "рецепт", port: "receita (f)" },
      { ru: "таблетка", port: "comprimido (m)" },
      { ru: "мазь", port: "pomada (f)" },
      { ru: "ампула", port: "ampola (f)" },
      { ru: "микстура", port: "preparado (m)" },
      { ru: "сироп", port: "xarope (m)" },
      { ru: "пилюля", port: "cápsula (f)" },
      { ru: "порошок", port: "remédio (m)  em pó" },
      { ru: "бинт", port: "ligadura (f)" },
      { ru: "вата", port: "algodão (m)" },
      { ru: "йод", port: "iodo (m)" },
      { ru: "лейкопластырь", port: "penso (m)  rápido" },
      { ru: "пипетка", port: "conta-gotas (f)" },
      { ru: "градусник", port: "termómetro (m)" },
      { ru: "шприц", port: "seringa (f)" },
      { ru: "инвалидная коляска", port: "cadeira (m)  de rodas" },
      { ru: "костыли", port: "muletas (f pl)" },
      { ru: "обезболивающее", port: "analgésico (m)" },
      { ru: "слабительное", port: "laxante (m)" },
      { ru: "спирт", port: "álcool (m)" },
      { ru: "лекарственная трава", port: "ervas (f pl)  medicinais" },
      { ru: "травяной (чай и т.п.)", port: "de ervas" },
    ],
  },
  {
    name: "Курение, табак",
    dict: [
      { ru: "табак", port: "tabaco (m)" },
      { ru: "сигарета", port: "cigarro (m)" },
      { ru: "сигара", port: "charuto (m)" },
      { ru: "трубка", port: "cachimbo (m)" },
      { ru: "пачка (сигарет)", port: "maço (m)" },
      { ru: "спички", port: "fósforos (m pl)" },
      { ru: "спичечный коробок", port: "caixa (f)  de fósforos" },
      { ru: "зажигалка", port: "isqueiro (m)" },
      { ru: "пепельница", port: "cinzeiro (m)" },
      { ru: "портсигар", port: "cigarreira (f)" },
      { ru: "мундштук", port: "boquilha (f)" },
      { ru: "фильтр", port: "filtro (m)" },
      { ru: "курить", port: "fumar (vi, vt)" },
      { ru: "прикурить", port: "acender um cigarro" },
      { ru: "курение", port: "tabagismo (m)" },
      { ru: "курильщик", port: "fumador (m)" },
      { ru: "окурок", port: "beata (f)" },
      { ru: "дым", port: "fumo (m)" },
      { ru: "пепел", port: "cinza (f)" },
    ],
  },
  {
    name: "Город. Жизнь в городе",
    dict: [
      { ru: "город", port: "cidade (f)" },
      { ru: "столица", port: "capital (f)" },
      { ru: "деревня", port: "aldeia (f)" },
      { ru: "план города", port: "mapa (m)  da cidade" },
      { ru: "центр города", port: "centro (m)  da cidade" },
      { ru: "пригород", port: "subúrbio (m)" },
      { ru: "пригородный", port: "suburbano" },
      { ru: "окраина (города)", port: "periferia (f)" },
      { ru: "окрестности", port: "arredores (m pl)" },
      { ru: "квартал", port: "quarteirão (m)" },
      { ru: "жилой квартал", port: "quarteirão (m)  residencial" },
      { ru: "уличное движение", port: "tráfego (m)" },
      { ru: "светофор", port: "semáforo (m)" },
      { ru: "городской транспорт", port: "transporte (m)  público" },
      { ru: "перекрёсток", port: "cruzamento (m)" },
      { ru: "пешеходный переход", port: "passadeira (f)  para peões" },
      { ru: "подземный переход", port: "passagem (f)  subterrânea" },
      { ru: "переходить (улицу)", port: "cruzar, atravessar (vt)" },
      { ru: "пешеход", port: "peão (m)" },
      { ru: "тротуар", port: "passeio (m)" },
      { ru: "мост", port: "ponte (f)" },
      { ru: "набережная", port: "marginal (f)" },
      { ru: "фонтан", port: "fonte (f)" },
      { ru: "аллея", port: "alameda (f)" },
      { ru: "парк", port: "parque (m)" },
      { ru: "бульвар", port: "bulevar (m)" },
      { ru: "площадь", port: "praça (f)" },
      { ru: "проспект", port: "avenida (f)" },
      { ru: "улица", port: "rua (f)" },
      { ru: "переулок", port: "travessa (f)" },
      { ru: "тупик", port: "beco (m)  sem saída" },
      { ru: "дом", port: "casa (f)" },
      { ru: "здание", port: "edifício, prédio (m)" },
      { ru: "небоскрёб", port: "arranha-céus (m)" },
      { ru: "фасад", port: "fachada (f)" },
      { ru: "крыша", port: "telhado (m)" },
      { ru: "окно", port: "janela (f)" },
      { ru: "арка", port: "arco (m)" },
      { ru: "колонна", port: "coluna (f)" },
      { ru: "угол (дома)", port: "esquina (f)" },
      { ru: "витрина", port: "montra (f)" },
      { ru: "вывеска", port: "letreiro (m)" },
      { ru: "афиша", port: "cartaz (m)" },
      { ru: "рекламный плакат", port: "cartaz (m)  publicitário" },
      { ru: "рекламный щит", port: "painel (m)  publicitário" },
      { ru: "мусор (отбросы)", port: "lixo (m)" },
      { ru: "урна (для мусора)", port: "cesta (f)  do lixo" },
      { ru: "сорить", port: "jogar lixo na rua" },
      { ru: "свалка", port: "aterro (m)  sanitário" },
      { ru: "телефонная будка", port: "cabine (f)  telefónica" },
      { ru: "фонарный столб", port: "candeeiro (m)  de rua" },
      { ru: "скамейка", port: "banco (m)" },
      { ru: "полицейский", port: "polícia (m)" },
      { ru: "полиция", port: "polícia (f)" },
      { ru: "нищий (бродяга)", port: "mendigo (m)" },
      { ru: "бездомный (сущ.)", port: "sem-abrigo (m)" },
    ],
  },
  {
    name: "Городские учреждения",
    dict: [
      { ru: "магазин", port: "loja (f)" },
      { ru: "аптека", port: "farmácia (f)" },
      { ru: "оптика", port: "ótica (f)" },
      { ru: "торговый центр", port: "centro (m)  comercial" },
      { ru: "супермаркет", port: "supermercado (m)" },
      { ru: "булочная", port: "padaria (f)" },
      { ru: "пекарь", port: "padeiro (m)" },
      { ru: "кондитерская", port: "pastelaria (f)" },
      { ru: "бакалея", port: "mercearia (f)" },
      { ru: "мясная лавка", port: "talho (m)" },
      { ru: "овощная лавка", port: "loja (f)  de legumes" },
      { ru: "рынок", port: "mercado (m)" },
      { ru: "кофейня", port: "café (m)" },
      { ru: "ресторан", port: "restaurante (m)" },
      { ru: "пивная", port: "cervejaria (f)" },
      { ru: "пиццерия", port: "pizzaria (f)" },
      { ru: "парикмахерская", port: "salão (m)  de cabeleireiro" },
      { ru: "почта", port: "correios (m pl)" },
    ],
  },
  {
    name: "Достопримечательности",
    dict: [
      { ru: "памятник", port: "monumento (m)" },
      { ru: "крепость", port: "fortaleza (f)" },
      { ru: "дворец", port: "palácio (m)" },
      { ru: "зáмок", port: "castelo (m)" },
      { ru: "башня", port: "torre (f)" },
      { ru: "мавзолей", port: "mausoléu (m)" },
      { ru: "архитектура", port: "arquitetura (f)" },
      { ru: "средневековый", port: "medieval" },
      { ru: "старинный", port: "antigo" },
      { ru: "национальный", port: "nacional" },
      { ru: "известный", port: "conhecido" },
      { ru: "турист", port: "turista (m)" },
      { ru: "гид (экскурсовод)", port: "guia (m)" },
      { ru: "экскурсия", port: "excursão (f)" },
      { ru: "показывать", port: "mostrar (vt)" },
      { ru: "рассказывать", port: "contar (vt)" },
      { ru: "найти", port: "encontrar (vt)" },
      { ru: "потеряться", port: "perder-se (vp)" },
      { ru: "схема (метро и т.п.)", port: "mapa (m)" },
      { ru: "план (города и т.п.)", port: "mapa (m)" },
      { ru: "сувенир", port: "lembrança (f), presente (m)" },
      { ru: "магазин сувениров", port: "loja (f)  de presentes" },
      { ru: "фотографировать", port: "fotografar (vt)" },
      { ru: "фотографироваться", port: "fotografar-se" },
    ],
  },
  {
    name: "Покупки",
    dict: [
      { ru: "покупать", port: "comprar (vt)" },
      { ru: "покупка (предмет)", port: "compra (f)" },
      { ru: "делать покупки", port: "fazer compras" },
      { ru: "шоппинг", port: "compras (f pl)" },
      { ru: "работать (о магазине)", port: "estar aberta" },
      { ru: "быть закрытым", port: "estar fechada" },
      { ru: "обувь", port: "calçado (m)" },
      { ru: "одежда", port: "roupa (f)" },
      { ru: "косметика", port: "cosméticos (m pl)" },
      { ru: "продукты", port: "alimentos (m pl)" },
      { ru: "подарок", port: "presente (m)" },
      { ru: "продавец", port: "vendedor (m)" },
      { ru: "продавщица", port: "vendedora (f)" },
      { ru: "касса (в магазине)", port: "caixa (f)" },
      { ru: "зеркало", port: "espelho (m)" },
      { ru: "прилавок", port: "balcão (m)" },
      { ru: "примерочная", port: "cabine (f)  de provas" },
      { ru: "примерить", port: "provar (vt)" },
      { ru: "подходить (быть впору)", port: "servir (vi)" },
      { ru: "нравиться", port: "gostar (vt)" },
      { ru: "цена", port: "preço (m)" },
      { ru: "ценник (на одежде)", port: "etiqueta (f)  de preço" },
      { ru: "стоить", port: "custar (vt)" },
      { ru: "Сколько? (о цене)", port: "Quanto?" },
      { ru: "скидка", port: "desconto (m)" },
      { ru: "недорогой", port: "não caro" },
      { ru: "дешёвый", port: "barato" },
      { ru: "дорогой", port: "caro" },
      { ru: "Это дорого", port: "É caro" },
      { ru: "прокат", port: "aluguer (m)" },
      { ru: "взять напрокат", port: "alugar (vt)" },
      { ru: "кредит", port: "crédito (m)" },
      { ru: "в кредит", port: "a crédito" },
    ],
  },
  {
    name: "Деньги",
    dict: [
      { ru: "деньги", port: "dinheiro (m)" },
      { ru: "обмен", port: "câmbio (m)" },
      { ru: "курс (валют)", port: "taxa (f)  de câmbio" },
      { ru: "банкомат", port: "Caixa Multibanco (m)" },
      { ru: "монета", port: "moeda (f)" },
      { ru: "доллар", port: "dólar (m)" },
      { ru: "евро", port: "euro (m)" },
      { ru: "итальянская лира", port: "lira (f)" },
      { ru: "немецкая марка", port: "marco (m)" },
      { ru: "франк", port: "franco (m)" },
      { ru: "фунт стерлингов", port: "libra (f)  esterlina" },
      { ru: "йена", port: "iene (m)" },
      { ru: "долг", port: "dívida (f)" },
      { ru: "должник", port: "devedor (m)" },
      { ru: "дать в долг", port: "emprestar (vt)" },
      { ru: "взять в долг", port: "pedir emprestado" },
      { ru: "банк", port: "banco (m)" },
      { ru: "счёт (в банке)", port: "conta (f)" },
      { ru: "положить (на счёт)", port: "depositar (vt)" },
      { ru: "положить на счёт", port: "depositar na conta" },
      { ru: "снять со счёта", port: "levantar (vt)" },
      { ru: "кредитная карта", port: "cartão (m)  de crédito" },
      { ru: "наличные деньги", port: "dinheiro (m)  vivo" },
      { ru: "чек", port: "cheque (m)" },
      { ru: "выписать чек", port: "passar um cheque" },
      { ru: "чековая книжка", port: "livro (m)  de cheques" },
      { ru: "бумажник", port: "carteira (f)" },
      { ru: "кошелёк", port: "porta-moedas (m)" },
      { ru: "портмоне", port: "carteira (f)" },
      { ru: "сейф", port: "cofre (m)" },
      { ru: "наследник", port: "herdeiro (m)" },
      { ru: "наследство", port: "herança (f)" },
      { ru: "состояние", port: "fortuna (f)" },
      { ru: "аренда", port: "arrendamento (m)" },
      { ru: "квартирная плата", port: "renda (f)  de casa" },
      { ru: "снимать (~ квартиру)", port: "alugar (vt)" },
      { ru: "цена", port: "preço (m)" },
      { ru: "стоимость", port: "custo (m)" },
      { ru: "сумма", port: "soma (f)" },
      { ru: "тратить, расходовать", port: "gastar (vt)" },
      { ru: "расходы", port: "gastos (m pl)" },
      { ru: "экономить", port: "economizar (vi)" },
      { ru: "экономный", port: "económico" },
      { ru: "платить", port: "pagar (vt)" },
      { ru: "оплата", port: "pagamento (m)" },
      { ru: "сдача (деньги)", port: "troco (m)" },
      { ru: "налог", port: "imposto (m)" },
      { ru: "штраф", port: "multa (f)" },
      { ru: "штрафовать", port: "multar (vt)" },
    ],
  },
  {
    name: "Почта",
    dict: [
      { ru: "почта (учреждение)", port: "correios (m pl)" },
      { ru: "почта (письма и т.п.)", port: "correio (m)" },
      { ru: "почтальон", port: "carteiro (m)" },
      { ru: "часы работы", port: "horário (m)" },
      { ru: "письмо", port: "carta (f)" },
      { ru: "заказное письмо", port: "carta (f)  registada" },
      { ru: "открытка", port: "postal (m)" },
      { ru: "телеграмма", port: "telegrama (m)" },
      { ru: "посылка", port: "encomenda (f)  postal" },
      { ru: "денежный перевод", port: "remessa (f)  de dinheiro" },
      { ru: "получить", port: "receber (vt)" },
      { ru: "отправить", port: "enviar (vt)" },
      { ru: "отправка (письма)", port: "envio (m)" },
      { ru: "адрес", port: "endereço (m)" },
      { ru: "индекс", port: "código (m)  postal" },
      { ru: "отправитель", port: "remetente (m)" },
      { ru: "получатель", port: "destinatário (m)" },
      { ru: "имя", port: "nome (m)" },
      { ru: "фамилия", port: "apelido (m)" },
      { ru: "тариф", port: "tarifa (f)" },
      { ru: "обычный", port: "normal" },
      { ru: "экономичный", port: "económico" },
      { ru: "вес", port: "peso (m)" },
      { ru: "взвешивать", port: "pesar (vt)" },
      { ru: "конверт", port: "envelope (m)" },
      { ru: "марка", port: "selo (m)" },
      { ru: "наклеивать марку", port: "colar o selo" },
    ],
  },
  {
    name: "Дом, жилище",
    dict: [
      { ru: "дом", port: "casa (f)" },
      { ru: "дома (у себя)", port: "em casa" },
      { ru: "двор", port: "pátio (m)" },
      { ru: "ограда (решётка)", port: "cerca (f)" },
      { ru: "кирпич", port: "tijolo (m)" },
      { ru: "кирпичный", port: "de tijolos" },
      { ru: "камень", port: "pedra (f)" },
      { ru: "каменный", port: "de pedra" },
      { ru: "бетон", port: "betão (m)" },
      { ru: "бетонный", port: "de betão" },
      { ru: "новый", port: "novo" },
      { ru: "старый", port: "velho" },
      { ru: "ветхий", port: "decrépito" },
      { ru: "современный", port: "moderno" },
      { ru: "многоэтажный", port: "de muitos andares" },
      { ru: "высокий", port: "alto" },
      { ru: "этаж", port: "andar (m)" },
      { ru: "одноэтажный", port: "de um andar" },
      { ru: "нижний этаж", port: "andar (m)  de baixo" },
      { ru: "верхний этаж", port: "andar (m)  de cima" },
      { ru: "крыша", port: "telhado (m)" },
      { ru: "труба", port: "chaminé (f)" },
      { ru: "черепица", port: "telha (f)" },
      { ru: "черепичный", port: "de telha" },
      { ru: "чердак", port: "sótão (m)" },
      { ru: "окно", port: "janela (f)" },
      { ru: "стекло", port: "vidro (m)" },
      { ru: "подоконник", port: "parapeito (m)" },
      { ru: "ставни", port: "portadas (f pl)" },
      { ru: "стена", port: "parede (f)" },
      { ru: "балкон", port: "varanda (f)" },
      { ru: "водосточная труба", port: "tubo (m)  de queda" },
      { ru: "наверху", port: "em cima" },
      { ru: "подниматься", port: "subir (vi)" },
      { ru: "спускаться", port: "descer (vi)" },
      { ru: "переезжать", port: "mudar-se (vp)" },
    ],
  },
  {
    name: "Дом, подьезд, лифт",
    dict: [
      { ru: "подъезд ", port: "entrada (f)" },
      { ru: "ступени", port: "degraus (m pl)" },
      { ru: "перила", port: "corrimão (m)" },
      { ru: "холл", port: "hall (m)" },
      { ru: "почтовый ящик", port: "caixa (f)  de correio" },
      { ru: "мусорный бак", port: "caixote (m)  do lixo" },
      { ru: "мусоропровод", port: "conduta (f)  do lixo" },
      { ru: "лифт", port: "elevador (m)" },
      { ru: "грузовой лифт", port: "elevador (m)  de carga" },
      { ru: "кабина (лифта)", port: "cabine (f)" },
      { ru: "ехать на лифте", port: "pegar o elevador" },
      { ru: "квартира", port: "apartamento (m)" },
      { ru: "жильцы", port: "moradores (m pl)" },
      { ru: "сосед", port: "vizinho (m)" },
      { ru: "соседка", port: "vizinha (f)" },
      { ru: "соседи", port: "vizinhos (pl)" },
    ],
  },
  {
    name: "Дом, электричество",
    dict: [
      { ru: "электричество", port: "eletricidade (f)" },
      { ru: "выключатель", port: "interruptor (m)" },
      { ru: "пробка (электр.)", port: "fusível (m)" },
      { ru: "провод", port: "fio, cabo (m)" },
      { ru: "проводка", port: "instalação (f)  elétrica" },
      { ru: "электросчётчик", port: "contador (m)  de eletricidade" },
      { ru: "показание (счётчика)", port: "leitura (f)" },
    ],
  },
  {
    name: "Дом, дверь, замок",
    dict: [
      { ru: "дверь", port: "porta (f)" },
      { ru: "ручка (дверная)", port: "maçaneta (f)" },
      { ru: "отпереть", port: "destrancar (vt)" },
      { ru: "открывать", port: "abrir (vt)" },
      { ru: "закрывать", port: "fechar (vt)" },
      { ru: "ключ", port: "chave (f)" },
      { ru: "связка (ключей)", port: "molho (m)" },
      { ru: "скрипеть", port: "ranger (vi)" },
      { ru: "скрип", port: "rangido (m)" },
      { ru: "петля (дверная)", port: "dobradiça (f)" },
      { ru: "коврик", port: "tapete (m)  de entrada" },
      { ru: "замок (дверной ~)", port: "fechadura (f)" },
      { ru: "замочная скважина", port: "buraco (m)  da fechadura" },
      { ru: "засов", port: "ferrolho (m)" },
      { ru: "задвижка", port: "ferrolho, fecho (m)" },
      { ru: "навесной замок", port: "cadeado (m)" },
      { ru: "звонить", port: "tocar (vt)" },
      { ru: "звонок (звук)", port: "toque (m)" },
      { ru: "звонок (прибор)", port: "campainha (f)" },
      { ru: "кнопка (звонка)", port: "botão (m)" },
      { ru: "стук (в дверь)", port: "batida (f)" },
      { ru: "стучать", port: "bater (vi)" },
      { ru: "код", port: "código (m)" },
      { ru: "кодовый замок", port: "fechadura (f)  de código" },
      { ru: "домофон", port: "telefone (m)  de porta" },
      { ru: "номер", port: "número (m)" },
      { ru: "табличка", port: "placa (f)  de porta" },
      { ru: "глазок", port: "vigia (f), olho (m)  mágico" },
    ],
  },
  {
    name: "Загородный дои, Дом в деревне",
    dict: [
      { ru: "деревня", port: "aldeia (f)" },
      { ru: "забор", port: "cerca (f)" },
      { ru: "изгородь", port: "paliçada (f)" },
      { ru: "калитка", port: "cancela (f)" },
      { ru: "амбар", port: "celeiro (m)" },
      { ru: "погреб", port: "adega (f)" },
      { ru: "сарай", port: "galpão, barracão (m)" },
      { ru: "колодец", port: "poço (m)" },
      { ru: "печь (предмет)", port: "fogão (f)" },
      { ru: "топить печь", port: "atiçar o fogo" },
      { ru: "дрова", port: "lenha (f)" },
      { ru: "полено", port: "acha, lenha (f)" },
      { ru: "веранда", port: "varanda (f)" },
      { ru: "терраса", port: "alpendre (m)" },
      { ru: "крыльцо", port: "degraus (m pl)  de entrada" },
      { ru: "качели", port: "balouço (m)" },
    ],
  },
  {
    name: "Особняк, вилла",
    dict: [
      { ru: "загородный дом", port: "casa (f)  de campo" },
      { ru: "крыло (здания)", port: "ala (f)" },
      { ru: "сад", port: "jardim (m)" },
      { ru: "парк", port: "parque (m)" },
      { ru: "оранжерея", port: "estufa (f)" },
      { ru: "ухаживать (за садом)", port: "cuidar de …" },
      { ru: "бассейн", port: "piscina (f)" },
      { ru: "тренажёрный зал", port: "ginásio (m)" },
      { ru: "теннисный корт", port: "campo (m)  de ténis" },
      { ru: "кинотеатр", port: "cinema (m)" },
      { ru: "гараж", port: "garagem (f)" },
      { ru: "частная собственность", port: "propriedade (f)  privada" },
      { ru: "частные владения", port: "terreno (m)  privado" },
      { ru: "предупреждение", port: "advertência (f)" },
      { ru: "предупреждающая надпись", port: "sinal (m)  de aviso" },
      { ru: "охрана", port: "guarda (f)" },
      { ru: "охранник", port: "guarda (m)" },
      { ru: "сигнализация", port: "alarme (m)" },
    ],
  },
  {
    name: "Замок, дворец",
    dict: [
      { ru: "зáмок", port: "castelo (m)" },
      { ru: "крепость", port: "fortaleza (f)" },
      { ru: "стена (крепостная)", port: "muralha (f)" },
      { ru: "башня", port: "torre (f)" },
      { ru: "главная башня", port: "torre (f)  de menagem" },
      { ru: "подъёмная решётка", port: "grade (f)" },
      { ru: "подземный ход", port: "passagem (f)  subterrânea" },
      { ru: "ров", port: "fosso (m)" },
      { ru: "цепь", port: "corrente, cadeia (f)" },
      { ru: "бойница", port: "seteira (f)" },
      { ru: "великолепный", port: "magnífico" },
      { ru: "величественный", port: "majestoso" },
      { ru: "неприступный", port: "inexpugnável" },
      { ru: "средневековый", port: "medieval" },
    ],
  },
  {
    name: "Квартира",
    dict: [
      { ru: "квартира", port: "apartamento (m)" },
      { ru: "кабинет", port: "escritório (m)" },
      { ru: "прихожая", port: "antessala (f)" },
      { ru: "ванная комната", port: "quarto (m)  de banho" },
      { ru: "туалет", port: "quarto (m)  de banho" },
      { ru: "потолок", port: "teto (m)" },
      { ru: "пол", port: "chão, soalho (m)" },
      { ru: "угол (комнаты)", port: "canto (m)" },
    ],
  },
  {
    name: "Уборка",
    dict: [
      { ru: "убирать (~ квартиру)", port: "arrumar, limpar (vt)" },
      { ru: "пыль", port: "pó (m)" },
      { ru: "пыльный", port: "empoeirado" },
      { ru: "вытирать пыль", port: "limpar o pó" },
      { ru: "пылесос", port: "aspirador (m)" },
      { ru: "пылесосить", port: "aspirar (vt)" },
      { ru: "подметать", port: "varrer (vt)" },
      { ru: "мусор (сор)", port: "varredura (f), cisco (m)" },
      { ru: "порядок (в комнате)", port: "arrumação (f), ordem (f)" },
      { ru: "беспорядок", port: "desordem (f)" },
      { ru: "швабра", port: "esfregona (f)" },
      { ru: "тряпка", port: "pano (m), trapo (m)" },
      { ru: "веник", port: "vassoura (f)" },
      { ru: "совок для мусора", port: "pá (f)  de lixo" },
    ],
  },
  {
    name: "Мебель, интерьер",
    dict: [
      { ru: "мебель", port: "mobiliário (m)" },
      { ru: "стул", port: "cadeira (f)" },
      { ru: "кровать", port: "cama (f)" },
      { ru: "диван", port: "divã (m)" },
      { ru: "кресло", port: "cadeirão (m)" },
      { ru: "шкаф (книжный)", port: "biblioteca (f)" },
      { ru: "полка (для книг)", port: "prateleira (f)" },
      { ru: "этажерка", port: "estante (f)" },
      { ru: "шкаф (для одежды)", port: "guarda-vestidos (m)" },
      { ru: "вешалка (на стене)", port: "cabide (m)  de parede" },
      { ru: "вешалка (стойка)", port: "cabide (m)  de pé" },
      { ru: "комод", port: "cómoda (f)" },
      { ru: "журнальный столик", port: "mesinha (f)  de centro" },
      { ru: "зеркало", port: "espelho (m)" },
      { ru: "ковёр", port: "tapete (m)" },
      { ru: "коврик", port: "tapete (m)  pequeno" },
      { ru: "камин", port: "lareira (f)" },
      { ru: "свеча", port: "vela (f)" },
      { ru: "подсвечник", port: "castiçal (m)" },
      { ru: "шторы", port: "cortinas (f pl)" },
      { ru: "обои", port: "papel (m)  de parede" },
      { ru: "жалюзи", port: "estores (f pl)" },
      { ru: "настольная лампа", port: "candeeiro (m)  de mesa" },
      { ru: "светильник (на стене)", port: "candeeiro (m)  de parede" },
      { ru: "торшер", port: "candeeiro (m)  de pé" },
      { ru: "люстра", port: "lustre (m)" },
      { ru: "ножка (стола, стула)", port: "perna (f)" },
      { ru: "подлокотник", port: "braço (m)" },
      { ru: "спинка (стула и т.п.)", port: "costas (f pl)" },
      { ru: "ящик (стола)", port: "gaveta (f)" },
    ],
  },
  {
    name: "Постельные принадлежности",
    dict: [
      { ru: "постельное бельё", port: "roupa (f)  de cama" },
      { ru: "наволочка", port: "fronha (f)" },
      { ru: "одеяло", port: "cobertor (m)" },
      { ru: "простыня", port: "lençol (m)" },
      { ru: "покрывало", port: "colcha (f)" },
    ],
  },
  {
    name: "Кухня",
    dict: [
      { ru: "кухня (помещение)", port: "cozinha (f)" },
      { ru: "плита газовая", port: "fogão (m)  a gás" },
      { ru: "плита электрическая", port: "fogão (m)  elétrico" },
      { ru: "духовка", port: "forno (m)" },
      { ru: "микроволновая печь", port: "forno (m)  de micro-ondas" },
      { ru: "холодильник", port: "frigorífico (m)" },
      { ru: "морозильник", port: "congelador (m)" },
      { ru: "посудомоечная машина", port: "máquina (f)  de lavar louça" },
      { ru: "мясорубка", port: "moedor (m)  de carne" },
      { ru: "соковыжималка", port: "espremedor (m)" },
      { ru: "тостер", port: "torradeira (f)" },
      { ru: "миксер", port: "batedeira (f)" },
      { ru: "кофеварка", port: "máquina (f)  de café" },
      { ru: "кофейник", port: "cafeteira (f)" },
      { ru: "кофемолка", port: "moinho (m)  de café" },
      { ru: "чайник (для кипятка)", port: "chaleira (f)" },
      { ru: "чайник (для заварки)", port: "bule (m)" },
      { ru: "крышка (чайника и т.п.)", port: "tampa (f)" },
      { ru: "ситечко (для чая)", port: "coador (f)  de chá" },
      { ru: "ложка", port: "colher (f)" },
      { ru: "чайная ложка", port: "colher (f)  de chá" },
      { ru: "столовая ложка", port: "colher (f)  de sopa" },
      { ru: "вилка", port: "garfo (m)" },
      { ru: "нож", port: "faca (f)" },
      { ru: "посуда", port: "louça (f)" },
      { ru: "тарелка", port: "prato (m)" },
      { ru: "блюдце", port: "pires (m)" },
      { ru: "рюмка (стопка)", port: "cálice (m)" },
      { ru: "стакан", port: "copo (m)" },
      { ru: "чашка", port: "chávena (f)" },
      { ru: "сахарница", port: "açucareiro (m)" },
      { ru: "солонка", port: "saleiro (m)" },
      { ru: "перечница", port: "pimenteiro (m)" },
      { ru: "маслёнка", port: "manteigueira (f)" },
      { ru: "кастрюля", port: "panela (f)" },
      { ru: "сковородка", port: "frigideira (f)" },
      { ru: "половник", port: "concha (f)" },
      { ru: "дуршлаг", port: "passador (m)" },
      { ru: "поднос", port: "bandeja (f)" },
      { ru: "бутылка", port: "garrafa (f)" },
      { ru: "банка (стеклянная)", port: "boião (m)  de vidro" },
      { ru: "банка (жестяная)", port: "lata (f)" },
      { ru: "открывалка (для бутылок)", port: "abridor (m)  de garrafas" },
      { ru: "открывалка (для консервов)", port: "abre-latas (m)" },
      { ru: "штопор", port: "saca-rolhas (m)" },
      { ru: "фильтр", port: "filtro (m)" },
      { ru: "фильтровать", port: "filtrar (vt)" },
      { ru: "мусор (отходы)", port: "lixo (m)" },
      { ru: "мусорное ведро", port: "balde (m)  do lixo" },
    ],
  },
  {
    name: "Ванная комата",
    dict: [
      { ru: "ванная комната", port: "quarto (m)  de banho" },
      { ru: "кран", port: "torneira (f)" },
      { ru: "горячая вода", port: "água (f)  quente" },
      { ru: "холодная вода", port: "água (f)  fria" },
      { ru: "зубная паста", port: "pasta (f)  de dentes" },
      { ru: "чистить зубы", port: "escovar os dentes" },
      { ru: "зубная щётка", port: "escova (f)  de dentes" },
      { ru: "бриться", port: "barbear-se (vp)" },
      { ru: "пена для бритья", port: "espuma (f)  de barbear" },
      { ru: "бритва", port: "máquina (f)  de barbear" },
      { ru: "мыть", port: "lavar (vt)" },
      { ru: "мыться", port: "lavar-se (vp)" },
      { ru: "душ", port: "duche (m)" },
      { ru: "принимать душ", port: "tomar um duche" },
      { ru: "ванна", port: "banheira (f)" },
      { ru: "унитаз", port: "sanita (f)" },
      { ru: "раковина", port: "lavatório (m)" },
      { ru: "мыло", port: "sabonete (m)" },
      { ru: "мыльница", port: "saboneteira (f)" },
      { ru: "губка", port: "esponja (f)" },
      { ru: "шампунь", port: "champô (m)" },
      { ru: "полотенце", port: "toalha (f)" },
      { ru: "халат (махровый)", port: "roupão (m)  de banho" },
      { ru: "стирка", port: "lavagem (f)" },
      { ru: "стиральная машина", port: "máquina (f)  de lavar" },
      { ru: "стирать бельё", port: "lavar a roupa" },
      { ru: "стиральный порошок", port: "detergente (m)" },
    ],
  },
  {
    name: "Бытовая техника",
    dict: [
      { ru: "телевизор", port: "televisor (m)" },
      { ru: "видеомагнитофон", port: "videogravador (m)" },
      { ru: "приёмник", port: "rádio (m)" },
      { ru: "плеер", port: "leitor (m)" },
      { ru: "видеопроектор", port: "projetor (m)" },
      { ru: "домашний кинотеатр", port: "cinema (m)  em casa" },
      { ru: "проигрыватель DVD", port: "leitor (m)  de DVD" },
      { ru: "усилитель", port: "amplificador (m)" },
      { ru: "игровая приставка", port: "console (f)  de jogos" },
      { ru: "видеокамера", port: "câmara (f)  de vídeo" },
      { ru: "фотоаппарат", port: "máquina (f)  fotográfica" },
      { ru: "цифровой фотоаппарат", port: "câmara (f)  digital" },
      { ru: "пылесос", port: "aspirador (m)" },
      { ru: "утюг", port: "ferro (m)  de engomar" },
      { ru: "гладильная доска", port: "tábua (f)  de engomar" },
      { ru: "телефон", port: "telefone (m)" },
      { ru: "мобильный телефон", port: "telemóvel (m)" },
      { ru: "машинка (пишущая)", port: "máquina (f)  de escrever" },
      { ru: "машинка (швейная)", port: "máquina (f)  de costura" },
      { ru: "микрофон", port: "microfone (m)" },
      { ru: "наушники", port: "auscultadores (m pl)" },
      { ru: "пульт", port: "controlo remoto (m)" },
      { ru: "компакт-диск", port: "CD (m)" },
      { ru: "кассета", port: "cassete (f)" },
      { ru: "пластинка (муз.)", port: "disco (m)  de vinil" },
    ],
  },
  {
    name: "Ремонт",
    dict: [
      { ru: "ремонт", port: "renovação (f)" },
      { ru: "ремонтировать", port: "reparar (vt)" },
      { ru: "приводить в порядок", port: "arranjar (vt)" },
      { ru: "переделывать", port: "refazer (vt)" },
      { ru: "краска", port: "tinta (f)" },
      { ru: "красить", port: "pintar (vt)" },
      { ru: "маляр", port: "pintor (m)" },
      { ru: "кисть", port: "pincel (m)" },
      { ru: "побелка (материал)", port: "cal (f)" },
      { ru: "белить", port: "caiar (vt)" },
      { ru: "обои", port: "papel (m)  de parede" },
      { ru: "оклеить обоями", port: "colar o papel" },
      { ru: "лак (для пола и т.п.)", port: "verniz (m)" },
      { ru: "покрывать лаком", port: "envernizar (vt)" },
    ],
  },
  {
    name: "Водопровод",
    dict: [
      { ru: "3 три", port: "três" },
      { ru: "вода", port: "água (f)" },
      { ru: "горячая вода", port: "água (f)  quente" },
      { ru: "холодная вода", port: "água (f)  fria" },
      { ru: "кран", port: "torneira (f)" },
      { ru: "капля", port: "gota (f)" },
      { ru: "капать", port: "gotejar (vi)" },
      { ru: "течь (протекать)", port: "vazar (vt)" },
      { ru: "течь (утечка)", port: "vazamento (m)" },
      { ru: "лужа (на полу и т.п.)", port: "poça (f)" },
      { ru: "труба", port: "tubo (m)  " },
      { ru: "инструменты", port: "ferramentas (f pl)" },
      { ru: "разводной ключ", port: "chave (f)  inglesa" },
      { ru: "открутить", port: "desenroscar (vt)" },
      { ru: "закрутить", port: "enroscar (vt)" },
      { ru: "прочищать", port: "desentupir (vt)" },
      { ru: "сантехник", port: "canalizador (m)" },
      { ru: "подвал", port: "cave (f)" },
      { ru: "канализация", port: "sistema (m)  de esgotos" },
    ],
  },

  {
    name: "Пожар",
    dict: [
      { ru: "огонь", port: "fogo (m)" },
      { ru: "пламя", port: "chama (f)" },
      { ru: "искра", port: "faísca (f)" },
      { ru: "дым", port: "fumo (m)" },
      { ru: "факел", port: "tocha (f)" },
      { ru: "костёр", port: "fogueira (f)" },
      { ru: "бензин", port: "gasolina (f)" },
      { ru: "керосин", port: "querosene (m)" },
      { ru: "горючий", port: "inflamável" },
      { ru: "взрывоопасный", port: "explosivo" },
      { ru: "НЕ КУРИТЬ!", port: "PROIBIDO FUMAR!" },
      { ru: "безопасность", port: "segurança (f)" },
      { ru: "опасность", port: "perigo (m)" },
      { ru: "опасный", port: "perigoso" },
      { ru: "загореться", port: "incendiar-se (vp)" },
      { ru: "взрыв", port: "explosão (f)" },
      { ru: "поджечь", port: "incendiar (vt)" },
      { ru: "поджигатель", port: "incendiário (m)" },
      { ru: "поджог", port: "incêndio (m)  criminoso" },
      { ru: "поджог", port: "incêndio (m)  criminoso" },
      { ru: "пылать", port: "arder (vi)" },
      { ru: "гореть", port: "queimar (vi)" },
      { ru: "сгореть", port: "queimar tudo (vi)" },
      { ru: "вызвать пожарных", port: "chamar os bombeiros" },
      { ru: "пожарный", port: "bombeiro (m)" },
      { ru: "пожарная машина", port: "carro (m)  de bombeiros" },
      { ru: "пожарная команда", port: "corpo (m)  de bombeiros" },
      { ru: "выдвижная лестница", port: "escada (f)  extensivel" },
      { ru: "шланг", port: "mangueira (f)" },
      { ru: "огнетушитель", port: "extintor (m)" },
      { ru: "каска", port: "capacete (m)" },
      { ru: "сирена", port: "sirene (f)" },
      { ru: "кричать", port: "gritar (vi)" },
      { ru: "звать на помощь", port: "chamar por socorro" },
      { ru: "спасатель", port: "salvador (m)" },
      { ru: "спасать", port: "salvar, resgatar (vt)" },
      { ru: "приехать", port: "chegar (vi)" },
      { ru: "тушить", port: "apagar (vt)" },
      { ru: "вода", port: "água (f)" },
      { ru: "песок", port: "areia (f)" },
      { ru: "руины", port: "ruínas (f pl)" },
      { ru: "рухнуть", port: "ruir (vi)" },
      { ru: "обвалиться", port: "desmoronar (vi)," },
      { ru: "обрушиться", port: "ir abaixo" },
      { ru: "обломок", port: "fragmento (m)" },
      { ru: "пепел", port: "cinza (f)" },
      { ru: "задохнуться", port: "sufocar (vi)" },
      { ru: "погибнуть", port: "ser morto, morrer (vi)" },
    ],
  },

  {
    name: "Офис, работа в офисе",
    dict: [
      { ru: "офис", port: "escritório (m)" },
      { ru: "кабинет (на работе)", port: "escritório (m)" },
      { ru: "ресепшн", port: "receção (f)" },
      { ru: "секретарь", port: "secretário (m)" },
      { ru: "секретарь (женщина)", port: "secretária (f)" },
      { ru: "директор", port: "diretor (m)" },
      { ru: "менеджер", port: "gerente (m)" },
      { ru: "бухгалтер", port: "contabilista (m)" },
      { ru: "сотрудник", port: "empregado (m)" },
      { ru: "мебель", port: "mobiliário (m)" },
      { ru: "стол", port: "mesa (f)" },
      { ru: "кресло", port: "cadeira (f)" },
      { ru: "тумбочка", port: "bloco (m)  de gavetas" },
      { ru: "вешалка (стойка)", port: "cabide (m)  de pé" },
      { ru: "компьютер", port: "computador (m)" },
      { ru: "принтер", port: "impressora (f)" },
      { ru: "факс", port: "fax (m)" },
      { ru: "копировальный аппарат", port: "fotocopiadora (f)" },
      { ru: "бумага", port: "papel (m)" },
      { ru: "бумага", port: "papel (m)" },
      { ru: "канцтовары", port: "artigos (m pl)  de escritório" },
      { ru: "коврик для мыши", port: "tapete (m)  de rato" },
      { ru: "лист (бумаги и т.п.)", port: "folha (f)" },
      { ru: "папка", port: "pasta (f)" },
      { ru: "каталог", port: "catálogo (m)" },
      { ru: "справочник", port: "diretório (f)  telefónico" },
      { ru: "документация", port: "documentação (f)" },
      { ru: "брошюра", port: "brochura (f)" },
      { ru: "листовка", port: "flyer (m)" },
      { ru: "образец", port: "amostra (f)" },
      { ru: "тренинг", port: "formação (f)" },
      { ru: "совещание", port: "reunião (f)" },
      { ru: "перерыв на обед", port: "hora (f)  de almoço" },
      { ru: "делать копию", port: "fazer uma cópia" },
      { ru: "размножить", port: "tirar cópias" },
    ],
  },
  {
    name: "Работа - 1",
    dict: [
      { ru: "дело, бизнес", port: "negócio (m)" },
      { ru: "дело (занятие)", port: "ocupação (f)" },
      { ru: "фирма", port: "firma, empresa (f)" },
      { ru: "компания", port: "companhia (f)" },
      { ru: "корпорация", port: "corporação (f)" },
      { ru: "предприятие", port: "empresa (f)" },
      { ru: "агентство", port: "agência (f)" },
      { ru: "договор", port: "acordo (m)" },
      { ru: "контракт", port: "contrato (m)" },
      { ru: "сделка", port: "acordo (m)" },
      { ru: "заказ", port: "encomenda (f)" },
      { ru: "условие (договора)", port: "condição (f)" },
      { ru: "оптом", port: "por grosso" },
      { ru: "оптовый", port: "grossista" },
      { ru: "продажа оптом", port: "venda (f)  por grosso" },
      { ru: "розничный", port: "a retalho" },
      { ru: "продажа в розницу", port: "venda (f)  a retalho" },
      { ru: "конкурент", port: "concorrente (m)" },
      { ru: "конкуренция", port: "concorrência (f)" },
      { ru: "конкуренция", port: "concorrência (f)" },
      { ru: "конкурировать", port: "competir (vi)" },
      { ru: "партнёр (соучредитель)", port: "sócio (m)" },
      { ru: "партнёрство", port: "parceria (f)" },
      { ru: "кризис", port: "crise (f)" },
      { ru: "банкротство", port: "bancarrota (f)" },
      { ru: "обанкротиться", port: "entrar em falência" },
      { ru: "трудность", port: "dificuldade (f)" },
      { ru: "проблема", port: "problema (m)" },
      { ru: "катастрофа (перен.)", port: "catástrofe (f)" },
      { ru: "экономика", port: "economia (f)" },
      { ru: "экономический", port: "económico" },
      { ru: "экономический спад", port: "recessão (f)  económica" },
    ],
  },

  {
    name: "Работа - 2",
    dict: [
      { ru: "цель", port: "objetivo (m)" },
      { ru: "задача", port: "tarefa (f)" },
      { ru: "торговать", port: "comercializar (vi)" },
      { ru: "сеть (дистрибьюторов)", port: "rede (f), cadeia (f)" },
      { ru: "склад (товары)", port: "estoque (m)" },
      { ru: "ассортимент", port: "sortido (m)" },
      { ru: "лидер", port: "líder (m)" },
      { ru: "крупный (о фирме)", port: "grande" },
      { ru: "монополия", port: "monopólio (m)" },
      { ru: "теория", port: "teoria (f)" },
      { ru: "практика", port: "prática (f)" },
      { ru: "опыт (знания)", port: "experiência (f)" },
      { ru: "тенденция", port: "tendência (f)" },
      { ru: "развитие", port: "desenvolvimento (m)" },
      { ru: "выгода", port: "rentabilidade (f)" },
      { ru: "выгодный", port: "rentável" },
      { ru: "делегация", port: "delegação (f)" },
      { ru: "заработная плата", port: "salário, ordenado (m)" },
      { ru: "исправлять (ошибку)", port: "corrigir (vt)" },
      { ru: "исправлять (ошибку)", port: "corrigir (vt)" },
      { ru: "командировка", port: "viagem (f)  de negócios" },
      { ru: "комиссия", port: "comissão (f)" },
      { ru: "контролировать", port: "controlar (vt)" },
      { ru: "конференция", port: "conferência (f)" },
      { ru: "лицензия", port: "licença (f)" },
      { ru: "надёжный (партнёр)", port: "fiável" },
      { ru: "начинание", port: "empreendimento (m)" },
      { ru: "норма (стандарт)", port: "norma (f)" },
      { ru: "обстоятельство", port: "circunstância (f)" },
      { ru: "обязанность", port: "dever (m)" },
      { ru: "организация (предприятие)", port: "empresa (f)" },
      { ru: "организация (процесс)", port: "organização (f)" },
      { ru: "организованный", port: "organizado" },
      { ru: "отмена", port: "anulação (f)" },
      { ru: "отменить", port: "anular, cancelar (vt)" },
      { ru: "отчёт", port: "relatório (m)" },
      { ru: "патент", port: "patente (f)" },
      { ru: "патентовать", port: "patentear (vt)" },
      { ru: "планировать", port: "planear (vt)" },
      { ru: "премия", port: "prémio (m)" },
      { ru: "профессиональный", port: "profissional" },
      { ru: "процедура", port: "procedimento (m)" },
      { ru: "рассмотреть (вопрос и т.п.)", port: "examinar (vt)" },
      { ru: "расчёт (прибыли и т.п.)", port: "cálculo (m)" },
      { ru: "репутация", port: "reputação (f)" },
      { ru: "риск", port: "risco (m)" },
      { ru: "руководить (чем-л.)", port: "dirigir (vt)" },
      { ru: "сведения", port: "informação (f)" },
      { ru: "собственность", port: "propriedade (f)" },
      { ru: "союз (группа фирм)", port: "união (f)" },
      { ru: "страхование жизни", port: "seguro (m)  de vida" },
      { ru: "страховать", port: "fazer um seguro" },
      { ru: "страховка", port: "seguro (m)" },
      { ru: "торги (аукцион)", port: "leilão (m)" },
      { ru: "уведомить", port: "notificar (vt)" },
      { ru: "управление (процесс)", port: "gestão (f)" },
      { ru: "услуга", port: "serviço (m)" },
      { ru: "форум", port: "fórum (m)" },
      { ru: "функционировать", port: "funcionar (vi)" },
    ],
  },
  {
    name: "Контракт",
    dict: [
      { ru: "контракт", port: "contrato (m)" },
      { ru: "соглашение", port: "acordo (m)" },
      { ru: "приложение", port: "adenda (f), anexo (m)" },
      { ru: "заключить контракт", port: "assinar o contrato" },
      { ru: "подпись", port: "assinatura (f)" },
      { ru: "подписать", port: "assinar (vt)" },
      { ru: "печать (на документе)", port: "carimbo (m)" },
      { ru: "предмет договора", port: "objeto (m)  do contrato" },
      { ru: "пункт (договора)", port: "cláusula (f)" },
      { ru: "стороны", port: "partes (f pl)" },
      { ru: "юридический адрес", port: "morada (f)  jurídica" },
      { ru: "нарушить контракт", port: "violar o contrato" },
      { ru: "обязательство", port: "obrigação (f)" },
      { ru: "ответственность", port: "responsabilidade (f)" },
      { ru: "форс-мажор", port: "força (f)  maior" },
      { ru: "спор", port: "litígio (m), disputa (f)" },
      { ru: "штрафные санкции", port: "multas (f pl)" },
    ],
  },
  {
    name: "Производство",
    dict: [
      { ru: "завод", port: "fábrica (f)" },
      { ru: "фабрика", port: "fábrica (f)" },
      { ru: "цех", port: "oficina (f)" },
      { ru: "производство (завод и т.п.)", port: "local (m)  de produção" },
      { ru: "промышленность", port: "indústria (f)" },
      { ru: "промышленный", port: "industrial" },
      { ru: "тяжелая промышленность", port: "indústria (f)  pesada" },
      { ru: "лёгкая промышленность", port: "indústria (f)  ligeira" },
      { ru: "продукция", port: "produção (f)" },
      { ru: "производить", port: "produzir (vt)" },
      { ru: "сырьё", port: "matérias (f pl)  primas" },
      { ru: "бригадир", port: "chefe (m)  de brigada" },
      { ru: "бригада", port: "brigada (f)" },
      { ru: "рабочий", port: "operário (m)" },
      { ru: "рабочий день", port: "dia (m)  de trabalho" },
      { ru: "остановка (перерыв)", port: "pausa (f)" },
      { ru: "собрание", port: "reunião (f)" },
      { ru: "обсуждать", port: "discutir (vt)" },
      { ru: "план", port: "plano (m)" },
      { ru: "план", port: "plano (m)" },
      { ru: "выполнять план", port: "cumprir o plano" },
      { ru: "норма выработки", port: "taxa (f)  de produção" },
      { ru: "качество", port: "qualidade (f)" },
      { ru: "контроль (проверка)", port: "controle (m)" },
      { ru: "контроль качества", port: "controle (m)  da qualidade" },
      { ru: "безопасность труда", port: "segurança (f)  no trabalho" },
      { ru: "дисциплина", port: "disciplina (f)" },
      { ru: "нарушение", port: "infração (f)" },
      { ru: "нарушать", port: "violar (vt)" },
      { ru: "забастовка", port: "greve (f)" },
      { ru: "забастовщик", port: "grevista (m)" },
      { ru: "бастовать", port: "estar em greve" },
      { ru: "профсоюз", port: "sindicato (m)" },
      { ru: "изобретать", port: "inventar (vt)" },
      { ru: "изобретение", port: "invenção (f)" },
      { ru: "исследование (поиск)", port: "pesquisa (f)" },
      { ru: "улучшать", port: "melhorar (vt)" },
      { ru: "технология", port: "tecnologia (f)" },
      { ru: "чертёж", port: "desenho (m)  técnico" },
      { ru: "груз", port: "carga (f)" },
      { ru: "грузчик", port: "carregador (m)" },
      { ru: "грузить", port: "carregar (vt)" },
      { ru: "погрузка", port: "carregamento (m)" },
      { ru: "разгружать", port: "descarregar (vt)" },
      { ru: "разгрузка", port: "descarga (f)" },
      { ru: "транспорт", port: "transporte (m)" },
      { ru: "транспортная компания", port: "companhia (f)  de transporte" },
      { ru: "транспортировать", port: "transportar (vt)" },
      { ru: "вагон (товарный)", port: "vagão (m)  de carga" },
      { ru: "цистерна", port: "cisterna (f)" },
      { ru: "грузовик", port: "camião (m)" },
      { ru: "станок", port: "máquina-ferramenta (f)" },
      { ru: "механизм", port: "mecanismo (m)" },
      { ru: "отходы", port: "resíduos (m pl)  industriais" },
      { ru: "упаковка (действие)", port: "embalagem (f)" },
      { ru: "упаковать", port: "embalar (vt)" },
    ],
  },
  {
    name: "Импорт, экспорт",
    dict: [
      { ru: "импорт", port: "importação (f)" },
      { ru: "импортёр", port: "importador (m)" },
      { ru: "импортировать", port: "importar (vt)" },
      { ru: "импортный", port: "de importação" },
      { ru: "экспорт", port: "exportação (f)" },
      { ru: "экспортёр", port: "exportador (m)" },
      { ru: "экспортировать", port: "exportar (vt)" },
      { ru: "экспортный", port: "de exportação" },
      { ru: "товар", port: "mercadoria (f)" },
      { ru: "партия (груза)", port: "lote (m)" },
      { ru: "вес", port: "peso (m)" },
      { ru: "объём", port: "volume (m)" },
      { ru: "кубический метр", port: "metro (m)  cúbico" },
      { ru: "производитель", port: "produtor (m)" },
      { ru: "транспортная компания", port: "companhia (f)  de transporte" },
      { ru: "контейнер", port: "contentor (m)" },
      { ru: "граница", port: "fronteira (f)" },
      { ru: "таможня", port: "alfândega (f)" },
      { ru: "таможенная пошлина", port: "taxa (f)  alfandegária" },
      { ru: "таможенная пошлина", port: "taxa (f)  alfandegária" },
      { ru: "таможенник", port: "funcionário (m)  da alfândega" },
      { ru: "контрабанда (действия)", port: "contrabando (m)" },
      { ru: "контрабанда (товары)", port: "contrabando (m)" },
    ],
  },

  {
    name: "Финансы",
    dict: [
      { ru: "акция", port: "ação (f)" },
      { ru: "облигация", port: "obrigação (f)" },
      { ru: "вексель", port: "letra (f)  de câmbio" },
      { ru: "биржа", port: "bolsa (f)" },
      { ru: "курс акций", port: "cotação (m)  das ações" },
      { ru: "подешеветь", port: "tornar-se mais barato" },
      { ru: "подорожать", port: "tornar-se mais caro" },
      { ru: "доля, пай", port: "parte (f)" },
      { ru: "контрольный пакет", port: "participação (f)  maioritária" },
      { ru: "инвестиции", port: "investimento (m)" },
      { ru: "инвестировать", port: "investir (vt)" },
      { ru: "процент", port: "percentagem (f)" },
      { ru: "проценты (доход)", port: "juros (m pl)" },
      { ru: "прибыль", port: "lucro (m)" },
      { ru: "прибыльный", port: "lucrativo" },
      { ru: "налог", port: "imposto (m)" },
      { ru: "валюта", port: "divisa (f)" },
      { ru: "национальный", port: "nacional" },
      { ru: "обмен", port: "câmbio (m)" },
      { ru: "обмен", port: "câmbio (m)" },
      { ru: "бухгалтер", port: "contabilista (m)" },
      { ru: "бухгалтерия", port: "contabilidade (f)" },
      { ru: "банкротство", port: "bancarrota (f)" },
      { ru: "крах", port: "falência (f)" },
      { ru: "разорение", port: "ruína (f)" },
      { ru: "разориться", port: "arruinar-se (vp)" },
      { ru: "инфляция", port: "inflação (f)" },
      { ru: "девальвация", port: "desvalorização (f)" },
      { ru: "капитал", port: "capital (m)" },
      { ru: "доход", port: "rendimento (m)" },
      { ru: "оборот", port: "volume (m)  de negócios" },
      { ru: "ресурсы", port: "recursos (m pl)" },
      { ru: "денежные средства", port: "recursos (m pl)  financeiros" },
      { ru: "накладные расходы", port: "despesas (f pl)  gerais" },
      { ru: "сократить (расходы)", port: "reduzir (vt)" },
    ],
  },
  {
    name: "Маркетинг",
    dict: [
      { ru: "маркетинг", port: "marketing (m)" },
      { ru: "рынок", port: "mercado (m)" },
      { ru: "сегмент рынка", port: "segmento (m)  do mercado" },
      { ru: "продукт", port: "produto (m)" },
      { ru: "товар", port: "mercadoria (f)" },
      { ru: "торговая марка, бренд", port: "marca (f)" },
      { ru: "торговая марка", port: "marca (f)  comercial" },
      { ru: "фирменный знак", port: "logótipo (m)" },
      { ru: "логотип", port: "logótipo (m)" },
      { ru: "спрос", port: "demanda (f)" },
      { ru: "предложение", port: "oferta (f)" },
      { ru: "потребность", port: "necessidade (f)" },
      { ru: "потребитель", port: "consumidor (m)" },
      { ru: "анализ", port: "análise (f)" },
      { ru: "анализировать", port: "analisar (vt)" },
      { ru: "позиционирование", port: "posicionamento (m)" },
      { ru: "позиционировать", port: "posicionar (vt)" },
      { ru: "цена", port: "preço (m)" },
      { ru: "ценовая политика", port: "política (f)  de preços" },
      { ru: "ценовая политика", port: "política (f)  de preços" },
      { ru: "ценообразование", port: "formação (f)  de preços" },
    ],
  },
  {
    name: "Мобильный телефон",
    dict: [
      { ru: "мобильный телефон", port: "telemóvel (m)" },
      { ru: "дисплей", port: "ecrã (m)" },
      { ru: "кнопка", port: "botão (m)" },
      { ru: "SIM-карта", port: "cartão SIM (m)" },
      { ru: "батарея", port: "bateria (f)" },
      { ru: "разрядиться", port: "descarregar-se" },
      { ru: "зарядное устройство", port: "carregador (m)" },
      { ru: "меню", port: "menu (m)" },
      { ru: "настройки", port: "definições (f pl)" },
      { ru: "мелодия", port: "melodia (f)" },
      { ru: "выбрать", port: "escolher (vt)" },
      { ru: "калькулятор", port: "calculadora (f)" },
      { ru: "автоответчик", port: "atendedor (m)  de chamadas" },
      { ru: "будильник", port: "despertador (m)" },
      { ru: "телефонная книга", port: "contatos (m pl)" },
      { ru: "SMS-сообщение", port: "mensagem (f)  de texto" },
      { ru: "абонент", port: "assinante (m)" },
    ],
  },
  {
    name: "Канцелярия",
    dict: [
      { ru: "шариковая ручка", port: "caneta (f)" },
      { ru: "перьевая ручка", port: "caneta (f)  tinteiro" },
      { ru: "карандаш", port: "lápis (m)" },
      { ru: "маркер", port: "marcador (m)" },
      { ru: "фломастер", port: "caneta (f)  de feltro" },
      { ru: "блокнот", port: "bloco (m)  de notas" },
      { ru: "ежедневник", port: "agenda (f)" },
      { ru: "линейка", port: "régua (f)" },
      { ru: "калькулятор", port: "calculadora (f)" },
      { ru: "ластик", port: "borracha (f)" },
      { ru: "кнопка", port: "pionés (m)" },
      { ru: "скрепка", port: "clipe (m)" },
      { ru: "клей", port: "cola (f)" },
      { ru: "степлер", port: "agrafador (m)" },
      { ru: "дырокол", port: "furador (m)" },
      { ru: "точилка", port: "afia-lápis (m)" },
    ],
  },
  {
    name: "Документы",
    dict: [
      { ru: "анкета", port: "questionário (m)" },
      { ru: "билет (входной)", port: "bilhete (m)" },
      { ru: "бланк (фирменный)", port: "papel (m)  timbrado" },
      { ru: "бэдж", port: "crachá (m)" },
      { ru: "бюллетень", port: "boletim (m)" },
      { ru: "визитная карточка", port: "cartão (m)  de visita" },
      { ru: "водительские права", port: "carta (f)  de condução" },
      { ru: "таможенная декларация", port: "declaração (f)  alfandegária" },
      { ru: "договор", port: "contrato (m)" },
      { ru: "документ", port: "documento (m)" },
      { ru: "завещание (документ)", port: "testamento (m)" },
      { ru: "закон", port: "lei (f)" },
      { ru: "записка", port: "bilhete (m)" },
      { ru: "заполнить (~ анкету)", port: "preencher (vt)" },
      { ru: "запрос", port: "inquérito (m)" },
      { ru: "зачеркнуть", port: "riscar (vt)" },
      { ru: "заявка", port: "ficha (f)  de inscrição" },
      { ru: "квитанция", port: "recibo (m)" },
      { ru: "конституция", port: "constituição (f)" },
      { ru: "конституция", port: "constituição (f)" },
      { ru: "копия", port: "cópia (f)" },
      { ru: "накладная", port: "guia (f)  de remessa" },
      { ru: "отчёт", port: "relatório (m)" },
      { ru: "паспорт", port: "passaporte (m)" },
      { ru: "печать (на документе)", port: "carimbo (m)" },
      { ru: "письмо", port: "carta (f)" },
      { ru: "подлинный (документ)", port: "autêntico" },
      { ru: "подписать", port: "assinar (vt)" },
      { ru: "подпись", port: "assinatura (f)" },
      { ru: "предъявлять", port: "mostrar (vt)" },
      { ru: "пригласительный билет", port: "convite (m)" },
      { ru: "приложение (к договору)", port: "adenda (f), anexo (m)" },
      { ru: "пропуск (документ)", port: "passe (m)" },
      { ru: "разрешение (документ)", port: "permissão (f)" },
      { ru: "рапорт", port: "informe (m)" },
      { ru: "расписка", port: "vale (f), nota (f)  promissória" },
      { ru: "резюме", port: "CV, currículo (m)" },
      { ru: "рукопись", port: "manuscrito (m)" },
      { ru: "сертификат", port: "certificado (m)" },
      { ru: "соглашение", port: "acordo (m)" },
      { ru: "список", port: "lista (f)" },
      { ru: "счёт (в бизнесе)", port: "fatura (f)" },
      { ru: "счёт (в ресторане)", port: "conta (f)" },
      { ru: "текст", port: "texto (m)" },
      { ru: "удостоверение", port: "bilhete (m)  de identidade" },
      { ru: "чек (банковский)", port: "cheque (m)" },
      { ru: "чек (кассовый)", port: "talão (f)" },
      { ru: "экземпляр (документа)", port: "exemplar (m)" },
    ],
  },

  {
    name: "Виды бизнеса",
    dict: [
      { ru: "строительство", port: "construção (f)" },
      { ru: "супермаркет", port: "supermercado (m)" },
      { ru: "театр", port: "teatro (m)" },
      { ru: "телевидение", port: "televisão (f)" },
      { ru: "торговля", port: "comércio (m)" },
      { ru: "торговля по каталогу", port: "vendas (f pl)  por catálogo" },
      { ru: "туризм", port: "viagens (m pl)" },
      { ru: "фармацевтика", port: "indústria (f)  farmacêutica" },
      { ru: "финансовые услуги", port: "serviços (m pl)  financeiros" },
      { ru: "химчистка", port: "lavandaria (f)" },
      { ru: "ювелир", port: "joalheiro (m)" },
      { ru: "ювелирные изделия", port: "joias (f pl)" },
      { ru: "юридические услуги", port: "serviços (m pl)  jurídicos" },
    ],
  },
  {
    name: "Выставка",
    dict: [
      { ru: "выставка", port: "feira (f)" },
      { ru: "торговая выставка", port: "exposição (f)  comercial" },
      { ru: "участие", port: "participação (f)" },
      { ru: "участвовать", port: "participar (vi)" },
      { ru: "участник", port: "participante (m)" },
      { ru: "дирекция, оргкомитет", port: "direção (f)" },
      { ru: "директор", port: "diretor (m)" },
      { ru: "организатор", port: "organizador (m)" },
      { ru: "организовывать", port: "organizar (vt)" },
      { ru: "заявка на участие", port: "ficha (f)  de inscrição" },
      { ru: "заполнить (документ)", port: "preencher (vt)" },
      { ru: "детали (подробности)", port: "detalhes (m pl)" },
      { ru: "информация", port: "informação (f)" },
      { ru: "цена", port: "preço (m)" },
      { ru: "включая", port: "incluindo" },
      { ru: "включать (о цене)", port: "incluir (vt)" },
      { ru: "платить", port: "pagar (vt)" },
      { ru: "регистрационный взнос", port: "taxa (m)  de inscrição" },
      { ru: "вход", port: "entrada (f)" },
      { ru: "вход", port: "entrada (f)" },
      { ru: "павильон", port: "pavilhão (m)" },
      { ru: "регистрировать", port: "inscrever (vt)" },
      { ru: "бэдж", port: "crachá (m)" },
      { ru: "выставочный стенд", port: "stand (m)" },
      { ru: "резервировать (~ стенд)", port: "reservar (vt)" },
      { ru: "витрина", port: "vitrina (f)" },
      { ru: "светильник", port: "foco, spot (m)" },
      { ru: "дизайн", port: "design (m)" },
      { ru: "располагать (размещать)", port: "pôr, colocar (vt)" },
      { ru: "дистрибьютор", port: "distribuidor (m)" },
      { ru: "поставщик", port: "fornecedor (m)" },
      { ru: "поставлять", port: "fornecer (vt)" },
      { ru: "страна", port: "país (m)" },
      { ru: "иностранный", port: "estrangeiro" },
      { ru: "продукт", port: "produto (m)" },
      { ru: "ассоциация", port: "associação (f)" },
      { ru: "конференц-зал", port: "sala (f)  de conferências" },
      { ru: "конгресс", port: "congresso (m)" },
      { ru: "конкурс", port: "concurso (m)" },
      { ru: "посетитель", port: "visitante (m)" },
      { ru: "посещать", port: "visitar (vt)" },
      { ru: "заказчик", port: "cliente (m)" },
    ],
  },

  {
    name: "СМИ",
    dict: [
      { ru: "газета", port: "jornal (m)" },
      { ru: "журнал", port: "revista (f)" },
      { ru: "пресса", port: "imprensa (f)" },
      { ru: "радио", port: "rádio (m)" },
      { ru: "радиостанция", port: "estação (f)  de rádio" },
      { ru: "телевидение", port: "televisão (f)" },
      { ru: "ведущий (ТВ)", port: "apresentador (m)" },
      { ru: "диктор", port: "locutor (m)" },
      { ru: "комментатор", port: "comentador (m)" },
      { ru: "журналист", port: "jornalista (m)" },
      { ru: "корреспондент", port: "correspondente (m)" },
      { ru: "фотокорреспондент", port: "repórter (m)  fotográfico" },
      { ru: "репортёр", port: "repórter (m)" },
      { ru: "редактор", port: "redator (m)" },
      { ru: "главный редактор", port: "redator-chefe (m)" },
      { ru: "подписаться (на газету)", port: "assinar a …" },
      { ru: "подписка", port: "assinatura (f)" },
      { ru: "подписчик", port: "assinante (m)" },
      { ru: "читать", port: "ler (vt)" },
      { ru: "читать", port: "ler (vt)" },
      { ru: "читатель", port: "leitor (m)" },
      { ru: "тираж (газеты, журнала)", port: "tiragem (f)" },
      { ru: "ежемесячный", port: "mensal" },
      { ru: "еженедельный", port: "semanal" },
      { ru: "номер (журнала)", port: "número (m)" },
      { ru: "свежий (напр. журнал)", port: "recente" },
      { ru: "заголовок", port: "título (m)" },
      { ru: "заметка (в газете)", port: "pequeno artigo (m)" },
      { ru: "рубрика", port: "rubrica (f)" },
      { ru: "статья", port: "artigo (m)" },
      { ru: "страница", port: "página (f)" },
      { ru: "репортаж", port: "reportagem (f)" },
      { ru: "событие", port: "evento (m)" },
      { ru: "сенсация", port: "sensação (f)" },
      { ru: "скандал", port: "escândalo (m)" },
      { ru: "скандальный", port: "escandaloso" },
      { ru: "громкий (скандал)", port: "grande" },
      { ru: "передача (ТВ)", port: "programa (m)  de TV" },
      { ru: "интервью", port: "entrevista (f)" },
      { ru: "прямая трансляция", port: "transmissão (f)  em direto" },
      { ru: "канал (вещания)", port: "canal (m)" },
    ],
  },
  {
    name: "Сельское хозяйство",
    dict: [
      { ru: "сельское хозяйство", port: "agricultura (f)" },
      { ru: "крестьянин", port: "camponês (m)" },
      { ru: "крестьянка", port: "camponesa (f)" },
      { ru: "фермер", port: "agricultor (m)" },
      { ru: "трактор", port: "trator (m)" },
      { ru: "комбайн", port: "ceifeira-debulhadora (f)" },
      { ru: "плуг", port: "arado (m)" },
      { ru: "пахать", port: "arar (vt)" },
      { ru: "пашня", port: "campo (m)  lavrado" },
      { ru: "борозда", port: "rego (m)" },
      { ru: "сеять", port: "semear (vt)" },
      { ru: "сеялка", port: "semeadora (f)" },
      { ru: "посев (действие)", port: "semeação (f)" },
      { ru: "коса (орудие)", port: "gadanha (m)" },
      { ru: "косить (траву)", port: "gadanhar (vt)" },
      { ru: "лопата", port: "pá (f)" },
      { ru: "копать (вскапывать)", port: "cavar (vt)" },
      { ru: "тяпка", port: "enxada (f)" },
      { ru: "полоть", port: "carpir (vt)" },
      { ru: "полоть", port: "carpir (vt)" },
      { ru: "сорняк", port: "erva (f)  daninha" },
      { ru: "лейка", port: "regador (m)" },
      { ru: "поливать", port: "regar (vt)" },
      { ru: "полив", port: "rega (f)" },
      { ru: "вилы", port: "forquilha (f)" },
      { ru: "грабли", port: "ancinho (m)" },
      { ru: "удобрение (вещество)", port: "fertilizante (m)" },
      { ru: "удобрять", port: "fertilizar (vt)" },
      { ru: "навоз", port: "estrume (m)" },
      { ru: "поле", port: "campo (m)" },
      { ru: "луг", port: "prado (m)" },
      { ru: "огород", port: "horta (f)" },
      { ru: "сад", port: "pomar (m)" },
      { ru: "пасти (скот)", port: "pastar (vt)" },
      { ru: "пастух", port: "pastor (m)" },
      { ru: "пастбище", port: "pastagem (f)" },
      { ru: "животноводство", port: "pecuária (f)" },
      { ru: "овцеводство", port: "criação (f)  de ovelhas" },
      { ru: "плантация", port: "plantação (f)" },
      { ru: "грядка", port: "canteiro (m)" },
      { ru: "парник", port: "invernadouro (m)" },
      { ru: "засуха", port: "seca (f)" },
      { ru: "засушливый (лето, период)", port: "seco" },
      { ru: "зерно, зерновые", port: "cereal (m)" },
      { ru: "зерновые (сущ.)", port: "cereais (m pl)" },
      { ru: "убирать (урожай)", port: "colher (vt)" },
      { ru: "мельник", port: "moleiro (m)" },
      { ru: "мельница", port: "moinho (m)" },
      { ru: "молоть (зерно)", port: "moer (vt)" },
      { ru: "мука", port: "farinha (f)" },
      { ru: "солома", port: "palha (f)" },
    ],
  },
  {
    name: "Стройка",
    dict: [
      { ru: "стройка (место)", port: "canteiro (m)  de obras" },
      { ru: "строить", port: "construir (vt)" },
      { ru: "строитель", port: "construtor (m)" },
      { ru: "проект", port: "projeto (m)" },
      { ru: "архитектор", port: "arquiteto (m)" },
      { ru: "рабочий", port: "operário (m)" },
      { ru: "фундамент", port: "fundação (f)" },
      { ru: "крыша", port: "telhado (m)" },
      { ru: "свая", port: "estaca (f)" },
      { ru: "стена", port: "parede (f)" },
      { ru: "арматура", port: "varões (m pl)  para betão" },
      { ru: "строительные леса", port: "andaime (m)" },
      { ru: "бетон", port: "betão (m)" },
      { ru: "гранит", port: "granito (m)" },
      { ru: "камень", port: "pedra (f)" },
      { ru: "кирпич", port: "tijolo (m)" },
      { ru: "песок", port: "areia (f)" },
      { ru: "цемент", port: "cimento (m)" },
      { ru: "штукатурка", port: "emboço (m)" },
      { ru: "штукатурка", port: "emboço (m)" },
      { ru: "штукатурить", port: "emboçar (vt)" },
      { ru: "краска", port: "tinta (f)" },
      { ru: "красить", port: "pintar (vt)" },
      { ru: "бочка", port: "barril (m)" },
      { ru: "строительный кран", port: "grua (f), guindaste (m)" },
      { ru: "поднимать", port: "erguer (vt)" },
      { ru: "опускать", port: "baixar (vt)" },
      { ru: "бульдозер", port: "buldózer (m)" },
      { ru: "экскаватор", port: "escavadora (f)" },
      { ru: "ковш", port: "caçamba (f)" },
      { ru: "копать (~ котлован)", port: "escavar (vt)" },
      { ru: "каска", port: "capacete (m)  de proteção" },
    ],
  },

  {
    name: "Наука",
    dict: [
      { ru: "наука", port: "ciência (f)" },
      { ru: "научный", port: "científico" },
      { ru: "учёный", port: "cientista (m)" },
      { ru: "теория", port: "teoria (f)" },
      { ru: "аксиома", port: "axioma (m)" },
      { ru: "анализ", port: "análise (f)" },
      { ru: "анализировать", port: "analisar (vt)" },
      { ru: "аргумент", port: "argumento (m)" },
      { ru: "вещество", port: "substância (f)" },
      { ru: "гипотеза", port: "hipótese (f)" },
      { ru: "дилемма", port: "dilema (m)" },
      { ru: "диссертация", port: "tese (f)" },
      { ru: "догма", port: "dogma (m)" },
      { ru: "доктрина", port: "doutrina (f)" },
      { ru: "исследование (поиск)", port: "investigação (f)" },
      { ru: "исследовать", port: "investigar (vt)" },
      { ru: "контроль (испытания)", port: "teste (m)" },
      { ru: "лаборатория", port: "laboratório (m)" },
      { ru: "метод", port: "método (m)" },
      { ru: "метод", port: "método (m)" },
      { ru: "молекула", port: "molécula (f)" },
      { ru: "мониторинг", port: "monitoramento (m)" },
      { ru: "открытие (в науке)", port: "descoberta (f)" },
      { ru: "постулат", port: "postulado (m)" },
      { ru: "принцип", port: "princípio (m)" },
      { ru: "прогноз", port: "prognóstico (m)" },
      { ru: "прогнозировать", port: "prognosticar (vt)" },
      { ru: "синтез", port: "síntese (f)" },
      { ru: "тенденция", port: "tendência (f)" },
      { ru: "теорема", port: "teorema (m)" },
      { ru: "учения", port: "ensinamentos (m pl)" },
      { ru: "факт", port: "facto (m)" },
      { ru: "экспедиция", port: "expedição (f)" },
      { ru: "эксперимент", port: "experiência (f)" },
      { ru: "академик", port: "académico (m)" },
      { ru: "бакалавр", port: "bacharel (m)" },
      { ru: "доктор", port: "doutor (m)" },
      { ru: "доцент", port: "docente (m)" },
      { ru: "магистр", port: "mestre (m)" },
      { ru: "профессор", port: "professor (m)  catedrático" },
    ],
  },

  {
    name: "Наука",
    dict: [
      { ru: "наука", port: "ciência (f)" },
      { ru: "научный", port: "científico" },
      { ru: "учёный", port: "cientista (m)" },
      { ru: "теория", port: "teoria (f)" },
      { ru: "аксиома", port: "axioma (m)" },
      { ru: "анализ", port: "análise (f)" },
      { ru: "анализировать", port: "analisar (vt)" },
      { ru: "аргумент", port: "argumento (m)" },
      { ru: "вещество", port: "substância (f)" },
      { ru: "гипотеза", port: "hipótese (f)" },
      { ru: "дилемма", port: "dilema (m)" },
      { ru: "диссертация", port: "tese (f)" },
      { ru: "догма", port: "dogma (m)" },
      { ru: "доктрина", port: "doutrina (f)" },
      { ru: "исследование (поиск)", port: "investigação (f)" },
      { ru: "исследовать", port: "investigar (vt)" },
      { ru: "контроль (испытания)", port: "teste (m)" },
      { ru: "лаборатория", port: "laboratório (m)" },
      { ru: "метод", port: "método (m)" },
      { ru: "метод", port: "método (m)" },
      { ru: "молекула", port: "molécula (f)" },
      { ru: "мониторинг", port: "monitoramento (m)" },
      { ru: "открытие (в науке)", port: "descoberta (f)" },
      { ru: "постулат", port: "postulado (m)" },
      { ru: "принцип", port: "princípio (m)" },
      { ru: "прогноз", port: "prognóstico (m)" },
      { ru: "прогнозировать", port: "prognosticar (vt)" },
      { ru: "синтез", port: "síntese (f)" },
      { ru: "тенденция", port: "tendência (f)" },
      { ru: "теорема", port: "teorema (m)" },
      { ru: "учения", port: "ensinamentos (m pl)" },
      { ru: "факт", port: "facto (m)" },
      { ru: "экспедиция", port: "expedição (f)" },
      { ru: "эксперимент", port: "experiência (f)" },
      { ru: "академик", port: "académico (m)" },
      { ru: "бакалавр", port: "bacharel (m)" },
      { ru: "доктор", port: "doutor (m)" },
      { ru: "доцент", port: "docente (m)" },
      { ru: "магистр", port: "mestre (m)" },
      { ru: "профессор", port: "professor (m)  catedrático" },
    ],
  },
  {
    name: "Поиск работы, увольнение",
    dict: [
      { ru: "работа", port: "trabalho (m) " },
      { ru: "кадры, штат персонал", port: "equipa (f) " },
      { ru: "карьера", port: "pessoal (m)" },
      { ru: "перспективы", port: "perspetivas (f pl)" },
      { ru: "мастерство", port: "mestria (f)" },
      { ru: "подбор", port: "seleção (f)" },
      { ru: "кадровое агентство", port: "agência (f)  de emprego" },
      { ru: "резюме", port: "CV, currículo (m)" },
      { ru: "собеседование", port: "entrevista (f)" },
      { ru: "вакансия", port: "vaga (f)" },
      { ru: "зарплата", port: "salário (m)" },
      { ru: "оклад", port: "salário (m)  fixo" },
      { ru: "оплата", port: "pagamento (m)" },
      { ru: "должность", port: "posto (m)" },
      { ru: "обязанность", port: "dever (m)" },
      { ru: "круг обязанностей", port: "gama (f)  de deveres" },
      { ru: "занятой (о человеке)", port: "ocupado" },
      { ru: "уволить", port: "despedir, demitir (vt)" },
      { ru: "увольнение", port: "demissão (f)" },
      { ru: "увольнение", port: "demissão (f)" },
      { ru: "безработица", port: "desemprego (m)" },
      { ru: "безработный (сущ.)", port: "desempregado (m)" },
      { ru: "пенсия", port: "reforma (f)" },
      { ru: "уйти на пенсию", port: "reformar-se" },
    ],
  },
  {
    name: "Люди в бизнесе",
    dict: [
      { ru: "начальство", port: "superiores (m pl)" },
      { ru: "президент", port: "presidente (m)" },
      { ru: "председатель", port: "presidente (m)  de direção" },
      { ru: "заместитель", port: "substituto (m)" },
      { ru: "помощник", port: "assistente (m)" },
      { ru: "секретарь", port: "secretário (m)" },
      { ru: "личный секретарь", port: "secretário (m)  pessoal" },
      { ru: "бизнесмен", port: "homem (m)  de negócios" },
      { ru: "предприниматель", port: "empresário (m)" },
      { ru: "основатель", port: "fundador (m)" },
      { ru: "основать", port: "fundar (vt)" },
      { ru: "учредитель", port: "fundador, sócio (m)" },
      { ru: "партнёр", port: "parceiro, sócio (m)" },
      { ru: "акционер", port: "acionista (m)" },
      { ru: "миллионер", port: "milionário (m)" },
      { ru: "миллиардер", port: "bilionário (m)" },
      { ru: "владелец", port: "proprietário (m)" },
      { ru: "землевладелец", port: "proprietário (m)  de terras" },
      { ru: "клиент", port: "cliente (m)" },
      { ru: "клиент", port: "cliente (m)" },
      { ru: "постоянный клиент", port: "cliente (m)  habitual" },
      { ru: "покупатель", port: "comprador (m)" },
      { ru: "посетитель", port: "visitante (m)" },
      { ru: "профессионал", port: "profissional (m)" },
      { ru: "эксперт", port: "perito (m)" },
      { ru: "специалист", port: "especialista (m)" },
      { ru: "банкир", port: "banqueiro (m)" },
      { ru: "брокер", port: "corretor (m)" },
      { ru: "кассир", port: "caixa (m, f)" },
      { ru: "бухгалтер", port: "contabilista (m)" },
      { ru: "охранник", port: "guarda (m)" },
      { ru: "инвестор", port: "investidor (m)" },
      { ru: "должник", port: "devedor (m)" },
      { ru: "кредитор", port: "credor (m)" },
      { ru: "заёмщик", port: "mutuário (m)" },
      { ru: "импортёр", port: "importador (m)" },
      { ru: "экспортёр", port: "exportador (m)" },
      { ru: "производитель", port: "produtor (m)" },
      { ru: "дистрибьютор", port: "distribuidor (m)" },
      { ru: "посредник", port: "intermediário (m)" },
      { ru: "консультант", port: "consultor (m)" },
      { ru: "представитель", port: "representante (m)" },
      { ru: "агент", port: "agente (m)" },
      { ru: "страховой агент", port: "agente (m)  de seguros" },
    ],
  },
  {
    name: "Профессии в сфере услуг",
    dict: [
      { ru: "повар", port: "cozinheiro (m)" },
      { ru: "шеф-повар", port: "cozinheiro chefe (m)" },
      { ru: "пекарь", port: "padeiro (m)" },
      { ru: "бармен", port: "barman (m)" },
      { ru: "официант", port: "empregado (m)  de mesa" },
      { ru: "официантка", port: "empregada (f)  de mesa" },
      { ru: "адвокат", port: "advogado (m)" },
      { ru: "юрист", port: "jurista (m)" },
      { ru: "нотариус", port: "notário (m)" },
      { ru: "электрик", port: "eletricista (m)" },
      { ru: "сантехник", port: "canalizador (m)" },
      { ru: "плотник", port: "carpinteiro (m)" },
      { ru: "массажист", port: "massagista (m)" },
      { ru: "массажистка", port: "massagista (f)" },
      { ru: "врач", port: "médico (m)" },
      { ru: "таксист", port: "taxista (m)" },
      { ru: "шофёр", port: "condutor (m)" },
      { ru: "курьер", port: "entregador (m)" },
      { ru: "горничная", port: "camareira (f)" },
      { ru: "горничная", port: "camareira (f)" },
      { ru: "охранник", port: "guarda (m)" },
      { ru: "стюардесса", port: "hospedeira (f)  de bordo" },
      { ru: "учитель", port: "professor (m)" },
      { ru: "библиотекарь", port: "bibliotecário (m)" },
      { ru: "переводчик", port: "tradutor (m)" },
      { ru: "переводчик (устный)", port: "intérprete (m)" },
      { ru: "гид (экскурсовод)", port: "guia (m)" },
      { ru: "парикмахер", port: "cabeleireiro (m)" },
      { ru: "почтальон", port: "carteiro (m)" },
      { ru: "продавец", port: "vendedor (m)" },
      { ru: "садовник", port: "jardineiro (m)" },
      { ru: "слуга", port: "criado (m)" },
      { ru: "служанка", port: "criada (f)" },
      { ru: "уборщица", port: "empregada (f)  de limpeza" },
    ],
  },

  {
    name: "Государсвенные служащие",
    dict: [
      { ru: "король", port: "rei (m)" },
      { ru: "королева", port: "rainha (f)" },
      { ru: "принц", port: "príncipe (m)" },
      { ru: "принцесса", port: "princesa (f)" },
      { ru: "царь", port: "czar (m)" },
      { ru: "царица", port: "czarina (f)" },
      { ru: "президент", port: "presidente (m)" },
      { ru: "министр", port: "ministro (m)" },
      { ru: "премьер-министр", port: "primeiro-ministro (m)" },
      { ru: "сенатор", port: "senador (m)" },
      { ru: "дипломат", port: "diplomata (m)" },
      { ru: "консул", port: "cônsul (m)" },
      { ru: "посол", port: "embaixador (m)" },
      { ru: "советник", port: "conselheiro (m)" },
      { ru: "чиновник", port: "funcionário (m)" },
      { ru: "префект", port: "prefeito (m)" },
      { ru: "мэр", port: "Presidente (m)  da Câmara" },
      { ru: "судья", port: "juiz (m)" },
      { ru: "прокурор", port: "procurador (m)" },
      { ru: "прокурор", port: "procurador (m)" },
      { ru: "миссионер", port: "missionário (m)" },
      { ru: "монах", port: "monge (m)" },
      { ru: "аббат", port: "abade (m)" },
      { ru: "раввин", port: "rabino (m)" },
    ],
  },
  {
    name: "Профессии в сельском хозяйстве",
    dict: [
      { ru: "пчеловод", port: "apicultor (m)" },
      { ru: "пастух", port: "pastor (m)" },
      { ru: "агроном", port: "agrónomo (m)" },
      { ru: "животновод", port: "criador (m)  de gado" },
      { ru: "ветеринар", port: "veterinário (m)" },
      { ru: "фермер", port: "agricultor (m)" },
      { ru: "винодел", port: "vinicultor (m)" },
      { ru: "зоолог", port: "zoólogo (m)" },
      { ru: "ковбой", port: "cowboy (m)" },
    ],
  },
  {
    name: "Профессии в искусстве",
    dict: [
      { ru: "актёр", port: "ator (m)" },
      { ru: "актриса", port: "atriz (f)" },
      { ru: "певец", port: "cantor (m)" },
      { ru: "певица", port: "cantora (f)" },
      { ru: "танцор", port: "bailarino (m)" },
      { ru: "танцовщица", port: "bailarina (f)" },
      { ru: "артист", port: "artista (m)" },
      { ru: "артистка", port: "artista (f)" },
      { ru: "музыкант", port: "músico (m)" },
      { ru: "пианист", port: "pianista (m)" },
      { ru: "гитарист", port: "guitarrista (m)" },
      { ru: "дирижёр", port: "maestro (m)" },
      { ru: "композитор", port: "compositor (m)" },
      { ru: "импресарио", port: "empresário (m)" },
      { ru: "режиссёр", port: "realizador (m)" },
      { ru: "продюсер", port: "produtor (m)" },
      { ru: "сценарист", port: "argumentista (m)" },
      { ru: "критик", port: "crítico (m)" },
      { ru: "писатель", port: "escritor (m)" },
      { ru: "писатель", port: "escritor (m)" },
      { ru: "поэт", port: "poeta (m)" },
      { ru: "скульптор", port: "escultor (m)" },
      { ru: "художник", port: "pintor (m)" },
      { ru: "жонглёр", port: "malabarista (m)" },
    ],
  },
  {
    name: "Профессии разные",
    dict: [
      { ru: "инженер", port: "engenheiro (m)" },
      { ru: "моряк", port: "marujo (m)" },
      { ru: "матрос", port: "marinheiro (m)" },
      { ru: "спасатель", port: "salvador (m)" },
      { ru: "пожарный", port: "bombeiro (m)" },
      { ru: "полицейский", port: "polícia (m)" },
      { ru: "сторож", port: "guarda-noturno (m)" },
      { ru: "сыщик", port: "detetive (m)" },
      { ru: "таможенник", port: "funcionário (m)  da alfândega" },
      { ru: "телохранитель", port: "guarda-costas (m)" },
      { ru: "охранник (в тюрьме)", port: "guarda (m)  prisional" },
      { ru: "инспектор", port: "inspetor (m)" },
      { ru: "спортсмен", port: "desportista (m)" },
      { ru: "тренер", port: "treinador (m)" },
      { ru: "мясник", port: "carniceiro (m)" },
      { ru: "сапожник", port: "sapateiro (m)" },
      { ru: "коммерсант", port: "comerciante (m)" },
      { ru: "грузчик", port: "carregador (m)" },
      { ru: "модельер", port: "estilista (m)" },
      { ru: "модельер", port: "estilista (m)" },
      { ru: "модель (манекенщица)", port: "modelo (f)" },
    ],
  },
  {
    name: "Социальное положение",
    dict: [
      { ru: "школьник", port: "escolar (m)" },
      { ru: "студент", port: "estudante (m)" },
      { ru: "философ", port: "filósofo (m)" },
      { ru: "экономист", port: "economista (m)" },
      { ru: "изобретатель", port: "inventor (m)" },
      { ru: "безработный (сущ.)", port: "desempregado (m)" },
      { ru: "пенсионер", port: "reformado (m)" },
      { ru: "шпион", port: "espião (m)" },
      { ru: "заключённый", port: "preso (m)" },
      { ru: "забастовщик", port: "grevista (m)" },
      { ru: "бюрократ", port: "burocrata (m)" },
      { ru: "путешественник", port: "viajante (m)" },
      { ru: "гомосексуалист", port: "homossexual (m)" },
      { ru: "хакер", port: "hacker (m)" },
      { ru: "хиппи", port: "hippie" },
      { ru: "бандит", port: "bandido (m)" },
      { ru: "наёмный убийца", port: "assassino (m)  a soldo" },
      { ru: "наркоман", port: "toxicodependente (m)" },
      { ru: "торговец наркотиками", port: "traficante (m)" },
      { ru: "торговец наркотиками", port: "traficante (m)" },
      { ru: "проститутка", port: "prostituta (f)" },
      { ru: "сутенёр", port: "chulo (m)" },
      { ru: "колдун", port: "bruxo (m)" },
      { ru: "колдунья", port: "bruxa (f)" },
      { ru: "пират", port: "pirata (m)" },
      { ru: "раб", port: "escravo (m)" },
      { ru: "самурай", port: "samurai (m)" },
      { ru: "дикарь", port: "selvagem (m)" },
    ],
  },
  {
    name: "Виды спорта",
    dict: [
      { ru: "спортсмен", port: "desportista (m)" },
      { ru: "вид спорта", port: "tipo (m)  de desporto" },
      { ru: "баскетбол", port: "basquetebol (m)" },
      { ru: "баскетболист", port: "jogador (m)  de basquetebol" },
      { ru: "бейсбол", port: "beisebol (m)" },
      { ru: "бейсболист", port: "jogador (m)  de beisebol" },
      { ru: "футбол", port: "futebol (m)" },
      { ru: "футболист", port: "futebolista (m)" },
      { ru: "вратарь", port: "guarda-redes (m)" },
      { ru: "хоккей", port: "hóquei (m)" },
      { ru: "хоккеист", port: "jogador (m)  de hóquei" },
      { ru: "волейбол", port: "voleibol (m)" },
      { ru: "волейболист", port: "jogador (m)  de voleibol" },
      { ru: "бокс", port: "boxe (m)" },
      { ru: "боксёр", port: "boxeador, pugilista (m)" },
      { ru: "борьба", port: "luta (f)" },
      { ru: "борец", port: "lutador (m)" },
      { ru: "карате", port: "karaté (m)" },
      { ru: "каратист", port: "karateca (m)" },
      { ru: "каратист", port: "karateca (m)" },
      { ru: "дзюдо", port: "judo (m)" },
      { ru: "дзюдоист", port: "judoca (m)" },
      { ru: "теннис", port: "ténis (m)" },
      { ru: "теннисист", port: "tenista (m)" },
      { ru: "плавание", port: "natação (f)" },
      { ru: "пловец", port: "nadador (m)" },
      { ru: "фехтование", port: "esgrima (f)" },
      { ru: "фехтовальщик", port: "esgrimista (m)" },
      { ru: "шахматы", port: "xadrez (m)" },
      { ru: "шахматист", port: "xadrezista (m)" },
      { ru: "альпинизм", port: "alpinismo (m)" },
      { ru: "альпинист", port: "alpinista (m)" },
      { ru: "бег", port: "corrida (f)" },
      { ru: "бегун", port: "corredor (m)" },
      { ru: "лёгкая атлетика", port: "atletismo (m)" },
      { ru: "атлет", port: "atleta (m)" },
      { ru: "конный спорт", port: "hipismo (m)" },
      { ru: "наездник", port: "cavaleiro (m)" },
      { ru: "фигурное катание", port: "patinagem (f)  artística" },
      { ru: "фигурист", port: "patinador (m)" },
      { ru: "фигуристка", port: "patinadora (f)" },
      { ru: "тяжёлая атлетика", port: "halterofilismo (m)" },
      { ru: "тяжелоатлет, штангист", port: "halterofilista (m)" },
      { ru: "автогонки", port: "corrida (f)  de carros" },
      { ru: "гонщик", port: "piloto (m)" },
      { ru: "велоспорт", port: "ciclismo (m)" },
      { ru: "велосипедист", port: "ciclista (m)" },
      { ru: "прыжки в длину", port: "salto (m)  em comprimento" },
      { ru: "прыжки с шестом", port: "salto (m)  à vara" },
      { ru: "прыгун", port: "atleta (m)  de saltos" },
      { ru: "прыгун", port: "atleta (m)  de saltos" },
      { ru: "американский футбол", port: "futebol (m)  americano" },
      { ru: "бадминтон", port: "badminton (m)" },
      { ru: "биатлон", port: "biatlo (m)" },
      { ru: "бильярд", port: "bilhar (m)" },
      { ru: "бобслей", port: "bobsleigh (m)" },
      { ru: "бодибилдинг", port: "musculação (f)" },
      { ru: "водное поло", port: "pólo (m)  aquático" },
      { ru: "гандбол", port: "handebol (m)" },
      { ru: "гольф", port: "golfe (m)" },
      { ru: "гребля", port: "remo (m)" },
      { ru: "дайвинг", port: "mergulho (m)" },
      { ru: "лыжные гонки", port: "corrida (f)  de esqui" },
      { ru: "настольный теннис", port: "ténis (m)  de mesa" },
      { ru: "парусный спорт", port: "vela (f)" },
      { ru: "ралли", port: "rali (m)" },
      { ru: "регби", port: "rugbi (m)" },
      { ru: "сноуборд", port: "snowboard (m)" },
      { ru: "стрельба из лука", port: "tiro (m)  com arco" },
    ],
  },
  {
    name: "Спортивный зал",
    dict: [
      { ru: "штанга", port: "barra (f)" },
      { ru: "гантели", port: "halteres (m pl)" },
      { ru: "тренажёр", port: "aparelho (m)  de musculaçao" },
      { ru: "велотренажёр", port: "bicicleta (f)  ergométrica" },
      { ru: "беговая дорожка", port: "passadeira (f)" },
      { ru: "перекладина", port: "barra (f)  fixa" },
      { ru: "брусья", port: "barras (f)  paralelas" },
      { ru: "конь", port: "cavalo (m)" },
      { ru: "мат", port: "tapete (m)  de ginástica" },
      { ru: "скакалка", port: "corda (f)  de saltar" },
      { ru: "аэробика", port: "aeróbica (f)" },
      { ru: "йога", port: "ioga (f)" },
    ],
  },
  {
    name: "Хоккей",
    dict: [
      { ru: "хоккей", port: "hóquei (m)" },
      { ru: "хоккеист", port: "jogador (m)  de hóquei" },
      { ru: "играть в хоккей", port: "jogar hóquei" },
      { ru: "лёд", port: "gelo (m)" },
      { ru: "шайба", port: "disco (m)" },
      { ru: "клюшка", port: "taco (m)  de hóquei" },
      { ru: "коньки", port: "patins (m pl)  de gelo" },
      { ru: "борт", port: "muro (m)" },
      { ru: "бросок (хокк.)", port: "tiro (m)" },
      { ru: "вратарь", port: "guarda-redes (m)" },
      { ru: "гол", port: "golo (m)" },
      { ru: "забить гол", port: "marcar um golo" },
      { ru: "период (хокк.)", port: "tempo (m)" },
      { ru: "2-й период", port: "segundo tempo (m)" },
      { ru: "скамейка запасных", port: "banco (m)  de reservas" },
    ],
  },
  {
    name: "Футбол",
    dict: [
      { ru: "футбол", port: "futebol (m)" },
      { ru: "футболист", port: "futebolista (m)" },
      { ru: "играть в футбол", port: "jogar futebol" },
      { ru: "высшая лига", port: "Liga Principal (f)" },
      { ru: "футбольный клуб", port: "clube (m)  de futebol" },
      { ru: "тренер", port: "treinador (m)" },
      { ru: "владелец", port: "proprietário (m)" },
      { ru: "команда", port: "equipa (f)" },
      { ru: "капитан команды", port: "capitão (m)  da equipa" },
      { ru: "игрок", port: "jogador (m)" },
      { ru: "запасной игрок", port: "jogador (m)  de reserva" },
      { ru: "нападающий", port: "atacante (m)" },
      { ru: "центральный нападающий", port: "avançado (m)  centro" },
      { ru: "бомбардир", port: "marcador (m)" },
      { ru: "защитник", port: "defesa (m)" },
      { ru: "полузащитник", port: "médio (m)" },
      { ru: "матч", port: "jogo (m)" },
      { ru: "встречаться", port: "defrontar-se (vp)" },
      { ru: "финал", port: "final (m)" },
      { ru: "финал", port: "final (m)" },
      { ru: "полуфинал", port: "meia-final (f)" },
      { ru: "чемпионат", port: "campeonato (m)" },
      { ru: "тайм", port: "tempo (m)" },
      { ru: "1-й тайм", port: "primeiro tempo (m)" },
      { ru: "перерыв", port: "intervalo (m)" },
      { ru: "ворота", port: "baliza (f)" },
      { ru: "вратарь", port: "guarda-redes (m)" },
      { ru: "штанга", port: "poste (m)" },
      { ru: "перекладина (ворот)", port: "travessa (f)" },
      { ru: "сетка (ворот)", port: "rede (f)" },
      { ru: "пропустить гол", port: "sofrer um golo" },
      { ru: "мяч", port: "bola (f)" },
      { ru: "пас, передача", port: "passe (m)" },
      { ru: "удар", port: "chute (m)" },
      { ru: "нанести удар", port: "chutar (vt)" },
      { ru: "штрафной удар", port: "tiro (m)  livre" },
      { ru: "угловой удар", port: "canto (m)" },
      { ru: "атака", port: "ataque (m)" },
      { ru: "контратака", port: "contra-ataque (m)" },
      { ru: "комбинация", port: "combinação (f)" },
      { ru: "арбитр", port: "árbitro (m)" },
      { ru: "свистеть", port: "apitar (vi)" },
      { ru: "свисток", port: "apito (m)" },
      { ru: "нарушение", port: "falta (f)" },
      { ru: "нарушить", port: "cometer a falta" },
      { ru: "удалить с поля", port: "expulsar (vt)" },
      { ru: "жёлтая карточка", port: "cartão (m)  amarelo" },
      { ru: "красная карточка", port: "cartão (m)  vermelho" },
      { ru: "дисквалификация", port: "desqualificação (f)" },
      { ru: "дисквалифицировать", port: "desqualificar (vt)" },
      { ru: "пенальти", port: "penálti (m)" },
      { ru: "стенка (футб.)", port: "barreira (f)" },
      { ru: "забить", port: "marcar (vt)" },
      { ru: "гол", port: "golo (m)" },
      { ru: "забить гол", port: "marcar um golo" },
      { ru: "замена", port: "substituto (m)" },
      { ru: "заменить (игрока)", port: "substituir (vt)" },
      { ru: "правила", port: "regulamento (m)" },
      { ru: "тактика", port: "tática (f)" },
    ],
  },

  {
    name: "Горные лыжи",
    dict: [
      { ru: "лыжи", port: "esqui (m)" },
      { ru: "кататься на лыжах", port: "esquiar (vi)" },
      { ru: "горнолыжный курорт", port: "estância (f)  de esqui" },
      { ru: "подъёмник", port: "teleférico (m)" },
      { ru: "палки (лыжные)", port: "bastões (m pl)  de esqui" },
      { ru: "склон", port: "declive (m)" },
      { ru: "слалом", port: "slalom (m)" },
    ],
  },
  {
    name: "Теннис, гольф",
    dict: [
      { ru: "гольф", port: "golfe (m)" },
      { ru: "гольф-клуб", port: "clube (m)  de golfe" },
      { ru: "игрок в гольф", port: "jogador (m)  de golfe" },
      { ru: "лунка", port: "buraco (m)" },
      { ru: "клюшка", port: "taco (m)" },
      { ru: "тележка для клюшек", port: "trolley (m)" },
      { ru: "теннис", port: "ténis (m)" },
      { ru: "корт", port: "quadra (f)  de ténis" },
      { ru: "подача", port: "saque (m)" },
      { ru: "подавать", port: "sacar (vi)" },
      { ru: "ракетка", port: "raquete (f)" },
      { ru: "сетка", port: "rede (f)" },
      { ru: "мяч", port: "bola (f)" },
    ],
  },
  {
    name: "Шахматы",
    dict: [
      { ru: "шахматы (игра)", port: "xadrez (m)" },
      { ru: "шахматы (фигуры)", port: "peças (f pl)  de xadrez" },
      { ru: "шахматист", port: "xadrezista (m)" },
      { ru: "шахматная доска", port: "tabuleiro (m)  de xadrez" },
      { ru: "фигура (шахм.)", port: "peça (f)  de xadrez" },
      { ru: "белые", port: "brancas (f pl)" },
      { ru: "чёрные", port: "pretas (f pl)" },
      { ru: "пешка", port: "peão (m)" },
      { ru: "слон", port: "bispo (m)" },
      { ru: "конь", port: "cavalo (m)" },
      { ru: "ладья", port: "torre (f), roque (m)" },
      { ru: "ферзь", port: "dama (f)" },
      { ru: "король", port: "rei (m)" },
      { ru: "ход", port: "vez (f)" },
      { ru: "ходить", port: "mover (vt)" },
      { ru: "пожертвовать (фигуру)", port: "sacrificar (vt)" },
      { ru: "рокировка (шахм.)", port: "roque (m)" },
      { ru: "шах", port: "xeque (m)" },
      { ru: "мат", port: "xeque-mate (m)" },
      { ru: "мат", port: "xeque-mate (m)" },
      { ru: "шахматный турнир", port: "torneio (m)  de xadrez" },
      { ru: "гроссмейстер", port: "grão-mestre (m)" },
      { ru: "комбинация", port: "combinação (f)" },
      { ru: "партия", port: "partida (f)" },
      { ru: "шашки", port: "jogo (m)  de damas" },
    ],
  },

  {
    name: "Бокс",
    dict: [
      { ru: "бокс", port: "boxe (m)" },
      { ru: "бой (в боксе)", port: "combate (m)" },
      { ru: "поединок", port: "duelo (m)" },
      { ru: "раунд", port: "round, assalto (m)" },
      { ru: "ринг", port: "ringue (m)" },
      { ru: "гонг", port: "gongo (m)" },
      { ru: "удар", port: "murro, soco (m)" },
      { ru: "нокдаун", port: "knockdown (m)" },
      { ru: "нокаут", port: "nocaute (m)" },
      { ru: "нокаутировать", port: "nocautear (vt)" },
      { ru: "боксёрская перчатка", port: "luva (f)  de boxe" },
      { ru: "рефери", port: "árbitro (m)" },
      { ru: "легкий вес", port: "peso-leve (m)" },
      { ru: "средний вес", port: "peso-médio (m)" },
      { ru: "тяжелый вес", port: "peso-pesado (m)" },
    ],
  },

  {
    name: "Спорт разное",
    dict: [
      { ru: "Олимпийские игры", port: "Jogos (m pl)  Olímpicos" },
      { ru: "победитель", port: "vencedor (m)" },
      { ru: "побеждать", port: "vencer (vi)" },
      { ru: "выиграть", port: "ganhar (vi)" },
      { ru: "лидер", port: "líder (m)" },
      { ru: "лидировать", port: "liderar (vt)" },
      { ru: "первое место", port: "primeiro lugar (m)" },
      { ru: "второе место", port: "segundo lugar (m)" },
      { ru: "третье место", port: "terceiro lugar (m)" },
      { ru: "медаль", port: "medalha (f)" },
      { ru: "трофей", port: "troféu (m)" },
      { ru: "кубок", port: "taça (f)" },
      { ru: "приз", port: "prémio (m)" },
      { ru: "главный приз", port: "prémio (m)  principal" },
      { ru: "рекорд", port: "recorde (m)" },
      { ru: "ставить рекорд", port: "estabelecer um recorde" },
      { ru: "финал (спорт.)", port: "final (m)" },
      { ru: "финальный", port: "final" },
      { ru: "чемпион", port: "campeão (m)" },
      { ru: "чемпион", port: "campeão (m)" },
      { ru: "чемпионат", port: "campeonato (m)" },
      { ru: "стадион", port: "estádio (m)" },
      { ru: "трибуна (стадиона)", port: "bancadas (f pl)" },
      { ru: "фан, болельщик", port: "fã, adepto (m)" },
      { ru: "противник", port: "adversário (m)" },
      { ru: "старт", port: "partida (f)" },
      { ru: "финиш", port: "chegada, meta (f)" },
      { ru: "поражение (проигрыш)", port: "derrota (f)" },
      { ru: "проиграть", port: "perder (vt)" },
      { ru: "судья", port: "árbitro (m)" },
      { ru: "жюри", port: "júri (m)" },
      { ru: "счёт", port: "resultado (m)" },
      { ru: "ничья", port: "empate (m)" },
      { ru: "сыграть вничью", port: "empatar (vi)" },
      { ru: "очко", port: "ponto (m)" },
      { ru: "результат", port: "resultado (m)  final" },
      { ru: "тайм, период", port: "tempo, período (m)" },
      { ru: "перерыв", port: "intervalo (m)" },
      { ru: "допинг", port: "doping (m)" },
      { ru: "штрафовать (спорт)", port: "penalizar (vt)" },
      { ru: "дисквалифицировать", port: "desqualificar (vt)" },
      { ru: "снаряд (спорт.)", port: "aparelho (m)" },
      { ru: "копьё (спорт.)", port: "dardo (m)" },
      { ru: "ядро", port: "peso (m)" },
      { ru: "шар (в бильярде и т.п.)", port: "bola (f)" },
      { ru: "цель (при стрельбе)", port: "alvo (m)" },
      { ru: "мишень", port: "alvo (m)" },
      { ru: "стрелять", port: "atirar, disparar (vi)" },
      { ru: "точный (попадание)", port: "preciso" },
      { ru: "тренер", port: "treinador (m)" },
      { ru: "тренировать", port: "treinar (vt)" },
      { ru: "тренироваться", port: "treinar-se (vp)" },
      { ru: "тренировка", port: "treino (m)" },
      { ru: "спортзал", port: "ginásio (m)" },
      { ru: "упражнение", port: "exercício (m)" },
      { ru: "разминка", port: "aquecimento (m)" },
    ],
  },
  {
    name: "Школа",
    dict: [
      { ru: "школа", port: "escola (f)" },
      { ru: "директор школы", port: "diretor (m)  de escola" },
      { ru: "ученик", port: "aluno (m)" },
      { ru: "ученица", port: "aluna (f)" },
      { ru: "школьник", port: "escolar (m)" },
      { ru: "школьница", port: "escolar (f)" },
      { ru: "учить (об учителе)", port: "ensinar (vt)" },
      { ru: "учить (что-л.)", port: "aprender (vt)" },
      { ru: "учить наизусть", port: "aprender de cor" },
      { ru: "учиться (чему-л.)", port: "estudar (vi)" },
      { ru: "учиться (ходить в школу)", port: "andar na escola" },
      { ru: "идти в школу", port: "ir à escola" },
      { ru: "алфавит", port: "alfabeto (m)" },
      { ru: "предмет (дисциплина)", port: "disciplina (f)" },
      { ru: "класс", port: "sala (f)  de aula" },
      { ru: "урок", port: "lição, aula (f)" },
      { ru: "перемена", port: "recreio (m)" },
      { ru: "звонок", port: "toque (m)" },
      { ru: "парта", port: "carteira (f)" },
      { ru: "парта", port: "carteira (f)" },
      { ru: "доска (школьная)", port: "quadro (m)  negro" },
      { ru: "отметка", port: "nota (f)" },
      { ru: "хорошая отметка", port: "boa nota (f)" },
      { ru: "плохая отметка", port: "nota (f)  baixa" },
      { ru: "ставить отметку", port: "dar uma nota" },
      { ru: "ошибка", port: "erro (m)" },
      { ru: "делать ошибки", port: "fazer erros" },
      { ru: "исправлять (ошибку)", port: "corrigir (vt)" },
      { ru: "шпаргалка", port: "cábula (f)" },
      { ru: "домашнее задание", port: "trabalho (m)  de casa" },
      { ru: "упражнение", port: "exercício (m)" },
      { ru: "присутствовать", port: "estar presente" },
      { ru: "отсутствовать", port: "estar ausente" },
      { ru: "пропускать уроки", port: "faltar às aulas" },
      { ru: "наказывать", port: "punir (vt)" },
      { ru: "наказание", port: "punição (f)" },
      { ru: "поведение", port: "comportamento (m)" },
      { ru: "дневник", port: "boletim (m)  escolar" },
      { ru: "карандаш", port: "lápis (m)" },
      { ru: "ластик", port: "borracha (f)" },
      { ru: "мел", port: "giz (m)" },
      { ru: "пенал", port: "estojo (m)" },
      { ru: "портфель", port: "pasta (f)  escolar" },
      { ru: "ручка", port: "caneta (f)" },
      { ru: "тетрадь", port: "caderno (m)" },
      { ru: "учебник", port: "manual (m)  escolar" },
      { ru: "циркуль", port: "compasso (m)" },
      { ru: "чертить", port: "traçar (vt)" },
      { ru: "чертёж", port: "desenho (m)  técnico" },
      { ru: "стихотворение", port: "poesia (f)" },
      { ru: "наизусть", port: "de cor" },
      { ru: "учить наизусть", port: "aprender de cor" },
      { ru: "каникулы", port: "férias (f pl)" },
      { ru: "быть на каникулах", port: "estar de férias" },
      { ru: "провести каникулы", port: "passar as férias" },
      { ru: "контрольная работа", port: "teste (m)" },
      { ru: "сочинение", port: "composição, redação (f)" },
      { ru: "диктант", port: "ditado (m)" },
      { ru: "экзамен", port: "exame (m)" },
    ],
  },
  {
    name: "Вуз",
    dict: [
      { ru: "академия", port: "academia (f)" },
      { ru: "университет", port: "universidade (f)" },
      { ru: "факультет", port: "faculdade (f)" },
      { ru: "студент", port: "estudante (m)" },
      { ru: "студентка", port: "estudante (f)" },
      { ru: "преподаватель", port: "professor (m)" },
      { ru: "аудитория (помещение)", port: "sala (f)  de palestras" },
      { ru: "выпускник (вуза)", port: "graduado (m)" },
      { ru: "диплом", port: "diploma (m)" },
      { ru: "диссертация", port: "tese (f)" },
      { ru: "исследование (научн. труд)", port: "estudo (m)" },
      { ru: "лаборатория", port: "laboratório (m)" },
      { ru: "лекция", port: "palestra (f)" },
      { ru: "однокурсник", port: "colega (m)  de curso" },
      { ru: "стипендия", port: "bolsa (f)  de estudos" },
      { ru: "учёная степень", port: "grau (m)  académico" },
    ],
  },
  {
    name: "Науки и дисциплины",
    dict: [
      { ru: "математика", port: "matemática (f)" },
      { ru: "алгебра", port: "álgebra (f)" },
      { ru: "геометрия", port: "geometria (f)" },
      { ru: "астрономия", port: "astronomia (f)" },
      { ru: "биология", port: "biologia (f)" },
      { ru: "география", port: "geografia (f)" },
      { ru: "геология", port: "geologia (f)" },
      { ru: "история", port: "história (f)" },
      { ru: "медицина", port: "medicina (f)" },
      { ru: "педагогика", port: "pedagogia (f)" },
      { ru: "право", port: "direito (m)" },
      { ru: "физика", port: "física (f)" },
      { ru: "химия", port: "química (f)" },
      { ru: "философия", port: "filosofia (f)" },
      { ru: "психология", port: "psicologia (f)" },
    ],
  },

  {
    name: "Письмо, части речи, орфография",
    dict: [
      { ru: "грамматика", port: "gramática (f)" },
      { ru: "лексика", port: "léxico (m)" },
      { ru: "фонетика", port: "fonética (f)" },
      { ru: "существительное", port: "substantivo (m)" },
      { ru: "прилагательное", port: "adjetivo (m)" },
      { ru: "глагол", port: "verbo (m)" },
      { ru: "наречие", port: "advérbio (m)" },
      { ru: "местоимение", port: "pronome (m)" },
      { ru: "междометие", port: "interjeição (f)" },
      { ru: "предлог", port: "preposição (f)" },
      { ru: "корень слова", port: "raiz (f)  da palavra" },
      { ru: "окончание", port: "terminação (f)" },
      { ru: "приставка", port: "prefixo (m)" },
      { ru: "слог (часть слова)", port: "sílaba (f)" },
      { ru: "суффикс", port: "sufixo (m)" },
      { ru: "ударение", port: "acento (m)" },
      { ru: "апостроф", port: "apóstrofo (m)" },
      { ru: "точка", port: "ponto (m)" },
      { ru: "запятая", port: "vírgula (f)" },
      { ru: "многоточие", port: "reticências (f pl)" },
      { ru: "вопросительный знак", port: "ponto (m)  de interrogação" },
      { ru: "восклицательный знак", port: "ponto (m)  de exclamação" },
      { ru: "кавычки", port: "aspas (f pl)" },
      { ru: "в кавычках", port: "entre aspas" },
      { ru: "скобки", port: "parênteses (m pl)" },
      { ru: "в скобках", port: "entre parênteses" },
      { ru: "дефис", port: "hífen (m)" },
      { ru: "тире", port: "travessão (m)" },
      { ru: "пробел (между словами)", port: "espaço (m)" },
      { ru: "буква", port: "letra (f)" },
      { ru: "большая буква", port: "letra (f)  maiúscula" },
      { ru: "гласный звук", port: "vogal (f)" },
      { ru: "согласный звук", port: "consoante (f)" },
      { ru: "предложение", port: "frase (f)" },
      { ru: "подлежащее (лингв.)", port: "sujeito (m)" },
      { ru: "сказуемое", port: "predicado (m)" },
      { ru: "строка", port: "linha (f)" },
      { ru: "строка", port: "linha (f)" },
      { ru: "с новой строки", port: "em uma nova linha" },
      { ru: "абзац", port: "parágrafo (m)" },
      { ru: "слово", port: "palavra (f)" },
      { ru: "словосочетание", port: "grupo (m)  de palavras" },
      { ru: "выражение", port: "expressão (f)" },
      { ru: "синоним", port: "sinónimo (m)" },
      { ru: "антоним", port: "antónimo (m)" },
      { ru: "правило", port: "regra (f)" },
      { ru: "исключение", port: "exceção (f)" },
      { ru: "верный (правильный)", port: "correto" },
      { ru: "спряжение", port: "conjugação (f)" },
      { ru: "склонение", port: "declinação (f)" },
      { ru: "падеж", port: "caso (m)" },
      { ru: "вопрос", port: "pergunta (f)" },
      { ru: "подчеркнуть", port: "sublinhar (vt)" },
      { ru: "пунктир", port: "linha (f)  pontilhada" },
    ],
  },
  {
    name: "Сказочные персонажи",
    dict: [
      { ru: "язык (напр. русский)", port: "língua (f)" },
      { ru: "иностранный", port: "estrangeiro" },
      { ru: "Санта Клаус", port: "Pai (m)  Natal" },
      { ru: "Золушка", port: "Cinderela (f)" },
      { ru: "русалка", port: "sereia (f)" },
      { ru: "Нептун", port: "Neptuno (m)" },
      { ru: "волшебник", port: "mago (m)" },
      { ru: "волшебница", port: "fada (f)" },
      { ru: "волшебный", port: "mágico" },
      { ru: "волшебная палочка", port: "varinha (f)  mágica" },
      { ru: "сказка", port: "conto (m)  de fadas" },
      { ru: "чудо", port: "milagre (m)" },
      { ru: "гном", port: "anão (m)" },
      { ru: "превратиться в …", port: "transformar-se em …" },
      { ru: "привидение", port: "espetro (m)" },
      { ru: "призрак", port: "fantasma (m)" },
      { ru: "чудовище", port: "monstro (m)" },
      { ru: "дракон", port: "dragão (m)" },
      { ru: "великан", port: "gigante (m)" },
      { ru: "великан", port: "gigante (m)" },
    ],
  },
  {
    name: "Знаки зодиака",
    dict: [
      { ru: "Овен", port: "Carneiro" },
      { ru: "Телец", port: "Touro" },
      { ru: "Близнецы", port: "Gémeos" },
      { ru: "Рак", port: "Caranguejo" },
      { ru: "Лев", port: "Leão" },
      { ru: "Дева", port: "Virgem" },
      { ru: "Весы", port: "Balança" },
      { ru: "Скорпион", port: "Escorpião" },
      { ru: "Стрелец", port: "Sagitário" },
      { ru: "Козерог", port: "Capricórnio" },
      { ru: "Водолей", port: "Aquário" },
      { ru: "Рыбы", port: "Peixes" },
      { ru: "характер", port: "caráter (m)" },
      { ru: "черты характера", port: "traços (m pl)  do caráter" },
      { ru: "поведение", port: "comportamento (m)" },
      { ru: "гадать (о гадалке)", port: "predizer (vt)" },
      { ru: "гадалка", port: "adivinha (f)" },
      { ru: "гороскоп", port: "horóscopo (m)" },
    ],
  },
  {
    name: "Театр",
    dict: [
      { ru: "балет", port: "balé (m)" },
      { ru: "афиша (театральная)", port: "cartaz (m)" },
      { ru: "труппа", port: "companhia (f)  teatral" },
      { ru: "гастроли", port: "turné (f)" },
      { ru: "гастролировать", port: "estar em turné" },
      { ru: "репетировать", port: "ensaiar (vt)" },
      { ru: "репетиция", port: "ensaio (m)" },
      { ru: "репертуар", port: "repertório (m)" },
      { ru: "представление", port: "apresentação (f)" },
      { ru: "спектакль", port: "espetáculo (m)" },
      { ru: "пьеса", port: "peça (f)" },
      { ru: "билет", port: "bilhete (m)" },
      { ru: "билетная касса", port: "bilheteira (f)" },
      { ru: "холл (в театре)", port: "hall (m)" },
      { ru: "гардероб", port: "guarda-roupa (m)" },
      { ru: "номерок (для пальто)", port: "senha (f)  numerada" },
      { ru: "бинокль", port: "binóculo (m)" },
      { ru: "контролёр", port: "lanterninha (m)" },
      { ru: "партер", port: "plateia (f)" },
      { ru: "партер", port: "plateia (f)" },
      { ru: "балкон", port: "balcão (m)" },
      { ru: "бельэтаж", port: "primeiro balcão (m)" },
      { ru: "ложа", port: "camarote (m)" },
      { ru: "ряд", port: "fila (f)" },
      { ru: "место", port: "assento (m)" },
      { ru: "публика", port: "público (m)" },
      { ru: "зритель", port: "espetador (m)" },
      { ru: "хлопать (артистам)", port: "aplaudir (vt)" },
      { ru: "аплодисменты", port: "aplausos (m pl)" },
      { ru: "овации", port: "ovação (f)" },
      { ru: "сцена", port: "palco (m)" },
      { ru: "занавес", port: "pano (m)  de boca" },
      { ru: "декорация", port: "cenário (m)" },
      { ru: "кулисы", port: "bastidores (m pl)" },
      { ru: "сцена (действие)", port: "cena (f)" },
    ],
  },
  {
    name: "Кино",
    dict: [
      { ru: "кинокамера", port: "câmara (f)" },
      { ru: "кинотеатр", port: "cinema (m)" },
      { ru: "экран", port: "ecrã, tela (m)" },
      { ru: "показывать фильм", port: "exibir um filme" },
      { ru: "звуковая дорожка", port: "pista (f)  sonora" },
      { ru: "специальные эффекты", port: "efeitos (m pl)  especiais" },
      { ru: "субтитры", port: "legendas (f pl)" },
      { ru: "титры (имена актеров)", port: "genérico (m)" },
      { ru: "перевод (с другого языка)", port: "tradução (f)" },
    ],
  },
  {
    name: "Изобразительное исскуство",
    dict: [
      { ru: "искусство", port: "arte (f)" },
      { ru: "изящные искусства", port: "belas-artes (f pl)" },
      { ru: "арт-галерея", port: "galeria (f)  de arte" },
      { ru: "выставка картин", port: "exposição (f)  de arte" },
      { ru: "живопись", port: "pintura (f)" },
      { ru: "графика", port: "arte (f)  gráfica" },
      { ru: "абстракционизм", port: "arte (f)  abstrata" },
      { ru: "импрессионизм", port: "impressionismo (m)" },
      { ru: "картина", port: "pintura (f), quadro (m)" },
      { ru: "рисунок", port: "desenho (m)" },
      { ru: "плакат", port: "cartaz, póster (m)" },
      { ru: "иллюстрация", port: "ilustração (f)" },
      { ru: "миниатюра", port: "miniatura (f)" },
      { ru: "копия", port: "cópia (f)" },
      { ru: "репродукция", port: "reprodução (f)" },
      { ru: "мозаика", port: "mosaico (m)" },
      { ru: "витраж", port: "vitral (m)" },
      { ru: "фреска", port: "fresco (m)" },
      { ru: "гравюра", port: "gravura (f)" },
      { ru: "гравюра", port: "gravura (f)" },
      { ru: "бюст", port: "busto (m)" },
      { ru: "скульптура", port: "escultura (f)" },
      { ru: "статуя", port: "estátua (f)" },
      { ru: "гипс", port: "gesso (m)" },
      { ru: "из гипса", port: "em gesso" },
      { ru: "портрет", port: "retrato (m)" },
      { ru: "автопортрет", port: "autorretrato (m)" },
      { ru: "пейзаж (картина)", port: "paisagem (f)" },
      { ru: "натюрморт", port: "natureza (f)  morta" },
      { ru: "карикатура", port: "caricatura (f)" },
      { ru: "набросок", port: "esboço (m)" },
      { ru: "краска", port: "tinta (f)" },
      { ru: "акварель (краска)", port: "aguarela (f)" },
      { ru: "масло", port: "óleo (m)" },
      { ru: "карандаш", port: "lápis (m)" },
      { ru: "тушь", port: "tinta da China (f)" },
      { ru: "уголь", port: "carvão (m)" },
      { ru: "рисовать (карандашом)", port: "desenhar (vt)" },
      { ru: "рисовать (красками)", port: "pintar (vt)" },
      { ru: "позировать", port: "posar (vi)" },
      { ru: "натурщик", port: "modelo (m)" },
      { ru: "натурщица", port: "modelo (f)" },
      { ru: "художник", port: "pintor (m)" },
      { ru: "произведение", port: "obra (f)" },
      { ru: "шедевр", port: "obra-prima (f)" },
      { ru: "мастерская", port: "estúdio (m)" },
      { ru: "холст", port: "tela (f)" },
      { ru: "мольберт", port: "cavalete (m)" },
      { ru: "палитра", port: "paleta (f)" },
      { ru: "рама (картины)", port: "moldura (f)" },
      { ru: "реставрация", port: "restauração (f)" },
      { ru: "реставрировать", port: "restaurar (vt)" },
    ],
  },
  {
    name: "Литература, поэзия",
    dict: [
      { ru: "литература", port: "literatura (f)" },
      { ru: "автор", port: "autor (m)" },
      { ru: "псевдоним", port: "pseudónimo (m)" },
      { ru: "книга", port: "livro (m)" },
      { ru: "том (издания)", port: "volume (m)" },
      { ru: "оглавление", port: "índice (m)" },
      { ru: "страница", port: "página (f)" },
      { ru: "главный герой", port: "protagonista (m)" },
      { ru: "автограф", port: "autógrafo (m)" },
      { ru: "рассказ", port: "conto (m)" },
      { ru: "повесть", port: "novela (f)" },
      { ru: "роман", port: "romance (m)" },
      { ru: "сочинение (литер.)", port: "obra (f)" },
      { ru: "басня", port: "fábula (m)" },
      { ru: "детектив", port: "romance (m)  policial" },
      { ru: "стихотворение", port: "poesia (f)" },
      { ru: "поэзия", port: "poesia (f)" },
      { ru: "поэма", port: "poema (m)" },
      { ru: "поэт", port: "poeta (m)" },
      { ru: "поэт", port: "poeta (m)" },
      { ru: "беллетристика", port: "ficção (f)" },
      { ru: "научная фантастика", port: "ficção (f)  científica" },
      { ru: "приключения", port: "aventuras (f pl)" },
      { ru: "учебная литература", port: "literatura (f)  didática" },
      { ru: "детская литература", port: "literatura (f)  infantil" },
    ],
  },
  {
    name: "Цирк",
    dict: [
      { ru: "цирк", port: "circo (m)" },
      { ru: "цирк-шапито", port: "circo (m)  ambulante" },
      { ru: "программа", port: "programa (m)" },
      { ru: "представление", port: "apresentação (f)" },
      { ru: "номер (выступление)", port: "número (m)" },
      { ru: "арена", port: "arena (f)" },
      { ru: "пантомима", port: "pantomima (f)" },
      { ru: "клоун", port: "palhaço (m)" },
      { ru: "акробат", port: "acrobata (m)" },
      { ru: "акробатика", port: "acrobacia (f)" },
      { ru: "гимнаст", port: "ginasta (m)" },
      { ru: "гимнастика", port: "ginástica (f)" },
      { ru: "сальто", port: "salto (m)  mortal" },
      { ru: "атлет (силач)", port: "homem forte (m)" },
      { ru: "укротитель", port: "domador (m)" },
      { ru: "наездник", port: "cavaleiro (m)  equilibrista" },
      { ru: "ассистент", port: "assistente (m)" },
      { ru: "трюк", port: "truque (m)" },
      { ru: "фокус", port: "truque (m)  de mágica" },
      { ru: "фокус", port: "truque (m)  de mágica" },
      { ru: "фокусник", port: "mágico (m)" },
      { ru: "жонглёр", port: "malabarista (m)" },
      { ru: "жонглировать", port: "fazer malabarismos" },
      { ru: "дрессировщик", port: "domador (m)" },
      { ru: "дрессировка", port: "adestramento (m)" },
      { ru: "дрессировать", port: "adestrar (vt)" },
    ],
  },
  {
    name: "Музыка, эстрада",
    dict: [
      { ru: "музыка", port: "música (f)" },
      { ru: "музыкант", port: "músico (m)" },
      { ru: "музыкальный инструмент", port: "instrumento (m)  musical" },
      { ru: "играть на …", port: "tocar …" },
      { ru: "гитара", port: "guitarra (f)" },
      { ru: "скрипка", port: "violino (m)" },
      { ru: "виолончель", port: "violoncelo (m)" },
      { ru: "контрабас", port: "contrabaixo (m)" },
      { ru: "арфа", port: "harpa (f)" },
      { ru: "пианино", port: "piano (m)" },
      { ru: "рояль", port: "piano (m)  de cauda" },
      { ru: "оргáн", port: "órgão (m)" },
      { ru: "духовые инструменты", port: "instrumentos (m pl)  de sopro" },
      { ru: "гобой", port: "oboé (m)" },
      { ru: "саксофон", port: "saxofone (m)" },
      { ru: "кларнет", port: "clarinete (m)" },
      { ru: "флейта", port: "flauta (f)" },
      { ru: "труба", port: "trompete (m)" },
      { ru: "аккордеон", port: "acordeão (m)" },
      { ru: "аккордеон", port: "acordeão (m)" },
      { ru: "барабан", port: "tambor (m)" },
      { ru: "дуэт", port: "duo, dueto (m)" },
      { ru: "трио", port: "trio (m)" },
      { ru: "квартет", port: "quarteto (m)" },
      { ru: "хор", port: "coro (m)" },
      { ru: "оркестр", port: "orquestra (f)" },
      { ru: "поп-музыка", port: "música (f)  pop" },
      { ru: "рок-музыка", port: "música (f)  rock" },
      { ru: "рок-группа", port: "grupo (m)  de rock" },
      { ru: "джаз", port: "jazz (m)" },
      { ru: "кумир", port: "ídolo (m)" },
      { ru: "поклонник", port: "fã, admirador (m)" },
      { ru: "концерт", port: "concerto (m)" },
      { ru: "симфония", port: "sinfonia (f)" },
      { ru: "сочинение (муз.)", port: "composição (f)" },
      { ru: "сочинить (стихи, песню)", port: "compor (vt)" },
      { ru: "пение", port: "canto (m)" },
      { ru: "песня", port: "canção (f)" },
      { ru: "мелодия", port: "melodia (f)" },
      { ru: "ритм", port: "ritmo (m)" },
      { ru: "блюз", port: "blues (m)" },
      { ru: "ноты", port: "notas (f pl)" },
      { ru: "палочка (дирижёрская)", port: "batuta (f)" },
      { ru: "смычок", port: "arco (m)" },
      { ru: "струна", port: "corda (f)" },
      { ru: "футляр", port: "estojo (m)" },
    ],
  },
  {
    name: "Турпоездка",
    dict: [
      { ru: "туризм", port: "turismo (m)" },
      { ru: "турист", port: "turista (m)" },
      { ru: "путешествие", port: "viagem (f)" },
      { ru: "приключение", port: "aventura (f)" },
      { ru: "поездка", port: "viagem (f)" },
      { ru: "отпуск", port: "férias (f pl)" },
      { ru: "быть в отпуске", port: "estar de férias" },
      { ru: "отдых", port: "descanso (m)" },
      { ru: "поезд", port: "comboio (m)" },
      { ru: "поездом", port: "de comboio" },
      { ru: "самолёт", port: "avião (m)" },
      { ru: "самолётом", port: "de avião" },
      { ru: "на автомобиле", port: "de carro" },
      { ru: "на корабле", port: "de navio" },
      { ru: "багаж", port: "bagagem (f)" },
      { ru: "чемодан", port: "mala (f)" },
      { ru: "тележка для багажа", port: "carrinho (m)" },
      { ru: "паспорт", port: "passaporte (m)" },
      { ru: "виза", port: "visto (m)" },
      { ru: "виза", port: "visto (m)" },
      { ru: "билет (на поезд и т.п.)", port: "bilhete (m)" },
      { ru: "авиабилет", port: "bilhete (m)  de avião" },
      { ru: "путеводитель", port: "guia (m)  de viagem" },
      { ru: "карта (географ.)", port: "mapa (m)" },
      { ru: "местность", port: "local (m), area (f)" },
      { ru: "место", port: "lugar, sítio (m)" },
      { ru: "экзотика", port: "exotismo (m)" },
      { ru: "экзотический", port: "exótico" },
      { ru: "удивительный", port: "surpreendente" },
      { ru: "группа", port: "grupo (m)" },
      { ru: "экскурсия", port: "excursão (f)" },
      { ru: "экскурсовод", port: "guia (m)" },
    ],
  },
  {
    name: "Гостиница",
    dict: [
      { ru: "гостиница", port: "hotel (m)" },
      { ru: "мотель", port: "motel (m)" },
      { ru: "3 звезды", port: "três estrelas " },
      { ru: "остановиться (в отеле)", port: "ficar (vi, vt)" },
      { ru: "номер (в гостинице)", port: "quarto (m)" },
      { ru: "одноместный номер", port: "quarto (m)  individual" },
      { ru: "двухместный номер", port: "quarto (m)  duplo" },
      { ru: "бронировать номер", port: "reservar um quarto" },
      { ru: "полупансион", port: "meia pensão (f)" },
      { ru: "полный пансион", port: "pensão (f)  completa" },
      { ru: "с ванной", port: "com banheira" },
      { ru: "с душем", port: "com duche" },
      { ru: "спутниковое телевидение", port: "televisão (m)  satélite" },
      { ru: "кондиционер", port: "ar (m)  condicionado" },
      { ru: "полотенце", port: "toalha (f)" },
      { ru: "ключ", port: "chave (f)" },
      { ru: "администратор", port: "administrador (m)" },
      { ru: "горничная", port: "camareira (f)" },
      { ru: "носильщик", port: "bagageiro (m)" },
      { ru: "носильщик", port: "bagageiro (m)" },
      { ru: "портье", port: "porteiro (m)" },
      { ru: "ресторан", port: "restaurante (m)" },
      { ru: "бар", port: "bar (m)" },
      { ru: "завтрак", port: "pequeno-almoço (m)" },
      { ru: "ужин", port: "jantar (m)" },
      { ru: "шведский стол", port: "buffet (m)" },
      { ru: "вестибюль", port: "hall (m)  de entrada" },
      { ru: "лифт", port: "elevador (m)" },
      { ru: "НЕ БЕСПОКОИТЬ", port: "NÃO PERTURBE" },
      { ru: "НЕ КУРИТЬ!", port: "PROIBIDO FUMAR!" },
    ],
  },
  {
    name: "Книги, чтение",
    dict: [
      { ru: "книга", port: "livro (m)" },
      { ru: "автор", port: "autor (m)" },
      { ru: "писатель", port: "escritor (m)" },
      { ru: "написать (книгу)", port: "escrever (vt)" },
      { ru: "читатель", port: "leitor (m)" },
      { ru: "читать", port: "ler (vt)" },
      { ru: "чтение", port: "leitura (f)" },
      { ru: "про себя (читать)", port: "para si" },
      { ru: "вслух", port: "em voz alta" },
      { ru: "издавать (книгу)", port: "publicar (vt)" },
      { ru: "издание", port: "publicação (f)" },
      { ru: "издатель", port: "editor (m)" },
      { ru: "издательство", port: "editora (f)" },
      { ru: "выйти (о книге)", port: "sair (vi)" },
      { ru: "выход (книги)", port: "lançamento (m)" },
      { ru: "тираж (книги)", port: "tiragem (f)" },
      { ru: "книжный магазин", port: "livraria (f)" },
      { ru: "библиотека", port: "biblioteca (f)" },
      { ru: "повесть", port: "novela (f)" },
      { ru: "повесть", port: "novela (f)" },
      { ru: "рассказ", port: "conto (m)" },
      { ru: "роман", port: "romance (m)" },
      { ru: "детектив", port: "romance (m)  policial" },
      { ru: "мемуары", port: "memórias (f pl)" },
      { ru: "легенда", port: "lenda (f)" },
      { ru: "миф", port: "mito (m)" },
      { ru: "стихи", port: "poesia (f)" },
      { ru: "автобиография", port: "autobiografia (f)" },
      { ru: "избранное", port: "obras (f pl)  escolhidas" },
      { ru: "фантастика (научная)", port: "ficção (f)  científica" },
      { ru: "название", port: "título (m)" },
      { ru: "введение", port: "introdução (f)" },
      { ru: "титульный лист", port: "folha (f)  de rosto" },
      { ru: "глава (книги)", port: "capítulo (m)" },
      { ru: "отрывок", port: "excerto (m)" },
      { ru: "эпизод", port: "episódio (m)" },
      { ru: "сюжет", port: "tema (m)" },
      { ru: "содержание (список)", port: "conteúdo (m)" },
      { ru: "оглавление", port: "índice (m)" },
      { ru: "главный герой", port: "protagonista (m)" },
      { ru: "том", port: "tomo, volume (m)" },
      { ru: "обложка (книги)", port: "capa (f)" },
      { ru: "переплёт", port: "encadernação (f)" },
      { ru: "закладка", port: "marcador (m)" },
      { ru: "страница", port: "página (f)" },
      { ru: "листать", port: "folhear (vt)" },
      { ru: "поля (на странице)", port: "margem (f)" },
      { ru: "пометка", port: "anotação (f)" },
      { ru: "примечание", port: "nota (f)  de rodapé" },
      { ru: "текст", port: "texto (m)" },
      { ru: "шрифт", port: "fonte (f)" },
      { ru: "опечатка", port: "gralha (f)" },
      { ru: "перевод", port: "tradução (f)" },
      { ru: "переводить", port: "traduzir (vt)" },
      { ru: "подлинник", port: "original (m)" },
      { ru: "знаменитый", port: "famoso" },
      { ru: "неизвестный", port: "desconhecido" },
      { ru: "интересный", port: "interessante" },
      { ru: "бестселлер", port: "best-seller (m)" },
    ],
  },

  {
    name: "Охота, рыбалка",
    dict: [
      { ru: "охота", port: "caça (f)" },
      { ru: "охотиться", port: "caçar (vi)" },
      { ru: "охотник", port: "caçador (m)" },
      { ru: "стрелять", port: "atirar (vi)" },
      { ru: "ружьё", port: "caçadeira (f)" },
      { ru: "патрон", port: "cartucho (m)" },
      { ru: "дробь", port: "chumbo (m)  de caça" },
      { ru: "капкан", port: "armadilha (f)" },
      { ru: "ловушка", port: "armadilha (f)" },
      { ru: "попасться в капкан", port: "cair na armadilha" },
      { ru: "ставить капкан", port: "pôr a armadilha" },
      { ru: "браконьер", port: "caçador (m)  furtivo" },
      { ru: "дичь", port: "caça (f)" },
      { ru: "охотничья собака", port: "cão (m)  de caça" },
      { ru: "сафари", port: "safári (m)" },
      { ru: "чучело", port: "animal (m)  empalhado" },
      { ru: "рыбак", port: "pescador (m)" },
      { ru: "рыбалка", port: "pesca (f)" },
      { ru: "ловить рыбу", port: "pescar (vt)" },
      { ru: "ловить рыбу", port: "pescar (vt)" },
      { ru: "удочка", port: "cana (f)  de pesca" },
      { ru: "леска", port: "linha (f)  de pesca" },
      { ru: "крючок", port: "anzol (m)" },
      { ru: "поплавок", port: "boia (f), flutuador (m)" },
      { ru: "наживка", port: "isca (f)" },
      { ru: "забросить удочку", port: "lançar a linha" },
      { ru: "клевать (о рыбе)", port: "morder (vt)" },
      { ru: "улов", port: "pesca (f)" },
      { ru: "прорубь", port: "buraco (m)  no gelo" },
      { ru: "сеть", port: "rede (f)" },
      { ru: "лодка", port: "barco (m)" },
      { ru: "ловить сетью", port: "pescar com rede" },
      { ru: "забрасывать сеть", port: "lançar a rede" },
      { ru: "вытаскивать сеть", port: "puxar a rede" },
      { ru: "попасться в сети", port: "cair nas malhas" },
      { ru: "китобой", port: "baleeiro (m)" },
      { ru: "китобойное судно", port: "baleeira (f)" },
      { ru: "гарпун", port: "arpão (m)" },
    ],
  },
  {
    name: "Игры",
    dict: [
      { ru: "бубны", port: "ouros (m pl)" },
      { ru: "пики", port: "espadas (f pl)" },
      { ru: "червы", port: "copas (f pl)" },
      { ru: "трефы", port: "paus (m pl)" },
      { ru: "туз", port: "ás (m)" },
      { ru: "король", port: "rei (m)" },
      { ru: "дама", port: "dama (f)" },
      { ru: "валет", port: "valete (m)" },
      { ru: "карта игральная", port: "carta (f)  de jogar" },
      { ru: "карты", port: "cartas (f pl)" },
      { ru: "козырь", port: "trunfo (m)" },
      { ru: "колода", port: "baralho (m)" },
      { ru: "очко (в игре)", port: "ponto (m)" },
      { ru: "сдавать (карты)", port: "dar, distribuir (vt)" },
      { ru: "тасовать", port: "embaralhar (vt)" },
      { ru: "ход (в игре)", port: "vez, jogada (f)" },
      { ru: "шулер", port: "batoteiro (m)" },
    ],
  },
  {
    name: "Казино",
    dict: [
      { ru: "казино", port: "casino (m)" },
      { ru: "рулетка", port: "roleta (f)" },
      { ru: "ставка", port: "aposta (f)" },
      { ru: "делать ставки", port: "apostar (vt)" },
      { ru: "красное", port: "vermelho (m)" },
      { ru: "крупье", port: "crupiê (m, f)" },
      { ru: "правила игры", port: "regras (f pl)  do jogo" },
      { ru: "фишка", port: "ficha (f)" },
      { ru: "выиграть", port: "ganhar (vi, vt)" },
      { ru: "выигрыш", port: "ganho (m)" },
      { ru: "проиграть", port: "perder (vt)" },
      { ru: "проигрыш", port: "perda (f)" },
      { ru: "игрок", port: "jogador (m)" },
      { ru: "блэк джек", port: "blackjack (m)" },
      { ru: "игра в кости", port: "jogo (m)  de dados" },
      { ru: "кости", port: "dados (m pl)" },
      { ru: "игральный автомат", port: "máquina (f)  de jogo" },
    ],
  },
  {
    name: "Отдых разное",
    dict: [
      { ru: "гулять (прогуливаться)", port: "passear (vi)" },
      { ru: "прогулка (пешком)", port: "passeio (m)" },
      { ru: "прогулка (поездка)", port: "viagem (f)  de carro" },
      { ru: "приключение", port: "aventura (f)" },
      { ru: "пикник", port: "piquenique (m)" },
      { ru: "игра", port: "jogo (m)" },
      { ru: "игрок", port: "jogador (m)" },
      { ru: "партия (в игре)", port: "partida (f)" },
      { ru: "коллекционер", port: "colecionador (m)" },
      { ru: "коллекционировать", port: "colecionar (vt)" },
      { ru: "коллекция", port: "coleção (f)" },
      { ru: "кроссворд", port: "palavras (f pl)  cruzadas" },
      { ru: "ипподром", port: "hipódromo (m)" },
      { ru: "дискотека", port: "discoteca (f)" },
      { ru: "сауна", port: "sauna (f)" },
      { ru: "лотерея", port: "lotaria (f)" },
      { ru: "поход", port: "viagem (f)  de acampamento" },
      { ru: "лагерь", port: "acampamento (m)" },
      { ru: "палатка", port: "tenda (f)" },
      { ru: "палатка", port: "tenda (f)" },
      { ru: "компас", port: "bússola (f)" },
      { ru: "турист (в походе)", port: "campista (m)" },
      { ru: "смотреть (телевизор)", port: "ver (vt), assistir à …" },
      { ru: "телезритель", port: "telespetador (m)" },
      { ru: "телепередача", port: "programa (m)  de TV" },
    ],
  },
  {
    name: "Фотография",
    dict: [
      { ru: "фотоаппарат", port: "máquina (f)  fotográfica" },
      { ru: "фото, фотография", port: "foto, fotografia (f)" },
      { ru: "фотограф", port: "fotógrafo (m)" },
      { ru: "фотостудия", port: "estúdio (m)  fotográfico" },
      { ru: "фотоальбом", port: "álbum (m)  de fotografias" },
      { ru: "объектив", port: "objetiva (f)" },
      { ru: "телеобъектив", port: "teleobjetiva (f)" },
      { ru: "фильтр", port: "filtro (m)" },
      { ru: "линза", port: "lente (f)" },
      { ru: "оптика", port: "ótica (f)" },
      { ru: "диафрагма", port: "abertura (f)" },
      { ru: "выдержка", port: "exposição (f)" },
      { ru: "видоискатель", port: "visor (m)" },
      { ru: "цифровая камера", port: "câmara (f)  digital" },
      { ru: "штатив", port: "tripé (m)" },
      { ru: "вспышка", port: "flash (m)" },
      { ru: "фотографировать", port: "fotografar (vt)" },
      { ru: "снимать (в фото)", port: "tirar fotos" },
      { ru: "фотографироваться", port: "fotografar-se" },
      { ru: "фотографироваться", port: "fotografar-se" },
      { ru: "фокус", port: "foco (m)" },
      { ru: "наводить на резкость", port: "focar (vt)" },
      { ru: "резкий (фото)", port: "nítido" },
      { ru: "резкость", port: "nitidez (f)" },
      { ru: "контраст", port: "contraste (m)" },
      { ru: "контрастный", port: "contrastante" },
      { ru: "снимок (фото)", port: "retrato (m)" },
      { ru: "негатив", port: "negativo (m)" },
      { ru: "фотоплёнка", port: "filme (m)" },
      { ru: "кадр", port: "fotograma (m)" },
      { ru: "печатать", port: "imprimir (vt)" },
    ],
  },
  {
    name: "Пляж",
    dict: [
      { ru: "пляж", port: "praia (f)" },
      { ru: "песок", port: "areia (f)" },
      { ru: "пустынный (пляж)", port: "deserto" },
      { ru: "загар", port: "bronzeado (m)" },
      { ru: "загорать", port: "bronzear-se (vp)" },
      { ru: "загорелый", port: "bronzeado" },
      { ru: "крем для загара", port: "protetor (m)  solar" },
      { ru: "бикини", port: "biquíni (m)" },
      { ru: "купальник", port: "fato (m)  de banho" },
      { ru: "плавки", port: "calção (m)  de banho" },
      { ru: "бассейн", port: "piscina (f)" },
      { ru: "плавать", port: "nadar (vi)" },
      { ru: "душ", port: "duche (m)" },
      { ru: "переодеваться", port: "mudar de roupa" },
      { ru: "полотенце", port: "toalha (f)" },
      { ru: "лодка", port: "barco (m)" },
      { ru: "катер", port: "lancha (f)" },
      { ru: "водные лыжи", port: "esqui (m)  aquático" },
      { ru: "водный велосипед", port: "barco (m)  de pedais" },
      { ru: "водный велосипед", port: "barco (m)  de pedais" },
      { ru: "серфинг", port: "surf, surfe (m)" },
      { ru: "серфингист", port: "surfista (m)" },
      { ru: "акваланг", port: "scuba (m)" },
      { ru: "ласты", port: "barbatanas (f pl)" },
      { ru: "маска", port: "máscara (f)" },
      { ru: "ныряльщик", port: "mergulhador (m)" },
      { ru: "нырять", port: "mergulhar (vi)" },
      { ru: "под водой", port: "debaixo d'água" },
      { ru: "зонт", port: "guarda-sol (m)" },
      { ru: "шезлонг", port: "espreguiçadeira (f)" },
      { ru: "очки (от солнца)", port: "óculos (m pl)  de sol" },
      { ru: "плавательный матрац", port: "colchão (m)  de ar" },
      { ru: "играть", port: "brincar (vi)" },
      { ru: "купаться", port: "ir nadar" },
      { ru: "мяч", port: "bola (f)  de praia" },
      { ru: "надувать", port: "encher (vt)" },
      { ru: "надувной (~ матрас)", port: "inflável, de ar" },
      { ru: "волна", port: "onda (f)" },
      { ru: "буй", port: "boia (f)" },
      { ru: "тонуть", port: "afogar-se (vp)" },
      { ru: "спасать", port: "salvar (vt)" },
      { ru: "спасатель", port: "nadador-salvador (m)" },
      { ru: "спасательный жилет", port: "colete (m)  salva-vidas" },
      { ru: "наблюдать", port: "observar (vt)" },
    ],
  },
  {
    name: "Компьютер",
    dict: [
      { ru: "данные", port: "dados (m pl)" },
      { ru: "база данных", port: "base (f)  de dados" },
      { ru: "кабель", port: "cabo (m)" },
      { ru: "отсоединить", port: "desconectar (vt)" },
      { ru: "подсоединить", port: "conetar (vt)" },
    ],
  },
  {
    name: "Интернет",
    dict: [
      { ru: "интернет", port: "internet (f)" },
      { ru: "браузер", port: "browser (m)" },
      { ru: "поисковый ресурс", port: "motor (m)  de busca" },
      { ru: "провайдер", port: "provedor (m)" },
      { ru: "веб-мастер", port: "webmaster (m)" },
      { ru: "веб-сайт", port: "website, sítio web (m)" },
      { ru: "веб-страница", port: "página (f)  web" },
      { ru: "адрес", port: "endereço (m)" },
      { ru: "адресная книга", port: "livro (m)  de endereços" },
      { ru: "почтовый ящик", port: "caixa (f)  de correio" },
      { ru: "почта (сообщения)", port: "correio (m)" },
      { ru: "переполненный (ящик)", port: "cheia" },
      { ru: "сообщение", port: "mensagem (f)" },
      { ru: "входящие сообщения", port: "mensagens recebidas" },
      { ru: "исходящие сообщения", port: "mensagens enviadas" },
      { ru: "отправитель", port: "remetente (m)" },
      { ru: "отправить", port: "enviar (vt)" },
      { ru: "отправка (письма)", port: "envio (m)" },
      { ru: "получатель", port: "destinatário (m)" },
      { ru: "получатель", port: "destinatário (m)" },
      { ru: "получить", port: "receber (vt)" },
      { ru: "переписка", port: "correspondência (f)" },
      { ru: "переписываться", port: "corresponder-se (vp)" },
      { ru: "файл", port: "ficheiro (m)" },
      { ru: "скачать (скопировать)", port: "fazer download, baixar (vt)" },
      { ru: "создать", port: "criar (vt)" },
      { ru: "удалить", port: "apagar, eliminar (vt)" },
      { ru: "удалённый (о файле)", port: "eliminado" },
      { ru: "связь", port: "ligação (f)" },
      { ru: "скорость", port: "velocidade (f)" },
      { ru: "доступ", port: "acesso (m)" },
      { ru: "порт (комп.)", port: "porta (f)" },
      { ru: "подключение", port: "conexão (f)" },
      { ru: "подключиться", port: "conetar (vi)" },
      { ru: "выбрать", port: "escolher (vt)" },
      { ru: "искать", port: "buscar (vt)" },
    ],
  },
  {
    name: "Электричество",
    dict: [
      { ru: "электричество", port: "eletricidade (f)" },
      { ru: "электрический", port: "elétrico" },
      { ru: "электростанция", port: "central (f)  elétrica" },
      { ru: "энергия", port: "energia (f)" },
      { ru: "электроэнергия", port: "energia (f)  elétrica" },
      { ru: "лампочка", port: "lâmpada (f)" },
      { ru: "фонарь (ручной)", port: "lanterna (f)" },
      { ru: "фонарь (уличный)", port: "poste (m)  de iluminação" },
      { ru: "свет (электричество)", port: "luz (f)" },
      { ru: "включать", port: "ligar (vt)" },
      { ru: "выключать", port: "desligar (vt)" },
      { ru: "погасить свет", port: "apagar a luz" },
      { ru: "перегореть (о лампочке)", port: "fundir (vi)" },
      { ru: "короткое замыкание", port: "curto-circuito (m)" },
      { ru: "обрыв (провода)", port: "rutura (f)" },
      { ru: "контакт", port: "contacto (m)" },
      { ru: "выключатель", port: "interruptor (m)" },
      { ru: "розетка", port: "tomada (f)" },
      { ru: "вилка", port: "ficha (f)" },
      { ru: "вилка", port: "ficha (f)" },
      { ru: "удлинитель", port: "extensão (f)" },
      { ru: "предохранитель", port: "fusível (m)" },
      { ru: "провод", port: "fio, cabo (m)" },
      { ru: "проводка", port: "instalação (f)  elétrica" },
      { ru: "ампер", port: "ampere (m)" },
      { ru: "сила тока", port: "amperagem (f)" },
      { ru: "вольт", port: "volt (m)" },
      { ru: "напряжение", port: "voltagem (f)" },
      { ru: "электроприбор", port: "aparelho (m)  elétrico" },
      { ru: "индикатор", port: "indicador (m)" },
      { ru: "электрик", port: "eletricista (m)" },
      { ru: "паять", port: "soldar (vt)" },
    ],
  },
  {
    name: "Инструменты",
    dict: [
      { ru: "инструмент", port: "ferramenta (f)" },
      { ru: "инструменты", port: "ferramentas (f pl)" },
      { ru: "оборудование", port: "equipamento (m)" },
      { ru: "молоток", port: "martelo (m)" },
      { ru: "отвёртка", port: "chave (f)  de fendas" },
      { ru: "топор", port: "machado (m)" },
      { ru: "пила", port: "serra (f)" },
      { ru: "пилить", port: "serrar (vt)" },
      { ru: "рубанок", port: "plaina (f)" },
      { ru: "строгать", port: "aplainar (vt)" },
      { ru: "паяльник", port: "ferro (m)  de soldar" },
      { ru: "паять", port: "soldar (vt)" },
      { ru: "напильник", port: "lima (f)" },
      { ru: "клещи", port: "tenaz (f)" },
      { ru: "плоскогубцы", port: "alicate (m)" },
      { ru: "стамеска", port: "formão (m)" },
      { ru: "сверло", port: "broca (f)" },
      { ru: "дрель", port: "berbequim (f)" },
      { ru: "сверлить", port: "furar (vt)" },
      { ru: "сверлить", port: "furar (vt)" },
      { ru: "нож", port: "faca (f)" },
      { ru: "карманный нож", port: "canivete (m)" },
      { ru: "складной нож", port: "articulado" },
      { ru: "лезвие (ножа)", port: "lâmina (f)" },
      { ru: "острый (нож и т.п.)", port: "afiado" },
      { ru: "тупой", port: "cego" },
      { ru: "затупиться", port: "embotar-se (vp)" },
      { ru: "точить", port: "afiar, amolar (vt)" },
      { ru: "болт", port: "parafuso (m)" },
      { ru: "гайка", port: "porca (f)" },
      { ru: "резьба (на винте)", port: "rosca (f)" },
      { ru: "шуруп", port: "parafuso (m)  para madeira" },
      { ru: "гвоздь", port: "prego (m)" },
      { ru: "шляпка (гвоздя)", port: "cabeça (f)  do prego" },
      { ru: "линейка", port: "régua (f)" },
      { ru: "рулетка", port: "fita (f)  métrica" },
      { ru: "уровень (инструмент)", port: "nível (m)" },
      { ru: "лупа", port: "lupa (f)" },
      { ru: "измерительный прибор", port: "medidor (m)" },
      { ru: "измерять", port: "medir (vt)" },
      { ru: "шкала", port: "escala (f)" },
      { ru: "показание (прибора)", port: "leitura (f)" },
      { ru: "компрессор", port: "compressor (m)" },
      { ru: "микроскоп", port: "microscópio (m)" },
      { ru: "насос", port: "bomba (f)" },
      { ru: "робот", port: "robô (m)" },
      { ru: "лазер", port: "laser (m)" },
      { ru: "гаечный ключ", port: "chave (f)  de boca" },
      { ru: "лента-скотч", port: "fita (f)  adesiva" },
      { ru: "клей", port: "cola (f)" },
      { ru: "наждачная бумага", port: "lixa (f)" },
      { ru: "пружина", port: "mola (f)" },
      { ru: "магнит", port: "íman (m)" },
      { ru: "перчатки", port: "luvas (f pl)" },
      { ru: "верёвка", port: "corda (f)" },
      { ru: "шнур (верёвка)", port: "cordel (m)" },
      { ru: "провод", port: "fio (m)" },
      { ru: "кабель", port: "cabo (m)" },
      { ru: "кувалда", port: "marreta (f)" },
    ],
  },
  {
    name: "Поезд",
    dict: [
      { ru: "поезд", port: "comboio (m)" },
      { ru: "электричка", port: "comboio (m)  suburbano" },
      { ru: "скорый поезд", port: "comboio (m)  rápido" },
      { ru: "тепловоз", port: "locomotiva (f)  diesel" },
      { ru: "паровоз", port: "comboio (m)  a vapor" },
      { ru: "вагон", port: "carruagem (f)" },
      { ru: "вагон-ресторан", port: "carruagem restaurante (f)" },
      { ru: "рельсы", port: "trilhos (m pl)" },
      { ru: "железная дорога", port: "caminho de ferro (m)" },
      { ru: "шпала", port: "travessa (f)" },
      { ru: "платформа", port: "plataforma (f)" },
      { ru: "путь", port: "linha (f)" },
      { ru: "семафор", port: "semáforo (m)" },
      { ru: "станция", port: "estação (f)" },
      { ru: "машинист", port: "maquinista (m)" },
      { ru: "носильщик", port: "bagageiro (m)" },
      { ru: "проводник", port: "condutor (m)" },
      { ru: "пассажир", port: "passageiro (m)" },
      { ru: "контролёр", port: "revisor (m)" },
      { ru: "контролёр", port: "revisor (m)" },
      { ru: "коридор (в поезде)", port: "corredor (m)" },
      { ru: "стоп-кран", port: "freio (m)  de emergência" },
      { ru: "купе", port: "compartimento (m)" },
      { ru: "полка (для лежания)", port: "cama (f)" },
      { ru: "полка верхняя", port: "cama (f)  de cima" },
      { ru: "полка нижняя", port: "cama (f)  de baixo" },
      { ru: "постельное бельё", port: "roupa (f)  de cama" },
      { ru: "билет", port: "bilhete (m)" },
      { ru: "расписание", port: "horário (m)" },
      { ru: "табло (расписание)", port: "painel (m)  de informação" },
      { ru: "отходить", port: "partir (vt)" },
      { ru: "отправление (поезда)", port: "partida (f)" },
      { ru: "прибывать", port: "chegar (vi)" },
      { ru: "прибытие", port: "chegada (f)" },
      { ru: "приехать поездом", port: "chegar de comboio" },
      { ru: "сесть на поезд", port: "apanhar o comboio" },
      { ru: "сойти с поезда", port: "sair do comboio" },
      { ru: "крушение", port: "acidente (m)  ferroviário" },
    ],
  },
  {
    name: "Корабль",
    dict: [
      { ru: "корабль", port: "navio (m)" },
      { ru: "судно", port: "embarcação (f)" },
      { ru: "пароход", port: "vapor (m)" },
      { ru: "теплоход", port: "navio (m)" },
      { ru: "лайнер", port: "transatlântico (m)" },
      { ru: "крейсер", port: "cruzador (m)" },
      { ru: "яхта", port: "iate (m)" },
      { ru: "буксир", port: "rebocador (m)" },
      { ru: "баржа", port: "barcaça (f)" },
      { ru: "паром", port: "ferry (m)" },
      { ru: "парусник", port: "veleiro (m)" },
      { ru: "бригантина", port: "bergantim (m)" },
      { ru: "ледокол", port: "quebra-gelo (m)" },
      { ru: "лодка подводная", port: "submarino (m)" },
      { ru: "лодка", port: "bote, barco (m)" },
      { ru: "шлюпка", port: "bote, dingue (m)" },
      { ru: "шлюпка спасательная", port: "bote (m)  salva-vidas" },
      { ru: "катер", port: "lancha (f)" },
      { ru: "капитан", port: "capitão (m)" },
      { ru: "капитан", port: "capitão (m)" },
      { ru: "матрос", port: "marinheiro (m)" },
      { ru: "моряк", port: "marujo (m)" },
      { ru: "экипаж", port: "tripulação (f)" },
      { ru: "боцман", port: "contramestre (m)" },
      { ru: "юнга", port: "grumete (m)" },
      { ru: "кок", port: "cozinheiro (m)  de bordo" },
      { ru: "судовой врач", port: "médico (m)  de bordo" },
      { ru: "палуба", port: "convés (m)" },
      { ru: "мачта", port: "mastro (m)" },
      { ru: "парус", port: "vela (f)" },
      { ru: "трюм", port: "porão (m)" },
      { ru: "нос (корабля)", port: "proa (f)" },
      { ru: "корма", port: "popa (f)" },
      { ru: "весло", port: "remo (m)" },
      { ru: "винт", port: "hélice (f)" },
      { ru: "каюта", port: "camarote (m)" },
      { ru: "кают-компания", port: "sala (f)  dos oficiais" },
      { ru: "машинное отделение", port: "sala (f)  das máquinas" },
      { ru: "капитанский мостик", port: "ponte (m)  de comando" },
      { ru: "радиорубка", port: "sala (f)  de comunicações" },
      { ru: "волна (радио)", port: "onda (f)" },
      { ru: "судовой журнал", port: "diário (m)  de bordo" },
      { ru: "подзорная труба", port: "luneta (f)" },
      { ru: "колокол", port: "sino (m)" },
      { ru: "флаг", port: "bandeira (f)" },
      { ru: "канат", port: "cabo (m)" },
      { ru: "узел (завязать ~)", port: "nó (m)" },
      { ru: "поручень", port: "corrimão (m)" },
      { ru: "трап", port: "prancha (f)  de embarque" },
      { ru: "якорь", port: "âncora (f)" },
      { ru: "поднять якорь", port: "recolher a âncora" },
      { ru: "бросить якорь", port: "lançar a âncora" },
      { ru: "якорная цепь", port: "amarra (f)" },
      { ru: "порт", port: "porto (m)" },
      { ru: "причал", port: "cais, amarradouro (m)" },
      { ru: "причаливать", port: "atracar (vi)" },
      { ru: "отчаливать", port: "desatracar (vi)" },
      { ru: "путешествие", port: "viagem (f)" },
      { ru: "круиз", port: "cruzeiro (m)" },
    ],
  },
  {
    name: "Аэропорт",
    dict: [
      { ru: "вылет", port: "partida (f)" },
      { ru: "прилёт", port: "chegada (f)" },
      { ru: "прилететь", port: "chegar (vi)" },
      { ru: "время вылета", port: "hora (f)  de partida" },
      { ru: "время прилёта", port: "hora (f)  de chegada" },
      { ru: "задерживаться (о рейсе)", port: "estar atrasado" },
      { ru: "задержка вылета", port: "atraso (m)  de voo" },
      { ru: "информационное табло", port: "painel (m)  de informação" },
      { ru: "информация", port: "informação (f)" },
      { ru: "объявлять", port: "anunciar (vt)" },
      { ru: "рейс", port: "voo (m)" },
      { ru: "таможня", port: "alfândega (f)" },
      { ru: "таможенник", port: "funcionário (m)  da alfândega" },
      { ru: "таможенная декларация", port: "declaração (f)  alfandegária" },
      { ru: "заполнить (декларацию)", port: "preencher (vt)" },
      { ru: "заполнить декларацию", port: "preencher a declaração" },
      { ru: "паспортный контроль", port: "controlo (m)  de passaportes" },
      { ru: "багаж", port: "bagagem (f)" },
      { ru: "ручная кладь", port: "bagagem (f)  de mão" },
      { ru: "ручная кладь", port: "bagagem (f)  de mão" },
      { ru: "розыск багажа", port: "Perdidos e Achados" },
      { ru: "тележка (для багажа)", port: "carrinho (m)" },
      { ru: "посадка", port: "aterragem (f)" },
      { ru: "посадочная полоса", port: "pista (f)  de aterragem" },
      { ru: "садиться (о самолёте)", port: "aterrar (vi)" },
      { ru: "трап (к самолету)", port: "escada (f)  de avião" },
      { ru: "регистрация", port: "check-in (m)" },
      { ru: "стойка регистрации", port: "balcão (m)  do check-in" },
      { ru: "зарегистрироваться", port: "fazer o check-in" },
      { ru: "посадочный талон", port: "cartão (m)  de embarque" },
      { ru: "выход (на посадку)", port: "porta (f)  de embarque" },
      { ru: "транзит", port: "trânsito (m)" },
      { ru: "ждать", port: "esperar (vi, vt)" },
      { ru: "зал ожидания", port: "sala (f)  de espera" },
      { ru: "провожать", port: "despedir-se de …" },
      { ru: "прощаться", port: "dizer adeus" },
    ],
  },
  {
    name: "Велосипед, мотоцикл",
    dict: [
      { ru: "велосипед", port: "bicicleta (f)" },
      { ru: "мотороллер", port: "scotter, lambreta (f)" },
      { ru: "мотоцикл", port: "mota (f)" },
      { ru: "ехать на велосипеде", port: "ir de bicicleta" },
      { ru: "руль (велосипеда) ", port: "guiador (m)" },
      { ru: "тормоза", port: "travões (m pl)" },
      { ru: "седло", port: "selim (m)" },
      { ru: "насос", port: "bomba (f)  de ar" },
      { ru: "багажник", port: "porta-bagagens (m)" },
      { ru: "фонарь", port: "lanterna (f)" },
      { ru: "шлем", port: "capacete (m)" },
      { ru: "колесо", port: "roda (f)" },
      { ru: "крыло", port: "guarda-lamas (m)" },
      { ru: "обод", port: "aro (m)" },
      { ru: "спица", port: "raio (m)" },
    ],
  },
  {
    name: "Автомобиль виды",
    dict: [
      { ru: "автомобиль", port: "carro, automóvel (m)" },
      { ru: "спортивный автомобиль", port: "carro (m)  desportivo" },
      { ru: "лимузин", port: "limusine (f)" },
      { ru: "внедорожник, джип", port: "todo o terreno (m)" },
      { ru: "кабриолет", port: "descapotável (m)" },
      { ru: "микроавтобус", port: "minibus (m)" },
      { ru: "скорая помощь", port: "ambulância (f)" },
      { ru: "снегоуборочная машина", port: "limpa-neve (m)" },
      { ru: "грузовик", port: "camião (m)" },
      { ru: "бензовоз", port: "camião-cisterna (m)" },
      { ru: "фургон", port: "carrinha (f)" },
      { ru: "тягач", port: "caminhão-trator (m)" },
      { ru: "прицеп", port: "atrelado (m)" },
      { ru: "комфортабельный", port: "confortável" },
      { ru: "подержанный", port: "usado" },
    ],
  },
  {
    name: "Кузов",
    dict: [
      { ru: "капот", port: "capô (m)" },
      { ru: "крыло", port: "guarda-lamas (m)" },
      { ru: "крыша", port: "tejadilho (m)" },
      { ru: "ветровое стекло", port: "para-brisa (m)" },
      { ru: "зеркало заднего вида", port: "espelho (m)  retrovisor" },
      { ru: "омыватель", port: "lavador (m)" },
      { ru: "щетки стеклоочистителя", port: "limpa-para-brisas (m)" },
      { ru: "боковое стекло", port: "vidro (m)  lateral" },
      { ru: "стеклоподъёмник", port: "elevador (m)  do vidro" },
      { ru: "антенна", port: "antena (f)" },
      { ru: "люк", port: "teto solar (m)" },
      { ru: "бампер", port: "para-choques (m pl)" },
      { ru: "багажник", port: "bagageira (f)" },
      { ru: "багажник (на крыше)", port: "bagageira (f)  de tejadilho" },
      { ru: "дверца", port: "porta (f)" },
      { ru: "ручка (двери)", port: "maçaneta (f)" },
      { ru: "замок", port: "fechadura (f)" },
      { ru: "номер (авто)", port: "matrícula (f)" },
      { ru: "глушитель", port: "silenciador (m)" },
      { ru: "глушитель", port: "silenciador (m)" },
      { ru: "бензобак", port: "tanque (m)  de gasolina" },
      { ru: "выхлопная труба", port: "tubo (m)  de escape" },
      { ru: "газ", port: "acelerador (m)" },
      { ru: "педаль", port: "pedal (m)" },
      { ru: "педаль газа", port: "pedal (m)  do acelerador" },
      { ru: "тормоз", port: "travão (m)" },
      { ru: "педаль тормоза", port: "pedal (m)  do travão" },
      { ru: "тормозить", port: "travar (vt)" },
      { ru: "стояночный тормоз", port: "travão (m)  de mão" },
      { ru: "сцепление", port: "embraiagem (f)" },
      { ru: "педаль сцепления", port: "pedal (m)  da embraiagem" },
      { ru: "диск сцепления", port: "disco (m)  de embraiagem" },
      { ru: "амортизатор", port: "amortecedor (m)" },
      { ru: "колесо", port: "roda (f)" },
      { ru: "запасное колесо", port: "pneu (m)  sobresselente" },
      { ru: "покрышка, шина", port: "pneu (m)" },
      { ru: "колпак (на колесе)", port: "tampão (m)  de roda" },
      { ru: "ведущие колёса", port: "rodas (f pl)  motrizes" },
      { ru: "переднеприводный", port: "de tração dianteira" },
      { ru: "заднеприводный", port: "de tração traseira" },
      { ru: "полноприводный", port: "de tração às 4 rodas" },
      { ru: "коробка передач", port: "caixa (f)  de mudanças" },
      { ru: "автоматический", port: "automático" },
      { ru: "механический", port: "mecânico" },
      { ru: "рычаг коробки передач", port: "alavanca (f)  das mudanças" },
      { ru: "фара", port: "farol (m)" },
      { ru: "фары (свет)", port: "faróis (m pl), luzes (f pl)" },
      { ru: "ближний свет", port: "médios (m pl)" },
      { ru: "дальний свет", port: "máximos (m pl)" },
      { ru: "стоп-сигнал", port: "luzes (f pl)  de stop" },
      { ru: "габаритные огни", port: "mínimos (m pl)" },
      { ru: "аварийные огни", port: "luzes (f pl)  de emergência" },
      { ru: "противотуманные фары", port: "faróis (m pl)  antinevoeiro" },
      { ru: "указатель поворота", port: "pisca-pisca (m)" },
      { ru: "фонарь заднего хода", port: "luz (f)  de marcha atrás" },
    ],
  },
  {
    name: "Автомобиль салон",
    dict: [
      { ru: "салон (автомобиля)", port: "interior (m)  do carro" },
      { ru: "кожаный", port: "de couro, de pele" },
      { ru: "велюровый", port: "de veludo" },
      { ru: "обивка", port: "estofos (m pl)" },
      { ru: "прибор", port: "indicador (m)" },
      { ru: "приборный щиток", port: "painel (m)  de instrumentos" },
      { ru: "спидометр", port: "velocímetro (m)" },
      { ru: "стрелка", port: "ponteiro (m)" },
      { ru: "счётчик километража", port: "conta-quilómetros (m)" },
      { ru: "датчик", port: "sensor (m)" },
      { ru: "уровень (напр. масла)", port: "nível (m)" },
      { ru: "лампочка (индикатор)", port: "luz (f)  avisadora" },
      { ru: "руль, рулевое колесо", port: "volante (m)" },
      { ru: "сигнал (клаксон)", port: "buzina (f)" },
      { ru: "кнопка", port: "botão (m)" },
      { ru: "переключатель", port: "interruptor (m)" },
      { ru: "сиденье", port: "assento (m)" },
      { ru: "спинка (сиденья)", port: "costas (f pl)  do assento" },
      { ru: "подголовник", port: "cabeceira (f)" },
      { ru: "подголовник", port: "cabeceira (f)" },
      { ru: "ремень безопасности", port: "cinto (m)  de segurança" },
      { ru: "пристегнуть ремень", port: "apertar o cinto" },
      { ru: "регулировка", port: "regulação (f)" },
      { ru: "воздушная подушка", port: "airbag (m)" },
      { ru: "кондиционер", port: "ar (m)  condicionado" },
      { ru: "радио", port: "rádio (m)" },
      { ru: "СD-проигрыватель", port: "leitor (m)  de CD" },
      { ru: "включить (приёмник)", port: "ligar (vt)" },
      { ru: "антенна", port: "antena (f)" },
      { ru: "отделение для перчаток", port: "porta-luvas (m)" },
      { ru: "пепельница", port: "cinzeiro (m)" },
    ],
  },
  {
    name: "Автомобиль двигатель",
    dict: [
      { ru: "двигатель, мотор", port: "motor (m)" },
      { ru: "дизельный", port: "diesel" },
      { ru: "бензиновый", port: "a gasolina" },
      { ru: "объём двигателя", port: "cilindrada (f)" },
      { ru: "мощность", port: "potência (f)" },
      { ru: "лошадиная сила", port: "cavalo-vapor (m)" },
      { ru: "поршень", port: "pistão (m)" },
      { ru: "цилиндр", port: "cilindro (m)" },
      { ru: "клапан", port: "válvula (f)" },
      { ru: "инжектор", port: "injetor (m)" },
      { ru: "генератор", port: "gerador (m)" },
      { ru: "карбюратор", port: "carburador (m)" },
      { ru: "масло моторное", port: "óleo (m)  para motor" },
      { ru: "радиатор", port: "radiador (m)" },
      { ru: "охлаждающая жидкость", port: "refrigerante (m)" },
      { ru: "вентилятор", port: "ventilador (m)" },
      { ru: "аккумулятор", port: "bateria (f)" },
      { ru: "стартер", port: "dispositivo (m)  de arranque" },
      { ru: "зажигание", port: "ignição (f)" },
      { ru: "зажигание", port: "ignição (f)" },
      { ru: "свеча зажигания", port: "vela (f)  de ignição" },
      { ru: "клемма", port: "borne (m)" },
      { ru: "плюс", port: "borne (m)  positivo" },
      { ru: "минус", port: "borne (m)  negativo" },
      { ru: "предохранитель", port: "fusível (m)" },
      { ru: "воздушный фильтр", port: "filtro (m)  de ar" },
      { ru: "масляный фильтр", port: "filtro (m)  de óleo" },
      { ru: "топливный фильтр", port: "filtro (m)  de combustível" },
    ],
  },
  {
    name: "Дорога",
    dict: [
      { ru: "канистра", port: "bidão (m)  de gasolina" },
      { ru: "асфальт", port: "asfalto (m)" },
      { ru: "разметка", port: "marcação (f)  de estradas" },
      { ru: "бордюр", port: "lancil (m)" },
      { ru: "ограждение", port: "proteção (f)  guard-rail" },
      { ru: "кювет", port: "valeta (f)" },
      { ru: "обочина", port: "berma (f)  da estrada" },
      { ru: "столб", port: "poste (m)  de luz" },
      { ru: "вести (автомобиль)", port: "conduzir, guiar (vt)" },
      { ru: "поворачивать", port: "virar (vi)" },
      { ru: "разворачиваться", port: "fazer inversão de marcha" },
      { ru: "задний ход", port: "marcha-atrás (f)" },
      { ru: "сигналить (авто)", port: "buzinar (vi)" },
      { ru: "звуковой сигнал", port: "buzina (f)" },
      { ru: "застрять", port: "atolar-se (vp)" },
      { ru: "буксовать", port: "patinar (vi)" },
      { ru: "глушить (мотор)", port: "desligar (vt)" },
      { ru: "скорость", port: "velocidade (f)" },
      { ru: "превысить скорость", port: "exceder a velocidade" },
      { ru: "превысить скорость", port: "exceder a velocidade" },
      { ru: "штрафовать", port: "multar (vt)" },
      { ru: "светофор", port: "semáforo (m)" },
      { ru: "права водительские", port: "carta (f)  de condução" },
      { ru: "переезд", port: "passagem (f)  de nível" },
      { ru: "перекрёсток", port: "cruzamento (m)" },
      { ru: "пешеходный переход", port: "passadeira (f)  para peões" },
      { ru: "поворот (место на дороге)", port: "curva (f)" },
      { ru: "пешеходная зона", port: "zona (f)  pedonal" },
    ],
  },
  {
    name: "Праздник",
    dict: [
      { ru: "праздник (торжество)", port: "festa (f)" },
      { ru: "национальный праздник", port: "festa (f)  nacional" },
      { ru: "праздничный день", port: "feriado (m)" },
      { ru: "праздновать", port: "festejar (vt)" },
      { ru: "событие", port: "evento (m)" },
      { ru: "мероприятие (событие)", port: "evento (m)" },
      { ru: "банкет", port: "banquete (m)" },
      { ru: "приём (мероприятие)", port: "receção (f)" },
      { ru: "пир", port: "festim (m)" },
      { ru: "годовщина", port: "aniversário (m)" },
      { ru: "юбилей", port: "jubileu (m)" },
      { ru: "отметить (юбилей и т.п.)", port: "celebrar (vt)" },
      { ru: "Новый год", port: "Ano (m)  Novo" },
      { ru: "С Новым Годом!", port: "Feliz Ano Novo!" },
      { ru: "Дед Мороз, Санта Клаус", port: "Pai (m)  Natal" },
      { ru: "Новогодняя ёлка", port: "árvore (f)  de Natal" },
      { ru: "Рождество", port: "Natal (m)" },
      { ru: "Весёлого Рождества!", port: "Feliz Natal!" },
      { ru: "салют (фейерверк)", port: "fogo (m)  de artifício" },
      { ru: "салют (фейерверк)", port: "fogo (m)  de artifício" },
      { ru: "свадьба", port: "boda (f)" },
      { ru: "жених", port: "noivo (m)" },
      { ru: "невеста", port: "noiva (f)" },
      { ru: "приглашать", port: "convidar (vt)" },
      { ru: "приглашение (документ)", port: "convite (m)" },
      { ru: "гость", port: "convidado (m)" },
      { ru: "идти в гости", port: "visitar (vt)" },
      { ru: "встречать гостей", port: "receber os hóspedes" },
      { ru: "подарок", port: "presente (m)" },
      { ru: "дарить (подарок)", port: "oferecer (vt)" },
      { ru: "получать подарки", port: "receber presentes" },
      { ru: "букет", port: "ramo (m)  de flores" },
      { ru: "поздравление", port: "felicitações (f pl)" },
    ],
  },
  {
    name: "Похороны",
    dict: [
      { ru: "кладбище", port: "cemitério (m)" },
      { ru: "могила", port: "sepultura (f), túmulo (m)" },
      { ru: "крест", port: "cruz (f)" },
      { ru: "надгробие", port: "lápide (f)" },
      { ru: "ограда", port: "cerca (f)" },
      { ru: "часовня", port: "capela (f)" },
      { ru: "смерть", port: "morte (f)" },
      { ru: "умереть", port: "morrer (vi)" },
      { ru: "покойник", port: "defunto (m)" },
      { ru: "траур", port: "luto (m)" },
      { ru: "хоронить", port: "enterrar, sepultar (vt)" },
      { ru: "похоронное бюро", port: "agência (f)  funerária" },
      { ru: "похороны", port: "funeral (m)" },
      { ru: "венок", port: "coroa (f)  de flores" },
      { ru: "гроб", port: "caixão (m)" },
      { ru: "катафалк", port: "carro (m)  funerário" },
      { ru: "саван", port: "mortalha (f)" },
      { ru: "траурная процессия", port: "procissão (f)  funerária" },
      { ru: "погребальная урна", port: "urna (f)  funerária" },
      { ru: "погребальная урна", port: "urna (f)  funerária" },
      { ru: "крематорий", port: "crematório (m)" },
      { ru: "некролог", port: "obituário (m), necrologia (f)" },
      { ru: "плакать", port: "chorar (vi)" },
      { ru: "рыдать", port: "soluçar (vi)" },
    ],
  },
  {
    name: "Война",
    dict: [
      { ru: "взвод", port: "pelotão (m)" },
      { ru: "рота", port: "companhia (f)" },
      { ru: "полк", port: "regimento (m)" },
      { ru: "армия", port: "exército (m)" },
      { ru: "дивизия", port: "divisão (f)" },
      { ru: "отряд", port: "destacamento (m)" },
      { ru: "войско", port: "hoste (f)" },
      { ru: "солдат", port: "soldado (m)" },
      { ru: "офицер", port: "oficial (m)" },
      { ru: "рядовой", port: "soldado (m)  raso" },
      { ru: "сержант", port: "sargento (m)" },
      { ru: "лейтенант", port: "tenente (m)" },
      { ru: "капитан", port: "capitão (m)" },
      { ru: "майор", port: "major (m)" },
      { ru: "полковник", port: "coronel (m)" },
      { ru: "генерал", port: "general (m)" },
      { ru: "моряк", port: "marujo (m)" },
      { ru: "капитан", port: "capitão (m)" },
      { ru: "боцман", port: "contramestre (m)" },
      { ru: "боцман", port: "contramestre (m)" },
      { ru: "артиллерист", port: "artilheiro (m)" },
      { ru: "десантник", port: "soldado (m)  paraquedista" },
      { ru: "лётчик", port: "piloto (m)" },
      { ru: "штурман", port: "navegador (m)" },
      { ru: "механик", port: "mecânico (m)" },
      { ru: "сапёр", port: "sapador (m)" },
      { ru: "парашютист", port: "paraquedista (m)" },
      { ru: "разведчик", port: "explorador (m)" },
      { ru: "снайпер", port: "franco-atirador (m)" },
      { ru: "патруль", port: "patrulha (f)" },
      { ru: "патрулировать", port: "patrulhar (vt)" },
      { ru: "часовой (воен.)", port: "sentinela (f)" },
      { ru: "воин", port: "guerreiro (m)" },
      { ru: "герой", port: "herói (m)" },
      { ru: "героиня", port: "heroína (f)" },
      { ru: "патриот", port: "patriota (m)" },
      { ru: "предатель", port: "traidor (m)" },
      { ru: "предавать, изменять", port: "trair (vt)" },
      { ru: "дезертир", port: "desertor (m)" },
      { ru: "дезертировать", port: "desertar (vt)" },
      { ru: "наёмник", port: "mercenário (m)" },
    ],
  },
  {
    name: "Война - 2",
    dict: [
      { ru: "плен", port: "cativeiro (m)" },
      { ru: "взять в плен", port: "capturar (vt)" },
      { ru: "быть в плену", port: "estar em cativeiro" },
      { ru: "попасть в плен", port: "ser aprisionado" },
      { ru: "концлагерь", port: "campo (m)  de concentração" },
      { ru: "пленный (сущ.)", port: "prisioneiro (m)  de guerra" },
      { ru: "бежать (из плена)", port: "escapar (vi)" },
      { ru: "предать", port: "trair (vt)" },
      { ru: "предатель", port: "traidor (m)" },
      { ru: "предательство", port: "traição (f)" },
      { ru: "расстрелять (казнить)", port: "fuzilar, executar (vt)" },
      { ru: "расстрел (казнь)", port: "fuzilamento (m)" },
      { ru: "обмундирование", port: "equipamento (m)" },
      { ru: "погон", port: "platina (f)" },
      { ru: "противогаз", port: "máscara (f)  antigás" },
      { ru: "рация", port: "rádio (m)" },
      { ru: "шифр", port: "cifra (f), código (m)" },
      { ru: "конспирация", port: "conspiração (f)" },
      { ru: "пароль", port: "senha (f)" },
      { ru: "пароль", port: "senha (f)" },
      { ru: "мина", port: "mina (f)" },
      { ru: "заминировать", port: "minar (vt)" },
      { ru: "минное поле", port: "campo (m)  minado" },
      { ru: "воздушная тревога", port: "alarme (m)  aéreo" },
      { ru: "тревога (сигнал)", port: "alarme (m)" },
      { ru: "сигнал", port: "sinal (m)" },
      { ru: "сигнальная ракета", port: "sinalizador (m)" },
      { ru: "штаб", port: "estado-maior (m)" },
      { ru: "разведка", port: "reconhecimento (m)" },
      { ru: "обстановка", port: "situação (f)" },
      { ru: "рапорт", port: "informe (m)" },
      { ru: "засада", port: "emboscada (f)" },
      { ru: "подкрепление", port: "reforço (m)" },
      { ru: "мишень", port: "alvo (m)" },
      { ru: "полигон", port: "campo (m)  de tiro" },
      { ru: "манёвры", port: "manobras (f pl)" },
      { ru: "паника", port: "pânico (m)" },
      { ru: "разруха", port: "devastação (f)" },
      { ru: "разрушения", port: "ruínas (f pl)" },
      { ru: "разрушать", port: "destruir (vt)" },
      { ru: "выжить", port: "sobreviver (vi)" },
      { ru: "обезоружить", port: "desarmar (vt)" },
      { ru: "обращаться (с оружием)", port: "manusear (vt)" },
      { ru: "Смирно!", port: "Firmes!" },
      { ru: "Вольно!", port: "Descansar!" },
      { ru: "подвиг", port: "façanha (f)" },
      { ru: "клятва", port: "juramento (m)" },
      { ru: "клясться", port: "jurar (vi)" },
      { ru: "награда (медаль и т.п.)", port: "condecoração (f)" },
      { ru: "награждать", port: "condecorar (vt)" },
      { ru: "медаль", port: "medalha (f)" },
      { ru: "орден", port: "ordem (f)" },
      { ru: "победа", port: "vitória (f)" },
      { ru: "поражение", port: "derrota (f)" },
      { ru: "перемирие", port: "armistício (m)" },
      { ru: "знамя", port: "bandeira (f)" },
      { ru: "слава (почёт)", port: "glória (f)" },
      { ru: "парад", port: "desfile (m)  militar" },
      { ru: "маршировать", port: "marchar (vi)" },
    ],
  },
  {
    name: "Оружие",
    dict: [
      { ru: "оружие", port: "arma (f)" },
      { ru: "огнестрельное оружие", port: "arma (f)  de fogo" },
      { ru: "холодное оружие", port: "arma (f)  branca" },
      { ru: "химическое оружие", port: "arma (f)  química" },
      { ru: "ядерный", port: "nuclear" },
      { ru: "ядерное оружие", port: "arma (f)  nuclear" },
      { ru: "бомба", port: "bomba (f)" },
      { ru: "атомная бомба", port: "bomba (f)  atómica" },
      { ru: "пистолет", port: "pistola (f)" },
      { ru: "ружьё", port: "caçadeira (f)" },
      { ru: "автомат", port: "pistola-metralhadora (f)" },
      { ru: "пулемёт", port: "metralhadora (f)" },
      { ru: "дуло", port: "boca (f)" },
      { ru: "ствол", port: "cano (m)" },
      { ru: "калибр", port: "calibre (m)" },
      { ru: "курок (спусковой крючок)", port: "gatilho (m)" },
      { ru: "прицел", port: "mira (f)" },
      { ru: "магазин (автомата)", port: "carregador (m)" },
      { ru: "приклад", port: "coronha (f)" },
      { ru: "приклад", port: "coronha (f)" },
      { ru: "ручная граната", port: "granada (f)  de mão" },
      { ru: "взрывчатка", port: "explosivo (m)" },
      { ru: "пуля", port: "bala (f)" },
      { ru: "патрон", port: "cartucho (m)" },
      { ru: "заряд", port: "carga (f)" },
      { ru: "боеприпасы", port: "munições (f pl)" },
      { ru: "бомбардировщик", port: "bombardeiro (m)" },
      { ru: "истребитель", port: "avião (m)  de caça" },
      { ru: "вертолёт", port: "helicóptero (m)" },
      { ru: "зенитка", port: "canhão (m)  antiaéreo" },
      { ru: "танк", port: "tanque (m)" },
      { ru: "пушка (на танке)", port: "canhão (m), peça (f)" },
      { ru: "артиллерия", port: "artilharia (f)" },
      { ru: "пушка, орудие", port: "canhão (m)" },
      { ru: "навести (орудие)", port: "fazer a pontaria" },
      { ru: "снаряд", port: "obus (m)" },
      { ru: "миномётная мина", port: "granada (f)  de morteiro" },
      { ru: "миномёт", port: "morteiro (m)" },
      { ru: "осколок", port: "estilhaço (m)" },
      { ru: "подводная лодка", port: "submarino (m)" },
      { ru: "торпеда", port: "torpedo (m)" },
      { ru: "ракета", port: "míssil (m)" },
      { ru: "заряжать (оружие)", port: "carregar (vt)" },
      { ru: "стрелять", port: "atirar, disparar (vi)" },
      { ru: "целиться (в …)", port: "apontar para …" },
      { ru: "штык", port: "baioneta (f)" },
      { ru: "шпага", port: "espada (f)" },
      { ru: "сабля", port: "sabre (m)" },
      { ru: "копьё", port: "lança (f)" },
      { ru: "лук", port: "arco (m)" },
      { ru: "стрела", port: "flecha (f)" },
      { ru: "мушкет", port: "mosquete (m)" },
      { ru: "арбалет", port: "besta (f)" },
    ],
  },
  {
    name: "Первобытные люди",
    dict: [
      { ru: "первобытный", port: "primitivo" },
      { ru: "доисторический", port: "pré-histórico" },
      { ru: "древний", port: "antigo" },
      { ru: "Каменный Век", port: "Idade (f)  da Pedra" },
      { ru: "Бронзовый Век", port: "Idade (f)  do Bronze" },
      { ru: "ледниковый период", port: "período (m)  glacial" },
      { ru: "племя", port: "tribo (f)" },
      { ru: "людоед", port: "canibal (m)" },
      { ru: "охотник", port: "caçador (m)" },
      { ru: "охотиться", port: "caçar (vi)" },
      { ru: "мамонт", port: "mamute (m)" },
      { ru: "пещера", port: "caverna (f)" },
      { ru: "огонь", port: "fogo (m)" },
      { ru: "костёр", port: "fogueira (f)" },
      { ru: "наскальный рисунок", port: "pintura (f)  rupestre" },
      { ru: "орудие труда", port: "ferramenta (f)" },
      { ru: "копьё", port: "lança (f)" },
      { ru: "каменный топор", port: "machado (m)  de pedra" },
      { ru: "воевать (вести войну)", port: "guerrear (vt)" },
      { ru: "воевать (вести войну)", port: "guerrear (vt)" },
      { ru: "приручать (животных)", port: "domesticar (vt)" },
      { ru: "идол", port: "ídolo (m)" },
      { ru: "поклоняться", port: "adorar, venerar (vt)" },
      { ru: "суеверие", port: "superstição (f)" },
      { ru: "обряд, ритуал", port: "ritual (m)" },
      { ru: "эволюция", port: "evolução (f)" },
      { ru: "развитие", port: "desenvolvimento (m)" },
      { ru: "исчезновение", port: "desaparecimento (m)" },
      { ru: "приспосабливаться", port: "adaptar-se (vp)" },
      { ru: "археология", port: "arqueologia (f)" },
      { ru: "археолог", port: "arqueólogo (m)" },
      { ru: "археологический", port: "arqueológico" },
      { ru: "раскопки (место работ)", port: "local (m)  das escavações" },
      { ru: "раскопки (работы)", port: "escavações (f pl)" },
      { ru: "находка", port: "achado (m)" },
      { ru: "фрагмент", port: "fragmento (m)" },
    ],
  },
  {
    name: "Средние века",
    dict: [
      { ru: "народ", port: "povo (m)" },
      { ru: "народы", port: "povos (m pl)" },
      { ru: "племя", port: "tribo (f)" },
      { ru: "племена", port: "tribos (f pl)" },
      { ru: "варвары", port: "bárbaros (m pl)" },
      { ru: "галлы", port: "gauleses (m pl)" },
      { ru: "готы", port: "godos (m pl)" },
      { ru: "славяне", port: "eslavos (m pl)" },
      { ru: "викинги", port: "vikings (m pl)" },
      { ru: "римляне", port: "romanos (m pl)" },
      { ru: "римский", port: "romano" },
      { ru: "византийцы", port: "bizantinos (m pl)" },
      { ru: "Византия", port: "Bizâncio" },
      { ru: "византийский", port: "bizantino" },
      { ru: "император", port: "imperador (m)" },
      { ru: "вождь", port: "líder (m)" },
      { ru: "могущественный", port: "poderoso" },
      { ru: "король", port: "rei (m)" },
      { ru: "правитель", port: "governante (m)" },
      { ru: "правитель", port: "governante (m)" },
      { ru: "рыцарь", port: "cavaleiro (m)" },
      { ru: "феодал", port: "senhor feudal (m)" },
      { ru: "феодальный", port: "feudal" },
      { ru: "вассал", port: "vassalo (m)" },
      { ru: "герцог", port: "duque (m)" },
      { ru: "граф", port: "conde (m)" },
      { ru: "барон", port: "barão (m)" },
      { ru: "епископ", port: "bispo (m)" },
      { ru: "доспехи", port: "armadura (f)" },
      { ru: "щит", port: "escudo (m)" },
      { ru: "меч", port: "espada (f)" },
      { ru: "забрало", port: "viseira (f)" },
      { ru: "кольчуга", port: "cota (f)  de malha" },
      { ru: "крестовый поход", port: "cruzada (f)" },
      { ru: "крестоносец", port: "cruzado (m)" },
      { ru: "территория", port: "território (m)" },
      { ru: "нападать", port: "atacar (vt)" },
      { ru: "завоевать", port: "conquistar (vt)" },
      { ru: "захватить", port: "ocupar, invadir (vt)" },
      { ru: "осада", port: "assédio, sítio (m)" },
      { ru: "осаждённый", port: "sitiado" },
      { ru: "осаждать", port: "assediar, sitiar (vt)" },
      { ru: "инквизиция", port: "inquisição (f)" },
      { ru: "инквизитор", port: "inquisidor (m)" },
      { ru: "пытка", port: "tortura (f)" },
      { ru: "жестокий", port: "cruel" },
      { ru: "еретик", port: "herege (m)" },
      { ru: "ересь", port: "heresia (f)" },
      { ru: "мореплавание", port: "navegação (f)  maritima" },
      { ru: "пират", port: "pirata (m)" },
      { ru: "пиратство", port: "pirataria (f)" },
      { ru: "абордаж", port: "abordagem (f)" },
      { ru: "добыча", port: "saque, pulhagem (f)" },
      { ru: "сокровища", port: "tesouros (m pl)" },
      { ru: "открытие (новых земель)", port: "descobrimento (m)" },
      { ru: "открыть", port: "descobrir (vt)" },
      { ru: "экспедиция", port: "expedição (f)" },
      { ru: "мушкетёр", port: "mosqueteiro (m)" },
      { ru: "кардинал", port: "cardeal (m)" },
    ],
  },
  {
    name: "Правитель, шеф, руководитель",
    dict: [
      { ru: "король", port: "rei (m)" },
      { ru: "королева", port: "rainha (f)" },
      { ru: "королевский", port: "real" },
      { ru: "королевство", port: "reino (m)" },
      { ru: "принц", port: "príncipe (m)" },
      { ru: "принцесса", port: "princesa (f)" },
      { ru: "президент", port: "presidente (m)" },
      { ru: "вице-президент", port: "vice-presidente (m)" },
      { ru: "сенатор", port: "senador (m)" },
      { ru: "монарх", port: "monarca (m)" },
      { ru: "правитель", port: "governante (m)" },
      { ru: "диктатор", port: "ditador (m)" },
      { ru: "тиран", port: "tirano (m)" },
      { ru: "магнат", port: "magnata (m)" },
      { ru: "директор", port: "diretor (m)" },
      { ru: "шеф", port: "chefe (m)" },
      { ru: "управляющий", port: "dirigente (m)" },
      { ru: "босс", port: "patrão (m)" },
      { ru: "хозяин (владелец)", port: "dono (m)" },
      { ru: "хозяин (владелец)", port: "dono (m)" },
      { ru: "глава (~ делегации)", port: "chefe (m)" },
      { ru: "власти", port: "autoridades (f pl)" },
      { ru: "начальство", port: "superiores (m pl)" },
      { ru: "губернатор", port: "governador (m)" },
      { ru: "консул", port: "cônsul (m)" },
      { ru: "дипломат", port: "diplomata (m)" },
      { ru: "мэр", port: "prefeito (m)" },
      { ru: "шериф", port: "xerife (m)" },
      { ru: "император", port: "imperador (m)" },
      { ru: "царь", port: "czar (m)" },
      { ru: "фараон", port: "faraó (m)" },
      { ru: "хан", port: "cão (m)" },
    ],
  },
  {
    name: "Дорога, путь",
    dict: [
      { ru: "дорога", port: "estrada (f)" },
      { ru: "путь", port: "caminho (m)" },
      { ru: "шоссе", port: "rodovia (f)" },
      { ru: "автомагистраль", port: "autoestrada (f)" },
      { ru: "национальная дорога", port: "estrada (f)  nacional" },
      { ru: "главная дорога", port: "estrada (f)  principal" },
      { ru: "просёлочная дорога", port: "caminho (m)  de terra batida" },
      { ru: "тропа", port: "trilha (f)" },
      { ru: "тропинка", port: "vereda (f)" },
      { ru: "Где?", port: "Onde?" },
      { ru: "Куда?", port: "Para onde?" },
      { ru: "Откуда?", port: "De onde?" },
      { ru: "направление", port: "direção (f)" },
      { ru: "указать (направление)", port: "indicar (vt)" },
      { ru: "налево", port: "para esquerda" },
      { ru: "направо", port: "para direita" },
      { ru: "прямо", port: "em frente" },
      { ru: "назад", port: "para trás" },
      { ru: "поворот (место на дороге)", port: "curva (f)" },
      { ru: "поворот (место на дороге)", port: "curva (f)" },
      { ru: "поворачивать", port: "virar (vi)" },
      { ru: "разворачиваться", port: "fazer inversão de marcha" },
      { ru: "виднеться", port: "estar visível" },
      { ru: "показаться (появиться)", port: "aparecer (vi)" },
      { ru: "остановка (в пути)", port: "paragem (f)" },
      { ru: "отдохнуть", port: "descansar (vi)" },
      { ru: "отдых", port: "descanso (m)" },
      { ru: "заблудиться", port: "perder-se (vp)" },
      { ru: "вести к … (о дороге)", port: "levar para …" },
      { ru: "выйти к …", port: "chegar a …" },
      { ru: "отрезок (пути)", port: "trecho (m)" },
      { ru: "асфальт", port: "asfalto (m)" },
      { ru: "бордюр", port: "lancil (m)" },
      { ru: "канава", port: "valeta (f)" },
      { ru: "люк (на дороге)", port: "tampa (f)  de esgoto" },
      { ru: "обочина", port: "berma (f)  da estrada" },
      { ru: "яма", port: "buraco (m)" },
      { ru: "идти", port: "ir (vi)" },
      { ru: "обогнать", port: "ultrapassar (vt)" },
      { ru: "шаг", port: "passo (m)" },
      { ru: "пешком", port: "a pé" },
      { ru: "перегородить (дорогу)", port: "bloquear (vt)" },
      { ru: "шлагбаум", port: "cancela (f)" },
      { ru: "тупик", port: "beco (m)  sem saída" },
    ],
  },
  {
    name: "Нарушение закона -1",
    dict: [
      { ru: "бандит", port: "bandido (m)" },
      { ru: "преступление", port: "crime (m)" },
      { ru: "преступник", port: "criminoso (m)" },
      { ru: "вор", port: "ladrão (m)" },
      { ru: "воровать, красть", port: "roubar (vt)" },
      { ru: "воровство", port: "roubo (m)" },
      { ru: "кража", port: "furto (m)" },
      { ru: "похитить (кого-л.)", port: "raptar (vt)" },
      { ru: "похищение (людей)", port: "rapto (m)" },
      { ru: "похититель (людей)", port: "raptor (m)" },
      { ru: "выкуп (плата)", port: "resgate (m)" },
      { ru: "требовать выкуп", port: "pedir resgate" },
      { ru: "грабить", port: "roubar (vt)" },
      { ru: "грабёж, ограбление", port: "assalto, roubo (m)" },
      { ru: "грабитель", port: "assaltante (m)" },
      { ru: "вымогать", port: "extorquir (vt)" },
      { ru: "вымогатель", port: "extorsionário (m)" },
      { ru: "вымогательство", port: "extorsão (f)" },
      { ru: "убить", port: "matar, assassinar (vt)" },
      { ru: "убить", port: "matar, assassinar (vt)" },
      { ru: "убийство", port: "homicídio (m)" },
      { ru: "убийца", port: "homicida, assassino (m)" },
      { ru: "выстрел", port: "tiro (m)" },
      { ru: "выстрелить", port: "dar um tiro" },
      { ru: "застрелить", port: "matar a tiro" },
      { ru: "стрелять", port: "atirar, disparar (vi)" },
      { ru: "стрельба", port: "tiroteio (m)" },
      { ru: "происшествие", port: "acontecimento (m)" },
      { ru: "драка", port: "porrada (f)" },
      { ru: "На помощь! Спасите!", port: "Socorro!" },
      { ru: "жертва", port: "vítima (f)" },
      { ru: "повредить (испортить)", port: "danificar (vt)" },
      { ru: "ущерб", port: "dano (m)" },
      { ru: "труп", port: "cadáver (m)" },
      { ru: "тяжкий (о преступлении)", port: "grave" },
      { ru: "нападать", port: "atacar (vt)" },
      { ru: "бить (ударять)", port: "bater (vt)" },
      { ru: "избить", port: "espancar (vt)" },
      { ru: "отнять (деньги и т.п.)", port: "tirar (vt)" },
      { ru: "зарезать", port: "esfaquear (vt)" },
      { ru: "изувечить", port: "mutilar (vt)" },
      { ru: "ранить", port: "ferir (vt)" },
      { ru: "шантаж", port: "chantagem (f)" },
      { ru: "шантажировать", port: "chantagear (vt)" },
      { ru: "шантажист", port: "chantagista (m)" },
      { ru: "рэкет", port: "extorsão (f)" },
      { ru: "рэкетир", port: "extorsionário (m)" },
      { ru: "гангстер", port: "gângster (m)" },
      { ru: "мафия", port: "máfia (f)" },
      { ru: "карманный воришка", port: "carteirista (m)" },
      { ru: "взломщик", port: "assaltante, ladrão (m)" },
      { ru: "контрабанда (действия)", port: "contrabando (m)" },
      { ru: "контрабандист", port: "contrabandista (m)" },
      { ru: "подделка (понятие)", port: "falsificação (f)" },
      { ru: "подделывать", port: "falsificar (vt)" },
      { ru: "фальшивый", port: "falsificado" },
    ],
  },
  {
    name: "Нарушение закона - 2",
    dict: [
      { ru: "изнасилование", port: "violação (f)" },
      { ru: "изнасиловать", port: "violar (vt)" },
      { ru: "насильник", port: "violador (m)" },
      { ru: "маньяк", port: "maníaco (m)" },
      { ru: "проститутка", port: "prostituta (f)" },
      { ru: "проституция", port: "prostituição (f)" },
      { ru: "сутенёр", port: "chulo (m)" },
      { ru: "наркоман", port: "toxicodependente (m)" },
      { ru: "торговец наркотиками", port: "traficante (m)" },
      { ru: "взорвать", port: "explodir (vt)" },
      { ru: "взрыв", port: "explosão (f)" },
      { ru: "поджечь", port: "incendiar (vt)" },
      { ru: "поджигатель", port: "incendiário (m)" },
      { ru: "терроризм", port: "terrorismo (m)" },
      { ru: "террорист", port: "terrorista (m)" },
      { ru: "заложник", port: "refém (m)" },
      { ru: "обмануть", port: "enganar (vt)" },
      { ru: "обман", port: "engano (m)" },
      { ru: "мошенник", port: "vigarista (m)" },
      { ru: "мошенник", port: "vigarista (m)" },
      { ru: "подкупить", port: "subornar (vt)" },
      { ru: "подкуп", port: "suborno (m)" },
      { ru: "взятка", port: "suborno (m)" },
      { ru: "яд", port: "veneno (m)" },
      { ru: "отравить", port: "envenenar (vt)" },
      { ru: "отравиться (убить себя)", port: "envenenar-se (vp)" },
      { ru: "самоубийство", port: "suicídio (m)" },
      { ru: "самоубийца", port: "suicida (m)" },
      { ru: "угрожать", port: "ameaçar (vt)" },
      { ru: "угроза", port: "ameaça (f)" },
      { ru: "покушаться", port: "atentar contra a vida de …" },
      { ru: "покушение (на жизнь)", port: "atentado (m)" },
      { ru: "угнать (автомобиль)", port: "roubar (vt)" },
      { ru: "угнать (самолёт)", port: "desviar (vt)" },
      { ru: "месть", port: "vingança (f)" },
      { ru: "мстить", port: "vingar-se (vp)" },
      { ru: "пытать", port: "torturar (vt)" },
      { ru: "пытка", port: "tortura (f)" },
      { ru: "мучить", port: "atormentar (vt)" },
      { ru: "пират", port: "pirata (m)" },
      { ru: "хулиган", port: "desordeiro (m)" },
      { ru: "вооружённый", port: "armado" },
      { ru: "насилие", port: "violência (f)" },
      { ru: "нелегальный", port: "ilegal" },
      { ru: "шпионаж", port: "espionagem (f)" },
      { ru: "шпионить", port: "espionar (vi)" },
    ],
  },
  {
    name: "Полиция - 1",
    dict: [
      { ru: "присяжные", port: "jurados (m pl)" },
      { ru: "суд присяжных", port: "tribunal (m)  do júri" },
      { ru: "судить (о судьях)", port: "julgar (vt)" },
      { ru: "адвокат", port: "advogado (m)" },
      { ru: "подсудимый", port: "réu (m)" },
      { ru: "скамья подсудимых", port: "banco (m)  dos réus" },
      { ru: "обвинение", port: "acusação (f)" },
      { ru: "обвиняемый", port: "acusado (m)" },
      { ru: "приговор", port: "sentença (f)" },
      { ru: "приговорить", port: "sentenciar (vt)" },
      { ru: "виновник", port: "culpado (m)" },
      { ru: "наказать", port: "punir (vt)" },
      { ru: "наказание", port: "punição (f)" },
      { ru: "штраф", port: "multa (f)" },
      { ru: "пожизненное заключение", port: "prisão (f)  perpétua" },
      { ru: "смертная казнь", port: "pena (f)  de morte" },
      { ru: "электрический стул", port: "cadeira (f)  elétrica" },
      { ru: "виселица", port: "forca (f)" },
      { ru: "казнить", port: "executar (vt)" },
      { ru: "казнить", port: "executar (vt)" },
      { ru: "казнь", port: "execução (f)" },
      { ru: "тюрьма", port: "prisão (f)" },
      { ru: "камера", port: "cela (f)  de prisão" },
      { ru: "присяжные", port: "jurados (m pl)" },
      { ru: "суд присяжных", port: "tribunal (m)  do júri" },
      { ru: "судить (о судьях)", port: "julgar (vt)" },
      { ru: "адвокат", port: "advogado (m)" },
      { ru: "подсудимый", port: "réu (m)" },
      { ru: "скамья подсудимых", port: "banco (m)  dos réus" },
      { ru: "обвинение", port: "acusação (f)" },
      { ru: "обвиняемый", port: "acusado (m)" },
      { ru: "приговор", port: "sentença (f)" },
      { ru: "приговорить", port: "sentenciar (vt)" },
      { ru: "виновник", port: "culpado (m)" },
      { ru: "наказать", port: "punir (vt)" },
      { ru: "наказание", port: "punição (f)" },
      { ru: "штраф", port: "multa (f)" },
      { ru: "пожизненное заключение", port: "prisão (f)  perpétua" },
      { ru: "смертная казнь", port: "pena (f)  de morte" },
      { ru: "электрический стул", port: "cadeira (f)  elétrica" },
      { ru: "виселица", port: "forca (f)" },
      { ru: "казнить", port: "executar (vt)" },
      { ru: "казнь", port: "execução (f)" },
      { ru: "тюрьма", port: "prisão (f)" },
      { ru: "камера", port: "cela (f)  de prisão" },
    ],
  },
  {
    name: "Полиция - 2",
    dict: [
      { ru: "розыск", port: "procura (f)" },
      { ru: "искать, разыскивать", port: "procurar (vt)" },
      { ru: "подозрение", port: "suspeita (f)" },
      { ru: "подозрительный", port: "suspeito" },
      { ru: "остановить (для проверки)", port: "parar (vt)" },
      { ru: "задержать", port: "deter (vt)" },
      { ru: "дело (уголовное)", port: "caso (m)" },
      { ru: "расследование", port: "investigação (f)" },
      { ru: "детектив, сыщик", port: "detetive (m)" },
      { ru: "следователь", port: "investigador (m)" },
      { ru: "версия", port: "versão (f)" },
      { ru: "мотив (преступления)", port: "motivo (m)" },
      { ru: "допрос", port: "interrogatório (m)" },
      { ru: "допрашивать", port: "interrogar (vt)" },
      { ru: "опрашивать", port: "questionar (vt)" },
      { ru: "проверка", port: "verificação (f)" },
      { ru: "облава", port: "rusga (f)" },
      { ru: "обыск", port: "busca (f)" },
      { ru: "погоня", port: "perseguição (f)" },
      { ru: "погоня", port: "perseguição (f)" },
      { ru: "преследовать", port: "perseguir (vt)" },
      { ru: "следить (выслеживать)", port: "seguir (vt)" },
      { ru: "арест", port: "prisão (f)" },
      { ru: "арестовать", port: "prender (vt)" },
      { ru: "поймать (преступника)", port: "pegar, capturar (vt)" },
      { ru: "поимка", port: "captura (f)" },
      { ru: "документ", port: "documento (m)" },
      { ru: "доказательство", port: "prova (f)" },
      { ru: "доказывать", port: "provar (vt)" },
      { ru: "след (отпечаток ноги)", port: "pegada (f)" },
      { ru: "отпечатки пальцев", port: "impressões (f pl)  digitais" },
      { ru: "улика", port: "prova (f)" },
      { ru: "алиби", port: "álibi (m)" },
      { ru: "невиновный", port: "inocente" },
      { ru: "несправедливость", port: "injustiça (f)" },
      { ru: "несправедливый", port: "injusto" },
      { ru: "криминальный", port: "criminal" },
      { ru: "конфисковать", port: "confiscar (vt)" },
      { ru: "наркотик", port: "droga (f)" },
      { ru: "оружие", port: "arma (f)" },
      { ru: "обезоружить", port: "desarmar (vt)" },
      { ru: "приказывать", port: "ordenar (vt)" },
      { ru: "исчезнуть", port: "desaparecer (vi)" },
      { ru: "закон", port: "lei (f)" },
      { ru: "законный", port: "legal" },
      { ru: "незаконный", port: "ilegal" },
      { ru: "ответственность", port: "responsabilidade (f)" },
      { ru: "ответственный", port: "responsável" },
    ],
  },
  {
    name: "Космос",
    dict: [
      { ru: "космос", port: "cosmos (m)" },
      { ru: "космический", port: "cósmico" },
      { ru: "космическое пространство", port: "espaço (m)  cósmico" },
      { ru: "мир", port: "mundo (m)" },
      { ru: "вселенная", port: "universo (m)" },
      { ru: "галактика", port: "galáxia (f)" },
      { ru: "звезда", port: "estrela (f)" },
      { ru: "созвездие", port: "constelação (f)" },
      { ru: "планета", port: "planeta (m)" },
      { ru: "спутник", port: "satélite (m)" },
      { ru: "метеорит", port: "meteorito (m)" },
      { ru: "комета", port: "cometa (m)" },
      { ru: "астероид", port: "asteroide (m)" },
      { ru: "орбита", port: "órbita (f)" },
      { ru: "вращаться", port: "girar (vi)" },
      { ru: "атмосфера", port: "atmosfera (f)" },
      { ru: "Солнце", port: "Sol (m)" },
      { ru: "солнечная система", port: "Sistema (m)  Solar" },
      { ru: "солнечное затмение", port: "eclipse (m)  solar" },
      { ru: "солнечное затмение", port: "eclipse (m)  solar" },
      { ru: "Земля", port: "Terra (f)" },
      { ru: "Луна", port: "Lua (f)" },
      { ru: "Марс", port: "Marte (m)" },
      { ru: "Венера", port: "Vénus (m)" },
      { ru: "Юпитер", port: "Júpiter (m)" },
      { ru: "Сатурн", port: "Saturno (m)" },
      { ru: "Меркурий", port: "Mercúrio (m)" },
      { ru: "Уран", port: "Urano (m)" },
      { ru: "Нептун", port: "Neptuno (m)" },
      { ru: "Плутон", port: "Plutão (m)" },
      { ru: "Млечный Путь", port: "Via Láctea (f)" },
      { ru: "Большая Медведица", port: "Ursa Maior (f)" },
      { ru: "Полярная Звезда", port: "Estrela Polar (f)" },
      { ru: "марсианин", port: "marciano (m)" },
      { ru: "инопланетянин", port: "extraterrestre (m)" },
      { ru: "пришелец", port: "alienígena (m)" },
      { ru: "летающая тарелка", port: "disco (m)  voador" },
      { ru: "космический корабль", port: "nave (f)  espacial" },
      { ru: "орбитальная станция", port: "estação (f)  orbital" },
      { ru: "старт (ракеты)", port: "lançamento (m)" },
      { ru: "двигатель", port: "motor (m)" },
      { ru: "сопло", port: "bocal (m)" },
      { ru: "топливо", port: "combustível (m)" },
      { ru: "кабина", port: "cabine (f)" },
      { ru: "антенна", port: "antena (f)" },
      { ru: "иллюминатор", port: "vigia (f)" },
      { ru: "солнечная батарея", port: "bateria (f)  solar" },
      { ru: "скафандр", port: "traje (m)  espacial" },
      { ru: "невесомость", port: "imponderabilidade (f)" },
      { ru: "кислород", port: "oxigénio (m)" },
      { ru: "стыковка", port: "acoplagem (f)" },
      { ru: "производить стыковку", port: "fazer uma acoplagem" },
      { ru: "обсерватория", port: "observatório (m)" },
      { ru: "телескоп", port: "telescópio (m)" },
      { ru: "наблюдать", port: "observar (vt)" },
      { ru: "исследовать", port: "explorar (vt)" },
    ],
  },
  {
    name: "Земля",
    dict: [
      { ru: "Земля", port: "Terra (f)" },
      { ru: "земной шар", port: "globo (m)  terrestre" },
      { ru: "планета", port: "planeta (m)" },
      { ru: "атмосфера", port: "atmosfera (f)" },
      { ru: "география", port: "geografia (f)" },
      { ru: "природа", port: "natureza (f)" },
      { ru: "глобус", port: "globo (m)" },
      { ru: "карта", port: "mapa (m)" },
      { ru: "атлас", port: "atlas (m)" },
      { ru: "Европа", port: "Europa (f)" },
      { ru: "Азия", port: "Ásia (f)" },
      { ru: "Африка", port: "África (f)" },
      { ru: "Австралия", port: "Austrália (f)" },
      { ru: "Америка", port: "América (f)" },
      { ru: "Северная Америка", port: "América (f)  do Norte" },
      { ru: "Южная Америка", port: "América (f)  do Sul" },
      { ru: "Антарктида", port: "Antártida (f)" },
      { ru: "Арктика", port: "Ártico (m)" },
    ],
  },
  {
    name: "Части света",
    dict: [
      { ru: "север", port: "norte (m)" },
      { ru: "на север", port: "para norte" },
      { ru: "на севере", port: "no norte" },
      { ru: "северный", port: "do norte" },
      { ru: "юг", port: "sul (m)" },
      { ru: "на юг", port: "para sul" },
      { ru: "на юге", port: "no sul" },
      { ru: "южный", port: "do sul" },
      { ru: "запад", port: "oeste, ocidente (m)" },
      { ru: "на запад", port: "para oeste" },
      { ru: "на западе", port: "no oeste" },
      { ru: "западный", port: "ocidental" },
      { ru: "восток", port: "leste, oriente (m)" },
      { ru: "на восток", port: "para leste" },
      { ru: "на востоке", port: "no leste" },
      { ru: "восточный", port: "oriental" },
    ],
  },
  {
    name: "Море, океан",
    dict: [
      { ru: "море", port: "mar (m)" },
      { ru: "океан", port: "oceano (m)" },
      { ru: "залив", port: "golfo (m)" },
      { ru: "пролив", port: "estreito (m)" },
      { ru: "земля, суша", port: "terra (f)  firme" },
      { ru: "материк", port: "continente (m)" },
      { ru: "остров", port: "ilha (f)" },
      { ru: "полуостров", port: "península (f)" },
      { ru: "архипелаг", port: "arquipélago (m)" },
      { ru: "бухта", port: "baía (f)" },
      { ru: "гавань", port: "porto (m)" },
      { ru: "лагуна", port: "lagoa (f)" },
      { ru: "мыс", port: "cabo (m)" },
      { ru: "атолл", port: "atol (m)" },
      { ru: "риф", port: "recife (m)" },
      { ru: "коралл", port: "coral (m)" },
      { ru: "коралловый риф", port: "recife (m)  de coral" },
      { ru: "глубокий", port: "profundo" },
      { ru: "глубина", port: "profundidade (f)" },
      { ru: "глубина", port: "profundidade (f)" },
      { ru: "бездна", port: "abismo (m)" },
      { ru: "впадина", port: "fossa (f)  oceânica" },
      { ru: "течение", port: "corrente (f)" },
      { ru: "омывать", port: "banhar (vt)" },
      { ru: "побережье", port: "litoral (m)" },
      { ru: "берег (моря)", port: "costa (f)" },
      { ru: "прилив", port: "maré (f)  alta" },
      { ru: "отлив", port: "maré (f)  baixa" },
      { ru: "отмель", port: "restinga (f)" },
      { ru: "дно", port: "fundo (m)" },
      { ru: "волна", port: "onda (f)" },
      { ru: "гребень волны", port: "crista (f)  da onda" },
      { ru: "пена", port: "espuma (f)" },
      { ru: "буря, шторм", port: "tempestade (f)" },
      { ru: "ураган", port: "furacão (m)" },
      { ru: "цунами", port: "tsunami (m)" },
      { ru: "штиль", port: "calmaria (f)" },
      { ru: "спокойный", port: "calmo" },
      { ru: "полюс", port: "polo (m)" },
      { ru: "полярный", port: "polar" },
      { ru: "широта", port: "latitude (f)" },
      { ru: "долгота", port: "longitude (f)" },
      { ru: "параллель", port: "paralela (f)" },
      { ru: "экватор", port: "equador (m)" },
      { ru: "небо", port: "céu (m)" },
      { ru: "горизонт", port: "horizonte (m)" },
      { ru: "воздух", port: "ar (m)" },
      { ru: "маяк", port: "farol (m)" },
      { ru: "нырять", port: "mergulhar (vi)" },
      { ru: "затонуть (о корабле)", port: "afundar-se (vp)" },
      { ru: "сокровища", port: "tesouros (m pl)" },
    ],
  },
  {
    name: "Горы",
    dict: [
      { ru: "прóпасть, обрыв", port: "precipício (m)" },
      { ru: "перевал", port: "passo, colo (m)" },
      { ru: "плато", port: "planalto (m)" },
      { ru: "скала", port: "falésia (f)" },
      { ru: "холм", port: "colina (f)" },
      { ru: "ледник", port: "glaciar (m)" },
      { ru: "водопад", port: "queda (f)  d'água" },
      { ru: "гейзер", port: "géiser (m)" },
      { ru: "озеро", port: "lago (m)" },
      { ru: "равнина", port: "planície (f)" },
      { ru: "пейзаж", port: "paisagem (f)" },
      { ru: "эхо", port: "eco (m)" },
      { ru: "альпинист", port: "alpinista (m)" },
      { ru: "скалолаз", port: "escalador (m)" },
      { ru: "покорять", port: "conquistar (vt)" },
      { ru: "восхождение", port: "subida, escalada (f)" },
      { ru: "Альпы", port: "Alpes (m pl)" },
      { ru: "Пиренеи", port: "Pirineus (m pl)" },
      { ru: "Карпаты", port: "Cárpatos (m pl)" },
      { ru: "Карпаты", port: "Cárpatos (m pl)" },
      { ru: "Уральские горы", port: "montes (m pl)  Urais" },
      { ru: "Кавказ", port: "Cáucaso (m)" },
      { ru: "Эльбрус", port: "Elbrus (m)" },
      { ru: "Алтай", port: "Altai (m)" },
      { ru: "Тянь-Шань", port: "Tian Shan (m)" },
      { ru: "Памир", port: "Pamir (m)" },
      { ru: "Гималаи", port: "Himalaias (m pl)" },
      { ru: "Эверест", port: "monte (m)  Everest" },
      { ru: "Анды", port: "Cordilheira (f)  dos Andes" },
      { ru: "Килиманджаро", port: "Kilimanjaro (m)" },
    ],
  },
  {
    name: "Реки",
    dict: [
      { ru: "река", port: "rio (m)" },
      { ru: "источник, родник", port: "fonte, nascente (f)" },
      { ru: "русло", port: "leito (m)  do rio" },
      { ru: "бассейн (реки)", port: "bacia (f)" },
      { ru: "впадать в … (о реке)", port: "desaguar no …" },
      { ru: "приток", port: "afluente (m)" },
      { ru: "берег", port: "margem (f)" },
      { ru: "течение", port: "corrente (f)" },
      { ru: "вниз по течению", port: "rio abaixo" },
      { ru: "вверх по течению", port: "rio acima" },
      { ru: "наводнение", port: "inundação (f)" },
      { ru: "половодье", port: "cheia (f)" },
      { ru: "разливаться", port: "transbordar (vi)" },
      { ru: "затоплять", port: "inundar (vt)" },
      { ru: "мель", port: "baixio (m)" },
      { ru: "порог", port: "rápidos (m pl)" },
      { ru: "плотина", port: "barragem (f)" },
      { ru: "канал", port: "canal (m)" },
      { ru: "водохранилище", port: "reservatório (m)  de água" },
      { ru: "водохранилище", port: "reservatório (m)  de água" },
      { ru: "шлюз", port: "esclusa (f)" },
      { ru: "водоём", port: "corpo (m)  de água" },
      { ru: "болото", port: "pântano (m)" },
      { ru: "трясина", port: "tremedal (m)" },
      { ru: "водоворот", port: "remoinho (m)" },
      { ru: "ручей", port: "arroio, regato (m)" },
      { ru: "питьевая", port: "potável" },
      { ru: "пресная (о воде)", port: "doce" },
      { ru: "лёд", port: "gelo (m)" },
      { ru: "замёрзнуть (о реке)", port: "congelar-se (vp)" },
      { ru: "Сена", port: "rio Sena (m)" },
      { ru: "Луара", port: "rio Loire (m)" },
      { ru: "Темза", port: "rio Tamisa (m)" },
      { ru: "Рейн", port: "rio Reno (m)" },
      { ru: "Дунай", port: "rio Danúbio (m)" },
      { ru: "Волга", port: "rio Volga (m)" },
      { ru: "Дон", port: "rio Don (m)" },
      { ru: "Лена", port: "rio Lena (m)" },
      { ru: "Хуанхэ", port: "rio Amarelo (m)" },
      { ru: "Янцзы", port: "rio Yangtzé (m)" },
      { ru: "Меконг", port: "rio Mekong (m)" },
      { ru: "Ганг", port: "rio Ganges (m)" },
      { ru: "Нил", port: "rio Nilo (m)" },
      { ru: "Конго", port: "rio Congo (m)" },
      { ru: "Окаванго", port: "rio Cubango (m)" },
      { ru: "Замбези", port: "rio Zambeze (m)" },
    ],
  },

  {
    name: "Лес",
    dict: [
      { ru: "лес", port: "floresta (f), bosque (m)" },
      { ru: "лесной", port: "florestal" },
      { ru: "чаща", port: "mata (f)  cerrada" },
      { ru: "роща", port: "arvoredo (m)" },
      { ru: "поляна", port: "clareira (f)" },
      { ru: "заросли", port: "matagal (f)" },
      { ru: "кустарник", port: "mato (m)" },
      { ru: "тропинка", port: "vereda (f)" },
      { ru: "овраг", port: "ravina (f)" },
      { ru: "дерево", port: "árvore (f)" },
      { ru: "лист", port: "folha (f)" },
      { ru: "листва", port: "folhagem (f)" },
      { ru: "листопад", port: "queda (f)  das folha" },
      { ru: "опадать (о листьях)", port: "cair (vi)" },
      { ru: "верхушка (дерева)", port: "topo (m)" },
      { ru: "ветка", port: "ramo (m)" },
      { ru: "сук", port: "galho (m)" },
      { ru: "почка", port: "botão, rebento (m)" },
      { ru: "иголка", port: "agulha (f)" },
      { ru: "иголка", port: "agulha (f)" },
      { ru: "шишка (сосновая)", port: "pinha (f)" },
      { ru: "дупло", port: "buraco (m)  de árvore" },
      { ru: "гнездо", port: "ninho (m)" },
      { ru: "нора", port: "toca (f)" },
      { ru: "ствол (дерева)", port: "tronco (m)" },
      { ru: "корень", port: "raiz (f)" },
      { ru: "кора", port: "casca (f)  de árvore" },
      { ru: "мох", port: "musgo (m)" },
      { ru: "корчевать", port: "arrancar pela raiz" },
      { ru: "рубить (дерево)", port: "cortar (vt)" },
      { ru: "вырубать лес", port: "desflorestar (vt)" },
      { ru: "пень", port: "toco, cepo (m)" },
      { ru: "костёр", port: "fogueira (f)" },
      { ru: "пожар", port: "incêndio (m)  florestal" },
      { ru: "тушить", port: "apagar (vt)" },
      { ru: "лесник", port: "guarda-florestal (m)" },
      { ru: "охрана (растений)", port: "proteção (f)" },
      { ru: "охранять (природу)", port: "proteger (vt)" },
      { ru: "браконьер", port: "caçador (m)  furtivo" },
      { ru: "капкан", port: "armadilha (f)" },
      { ru: "собирать (грибы, ягоды)", port: "colher (vt)" },
      { ru: "заблудиться", port: "perder-se (vp)" },
    ],
  },
  {
    name: "Природные ресурсы",
    dict: [
      { ru: "природные ресурсы", port: "recursos (m pl)  naturais" },
      { ru: "полезные ископаемые", port: "minerais (m pl)" },
      { ru: "залежи", port: "depósitos (m pl)" },
      { ru: "месторождение", port: "jazida (f)" },
      { ru: "добывать (руду)", port: "extrair (vt)" },
      { ru: "добыча", port: "extração (f)" },
      { ru: "руда", port: "minério (m)" },
      { ru: "рудник, шахта", port: "mina (f)" },
      { ru: "шахта (скважина)", port: "poço (m)  de mina" },
      { ru: "шахтёр", port: "mineiro (m)" },
      { ru: "газ", port: "gás (m)" },
      { ru: "газопровод", port: "gasoduto (m)" },
      { ru: "нефть", port: "petróleo (m)" },
      { ru: "нефтепровод", port: "oleoduto (m)" },
      { ru: "нефтяная скважина", port: "poço (m)  de petróleo" },
      { ru: "нефтяная вышка", port: "torre (f)  petrolífera" },
      { ru: "танкер", port: "petroleiro (m)" },
      { ru: "песок", port: "areia (f)" },
      { ru: "известняк", port: "calcário (m)" },
      { ru: "известняк", port: "calcário (m)" },
      { ru: "гравий", port: "cascalho (m)" },
      { ru: "торф", port: "turfa (f)" },
      { ru: "глина", port: "argila (f)" },
      { ru: "уголь", port: "carvão (m)" },
      { ru: "железо", port: "ferro (m)" },
      { ru: "золото", port: "ouro (m)" },
      { ru: "серебро", port: "prata (f)" },
      { ru: "никель", port: "níquel (m)" },
      { ru: "медь", port: "cobre (m)" },
      { ru: "цинк", port: "zinco (m)" },
      { ru: "марганец", port: "manganês (m)" },
      { ru: "ртуть", port: "mercúrio (m)" },
      { ru: "свинец", port: "chumbo (m)" },
      { ru: "минерал", port: "mineral (m)" },
      { ru: "кристалл", port: "cristal (m)" },
      { ru: "мрамор", port: "mármore (m)" },
      { ru: "уран", port: "urânio (m)" },
    ],
  },
  {
    name: "Погода",
    dict: [
      { ru: "погода", port: "tempo (m)" },
      { ru: "прогноз погоды", port: "previsão (f)  do tempo" },
      { ru: "температура", port: "temperatura (f)" },
      { ru: "термометр", port: "termómetro (m)" },
      { ru: "барометр", port: "barómetro (m)" },
      { ru: "влажный", port: "húmido" },
      { ru: "влажность", port: "humidade (f)" },
      { ru: "жара", port: "calor (m)" },
      { ru: "жаркий", port: "cálido" },
      { ru: "жарко (о погоде)", port: "está muito calor" },
      { ru: "тепло (о погоде)", port: "está calor" },
      { ru: "тёплый (день)", port: "quente" },
      { ru: "холодно (о погоде)", port: "está frio" },
      { ru: "холодный", port: "frio" },
      { ru: "солнце", port: "sol (m)" },
      { ru: "светить (о солнце)", port: "brilhar (vi)" },
      { ru: "солнечный (день)", port: "de sol, ensolarado" },
      { ru: "взойти", port: "nascer (vi)" },
      { ru: "сесть", port: "pôr-se (vp)" },
      { ru: "сесть", port: "pôr-se (vp)" },
      { ru: "облако", port: "nuvem (f)" },
      { ru: "облачный", port: "nublado" },
      { ru: "туча", port: "nuvem (f)  negra" },
      { ru: "пасмурный", port: "escuro, cinzento" },
      { ru: "дождь", port: "chuva (f)" },
      { ru: "идёт дождь", port: "está a chover" },
      { ru: "дождливый", port: "chuvoso" },
      { ru: "моросить", port: "chuviscar (vi)" },
      { ru: "проливной дождь", port: "chuva (f)  torrencial" },
      { ru: "ливень", port: "chuvada (f)" },
      { ru: "сильный (дождь)", port: "forte" },
      { ru: "лужа (на улице)", port: "poça (f)" },
      { ru: "промокнуть", port: "molhar-se (vp)" },
      { ru: "туман", port: "nevoeiro (m)" },
      { ru: "туманный", port: "de nevoeiro" },
      { ru: "снег", port: "neve (f)" },
      { ru: "идёт снег", port: "está a nevar" },
    ],
  },

  {
    name: "Стихия",
    dict: [
      { ru: "гроза", port: "trovoada (f)" },
      { ru: "молния", port: "relâmpago (m)" },
      { ru: "сверкать", port: "relampejar (vi)" },
      { ru: "гром", port: "trovão (m)" },
      { ru: "греметь (о громе)", port: "trovejar (vi)" },
      { ru: "гремит гром", port: "está a trovejar" },
      { ru: "град", port: "granizo (m)" },
      { ru: "идёт град", port: "está a cair granizo" },
      { ru: "затопить (залить водой)", port: "inundar (vt)" },
      { ru: "наводнение", port: "inundação (f)" },
      { ru: "землетрясение", port: "terremoto (m)" },
      { ru: "толчок (подземный)", port: "abalo, tremor (m)" },
      { ru: "эпицентр", port: "epicentro (m)" },
      { ru: "извержение", port: "erupção (f)" },
      { ru: "лава", port: "lava (f)" },
      { ru: "смерч", port: "turbilhão (m)" },
      { ru: "торнадо", port: "tornado (m)" },
      { ru: "тайфун", port: "tufão (m)" },
      { ru: "ураган", port: "furacão (m)" },
      { ru: "ураган", port: "furacão (m)" },
      { ru: "буря", port: "tempestade (f)" },
      { ru: "цунами", port: "tsunami (m)" },
      { ru: "циклон", port: "ciclone (m)" },
      { ru: "непогода", port: "mau tempo (m)" },
      { ru: "пожар", port: "incêndio (m)" },
      { ru: "катастрофа", port: "catástrofe (f)" },
      { ru: "метеорит", port: "meteorito (m)" },
      { ru: "лавина", port: "avalanche (f)" },
      { ru: "обвал (снежный)", port: "deslizamento (f)  de neve" },
      { ru: "метель", port: "nevasca (f)" },
      { ru: "вьюга", port: "tempestade (f)  de neve" },
    ],
  },
  {
    name: "Шумы, звуки",
    dict: [
      { ru: "тишина", port: "silêncio (m)" },
      { ru: "звук", port: "som (m)" },
      { ru: "шум", port: "ruído, barulho (m)" },
      { ru: "шуметь", port: "fazer barulho" },
      { ru: "шумный", port: "ruidoso, barulhento" },
      { ru: "громко", port: "alto" },
      { ru: "громкий", port: "alto" },
      { ru: "постоянный (шум)", port: "constante" },
      { ru: "крик", port: "grito (m)" },
      { ru: "кричать", port: "gritar (vi)" },
      { ru: "шёпот", port: "sussurro (m)" },
      { ru: "шептать", port: "sussurrar (vt)" },
      { ru: "лай", port: "latido (m)" },
      { ru: "лаять", port: "ladrar, latir (vi)" },
      { ru: "стон", port: "gemido (m)" },
      { ru: "стонать", port: "gemer (vi)" },
      { ru: "кашель", port: "tosse (f)" },
      { ru: "кашлять", port: "tossir (vi)" },
      { ru: "свист", port: "assobio (m)" },
      { ru: "свист", port: "assobio (m)" },
      { ru: "свистеть", port: "assobiar (vi)" },
      { ru: "стук (в дверь)", port: "batida (f)" },
      { ru: "стучать (в дверь)", port: "bater (vi)" },
      { ru: "трещать (доски, лёд)", port: "estalar (vi)" },
      { ru: "треск", port: "estalido (m)" },
      { ru: "сирена", port: "sirene (f)" },
      { ru: "гудок (фабричный)", port: "apito (m)" },
      { ru: "гудеть (гудок, поезд)", port: "apitar (vi)" },
      { ru: "сигнал (авто)", port: "buzina (f)" },
      { ru: "сигналить (гудеть)", port: "buzinar (vi)" },
    ],
  },

  {
    name: "Зима",
    dict: [
      { ru: "зима", port: "inverno (m)" },
      { ru: "зимний", port: "de inverno" },
      { ru: "зимой", port: "no inverno" },
      { ru: "снег", port: "neve (f)" },
      { ru: "идёт снег", port: "está a nevar" },
      { ru: "снегопад", port: "queda (f)  de neve" },
      { ru: "сугроб", port: "amontoado (m)  de neve" },
      { ru: "снежинка", port: "floco (m)  de neve" },
      { ru: "снежок", port: "bola (f)  de neve" },
      { ru: "снеговик", port: "boneco (m)  de neve" },
      { ru: "сосулька", port: "sincelo (m)" },
      { ru: "декабрь", port: "dezembro (m)" },
      { ru: "январь", port: "janeiro (m)" },
      { ru: "февраль", port: "fevereiro (m)" },
      { ru: "мороз", port: "gelo (m)" },
      { ru: "морозный", port: "gelado, glacial" },
      { ru: "ниже нуля", port: "abaixo de zero" },
      { ru: "заморозки", port: "geada (f)" },
      { ru: "иней", port: "geada (f)  branca" },
      { ru: "иней", port: "geada (f)  branca" },
      { ru: "холод", port: "frio (m)" },
      { ru: "холодно", port: "está frio" },
      { ru: "шуба", port: "casaco (m)  de peles" },
      { ru: "варежки", port: "mitenes (f pl)" },
      { ru: "заболеть", port: "adoecer (vi)" },
      { ru: "простуда", port: "constipação (m)" },
      { ru: "простудиться", port: "constipar-se (vp)" },
      { ru: "лёд", port: "gelo (m)" },
      { ru: "гололёд", port: "gelo (m)  na estrada" },
      { ru: "замёрзнуть (о реке)", port: "congelar-se (vp)" },
      { ru: "льдина", port: "bloco (m)  de gelo" },
      { ru: "лыжи", port: "esqui (m)" },
      { ru: "лыжник", port: "esquiador (m)" },
      { ru: "кататься на лыжах", port: "esquiar (vi)" },
      { ru: "кататься на коньках", port: "patinar (vi)" },
    ],
  },

  {
    name: "Млекопитающие",
    dict: [
      { ru: "хищник", port: "predador (m)" },
      { ru: "тигр", port: "tigre (m)" },
      { ru: "лев", port: "leão (m)" },
      { ru: "волк", port: "lobo (m)" },
      { ru: "лиса", port: "raposa (f)" },
      { ru: "ягуар", port: "jaguar (m)" },
      { ru: "леопард", port: "leopardo (m)" },
      { ru: "гепард", port: "chita (f)" },
      { ru: "пантера", port: "pantera (f)" },
      { ru: "пума", port: "puma (m)" },
      { ru: "снежный барс", port: "leopardo-das-neves (m)" },
      { ru: "рысь", port: "lince (m)" },
      { ru: "койот", port: "coiote (m)" },
      { ru: "шакал", port: "chacal (m)" },
      { ru: "гиена", port: "hiena (f)" },
    ],
  },
  {
    name: "Дикие животные",
    dict: [
      { ru: "животное", port: "animal (m)" },
      { ru: "зверь", port: "besta (f)" },
      { ru: "белка", port: "esquilo (m)" },
      { ru: "ёж", port: "ouriço (m)" },
      { ru: "заяц", port: "lebre (f)" },
      { ru: "кролик", port: "coelho (m)" },
      { ru: "барсук", port: "texugo (m)" },
      { ru: "енот", port: "guaxinim (m)" },
      { ru: "хомяк", port: "hamster (m)" },
      { ru: "сурок", port: "marmota (f)" },
      { ru: "крот", port: "toupeira (f)" },
      { ru: "мышь", port: "rato (m)" },
      { ru: "крыса", port: "ratazana (f)" },
      { ru: "летучая мышь", port: "morcego (m)" },
      { ru: "горностай", port: "arminho (m)" },
      { ru: "соболь", port: "zibelina (f)" },
      { ru: "куница", port: "marta (f)" },
      { ru: "ласка", port: "doninha (f)" },
      { ru: "норка", port: "vison (m)" },
      { ru: "норка", port: "vison (m)" },
      { ru: "бобр", port: "castor (m)" },
      { ru: "выдра", port: "lontra (f)" },
      { ru: "лошадь", port: "cavalo (m)" },
      { ru: "лось", port: "alce (m)  americano" },
      { ru: "олень", port: "veado (m)" },
      { ru: "верблюд", port: "camelo (m)" },
      { ru: "бизон", port: "bisão (m)" },
      { ru: "зубр", port: "auroque (m)" },
      { ru: "буйвол", port: "búfalo (m)" },
      { ru: "зебра", port: "zebra (f)" },
      { ru: "антилопа", port: "antílope (m)" },
      { ru: "косуля", port: "corça (f)" },
      { ru: "лань", port: "gamo (m)" },
      { ru: "серна", port: "camurça (f)" },
      { ru: "кабан", port: "javali (m)" },
      { ru: "кит", port: "baleia (f)" },
      { ru: "тюлень", port: "foca (f)" },
      { ru: "морж", port: "morsa (f)" },
      { ru: "котик", port: "urso-marinho (m)" },
      { ru: "дельфин", port: "golfinho (m)" },
      { ru: "медведь", port: "urso (m)" },
      { ru: "белый медведь", port: "urso (m)  branco" },
      { ru: "панда", port: "panda (m)" },
      { ru: "обезьяна", port: "macaco (m)" },
      { ru: "шимпанзе", port: "chimpanzé (m)" },
      { ru: "орангутанг", port: "orangotango (m)" },
      { ru: "горилла", port: "gorila (m)" },
      { ru: "макака", port: "macaco (m)" },
      { ru: "гиббон", port: "gibão (m)" },
      { ru: "слон", port: "elefante (m)" },
      { ru: "носорог", port: "rinoceronte (m)" },
      { ru: "жираф", port: "girafa (f)" },
      { ru: "бегемот", port: "hipopótamo (m)" },
      { ru: "кенгуру", port: "canguru (m)" },
      { ru: "коала", port: "coala (m)" },
      { ru: "мангуст", port: "mangusto (m)" },
      { ru: "шиншилла", port: "chinchila (f)" },
      { ru: "скунс", port: "doninha-fedorenta (f)" },
      { ru: "дикобраз", port: "porco-espinho (m)" },
    ],
  },
  {
    name: "Домашние животные",
    dict: [
      { ru: "кошка", port: "gata (f)" },
      { ru: "кот", port: "gato (m)  macho" },
      { ru: "собака, пёс", port: "cão (m)" },
      { ru: "лошадь", port: "cavalo (m)" },
      { ru: "жеребец", port: "garanhão (m)" },
      { ru: "кобыла", port: "égua (f)" },
      { ru: "корова", port: "vaca (f)" },
      { ru: "бык", port: "touro (m)" },
      { ru: "вол", port: "boi (m)" },
      { ru: "овца", port: "ovelha (f)" },
      { ru: "баран", port: "carneiro (m)" },
      { ru: "коза", port: "cabra (f)" },
      { ru: "козёл", port: "bode (m)" },
      { ru: "осёл", port: "burro (m)" },
      { ru: "мул", port: "mula (f)" },
      { ru: "свинья", port: "porco (m)" },
      { ru: "поросёнок", port: "porquinho (m)" },
      { ru: "кролик", port: "coelho (m)" },
      { ru: "курица", port: "galinha (f)" },
      { ru: "курица", port: "galinha (f)" },
      { ru: "петух", port: "galo (m)" },
      { ru: "утка", port: "pato (m), pata (f)" },
      { ru: "селезень", port: "pato (m)" },
      { ru: "гусь", port: "ganso (m)" },
      { ru: "индюк", port: "peru (m)" },
      { ru: "индюшка", port: "perua (f)" },
      { ru: "домашние животные", port: "animais (m pl)  domésticos" },
      { ru: "ручной (о животных)", port: "domesticado" },
      { ru: "приручать", port: "domesticar (vt)" },
      { ru: "выращивать", port: "criar (vt)" },
      { ru: "ферма", port: "quinta (f)" },
      { ru: "домашняя птица", port: "aves (f pl)  domésticas" },
      { ru: "скот", port: "gado (m)" },
      { ru: "стадо", port: "rebanho (m), manada (f)" },
      { ru: "конюшня", port: "estábulo (m)" },
      { ru: "свинарник", port: "pocilga (f)" },
      { ru: "коровник", port: "vacaria (m)" },
      { ru: "крольчатник", port: "coelheira (f)" },
      { ru: "курятник", port: "galinheiro (m)" },
    ],
  },
  {
    name: "Собаки, породы",
    dict: [
      { ru: "собака", port: "cão (m)" },
      { ru: "овчарка", port: "cão pastor (m)" },
      { ru: "немецкая овчарка", port: "pastor-alemão (m)" },
      { ru: "пудель", port: "caniche (m)" },
      { ru: "такса", port: "teckel (m)" },
      { ru: "бульдог", port: "buldogue (m)" },
      { ru: "боксёр", port: "boxer (m)" },
      { ru: "мастиф", port: "mastim (m)" },
      { ru: "ротвейлер", port: "rottweiler (m)" },
      { ru: "доберман", port: "dobermann (m)" },
      { ru: "бассет", port: "basset (m)" },
      { ru: "бобтейл", port: "pastor inglês (m)" },
      { ru: "далматинец", port: "dálmata (m)" },
      { ru: "кокер-спаниель", port: "cocker spaniel (m)" },
      { ru: "ньюфаундленд", port: "terra-nova (m)" },
      { ru: "сенбернар", port: "são-bernardo (m)" },
      { ru: "хаски", port: "husky (m)" },
      { ru: "чау-чау", port: "Chow-chow (m)" },
      { ru: "шпиц", port: "spitz alemão (m)" },
      { ru: "шпиц", port: "spitz alemão (m)" },
      { ru: "мопс", port: "carlindogue (m)" },
    ],
  },

  {
    name: "Звуки животных",
    dict: [
      { ru: "лай", port: "latido (m)" },
      { ru: "лаять", port: "ladrar, latir (vi)" },
      { ru: "мяукать", port: "miar (vi)" },
      { ru: "мурлыкать", port: "ronronar (vi)" },
      { ru: "мычать", port: "mugir (vi)" },
      { ru: "реветь", port: "bramir (vi)" },
      { ru: "рычать", port: "rosnar (vi)" },
      { ru: "вой", port: "uivo (m)" },
      { ru: "выть", port: "uivar (vi)" },
      { ru: "скулить", port: "ganir (vi)" },
      { ru: "блеять", port: "balir (vi)" },
      { ru: "хрюкать", port: "grunhir (vi)" },
      { ru: "визжать", port: "guinchar (vi)" },
      { ru: "квакать", port: "coaxar (vi)" },
      { ru: "жужжать", port: "zumbir (vi)" },
      { ru: "стрекотать", port: "estridular, ziziar (vi)" },
    ],
  },

  {
    name: "Детеныши животных",
    dict: [
      { ru: "детёныш", port: "cria (f), filhote (m)" },
      { ru: "котёнок", port: "gatinho (m)" },
      { ru: "мышонок", port: "ratinho (m)" },
      { ru: "щенок", port: "cãozinho (m)" },
      { ru: "зайчонок", port: "filhote (m)  de lebra" },
      { ru: "крольчонок", port: "coelhinho (m)" },
      { ru: "волчонок", port: "filhote (m)  de lobo" },
      { ru: "лисёнок", port: "raposinho (m)" },
      { ru: "медвежонок", port: "ursinho (m)" },
      { ru: "львёнок", port: "leãozinho (m)" },
      { ru: "тигрёнок", port: "filhote (m)  de tigre" },
      { ru: "слонёнок", port: "filhote (m)  de elefante" },
      { ru: "поросёнок", port: "porquinho (m)" },
      { ru: "телёнок", port: "bezerro (m)" },
      { ru: "козлёнок", port: "cabrito (m)" },
      { ru: "ягнёнок", port: "cordeiro (m)" },
      { ru: "оленёнок", port: "cria (f)  de veado" },
      { ru: "верблюжонок", port: "cria (f)  de camelo" },
      { ru: "змеёныш", port: "filhote (m)  de serpente" },
      { ru: "змеёныш", port: "filhote (m)  de serpente" },
      { ru: "лягушонок", port: "cria (f)  de rã" },
      { ru: "птенец", port: "cria (f)  de ave" },
      { ru: "цыплёнок", port: "pinto (m)" },
      { ru: "утёнок", port: "patinho (m)" },
    ],
  },

  {
    name: "Птицы",
    dict: [
      { ru: "сокол", port: "falcão (m)" },
      { ru: "гриф", port: "abutre (m)" },
      { ru: "кондор", port: "condor (m)" },
      { ru: "лебедь", port: "cisne (m)" },
      { ru: "журавль", port: "grou (m)" },
      { ru: "аист", port: "cegonha (f)" },
      { ru: "попугай", port: "papagaio (m)" },
      { ru: "колибри", port: "beija-flor (m)" },
      { ru: "павлин", port: "pavão (m)" },
      { ru: "страус", port: "avestruz (f)" },
      { ru: "цапля", port: "garça (f)" },
      { ru: "фламинго", port: "flamingo (m)" },
      { ru: "пеликан", port: "pelicano (m)" },
      { ru: "соловей", port: "rouxinol (m)" },
      { ru: "ласточка", port: "andorinha (f)" },
      { ru: "дрозд", port: "tordo-zornal (m)" },
      { ru: "певчий дрозд", port: "tordo-músico (m)" },
      { ru: "чёрный дрозд", port: "melro-preto (m)" },
      { ru: "стриж", port: "andorinhão (m)" },
      { ru: "стриж", port: "andorinhão (m)" },
      { ru: "жаворонок", port: "cotovia (f)" },
      { ru: "перепел", port: "codorna (f)" },
      { ru: "дятел", port: "pica-pau (m)" },
      { ru: "кукушка", port: "cuco (m)" },
      { ru: "сова", port: "coruja (f)" },
      { ru: "филин", port: "corujão, bufo (m)" },
      { ru: "глухарь", port: "tetraz-grande (m)" },
      { ru: "тетерев", port: "tetraz-lira (m)" },
      { ru: "куропатка", port: "perdiz-cinzenta (f)" },
      { ru: "скворец", port: "estorninho (m)" },
      { ru: "канарейка", port: "canário (m)" },
      { ru: "рябчик", port: "galinha-do-mato (f)" },
      { ru: "зяблик", port: "tentilhão (m)" },
      { ru: "снегирь", port: "dom-fafe (m)" },
      { ru: "чайка", port: "gaivota (f)" },
      { ru: "альбатрос", port: "albatroz (m)" },
      { ru: "пингвин", port: "pinguim (m)" },
    ],
  },

  {
    name: "Рыбы, морские животные",
    dict: [
      { ru: "лещ", port: "brema (f)" },
      { ru: "карп", port: "carpa (f)" },
      { ru: "окунь", port: "perca (f)" },
      { ru: "сом", port: "siluro (m)" },
      { ru: "щука", port: "lúcio (m)" },
      { ru: "лосось", port: "salmão (m)" },
      { ru: "осётр", port: "esturjão (m)" },
      { ru: "сельдь", port: "arenque (m)" },
      { ru: "сёмга", port: "salmão (m)" },
      { ru: "скумбрия", port: "cavala (m), sarda (f)" },
      { ru: "камбала", port: "solha (f)" },
      { ru: "судак", port: "zander (m)" },
      { ru: "треска", port: "bacalhau (m)" },
      { ru: "тунец", port: "atum (m)" },
      { ru: "форель", port: "truta (f)" },
      { ru: "угорь", port: "enguia (f)" },
      { ru: "электрический скат", port: "raia elétrica (f)" },
      { ru: "мурена", port: "moreia (f)" },
      { ru: "пиранья", port: "piranha (f)" },
      { ru: "пиранья", port: "piranha (f)" },
      { ru: "акула", port: "tubarão (m)" },
      { ru: "дельфин", port: "golfinho (m)" },
      { ru: "кит", port: "baleia (f)" },
      { ru: "краб", port: "caranguejo (m)" },
      { ru: "медуза", port: "medusa, alforreca (f)" },
      { ru: "осьминог", port: "polvo (m)" },
      { ru: "морская звезда", port: "estrela-do-mar (f)" },
      { ru: "морской ёж", port: "ouriço-do-mar (m)" },
      { ru: "морской конёк", port: "cavalo-marinho (m)" },
      { ru: "устрица", port: "ostra (f)" },
      { ru: "креветка", port: "camarão (m)" },
      { ru: "омар", port: "lavagante (m)" },
      { ru: "лангуст", port: "lagosta (f)" },
    ],
  },
  {
    name: "Земноводные, пресмыкающиеся",
    dict: [
      { ru: "змея", port: "serpente, cobra (f)" },
      { ru: "ядовитый", port: "venenoso" },
      { ru: "гадюка", port: "víbora (f)" },
      { ru: "кобра", port: "cobra-capelo, naja (f)" },
      { ru: "питон", port: "piton (m)" },
      { ru: "удав", port: "jiboia (f)" },
      { ru: "уж", port: "cobra-de-água (f)" },
      { ru: "гремучая змея", port: "cascavel (f)" },
      { ru: "анаконда", port: "anaconda (f)" },
      { ru: "ящерица", port: "lagarto (m)" },
      { ru: "игуана", port: "iguana (f)" },
      { ru: "варан", port: "varano (m)" },
      { ru: "саламандра", port: "salamandra (f)" },
      { ru: "хамелеон", port: "camaleão (m)" },
      { ru: "скорпион", port: "escorpião (m)" },
      { ru: "черепаха", port: "tartaruga (f)" },
      { ru: "лягушка", port: "rã (f)" },
      { ru: "жаба", port: "sapo (m)" },
      { ru: "крокодил", port: "crocodilo (m)" },
      { ru: "крокодил", port: "crocodilo (m)" },
    ],
  },
  {
    name: "Насекомые, беспозвоночные",
    dict: [
      { ru: "насекомое", port: "inseto (m)" },
      { ru: "бабочка", port: "borboleta (f)" },
      { ru: "муравей", port: "formiga (f)" },
      { ru: "муха", port: "mosca (f)" },
      { ru: "комар", port: "mosquito (m)" },
      { ru: "жук", port: "escaravelho (m)" },
      { ru: "оса", port: "vespa (f)" },
      { ru: "пчела", port: "abelha (f)" },
      { ru: "шмель", port: "zangão (m)" },
      { ru: "овод", port: "moscardo (m)" },
      { ru: "паук", port: "aranha (f)" },
      { ru: "паутина", port: "teia (f)  de aranha" },
      { ru: "стрекоза", port: "libélula (f)" },
      { ru: "кузнечик", port: "gafanhoto-do-campo (m)" },
      { ru: "мотылёк", port: "traça (f)" },
      { ru: "таракан", port: "barata (f)" },
      { ru: "клещ", port: "carraça (f)" },
      { ru: "блоха", port: "pulga (f)" },
      { ru: "мошка", port: "borrachudo (m)" },
      { ru: "мошка", port: "borrachudo (m)" },
      { ru: "саранча", port: "gafanhoto (m)" },
      { ru: "улитка", port: "caracol (m)" },
      { ru: "сверчок", port: "grilo (m)" },
      { ru: "светлячок", port: "pirilampo (m)" },
      { ru: "божья коровка", port: "joaninha (f)" },
      { ru: "майский жук", port: "besouro (m)" },
      { ru: "пиявка", port: "sanguessuga (f)" },
      { ru: "гусеница", port: "lagarta (f)" },
      { ru: "червь", port: "minhoca (f)" },
      { ru: "червь (личинка)", port: "larva (f)" },
    ],
  },
  {
    name: "Части тела животных",
    dict: [
      { ru: "клюв", port: "bico (m)" },
      { ru: "крылья", port: "asas (f pl)" },
      { ru: "лапа (птицы)", port: "pata (f)" },
      { ru: "оперение", port: "plumagem (f)" },
      { ru: "перо (птицы)", port: "pena, pluma (f)" },
      { ru: "хохолок", port: "crista (f)" },
      { ru: "жабры", port: "brânquias, guelras (f pl)" },
      { ru: "икра", port: "ovas (f pl)" },
      { ru: "личинка", port: "larva (f)" },
      { ru: "плавник", port: "barbatana (f)" },
      { ru: "чешуя", port: "escama (f)" },
      { ru: "клык", port: "canino (m)" },
      { ru: "лапа (напр. собаки)", port: "pata (f)" },
      { ru: "морда", port: "focinho (m)" },
      { ru: "пасть", port: "boca (f)" },
      { ru: "хвост", port: "cauda (f), rabo (m)" },
      { ru: "усы (у животных)", port: "bigodes (m pl)" },
      { ru: "копыто", port: "casco (m)" },
      { ru: "рог", port: "corno (m)" },
      { ru: "рог", port: "corno (m)" },
      { ru: "панцирь (черепахи)", port: "carapaça (f)" },
      { ru: "ракушка", port: "concha (f)" },
      { ru: "скорлупа", port: "casca (f)  de ovo" },
      { ru: "шерсть (животного)", port: "pelo (m)" },
      { ru: "шкура (убитого животного)", port: "pele (f), couro (m)" },
    ],
  },
  {
    name: "Действия животных",
    dict: [
      { ru: "летать", port: "voar (vi)" },
      { ru: "кружить (о птице)", port: "dar voltas" },
      { ru: "улететь (о птице)", port: "voar (vi)" },
      { ru: "махать (крыльями)", port: "bater as asas" },
      { ru: "клевать", port: "bicar (vi)" },
      { ru: "высиживать яйца", port: "incubar (vt)" },
      { ru: "вылупляться", port: "sair do ovo" },
      { ru: "вить гнездо", port: "fazer o ninho" },
      { ru: "ползать", port: "rastejar (vi)" },
      { ru: "жалить (о насекомом)", port: "picar (vt)" },
      { ru: "кусать (о собаке и т.п.)", port: "morder (vt)" },
      { ru: "нюхать (о животных)", port: "cheirar (vt)" },
      { ru: "лаять", port: "ladrar (vi)" },
      { ru: "шипеть", port: "silvar (vi)" },
      { ru: "пугать", port: "assustar (vt)" },
      { ru: "нападать", port: "atacar (vt)" },
      { ru: "грызть", port: "roer (vt)" },
      { ru: "царапать", port: "arranhar (vt)" },
      { ru: "прятаться", port: "esconder-se (vp)" },
      { ru: "прятаться", port: "esconder-se (vp)" },
      { ru: "играть", port: "brincar (vi)" },
      { ru: "охотиться", port: "caçar (vi)" },
      { ru: "быть в спячке", port: "hibernar (vi)" },
      { ru: "вымереть (динозавры)", port: "extinguir-se (vp)" },
    ],
  },
  {
    name: "Среда обитания",
    dict: [
      { ru: "среда обитания", port: "habitat (m)" },
      { ru: "миграция", port: "migração (f)" },
      { ru: "гора", port: "montanha (f)" },
      { ru: "риф", port: "recife (m)" },
      { ru: "скала", port: "falésia (f)" },
      { ru: "лес", port: "floresta (f)" },
      { ru: "джунгли", port: "selva (f)" },
      { ru: "саванна", port: "savana (f)" },
      { ru: "тундра", port: "tundra (f)" },
      { ru: "степь", port: "estepe (f)" },
      { ru: "пустыня", port: "deserto (m)" },
      { ru: "оазис", port: "oásis (m)" },
      { ru: "море", port: "mar (m)" },
      { ru: "озеро", port: "lago (m)" },
      { ru: "океан", port: "oceano (m)" },
      { ru: "болото", port: "pântano (m)" },
      { ru: "пресноводный", port: "de água doce" },
      { ru: "пруд", port: "lagoa (f)" },
      { ru: "река", port: "rio (m)" },
      { ru: "река", port: "rio (m)" },
      { ru: "берлога", port: "toca (f)  do urso" },
      { ru: "гнездо", port: "ninho (m)" },
      { ru: "дупло", port: "buraco (m)  de árvore" },
      { ru: "нора", port: "toca (f)" },
      { ru: "муравейник", port: "formigueiro (m)" },
    ],
  },

  {
    name: "Разное",
    dict: [
      { ru: "зоопарк", port: "jardim (m)  zoológico" },
      { ru: "заповедник", port: "reserva (f)  natural" },
      { ru: "питомник", port: "viveiro (m)" },
      { ru: "вольер", port: "jaula (f)  de ar livre" },
      { ru: "клетка", port: "jaula, gaiola (f)" },
      { ru: "конура", port: "casinha (f)  de cão" },
      { ru: "голубятня", port: "pombal (m)" },
      { ru: "аквариум", port: "aquário (m)" },
      { ru: "дельфинарий", port: "delfinário (m)" },
      { ru: "разводить (животных)", port: "criar (vt)" },
      { ru: "потомство", port: "ninhada (f)" },
      { ru: "приручать", port: "domesticar (vt)" },
      { ru: "корм", port: "ração (f)" },
      { ru: "кормить", port: "alimentar (vt)" },
      { ru: "дрессировать", port: "adestrar (vt)" },
      { ru: "зоомагазин", port: "loja (f)  de animais" },
      { ru: "намордник", port: "açaime (m)" },
      { ru: "ошейник", port: "coleira (f)" },
      { ru: "кличка (животн.)", port: "nome (f)" },
      { ru: "кличка (животн.)", port: "nome (f)" },
      { ru: "родословная (собаки)", port: "pedigree (m)" },
      { ru: "стая (волков)", port: "alcateia (f)" },
      { ru: "стая (птиц)", port: "bando (m)" },
      { ru: "стая (рыб)", port: "cardume (m)" },
      { ru: "табун", port: "manada (f)" },
      { ru: "самец", port: "macho (m)" },
      { ru: "самка", port: "fêmea (f)" },
      { ru: "голодный", port: "faminto" },
      { ru: "дикий", port: "selvagem" },
      { ru: "опасный", port: "perigoso" },
    ],
  },

  {
    name: "Лошади",
    dict: [
      { ru: "лошадь, конь", port: "cavalo (m)" },
      { ru: "порода", port: "raça (f)" },
      { ru: "жеребёнок", port: "potro (m)" },
      { ru: "кобыла", port: "égua (f)" },
      { ru: "мустанг", port: "mustangue (m)" },
      { ru: "пони", port: "pónei (m)" },
      { ru: "тяжеловоз", port: "cavalo (m)  de tiro" },
      { ru: "грива", port: "crina (f)" },
      { ru: "хвост", port: "cauda (f)" },
      { ru: "копыто", port: "casco (m)" },
      { ru: "подкова", port: "ferradura (f)" },
      { ru: "подковать", port: "ferrar (vt)" },
      { ru: "кузнец", port: "ferreiro (m)" },
      { ru: "седло", port: "sela (f)" },
      { ru: "стремя", port: "estribo (m)" },
      { ru: "уздечка", port: "brida (f)" },
      { ru: "вожжи", port: "rédeas (f pl)" },
      { ru: "плётка", port: "chicote (m)" },
      { ru: "наездник", port: "cavaleiro (m)" },
      { ru: "наездник", port: "cavaleiro (m)" },
      { ru: "объезжать (лошадь)", port: "amansar (vt)" },
      { ru: "оседлать", port: "colocar sela" },
      { ru: "сесть в седло", port: "montar no cavalo" },
      { ru: "галоп", port: "galope (m)" },
      { ru: "скакать галопом", port: "galopar (vi)" },
      { ru: "рысь", port: "trote (m)" },
      { ru: "рысью", port: "a trote" },
      { ru: "скакать рысью", port: "ir a trote" },
      { ru: "скаковая лошадь", port: "cavalo (m)  de corrida" },
      { ru: "скачки", port: "corridas (f pl)" },
      { ru: "конюшня", port: "estábulo (m)" },
      { ru: "кормить", port: "alimentar (vt)" },
      { ru: "сено", port: "feno (m)" },
      { ru: "поить", port: "dar água" },
      { ru: "чистить", port: "limpar (vt)" },
      { ru: "стреножить", port: "pear, prender as patas" },
      { ru: "воз, повозка", port: "carroça (f)" },
      { ru: "пастись", port: "pastar (vi)" },
      { ru: "ржать", port: "relinchar (vi)" },
      { ru: "лягнуть", port: "dar um coice" },
    ],
  },
  {
    name: "Деревья",
    dict: [
      { ru: "дерево", port: "árvore (f)" },
      { ru: "лиственное", port: "decídua" },
      { ru: "хвойное", port: "conífera" },
      { ru: "вечнозеленое", port: "perene" },
      { ru: "яблоня", port: "macieira (f)" },
      { ru: "груша", port: "pereira (f)" },
      { ru: "черешня", port: "cerejeira (f)" },
      { ru: "вишня", port: "ginjeira (f)" },
      { ru: "слива", port: "ameixeira (f)" },
      { ru: "берёза", port: "bétula (f)" },
      { ru: "дуб", port: "carvalho (m)" },
      { ru: "липа", port: "tília (f)" },
      { ru: "осина", port: "choupo-tremedor (m)" },
      { ru: "клён", port: "bordo (m)" },
      { ru: "ель", port: "espruce-europeu (m)" },
      { ru: "сосна", port: "pinheiro (m)" },
      { ru: "лиственница", port: "alerce, lariço (m)" },
      { ru: "пихта", port: "abeto (m)" },
      { ru: "кедр", port: "cedro (m)" },
      { ru: "кедр", port: "cedro (m)" },
      { ru: "тополь", port: "choupo, álamo (m)" },
      { ru: "рябина", port: "tramazeira (f)" },
      { ru: "ива", port: "salgueiro (m)" },
      { ru: "ольха", port: "amieiro (m)" },
      { ru: "бук", port: "faia (f)" },
      { ru: "вяз", port: "ulmeiro (m)" },
      { ru: "ясень", port: "freixo (m)" },
      { ru: "каштан", port: "castanheiro (m)" },
      { ru: "магнолия", port: "magnólia (f)" },
      { ru: "пальма", port: "palmeira (f)" },
      { ru: "кипарис", port: "cipreste (m)" },
      { ru: "мангровое дерево", port: "mangue (m)" },
      { ru: "баобаб", port: "embondeiro, baobá (m)" },
      { ru: "эвкалипт", port: "eucalipto (m)" },
      { ru: "секвойя", port: "sequoia (f)" },
    ],
  },
  {
    name: "Кустарники",
    dict: [
      { ru: "куст", port: "arbusto (m)" },
      { ru: "кустарник", port: "arbusto (m), moita (f)" },
      { ru: "виноград", port: "videira (f)" },
      { ru: "виноградник", port: "vinhedo (m)" },
      { ru: "малина", port: "framboeseira (f)" },
      { ru: "чёрная смородина", port: "groselheira-preta (f)" },
      { ru: "красная смородина", port: "groselheira-vermelha (f)" },
      { ru: "крыжовник", port: "groselheira (f)  espinhosa" },
      { ru: "акация", port: "acácia (f)" },
      { ru: "барбарис", port: "bérberis (f)" },
      { ru: "жасмин", port: "jasmim (m)" },
      { ru: "можжевельник", port: "junípero (m)" },
      { ru: "розовый куст", port: "roseira (f)" },
      { ru: "шиповник", port: "roseira (f)  brava" },
    ],
  },
  {
    name: "Грибы",
    dict: [
      { ru: "гриб", port: "cogumelo (m)" },
      { ru: "съедобный гриб", port: "cogumelo (m)  comestível" },
      { ru: "ядовитый гриб", port: "cogumelo (m)  venenoso" },
      { ru: "шляпка (гриба)", port: "chapéu (m)" },
      { ru: "ножка (гриба)", port: "pé, caule (m)" },
      { ru: "белый гриб", port: "cepe-de-bordéus (m)" },
      { ru: "подосиновик", port: "boleto (m)  áspero" },
      { ru: "подберёзовик", port: "boleto (m)  castanho" },
      { ru: "лисичка", port: "cantarelo (m)" },
      { ru: "сыроежка", port: "rússula (f)" },
      { ru: "сморчок", port: "morchela (f)" },
      { ru: "мухомор", port: "agário-das-moscas (m)" },
      { ru: "поганка", port: "cicuta (f)  verde" },
    ],
  },
  {
    name: "Фрукты,ягоды",
    dict: [
      { ru: "фрукт, плод", port: "fruta (f)" },
      { ru: "фрукты, плоды", port: "frutas (f pl)" },
      { ru: "яблоко", port: "maçã (f)" },
      { ru: "груша", port: "pera (f)" },
      { ru: "слива", port: "ameixa (f)" },
      { ru: "клубника", port: "morango (m)" },
      { ru: "вишня", port: "ginja (f)" },
      { ru: "черешня", port: "cereja (f)" },
      { ru: "виноград", port: "uva (f)" },
      { ru: "малина", port: "framboesa (f)" },
      { ru: "чёрная смородина", port: "groselha (f)  preta" },
      { ru: "красная смородина", port: "groselha (f)  vermelha" },
      { ru: "крыжовник", port: "groselha (f)  espinhosa" },
      { ru: "клюква", port: "oxicoco (m)" },
      { ru: "апельсин", port: "laranja (f)" },
      { ru: "мандарин", port: "tangerina (f)" },
      { ru: "ананас", port: "ananás (m)" },
      { ru: "банан", port: "banana (f)" },
      { ru: "финик", port: "tâmara (f)" },
      { ru: "финик", port: "tâmara (f)" },
      { ru: "лимон", port: "limão (m)" },
      { ru: "абрикос", port: "damasco (m)" },
      { ru: "персик", port: "pêssego (m)" },
      { ru: "киви", port: "kiwi (m)" },
      { ru: "грейпфрут", port: "toranja (f)" },
      { ru: "ягода", port: "baga (f)" },
      { ru: "ягоды", port: "bagas (f pl)" },
      { ru: "брусника", port: "arando (m)  vermelho" },
      { ru: "земляника", port: "morango-silvestre (m)" },
      { ru: "черника", port: "mirtilo (m)" },
    ],
  },
  {
    name: "Цветы, растения",
    dict: [
      { ru: "цветок", port: "flor (f)" },
      { ru: "букет", port: "ramo (m)  de flores" },
      { ru: "роза", port: "rosa (f)" },
      { ru: "тюльпан", port: "tulipa (f)" },
      { ru: "гвоздика", port: "cravo (m)" },
      { ru: "гладиолус", port: "gladíolo (m)" },
      { ru: "василёк", port: "centáurea (f)" },
      { ru: "колокольчик", port: "campânula (f)" },
      { ru: "одуванчик", port: "dente-de-leão (m)" },
      { ru: "ромашка", port: "camomila (f)" },
      { ru: "алоэ", port: "aloé (m)" },
      { ru: "кактус", port: "cato (m)" },
      { ru: "фикус", port: "fícus (m)" },
      { ru: "лилия", port: "lírio (m)" },
      { ru: "герань", port: "gerânio (m)" },
      { ru: "гиацинт", port: "jacinto (m)" },
      { ru: "мимоза", port: "mimosa (f)" },
      { ru: "нарцисс", port: "narciso (m)" },
      { ru: "настурция", port: "capuchinha (f)" },
      { ru: "настурция", port: "capuchinha (f)" },
      { ru: "орхидея", port: "orquídea (f)" },
      { ru: "пион", port: "peónia (f)" },
      { ru: "фиалка", port: "violeta (f)" },
      { ru: "анютины глазки", port: "amor-perfeito (m)" },
      { ru: "незабудка", port: "não-me-esqueças (m)" },
      { ru: "маргаритка", port: "margarida (f)" },
      { ru: "мак", port: "papoula (f)" },
      { ru: "конопля", port: "cânhamo (m)" },
      { ru: "мята", port: "hortelã (f)" },
      { ru: "ландыш", port: "lírio-do-vale (m)" },
      { ru: "подснежник", port: "campânula-branca (f)" },
      { ru: "крапива", port: "urtiga (f)" },
      { ru: "щавель", port: "azeda (f)" },
      { ru: "кувшинка", port: "nenúfar (m)" },
      { ru: "папоротник", port: "feto (m), samambaia (f)" },
      { ru: "лишайник", port: "líquen (m)" },
      { ru: "оранжерея", port: "estufa (f)" },
      { ru: "газон", port: "relvado (m)" },
      { ru: "клумба", port: "canteiro (m)  de flores" },
      { ru: "растение", port: "planta (f)" },
      { ru: "трава", port: "erva (f)" },
      { ru: "травинка", port: "folha (f)  de erva" },
      { ru: "лист (растения)", port: "folha (f)" },
      { ru: "лепесток", port: "pétala (f)" },
      { ru: "стебель", port: "talo (m)" },
      { ru: "клубень", port: "tubérculo (m)" },
      { ru: "росток", port: "broto, rebento (m)" },
      { ru: "шип (растения)", port: "espinho (m)" },
      { ru: "цвести", port: "florescer (vi)" },
      { ru: "вянуть", port: "murchar (vi)" },
      { ru: "запах", port: "cheiro (m)" },
      { ru: "срезать", port: "cortar (vt)" },
      { ru: "сорвать (цветок)", port: "colher (vt)" },
    ],
  },
  {
    name: "Зерновые",
    dict: [
      { ru: "зерно (собират.)", port: "grão (m)" },
      { ru: "зерновые растения", port: "cereais (m pl)" },
      { ru: "колос", port: "espiga (f)" },
      { ru: "пшеница", port: "trigo (m)" },
      { ru: "рожь", port: "centeio (m)" },
      { ru: "овёс", port: "aveia (f)" },
      { ru: "просо", port: "milho-miúdo (m)" },
      { ru: "ячмень", port: "cevada (f)" },
      { ru: "кукуруза", port: "milho (m)" },
      { ru: "рис", port: "arroz (m)" },
      { ru: "гречиха", port: "trigo-sarraceno (m)" },
      { ru: "горох", port: "ervilha (f)" },
      { ru: "фасоль", port: "feijão (m)" },
      { ru: "соя", port: "soja (f)" },
      { ru: "чечевица", port: "lentilha (f)" },
      { ru: "бобы", port: "fava (f)" },
    ],
  },

  {
    name: "Овощи, зелень",
    dict: [
      { ru: "овощи", port: "legumes (m pl)" },
      { ru: "зелень", port: "verduras (f pl)" },
      { ru: "помидор", port: "tomate (m)" },
      { ru: "огурец", port: "pepino (m)" },
      { ru: "морковь", port: "cenoura (f)" },
      { ru: "картофель", port: "batata (f)" },
      { ru: "лук (луковица)", port: "cebola (f)" },
      { ru: "чеснок", port: "alho (m)" },
      { ru: "капуста", port: "couve (f)" },
      { ru: "цветная капуста", port: "couve-flor (f)" },
      { ru: "брюссельская капуста", port: "couve-de-bruxelas (f)" },
      { ru: "капуста брокколи", port: "brócolos (m pl)" },
      { ru: "свёкла", port: "beterraba (f)" },
      { ru: "баклажан", port: "beringela (f)" },
      { ru: "кабачок", port: "curgete (f)" },
      { ru: "тыква", port: "abóbora (f)" },
      { ru: "репа", port: "nabo (m)" },
      { ru: "петрушка", port: "salsa (f)" },
      { ru: "укроп", port: "funcho, endro (m)" },
      { ru: "укроп", port: "funcho, endro (m)" },
      { ru: "салат (латук)", port: "alface (f)" },
      { ru: "сельдерей", port: "aipo (m)" },
      { ru: "спаржа", port: "espargo (m)" },
      { ru: "шпинат", port: "espinafre (m)" },
      { ru: "горох", port: "ervilha (f)" },
      { ru: "бобы", port: "fava (f)" },
      { ru: "кукуруза", port: "milho (m)" },
      { ru: "фасоль", port: "feijão (m)" },
      { ru: "перец", port: "pimentão (m)" },
      { ru: "редис", port: "rabanete (m)" },
      { ru: "артишок", port: "alcachofra (f)" },
    ],
  },
  {
    name: "Западная Европа",
    dict: [
      { ru: "Европа", port: "Europa (f)" },
      { ru: "Европейский Союз", port: "União (f)  Europeia" },
      { ru: "европеец", port: "europeu (m)" },
      { ru: "европейский", port: "europeu" },
      { ru: "Австрия", port: "Áustria (f)" },
      { ru: "австриец", port: "austríaco (m)" },
      { ru: "австрийка", port: "austríaca (f)" },
      { ru: "австрийский", port: "austríaco" },
      { ru: "Великобритания", port: "Grã-Bretanha (f)" },
      { ru: "Англия", port: "Inglaterra (f)" },
      { ru: "англичанин", port: "inglês (m)" },
      { ru: "англичанка", port: "inglesa (f)" },
      { ru: "английский", port: "inglês" },
      { ru: "Бельгия", port: "Bélgica (f)" },
      { ru: "бельгиец", port: "belga (m)" },
      { ru: "бельгийка", port: "belga (f)" },
      { ru: "бельгийский", port: "belga" },
      { ru: "Германия", port: "Alemanha (f)" },
      { ru: "немец", port: "alemão (m)" },
      { ru: "немец", port: "alemão (m)" },
      { ru: "немка", port: "alemã (f)" },
      { ru: "немецкий", port: "alemão" },
      { ru: "Нидерланды", port: "Países (m pl)  Baixos" },
      { ru: "Голландия", port: "Holanda (f)" },
      { ru: "голландец", port: "holandês (m)" },
      { ru: "голландка", port: "holandesa (f)" },
      { ru: "голландский", port: "holandês" },
      { ru: "Греция", port: "Grécia (f)" },
      { ru: "грек", port: "grego (m)" },
      { ru: "гречанка", port: "grega (f)" },
      { ru: "греческий", port: "grego" },
      { ru: "Дания", port: "Dinamarca (f)" },
      { ru: "датчанин", port: "dinamarquês (m)" },
      { ru: "датчанка", port: "dinamarquesa (f)" },
      { ru: "датский", port: "dinamarquês" },
      { ru: "Ирландия", port: "Irlanda (f)" },
      { ru: "ирландец", port: "irlandês (m)" },
      { ru: "ирландка", port: "irlandesa (f)" },
      { ru: "ирландский", port: "irlandês" },
      { ru: "Исландия", port: "Islândia (f)" },
      { ru: "исландец", port: "islandês (m)" },
      { ru: "исландка", port: "islandesa (f)" },
      { ru: "исландский", port: "islandês" },
      { ru: "Испания", port: "Espanha (f)" },
      { ru: "испанец", port: "espanhol (m)" },
      { ru: "испанка", port: "espanhola (f)" },
      { ru: "испанский", port: "espanhol" },
      { ru: "Италия", port: "Itália (f)" },
      { ru: "итальянец", port: "italiano (m)" },
      { ru: "итальянка", port: "italiana (f)" },
      { ru: "итальянский", port: "italiano" },
      { ru: "Кипр", port: "Chipre (m)" },
      { ru: "киприот", port: "cipriota (m)" },
      { ru: "киприот", port: "cipriota (m)" },
      { ru: "киприотка", port: "cipriota (f)" },
      { ru: "кипрский", port: "cipriota" },
      { ru: "Мальта", port: "Malta (f)" },
      { ru: "мальтиец", port: "maltês (m)" },
      { ru: "мальтийка", port: "maltesa (f)" },
      { ru: "мальтийский", port: "maltês" },
      { ru: "Норвегия", port: "Noruega (f)" },
      { ru: "норвежец", port: "norueguês (m)" },
      { ru: "норвежка", port: "norueguesa (f)" },
      { ru: "норвежский", port: "norueguês" },
      { ru: "Португалия", port: "Portugal (m)" },
      { ru: "португалец", port: "português (m)" },
      { ru: "португалка", port: "portuguesa (f)" },
      { ru: "португальский", port: "português" },
      { ru: "Финляндия", port: "Finlândia (f)" },
      { ru: "финн", port: "finlandês (m)" },
      { ru: "финка", port: "finlandesa (f)" },
      { ru: "финский", port: "finlandês" },
      { ru: "Франция", port: "França (f)" },
      { ru: "француз", port: "francês (m)" },
      { ru: "француженка", port: "francesa (f)" },
      { ru: "французский", port: "francês" },
      { ru: "Швеция", port: "Suécia (f)" },
      { ru: "швед", port: "sueco (m)" },
      { ru: "шведка", port: "sueca (f)" },
      { ru: "шведский", port: "sueco" },
      { ru: "Швейцария", port: "Suíça (f)" },
      { ru: "швейцарец", port: "suíço (m)" },
      { ru: "швейцарка", port: "suíça (f)" },
      { ru: "швейцарский", port: "suíço" },
      { ru: "Шотландия", port: "Escócia (f)" },
      { ru: "шотландец", port: "escocês (m)" },
      { ru: "шотландка", port: "escocesa (f)" },
      { ru: "шотландский", port: "escocês" },
      { ru: "Ватикан", port: "Vaticano (m)" },
      { ru: "Лихтенштейн", port: "Liechtenstein (m)" },
      { ru: "Люксембург", port: "Luxemburgo (m)" },
    ],
  },
  {
    name: "Центральная и Восточная Европа",
    dict: [
      { ru: "Албания", port: "Albânia (f)" },
      { ru: "албанец", port: "albanês (m)" },
      { ru: "албанка", port: "albanesa (f)" },
      { ru: "албанский", port: "albanês" },
      { ru: "Болгария", port: "Bulgária (f)" },
      { ru: "болгарин", port: "búlgaro (m)" },
      { ru: "болгарка", port: "búlgara (f)" },
      { ru: "болгарский", port: "búlgaro" },
      { ru: "Венгрия", port: "Hungria (f)" },
      { ru: "венгр", port: "húngaro (m)" },
      { ru: "венгерка", port: "húngara (f)" },
      { ru: "венгерский", port: "húngaro" },
      { ru: "Латвия", port: "Letónia (f)" },
      { ru: "латыш", port: "letão (m)" },
      { ru: "латышка", port: "letã (f)" },
      { ru: "латышский", port: "letão" },
      { ru: "Литва", port: "Lituânia (f)" },
      { ru: "литовец", port: "lituano (m)" },
      { ru: "литовка", port: "lituana (f)" },
      { ru: "литовка", port: "lituana (f)" },
      { ru: "литовский", port: "lituano" },
      { ru: "Польша", port: "Polónia (f)" },
      { ru: "поляк", port: "polaco (m)" },
      { ru: "полька", port: "polaca (f)" },
      { ru: "польский", port: "polaco" },
      { ru: "Румыния", port: "Roménia (f)" },
      { ru: "румын", port: "romeno (m)" },
      { ru: "румынка", port: "romena (f)" },
      { ru: "румынский", port: "romeno" },
      { ru: "Сербия", port: "Sérvia (f)" },
      { ru: "серб", port: "sérvio (m)" },
      { ru: "сербка", port: "sérvia (f)" },
      { ru: "сербский", port: "sérvio" },
      { ru: "Словакия", port: "Eslováquia (f)" },
      { ru: "словак", port: "eslovaco (m)" },
      { ru: "словачка", port: "eslovaca (f)" },
      { ru: "словацкий", port: "eslovaco" },
      { ru: "Хорватия", port: "Croácia (f)" },
      { ru: "хорват", port: "croata (m)" },
      { ru: "хорватка", port: "croata (f)" },
      { ru: "хорватский", port: "croata" },
      { ru: "Чехия", port: "República (f)  Checa" },
      { ru: "чех", port: "checo (m)" },
      { ru: "чешка", port: "checa (f)" },
      { ru: "чешский", port: "checo" },
      { ru: "Эстония", port: "Estónia (f)" },
      { ru: "эстонец", port: "estónio (m)" },
      { ru: "эстонка", port: "estónia (f)" },
      { ru: "эстонский", port: "estónio (m)" },
      { ru: "Босния и Герцеговина", port: "Bósnia e Herzegovina (f)" },
      { ru: "Македония", port: "Macedónia (f)" },
      { ru: "Словения", port: "Eslovénia (f)" },
      { ru: "Черногория", port: "Montenegro (m)" },
    ],
  },
  {
    name: "Страны бывшего СССР",
    dict: [
      { ru: "Азербайджан", port: "Azerbaijão (m)" },
      { ru: "азербайджанец", port: "azeri (m)" },
      { ru: "азербайджанка", port: "azeri (f)" },
      { ru: "азербайджанский", port: "azeri, azerbaijano" },
      { ru: "Армения", port: "Arménia (f)" },
      { ru: "армянин", port: "arménio (m)" },
      { ru: "армянка", port: "arménia (f)" },
      { ru: "армянский", port: "arménio" },
      { ru: "Беларусь", port: "Bielorrússia (f)" },
      { ru: "белорус", port: "bielorrusso (m)" },
      { ru: "белоруска", port: "bielorrussa (f)" },
      { ru: "белорусский", port: "bielorrusso" },
      { ru: "Грузия", port: "Geórgia (f)" },
      { ru: "грузин", port: "georgiano (m)" },
      { ru: "грузинка", port: "georgiana (f)" },
      { ru: "грузинский", port: "georgiano" },
      { ru: "Казахстан", port: "Cazaquistão (m)" },
      { ru: "казах", port: "cazaque (m)" },
      { ru: "казашка", port: "cazaque (f)" },
      { ru: "казашка", port: "cazaque (f)" },
      { ru: "казахский", port: "cazaque" },
      { ru: "Кыргызстан", port: "Quirguizistão (m)" },
      { ru: "киргиз", port: "quirguiz (m)" },
      { ru: "киргизка", port: "quirguiza (f)" },
      { ru: "киргизский", port: "quirguiz" },
      { ru: "Молдова", port: "Moldávia (f)" },
      { ru: "молдаванин", port: "moldavo (m)" },
      { ru: "молдаванка", port: "moldava (f)" },
      { ru: "молдавский", port: "moldavo" },
      { ru: "Россия", port: "Rússia (f)" },
      { ru: "русский (сущ.)", port: "russo (m)" },
      { ru: "русская (сущ.)", port: "russa (f)" },
      { ru: "русский", port: "russo" },
      { ru: "Таджикистан", port: "Tajiquistão (m)" },
      { ru: "таджик", port: "tajique (m)" },
      { ru: "таджичка", port: "tajique (f)" },
      { ru: "таджикский", port: "tajique" },
      { ru: "Туркменистан", port: "Turquemenistão (m)" },
      { ru: "туркмен", port: "turcomeno (m)" },
      { ru: "туркменка", port: "turcomena (f)" },
      { ru: "туркменский", port: "turcomeno" },
      { ru: "Узбекистан", port: "Uzbequistão (f)" },
      { ru: "узбек", port: "uzbeque (m)" },
      { ru: "узбечка", port: "uzbeque (f)" },
      { ru: "узбекский", port: "uzbeque" },
      { ru: "Украина", port: "Ucrânia (f)" },
      { ru: "украинец", port: "ucraniano (m)" },
      { ru: "украинка", port: "ucraniana (f)" },
      { ru: "украинский", port: "ucraniano" },
    ],
  },
  {
    name: "Азия",
    dict: [
      { ru: "Азия", port: "Ásia (f)" },
      { ru: "азиатский", port: "asiático" },
      { ru: "Вьетнам", port: "Vietname (m)" },
      { ru: "вьетнамец", port: "vietnamita (m)" },
      { ru: "вьетнамка", port: "vietnamita (f)" },
      { ru: "вьетнамский", port: "vietnamita" },
      { ru: "Индия", port: "Índia (f)" },
      { ru: "индиец", port: "indiano (m)" },
      { ru: "индианка", port: "indiana (f)" },
      { ru: "индийский", port: "indiano, hindu" },
      { ru: "Израиль", port: "Israel (m)" },
      { ru: "израильтянин", port: "israelita (m)" },
      { ru: "израильтянка", port: "israelita (f)" },
      { ru: "израильский", port: "israelita" },
      { ru: "еврей", port: "judeu (m)" },
      { ru: "еврейка", port: "judia (f)" },
      { ru: "еврейский", port: "judeu" },
      { ru: "Китай", port: "China (f)" },
      { ru: "китаец", port: "chinês (m)" },
      { ru: "китаец", port: "chinês (m)" },
      { ru: "китаянка", port: "chinesa (f)" },
      { ru: "китайский", port: "chinês" },
      { ru: "кореец", port: "coreano (m)" },
      { ru: "кореянка", port: "coreana (f)" },
      { ru: "корейский", port: "coreano" },
      { ru: "Ливан", port: "Líbano (m)" },
      { ru: "ливанец", port: "libanês (m)" },
      { ru: "ливанка", port: "libanesa (f)" },
      { ru: "ливанский", port: "libanês" },
      { ru: "Монголия", port: "Mongólia (f)" },
      { ru: "монгол", port: "mongol (m)" },
      { ru: "монголка", port: "mongol (f)" },
      { ru: "монгольский", port: "mongol" },
      { ru: "Малайзия", port: "Malásia (f)" },
      { ru: "малаец", port: "malaio (m)" },
      { ru: "малайка", port: "malaia (f)" },
      { ru: "малайский", port: "malaio" },
      { ru: "Пакистан", port: "Paquistão (m)" },
      { ru: "пакистанец", port: "paquistanês (m)" },
      { ru: "пакистанка", port: "paquistanesa (f)" },
      { ru: "пакистанский", port: "paquistanês" },
      { ru: "Саудовская Аравия", port: "Arábia (f)  Saudita" },
      { ru: "араб", port: "árabe (m)" },
      { ru: "арабка", port: "árabe (f)" },
      { ru: "арабский", port: "árabe" },
      { ru: "Таиланд", port: "Tailândia (f)" },
      { ru: "таец", port: "tailandês (m)" },
      { ru: "тайка", port: "tailandesa (f)" },
      { ru: "тайский", port: "tailandês" },
    ],
  },
  {
    name: "Центральная и Южная Америка",
    dict: [
      { ru: "Аргентина", port: "Argentina (f)" },
      { ru: "аргентинец", port: "argentino (m)" },
      { ru: "аргентинка", port: "argentina (f)" },
      { ru: "аргентинский", port: "argentino" },
      { ru: "Бразилия", port: "Brasil (m)" },
      { ru: "бразилец", port: "brasileiro (m)" },
      { ru: "бразильянка", port: "brasileira (f)" },
      { ru: "бразильский", port: "brasileiro" },
      { ru: "Колумбия", port: "Colômbia (f)" },
      { ru: "колумбиец", port: "colombiano (m)" },
      { ru: "колумбийка", port: "colombiana (f)" },
      { ru: "колумбийский", port: "colombiano" },
      { ru: "Куба", port: "Cuba (f)" },
      { ru: "кубинец", port: "cubano (m)" },
      { ru: "кубинка", port: "cubana (f)" },
      { ru: "кубинский", port: "cubano" },
      { ru: "Чили", port: "Chile (m)" },
      { ru: "чилиец", port: "chileno (m)" },
      { ru: "чилийка", port: "chilena (f)" },
      { ru: "чилийка", port: "chilena (f)" },
      { ru: "чилийский", port: "chileno" },
      { ru: "Боливия", port: "Bolívia (f)" },
      { ru: "Венесуэла", port: "Venezuela (f)" },
      { ru: "Парагвай", port: "Paraguai (m)" },
      { ru: "Перу", port: "Peru (m)" },
      { ru: "Суринам", port: "Suriname (m)" },
      { ru: "Уругвай", port: "Uruguai (m)" },
      { ru: "Эквадор", port: "Equador (m)" },
      { ru: "Багамские острова", port: "Bahamas, Baamas (f pl)" },
      { ru: "Гаити", port: "Haiti (m)" },
      { ru: "Доминиканская республика", port: "República (f)  Dominicana" },
      { ru: "Панама", port: "Panamá (m)" },
      { ru: "Ямайка", port: "Jamaica (f)" },
    ],
  },
  {
    name: "Африка",
    dict: [
      { ru: "Египет", port: "Egito (m)" },
      { ru: "египтянин", port: "egípcio (m)" },
      { ru: "египтянка", port: "egípcia (f)" },
      { ru: "египетский", port: "egípcio" },
      { ru: "Марокко", port: "Marrocos" },
      { ru: "марокканец", port: "marroquino (m)" },
      { ru: "марокканка", port: "marroquina (f)" },
      { ru: "марокканский", port: "marroquino" },
      { ru: "Тунис", port: "Tunísia (f)" },
      { ru: "тунисец", port: "tunisino (m)" },
      { ru: "туниска", port: "tunisina (f)" },
      { ru: "тунисский", port: "tunisino" },
      { ru: "Гана", port: "Gana (f)" },
      { ru: "Занзибар", port: "Zanzibar (m)" },
      { ru: "Кения", port: "Quénia (f)" },
      { ru: "Ливия", port: "Líbia (f)" },
      { ru: "Мадагаскар", port: "Madagáscar (m)" },
      { ru: "Намибия", port: "Namíbia (f)" },
      { ru: "Сенегал", port: "Senegal (m)" },
      { ru: "Сенегал", port: "Senegal (m)" },
      { ru: "Танзания", port: "Tanzânia (f)" },
      { ru: "ЮАР", port: "África do Sul (f)" },
      { ru: "африканец", port: "africano (m)" },
      { ru: "африканка", port: "africana (f)" },
      { ru: "африканский", port: "africano" },
    ],
  },
  {
    name: "Австралия и Океания",
    dict: [
      { ru: "Австралия", port: "Austrália (f)" },
      { ru: "австралиец", port: "australiano (m)" },
      { ru: "австралийка", port: "australiana (f)" },
      { ru: "австралийский", port: "australiano" },
      { ru: "Новая Зеландия", port: "Nova Zelândia (f)" },
      { ru: "новозеландец", port: "neozelandês (m)" },
      { ru: "новозеландка", port: "neozelandesa (f)" },
      { ru: "новозеландский", port: "neozelandês" },
      { ru: "Тасмания", port: "Tasmânia (f)" },
      { ru: "Французская Полинезия", port: "Polinésia Francesa (f)" },
    ],
  },

  {
    name: "Города",
    dict: [
      { ru: "Амстердам", port: "Amesterdão" },
      { ru: "Анкара", port: "Ancara" },
      { ru: "Афины", port: "Atenas" },
      { ru: "Багдад", port: "Bagdade" },
      { ru: "Бангкок", port: "Banguecoque" },
      { ru: "Барселона", port: "Barcelona" },
      { ru: "Бейрут", port: "Beirute" },
      { ru: "Берлин", port: "Berlim" },
      { ru: "Бомбей", port: "Bombaim" },
      { ru: "Бонн", port: "Bona" },
      { ru: "Бордо", port: "Bordéus" },
      { ru: "Братислава", port: "Bratislava" },
      { ru: "Брюссель", port: "Bruxelas" },
      { ru: "Будапешт", port: "Budapeste" },
      { ru: "Бухарест", port: "Bucareste" },
      { ru: "Варшава", port: "Varsóvia" },
      { ru: "Вашингтон", port: "Washington" },
      { ru: "Вена", port: "Viena" },
      { ru: "Венеция", port: "Veneza" },
      { ru: "Венеция", port: "Veneza" },
      { ru: "Гаага", port: "Haia" },
      { ru: "Гавана", port: "Havana" },
      { ru: "Гамбург", port: "Hamburgo" },
      { ru: "Гонконг", port: "Hong Kong" },
      { ru: "Дар-эс-Салам", port: "Dar es Salaam" },
      { ru: "Дели", port: "Deli" },
      { ru: "Дубай", port: "Dubai" },
      { ru: "Дублин", port: "Dublin, Dublim" },
      { ru: "Дюссельдорф", port: "Düsseldorf" },
      { ru: "Женева", port: "Genebra" },
      { ru: "Иерусалим", port: "Jerusalém" },
      { ru: "Каир", port: "Cairo" },
      { ru: "Калькутта", port: "Calcutá" },
      { ru: "Киев", port: "Kiev" },
      { ru: "Копенгаген", port: "Copenhaga" },
      { ru: "Куала-Лумпур", port: "Kuala Lumpur" },
      { ru: "Лион", port: "Lyon" },
      { ru: "Лиссабон", port: "Lisboa" },
      { ru: "Лондон", port: "Londres" },
      { ru: "Лос-Анджелес", port: "Los Angeles" },
      { ru: "Мадрид", port: "Madrid" },
      { ru: "Майями", port: "Miami" },
      { ru: "Марсель", port: "Marselha" },
      { ru: "Мехико", port: "Cidade do México" },
      { ru: "Монреаль", port: "Montreal" },
      { ru: "Москва", port: "Moscovo" },
      { ru: "Мюнхен", port: "Munique" },
      { ru: "Найроби", port: "Nairóbi" },
      { ru: "Неаполь", port: "Nápoles" },
      { ru: "Ницца", port: "Nisa" },
      { ru: "Нью-Йорк", port: "Nova York" },
      { ru: "Осло", port: "Oslo" },
      { ru: "Оттава", port: "Ottawa" },
      { ru: "Париж", port: "Paris" },
      { ru: "Пекин", port: "Pequim" },
      { ru: "Прага", port: "Praga" },
      { ru: "Рим", port: "Roma" },
      { ru: "Рио-де-Жанейро", port: "Rio de Janeiro" },
      { ru: "Санкт-Петербург", port: "São Petersburgo" },
    ],
  },
  {
    name: "Политика -1",
    dict: [
      { ru: "политика", port: "política (f)" },
      { ru: "политический", port: "político" },
      { ru: "политик", port: "político (m)" },
      { ru: "государство", port: "estado (m)" },
      { ru: "гражданин", port: "cidadão (m)" },
      { ru: "гражданство", port: "cidadania (f)" },
      { ru: "национальный герб", port: "brasão (m)  de armas" },
      { ru: "государственный гимн", port: "hino (m)  nacional" },
      { ru: "правительство", port: "governo (m)" },
      { ru: "руководитель страны", port: "Chefe (m)  de Estado" },
      { ru: "парламент", port: "parlamento (m)" },
      { ru: "партия", port: "partido (m)" },
      { ru: "капитализм", port: "capitalismo (m)" },
      { ru: "капиталистический", port: "capitalista" },
      { ru: "социализм", port: "socialismo (m)" },
      { ru: "социалистический", port: "socialista" },
      { ru: "коммунизм", port: "comunismo (m)" },
      { ru: "коммунистический", port: "comunista" },
      { ru: "коммунист", port: "comunista (m)" },
      { ru: "коммунист", port: "comunista (m)" },
      { ru: "демократия", port: "democracia (f)" },
      { ru: "демократ", port: "democrata (m)" },
      { ru: "демократический", port: "democrático" },
      { ru: "демократическая партия", port: "Partido (m)  Democrático" },
      { ru: "либерал", port: "liberal (m)" },
      { ru: "либеральный", port: "liberal" },
      { ru: "консерватор", port: "conservador (m)" },
      { ru: "консервативный", port: "conservador" },
      { ru: "республика", port: "república (f)" },
      { ru: "республиканец", port: "republicano (m)" },
      { ru: "республиканская партия", port: "Partido (m)  Republicano" },
      { ru: "выборы", port: "eleições (f pl)" },
      { ru: "выбирать, избирать", port: "eleger (vt)" },
      { ru: "избиратель", port: "eleitor (m)" },
      { ru: "избирательная кампания", port: "campanha (f)  eleitoral" },
      { ru: "голосование", port: "votação (f)" },
      { ru: "голосовать", port: "votar (vi)" },
      { ru: "право голоса", port: "direito (m)  de voto" },
      { ru: "кандидат", port: "candidato (m)" },
      { ru: "баллотироваться", port: "candidatar-se (vi)" },
      { ru: "кампания (напр. рекламная)", port: "campanha (f)" },
      { ru: "оппозиционный", port: "da oposição" },
      { ru: "оппозиция", port: "oposição (f)" },
      { ru: "визит", port: "visita (f)" },
      { ru: "официальный визит", port: "visita (f)  oficial" },
      { ru: "международный", port: "internacional" },
      { ru: "переговоры", port: "negociações (f pl)" },
      { ru: "вести переговоры", port: "negociar (vi)" },
    ],
  },
  {
    name: "Политика -2",
    dict: [
      { ru: "общество", port: "sociedade (f)" },
      { ru: "конституция", port: "constituição (f)" },
      { ru: "власть", port: "poder (m)" },
      { ru: "коррупция", port: "corrupção (f)" },
      { ru: "закон", port: "lei (f)" },
      { ru: "законный", port: "legal" },
      { ru: "справедливость", port: "justiça (f)" },
      { ru: "комитет", port: "comité (m)" },
      { ru: "законопроект", port: "projeto-lei (m)" },
      { ru: "бюджет", port: "orçamento (m)" },
      { ru: "политика (курс)", port: "política (f)" },
      { ru: "реформа", port: "reforma (f)" },
      { ru: "радикальный", port: "radical" },
      { ru: "сила (мощь)", port: "força (f)" },
      { ru: "могущественный", port: "poderoso" },
      { ru: "сторонник", port: "partidário (m)" },
      { ru: "влияние", port: "influência (f)" },
      { ru: "режим", port: "regime (m)" },
      { ru: "режим", port: "regime (m)" },
      { ru: "конфликт", port: "conflito (m)" },
      { ru: "заговор", port: "conspiração (f)" },
      { ru: "провокация", port: "provocação (f)" },
      { ru: "свергнуть", port: "derrubar (vt)" },
      { ru: "свержение", port: "derrube (m), queda (f)" },
      { ru: "революция", port: "revolução (f)" },
      { ru: "переворот", port: "golpe (m)  de Estado" },
      { ru: "военный переворот", port: "golpe (m)  militar" },
      { ru: "кризис", port: "crise (f)" },
      { ru: "экономический спад", port: "recessão (f)  económica" },
      { ru: "демонстрант", port: "manifestante (m)" },
      { ru: "демонстрация", port: "manifestação (f)" },
      { ru: "военное положение", port: "lei (f)  marcial" },
      { ru: "военная база", port: "base (f)  militar" },
      { ru: "стабильность", port: "estabilidade (f)" },
      { ru: "стабильный", port: "estável" },
      { ru: "эксплуатация", port: "exploração (f)" },
      { ru: "эксплуатировать", port: "explorar (vt)" },
      { ru: "расизм", port: "racismo (m)" },
      { ru: "расист", port: "racista (m)" },
      { ru: "фашизм", port: "fascismo (m)" },
      { ru: "фашист", port: "fascista (m)" },
    ],
  },
  {
    name: "Политика -2",
    dict: [
      { ru: "общество", port: "sociedade (f)" },
      { ru: "конституция", port: "constituição (f)" },
      { ru: "власть", port: "poder (m)" },
      { ru: "коррупция", port: "corrupção (f)" },
      { ru: "закон", port: "lei (f)" },
      { ru: "законный", port: "legal" },
      { ru: "справедливость", port: "justiça (f)" },
      { ru: "комитет", port: "comité (m)" },
      { ru: "законопроект", port: "projeto-lei (m)" },
      { ru: "бюджет", port: "orçamento (m)" },
      { ru: "политика (курс)", port: "política (f)" },
      { ru: "реформа", port: "reforma (f)" },
      { ru: "радикальный", port: "radical" },
      { ru: "сила (мощь)", port: "força (f)" },
      { ru: "могущественный", port: "poderoso" },
      { ru: "сторонник", port: "partidário (m)" },
      { ru: "влияние", port: "influência (f)" },
      { ru: "режим", port: "regime (m)" },
      { ru: "режим", port: "regime (m)" },
      { ru: "конфликт", port: "conflito (m)" },
      { ru: "заговор", port: "conspiração (f)" },
      { ru: "провокация", port: "provocação (f)" },
      { ru: "свергнуть", port: "derrubar (vt)" },
      { ru: "свержение", port: "derrube (m), queda (f)" },
      { ru: "революция", port: "revolução (f)" },
      { ru: "переворот", port: "golpe (m)  de Estado" },
      { ru: "военный переворот", port: "golpe (m)  militar" },
      { ru: "кризис", port: "crise (f)" },
      { ru: "экономический спад", port: "recessão (f)  económica" },
      { ru: "демонстрант", port: "manifestante (m)" },
      { ru: "демонстрация", port: "manifestação (f)" },
      { ru: "военное положение", port: "lei (f)  marcial" },
      { ru: "военная база", port: "base (f)  militar" },
      { ru: "стабильность", port: "estabilidade (f)" },
      { ru: "стабильный", port: "estável" },
      { ru: "эксплуатация", port: "exploração (f)" },
      { ru: "эксплуатировать", port: "explorar (vt)" },
      { ru: "расизм", port: "racismo (m)" },
      { ru: "расист", port: "racista (m)" },
      { ru: "фашизм", port: "fascismo (m)" },
      { ru: "фашист", port: "fascista (m)" },
    ],
  },
  {
    name: "Страны разное",
    dict: [
      { ru: "иностранец", port: "estrangeiro (m)" },
      { ru: "иностранный", port: "estrangeiro" },
      { ru: "за границей", port: "no estrangeiro" },
      { ru: "эмигрант", port: "emigrante (m)" },
      { ru: "эмиграция", port: "emigração (f)" },
      { ru: "эмигрировать", port: "emigrar (vi)" },
      { ru: "Запад", port: "Ocidente (m)" },
      { ru: "Восток", port: "Oriente (m)" },
      { ru: "Дальний Восток", port: "Extremo Oriente (m)" },
      { ru: "цивилизация", port: "civilização (f)" },
      { ru: "человечество", port: "humanidade (f)" },
      { ru: "мир (вся планета)", port: "mundo (m)" },
      { ru: "мир (спокойствие)", port: "paz (f)" },
      { ru: "мировой (всемирный)", port: "mundial" },
      { ru: "родина", port: "pátria (f)" },
      { ru: "народ", port: "povo (m)" },
      { ru: "население", port: "população (f)" },
      { ru: "люди", port: "gente (f)" },
      { ru: "люди", port: "gente (f)" },
      { ru: "нация", port: "nação (f)" },
      { ru: "поколение", port: "geração (f)" },
      { ru: "территория", port: "território (m)" },
      { ru: "регион", port: "região (f)" },
      { ru: "штат (часть страны)", port: "estado (m)" },
      { ru: "традиция", port: "tradição (f)" },
      { ru: "обычай", port: "costume (m)" },
      { ru: "экология", port: "ecologia (f)" },
      { ru: "индеец", port: "índio (m)" },
      { ru: "цыган", port: "cigano (m)" },
      { ru: "цыганка", port: "cigana (f)" },
      { ru: "цыганский", port: "cigano" },
      { ru: "империя", port: "império (m)" },
      { ru: "колония", port: "colónia (f)" },
      { ru: "рабство", port: "escravidão (f)" },
      { ru: "нашествие", port: "invasão (f)" },
      { ru: "голод (бедствие)", port: "fome (f)" },
    ],
  },
  {
    name: "Мировые религии",
    dict: [
      { ru: "религия", port: "religião (f)" },
      { ru: "религиозный", port: "religioso" },
      { ru: "верование", port: "crença (f)" },
      { ru: "верить (в бога)", port: "crer (vt)" },
      { ru: "верующий (сущ.)", port: "crente (m)" },
      { ru: "атеизм", port: "ateísmo (m)" },
      { ru: "атеист", port: "ateu (m)" },
      { ru: "Христианство", port: "cristianismo (m)" },
      { ru: "христианин", port: "cristão (m)" },
      { ru: "христианский", port: "cristão" },
      { ru: "Католицизм", port: "catolicismo (m)" },
      { ru: "католик", port: "católico (m)" },
      { ru: "католический", port: "católico" },
      { ru: "Протестантство", port: "protestantismo (m)" },
      { ru: "Протестантская церковь", port: "Igreja (f)  Protestante" },
      { ru: "протестант", port: "protestante (m)" },
      { ru: "Православие", port: "ortodoxia (f)" },
      { ru: "Православная церковь", port: "Igreja (f)  Ortodoxa" },
      { ru: "Православная церковь", port: "Igreja (f)  Ortodoxa" },
      { ru: "православный", port: "ortodoxo (m)" },
      { ru: "Пресвитерианство", port: "presbiterianismo (m)" },
      { ru: "Пресвитерианская церковь", port: "Igreja (f)  Presbiteriana" },
      { ru: "пресвитерианин", port: "presbiteriano (m)" },
      { ru: "Лютеранская церковь", port: "Igreja (f)  Luterana" },
      { ru: "лютеранин", port: "luterano (m)" },
      { ru: "Баптизм", port: "Igreja (f)  Batista" },
      { ru: "баптист", port: "batista (m)" },
      { ru: "Англиканская церковь", port: "Igreja (f)  Anglicana" },
      { ru: "англиканин", port: "anglicano (m)" },
      { ru: "Мормонство", port: "mormonismo (m)" },
      { ru: "мормон", port: "mórmon (m)" },
      { ru: "Иудаизм", port: "Judaísmo (m)" },
      { ru: "иудей", port: "judeu (m)" },
      { ru: "Буддизм", port: "budismo (m)" },
      { ru: "буддист", port: "budista (m)" },
      { ru: "Индуизм", port: "hinduísmo (m)" },
      { ru: "индуист", port: "hindu (m)" },
      { ru: "Ислам", port: "Islão (m)" },
      { ru: "мусульманин", port: "muçulmano (m)" },
      { ru: "мусульманский", port: "muçulmano" },
      { ru: "Шиизм", port: "Xiismo (m)" },
      { ru: "шиит", port: "xiita (m)" },
      { ru: "Суннизм", port: "sunismo (m)" },
      { ru: "суннит", port: "sunita (m)" },
      { ru: "священник", port: "padre (m)" },
      { ru: "Папа Римский", port: "Papa (m)" },
      { ru: "монах", port: "monge (m)" },
      { ru: "монахиня", port: "freira (f)" },
      { ru: "пастор", port: "pastor (m)" },
      { ru: "аббат", port: "abade (m)" },
      { ru: "викарий", port: "vigário (m)" },
      { ru: "епископ", port: "bispo (m)" },
      { ru: "кардинал", port: "cardeal (m)" },
      { ru: "проповедник", port: "pregador (m)" },
      { ru: "проповедь", port: "sermão (m)" },
      { ru: "прихожане", port: "paroquianos (pl)" },
      { ru: "верующий (сущ.)", port: "crente (m)" },
      { ru: "атеист", port: "ateu (m)" },
    ],
  },
  {
    name: "Термины общие",
    dict: [
      { ru: "база (основа)", port: "base (f)" },
      { ru: "баланс", port: "equilíbrio (m)" },
      { ru: "вариант", port: "variante (f)" },
      { ru: "вещь", port: "coisa (f)" },
      { ru: "вид (напр. спорта)", port: "tipo (m)" },
      { ru: "выбор (ассортимент)", port: "variedade (f)" },
      { ru: "зона", port: "zona (f)" },
      { ru: "идеал", port: "ideal" },
      { ru: "истина", port: "verdade (f)" },
      { ru: "категория", port: "categoria (f)" },
      { ru: "компенсация", port: "compensação (f)" },
      { ru: "лабиринт", port: "labirinto (m)" },
      { ru: "момент", port: "momento (m)" },
      { ru: "начало", port: "começo (m)" },
      { ru: "объект (предмет)", port: "objeto (m)" },
      { ru: "окончание (конец)", port: "fim (m)" },
      { ru: "оригинал", port: "original (m)" },
      { ru: "остановка (перерыв)", port: "paragem (f)" },
      { ru: "остановка (перерыв)", port: "paragem (f)" },
      { ru: "очередь (в первую ~)", port: "vez (f)" },
      { ru: "ошибка", port: "erro (m)" },
      { ru: "пауза", port: "pausa (f)" },
      { ru: "позиция", port: "posição (f)" },
      { ru: "польза (полезность)", port: "utilidade (f)" },
      { ru: "помощь", port: "ajuda (f)" },
      { ru: "преграда", port: "barreira (f)" },
      { ru: "препятствие", port: "obstáculo (m)" },
      { ru: "пример", port: "exemplo (m)" },
      { ru: "принцип", port: "princípio (m)" },
      { ru: "причина", port: "causa (f)" },
      { ru: "проблема", port: "problema (m)" },
      { ru: "прогресс", port: "progresso (m)" },
      { ru: "процесс", port: "processo (m)" },
      { ru: "развитие", port: "desenvolvimento (m)" },
      { ru: "различие", port: "diferença (f)" },
      { ru: "реакция", port: "reação (f)" },
      { ru: "решение (задачи)", port: "solução (f)" },
      { ru: "риск", port: "risco (m)" },
      { ru: "рост (процесс)", port: "crescimento (m)" },
      { ru: "свойство", port: "propriedade (f)" },
      { ru: "секция", port: "secção (f)" },
      { ru: "серия (ряд)", port: "série (f)" },
      { ru: "система", port: "sistema (m)" },
      { ru: "ситуация", port: "situação (f)" },
      { ru: "совпадение", port: "coincidência (f)" },
      { ru: "способ", port: "modo (m)" },
      { ru: "сравнение", port: "comparação (f)" },
      { ru: "срочно", port: "urgentemente" },
      { ru: "срочный", port: "urgente" },
      { ru: "стандарт", port: "padrão (m)" },
      { ru: "стандартный", port: "padrão" },
      { ru: "степень", port: "grau (m)" },
      { ru: "стиль", port: "estilo (m)" },
      { ru: "таблица", port: "tabela (f)" },
      { ru: "тайна, секрет", port: "mistério (m)" },
      { ru: "темп", port: "ritmo (m)" },
      { ru: "термин", port: "termo (m)" },
      { ru: "тип", port: "tipo (m)" },
    ],
  },
  {
    name: "Прилагательные - 1",
    dict: [
      { ru: "аккуратный (работа и т.п.)", port: "meticuloso" },
      { ru: "бедный", port: "pobre" },
      { ru: "безоблачный", port: "desanuviado" },
      { ru: "безопасный", port: "seguro" },
      { ru: "бесплатный", port: "gratuito, grátis" },
      { ru: "благодарный", port: "agradecido" },
      { ru: "ближайший", port: "mais próximo" },
      { ru: "ближний", port: "perto" },
      { ru: "близкий (недалёкий)", port: "próximo" },
      { ru: "близорукий", port: "míope" },
      { ru: "больной", port: "doente" },
      { ru: "большой", port: "grande" },
      { ru: "бывший в употреблении", port: "usado" },
      { ru: "быстрый", port: "rápido" },
      { ru: "важный", port: "importante" },
      { ru: "вежливый", port: "educado" },
      { ru: "вероятный", port: "provável" },
      { ru: "весёлый", port: "alegre" },
      { ru: "весёлый", port: "alegre" },
      { ru: "вкусный", port: "gostoso" },
      { ru: "влажный (климат)", port: "húmido" },
      { ru: "внешний", port: "externo" },
      { ru: "внутренний", port: "interno" },
      { ru: "возможный", port: "possível" },
      { ru: "враждебный", port: "hostil" },
      { ru: "высший", port: "superior" },
      { ru: "главный", port: "principal" },
      { ru: "гладкий", port: "liso" },
      { ru: "глупый", port: "burro, estúpido" },
      { ru: "голодный", port: "faminto" },
      { ru: "горький", port: "amargo" },
      { ru: "горячий", port: "quente" },
      { ru: "гражданский", port: "civil" },
      { ru: "громкий (голос)", port: "alto" },
      { ru: "грустный", port: "triste" },
      { ru: "грязный", port: "sujo" },
      { ru: "густой (туман)", port: "cerrado" },
      { ru: "далёкий", port: "distante" },
      { ru: "дальний (отдалённый)", port: "remoto, longínquo" },
      { ru: "детский", port: "infantil" },
      { ru: "дешёвый (о предмете)", port: "barato" },
      { ru: "длинный (путь)", port: "longo" },
      { ru: "добрый", port: "bondoso" },
      { ru: "довольный", port: "contente" },
      { ru: "дополнительный", port: "suplementar" },
      { ru: "дорогой", port: "caro" },
      { ru: "древний", port: "antigo" },
      { ru: "жидкий", port: "líquido" },
      { ru: "жирный (о пище)", port: "gordo" },
      { ru: "заботливый", port: "carinhoso" },
      { ru: "загадочный", port: "enigmático" },
      { ru: "загорелый", port: "bronzeado" },
      { ru: "задний", port: "de trás" },
      { ru: "законный", port: "legal" },
      { ru: "закрытый", port: "fechado" },
      { ru: "замороженный (продукт)", port: "congelado" },
      { ru: "значительный", port: "considerável" },
      { ru: "зрелый (плод)", port: "maduro" },
    ],
  },
  {
    name: "Прилагательные - 2",
    dict: [
      { ru: "общественный", port: "social" },
      { ru: "обыкновенный", port: "comum, normal" },
      { ru: "обязательный", port: "obrigatório" },
      { ru: "ограниченный", port: "limitado" },
      { ru: "огромный", port: "enorme" },
      { ru: "одинаковый", port: "igual" },
      { ru: "опасный", port: "perigoso" },
      { ru: "оригинальный", port: "original" },
      { ru: "основной", port: "principal" },
      { ru: "острый (нож и т.п.)", port: "afiado" },
      { ru: "открытый", port: "aberto" },
      { ru: "отличный (хороший)", port: "excelente" },
      { ru: "отрицательный", port: "negativo" },
      { ru: "персональный", port: "pessoal" },
      { ru: "печальный", port: "triste" },
      { ru: "плодородный", port: "fértil" },
      { ru: "плоский", port: "plano" },
      { ru: "плотный (напр. туман)", port: "denso" },
      { ru: "плотный (напр. туман)", port: "denso" },
      { ru: "плохой", port: "mau" },
      { ru: "подпольный", port: "clandestino" },
      { ru: "полный (наполненный)", port: "cheio" },
      { ru: "понятный (ясный)", port: "claro" },
      { ru: "последний", port: "último" },
      { ru: "постоянный (работа, адрес)", port: "permanente" },
      { ru: "похожий", port: "similar" },
      { ru: "правильный (верный)", port: "correto" },
      { ru: "правый", port: "direito" },
      { ru: "превосходный", port: "soberbo, perfeito" },
      { ru: "предыдущий, прежний", port: "prévio" },
      { ru: "прекрасный (красивый)", port: "belo" },
      { ru: "пресная (о воде)", port: "doce" },
      { ru: "пригодный", port: "útil" },
      { ru: "приятный (голос)", port: "agradável" },
      { ru: "продолжительный", port: "contínuo" },
      { ru: "прозрачный", port: "transparente" },
      { ru: "простой", port: "simples" },
      { ru: "просторный", port: "amplo" },
      { ru: "противоположный", port: "contrário" },
      { ru: "прохладный", port: "fresco" },
      { ru: "прочный", port: "sólido" },
      { ru: "прошедший", port: "mais recente" },
      { ru: "прошлый", port: "passado" },
      { ru: "прямой", port: "reto" },
    ],
  },
  {
    name: "Прилагательные - 3",
    dict: [
      { ru: "пунктуальный", port: "pontual" },
      { ru: "пустой (~ бокал)", port: "vazio" },
      { ru: "различный (разный)", port: "diverso" },
      { ru: "разный", port: "diferente" },
      { ru: "редкий", port: "raro" },
      { ru: "рискованный", port: "arriscado" },
      { ru: "ровный (о поверхности)", port: "liso" },
      { ru: "родной (о стране)", port: "natal" },
      { ru: "самый важный", port: "o mais importante" },
      { ru: "свежий", port: "fresco" },
      { ru: "светлый (о цвете)", port: "claro" },
      { ru: "свободный", port: "livre" },
      { ru: "сильный", port: "forte" },
      { ru: "сладкий", port: "doce" },
      { ru: "следующий", port: "seguinte" },
      { ru: "слепой", port: "cego" },
      { ru: "сложный (вопрос и т.п.)", port: "difícil, complexo" },
      { ru: "смуглый", port: "moreno" },
      { ru: "смуглый", port: "moreno" },
      { ru: "совместимый", port: "compatível" },
      { ru: "совместный", port: "conjunto" },
      { ru: "солёный", port: "salgado" },
      { ru: "солнечный (день)", port: "de sol, ensolarado" },
      { ru: "соседний", port: "vizinho" },
      { ru: "специальный", port: "especial" },
      { ru: "спокойный", port: "calmo" },
      { ru: "старый (дом и т.п.)", port: "velho" },
      { ru: "сухой", port: "seco" },
      { ru: "счастливый", port: "feliz" },
      { ru: "сырой (не варёный)", port: "cru" },
      { ru: "твёрдый (не мягкий)", port: "duro" },
      { ru: "тёмный (о комнате)", port: "escuro" },
      { ru: "тёплый (день, пища)", port: "quente" },
      { ru: "тесный (одежда, обувь)", port: "apertado" },
      { ru: "тихий (негромкий)", port: "baixo" },
      { ru: "тихий (спокойный)", port: "tranquilo" },
      { ru: "толстый (слой)", port: "grosso" },
      { ru: "точный", port: "exato" },
      { ru: "тощий", port: "magro" },
      { ru: "трудный", port: "difícil" },
      { ru: "тусклый (о свете)", port: "fraco" },
      { ru: "тяжёлый (напр. груз)", port: "pesado" },
      { ru: "удовлетворённый", port: "satisfeito" },
      { ru: "узкий", port: "estreito" },
      { ru: "умный", port: "inteligente" },
      { ru: "уникальный", port: "único" },
      { ru: "усталый", port: "cansado" },
      { ru: "утомительный", port: "cansativo" },
      { ru: "холодный", port: "frio" },
      { ru: "хороший", port: "bom" },
      { ru: "хрупкий", port: "frágil" },
      { ru: "худой", port: "magro" },
      { ru: "целый (полностью)", port: "inteiro" },
      { ru: "центральный", port: "central" },
      { ru: "частный (личный)", port: "privado" },
      { ru: "чистый", port: "limpo" },
      { ru: "чрезмерный", port: "excessivo" },
      { ru: "широкий", port: "largo" },
    ],
  },
  {
    name: "Глаголы А-Е",
    dict: [
      { ru: "ампутировать", port: "amputar (vt)" },
      { ru: "аплодировать", port: "aplaudir (vi)" },
      { ru: "ассистировать", port: "assistir (vt)" },
      { ru: "атаковать", port: "atacar (vt)" },
      { ru: "базироваться (на…)", port: "basear-se (vp)" },
      { ru: "бежать", port: "correr (vi)" },
      { ru: "беспокоить (волновать)", port: "preocupar (vt)" },
      { ru: "беспокоить (мешать)", port: "perturbar (vt)" },
      { ru: "беспокоиться", port: "preocupar-se (vp)" },
      { ru: "бить (ударять)", port: "bater (vt)" },
      { ru: "благодарить", port: "agradecer (vt)" },
      { ru: "бороться (воевать)", port: "lutar (vt)" },
      { ru: "бороться (спорт.)", port: "lutar (vi)" },
      { ru: "бояться (чего-л.)", port: "ter medo" },
      { ru: "брать", port: "pegar (vt)" },
      { ru: "бриться", port: "barbear-se (vp)" },
      { ru: "бронировать (номер и т.п.)", port: "reservar (vt)" },
      { ru: "бросать (напр. камень)", port: "jogar, atirar (vt)" },
      { ru: "бросать (напр. камень)", port: "jogar, atirar (vt)" },
      { ru: "бросать (покидать)", port: "deixar (vt)" },
      { ru: "будить", port: "acordar, despertar (vt)" },
      { ru: "быть (в настоящий момент)", port: "estar (vi)" },
      { ru: "быть (всегда)", port: "ser (vi)" },
      { ru: "быть должным", port: "dever (vi)" },
      { ru: "быть похожим", port: "parecer-se (vp)" },
      { ru: "быть причиной …", port: "causar (vt)" },
      { ru: "быть убежденным (в ...)", port: "estar convencido" },
      { ru: "верить (думать)", port: "crer (vt)" },
      { ru: "веселиться", port: "divertir-se (vp)" },
      { ru: "весить (иметь вес)", port: "pesar (vt)" },
      { ru: "вести машину", port: "conduzir (vt)" },
      { ru: "вести переговоры", port: "negociar (vi)" },
      { ru: "вести себя", port: "comportar-se (vp)" },
      { ru: "вешать", port: "pendurar (vt)" },
      { ru: "вздохнуть", port: "suspirar (vi)" },
      { ru: "вздрагивать", port: "estremecer (vi)" },
      { ru: "взлетать (о самолёте)", port: "descolar (vi)" },
      { ru: "датироваться", port: "datar (vi)" },
      { ru: "действовать (поступать)", port: "agir (vi)" },
      { ru: "делать", port: "fazer (vt)" },
      { ru: "делать заключение", port: "tirar uma conclusão" },
      { ru: "делать прививки", port: "vacinar (vt)" },
      { ru: "делить (матем.)", port: "dividir (vt)" },
      { ru: "добавлять", port: "acrescentar (vt)" },
      { ru: "доверять", port: "confiar (vt)" },
      { ru: "доказывать", port: "provar (vt)" },
      { ru: "докладывать", port: "reportar (vt)" },
      { ru: "доносить (сделать донос)", port: "denunciar (vt)" },
      { ru: "достигать (напр. ~ полюса)", port: "chegar a …" },
      { ru: "достигать (результата)", port: "alcançar (vt)" },
      { ru: "драться (в драке)", port: "bater-se (vp)" },
      { ru: "дрессировать", port: "adestrar (vt)" },
      { ru: "дрожать", port: "tremer (vi)" },
      { ru: "думать", port: "pensar (vt)" },
      { ru: "дуть", port: "soprar (vi)" },
      { ru: "дуть", port: "soprar (vi)" },
      { ru: "дышать", port: "respirar (vi)" },
      { ru: "ехать", port: "ir (vi)" },
    ],
  },
  {
    name: "Глаголы Ж-М",
    dict: [
      { ru: "жаловаться", port: "queixar-se (vp)" },
      { ru: "ждать", port: "esperar (vi, vt)" },
      { ru: "желать", port: "desejar (vt)" },
      { ru: "жениться", port: "casar-se (vp)" },
      { ru: "жечь, сжигать", port: "queimar (vt)" },
      { ru: "жить (проживать)", port: "morar (vt)" },
      { ru: "жить (существовать)", port: "viver (vi)" },
      { ru: "забыть", port: "esquecer (vi)" },
      { ru: "завидовать", port: "invejar (vt)" },
      { ru: "зависеть (от …)", port: "depender de … (vi)" },
      { ru: "заворачивать (в бумагу)", port: "embrulhar (vt)" },
      { ru: "завтракать", port: "tomar o pequeno-almoço" },
      { ru: "задуматься", port: "ficar pensativo" },
      { ru: "зажечь (костёр, камин)", port: "acender (vt)" },
      { ru: "заказывать (в ресторане)", port: "pedir (vt)" },
      { ru: "заканчивать", port: "terminar (vt)" },
      { ru: "закрывать", port: "fechar (vt)" },
      { ru: "замечать (увидеть)", port: "perceber (vt)" },
      { ru: "замечать (увидеть)", port: "perceber (vt)" },
      { ru: "замолчать", port: "calar-se (vp)" },
      { ru: "занимать (деньги)", port: "tomar emprestado (vt)" },
      { ru: "записывать", port: "anotar (vt)" },
      { ru: "запомнить", port: "memorizar (vt)" },
      { ru: "запрещать", port: "proibir (vt)" },
      { ru: "запускать (начинать)", port: "lançar (vt)" },
      { ru: "заразить (~ гриппом)", port: "infetar, contagiar (vt)" },
      { ru: "заразиться чем-л.", port: "contagiar-se com …" },
      { ru: "заряжать (оружие)", port: "carregar (vt)" },
      { ru: "заслуживать", port: "merecer (vt)" },
      { ru: "жаловаться", port: "queixar-se (vp)" },
      { ru: "ждать", port: "esperar (vi, vt)" },
      { ru: "желать", port: "desejar (vt)" },
      { ru: "жениться", port: "casar-se (vp)" },
      { ru: "жечь, сжигать", port: "queimar (vt)" },
      { ru: "жить (проживать)", port: "morar (vt)" },
      { ru: "жить (существовать)", port: "viver (vi)" },
      { ru: "забыть", port: "esquecer (vi)" },
      { ru: "завидовать", port: "invejar (vt)" },
      { ru: "зависеть (от …)", port: "depender de … (vi)" },
      { ru: "заворачивать (в бумагу)", port: "embrulhar (vt)" },
      { ru: "завтракать", port: "tomar o pequeno-almoço" },
      { ru: "задуматься", port: "ficar pensativo" },
      { ru: "зажечь (костёр, камин)", port: "acender (vt)" },
      { ru: "заказывать (в ресторане)", port: "pedir (vt)" },
      { ru: "заканчивать", port: "terminar (vt)" },
      { ru: "закрывать", port: "fechar (vt)" },
      { ru: "замечать (увидеть)", port: "perceber (vt)" },
      { ru: "замолчать", port: "calar-se (vp)" },
      { ru: "занимать (деньги)", port: "tomar emprestado (vt)" },
      { ru: "записывать", port: "anotar (vt)" },
      { ru: "запомнить", port: "memorizar (vt)" },
      { ru: "запрещать", port: "proibir (vt)" },
      { ru: "запускать (начинать)", port: "lançar (vt)" },
      { ru: "заразить (~ гриппом)", port: "infetar, contagiar (vt)" },
      { ru: "заразиться чем-л.", port: "contagiar-se com …" },
      { ru: "заряжать (оружие)", port: "carregar (vt)" },
      { ru: "заслуживать", port: "merecer (vt)" },
      { ru: "защищать (страну)", port: "defender (vt)" },
      { ru: "защищаться", port: "defender-se (vp)" },
      { ru: "звать (на помощь и т.п.)", port: "chamar (vt)" },
      { ru: "знакомить (кого-л. с кем-л.)", port: "apresentar (vt)" },
      { ru: "знакомиться (с кем-л.)", port: "conhecer-se (vp)" },
      { ru: "знать (кого-л.)", port: "conhecer (vt)" },
      { ru: "знать (что-л.)", port: "saber (vt)" },
      { ru: "значить", port: "significar (vt)" },
      { ru: "играть (в игру)", port: "brincar, jogar (vi, vt)" },
      { ru: "играть (об актёре)", port: "desempenhar (vt)" },
      { ru: "идти", port: "ir (vi)" },
      { ru: "избавиться от …", port: "livrar-se de …" },
      { ru: "избегать", port: "evitar (vt)" },
      { ru: "извинять", port: "desculpar (vt)" },
      { ru: "извиняться", port: "desculpar-se (vp)" },
      { ru: "изменить (поменять)", port: "mudar (vt)" },
      { ru: "изобретать", port: "inventar (vt)" },
      { ru: "изолировать", port: "isolar (vt)" },
      { ru: "изолировать", port: "isolar (vt)" },
      { ru: "изучать", port: "estudar (vt)" },
      { ru: "иметь", port: "ter (vt)" },
      { ru: "имитировать", port: "imitar (vt)" },
      { ru: "импортировать", port: "importar (vt)" },
      { ru: "инструктировать", port: "instruir (vt)" },
      { ru: "интересовать", port: "interessar (vt)" },
      { ru: "интересоваться", port: "interessar-se (vp)" },
      { ru: "информировать", port: "informar (vt)" },
      { ru: "искать …", port: "buscar (vt)" },
      { ru: "исключать (~ из школы)", port: "expulsar (vt)" },
      { ru: "испачкаться", port: "sujar-se (vp)" },
      { ru: "исправлять (ошибку)", port: "corrigir (vt)" },
      { ru: "исправлять (починить)", port: "reparar (vt)" },
      { ru: "исчезнуть", port: "desaparecer (vi)" },
      { ru: "касаться", port: "tocar (vt)" },
      { ru: "класть, положить", port: "pôr, colocar (vt)" },
      { ru: "компенсировать", port: "compensar (vt)" },
      { ru: "компрометировать", port: "comprometer (vt)" },
      { ru: "конкурировать", port: "competir (vi)" },
      { ru: "консультироваться c …", port: "consultar …" },
      { ru: "контролировать", port: "controlar (vt)" },
      { ru: "концентрироваться", port: "concentrar-se (vp)" },
      { ru: "координировать", port: "coordenar (vt)" },
      { ru: "кормить", port: "alimentar (vt)" },
      { ru: "краснеть (от стыда)", port: "ficar vermelho" },
      { ru: "красть", port: "roubar (vt)" },
      { ru: "кричать", port: "gritar (vi)" },
      { ru: "купать", port: "dar banho, lavar (vt)" },
      { ru: "купаться (в море и т.п.)", port: "ir nadar" },
      { ru: "кушать, есть", port: "comer (vt)" },
      { ru: "лежать (о предмете)", port: "estar" },
      { ru: "лежать (о человеке)", port: "estar deitado" },
      { ru: "летать", port: "voar (vi)" },
      { ru: "лечить (болезнь)", port: "tratar (vt)" },
      { ru: "лишать", port: "privar (vt)" },
      { ru: "ловить", port: "pegar (vt)" },
      { ru: "ловить рыбу", port: "pescar (vt)" },
      { ru: "ложиться спать", port: "ir para a cama" },
      { ru: "ломать", port: "quebrar (vt)" },
    ],
  },
  {
    name: "Глаголы Н-О",
    dict: [
      { ru: "наблюдать", port: "observar (vt)" },
      { ru: "наградить (~ медалью)", port: "condecorar (vt)" },
      { ru: "нагревать", port: "aquecer (vt)" },
      { ru: "надеяться", port: "esperar (vt)" },
      { ru: "назначать (на должность)", port: "nomear (vt)" },
      { ru: "называть", port: "denominar (vt)" },
      { ru: "наказывать", port: "punir (vt)" },
      { ru: "наливать (в чашку)", port: "verter, encher (vt)" },
      { ru: "намекать", port: "aludir (vt)" },
      { ru: "нанимать (напр. лодку)", port: "alugar (vt)" },
      { ru: "нанимать (работника)", port: "contratar (vt)" },
      { ru: "наполнять", port: "encher (vt)" },
      { ru: "напоминать", port: "fazer lembrar" },
      { ru: "направлять", port: "direcionar (vt)" },
      { ru: "наследовать", port: "herdar (vt)" },
      { ru: "насмехаться", port: "zombar (vt)" },
      { ru: "настаивать (упорствовать)", port: "insistir (vi)" },
      { ru: "находить", port: "encontrar (vt)" },
      { ru: "находить", port: "encontrar (vt)" },
      { ru: "начинать", port: "começar (vt)" },
      { ru: "недооценивать", port: "subestimar (vt)" },
      { ru: "недоумевать", port: "estar perplexo" },
      { ru: "нравиться", port: "gostar (vt)" },
      { ru: "нырять", port: "mergulhar (vi)" },
      { ru: "нюхать (цветы и т.п.)", port: "cheirar (vi)" },
      { ru: "обвинять", port: "acusar (vt)" },
      { ru: "обедать", port: "almoçar (vi)" },
      { ru: "обещать", port: "prometer (vt)" },
      { ru: "обижать", port: "ofender (vt)" },
      { ru: "облегчить (жизнь)", port: "facilitar (vt)" },
      { ru: "обманывать", port: "enganar (vt)" },
      { ru: "обмениваться (чем-л.)", port: "trocar (vt)" },
      { ru: "обнимать", port: "abraçar (vt)" },
      { ru: "оборудовать", port: "equipar (vt)" },
      { ru: "образовывать", port: "formar (vt)" },
      { ru: "обращаться (к кому-л.)", port: "dirigir-se (vp)" },
      { ru: "обслуживать (в баре и т.п.)", port: "servir (vt)" },
      { ru: "обсуждать", port: "discutir (vt)" },
      { ru: "обучать", port: "ensinar (vt)" },
      { ru: "объединять", port: "juntar, unir (vt)" },
      { ru: "объяснять", port: "explicar (vt)" },
      { ru: "ограничивать", port: "limitar (vt)" },
      { ru: "ожидать (рассчитывать)", port: "esperar (vt)" },
      { ru: "означать", port: "significar (vt)" },
      { ru: "опускать (напр. ~ фразу)", port: "omitir (vt)" },
      { ru: "опускать (ниже)", port: "baixar (vt)" },
      { ru: "освещать (светом)", port: "iluminar (vt)" },
      { ru: "освобождать (город)", port: "libertar (vt)" },
      { ru: "оскорблять", port: "insultar (vt)" },
      { ru: "ослеплять (светом)", port: "cegar, ofuscar (vt)" },
      { ru: "осложнить", port: "complicar (vt)" },
      { ru: "осмеливаться", port: "ousar (vt)" },
      { ru: "оставлять (забыть)", port: "deixar (vt)" },
      { ru: "останавливаться", port: "parar (vi)" },
      { ru: "осуществлять", port: "realizar (vt)" },
      { ru: "отвечать", port: "responder (vt)" },
      { ru: "отворачиваться", port: "virar as costas" },
      { ru: "отвязывать", port: "desatar (vt)" },
    ],
  },
  {
    name: "Глаголы П",
    dict: [
      { ru: "пахнуть", port: "cheirar (vi)" },
      { ru: "перевернуть (напр. камень)", port: "virar (vt)" },
      { ru: "переводить (текст)", port: "traduzir (vt)" },
      { ru: "передвигать (в другое место)", port: "mover (vt)" },
      { ru: "переделывать", port: "refazer (vt)" },
      { ru: "переоценивать", port: "sobrestimar (vt)" },
      { ru: "писать (письмо и т.п.)", port: "escrever (vt)" },
      { ru: "пить", port: "beber, tomar (vt)" },
      { ru: "плавать", port: "nadar (vi)" },
      { ru: "плакать", port: "chorar (vi)" },
      { ru: "планировать", port: "planear (vt)" },
      { ru: "платить", port: "pagar (vt)" },
      { ru: "плевать", port: "cuspir (vi)" },
      { ru: "поворачивать (~ направо)", port: "virar (vi)" },
      { ru: "повторять", port: "repetir (vt)" },
      { ru: "подготовить", port: "preparar (vt)" },
      { ru: "поддержать (предложение)", port: "apoiar (vt)" },
      { ru: "подозревать", port: "suspeitar (vt)" },
      { ru: "подозревать", port: "suspeitar (vt)" },
      { ru: "подписывать", port: "assinar (vt)" },
      { ru: "подслушивать", port: "escutar atrás da porta" },
      { ru: "подсматривать", port: "espreitar (vi)" },
      { ru: "подходить (быть впору)", port: "servir (vi)" },
      { ru: "подходить (приближаться)", port: "aproximar-se (vt)" },
      { ru: "подчеркнуть", port: "sublinhar (vt)" },
      { ru: "подчиняться", port: "obedecer (vt)" },
      { ru: "позвать", port: "chamar (vt)" },
      { ru: "позволять", port: "permitir (vt)" },
      { ru: "поздравлять", port: "felicitar (vt)" },
      { ru: "показывать", port: "mostrar (vt)" },
      { ru: "покупать", port: "comprar (vt)" },
      { ru: "поливать (цветы)", port: "regar (vt)" },
      { ru: "пользоваться (чем-л.)", port: "utilizar (vt)" },
      { ru: "пометить (записать)", port: "anotar (vt)" },
      { ru: "помнить", port: "lembrar (vt)" },
      { ru: "помогать", port: "ajudar (vt)" },
      { ru: "понимать", port: "compreender (vt)" },
      { ru: "попытаться", port: "tentar (vt)" },
      { ru: "посвящать (напр. книгу)", port: "dedicar (vt)" },
      { ru: "появляться", port: "aparecer (vi)" },
      { ru: "предвидеть (ожидать)", port: "prever (vt)" },
      { ru: "предлагать", port: "propor (vt)" },
      { ru: "предполагать", port: "supor (vt)" },
      { ru: "предпочитать", port: "preferir (vt)" },
      { ru: "предпринимать", port: "empreender (vt)" },
      { ru: "представлять (кого-л.)", port: "apresentar (vt)" },
      { ru: "представлять себе", port: "imaginar (vt)" },
      { ru: "предупреждать", port: "advertir (vt)" },
      { ru: "презирать", port: "desprezar (vt)" },
      { ru: "прекращать", port: "cessar (vt)" },
      { ru: "преобладать", port: "predominar (vi, vt)" },
      { ru: "прибывать", port: "chegar (vi)" },
      { ru: "приветствовать", port: "saudar (vt)" },
      { ru: "приводить в порядок", port: "arranjar (vt)" },
      { ru: "привозить", port: "trazer (vt)" },
      { ru: "привязывать", port: "atar (vt)" },
      { ru: "приглашать", port: "convidar (vt)" },
      { ru: "приговаривать (о судье)", port: "sentenciar (vt)" },
      { ru: "признавать (ошибку)", port: "reconhecer (vt)" },
      { ru: "признаваться", port: "confessar-se (vp)" },
      { ru: "приказывать", port: "ordenar (vt)" },
      { ru: "принадлежать (кому-л.)", port: "pertencer (vt)" },
      { ru: "принуждать", port: "forçar (vt)" },
      { ru: "присоединяться", port: "juntar-se a …" },
      { ru: "причаливать", port: "atracar (vi)" },
      { ru: "причёсываться", port: "pentear-se (vp)" },
      { ru: "провоцировать", port: "provocar (vt)" },
      { ru: "прогнать (кого-л.)", port: "afugentar (vt)" },
      { ru: "продавать", port: "vender (vt)" },
      { ru: "продвигаться (о работе)", port: "avançar (vi)" },
      { ru: "продолжать", port: "continuar (vt)" },
      { ru: "проезжать (миновать)", port: "passar (vt)" },
      { ru: "проектировать", port: "projetar, criar (vt)" },
      { ru: "произносить (слово)", port: "pronunciar (vt)" },
      { ru: "пролить", port: "derramar (vt)" },
      { ru: "проникать", port: "penetrar (vt)" },
      { ru: "пропускать (занятия)", port: "faltar a …" },
      { ru: "просить", port: "pedir (vt)" },
      { ru: "просыпаться (напр. на пол)", port: "derramar-se (vp)" },
      { ru: "протестовать", port: "protestar (vi)" },
      { ru: "прощать", port: "perdoar (vt)" },
      { ru: "прятать", port: "esconder (vt)" },
      { ru: "путать (ошибаться)", port: "confundir (vt)" },
      { ru: "пытаться", port: "tentar (vt)" },
    ],
  },
  {
    name: "Глаголы Р-Т",
    dict: [
      { ru: "работать", port: "trabalhar (vi)" },
      { ru: "развлекать", port: "divertir (vt)" },
      { ru: "раздавить (~ насекомое)", port: "esmagar (vt)" },
      { ru: "раздать (напр. листовки)", port: "distribuir (vt)" },
      { ru: "раздражать", port: "irritar (vt)" },
      { ru: "раздражаться", port: "irritar-se (vp)" },
      { ru: "размножить", port: "tirar cópias" },
      { ru: "разорваться (о верёвке)", port: "romper-se (vp)" },
      { ru: "разрешать (позволять)", port: "permitir (vt)" },
      { ru: "располагать (размещать)", port: "pôr, colocar (vt)" },
      { ru: "распространять (~ листовки)", port: "distribuir (vt)" },
      { ru: "распространять (запах)", port: "emitir (vt)" },
      { ru: "рассказывать", port: "contar (vt)" },
      { ru: "рассмотреть (~ вопрос)", port: "examinar (vt)" },
      { ru: "рассчитывать на …", port: "contar com …" },
      { ru: "растить (растения)", port: "cultivar (vt)" },
      { ru: "рвать (напр. цветы)", port: "colher (vt)" },
      { ru: "рекламировать", port: "publicitar (vt)" },
      { ru: "рекламировать", port: "publicitar (vt)" },
      { ru: "рекомендовать", port: "recomendar (vt)" },
      { ru: "решать (принимать решение)", port: "decidir (vt)" },
      { ru: "решить (задачу)", port: "resolver (vt)" },
      { ru: "рисковать", port: "arriscar (vt)" },
      { ru: "ронять", port: "deixar cair (vt)" },
      { ru: "ругать", port: "repreender (vt)" },
      { ru: "руководить (чем-л.)", port: "dirigir (vt)" },
      { ru: "рыть, копать", port: "cavar (vt)" },
      { ru: "светиться (блестеть)", port: "brilhar (vi)" },
      { ru: "связывать", port: "amarrar (vt)" },
      { ru: "сердить", port: "zangar (vt)" },
      { ru: "сердиться (на …)", port: "zangar-se com …" },
      { ru: "сесть", port: "sentar-se (vp)" },
      { ru: "сеять", port: "semear (vt)" },
      { ru: "сидеть", port: "estar sentado" },
      { ru: "сказать", port: "dizer (vt)" },
      { ru: "скучать", port: "aborrecer-se (vp)" },
      { ru: "следовать за …", port: "seguir (vt)" },
      { ru: "слушать", port: "escutar (vt)" },
      { ru: "слышать", port: "ouvir (vt)" },
      { ru: "смешивать", port: "misturar (vt)" },
      { ru: "смеяться", port: "rir (vi)" },
      { ru: "смотреть", port: "olhar (vt)" },
      { ru: "снимать (~ квартиру)", port: "alugar (vt)" },
      { ru: "снимать (убирать)", port: "tirar (vt)" },
      { ru: "советовать", port: "aconselhar (vt)" },
      { ru: "соглашаться", port: "concordar (vt)" },
      { ru: "сожалеть", port: "lamentar (vt)" },
      { ru: "создать", port: "criar (vt)" },
      { ru: "сомневаться", port: "duvidar de …" },
      { ru: "сообщать (~ новость)", port: "informar (vt)" },
      { ru: "сопровождать", port: "acompanhar (vt)" },
      { ru: "составлять (~ список)", port: "fazer, elaborar (vt)" },
      { ru: "сотрудничать", port: "cooperar (vi)" },
      { ru: "сохраниться", port: "ser preservado" },
      { ru: "сохранять (~ мир)", port: "preservar (vt)" },
      { ru: "сочинить (музыку)", port: "compor (vt)" },
      { ru: "спасать", port: "salvar (vt)" },
      { ru: "спускаться", port: "descer (vi)" },
      { ru: "сравнивать", port: "comparar (vt)" },
      { ru: "сражаться", port: "combater (vi)" },
      { ru: "становиться (сделаться)", port: "tornar-se (vp)" },
      { ru: "стереть (~ надпись)", port: "apagar (vt)" },
      { ru: "стирать (бельё)", port: "lavar a roupa" },
      { ru: "стоить", port: "custar (vt)" },
      { ru: "страдать", port: "sofrer (vt)" },
      { ru: "стрелять", port: "atirar (vi)" },
      { ru: "стремиться (желать)", port: "aspirar a …" },
      { ru: "стучать (в дверь)", port: "bater (vi)" },
      { ru: "сушить", port: "secar (vt)" },
      { ru: "существовать", port: "existir (vi)" },
      { ru: "считать (~ деньги)", port: "calcular (vt)" },
      { ru: "считать (думать)", port: "achar (vt)" },
      { ru: "терпеть (боль и т.п.)", port: "suportar (vt)" },
      { ru: "терять", port: "perder (vt)" },
      { ru: "толкать", port: "empurrar (vt)" },
      { ru: "тонуть (о человеке)", port: "afogar-se (vp)" },
      { ru: "торопить (кого-л.)", port: "apressar (vt)" },
      { ru: "торопиться", port: "estar com pressa" },
      { ru: "трансформировать", port: "transformar (vt)" },
      { ru: "требовать", port: "exigir (vt)" },
      { ru: "требоваться", port: "ser necessário" },
      { ru: "требоваться (быть необходимым)", port: "ser preciso" },
      { ru: "тренировать  ", port: "treinar (vt)" },
      { ru: "тренироваться", port: "treinar-se (vp)" },
      { ru: "трескаться", port: "rachar-se (vp)" },
      { ru: "трясти", port: "agitar, sacudir (vt)" },
      { ru: "тушить (пожар)", port: "apagar (vt)" },
      { ru: "тушить (свет)", port: "desligar (vt)" },
      { ru: "тянуть (верёвку)", port: "puxar (vt)" },
    ],
  },
  {
    name: "Глаголы У-Я",
    dict: [
      { ru: "убеждать", port: "convencer (vt)" },
      { ru: "убивать", port: "matar (vt)" },
      { ru: "убирать (наводить порядок)", port: "arrumar, limpar (vt)" },
      { ru: "убирать (спрятать)", port: "arrumar, guardar (vt)" },
      { ru: "увеличивать", port: "aumentar (vt)" },
      { ru: "увеличиваться", port: "aumentar (vi)" },
      { ru: "увидеть (заметить)", port: "avistar (vt)" },
      { ru: "угрожать", port: "ameaçar (vt)" },
      { ru: "удалять (пятно и т.п.)", port: "remover (vt)" },
      { ru: "удваивать", port: "dobrar (vt)" },
      { ru: "удерживать (~ от чего-л.)", port: "refrear (vt)" },
      { ru: "удивлять", port: "surpreender (vt)" },
      { ru: "удивляться", port: "surpreender-se (vp)" },
      { ru: "удовлетворять", port: "satisfazer (vt)" },
      { ru: "уезжать", port: "partir (vt)" },
      { ru: "ужинать", port: "jantar (vi)" },
      { ru: "узнавать (адрес и т.п.)", port: "informar-se (vt)" },
      { ru: "узнавать (кого-л.)", port: "reconhecer (vt)" },
      { ru: "узнавать (кого-л.)", port: "reconhecer (vt)" },
      { ru: "указать (направление)", port: "indicar (vt)" },
      { ru: "украшать (дом и т.п.)", port: "decorar (vt)" },
      { ru: "укреплять (положение)", port: "reforçar (vt)" },
      { ru: "улаживать", port: "resolver (vt)" },
      { ru: "улыбаться", port: "sorrir (vi)" },
      { ru: "уменьшать", port: "reduzir (vt)" },
      { ru: "умножать (матем.)", port: "multiplicar (vt)" },
      { ru: "умолять (просить)", port: "implorar (vt)" },
      { ru: "уничтожать (~ улики)", port: "destruir (vt)" },
      { ru: "уносить", port: "levar (vt)" },
      { ru: "упоминать", port: "mencionar (vt)" },
      { ru: "употребить", port: "usar (vt)" },
      { ru: "упрекать", port: "censurar (vt)" },
      { ru: "упрощать", port: "simplificar (vt)" },
      { ru: "успокаивать (унять)", port: "acalmar (vt)" },
      { ru: "уставать", port: "ficar cansado" },
      { ru: "устраивать (~ вечеринку)", port: "organizar (vt)" },
      { ru: "устранять (препятствие)", port: "remover, eliminar (vt)" },
      { ru: "уступать (в чём-л.)", port: "ceder (vi)" },
      { ru: "утверждать (настаивать)", port: "afirmar (vt)" },
      { ru: "утомлять", port: "fatigar (vt)" },
      { ru: "участвовать", port: "participar (vi)" },
      { ru: "фотографировать", port: "fotografar (vt)" },
      { ru: "хвастаться", port: "gabar-se (vp)" },
      { ru: "хватать (быть достаточным)", port: "bastar (vi)" },
      { ru: "хотеть", port: "querer (vt)" },
      { ru: "хранить (старые письма)", port: "guardar (vt)" },
      { ru: "царапать", port: "arranhar (vt)" },
      { ru: "целиться (в …)", port: "apontar para …" },
      { ru: "цитировать", port: "citar (vt)" },
      { ru: "чистить", port: "limpar (vt)" },
      { ru: "читать", port: "ler (vt)" },
      { ru: "чувствовать (опасность)", port: "sentir (vt)" },
      { ru: "шутить", port: "fazer piadas" },
    ],
  },
  {
    name: "Неделя - 1",
    dict: [
      { ru: "убеждать", port: "convencer (vt)" },
      { ru: "с воскресенья", port: "a partir de domingo" },
      { ru: "иголка", port: "agulha" },
      { ru: "среда", port: "ambiente" },
      { ru: "только", port: "apenas" },
      { ru: "Бьюсь об заклад", port: "aposto" },
      { ru: "оболочка", port: "bainha" },
      { ru: "краб", port: "caranguejo" },
      { ru: "ревнивый", port: "ciumento" },
      { ru: "Подтверждать", port: "confirmar" },
      { ru: "отремонтировать", port: "consertar" },
      { ru: "убежденный", port: "convencido" },
      { ru: "хор", port: "coro" },
      { ru: "шить", port: "coser" },
      { ru: "шитье", port: "costura" },
      { ru: "портниха", port: "costureira" },
      { ru: "лечить", port: "curar" },
      { ru: "нечестный", port: "desonesto" },
      { ru: "эмоциональный", port: "emotivo" },
      { ru: "директор", port: "encenador" },
      { ru: "железо", port: "engomar" },
      { ru: "прилежный", port: "estudioso" },
      { ru: "очаровывать", port: "fascinar" },
      { ru: "верный", port: "fiel" },
      { ru: "налоги", port: "impostos" },
      { ru: "непредсказуемый", port: "imprevisivel" },
      { ru: "непредсказуемый", port: "imprevisível" },
      { ru: "беспокойный", port: "inquieto" },
      { ru: "нестабильный", port: "instavel" },
      { ru: "нестабильный", port: "instável" },
      { ru: "линия", port: "linha" },
      { ru: "скоро", port: "logo" },
      { ru: "боец", port: "lutador" },
      { ru: "половина", port: "metade" },
      { ru: "тонет", port: "naufraga" },
      { ru: "туман", port: "nevoeiro" },
      { ru: "однако", port: "no entanto" },
      { ru: "появление ливней", port: "ocorrencia de aguaceiros" },
      { ru: "этап", port: "palco" },
      { ru: "настойчивый", port: "persistente" },
      { ru: "полезный", port: "prestáveis" },
      { ru: "молния", port: "relâmpago" },
      { ru: "отремонтировать", port: "reparar" },
      { ru: "успешный", port: "sucedida" },
      { ru: "возникать", port: "surgirar" },
      { ru: "буря", port: "tempestade" },
      { ru: "землетрясение", port: "terramoto" },
      { ru: "гроза", port: "trovoada" },
      { ru: "гром", port: "trovões" },
      { ru: "тайфун", port: "tufão" },
    ],
  },
];
