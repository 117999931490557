import img_1 from "../images/ii_1.jpg";
import img_2 from "../images/ii_6.jpg";
import img_4 from "../images/ii_4.jpg";
import img_5 from "../images/ii_5.jpg";

export const menumain = [
  {
    name: "Учить слова",
    avatar: img_1,
    content: [
      {
        name: "Учить слова по темам",
        link: "/learn",
      },
      {
        name: "Карточки со словами",
        link: "/palavra/all",
      },

      {
        name: "Писать слова",
        link: "/write",
      },
      {
        name: "Искать слова",
        link: "/feed",
      },
      {
        name: "Учить слова (А2)",
        link: "/palavraaone",
      },
      {
        name: "Учить слова (В1)",
        link: "/palavrabone",
      },
    ],
  },
  {
    name: "Спрягать глаголы",
    avatar: img_2,
    content: [
      {
        name: "Presente do indicativo - учить",
        link: "/presente",
      },
      {
        name: "Presente do indicativo - писать",
        link: "/preswrite",
      },
      {
        name: "Pretérito perfeito simples do indicativo - учить",
        link: "/perfeito",
      },
      {
        name: "Pretérito perfeito simples do indicativo - писать",
        link: "/prefwrite",
      },
      {
        name: "Pretérito imperfeito do indicativo - учить",
        link: "/imperfeito",
      },
      {
        name: " Pretérito imperfeito do indicativo - писать",
        link: "/imperfeitowriter",
      },
    ],
  },
  {
    name: "Учить глаголы",
    avatar: img_5,
    content: [
      {
        name: "500 наиболее употребимых глаголов",
        link: "/verbo",
      },
      {
        name: "Писать глаголы в предложениях",
        link: "/predglag",
      },
    ],
  },
  {
    name: "Еще много интересного",
    avatar: img_4,
    content: [
      {
        name: "Учить фразы",
        link: "/fraz",
      },
      {
        name: "Карточки с фразами",
        link: "/cardfraz",
      },

      {
        name: "Переводить предложения",
        link: "/predall",
      },

      {
        name: "Составлять предложения",
        link: "/predconst",
      },

      /* {
        name: "Составлять предложения (сложно)",
        link: "/predconsthard",
      },*/
    ],
  },
];

export const textinfo =
  "Писать слова можно двумя способами: 1. Английскими буквами без учета португальских орфографических знаков, 2. Можно писать с  использованием португальской клавиатуры и дополнительных знаков";
