import { useEffect, useRef, useState } from "react";
import styles from "./sentensall.module.css";
import { convert, convertpt } from "../../utils/convert";
import dog from "../../images/smile.png";
import Speak from "../speak/speak";
type Twww = {
  port: string;
  rus: string;
};

type TOrderypeprops = {
  slovo: Twww[];
};

function SentensAll(props: TOrderypeprops) {
  const list = props.slovo;
  const button_2 = useRef<HTMLButtonElement | null>(null);
  const inp_2 = useRef<HTMLInputElement | null>(null);
  const [slovo, setSlovo] = useState(list[0]);
  const [sentense, setSentense] = useState(divid(list[0]));
  const [sentensept, setSentensept] = useState(dividpt(list[0]));
  const [sentensep, setSentensep] = useState(divid_p(list[0]));
  const [sentenser, setSentenser] = useState(list[0].rus);
  const [counter, setCounter] = useState(1);
  const [word, setWord] = useState(false);
  const [wordn, setWordn] = useState("");
  const [ok, setok] = useState("");
  const [okfim, setokfim] = useState(false);

  useEffect(() => {
    if (inp_2.current !== null) {
      inp_2.current.focus();
    }
  }, []);

  useEffect(() => {
    setSlovo(list[counter]);
    setSentense(divid(list[counter]));
    setSentensept(dividpt(list[counter]));
    setSentensep(divid_p(list[counter]));
    setSentenser(list[counter].rus);
    setok("");
    setWord(false);
    setokfim(false);
    setWordn("");

    if (inp_2.current !== null) {
      inp_2.current.value = "";
      inp_2.current.disabled = false;
      inp_2.current.focus();
    }
    setCounter(counter + 1);
  }, [props]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
    }
  };

  const onClick = () => {
    if (sentenser === slovo.rus) {
      let alln = "";
      sentensep.map((item) => {
        alln = alln + " " + item;
      });
      setSentenser(alln);
    } else {
      setSentenser(slovo.rus);
    }
  };

  function divid(slovo: Twww) {
    const all_4 = slovo.port.split(" ");
    const all: string[] = [];

    all_4.map((item) => {
      if (item) {
        if (item !== ".") {
          all.push(convert(item));
        }
      }
    });
    return all;
  }

  function dividpt(slovo: Twww) {
    const all_4 = slovo.port.split(" ");
    const all: string[] = [];

    all_4.map((item) => {
      if (item) {
        if (item !== ".") {
          all.push(convertpt(item));
        }
      }
    });
    return all;
  }

  function divid_p(slovo: Twww) {
    const all_4 = slovo.port.split(" ");
    const all: string[] = [];

    all_4.map((item) => {
      if (item) {
        all.push(item);
      }
    });
    return all;
  }

  const buttonHandler_1 = () => {
    setSlovo(list[counter]);
    setSentense(divid(list[counter]));
    setSentensept(dividpt(list[counter]));
    setSentensep(divid_p(list[counter]));
    setSentenser(list[counter].rus);
    setok("");
    setokfim(false);
    setWordn("");
    setWord(false);
    if (inp_2.current !== null) {
      inp_2.current.value = "";
      inp_2.current.disabled = false;
      inp_2.current.focus();
    }
    if (counter === list.length - 1) {
      setCounter(0);
    } else {
      setCounter(counter + 1);
    }
  };
  //****************************************************************************** */

  const setValueWord = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const slovow = convert(evt.target.value.toLowerCase().trim()).split(" ");
    //console.log(sentense);
    //console.log(slovow);
    let i = 0;
    let sentens_p = "";
    if (!slovow) {
      setok("");
      setokfim(false);
      setWordn("");
      setWord(false);
    } else {
      slovow.map((item) => {
        const slovo_i = item;
        const slovo_ip = sentense[i];
        const slovo_ipt = sentensept[i];
        if (item) {
          if (
            slovo_ip.slice(0, slovo_i.length) === slovo_i ||
            slovo_ipt.slice(0, slovo_i.length) === slovo_i
          ) {
            setok("Правильно!");
            setokfim(false);
            setWordn(`${sentens_p + " " + slovo_i}`);
            setWord(true);
          } else {
            setok("Было правильно до ");
            setokfim(false);
            setWordn(`${sentens_p + " " + slovo_i}`);
            setWord(false);
          }
          sentens_p = sentens_p + " " + sentensep[i];
          i = i + 1;
          if (i === sentense.length) {
            if (slovo_i === slovo_ip || slovo_i === slovo_ipt) {
              setokfim(true);
              setok("Да, это правильно: ");
              setWordn(`${sentens_p}`);
              setWord(true);
              evt.target.setAttribute("disabled", "disabled");
              if (button_2.current !== null) {
                button_2.current.focus();
              }
            }
          }
        }
      });
    }
    if (evt.target.value.length < 4) {
      evt.target.size = 1;
    } else {
      evt.target.size = Math.max(evt.target.value.length - 3, 1);
    }
  };
  //****************************************************************************** */
  return (
    <div className={styles.main}>
      <div className={styles.p_1}>
        <input
          className={`${styles.menuButton} `}
          type="text"
          placeholder="пишите"
          onInput={setValueWord}
          ref={inp_2}
          id="write"
          onKeyPress={handleKeyPress}
        ></input>
        {okfim === true && (
          <div className={styles.preview}>
            <img src={dog} alt="dog" />
          </div>
        )}
      </div>
      {word === true && (
        <div className={styles.p_1}>
          <p className={`${styles.menuButton_5} ${styles.menuButton}`}>{ok}</p>
          <p className={`${styles.menuButton_5} ${styles.menuButton}`}>
            {wordn}
          </p>
        </div>
      )}
      {word === false && (
        <div className={styles.p_1}>
          <p className={`${styles.menuButton_4} ${styles.menuButton}`}>{ok}</p>
          <p className={`${styles.menuButton_4} ${styles.menuButton}`}>
            {wordn}
          </p>
        </div>
      )}
      <div className={styles.p_1}>
        <div
          className={`${styles.menuButton_3} ${styles.menuButton} `}
          onClick={onClick}
        >
          {sentenser}
        </div>
      </div>
      <button
        className={styles.but}
        onClick={() => buttonHandler_1()}
        ref={button_2}
      >
        еще...
      </button>
      <Speak text={slovo.port}></Speak>
      <div className={styles.p_2}>
        <div className={`${styles.menuButton_1} ${styles.menuButton} `}>
          Не забывай, что каждое предложение заканчивается знаком препинания.
        </div>
        <div className={`${styles.menuButton_1} ${styles.menuButton} `}>
          Можно увидеть подсказку кликнув на перевод.
        </div>
      </div>
    </div>
  );
}

export default SentensAll;
/*<button className={styles.button} ref={button_2} onClick={OnClick}>
еще...
</button>*/
