export const verbP = [
  {
    ru: "глаголы -ar",
    port: "...ar",
    eu: "-ei",
    tu: "-aste",
    ele: "-ou",
    nos: "-ámos",
    eles: "-aram",
    spr: "1",
  },
  {
    ru: "глаголы -er",
    port: "...er",
    eu: "-i",
    tu: "-este",
    ele: "-eu",
    nos: "-emos",
    eles: "-eram",
    spr: "2",
  },
  {
    ru: "глаголы -ir",
    port: "...ir",
    eu: "-i",
    tu: "-iste",
    ele: "-iu",
    nos: "-imos",
    eles: "-iram",
    spr: "3",
  },
  {
    ru: "не правильные",
    port: " ",
    eu: " ",
    tu: "",
    ele: " ",
    nos: " ",
    eles: " ",
    spr: "4",
  },
];

export const verb_p = [
  {
    ru: "открывать",
    port: "abrir",
    eu: "abri",
    tu: "abriste",
    ele: "abriu",
    nos: "abrimos",
    eles: "abriram",
    spr: "3",
  },
  {
    ru: "заканчивать",
    port: "acabar",
    eu: "acabei",
    tu: "acabaste",
    ele: "acabou",
    nos: "acabámos",
    eles: "acabaram",
    spr: "1",
  },
  {
    ru: "принимать",
    port: "aceitar",
    eu: "aceitei",
    tu: "aceitaste",
    ele: "aceitou",
    nos: "aceitámos",
    eles: "aceitaram",
    spr: "1",
  },
  {
    ru: "зажигать",
    port: "acender",
    eu: "acendi",
    tu: "acendeste",
    ele: "acendeu",
    nos: "acendemos",
    eles: "acenderam",
    spr: "2",
  },
  {
    ru: "думать",
    port: "achar",
    eu: "achei",
    tu: "achaste",
    ele: "achaou",
    nos: "achámos",
    eles: "acharam",
    spr: "1",
  },
  {
    ru: "сопровождать",
    port: "acompanhar",
    eu: "acompanhei ",
    tu: "acompanhaste",
    ele: "acompanhou",
    nos: "acompanhámos",
    eles: "acompanharam",
    spr: "1",
  },
  {
    ru: "просыпаться",
    port: "acordar",
    eu: "acordaei",
    tu: "acordaste",
    ele: "acordou",
    nos: "acordámos",
    eles: "acordaram",
    spr: "1",
  },
  {
    ru: "обожать",
    port: "adorar",
    eu: "adorei",
    tu: "adoraste",
    ele: "adorou",
    nos: "adorámos",
    eles: "adoraram",
    spr: "1",
  },
  {
    ru: "приобретать",
    port: "adquirir",
    eu: "adquiri",
    tu: "adquiriste",
    ele: "adquiriu",
    nos: "adquirimos",
    eles: "adquiriram",
    spr: "3",
  },
  {
    ru: "ждать",
    port: "aguardar",
    eu: "aguardei",
    tu: "aguardaste",
    ele: "aguardou",
    nos: "aguardámos",
    eles: "aguardaram",
    spr: "1",
  },
  {
    ru: "помогать",
    port: "ajudar",
    eu: "ajudei",
    tu: "ajudaste",
    ele: "ajudou",
    nos: "ajudámos",
    eles: "ajudaram",
    spr: "1",
  },
  {
    ru: "кормить",
    port: "alimentar",
    eu: "alimentei",
    tu: "alimentaste",
    ele: "alimentou",
    nos: "alimentámos",
    eles: "alimentaram",
    spr: "1",
  },
  {
    ru: "обедать",
    port: "almoçar",
    eu: "almocei",
    tu: "almoçaste",
    ele: "almoçou",
    nos: "almoçámos",
    eles: "almoçaram",
    spr: "1",
  },
  {
    ru: "арендовать, снимать",
    port: "alugar",
    eu: "aluguei",
    tu: "alugaste",
    ele: "alugou",
    nos: "alugámos",
    eles: "alugaram",
    spr: "1",
  },
  {
    ru: "любить",
    port: "amar",
    eu: "amei",
    tu: "amaste",
    ele: "amou",
    nos: "amámos",
    eles: "amaram",
    spr: "1",
  },
  {
    ru: "ходить",
    port: "andar",
    eu: "andei",
    tu: "andaste",
    ele: "andou",
    nos: "andámos",
    eles: "andaram",
    spr: "1",
  },
  {
    ru: "брать, ловить",
    port: "apanhar",
    eu: "apanhei",
    tu: "apanhaste",
    ele: "apanhou",
    nos: "apanhámos",
    eles: "apanharam",
    spr: "1",
  },
  {
    ru: "появиться",
    port: "aparecer",
    eu: "apareci",
    tu: "apareceste",
    ele: "apareceu",
    nos: "aparecemos",
    eles: "apareceram",
    spr: "2",
  },
  {
    ru: "учить навык",
    port: "aprender",
    eu: "aprendi",
    tu: "aprendeste",
    ele: "aprendeu",
    nos: "aprendemos",
    eles: "aprenderam",
    spr: "2",
  },
  {
    ru: "насладжаться, прользоваться",
    port: "aproveitar",
    eu: "aproveitei",
    tu: "aproveitaste",
    ele: "aproveitou",
    nos: "aproveitámos",
    eles: "aproveitaram",
    spr: "1",
  },
  {
    ru: "нагреваться",
    port: "aquecer",
    eu: "aqueci",
    tu: "aqueceste",
    ele: "aqueceu",
    nos: "aquecemos",
    eles: "aqueceram",
    spr: "2",
  },
  {
    ru: "раздобыть",
    port: "arranjar",
    eu: "arranjei",
    tu: "arranjaste",
    ele: "arranjou",
    nos: "arranjámos",
    eles: "arranjaram",
    spr: "1",
  },
  {
    ru: "аррендовать",
    port: "arrendar",
    eu: "arrendei",
    tu: "arrendaste",
    ele: "arrendou",
    nos: "arrendámos",
    eles: "arrendaram",
    spr: "1",
  },
  {
    ru: "убирать",
    port: "arrumar",
    eu: "arrumei",
    tu: "arrumaste",
    ele: "arrumou",
    nos: "arrumános",
    eles: "arrumaram",
    spr: "1",
  },
  {
    ru: "присутствовать",
    port: "assistir",
    eu: "assisti",
    tu: "assististe ",
    ele: "assistiu",
    nos: "assistimos",
    eles: "assistiram",
    spr: "3",
  },
  {
    ru: "принимать",
    port: "assumir",
    eu: "assumi",
    tu: "assumiste",
    ele: "assumiu",
    nos: "assumimos",
    eles: "assumiram",
    spr: "3",
  },
  {
    ru: "отвечать",
    port: "atender",
    eu: "atendi",
    tu: "atendeste",
    ele: "atendeu",
    nos: "atendemos",
    eles: "atenderam",
    spr: "2",
  },
  {
    ru: "переходить",
    port: "atravessar",
    eu: "atravessei",
    tu: "atravessaste",
    ele: "atravessou",
    nos: "atravessámos",
    eles: "atravessaram",
    spr: "1",
  },
  {
    ru: "бить",
    port: "bater",
    eu: "bati",
    tu: "bateste",
    ele: "bateu",
    nos: "batemos",
    eles: "bateram",
    spr: "2",
  },
  {
    ru: "пить",
    port: "beber",
    eu: "bebi",
    tu: "bebeste",
    ele: "bebeu",
    nos: "bebemos",
    eles: "beberam",
    spr: "2",
  },
  {
    ru: "играть дети",
    port: "brincar",
    eu: "brinquei",
    tu: "brincaste",
    ele: "brincou",
    nos: "brincámos",
    eles: "brincaram",
    spr: "1",
  },
  {
    ru: "искать",
    port: "buscar",
    eu: "busquei",
    tu: "buscaste",
    ele: "buscou",
    nos: "buscámos",
    eles: "buscaram",
    spr: "1",
  },
  {
    ru: "падать",
    port: "cair",
    eu: "cai",
    tu: "caíste",
    ele: "caiu",
    nos: "caímos",
    eles: "caíram",
    spr: "4",
  },
  {
    ru: "молчать",
    port: "calar",
    eu: "calei",
    tu: "calaste",
    ele: "calou",
    nos: "calámos",
    eles: "calaram",
    spr: "1",
  },
  {
    ru: "обуваться",
    port: "calçar",
    eu: "calcei",
    tu: "calçaste",
    ele: "calçou",
    nos: "calçámos",
    eles: "calçaram",
    spr: "1",
  },
  {
    ru: "петь",
    port: "cantar",
    eu: "cantei",
    tu: "cantaste",
    ele: "cantou",
    nos: "cantámos",
    eles: "cantaram",
    spr: "1",
  },
  {
    ru: "жениться",
    port: "casar",
    eu: "casei",
    tu: "casaste",
    ele: "casou",
    nos: "casámos",
    eles: "casaram",
    spr: "1",
  },
  {
    ru: "звать",
    port: "chamar",
    eu: "chamei",
    tu: "chamaste",
    ele: "chamou",
    nos: "chamámos",
    eles: "chamaram",
    spr: "1",
  },
  {
    ru: "прибывать, приезжать",
    port: "chegar",
    eu: "cheguei",
    tu: "chegaste",
    ele: "chegou",
    nos: "chegamos",
    eles: "chegaram",
    spr: "1",
  },
  {
    ru: "плакать",
    port: "chorar",
    eu: "chorei",
    tu: "choraste",
    ele: "chorou",
    nos: "chorámos",
    eles: "choraram",
    spr: "1",
  },
  {
    ru: "покрывать",
    port: "cobrir",
    eu: "cobri",
    tu: "cobriste",
    ele: "cobriu",
    nos: "cobrimos",
    eles: "cobriram",
    spr: "3",
  },
  {
    ru: "начинать",
    port: "começar",
    eu: "começei ",
    tu: "começaste",
    ele: "começou",
    nos: "começámos ",
    eles: "começaram",
    spr: "1",
  },
  {
    ru: "есть",
    port: "comer",
    eu: "comi",
    tu: "comeste",
    ele: "comeu",
    nos: "comemos",
    eles: "comeram",
    spr: "2",
  },
  {
    ru: "покупать",
    port: "comprar",
    eu: "comprei",
    tu: "compraste",
    ele: "comprou",
    nos: "comprámos",
    eles: "compraram",
    spr: "1",
  },
  {
    ru: "понимать",
    port: "compreender",
    eu: "compreendi",
    tu: "compreendeste",
    ele: "compreendeu",
    nos: "compreendemos",
    eles: "compreenderam",
    spr: "2",
  },
  {
    ru: "соглашаться",
    port: "concordar",
    eu: "concordei",
    tu: "concordaste",
    ele: "concordou",
    nos: "concordámos",
    eles: "concordaram",
    spr: "1",
  },
  {
    ru: "водить",
    port: "conduzir",
    eu: "conduzi",
    tu: "conduziste",
    ele: "conduziu",
    nos: "conduzimos",
    eles: "conduziram",
    spr: "3",
  },
  {
    ru: "знать",
    port: "conhecer",
    eu: "conheci",
    tu: "conheceste",
    ele: "conheceu",
    nos: "conhecemos",
    eles: "conheceram",
    spr: "2",
  },
  {
    ru: "мочь",
    port: "conseguir",
    eu: "consegui",
    tu: "conseguiste",
    ele: "conseguiu",
    nos: "conseguimos",
    eles: "conseguiram",
    spr: "3",
  },
  {
    ru: "считать, рассказывать",
    port: "contar",
    eu: "contei",
    tu: "contaste",
    ele: "contou",
    nos: "contámos",
    eles: "contaram",
    spr: "1",
  },
  {
    ru: "содержать",
    port: "conter",
    eu: "contive",
    tu: "contiveste",
    ele: "conteve",
    nos: "contivemos",
    eles: "contiveram",
    spr: "2",
  },
  {
    ru: "продолжать",
    port: "continuar",
    eu: "continuei",
    tu: "continuaste",
    ele: "continuou",
    nos: "continuámos",
    eles: "continuaram",
    spr: "1",
  },
  {
    ru: "общаться",
    port: "conversar",
    eu: "conversei",
    tu: "conversaste",
    ele: "conversou",
    nos: "conversámos",
    eles: "conversaram",
    spr: "1",
  },
  {
    ru: "приглашать",
    port: "convidar",
    eu: "convidei",
    tu: "convidaste",
    ele: "convidou",
    nos: "convidámos",
    eles: "convidaram",
    spr: "1",
  },
  {
    ru: "бежать",
    port: "correr",
    eu: "corri ",
    tu: "correste",
    ele: "correu",
    nos: "corremos",
    eles: "correram",
    spr: "2",
  },
  {
    ru: "обычно делать",
    port: "costumar",
    eu: "costumei",
    tu: "costumaste",
    ele: "costumou",
    nos: "costumámos",
    eles: "costumaram",
    spr: "1",
  },
  {
    ru: "готовить",
    port: "cozinhar",
    eu: "cozinhei",
    tu: "cozinhaste",
    ele: "cozinhou",
    nos: "cozinhámos",
    eles: "cozinharam",
    spr: "1",
  },
  {
    ru: "расти",
    port: "crescer",
    eu: "cresci",
    tu: "cresceste",
    ele: "cresceu",
    nos: "crescemos",
    eles: "cresceam",
    spr: "2",
  },
  {
    ru: "заботиться",
    port: "cuidar",
    eu: "cuidei",
    tu: "cuidaste",
    ele: "cuidou",
    nos: "cuidámos",
    eles: "cuidaram",
    spr: "1",
  },
  {
    ru: "выполнить",
    port: "cumprir",
    eu: "cumpri ",
    tu: "cumpriste",
    ele: "cumpriu",
    nos: "cumprimos",
    eles: "cumpriram",
    spr: "3",
  },
  {
    ru: "стоить",
    port: "custar",
    eu: "custei",
    tu: "custaste",
    ele: "custou",
    nos: "custámos",
    eles: "custaram",
    spr: "1",
  },
  {
    ru: "танцевать",
    port: "dançar",
    eu: "dancei",
    tu: "dançase",
    ele: "dançou",
    nos: "dançámos",
    eles: "dançaram",
    spr: "1",
  },
  {
    ru: "давать",
    port: "dar",
    eu: "dei",
    tu: "deste",
    ele: "deu",
    nos: "demos",
    eles: "deram",
    spr: "4",
  },
  {
    ru: "решать",
    port: "decidir",
    eu: "decidi",
    tu: "decidiste",
    ele: "decidiu",
    nos: "decidimos",
    eles: "decidiram",
    spr: "3",
  },
  {
    ru: "защищать",
    port: "defender",
    eu: "defendi",
    tu: "defendeste",
    ele: "defendeu",
    nos: "defendemos",
    eles: "defenderam",
    spr: "2",
  },
  {
    ru: "ложиться",
    port: "deitar",
    eu: "deitei ",
    tu: "deitaste",
    ele: "deitou",
    nos: "deitámos",
    eles: "deitaram",
    spr: "1",
  },
  {
    ru: "оставлять, уходить, покидать",
    port: "deixar",
    eu: "deixei",
    tu: "deixaste",
    ele: "deixou",
    nos: "deixámos",
    eles: "deixaram",
    spr: "1",
  },
  {
    ru: "продолжаться",
    port: "demorar",
    eu: "demorei",
    tu: "demoraste",
    ele: "demorou",
    nos: "demorámos",
    eles: "demoraram",
    spr: "1",
  },
  {
    ru: "зависеть",
    port: "depender",
    eu: "dependi",
    tu: "dependeste",
    ele: "dependeu",
    nos: "dependemos",
    eles: "dependeram",
    spr: "2",
  },
  {
    ru: "разуваться",
    port: "descalçar",
    eu: "descalcei",
    tu: "descalçaste",
    ele: "descalçou",
    nos: "descalçámos",
    eles: "descalçaram",
    spr: "1",
  },
  {
    ru: "отдыхать",
    port: "descansar",
    eu: "descansei",
    tu: "descansaste",
    ele: "descansou",
    nos: "descansámos",
    eles: "descansaram",
    spr: "1",
  },
  {
    ru: "спускаться",
    port: "descer",
    eu: "desci",
    tu: "desceste",
    ele: "desceu",
    nos: "descemos",
    eles: "desceram",
    spr: "2",
  },
  {
    ru: "находить",
    port: "descobrir",
    eu: "descobri",
    tu: "descobriste",
    ele: "descobriu",
    nos: "descobrimos",
    eles: "descobriram",
    spr: "3",
  },
  {
    ru: "извиняться",
    port: "desculpar",
    eu: "desculpie",
    tu: "desculpaste",
    ele: "desculpou",
    nos: "desculpamos",
    eles: "desculparam",
    spr: "1",
  },
  {
    ru: "желать",
    port: "desejar",
    eu: "desejei",
    tu: "desejaste",
    ele: "desejou",
    nos: "desejámos",
    eles: "desejaram",
    spr: "1",
  },
  {
    ru: "рисовать карандашом",
    port: "desenhar",
    eu: "desenhei",
    tu: "desenhaste",
    ele: "desenhou",
    nos: "desenhámos",
    eles: "desenharam",
    spr: "1",
  },
  {
    ru: "выключать",
    port: "desligar",
    eu: "desliguie",
    tu: "desligaste",
    ele: "desligou",
    nos: "desligámos",
    eles: "desligaram",
    spr: "1",
  },
  {
    ru: "снимать ",
    port: "despir",
    eu: "despi",
    tu: "despiste",
    ele: "despiu",
    nos: "despimos",
    eles: "despiram",
    spr: "3",
  },
  {
    ru: "должен",
    port: "dever",
    eu: "deveste",
    tu: "devi",
    ele: "deveu",
    nos: "devemos",
    eles: "deveram",
    spr: "2",
  },
  {
    ru: "обсуждать",
    port: "discutir",
    eu: "discuti",
    tu: "discutiste",
    ele: "discutiu",
    nos: "discutimos",
    eles: "discutiram",
    spr: "3",
  },
  {
    ru: "делить",
    port: "dividir",
    eu: "dividi",
    tu: "dividiste",
    ele: "dividiu",
    nos: "dividimos",
    eles: "dividiram",
    spr: "3",
  },
  {
    ru: "сказать",
    port: "dizer",
    eu: "disse",
    tu: "disseste",
    ele: "disse",
    nos: "dissemos",
    eles: "disseram",
    spr: "4",
  },
  {
    ru: "спать",
    port: "dormir",
    eu: "dormi",
    tu: "dormiste",
    ele: "dormiu",
    nos: "dormimos",
    eles: "dormiram",
    spr: "3",
  },
  {
    ru: "длиться",
    port: "durar",
    eu: "durei",
    tu: "duraste",
    ele: "durou",
    nos: "durámos",
    eles: "duraram",
    spr: "1",
  },
  {
    ru: "одалживать",
    port: "empresar",
    eu: "empresei",
    tu: "empresaste",
    ele: "empresou",
    nos: "empresámos",
    eles: "empresaram",
    spr: "1",
  },
  {
    ru: "наполнять",
    port: "encher",
    eu: "enchi",
    tu: "encheste",
    ele: "encheu",
    nos: "enchemos",
    eles: "encheram",
    spr: "2",
  },
  {
    ru: "находить, встречаться",
    port: "encontrar",
    eu: "encontrei",
    tu: "encontraste",
    ele: "encontrou",
    nos: "encontrámos",
    eles: "encontraram",
    spr: "1",
  },
  {
    ru: "учить",
    port: "ensinar",
    eu: "ensinei",
    tu: "ensinaste",
    ele: "ensinou",
    nos: "ensinámos",
    eles: "ensinaram",
    spr: "1",
  },
  {
    ru: "понимать",
    port: "entender",
    eu: "entendi",
    tu: "entendeste",
    ele: "entendeu",
    nos: "entendemos",
    eles: "entenderam",
    spr: "2",
  },
  {
    ru: "входить",
    port: "entrar",
    eu: "entrei",
    tu: "entraste",
    ele: "entrou",
    nos: "entrámos",
    eles: "entraram",
    spr: "1",
  },
  {
    ru: "доставлять",
    port: "entregar",
    eu: "entreguei",
    tu: "entregaste",
    ele: "entregou",
    nos: "entregámos",
    eles: "entregaram",
    spr: "1",
  },
  {
    ru: "послать",
    port: "enviar",
    eu: "enviei",
    tu: "enviaste",
    ele: "enviou",
    nos: "enviámos",
    eles: "enviaram",
    spr: "1",
  },
  {
    ru: "выбирать",
    port: "escolher",
    eu: "escolhi",
    tu: "escolheste",
    ele: "escolheu",
    nos: "escolhemos",
    eles: "escolheram",
    spr: "2",
  },
  {
    ru: "прятать",
    port: "esconder",
    eu: "escondi",
    tu: "escondeste",
    ele: "escondeu",
    nos: "escondemos",
    eles: "esconderam",
    spr: "2",
  },
  {
    ru: "расчесываться",
    port: "escovar",
    eu: "escovei",
    tu: "escovaste",
    ele: "escovou",
    nos: "escovámos",
    eles: "escovaram",
    spr: "1",
  },
  {
    ru: "писать",
    port: "escrever",
    eu: "escrevi",
    tu: "escreveste",
    ele: "escreveru",
    nos: "escrevemos",
    eles: "escreveram",
    spr: "2",
  },
  {
    ru: "ждать",
    port: "esperar",
    eu: "esperei",
    tu: "esperaste",
    ele: "esperou",
    nos: "esperámos",
    eles: "esperaram",
    spr: "1",
  },
  {
    ru: "забывать",
    port: "esquecer",
    eu: "esqueci",
    tu: "esqueceste",
    ele: "esqueceu",
    nos: "esquecemos",
    eles: "esqueceram",
    spr: "2",
  },
  {
    ru: "быть",
    port: "estar",
    eu: "estive",
    tu: "estiveste",
    ele: "esteve",
    nos: "estivemos",
    eles: "estiveram",
    spr: "1",
  },
  {
    ru: "учить",
    port: "estudar",
    eu: "estudei",
    tu: "estudaste",
    ele: "estudou",
    nos: "estudámos",
    eles: "estudaram",
    spr: "1",
  },
  {
    ru: "существовать",
    port: "existir",
    eu: "existi",
    tu: "exististe",
    ele: "existiu",
    nos: "existimos",
    eles: "existiram",
    spr: "3",
  },
  {
    ru: "обьяснять",
    port: "explicar",
    eu: "explicei",
    tu: "explicaste",
    ele: "explicou",
    nos: "explicámos",
    eles: "explicaram",
    spr: "1",
  },
  {
    ru: "говорить",
    port: "falar",
    eu: "falei",
    tu: "falaste",
    ele: "falou",
    nos: "falámos",
    eles: "falaram",
    spr: "1",
  },
  {
    ru: "делать",
    port: "fazer",
    eu: "fiz",
    tu: "fizeste",
    ele: "fez",
    nos: "fizemos",
    eles: "fizeram",
    spr: "4",
  },
  {
    ru: "закрываться",
    port: "fechar",
    eu: "fechei",
    tu: "fechaste",
    ele: "fechou",
    nos: "fechámos",
    eles: "fecharam",
    spr: "1",
  },
  {
    ru: "находиться",
    port: "ficar",
    eu: "fiquei",
    tu: "ficaste",
    ele: "ficou",
    nos: "ficámos",
    eles: "ficaram",
    spr: "1",
  },
  {
    ru: "курить",
    port: "fumar",
    eu: "fumei",
    tu: "fumaste",
    ele: "fumou",
    nos: "fumámos",
    eles: "fumaram",
    spr: "1",
  },
  {
    ru: "зарабатывать",
    port: "ganhar",
    eu: "ganhei",
    tu: "ganhaste",
    ele: "ganhou",
    nos: "ganhámos",
    eles: "ganharam",
    spr: "1",
  },
  {
    ru: "гарантировать",
    port: "garantir",
    eu: "garanti",
    tu: "garantiste",
    ele: "garantiu",
    nos: "garantimos",
    eles: "garantiram",
    spr: "3",
  },
  {
    ru: "тратить",
    port: "gastar",
    eu: "gastei",
    tu: "gastaste",
    ele: "gastou",
    nos: "gastámos",
    eles: "gastaram",
    spr: "1",
  },
  {
    ru: "вращаться",
    port: "girar",
    eu: "girei",
    tu: "giraste",
    ele: "girou",
    nos: "girámos",
    eles: "giraram",
    spr: "1",
  },
  {
    ru: "нравиться",
    port: "gostar",
    eu: "gostei",
    tu: "gostaste",
    ele: "gostou",
    nos: "gostámos",
    eles: "gostaram",
    spr: "1",
  },
  {
    ru: "кричать",
    port: "gritar",
    eu: "gritei",
    tu: "gritaste",
    ele: "gritou",
    nos: "gritámos",
    eles: "gritaram",
    spr: "1",
  },
  {
    ru: "храненить",
    port: "guardar",
    eu: "guardei",
    tu: "guardaste",
    ele: "guardou",
    nos: "guardámos",
    eles: "guardaram",
    spr: "1",
  },
  {
    ru: "печатать",
    port: "imprimir",
    eu: "imprimi",
    tu: "imprimiste",
    ele: "imprimiu",
    nos: "imprimimos",
    eles: "imprimiram",
    spr: "3",
  },
  {
    ru: "преставлять",
    port: "introduzir",
    eu: "introduzi",
    tu: "introduziste",
    ele: "introduziu",
    nos: "introduzimos",
    eles: "introduziram",
    spr: "3",
  },
  {
    ru: "идти",
    port: "ir",
    eu: "fui",
    tu: "foste",
    ele: "foi",
    nos: "fomos",
    eles: "foram",
    spr: "3",
  },
  {
    ru: "ужинать",
    port: "jantar",
    eu: "jantei",
    tu: "jantaste",
    ele: "jantou",
    nos: "jantámos",
    eles: "jantaram",
    spr: "1",
  },
  {
    ru: "играть",
    port: "jogar",
    eu: "joguei",
    tu: "jogaste",
    ele: "jogou",
    nos: "jogámos",
    eles: "amjogar",
    spr: "1",
  },
  {
    ru: "бросать",
    port: "lançar",
    eu: "lançei",
    tu: "lançaste",
    ele: "lançou",
    nos: "lançámos",
    eles: "lançaram",
    spr: "1",
  },
  {
    ru: "полдничать",
    port: "lanchar",
    eu: "lanchei",
    tu: "lanchaste",
    ele: "lanchou",
    nos: "lanchámos",
    eles: "lancharam",
    spr: "1",
  },
  {
    ru: "мыть",
    port: "lavar",
    eu: "lavei",
    tu: "lavaste",
    ele: "lavou",
    nos: "lavámos",
    eles: "lavaram",
    spr: "1",
  },
  {
    ru: "помнить",
    port: "lembrar",
    eu: "lembrei",
    tu: "lembraste",
    ele: "lembrou",
    nos: "lembrámos",
    eles: "lembraram",
    spr: "1",
  },
  {
    ru: "читать",
    port: "ler",
    eu: "li",
    tu: "leste",
    ele: "leu",
    nos: "lemos",
    eles: "leram",
    spr: "2",
  },
  {
    ru: "вставать",
    port: "levantar",
    eu: "levantei",
    tu: "levantaste",
    ele: "levantou",
    nos: "levantámos",
    eles: "levantaram",
    spr: "1",
  },
  {
    ru: "брать, забирать",
    port: "levar",
    eu: "levei ",
    tu: "levaste",
    ele: "levou",
    nos: "levámos",
    eles: "levaram",
    spr: "1",
  },
  {
    ru: "включать, звонить",
    port: "ligar",
    eu: "liguei",
    tu: "ligaste",
    ele: "ligou",
    nos: "ligámos",
    eles: "ligaram",
    spr: "1",
  },
  {
    ru: "чистить",
    port: "limpar",
    eu: "limpei",
    tu: "limpaste",
    ele: "limpou",
    nos: "limpámos",
    eles: "limparam",
    spr: "1",
  },
  {
    ru: "отправлять",
    port: "mandar",
    eu: "mandei",
    tu: "mandaste",
    ele: "mandaou",
    nos: "mandámos",
    eles: "mandaram",
    spr: "1",
  },
  {
    ru: "записаться",
    port: "marcar",
    eu: "marquei",
    tu: "marcaste",
    ele: "marcou",
    nos: "marcámos",
    eles: "marcaram",
    spr: "1",
  },
  {
    ru: "измерять",
    port: "medir",
    eu: "medi",
    tu: "mediste",
    ele: "mediu",
    nos: "medimos",
    eles: "mediram",
    spr: "3",
  },
  {
    ru: "улучшить",
    port: "melhorar",
    eu: "melhoei",
    tu: "melhoraste",
    ele: "melhorou",
    nos: "melhorámos",
    eles: "melhoraram",
    spr: "1",
  },
  {
    ru: "вставлять",
    port: "meter",
    eu: "meti",
    tu: "meteste",
    ele: "meteu",
    nos: "metemos",
    eles: "meteram",
    spr: "2",
  },
  {
    ru: "жить",
    port: "morar",
    eu: "morei",
    tu: "moraste",
    ele: "morou",
    nos: "morámos",
    eles: "moraram",
    spr: "1",
  },
  {
    ru: "кусать",
    port: "morder",
    eu: "mordi",
    tu: "mordeste",
    ele: "mordeu",
    nos: "mordemos",
    eles: "morderam",
    spr: "2",
  },
  {
    ru: "умереть",
    port: "morrer",
    eu: "morri",
    tu: "morreste",
    ele: "morreu",
    nos: "morremos",
    eles: "morreram",
    spr: "2",
  },
  {
    ru: "показывать",
    port: "mostrar",
    eu: "mostrei",
    tu: "mostraste",
    ele: "mostrou",
    nos: "mostrámos",
    eles: "mostraram",
    spr: "1",
  },
  {
    ru: "изменять",
    port: "mudar",
    eu: "mudei",
    tu: "mudaste",
    ele: "mudou",
    nos: "mudámos",
    eles: "mudaram",
    spr: "1",
  },
  {
    ru: "плавать",
    port: "nadar",
    eu: "nadei",
    tu: "nadaste",
    ele: "nadou",
    nos: "nadámos",
    eles: "nadaram",
    spr: "1",
  },
  {
    ru: "встречаться",
    port: "namorar",
    eu: "namorei",
    tu: "namoraste",
    ele: "namorou",
    nos: "namorámos",
    eles: "namoraram",
    spr: "1",
  },
  {
    ru: "родиться",
    port: "nascer",
    eu: "nasci",
    tu: "nasceste",
    ele: "nasceu",
    nos: "nascemos",
    eles: "nasceram",
    spr: "2",
  },
  {
    ru: "ненавидеть",
    port: "odiar",
    eu: "odiei",
    tu: "odiaste",
    ele: "odiou",
    nos: "odiámos",
    eles: "odiaram",
    spr: "1",
  },
  {
    ru: "предлагать",
    port: "oferecer",
    eu: "ofereci",
    tu: "ofereceste",
    ele: "ofereceu",
    nos: "oferecemos",
    eles: "ofereceram",
    spr: "2",
  },
  {
    ru: "смотреть",
    port: "olhar",
    eu: "olhei",
    tu: "olhaste",
    ele: "olhou",
    nos: "olhámos",
    eles: "olharam",
    spr: "1",
  },
  {
    ru: "слышать",
    port: "ouvir",
    eu: "ouvi",
    tu: "ouviste",
    ele: "ouviu",
    nos: "ouvimos",
    eles: "ouviram",
    spr: "3",
  },
  {
    ru: "платить",
    port: "pagar",
    eu: "paguei",
    tu: "pagaste",
    ele: "pagaou",
    nos: "pagámos",
    eles: "pagaram",
    spr: "1",
  },
  {
    ru: "останавливаться",
    port: "parar",
    eu: "parei",
    tu: "paraste",
    ele: "parou",
    nos: "parámos",
    eles: "pararam",
    spr: "1",
  },
  {
    ru: "казаться",
    port: "parecer",
    eu: "pareci",
    tu: "pareceste",
    ele: "pareceu",
    nos: "parecemos",
    eles: "pareceram",
    spr: "2",
  },
  {
    ru: "участвовать",
    port: "participar",
    eu: "participei",
    tu: "participaste",
    ele: "participou",
    nos: "participámos",
    eles: "participaram",
    spr: "1",
  },
  {
    ru: "делиться",
    port: "partilhar",
    eu: "partilhei",
    tu: "partilhaste",
    ele: "partilhou",
    nos: "partilhámos",
    eles: "partilharam",
    spr: "1",
  },
  {
    ru: "уезжать, ломать, разбивать",
    port: "partir",
    eu: "parti",
    tu: "partiste",
    ele: "partiu",
    nos: "partimos",
    eles: "partiram",
    spr: "3",
  },
  {
    ru: "тратить, проводить",
    port: "passar",
    eu: "passei",
    tu: "passaste",
    ele: "passou",
    nos: "passámos",
    eles: "passaram",
    spr: "1",
  },
  {
    ru: "просить",
    port: "pedir",
    eu: "pedi",
    tu: "pediste",
    ele: "pediu",
    nos: "pedimos",
    eles: "pediram",
    spr: "3",
  },
  {
    ru: "брать, ловить",
    port: "pegar",
    eu: "peguei",
    tu: "pegaste",
    ele: "pegou",
    nos: "pegámos",
    eles: "pegaram",
    spr: "1",
  },
  {
    ru: "обдумывать",
    port: "pensar",
    eu: "pensei",
    tu: "pensaste",
    ele: "pensou",
    nos: "pensámos",
    eles: "pensaram",
    spr: "1",
  },
  {
    ru: "понимать",
    port: "perceber",
    eu: "percebi",
    tu: "percebeste",
    ele: "percebeu",
    nos: "percebemos",
    eles: "perceberam",
    spr: "2",
  },
  {
    ru: "терять",
    port: "perder",
    eu: "perdi",
    tu: "perdeste",
    ele: "perdeu",
    nos: "perdemos",
    eles: "perderam",
    spr: "2",
  },
  {
    ru: "спрашивать",
    port: "perguntar",
    eu: "perguntei",
    tu: "perguntaste",
    ele: "perguntou",
    nos: "perguntámos",
    eles: "perguntaram",
    spr: "1",
  },
  {
    ru: "разрешать",
    port: "permitir",
    eu: "permiti",
    tu: "permitiste",
    ele: "permitiu",
    nos: "permitimos",
    eles: "permitiram",
    spr: "3",
  },
  {
    ru: "взвешивать",
    port: "pesar",
    eu: "pesei",
    tu: "pesaste",
    ele: "pesou",
    nos: "pesámos",
    eles: "pesaram",
    spr: "1",
  },
  {
    ru: "рисовать красками",
    port: "pintar",
    eu: "pintei",
    tu: "pintaste",
    ele: "pintou",
    nos: "pintámos",
    eles: "pintaram",
    spr: "1",
  },
  {
    ru: "мочь",
    port: "poder",
    eu: "pude",
    tu: "pudeste",
    ele: "pôde",
    nos: "pudemos",
    eles: "puderam",
    spr: "4",
  },
  {
    ru: "класть, положить",
    port: "pôr",
    eu: "pus",
    tu: "puseste",
    ele: "pôs",
    nos: "pusemos",
    eles: "puseram",
    spr: "4",
  },
  {
    ru: "сохранять",
    port: "poupar",
    eu: "poupei",
    tu: "poupaste",
    ele: "poupou",
    nos: "poupámos",
    eles: "pouparam",
    spr: "1",
  },
  {
    ru: "нуждаться",
    port: "precisar",
    eu: "precisei",
    tu: "precisaste",
    ele: "precisou",
    nos: "precisámos",
    eles: "precisaram",
    spr: "1",
  },
  {
    ru: "предпочитать",
    port: "preferir",
    eu: "preferi",
    tu: "preferiste",
    ele: "preferiu",
    nos: "preferimos",
    eles: "preferiram",
    spr: "3",
  },
  {
    ru: "готовить",
    port: "preparar",
    eu: "preparei",
    tu: "preparaste",
    ele: "preparou",
    nos: "preparámos",
    eles: "prepararam",
    spr: "1",
  },
  {
    ru: "намереваться",
    port: "pretender",
    eu: "pretendi",
    tu: "pretendeste",
    ele: "pretendeu",
    nos: "pretendemos",
    eles: "pretenderam",
    spr: "2",
  },
  {
    ru: "искать",
    port: "procurar",
    eu: "procurei",
    tu: "procuraste",
    ele: "procurou",
    nos: "procurámos",
    eles: "procuraram",
    spr: "1",
  },
  {
    ru: "обещать",
    port: "prometer",
    eu: "prometi",
    tu: "prometeste",
    ele: "prometeu",
    nos: "prometemoa",
    eles: "prometeram",
    spr: "2",
  },
  {
    ru: "защищать",
    port: "proteger",
    eu: "protegi",
    tu: "protegeste",
    ele: "protegeu",
    nos: "protegemos",
    eles: "protegeram",
    spr: "2",
  },
  {
    ru: "хотеть",
    port: "querer",
    eu: "quis",
    tu: "quiseste",
    ele: "quis",
    nos: "quisemos",
    eles: "quiseram",
    spr: "4",
  },
  {
    ru: "получать",
    port: "receber",
    eu: "recebi",
    tu: "recebeste",
    ele: "recebeu",
    nos: "recebemos",
    eles: "receberam",
    spr: "2",
  },
  {
    ru: "возвращаться",
    port: "regressar",
    eu: "regressei",
    tu: "regressaste",
    ele: "regressou",
    nos: "regressámos",
    eles: "regressaram",
    spr: "1",
  },
  {
    ru: "повторять",
    port: "repetir",
    eu: "repeti",
    tu: "repetiste",
    ele: "repetiu",
    nos: "repetimos",
    eles: "repetiram",
    spr: "3",
  },
  {
    ru: "проживать",
    port: "residir",
    eu: "residi",
    tu: "residiste",
    ele: "residiu",
    nos: "residimos",
    eles: "residiram",
    spr: "3",
  },
  {
    ru: "сопротивляться",
    port: "resistir",
    eu: "resisti",
    tu: "resististe",
    ele: "resistiu",
    nos: "resistimos",
    eles: "resistiram",
    spr: "3",
  },
  {
    ru: "решать проблему",
    port: "resolver",
    eu: "resolvi",
    tu: "resolveste",
    ele: "resolveu",
    nos: "resolvemos",
    eles: "resolveram",
    spr: "2",
  },
  {
    ru: "отвечать",
    port: "responder",
    eu: "respondi",
    tu: "respondeste",
    ele: "respondeu",
    nos: "respondemos",
    eles: "responderam",
    spr: "2",
  },
  {
    ru: "отвечать",
    port: "responder",
    eu: "respondi",
    tu: "respondeste",
    ele: "respondeu",
    nos: "respondemos",
    eles: "responderam",
    spr: "2",
  },
  {
    ru: "смеяться",
    port: "rir",
    eu: "ri",
    tu: "riste",
    ele: "riu",
    nos: "rimos",
    eles: "riram",
    spr: "3",
  },
  {
    ru: "расставаться",
    port: "romper",
    eu: "rompi",
    tu: "rompeste",
    ele: "rompeu",
    nos: "rompemos",
    eles: "romperam",
    spr: "2",
  },
  {
    ru: "знать",
    port: "saber",
    eu: "soube",
    tu: "soubeste",
    ele: "soube",
    nos: "soubemos",
    eles: "souberam",
    spr: "4",
  },
  {
    ru: "выходить",
    port: "sair",
    eu: "saí",
    tu: "saíste",
    ele: "saiu",
    nos: "saímos",
    eles: "saíram",
    spr: "4",
  },
  {
    ru: "прагать",
    port: "saltar",
    eu: "saltei",
    tu: "saltaste",
    ele: "saltou",
    nos: "saltámos",
    eles: "saltaram",
    spr: "1",
  },
  {
    ru: "сушить",
    port: "secar",
    eu: "sequei",
    tu: "secaste",
    ele: "secou",
    nos: "secámos",
    eles: "secaram",
    spr: "1",
  },
  {
    ru: "сидеть",
    port: "sentar",
    eu: "sentei",
    tu: "sentaste",
    ele: "sentou",
    nos: "sentámos",
    eles: "sentaram",
    spr: "1",
  },
  {
    ru: "чувствовать",
    port: "sentir",
    eu: "senti",
    tu: "sentiste",
    ele: "sentiu",
    nos: "sentimos",
    eles: "sentiram",
    spr: "3",
  },
  {
    ru: "разделять",
    port: "separar",
    eu: "separei",
    tu: "separaste",
    ele: "separou",
    nos: "separámos",
    eles: "separaram",
    spr: "1",
  },
  {
    ru: "быть",
    port: "ser",
    eu: "fui",
    tu: "foste",
    ele: "foi",
    nos: "fomos",
    eles: "foram",
    spr: "2",
  },
  {
    ru: "обслуживать",
    port: "servir",
    eu: "servi",
    tu: "serviste",
    ele: "serviu",
    nos: "servimos",
    eles: "serviram",
    spr: "3",
  },
  {
    ru: "улыбаться",
    port: "sorrir",
    eu: "sorri",
    tu: "sorriste",
    ele: "sorriu",
    nos: "sorrimos",
    eles: "sorriram",
    spr: "3",
  },
  {
    ru: "подниматься",
    port: "subir",
    eu: "subi",
    tu: "subiste",
    ele: "subiu",
    nos: "subimos",
    eles: "subiram",
    spr: "3",
  },
  {
    ru: "удивлять",
    port: "surpreender",
    eu: "surpreendi",
    tu: "surpreendeste",
    ele: "surpreendeu",
    nos: "surpreendemos",
    eles: "surpreenderam",
    spr: "2",
  },
  {
    ru: "попытаться",
    port: "tentar",
    eu: "tentei",
    tu: "tentaste",
    ele: "tentou",
    nos: "tentámos",
    eles: "tentaram",
    spr: "1",
  },
  {
    ru: "иметь ",
    port: "ter",
    eu: "tive",
    tu: "tiveste",
    ele: "teve",
    nos: "tivemos",
    eles: "tiveram",
    spr: "4",
  },
  {
    ru: "взять, брать",
    port: "tirar",
    eu: "tirei",
    tu: "tiraste",
    ele: "tirou",
    nos: "tirámos",
    eles: "tiraram",
    spr: "1",
  },
  {
    ru: "играть на муз.инструметнах",
    port: "tocar",
    eu: "toquei",
    tu: "tocaste",
    ele: "tocou",
    nos: "tocámos",
    eles: "tocaram",
    spr: "1",
  },
  {
    ru: "брать, принимать",
    port: "tomar",
    eu: "tomei",
    tu: "tomaste",
    ele: "tomou",
    nos: "tomamos",
    eles: "tomaram",
    spr: "1",
  },
  {
    ru: "работать",
    port: "trabalhar",
    eu: "trabalhei",
    tu: "trabalhaste",
    ele: "trabalhou",
    nos: "trabalhámos",
    eles: "trabalharam",
    spr: "1",
  },
  {
    ru: "заботиться о ком то",
    port: "tratar",
    eu: "tratei",
    tu: "trataste",
    ele: "tratou",
    nos: "tratámos",
    eles: "trataram",
    spr: "1",
  },
  {
    ru: "приносить",
    port: "trazer",
    eu: "trouxe",
    tu: "trouxeste",
    ele: "trouxe",
    nos: "trouxemos",
    eles: "trouxeram",
    spr: "4",
  },
  {
    ru: "менять",
    port: "trocar",
    eu: "trocei",
    tu: "trocaste",
    ele: "trocou",
    nos: "trocámos",
    eles: "trocaram",
    spr: "1",
  },
  {
    ru: "использовать",
    port: "usar",
    eu: "usei",
    tu: "usaste",
    ele: "usou",
    nos: "usámos",
    eles: "usaram",
    spr: "1",
  },
  {
    ru: "продавать",
    port: "vender",
    eu: "vendi",
    tu: "vendeste",
    ele: "vendeu",
    nos: "vendemos",
    eles: "venderam",
    spr: "2",
  },
  {
    ru: "видеть",
    port: "ver",
    eu: "vi",
    tu: "viste",
    ele: "viu",
    nos: "vimos",
    eles: "viram",
    spr: "4",
  },
  {
    ru: "одевать",
    port: "vestir",
    eu: "vesti",
    tu: "vestiste",
    ele: "vestiu",
    nos: "vestimos",
    eles: "vestiram",
    spr: "3",
  },
  {
    ru: "путешествовать",
    port: "viajar",
    eu: "viajei",
    tu: "viajaste",
    ele: "viajou",
    nos: "viajámos",
    eles: "viajaram",
    spr: "1",
  },
  {
    ru: "приходить, приезжать",
    port: "vir",
    eu: "vim",
    tu: "vieste",
    ele: "veio",
    nos: "viemos",
    eles: "vieram",
    spr: "4",
  },
  {
    ru: "повернуть",
    port: "virar",
    eu: "virei",
    tu: "viraste",
    ele: "virou",
    nos: "virámos",
    eles: "amvirar",
    spr: "1",
  },
  {
    ru: "жить",
    port: "viver",
    eu: "vivi",
    tu: "viveste",
    ele: "viveu",
    nos: "vivemos",
    eles: "viveram",
    spr: "2",
  },
  {
    ru: "возвращаться",
    port: "voltar",
    eu: "voltei",
    tu: "voltaste",
    ele: "voltou",
    nos: "voltámos",
    eles: "voltaram",
    spr: "1",
  },
];
