import { getAuth, onAuthStateChanged } from "firebase/auth";

const sait = "https://www.conjugacao.com.br/verbo-esperar/";

export function checkResponse(res: Response) {
  if (res.ok) {
    return res.json();
  }
  return Promise.reject(`ошибка ${res.status}`);
}

export const getIngredients = () => {
  return fetch(sait).then(checkResponse);
};

export const fetchList = async () => {
  const res = await getIngredients();
  const data = res.data;
  return data;
};

export const getreg2 = () => {
  let userg = {
    email: "",
    tocken: "",
    id: "",
    auth: false,
    name: "",
  };
  let mail: string = "";
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      if (user.email) {
        mail = user.email;
      }
      const uid = user.uid;
      userg = {
        email: mail,
        tocken: user.refreshToken,
        id: user.uid,
        auth: true,
        name: "",
      };
      // ...
    } else {
      // User is signed out
      // ...
      userg = {
        email: mail,
        tocken: "",
        id: "",
        auth: false,
        name: "",
      };
    }
  });
  return userg;
};

export const getreg = async () => {
  let userg = {
    email: "",
    tocken: "",
    id: "",
    auth: false,
    name: "",
  };
  let mail: string = "";
  const auth = getAuth();
   
  const checkAuthState = async () => {
    const user = await auth
      .authStateReady()
      .then(() => {
        
        if (auth.currentUser) {
          
          if (auth.currentUser.email !== null) {
            
            userg = {
              email: auth.currentUser.email,
              tocken: auth.currentUser.refreshToken,
              id: auth.currentUser.uid,
              auth: true,
              name: "",
            };
          }
        }
      })
      .catch(() => {
        console.log("произошла ошибка");
      });

    // End of E.g.
  };
  checkAuthState();
  return userg;
};
