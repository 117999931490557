import { useState } from "react";
import styles from "./menu.module.css";

type TOrderypeprops = {
  list: string[];
  setlist: React.Dispatch<React.SetStateAction<string>>;
  name: string;
};

function Menu(props: TOrderypeprops) {
  const listMenu = props.list;
  let index = 1;
  const [open, setopen] = useState(false);

  return (
    <div className={styles.main}>
      <header className={styles.header}>
        <div className={styles.menuButton} onClick={() => setopen(!open)}>
          {props.name}
        </div>
        {open && (
          <nav className={styles.menu}>
            <ul className={styles.menulist}>
              {listMenu.map((item) => {
                if (item !== props.name) {
                  index = index + 1;
                  return (
                    <li
                      className={styles.menuitem}
                      key={index}
                      onClick={() => {
                        props.setlist(item);
                        setopen(!open);
                      }}
                    >
                      {item}
                    </li>
                  );
                }
              })}
            </ul>
          </nav>
        )}
      </header>
    </div>
  );
}

export default Menu;
