import styles from "./app.module.css";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import FestList from "../../pages/fest-list/fest-list";
import FoundList from "../../pages/found-list/found-list";
import WriteList from "../../pages/write-list/write-list";
import Title from "../title/title";
import { useAppDispatch, useAppSelector } from "../../types/hook";
import GlagList from "../glag/glag";
import Presente from "../presente/presente";
import PresWrite from "../preswrite/preswrite";
import Perfeito from "../perfeito/perfeito";
import Imperfeito from "../imperfeito/imperfeito";
import PerfWrite from "../perfwrite/perfwrite";
import ImperfeitoWrite from "../imperfeitowriter/imperfeitowriter";
import FrazList from "../fraz/fraz";
import Profile from "../../pages/profile/profile";
import Login from "../../pages/login/login";
import Register from "../../pages/registr/registr";
import ForgotPassword from "../../pages/forgot-password/forgot-password";
import { setuser } from "../../store/user";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getuser } from "../../store/action-selector";
import ManyList from "../many/many";
import GlagFraz from "../glag-present/glag-present";
import PredGlag from "../glagpred/glagpred";
import PredAll from "../predall/predall";

import PredConst from "../predconst/predconst";
import PredConstHard from "../predconsthard/predconsthard";
import ResetPassword from "../../pages/reset-password/reset-password";
import Loader from "../loader/loder";

import Chat from "../chat/chat";
import End from "../end/end";
import Palavra from "../../pages/palavra/palavra";
import Palavrab from "../../pages/palavrab/palavrab";
import Verbo from "../../pages/verbo/verbo";
import Words from "../../pages/words/words";
import StartPage from "../../pages/startpage/startpage";
import MenuPage from "../../pages/menupage/menupage";
import Frazs from "../../pages/frazs/frazs";
import RequireAuth from "../../hoc/requireAuth";
import { getreg } from "../../api/api";
import RequireAuthc from "../../hoc/requireoutAuth";

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      const res = await getreg();
      dispatch(setuser(res));
    })();
  });

  /*useEffect(() => {
    const auth = getAuth();
    console.log(auth);
    let mail = "";
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.email) {
          mail = user.email;
        }
        dispatch(
          setuser({
            email: mail,
            tocken: user.refreshToken,
            id: user.uid,
            auth: true,
            name: "",
          })
        );
      } else {
        // User is signed out
        // ...
      }
    });*/

  /*useEffect(() => {
    const auth = getAuth();
    console.log("111111111");
    const checkAuthState = async () => {
      const user = await auth
        .authStateReady()
        .then(() => {
          console.log("22222222222");
          console.log(auth.authStateReady());
          if (auth.currentUser) {
            console.log("333333333333333");
            console.log(auth.currentUser);
            if (auth.currentUser.email !== null) {
              console.log("4444444444444");
              console.log(auth.currentUser.email);
              dispatch(
                setuser({
                  email: auth.currentUser.email,
                  tocken: auth.currentUser.refreshToken,
                  id: auth.currentUser.uid,
                  auth: true,
                  name: "",
                })
              );
            }
          }
        })
        .catch(() => {
          console.log("произошла ошибка");
        });

      // End of E.g.
    };
    checkAuthState();
  }, []);*/

  useEffect(() => {
    document.title = "Европейский португальский с Тоби";
  }, []);

  return (
    <div className={styles.app}>
      <Routes>
        <Route
          path="/chat"
          element={
            <RequireAuth>
              <Chat />
            </RequireAuth>
          }
        />
        <Route
          path="/predall"
          element={
            <RequireAuth>
              <PredAll />
            </RequireAuth>
          }
        />
        <Route path="/predconst" element={<PredConst />} />
        <Route path="/predconsthard" element={<PredConstHard />} />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route path="/glag" element={<GlagList />} />
        <Route path="/learn" element={<FestList />} />
        <Route path="/feed" element={<FoundList />} />
        <Route path="/write" element={<WriteList />} />
        <Route path="/presente" element={<Presente />} />
        <Route path="/preswrite" element={<PresWrite />} />
        <Route path="/perfeito" element={<Perfeito />} />
        <Route path="/prefwrite" element={<PerfWrite />} />
        <Route path="/imperfeito" element={<Imperfeito />} />
        <Route path="/many" element={<ManyList />} />
        <Route path="/fraz" element={<FrazList />} />
        <Route path="/imperfeitowriter" element={<ImperfeitoWrite />} />
        <Route path="/predglag" element={<PredGlag />} />
        <Route path="/presglag" element={<GlagFraz />} />
        <Route
          path="/palavraaone"
          element={
            <RequireAuth>
              <Palavra />
            </RequireAuth>
          }
        />
        <Route
          path="/palavrabone"
          element={
            <RequireAuth>
              <Palavrab />
            </RequireAuth>
          }
        />
        <Route
          path="/verbo"
          element={
            <RequireAuth>
              <Verbo />
            </RequireAuth>
          }
        />
        <Route path="/palavra/all" element={<Words />} />
        <Route path="/cardfraz" element={<Frazs />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route
          path="/resetpassword"
          element={
            <RequireAuthc>
              <ResetPassword />
            </RequireAuthc>
          }
        />
        <Route
          path="/register"
          element={
            <RequireAuthc>
              <Register />
            </RequireAuthc>
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <RequireAuthc>
              <ForgotPassword />
            </RequireAuthc>
          }
        />
        <Route
          path="/login"
          element={
            <RequireAuthc>
              <Login />
            </RequireAuthc>
          }
        />
        <Route path="/" element={<StartPage />} />
        <Route path="*" element={<StartPage />} />
      </Routes>
      <End></End>
    </div>
  );
}

export default App;
//<Route path="/profile" element={<OnlyAuth component={<Profile />} />} />
